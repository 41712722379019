const initialState = () => ({
  meta: {
    page: 1,
    order: { id: 'DESC' },
    q: '',
  },
});

const state = initialState();

const getters = {
  meta: (state) => state.meta,
};

const actions = {
  setMeta: async ({ commit }, opts = {}) => {
    const { page, total_pages, limit, total_items, order, q } = opts;
    commit('_setMeta', { page, total_pages, limit, total_items, order, q });
  },

  useSearch: async ({ commit }, value) => {
    await commit('_setSearch', value);
  },

  useSort: async ({ commit }, val) => {
    await commit('_setSort', val);
  },
};

const mutations = {
  _setMeta: (state, { page, total_pages, limit, total_items, order, q }) => {
    const payload = {
      ...(page && { page }),
      ...(total_pages && { total_pages }),
      ...(limit && { limit }),
      ...(total_items && { total_items }),
      ...(order && { order }),
      ...(q && { q }),
    };
    state.meta = { ...state.meta, ...payload };
  },

  _setSearch: (state, value) => {
    state.meta.q = value;
  },

  _setSort: (state, value) => {
    state.meta.order = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
