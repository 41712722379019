export const COMPONENTTYPE = {
  SWITCHER: 'SwitchButton',
  LINKA: 'Linka',
};

export const fillingCoffeeSettingFields = (options) => [
  // {
  //   label: 'coffee_reeboot_coffee_machine',
  //   description: 'coffee_reeboot_text',
  //   value: true,
  //   actionComponent: 'Link',
  //   actionComponentLabel: this.setActionComponentLabel('reeboot'),
  //   // actionComponentLabel: this.requestPending
  //   //   ? 'coffee_reloading'
  //   //   : 'coffee_reeboot',
  //   actionCb: () => this.settingHandler('reeboot', 'Link'),
  // },
  {
    label: 'defer_service',
    description: 'coffee_service_maintenance_text',
    value: options.defer_service,
    actionComponent: COMPONENTTYPE.LINKA,
    actionComponentLabel: 'coffee_postpone',
    reducer: (setting) => {
      const value = !setting.value;
      return { ...setting, value };
    },
  },
  {
    label: 'block_drinks',
    description: 'coffee_block_text',
    value: options.block_drinks,
    actionComponent: COMPONENTTYPE.SWITCHER,
    reducer: (setting) => {
      const value = !setting.value;
      return { ...setting, value };
    },
  },
  {
    label: 'block_drink_changes',
    description: 'coffee_props_block_text',
    value: options.block_drink_changes,
    actionComponent: COMPONENTTYPE.SWITCHER,
    reducer: (setting) => {
      const value = !setting.value;
      return { ...setting, value };
    },
  },
  {
    label: 'block_menu_access',
    description: 'coffee_menu_text',
    value: options.block_menu_access,
    actionComponent: COMPONENTTYPE.SWITCHER,
    reducer: (setting) => {
      const value = !setting.value;
      return { ...setting, value };
    },
  },
  {
    label: 'block_drinks_when_offline',
    value: options.block_drinks_when_offline,
    actionComponent: COMPONENTTYPE.SWITCHER,
    reducer: (setting) => {
      const value = !setting.value;
      return { ...setting, value };
    },
  },
];
