import VueI18n from 'vue-i18n';
import { filters } from 'shared/core';
// import { difference } from 'lodash';

import { ru } from '@/core/locales/ru';
import kz from '@/core/locales/kz';
import hr from '@/core/locales/hr';
import ua from '@/core/locales/ua';
import gr from '@/core/locales/gr';
import tr from '@/core/locales/tr';
import en from '@/core/locales/en';
import lv from '@/core/locales/lv';

// Перевод таблицы Максима с жиры
// const locales = ['__key__', 'RU', 'EN', 'KZ', 'HR', 'UA', 'TR', 'GR', 'LV'];

// const getLocales = () => {
//   const result = {};

//   locales.slice(1).forEach((loc) => (result[loc] = {}));

//   let index = 0;
//   let key = '';

//   for (let i = 0; i < raw.length; i++) {
//     if (index === 0) {
//       key = raw[i];
//       index++;
//       continue;
//     }

//     result[locales[index]][key] = raw[i];
//     if (index === locales.length - 1) {
//       index = 0;
//     } else {
//       index++;
//     }
//   }

//   return result;
// };

// import without from 'lodash/without';

// console.log(
//   JSON.stringify(
//     without(Object.keys(ru), ...Object.keys(lv)).reduce((acc, v) => {
//       acc[v] = ru[v];
//       return acc;
//     }, {}),
//   ),
// );

// const r = /[а-яА-Я]+/;

// console.log(
//   JSON.stringify(
//     Object.keys(lv).reduce((acc, v) => {
//       if (r.test(lv[v])) acc[v] = ru[v];
//       return acc;
//     }, {}),
//   ),
// );

export const translateLocales = {
  ru: { id: 1, name: 'RU', label: 'ru', locale: 'ru' },
  kz: { id: 2, name: 'KZ', label: 'kz', locale: 'kz' },
  hr: { id: 3, name: 'HR', label: 'hr', locale: 'hr' },
  ua: { id: 4, name: 'UA', label: 'ua', locale: 'ua' },
  tr: { id: 5, name: 'TR', label: 'tr', locale: 'tr' },
  gr: { id: 6, name: 'GR  ', label: 'gr', locale: 'gr' },
  en: { id: 7, name: 'US', label: 'us', locale: 'en' },
  lv: { id: 8, name: 'LV', label: 'lv', locale: 'lv' },
  // uk: { id: 8, name: 'UK', label: 'gb', locale: 'en' },
  // cy: { id: 7, name: 'Ελληνικά', label: 'cy', locale: 'gr' },
};
//ISO 639-1
const locales = { ru, kz, hr, ua, tr, gr, en, lv };

// ищет разницу в ключах

// const excludes = [
//   'bbo_welcome',
//   'complete_registration',
//   'your_company_number',
//   'your_company_inn',
//   'contact_name',
//   'accept_terms',
//   'user_agreement_terms',
//   'register',
//   'exit',
//   'license_agreement',
//   'your_company_country',
//   'bank_name',
//   'company_name',
//   'party_filling',
//   'delete_goods_modal_info',
//   'client_show_settings_2',
//   'no_changes_to_update',
//   'for_go_to_exchange_need_say_store',
//   'link_to_showcase',
//   'catalog_successfully_added',
//   'refund_has_been_created',
//   'no_trans_action',
//   'no_refund_text_info',
//   'date_should_not_earlier',
//   'date_should_not_later',
//   'cant_play_video',
//   'check_refund_possibility',
//   'refund_helper_text',
//   'no_one_good_selected',
//   'errors_occurred_while_creating_the_refund',
//   'refund_by_id_goes_successed',
//   'refund_by_id_goes_accept',
//   'refund_technical_support_notice',
//   'refund_money_back_notice',
//   'refund_ready_title',
//   'refund_check_success_info',
//   'refund_check_success_info_with_order_id',
//   'approve_refund',
//   'video_not_available',
//   'video_tooltip_error',
//   'do_upload',
//   'amout_for_refund',
// ];

// const differenceBeetween = (ruLocale, diflocale) => {
//   const curArr = Object.keys(diflocale);
//   const trufulArr = Object.keys(ruLocale);
//   // return difference(trufulArr, curArr);
//   return difference(trufulArr, curArr).filter((key) => !excludes.includes(key));
// };
// const arr = differenceBeetween(ru, kz);

// console.log(
//   arr.map((el) => {
//     return { [el]: ru[el] };
//   }),
// );

const getLocale = () => {
  const preferredLang = localStorage.getItem('Tlocale');
  if (!preferredLang || typeof preferredLang !== 'string') {
    const userLoacale = navigator.language.slice(0, 2);
    if (Object.keys(locales).includes(userLoacale)) {
      return userLoacale;
    } else {
      return 'ru';
    }
  }
  return preferredLang.toString();
};

let instance;

export const getI18n = () => {
  if (!instance) {
    instance = new VueI18n({
      locale: getLocale(),
      messages: locales,
      fallbackLocale: IS_DEV ? [] : ['en'],
      pluralizationRules: {
        ru: function (choice, lng) {
          return filters.getSlavicPluralIndex(choice);
        },
      },
    });
  }

  return instance;
};
