import { uniqBy } from 'lodash';
import { companyAPI } from '@/core/api/company';
import { bus } from 'shared/core';
import { notificationService } from '@/core/services/NotificationService';

const initialState = () => ({
  order: { id: 'ASC' },
  search: '',
  employees: {
    items: [],
    meta: {
      page: null,
    },
  },
  employeeBase: {},
});

const state = initialState();

const getters = {
  order: (state) => state.order,
  search: (state) => state.search,
  employees: (state) => state.employees.items,

  isEmployeeSelected: (state) => (id) => state.employeeBase[id] && state.employeeBase[id].selected,

  canSkipStage: (state) =>
    Object.values(state.employeeBase).every((employee) => !employee.selected),

  hasUnsavedChanges: (_, getters) => !getters.canSkipStage,
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  completeStage: async ({ dispatch }) => {
    const isEmployeesSetToStore = await dispatch('setEmployeesToStore');
    //TODO: return isEmployeesSetToStore
    return true;
  },

  setOrder: ({ commit }, order) => {
    commit('_setOrder', order);
  },

  setSearch: ({ commit }, search) => {
    commit('_setSearch', search);
  },

  setSelected: ({ commit }, { value, employee }) => {
    commit('_setSelected', { value, employee });
  },

  addEmployeesToBase: ({ commit, state }, { employees, storeId }) => {
    const newEmployees = employees.reduce((acc, employee) => {
      const id = employee.id;
      const oldEmployee = state.employeeBase[id];
      const selected = oldEmployee ? oldEmployee.selected : employee.stores_ids.includes(storeId);

      return { ...acc, [id]: { ...employee, selected } };
    }, {});

    commit('_addEmployeesToBase', newEmployees);
  },

  getEmployees: async ({ state, commit, dispatch, rootGetters }, opts = {}) => {
    const { page = 1, merge = false } = opts;
    const { limit } = state.employees.meta;
    const storeId = opts.storeId || Number(rootGetters['trade-point$create/store'].id);

    try {
      const employees = await companyAPI.getEmployeeList({
        q: state.search,
        order: state.order,
        page,
        limit,
      });

      commit('_setEmployees', { employees, merge });
      dispatch('addEmployeesToBase', { employees: employees.items, storeId });
    } catch (error) {
      console.log(error);
    }
  },

  loadMoreEmployees({ state, dispatch }, opts = {}) {
    const { storeId } = opts;
    const { page, total_pages } = state.employees.meta;
    if (page + 1 > total_pages) return;

    return dispatch('getEmployees', { page: page + 1, merge: true, storeId });
  },

  createOrUpdateEmployee: async ({ commit }, employee) => {
    if (!employee.hasOwnProperty('id')) {
      notificationService.sendSuccessSave();
      return companyAPI.createEmployee(employee);
    }

    await companyAPI.updateEmployee(employee);
    notificationService.sendSuccessSave();
    commit('_updateEmployee', employee);
  },

  deleteEmployee: (_, employee) => {
    return companyAPI
      .deleteEmployee({ id: employee.id })
      .then(notificationService.sendSuccessDelete);
  },

  setEmployeesToStore: async ({ state, rootGetters }, storeId) => {
    if (!storeId) {
      storeId = rootGetters['trade-point$create/store'].id;
    }

    const employees_ids = Object.values(state.employeeBase)
      .filter((employee) => employee.selected)
      .map((employee) => employee.id);

    try {
      await companyAPI.saveEmployees({ employees_ids, id: storeId });
      notificationService.sendSuccessSave();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setOrder: (state, order) => {
    state.order = order;
  },

  _setSearch: (state, search) => {
    state.search = search;
  },

  _addEmployeesToBase: (state, employees) => {
    state.employeeBase = { ...state.employeeBase, ...employees };
  },

  _setEmployees: (state, { employees, merge }) => {
    const { items, meta } = employees;
    const newItems = merge ? uniqBy([...state.employees.items, ...items], 'id') : items;

    state.employees = { meta, items: newItems };
  },

  _updateEmployee: (state, employee) => {
    const items = state.employees.items.map((_employee) =>
      _employee.id === employee.id ? employee : _employee,
    );

    state.employees = { ...state.employees, items };
  },

  _setSelected: (state, { value, employee }) => {
    const employeeFromBase = state.employeeBase[employee.id];
    const newEmployee = { ...employeeFromBase, selected: value };

    state.employeeBase = { ...state.employeeBase, [employee.id]: newEmployee };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
