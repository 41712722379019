import { EXTENDED_TYPES, getStoreGroupType } from '@/apps/trade-point/data/types';
import { companyAPI } from '@/core/api/company';
import { searchAPI } from '@/core/api/search';
import { buildInitialOrder } from '@/apps/trade-point/data/stages';

import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import { phoneAlignment } from '@/core/utils/validate';
import { hasChanges } from '@/apps/trade-point/utils/changes';
import { notificationService } from '@/core/services/NotificationService';
import { getTimeZone } from '@/core/utils/timeZone';
import { fillingCoffeeSettingFields } from '@/apps/trade-point/data/coffeeSettings';

const initialState = () => ({
  type: null,
  address: {},
  inputs: {
    address: '',
    name: '',
    floor: '',
    radius: '200',
    catalog: '',
    phones: '',
    coords_lat: '',
    coords_lng: '',
    external_id: '',
    is_closed_area: false,
  },
  coffeeMachineSettings: null,
});

const state = initialState();

const getters = {
  type: (state) => state.type,
  isType: (state) => (type) => state.type === type,
  hasType: (state) => !!state.type,

  hasCoordinates: (state) => !!(state.address.coords_lat && state.address.coords_lng),
  is_closed_area: (state) => state.is_closed_area,

  canHaveRadius: (_state, getters) => {
    return (
      getters.isType(EXTENDED_TYPES.REFRIGERATOR) ||
      getters.isType(EXTENDED_TYPES.STORE) ||
      getters.isType(EXTENDED_TYPES.FILLING_STATION) ||
      getters.isType(EXTENDED_TYPES.CIGARETTES) ||
      getters.isType(EXTENDED_TYPES.COFFEE_MACHINE)
    );
  },

  address: (state) => state.address,
  input: (state) => (name) => state.inputs[name],
  computeTextForRadius: (state) => {
    switch (state.type) {
      case EXTENDED_TYPES.STORE:
        return 'stage_type_store_radius';

      case EXTENDED_TYPES.REFRIGERATOR:
        return 'stage_type_refrigerator_radius';

      case EXTENDED_TYPES.CIGARETTES:
        return 'stage_type_cigarettes_radius';

      case EXTENDED_TYPES.FILLING_STATION:
        return 'stage_type_filling_station_radius';

      case EXTENDED_TYPES.COFFEE_MACHINE:
        return 'stage_type_coffee_machine_radius';
    }
  },

  hasUnsavedChanges: (state) => hasChanges(state, initialState()),
  coffeeMachineSettings: (state) => state.coffeeMachineSettings,
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  completeStage: async ({ dispatch, rootGetters }) => {
    try {
      await dispatch('handleStoreGroup');
      await dispatch('handleStore');
      notificationService.sendSuccessSave();

      return true;
    } catch (error) {
      console.error(error);
    }
  },

  setTypeAction: ({ commit }, type) => {
    commit('setType', type);
    if (!type) return;

    const initialOrder = buildInitialOrder(type);

    const stagesOrder = initialOrder.map(({ name }) => name);

    commit('trade-point$create/setStagesOrder', stagesOrder, { root: true });

    initialOrder.forEach(({ name, required, block }) => {
      commit('trade-point$create/setStageRequired', { name, value: required }, { root: true });
      commit('trade-point$create/setStageBlock', { name, value: !!block }, { root: true });
    });
  },

  setAddressAction: ({ commit }, value) => {
    commit('setAddress', value);
  },

  fetchDirectAddressAction: async ({ commit }, value) => {
    const [target] = await searchAPI.getAddress({ q: value, limit: 1 });
    if (target) commit('setAddress', target);
  },

  setInputAction: ({ commit }, { name, value }) => {
    commit('setInput', { name, value });
  },

  handleStoreGroup: async ({ dispatch, getters, rootGetters }, opts = {}) => {
    const storeGroup = rootGetters['trade-point$create/storeGroup'] || {};
    const storeGroupId = opts.storeGroupId || storeGroup.id;
    const isRussian = rootGetters['core$company/isRussian'];

    const payload = {
      ...storeGroup,
      name: getters.input('name'),
      phones: getters.input('phones'),
      coords_lng: isRussian ? getters.address.coords_lng : Number(getters.input('coords_lng')),
      coords_lat: isRussian ? getters.address.coords_lat : Number(getters.input('coords_lat')),
      address: isRussian ? getters.address.address : getters.input('address'),
      id: storeGroupId,
      type: getStoreGroupType(getters.type),
      address_floor: getters.input('floor'),
    };

    const updatedStoreGroup = storeGroupId
      ? await companyAPI.updateStoreGroup(payload)
      : await companyAPI.createStoreGroup(payload);

    if (updatedStoreGroup && !opts.storeGroupId) {
      await dispatch('trade-point$create/setStoreGroup', updatedStoreGroup, { root: true });
    }
  },

  handleStore: async ({ rootGetters, dispatch, getters }, opts = {}) => {
    const storeGroup = rootGetters['trade-point$create/storeGroup'] || {};
    const store = rootGetters['trade-point$create/store'] || {};
    const storeId = opts.storeId || store.id;
    const radius = getters.canHaveRadius ? getters.input('radius') : 0;
    const isRussian = rootGetters['core$company/isRussian'];
    const isCoffeeMachine = getters.type === 'coffee_machine';

    const payload = {
      ...store,
      name: getters.input('name'),
      phones: phoneAlignment(getters.input('phones')),
      coords_lng: isRussian ? getters.address.coords_lng : Number(getters.input('coords_lng')),
      coords_lat: isRussian ? getters.address.coords_lat : Number(getters.input('coords_lat')),
      address: isRussian ? getters.address.address : getters.input('address'),
      type: getters.type,
      extended_type: getters.type,
      id: storeId,
      store_group_id: storeGroup.id,
      min_distance_to_open: radius,
      address_floor: getters.input('floor'),
      timezone_name: store.timezone_name || getTimeZone(),
      is_closed_area: getters.input('is_closed_area'),
    };

    if (isCoffeeMachine) {
      payload.external_id = getters.input('external_id');
      if (!payload.catalog_id) {
        payload.catalog_id = await dispatch('createCatalog', payload.external_id);
      }
      delete payload.phones;
    }

    const create = isCoffeeMachine ? companyAPI.createStoreCoffee : companyAPI.createStore;
    const update = isCoffeeMachine ? companyAPI.updateStoreCoffee : companyAPI.updateStore;

    const newStore = storeId ? await update(payload) : await create(payload);

    if (!opts.storeId) {
      await dispatch('trade-point$create/setStore', newStore, { root: true });
    }
  },

  getSettingsForCoffeeMachine: async ({ commit }, { store }) => {
    if (!store) return;
    const payload = {
      id: store.id,
    };
    try {
      const settings = await companyAPI.getCoffeeMachineSettings(payload);
      commit('_setCoffeMachineSettings', settings);
    } catch (error) {}
  },

  setInitialInfo: async ({ commit, dispatch }, store) => {
    const isCoffeeMachine = store.extended_type === EXTENDED_TYPES.COFFEE_MACHINE;
    const adresspayload = {
      address: store.address,
      coords_lat: store.coords_lat,
      coords_lng: store.coords_lng,
    };

    commit('setAddress', adresspayload);
    dispatch('setTypeAction', store.extended_type || null);
    commit('setInput', { name: 'name', value: store.name });
    commit('setInput', { name: 'floor', value: store.address_floor });
    commit('setInput', { name: 'address', value: store.address });
    commit('setInput', { name: 'coords_lat', value: String(store.coords_lat) });
    commit('setInput', { name: 'coords_lng', value: String(store.coords_lng) });
    commit('setInput', { name: 'is_closed_area', value: Boolean(store.is_closed_area) });
    commit('setInput', { name: 'radius', value: store.radius_to_select.toString() });
    if (isCoffeeMachine) {
      await dispatch('getSettingsForCoffeeMachine', store);
      commit('setInput', { name: 'external_id', value: store.external_id });
    } else {
      commit('setInput', { name: 'phones', value: store.phones });
    }
  },

  setCoffeeMachineSettings: async ({}, { settings }) => {
    try {
      await companyAPI.setCoffeeMachineSettings(settings);
    } catch (error) {
      console.log(error);
    }
  },

  createCatalog: async (_, externalId) => {
    try {
      const catalog = await companyAPI.createCatalog({ name: externalId, currency_code: 'RUB' });
      return catalog.id;
    } catch (error) {}
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  setType: (state, value) => {
    state.type = value;
  },

  setAddress: (state, value) => {
    state.address = value;
  },

  setInput: (state, { name, value }) => {
    state.inputs = produce(cloneDeep(state.inputs), (draft) => {
      draft[name] = value;
    });
  },

  _setCoffeMachineSettings: (state, settings) => {
    state.coffeeMachineSettings = fillingCoffeeSettingFields(settings);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
