import { TIME_MODES } from '@/core/data/timeModes';
import { differenceInDays, isMatch } from 'date-fns';
import { localeToUTC, utcToLocale } from '@/core/utils/timeZone';

const formatDate = (date) => {
  const [day, month, year] = date.split('.');
  return `${year}-${month}-${day}`;
};

const formatHour = (hour) => {
  const date = new Date();
  date.setHours(Number(hour));

  const localeDate = utcToLocale(date);
  const [localeHour] = localeDate.match(/[\d]+(?=:)/);

  return `${localeHour}:00`;
};

const getDateInterval = ({ start, end }) => ({
  start: localeToUTC(new Date(`${start} 00:00:00`)),
  end: localeToUTC(new Date(`${end} 23:59:59`)),
});

const getDateGroupMode = ({ start, end }) => {
  start = start instanceof Date ? start : new Date(start);
  end = end instanceof Date ? end : new Date(end);

  const difference = differenceInDays(end, start);

  if (difference === 0) return TIME_MODES.HOURS;
  if (difference > 31 && difference < 210) return TIME_MODES.WEEKS;
  if (difference >= 210 && difference < 900) return TIME_MODES.MONTHS;
  if (difference >= 900) return TIME_MODES.YEARS;

  return TIME_MODES.DAYS;
};

const isDate = (str) => {
  const isMatched = isMatch(str, 'yyyy-MM-dd HH:mm:ss');
  return isMatched;
};

export { getDateGroupMode, formatDate, getDateInterval, formatHour, isDate };
