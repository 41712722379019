import { companyAPI } from '@/core/api/company';
import { hasChanges } from '@/apps/trade-point/utils/changes';
import { notificationService } from '@/core/services/NotificationService';

const initialState = () => ({
  type: null,
  name: '',

  logo: {
    file: null,
    img: '',
  },
});

const state = initialState();

const getters = {
  type: (state) => state.type,
  logo: (state) => state.logo,
  name: (state) => state.name,

  canSkipStage: (state) => {
    const valid = state.logo.img && state.name;
    return !valid;
  },

  hasUnsavedChanges: (state) => hasChanges(state, initialState(), ['backgrounds', 'type']),
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  setName: ({ commit }, value) => {
    commit('_setName', value);
  },

  setType: ({ commit }, value) => {
    commit('_setType', value);
  },

  useCompanyBrand: ({ dispatch }, opts = {}) => {
    const { entity } = opts;
    const icon = entity.logos.find((l) => !l.type);

    if (icon) {
      dispatch('setLogoAction', { img: icon.link });
    }

    dispatch('setName', entity.name);
  },

  init: async ({ dispatch }, opts = {}) => {
    const { companyBrandId } = opts;
    const companyBrand = await companyAPI.getCompanyBrandById({ id: companyBrandId });

    await dispatch('useCompanyBrand', { entity: companyBrand });
    return { companyBrand };
  },

  completeStage: async ({ dispatch, getters }, opts = {}) => {
    if (!opts) opts = {};

    try {
      if (getters.type !== 'existing') {
        await dispatch('handleCompanyBrand', opts);
        await dispatch('assign', opts);
        await dispatch('handleCompanyBrandLogo', opts);
      }

      return true;
    } catch (error) {
      console.error(error);
    }
  },

  assign: async ({ rootGetters, dispatch }, opts = {}) => {
    try {
      let { storeGroupId, companyBrandId, storeId } = opts;

      storeId = storeId || rootGetters['trade-point$create/store'].id;
      storeGroupId = storeGroupId || rootGetters['trade-point$create/storeGroup'].id;
      companyBrandId = companyBrandId || rootGetters['trade-point$create/companyBrand'].id;

      const storeGroup = await companyAPI.updateStoreGroup({
        id: storeGroupId,
        company_brand_id: companyBrandId,
      });
      const store = await companyAPI.bindCompanyBrandToStore({
        id: storeId,
        company_brand_id: companyBrandId,
      });

      if (rootGetters['trade-point$create/storeGroup']) {
        await dispatch('trade-point$create/setStoreGroup', storeGroup, { root: true });
      }

      if (rootGetters['trade-point$create/store']) {
        await dispatch('trade-point$create/setStore', store, { root: true });
      }

      notificationService.sendSuccessSave();
      return true;
    } catch (error) {
      console.error(error);
    }
  },

  setLogoAction: ({ commit }, value) => {
    commit('setLogo', value);
  },

  handleCompanyBrand: async ({ rootGetters, dispatch, getters }, opts = {}) => {
    let { companyBrand, stage = 'create' } = opts;
    companyBrand = companyBrand || rootGetters['trade-point$create/companyBrand'];

    const payload = {
      id: companyBrand ? companyBrand.id : undefined,
      name: getters.name,
    };

    companyBrand = companyBrand
      ? await companyAPI.updateCompanyBrand(payload)
      : await companyAPI.createCompanyBrand(payload);

    if (stage === 'create') {
      await dispatch('trade-point$create/setCompanyBrand', companyBrand, { root: true });
    }
  },

  handleCompanyBrandLogo: ({ getters, rootGetters }, opts = {}) => {
    let { companyBrand } = opts;

    companyBrand = companyBrand || rootGetters['trade-point$create/companyBrand'];
    const { id } = companyBrand;
    const { logo } = getters;

    if (!logo.file) return Promise.resolve();
    return companyAPI.uploadCompanyBrandLogo({ id, type: '', file: logo.img });
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setType: (state, value) => {
    state.type = value;
  },

  _setName: (state, value) => {
    state.name = value;
  },

  setLogo: (state, value) => {
    state.logo = { ...state.logo, ...value };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
