import { companyAPI } from '@/core/api/company';
import { produce } from 'immer';
import { cloneDeep, omitBy } from 'lodash';
import XLSX from 'xlsx';
import { STATUSES } from '@/apps/trade-point/data/statuses';
import { getI18n } from '@/core/i18n';
import { bus } from 'shared/core';
import { hasChanges } from '@/apps/trade-point/utils/changes';
import { notificationService } from '@/core/services/NotificationService';

// TODO: добавить состояние для разных типов файловой интеграции

const initialState = () => ({
  catalogName: '',
  loading: false,
  fileFields: null,
  columns: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  content: null,
  selected: {},
  selectedType: null,
  availableExchangeServiceTypes: [],
  activeService: null,
  serviceFields: {},
  serviceSettings: {},
  serviceErrors: {},
  serviceTests: {},
  serviceFiles: {},
  inputs: {
    category: '',
  },
});

const state = initialState();

const getters = {
  input: (state) => (name) => state.inputs[name],
  serviceStage: (state, getters) => (service) => {
    const serviceErrors = getters.serviceErrors(service);
    const serviceTests = getters.serviceTests(service);

    if (serviceTests) return STATUSES.SUCCESS;
    if (serviceErrors) return STATUSES.ERROR;

    return STATUSES.INIT;
  },
  loading: (state) => state.loading,
  catalogName: (state) => state.catalogName,
  canSkipStage: (state) => {
    if (Object.keys(state.serviceTests).length) return false;
    return true;
  },
  serviceFiles: (state) => (service) => state.serviceFiles[service],
  serviceSettings: (state) => (service) => state.serviceSettings[service],
  serviceErrors: (state) => (service) => state.serviceErrors[service],
  serviceFields: (state) => (service) => state.serviceFields[service],
  serviceTests: (state) => (service) => state.serviceTests[service],
  activeService: (state) => state.activeService,
  availableExchangeServiceTypes: (state) => state.availableExchangeServiceTypes,
  selectedType: (state) => state.selectedType,
  getServiceType: (state) => (service) => {
    switch (service) {
      case 'one_s':
      case 'iiko':
      case 'iiko_biz':
      case 'yml':
      case 'moy_sklad':
      case 'supermag':
      case 'mts_kassa':
      case 'store_house':
      case 'poster':
      case 'ya_badge':
        return 'accounting';

      case 'xlsx':
        return 'file';
    }
  },
  fileFields: (state) => state.fileFields && Object.keys(state.fileFields),
  columns: (state) => state.columns,
  content: (state) => state.content,
  flattenServiceFields: (state, getters) => (service) => {
    const target = getters.serviceFields(service);
    const fields = Object.values(target)
      .flatMap((field) => Object.entries(field))
      .reduce((acc, [key, { value }]) => {
        return {
          ...acc,
          [key]: value,
        };
      }, {});
    return fields;
  },

  hasEmptyServiceRequiredFields: (state, getters) => (service) => {
    const { required } = getters.serviceFields(service);

    const values = Object.keys(required).map((k) => required[k]);

    return values.filter((v) => v).length !== Object.keys(required).length;
  },

  isFileFieldRequired: (state) => (name) => state.fileFields[name].required,
  selected: (state) => state.selected,
  columnLength: (state) => {
    if (!state.content) return 0;

    let len = 0;

    state.content.forEach((row) => {
      if (row.length > len) len = row.length;
    });

    return len;
  },

  localizeService: (state) => (name) => {
    switch (name) {
      case 'one_s':
        return 'service_one_s';

      case 'iiko':
        return 'service_iiko';

      case 'iiko_biz':
        return 'service_iiko_biz';

      case 'yml':
        return 'service_yml';

      case 'moy_sklad':
        return 'service_moy_sklad';

      case 'supermag':
        return 'service_supermag';

      case 'xlsx':
        return 'service_xlsx';

      case 'mts_kassa':
        return 'service_mts_kassa';

      case 'store_house':
        return 'service_store_house';

      case 'poster':
        return 'service_poster';

      case 'ya_badge':
        return 'service_ya_badge';

      default:
        return name;
    }
  },

  localizeFileField: (state) => (name) => {
    switch (name) {
      case 'article':
        return 'article';

      case 'name':
        return 'name';

      case 'price':
        return 'price';

      case 'barcode':
        return 'barcode';

      case 'unit_id':
        return 'unit_id';

      case 'unit_dimension':
        return 'volume';

      case 'quantity':
        return 'quantity';

      case 'category':
        return 'category';
    }
  },

  hasUnsavedChanges: (state) =>
    hasChanges(state, initialState(), ['loading', 'columns', 'availableExchangeServiceTypes']),
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  completeStage: async () => true,

  setInput: ({ commit }, { name, value }) => {
    commit('_setInput', { name, value });
  },

  setCatalogName: ({ commit }, value) => {
    commit('_setCatalogName', value);
  },

  handleUploadedFile: ({ commit }, { service, file }) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const { Sheets, SheetNames } = XLSX.read(data, { type: 'array' });
      commit('_setContent', XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], { header: 1 }));
      commit('_setServiceFiles', { service, value: file });
    };

    reader.readAsArrayBuffer(file);
  },

  handleUploadedItemFile: ({ commit }) => {},

  selectActiveService: ({ commit }, value) => {
    commit('_setActiveService', value);
  },

  flushContent: ({ commit }) => {
    commit('_setContent', null);
    commit('_resetSelected');
  },

  changeServiceStage: ({ commit }, { service, status }) => {
    if (status === STATUSES.INIT) {
      commit('_setServiceErrors', { service, value: null });
    }
  },

  createFileExchange: async ({ commit, getters, rootGetters, dispatch }, opts = {}) => {
    const { service, store, catalogId, updateStoreCatalog = true } = opts;
    const errorOptions = { passive: true };

    if (getters.loading) return;

    try {
      commit('_setLoading', true);
      commit('_setServiceTests', { service, value: null });
      commit('_setServiceErrors', { service, value: null });

      const catalog = catalogId
        ? await companyAPI.getCatalogById({ id: catalogId, errorOptions })
        : await companyAPI.createCatalog({
            name: getters.catalogName,
            currency_code: 'RUB',
            errorOptions,
          });

      commit('catalogs$catalogs/_addcatalog', catalog, { root: true });

      const storeObject = store || rootGetters['trade-point$create/store'];

      if (updateStoreCatalog) {
        const updatedStore = await companyAPI.bindCatalogToStore({
          id: storeObject.id,
          catalog_id: catalog.id,
          errorOptions,
        });

        await dispatch('trade-point$create/setStore', updatedStore, { root: true });
      }

      await dispatch('trade-point$create/setCatalog', catalog, { root: true });

      const file = getters.serviceFiles(service);

      const columns = Object.keys(getters.selected)
        .map((c) => ({ column: c, value: getters.selected[c] }))
        .reduce((acc, v) => {
          acc[v.value] = v.column;
          return acc;
        }, {});

      const result = await companyAPI.uploadExchangeFile({
        file: file.source || file,
        columns,
        catalog_id: catalog.id,
        errorOptions,
      });

      commit('_setServiceTests', { service, value: result });
    } catch (error) {
      commit('_setServiceErrors', { service, value: error.error.message });
    } finally {
      commit('_setLoading', false);
    }
  },

  createServiceExchange: async ({ commit, getters, rootGetters, dispatch }, { service, store }) => {
    const errorOptions = { passive: true };
    if (getters.loading) return;

    if (getters.hasEmptyServiceRequiredFields(service)) return;

    try {
      commit('_setLoading', true);
      commit('_setServiceErrors', { service, value: null });
      commit('_setServiceTests', { service, value: null });

      const catalog = await companyAPI.createCatalog({
        name: getters.catalogName,
        currency_code: 'RUB',
        errorOptions,
      });
      commit('catalogs$catalogs/_addcatalog', catalog, { root: true });

      const storeObject = store || rootGetters['trade-point$create/store'];
      const updatedStore = await companyAPI.bindCatalogToStore({
        id: storeObject.id,
        catalog_id: catalog.id,
        errorOptions,
      });

      await dispatch('trade-point$create/setStore', updatedStore, { root: true });
      await dispatch('trade-point$create/setCatalog', catalog, { root: true });

      await dispatch('lockOrUnlockPlanogram');

      const payload = {
        ...getters.flattenServiceFields(service),
        external_service: getters.activeService,
        catalog_id: catalog.id,
        errorOptions,
      };

      let serviceSettings = getters.serviceSettings(service);

      if (serviceSettings) {
        payload.id = serviceSettings.id;
      }

      // This code down below was fixed by a Back-ender. From here...
      try {
        const freshServiceSettings = serviceSettings
          ? await companyAPI.updateExchangeSettings(payload)
          : await companyAPI.createExchangeSettings(payload);

        commit('_setServiceSettings', { service, value: freshServiceSettings });
        notificationService.sendSuccessSave();
        serviceSettings = getters.serviceSettings(service);

        // const freshServiceTests = await companyAPI.testExchangeSettings({ id: serviceSettings.id });
        // commit('_setServiceTests', { service, value: freshServiceTests });
      } catch (exception) {
        console.log(exception);
        commit('_setServiceErrors', { service, value: exception.error.message });
      }
      // ...to here!!!
    } catch (error) {
      console.error(error);
      const i18n = getI18n();
      commit('_setServiceErrors', { service, value: i18n.t('something_wrong_try_again') });
    } finally {
      commit('_setLoading', false);
    }
  },

  setSelected: async ({ commit }, { name, value }) => {
    commit('_setSelected', { name, value });
  },

  setSelectedType: ({ commit }, value) => {
    commit('_setSelectedType', value);
  },

  setServiceField: ({ commit }, { service, type, field, value }) => {
    commit('_setServiceField', { service, type, field, value });
  },

  fetchServiceFields: async ({ commit, getters }, service) => {
    if (getters.serviceFields[service]) return;

    try {
      const fields = await companyAPI.getExchangeServiceFields({ service });
      commit('_setServiceFields', { service, fields });
    } catch (error) {
      console.error(error);
    }
  },

  fetchAvailableExchangeServiceTypes: async ({ commit, getters }) => {
    if (getters.availableExchangeServiceTypes.length) return;

    try {
      const services = await companyAPI.getExchangeAvailableServices();
      commit('_setAvailableExchangeServiceTypes', services);
    } catch (error) {
      console.error(error);
    }
  },

  loadFileFields: async ({ commit, getters }) => {
    if (getters.fileFields) return;

    try {
      const result = await companyAPI.getExchangeFileFields();
      commit('_setFileFields', result);
    } catch (error) {
      console.error(error);
    }
  },

  checkItems: async ({ rootGetters }) => {
    const store = rootGetters['trade-point$create/store'] || {};
    const catalog_id = store.catalog_id || 0;

    try {
      const { items } = await companyAPI.getItemList({ limit: 1, catalog_id });
      return !!items.length;
    } catch (error) {
      return false;
    }
  },

  lockOrUnlockPlanogram: async ({ dispatch }) => {
    const hasItems = await dispatch('checkItems');
    hasItems
      ? await dispatch('trade-point$create/unlockStage', 'planogram', { root: true })
      : await dispatch('trade-point$create/lockStage', 'planogram', { root: true });
  },

  setCommodityAccounting: async ({ commit, dispatch }, { id, commodityAccounting }) => {
    const payload = {
      id,
      is_enabled: commodityAccounting,
    };
    let resp;
    try {
      resp = await companyAPI.setCommodityAccounting(payload);
    } catch (error) {
      resp = error;
    } finally {
      return resp;
    }
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setInput: (state, { name, value }) => {
    state.inputs = produce(cloneDeep(state.inputs), (draft) => {
      draft[name] = value;
    });
  },

  _setCatalogName: (state, value) => {
    state.catalogName = value;
  },

  _setServiceTests: (state, { service, value }) => {
    state.serviceTests = produce(cloneDeep(state.serviceTests), (draft) => {
      if (!value) delete draft[service];
      else draft[service] = value;
    });
  },

  _setServiceErrors: (state, { service, value }) => {
    state.serviceErrors = produce(cloneDeep(state.serviceErrors), (draft) => {
      draft[service] = value;
    });
  },

  _setServiceSettings: (state, { service, value }) => {
    state.serviceSettings = produce(cloneDeep(state.serviceSettings), (draft) => {
      draft[service] = value;
    });
  },

  _setLoading: (state, value) => {
    state.loading = value;
  },

  _setAvailableExchangeServiceTypes: (state, value) => {
    // value.push('store_house');
    state.availableExchangeServiceTypes = value;
  },

  _setServiceFields: (state, { service, fields }) => {
    if (state.serviceFields[service]) return;

    state.serviceFields = produce(cloneDeep(state.serviceFields), (draft) => {
      let { preset, optional, required } = fields;

      const usedFields = { ...optional, ...required };
      preset = omitBy(preset, (_, key) => {
        return usedFields.hasOwnProperty(key);
      });

      draft[service] = { required, optional, preset };
    });
  },

  _setServiceFiles: (state, { service, value }) => {
    state.serviceFiles = produce(cloneDeep(state.serviceFiles), (draft) => {
      draft[service] = value;
    });
  },

  _setServiceField: (state, { service, type, field, value }) => {
    state.serviceFields = produce(cloneDeep(state.serviceFields), (draft) => {
      draft[service][type][field].value = value;
    });
  },

  _setSelectedType: (state, value) => {
    state.selectedType = value;
  },

  _setFileFields: (state, value) => {
    state.fileFields = value;
  },

  _setContent: (state, value) => {
    state.content = value;
  },

  _setSelected: (state, { name, value }) => {
    state.selected = produce(cloneDeep(state.selected), (draft) => {
      draft[name] = value;
    });
  },

  _resetSelected: (state) => {
    state.selected = {};
  },

  _setActiveService: (state, value) => {
    state.activeService = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
