import { companyAPI } from '@/core/api/company';
import { InfinityList } from '@/core/utils/infinityList';
import { notificationService } from '@/core/services/NotificationService';

const state = {
  discountGroupList: new InfinityList(companyAPI.getDiscountGroups),
};

const getters = {
  discountGroups: (state) => state.discountGroupList.getList(),
  discountGroupsMeta: (state) => state.discountGroupList.getMeta(),
};

const actions = {
  getDiscountGroups: ({ state }) => {
    return state.discountGroupList.fetch();
  },

  loadMoreDiscountGroups: async ({ state }) => {
    return state.discountGroupList.loadMore();
  },

  getOneDiscountGroup: async (_, opts = {}) => {
    const { id } = opts;

    try {
      return await companyAPI.getDiscountGroupById({ id });
    } catch (error) {
      console.log(error);
    }
  },

  createOrUpdateDiscountGroup: async (_, opts = {}) => {
    const { image, discountGroupInfo } = opts;

    const discountGroup = discountGroupInfo.id
      ? await companyAPI.updateDiscountGroup(discountGroupInfo)
      : await companyAPI.createDiscountGroup(discountGroupInfo);

    if (image) {
      await companyAPI.uploadDiscountGroupImage({ file: image, id: discountGroup.id });
    }
    notificationService.sendSuccessSave();
  },

  deleteDiscountGroupById: async ({ commit }, opts = {}) => {
    const { id } = opts;
    try {
      await companyAPI.deleteDiscountGroup({ id });
      commit('_updateDeletedDiscountGroup', { id });
      notificationService.sendSuccessDelete();
    } catch (error) {
      console.log(error);
    }
  },

  deleteDiscount: (_, id) => {
    return companyAPI.deleteDiscount({ id }).then(notificationService.sendSuccessDelete);
  },
};

const mutations = {
  _updateDeletedDiscountGroup: (state, { id }) => {
    state.discountGroupList.removeItem(id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
