export default {
  valid_upload_both_photos: 'Prenesite obje slike',
  mailing_status_rejected: 'Odbijen',
  catalog: 'Katalog',
  orders: 'Narudžbe',
  sales: 'Prodajni',
  delivery: 'Dostava',
  catalogs_and_categories: 'Katalozi i kategorije',

  filter_gender: 'Spol',
  filter_age: 'Dob',
  filter_registered_days_ago: 'Datum registracije',
  filter_purchases_quantity: 'Broj kupnji',
  filter_purchase_days_ago: 'Dob kupnji',
  filter_birth_date_month: 'Rođendan',

  gender_male: 'Muški',
  gender_female: 'Žena',

  before_num: 'Ispod {num}',
  older_then: 'Stariji od {num}',

  before_days: '{_} dan | do {_} dana | do {_} dana',
  year_ago: 'Prije godinu dana',

  more_then: 'Više {num}',

  in_this_month: 'U ovom mjesecu',
  in_next_month: 'U sljedećem mjesecu',

  select_employee_for_test_message: 'Odaberite zaposlenika koji će poslati testnu poruku',
  filter_will_be_available_soon: 'Ova će funkcija uskoro biti dostupna',

  documents_subtitle: `U ovom odjeljku morate se upoznati s pravilima rada platforme i ostalim pravnim dokumentima. <br /> <br /> Također, prikazuje trenutni status dokumenata potrebnih za ispravnu interakciju između vas i Brisklyja. <br/> Uključujući to, moguće je daljinski potpisati brojne dokumente pomoću funkcije potvrde SMS-a.`,

  reports_subtitle: `Odjeljak "Izvješća" omogućuje vam izradu vizualnih analitičkih izvješća o vašem poslovanju na tehnološkoj platformi b: pay. <br /> Dobijte jasne, pouzdane informacije o parametrima i uzorcima koji vas zanimaju, a na temelju tih podataka poboljšajte svoje poslovne procese, povećajte učinkovitost prodajnih mjesta i povećajte svoj prihod!`,

  videos_subtitle: `U ovom odjeljku možete dobiti video snimke s kamera instaliranih na vašim prodajnim mjestima`,

  support_service_greeting: 'Zdravo! Rado ćemo vam pomoći!',
  homeTitle: 'Početni zaslon',
  homeSubtitle: 'Na ovom ekranu će uskoro biti statistika prodaje u vašim prodajnim mjestima',
  homeSubtitleWithStat: 'Na ovom ekranu se prikazuje statistika prodaje u vašim prodajnim mjestima',
  homeReferalProgramNote:
    'Koristite sama, pozovi prijatelja. Čim je on registrirao u sustav,↵ dobiti кэшбэк od Briskly u iznosu od % od',
  referalProgram: 'Upućivanje program',
  trade_points: 'Prodajna mjesta',
  on_map: 'Na karti',
  do_add_trade_point: 'Dodaj prodajnog mjesta',
  bind: 'Vezati',
  doesnt_select: 'Nije izabran',
  select_dates: 'Odaberite datum',
  trade_point_list: 'Popis prodajnih mjesta',
  extended_types_washer_fluid: 'Омывающая tekućina',
  number: 'Soba',
  cost: 'Cijena',
  id: 'ID',
  do_open: 'Otvori',
  do_close: 'Zatvori',
  do_search: 'Traži',
  results: 'Rezultati',
  by_list: 'Popisom',
  name: 'Naziv',
  continue: 'Nastavak',
  revenue: 'Prihodi',
  averageCheck: 'Prosječna Ček',
  save: 'Spremi',
  skip: 'Preskočiti',
  metr: 'm',
  km: 'km',
  can_come_back_later: 'Možete se vratiti na taj korak kasnije',
  million_short: 'milijuna',
  do_select: 'Odabrati',
  thousand_short: 't',
  do_change: 'Promijeniti',
  first_name: 'Naziv',
  required_fields: 'Obavezna stupce',
  do_repeat: 'Ponoviti',
  do_check: 'Provjerite',
  last_name: 'Prezime',
  middle_name: 'Prezime',
  trade_point: 'Prodajno mjesto',
  trade_point_count: 'Broj bodova',
  role: 'Uloga',
  next_step: 'Sljedeći korak',
  yes: 'Da',
  no: 'Nema',
  do_skip_and_finish: 'Propustiti i završiti',
  do_end_registration: 'Dovršili registraciju',
  do_skip_and_end_registration: 'Propustiti i završiti registraciju',
  from: 'Od',
  before: 'Do',
  do_cancel: 'Poništi',
  odustani: 'Otkazivanje',
  format: 'Format',
  do_download_file: 'Preuzimanje datoteka',
  total: 'Ukupno',
  pick_or_input: 'Odaberite opciju ili da nastavite pisanje',
  search: 'Traži',
  step: 'Korak',
  all: 'Sve',
  price: 'Cijena',
  here: 'Ovdje',
  go_to: 'Idi',
  price_with_currency2: 'Cijena ₽*',
  do_create: 'Stvoriti',
  active_status_yes: 'Aktivan',
  active_status_no: 'Nije aktivan',
  pack_min: 'pak',
  go_to_page: 'Idi na stranicu',
  do_send: 'Pošalji',
  yesterday: 'Jučer',
  today: 'Danas',
  you: 'Vi',
  main_page: 'Početna',
  logout: 'Izlaz',
  shortcut_week: '7 dana',
  shortcut_month: '31 dan',
  among: 'Od',
  time_zone: 'Vremenska zona',
  pick_time_zone: 'Odaberite vremensku zonu',
  ready: 'Gotovo',
  extended_types_store: 'Shop',
  extended_types_refrigerator: 'Hladnjak',
  extended_types_store_online: 'Internet-Trgovina',
  extended_types_cafe: 'Kafić',
  extended_types_production_with_delivery: 'Proizvodnja, dostava',
  extended_types_filling_station: 'BENZINSKE postaje',
  extended_types_cigarettes: 'Cigarete ormar',
  extended_types_coffee_machine: 'Aparat za kavu',
  stage_type_title: 'Dodajte prodajnog mjesta',
  stage_type_edit_title: 'Osnovni podaci',
  stage_type_subtitle: 'Odaberite tip prodajnog mjesta',
  stage_type_description:
    'Izaberite svoju kategoriju: trgovina, kafić ili neki drugi iz predloženog popisa. Pročitajte više o mogućnostima svakoj kategoriji ovdje',
  stage_type_label_phone: 'Telefon prodajnog mjesta',
  address: 'Adresa',
  stage_type_label_floor: 'Kat',
  stage_type_store_radius: 'Radijus akcije↵sustava Scan&amp;Go',
  stage_type_refrigerator_radius: 'Radijus s kojeg će↵otvoriti vrata',
  stage_type_cigarettes_radius: 'Radijus s kojeg će↵otvoriti vrata',
  stage_type_filling_station_radius: 'Radijus akcije↵sustava Briskly',
  stage_type_coffee_machine_radius: 'Radijus akcije↵aparate',
  stage_design_description:
    'Zatim, kako će izgledati vaš prodajno mjesto u dodatku B-Pay. Ili preuzmite logo. On će se pojaviti u popisu robe',
  stage_design_label_select: 'Odaberite dizajn',
  stage_design_create_new: 'Stvoriti novi dizajn',
  usluge: 'Sporazum',
  promo_briskly: 'Promo materijali Briskly',
  gorivo: 'Gorivo',
  date_input_placeholder: 'dd.mm.gggg',
  finish_registration: 'Dovršite registraciju',
  purchases_quantity: 'Broj nabave',
  dobit: 'Prihod',
  do_open_new_trade_point: 'Otvori novu točku',
  support: 'Korisnička podrška',
  profile: 'Osobni kabinet',
  error_modal_title: 'Nije moguće aktivirati prodajnog mjesta',
  design_title: 'Dizajn',
  card_is_missing: 'Bez veze',
  your_logo: 'Vaš logo',
  your_logo_in_format: 'Vaš логитип u formatu',
  preview_for_app: 'Preglede za trgovinu u programu',
  enter_text: 'Unesite tekst',
  pick_background: 'Odaberite pozadina',
  little_background: 'Pozadina za mali pregled u obliku',
  big_background: 'Pozadina za veliki pregled u obliku',
  do_change_preview: 'Promijeni pregled',
  mock_address: 'g. Moskva, str. Akademik Янгеля, dv 26',
  store_photos: 'Foto prodajnog mjesta',
  schedule: 'Raspored',
  schedule_title: 'Raspored',
  work_schedule: 'Raspored rada',
  schedule__description:
    'Postavite raspored rada vašeg hladnjaka: u ono vrijeme on se otvara za kupovinu, a u ono vrijeme je blokiran.',
  do_download_from_template: 'Učitaj iz predloška',
  start_day_after_visiting_courier: 'Početi dan nakon posjeta kurira',
  save_as_template: 'Spremi kao predložak',
  do_enter_name: 'Unesite ime',
  week_mon: 'Mo',
  week_tue: 'W',
  week_wed: 'Srijeda',
  week_thu: 'Čet',
  week_fri: 'Pet',
  week_sat: 'Sat',
  week_sun: 'Sunce',
  week_all_day: 'Sve dane',
  permanently: 'Sat',
  weekend: 'Izlaz',
  do_add_break: 'Dodaj break',
  schedule_from: 's',
  schedule_to: 'na',
  templates: 'Predlošci',
  create_employees_title: 'Dodavanje osoblja',
  employees_description:
    'Na ovom zaslonu možete dodati osoblja prodajnog mjesta, odabrati s popisa ili заносите nove podatke. Dogovorite im uloge prema dužnosti. Zadani voditelj povezan sa svim ulogama. Pročitajte više u temi pomoći.',
  pick_employees: 'Odaberite zaposlenika',
  delete_employee_modal_title: 'Vi ćete ukloniti zaposlenika',
  employees_selected: 'Odabrano',
  employee_change_modal_title: 'Promjena podataka',
  employee_create_modal_title: 'Dodavanje zaposlenika',
  employee_change_modal_role_title: 'Dodijelite ulogu na этойт prodajnom mjestu',
  employee_create_modal_role_title: 'Odaberite uloge',
  add_employee: 'Dodaj zaposlenika',
  role_administrator_name: 'Administrator',
  role_administrator_access: 'Pristup svemu funkcionalne osobnih računa',
  role_courier_name: 'Kurir',
  role_courier_access:
    'Dostupna mogućnost za otvaranje hladnjak, mijenjati robu, napraviti fotografije, preuzimanje fotografija u sučelje kurira',
  role_checker_name: 'Provjera',
  role_checker_access:
    'Uzima narudžbe od kupaca i okuplja ih u prodajnom prostoru za slanje klijentu',
  employees: 'Osoblje',
  to_add_new_employee: 'Dodavanje novog zaposlenika',
  requisites_title: 'Rekviziti',
  requisites_description:
    'Dodajte podatke o vašoj prodajnog mjesta. Oni su potrebni za slanje novca na vaš tekući račun. Podaci tvrtke će biti naznačeno prilikom izdavanja računa kupcima.',
  requisites_single_description:
    'Ovaj odjeljak sadrži sve detalje o vašim trenutnim prodajnim mjestima, a možete dodati i nove. To je potrebno za slanje novca na tekući račun i prilikom izdavanja čeka kupcima. Prihod od prodaje prenosi se na tekući račun jednom dnevno.',
  pick_requisites: 'Odaberite rekvizite',
  requisites_company_info: 'Podaci o tvrtki',
  requisites_client_info: 'Podaci o kupcu',
  requisites_fio: 'Puni NAZIV',
  requisites_phone: 'Broj telefona',
  requisites_mail: 'E-pošta',
  requisites_account_number: 'Broj računa',
  requisites_current_account_number: 'Broj tekućeg računa',
  requisites_bank_bic: 'BIK banke',
  requisites_bic: 'BIK',
  add_new_requisites: 'Dodaj novi rekviziti',
  adding_new_requisites: 'Dodavanje novih rekvizita',
  requisites_cheque: 'Novčani ček',
  requisites_non_cash: 'Безналичными',
  requisites_buyer_phone: 'Telefon kupca',
  requisites_items: 'Roba',
  requisites_item: 'Proizvod',
  requisites_provider_info: 'Podaci prodavatelja',
  requisites_name: 'Naziv',
  requisites_inn: 'INN ili naziv pravne osobe',
  requisites_step1: 'Vrsta zaposlenja',
  requisites_step2: 'Osobni podaci',
  requisites_step2_legal: 'Osobne podatke voditelja',
  requisites_step3: 'Rekviziti organizacije',
  requisites_step4: 'Podaci o plaćanju',
  requisites_pick_employment_type: 'Odaberite vrstu zapošljavanja',
  employment_type_individual: 'IP',
  employment_type_legal: 'Organizacija',
  requisites_position: 'Post (kao i u povelji)',
  passport_first_page: 'Prva stranica pasoša',
  passport_second_page: 'Stranica s prebivalištem',
  passport_series: 'Serija',
  passport_number: 'Soba',
  passport_issue_date: 'Datum izdavanja',
  passport_authority_code: 'Kod jedinice',
  passport_authority_name: 'Naziv postrojbe koja je izdala dokument',
  passport_citizenship: 'Državljanstvo',
  passport_registration_place: 'Registracija adresa (kao u putovnici)',
  passport_birth_place: 'Mjesto rođenja',
  passport_birth_date: 'Datum rođenja',
  requisites_capital: 'Temeljni kapital, ₽',
  requisites_tax_system: 'Odaberite sustav oporezivanja',
  tax_system_general: 'D',
  tax_system_simplified_1: 'USN (prihodi-rashodi)',
  tax_system_simplified_2: 'USN (prihodi-6%)',
  requisites_org_phone: 'Telefon organizacije',
  requisites_company_iname: 'Naziv tvrtke',
  requisites_okopf_code: 'OKOPF',
  requisites_ogrn: 'OGRN',
  requisites_okved_code: 'OKVED',
  requisites_bank_account_info: 'Podaci o bankovnom računu',
  requisites_cheque_hint: 'Sada možete pogledati kako će izgledati ček vaše prodajno mjesto.',
  requisites_recipient_bank_name: 'Banka primatelja',
  requisites_bank_correspondent_account: 'Corr. račun',
  requisites_missing: 'Nema',
  want_to_deliver: 'Želite isporučiti svoje proizvode putem svoje ili naše isporuku?',
  delivery_own_radius: 'Radijus vlastite isporuke',
  delivery_briskly_radius: 'Radijus isporuke partnerima Briskly',
  delivery_own: 'Vlastita dostava',
  delivery_briskly: 'Dostava partnerima Briskly',
  delivery_both: 'Obje opcije',
  delivery_own_tariffs: 'Cijene na vlastitu isporuci',
  delivery_briskly_tariffs: 'Cijene po isporuci partnerima briskly',
  delivery_briskly_description:
    'Imajte na umu, ako je instaliran vama troškovi isporuke za kupca će biti manja od stvarne vrijednosti, na kraju meseca vam dođe račun za plaćanje ove razlike. Ako je vaš trošak će biti više, onda će doći novac u iznosu od ovog razlike.',
  delivery_briskly_price: 'Troškovi dostave partnerima Briskly',
  delivery_pickup: 'Mogućnost preuzimanja',
  delivery_tariffs: 'Cijene po isporuci',
  order_sum: 'Iznos narudžbe',
  delivery_cost: 'Troškovi isporuke',
  delivery_invalid_rates_text:
    'Intervali iznose narudžbe moraju biti drugačiji, između njih mora razlika biti veća od 1 rublje.',
  do_add_rate: 'Dodaj uvjet',
  delivery_warning_modal_title: 'Namjeravate promijeniti način isporuke',
  delivery_warning_modal_body: 'Nakon spremanja, prethodni način dostave će biti uklonjene',
  delivery_cost_free: 'Besplatno',
  delivery_cost_discount_percent: 'Popust od cijene dostave u postocima',
  delivery_cost_discount_fixed: 'Popust od utovar cijene u eurima',
  delivery_cost_fixed: 'Fiksna cijena',
  delivery_cost_unknown: 'Dostavu plaća kupac',
  offer: 'Ponuda',
  do_download_offer: 'Preuzmite ponudu',
  do_sign_document: 'Potpisati dokument',
  code_from_sms: 'Koda iz sms',
  try_again_in: 'Ako kod nije došao, pokušajte ponovno kroz',
  in_seconds: 'sekundi',
  do_send_code_again: 'Pošalji kôd ponovno',
  do_send_confirmation_code: 'Pošalji kôd za potvrdu',
  planogram: 'Planogram',
  planogram_block_hint: 'Potrebno je stvoriti direktorij',
  will_be_deleted: 'Bit će uklonjeni',
  planogram_photo: 'Foto planogram',
  do_add_items_on_shelf: 'Dodajte proizvode na policu',
  your_micromarket: 'Vaš микромаркет',
  shelf: 'polica',
  shelf_is_overflow: 'Na ovoj polici više ne stane',
  do_add_items_on_another_shelfs: 'dodajte proizvode na druge police',
  do_add_shelf: 'dodaj policu',
  going_to_remove_all_items: 'Vi ste собираетсь izbrisati svi proizvodi s polica',
  going_to_remove_shelf: 'Vi ste собираетсь uklonite policu',
  planogram_item: 'proizvod',
  planogram_list: 'Popis planogram',
  do_download_new_planogram: 'Skinuti novu планограмму',
  group_by: 'Grupa na',
  period: 'Razdoblje',
  order_status: 'Status narudžbe',
  pick_store: 'Odaberite shop',
  option_selection_by_number: 'Uzorkovanje na broj',
  paid: 'Platili',
  processed: 'Obrađen',
  povrat: 'Povrat',
  refund_awaiting: 'U očekivanju povratka',
  refund_error: 'Greška povratak',
  removed: 'Obrisan',
  količina: 'Broj',
  year: 'Godine',
  id_local: 'BAJRAM',
  order_number: 'Broj naloga',
  client: 'Klijent',
  item_quantity: 'Količina robe',
  items_sufix: 'robe',
  komisija: 'Komisija',
  income: 'Dobit',
  item_price: 'Cijena za robu',
  period_start: 'Početak razdoblja',
  period_end: 'Kraj razdoblja',
  sum_with_discount: 'Iznos sa popustom',
  sum: 'Iznos',
  narudžbe: 'Narudžbe',
  clients: 'Klijenti',
  unique_clients: 'Jedinstveni dan klijenti',
  prodaja: 'Prodaja',
  completed_order: 'Završio nalog',
  refund_awaiting_from_client: 'Očekuje se doplata od klijenta',
  service_one_s: '1C',
  service_iiko: 'Айко',
  service_iiko_biz: 'Айко biz',
  service_moy_sklad: 'Moj Skladišta',
  service_supermag: 'Супермаг',
  service_yml: 'YML',
  service_xlsx: 'XLSX',
  unit_id: 'OKEI (Jedinica. Za mjerenje)',
  something_wrong_try_again: 'Da je nešto pošlo po zlu. Pokušajte opet.',
  use_suggestions_begin: 'Iskoristite naše',
  use_suggestions_action: 'upute',
  use_suggestions_end: 'za integraciju sustava',
  payment_description:
    'Pošaljite upit za dodavanje način plaćanja odgovorno lice trgovine, gdje će biti postavljena cigarete ormar.',
  send_request: 'Pošalji upit',
  do_change_payment_method: 'Promijeniti način plaćanja',
  current_card: 'Trenutni karta',
  follow_us_begin: 'Se pretplatite na nas',
  follow_us_end: 'putem društvenih mreža',
  bpay_title: 'Preuzmite dodatak b-pay',
  bpay_subtitle: 'Kupite bez čekanja',
  checker_title: 'Koristite aplikaciju CHECK-ER',
  checker_subtitle: 'Prikupiti vaše narudžbe kupca na daljinu',
  valid_required: 'Nužno',
  valid_article_required: 'Broj artikla obavezan',
  valid_min_3: 'Minimalno 3 znaka',
  valid_min_1: 'Najmanje 1 znak',
  valid_min_6: 'Minimalno 6 znakova',
  valid_max_60: 'Maksimalno 60 znakova',
  valid_max_5: 'Najviše 5 znakova',
  valid_max_6: 'Najviše 6 znakova',
  valid_max_13: 'Najviše 13 znakova',
  valid_max_20: 'Maksimalno 20 znakova',
  valid_max_32: 'Maksimalno 32 znaka',
  valid_max_350: 'Maksimalno 350 znakova',
  valid_special_symbols_not_allow: 'Спецсимволы nisu dozvoljeni',
  valid_mail_format: 'E-pošta u formatu: welcome@brskl.io',
  valid_phone_format: 'Telefon u formatu +7 (123) 456 78 90',
  valid_no_less_1_percent: 'Ne manje od 1 %',
  valid_no_more_99_percent: 'Ne više od 99 %',
  module_tittle: 'Modul',
  briskly_module: 'Briskly Modul',
  module_description:
    'Briskly modul — to je mozak vašeg hladnjaka. Prilikom kupovine vam dali kod koji je potrebno vezati na računu. Kod možete pogledati',
  module_to_buy:
    'Ako još niste platili Hladnjak (hladnjak + Briskly Modul) ili odvojeno Briskly Modul za vašeg hladnjaka,',
  module_link_to_buy: 'učinite to sada',
  indicate_divice_code: 'Unesite kod sa uređaja',
  module_data: 'Podaci o modulu Briskly',
  network_status: 'Status mreže',
  working: 'Radi',
  not_working: 'Ne radi',
  lock_status: 'Status dvorca',
  lock_close: 'Zatvoren',
  lock_open: 'Otvoren',
  air_temp: 'Temperatura zraka',
  last_time_in_network: 'Posljednji put na mreži',
  lock_control: 'Upravljanje bravom',
  module_error_title: 'Informacije nisu dostupne. To bi se moglo dogoditi zbog',
  module_check: 'Provjerite modul',
  buy_module: 'Kupnja Modula Briskly',
  to_retry: 'Pokušajte ponovo',
  setup_module_in_you_ref: 'Instalacija sustava u vaš hladnjak',
  smart_ref_all_options: 'Inteligentni frižider ključ u ruke""',
  popular_format: 'Popularni format',
  big_market_with_all_options: 'Veliki kiosk ključ u ruke""',
  lock_mount: 'Instalacija br. dvorac',
  connect_to_b_pay: 'Povezivanje na programu B-Pay',
  goods_integration: 'Integracija asortimana',
  temperature_sensor: 'Senzori temperature',
  spy_camera: 'Nadzorne',
  refregerator_in_set: 'Hladnjak u paketu',
  trade_point_branding: 'Branding hladnjaka',
  electronic_lock: 'Elektronska brava',
  refregerator_with_two_doors: 'Hladnjak sa dvije vrata kako bi se omogućilo',
  module_apply: 'Podnijeti zahtjev',
  left_a_little: 'Ostalo malo',
  fill_in_the_required_sections_for_your_tt_start_working:
    'Molimo vas da ispunite obavezna particije, da se vaše prodajno mjesto je počeo raditi',
  continue_filling: 'Nastavi punjenje',
  copy_link: 'Kopirajte link',
  lock: 'Dvorac',
  last_check: 'Posljednja provjera',
  stages_description_delivery: 'Odaberite jednu od mogućnosti isporuke',
  stages_description_design:
    'Sada je vaš trgovinski točka u dodatku B-pay izgleda standardno. Kako ističu, možete promijeniti dizajn',
  stages_description_employees: '',
  stages_description_exchange:
    'Preuzmite svoje proizvode u katalog, kako bi kupci mogli da ih vide.',
  stages_description_briskly_module:
    'Svi zaštitni točke se prikazuju u sustavu Briskly samo nakon povezivanja Briskly Modula.',
  stages_description_offer:
    'Morat ćete potpisati ugovor za podnošenje ponude, za uživanje Briskly kabinet',
  stages_description_planogram:
    'Postavite ga na policama hladnjaka robe, kako će ih vidjeti vaši klijenti.',
  stages_description_requisites: 'Status: provjeravaju.↵Provjera traje 1-3 radna dana.',
  stages_description_schedule: 'Da trading point radio, ispunite polja rasporeda.',
  stages_description_type: '',
  stages_description_fuel: '',
  stages_description_payment:
    'Klijent još uvijek nije spojen karticu za plaćanje.↵ Kopirajte link i pošaljite ga ukoliko to još niste učinili.',
  trade_point_registation_complite: 'Registracija je dovršena',
  congratulations_your_tt_is_ready_to_go: 'Čestitamo! Vaša trgovačka točka spreman za rad',
  fine: 'Odličan',
  congratulations_your_tt_is_ready_we_need_check:
    'Čestitamo! Da li ste završili registraciju! Sada nam je potrebno 1-3 radna dana da provjerite svoje rekvizite. Nakon toga od vas će biti otvoren pristup prodajnom mjestu.',
  download_qr_code: 'Preuzmite QR kod ',
  download_sticker: 'Skinuti etiketu',
  download_table_banner: 'Preuzimanje stolni banner',
  do_edit_sufix: 'Uređivanje',
  delete_trade_point: 'Ukloniti prodajnog mjesta',
  this_action_cant_canceled: 'To će dovesti do безвозвратному uklanjanje',
  employees_title: 'Osoblje',
  planogram_title: 'Planogram',
  payment_method: 'Način plaćanja',
  payment_status: 'Status plaćanja',
  temperature_setup: 'Podešavanje temperature',
  temperature_setup_title:
    'Prilagodite temperaturu rada vašeg hladnjaka. Kada je temperatura unutar hladnjaka će izaći za koje ste vi odredili granice, poslat ćemo vam obavijest.',
  time_of_ordering: 'Vrijeme za narudžbu',
  Video: 'Video',
  video_by_order: 'Video za narudžbu',
  pick_date: 'Odaberite datum',
  video_recording_date: 'Datum snimanja video',
  video_not_found: 'Video nije pronađen',
  download: 'Preuzimanje',
  is_signet: 'Potpisan',
  documents: 'Dokumenti',
  is_doesnt_signed: 'Nije potpisan',
  is_touched: 'Pregledan',
  to_assign: 'Potpisati',
  time_stamp: 'Vrijeme dodavanja',
  type: 'Vrsta',
  offer_for_partners: 'Ponuda za partnere',
  tariff_plans: 'Tarifne planove',
  personal_account_rules: 'Pravila korištenja LUKA',
  reports: 'Izvješća',
  report_about_clients: 'Izvješće o klijentima',
  report_about_items: 'Izvješće robe',
  report_about_orders: 'Statistika o narudžbama',
  report_about_sales: 'Statistika prodaje',
  report_about_unique_clients: 'Statistika po jedinstvenim dnevnih klijentima',
  do_download_data: 'Preuzimanje podataka',
  do_download_promo_briskly: 'Download promo materijala Briskly',
  download_file_format: 'Format download',
  your_account_has_run_out_of_money: 'Na vašem računu ponestane novca.',
  do_top_up_an_account: 'Napuniti ravnotežu',
  air_temperature: 'Temperatura zraka',
  inside: 'Unutar',
  outside: 'Izvana',
  status: 'Status',
  actual_address: 'Poštanska adresa',
  card: 'Karta',
  phone: 'Telefon',
  credentials: 'Rekviziti',
  dostava: 'Dostava',
  do_edit: 'Uređivanje',
  most_popular_items: 'Popularni proizvodi',
  item_name: 'Naziv proizvoda',
  price_with_currency: 'Cijena ₽',
  revenue_with_currency: 'Prihodi, ₽',
  sold_items_with_amount: 'Prodano, kom',
  option_unknown: 'Nije instaliran',
  option_min: 'Minimalni',
  option_max: 'Maksimalni',
  option_discount_percent: 'Popust u postotku',
  option_discount_amount: 'Popust u eurima',
  option_discount_new_price: 'Nova cijena',
  option_usage_common: 'Ukupni popust',
  option_usage_first_order: 'Prva kupnja bez vremena',
  option_usage_first_order_after_timestamp: 'Prva kupnja nakon određenog vremenskog ograničenja',
  discount_size: 'Iznos popusta',
  discount: 'Popust',
  excluded_cost: 'Исключенная cijene',
  applying_features: 'Mogućnosti primjene',
  applying: 'Primjena',
  do_add_discount: 'Dodaj popust',
  editing_of_discount: 'Uređivanje popusti',
  adding_of_discount: 'Dodavanje nove popusti',
  applying_order: 'Redoslijed primjene',
  save_changes: 'Spremi izmjene',
  do_save: 'Spremi',
  excluded_cost_tooltip:
    'Iznos na čeku na koju ne djeluje popust. ↵Primjer: 10% popusta na kupnju iznad 500руб (исключенная vrijednost 500р) ↵Iznos kupnje 900р popust od 10% djeluje na 400р i da će 40р.',
  client_show_settings: 'Postavke prikaza za klijente',
  do_show: 'Preskočiti',
  title: 'Naslov',
  description: 'Opis',
  image: 'Slika',
  add_image_proposal: 'Dodajte sliku',
  adding_of_discount_group: 'Dodavanje skup popusta',
  editing_of_discount_group: 'Uređivanje skup popusta',
  do_add_discount_group: 'Dodajte skup',
  do_add_discount_group_v2: 'Dodajte skup popusta',
  do_assign_discount_group_to_store: 'Dodijeliti popusti prodajnim mjestima',
  discounts: 'Popusti',
  do_delete: 'Izbrisati',
  not_delete: 'Ne Izbriši',
  do_reset_changes: 'Poništi',
  you_are_trying_to_delete_discount: 'Vi ćete ukloniti popust',
  you_are_trying_to_delete_discount_group: 'Vi ćete ukloniti skup popusta',
  discount_assign: 'Dodjeljivanje popusta',
  store_name: 'Naziv prodajnog mjesta',
  discount_group: 'Skup popusta',
  title_for_clients: 'Naslov za klijente',
  is_enabled: 'Uključen',
  is_disabled: 'Isključen',
  is_enabled_female: 'Uključena',
  is_disabled_female: 'Isključen',
  show_for_clients: 'Prikaži za klijente',
  type_of_discount: 'Vrsta popusta',
  set: 'Set',
  around_the_clock: 'Sat',
  doesnt_act: 'Ne djeluje',
  good_photo: 'Slika proizvoda',
  volume: 'Količina',
  step_reminder: 'Možete se vratiti na taj korak kasnije.',
  category: 'Vrsta',
  categories: 'Kategorije',
  category_sufix: 'Kategorija',
  create_catalog: 'Kreirajte direktorij',
  create_catalog_label:
    'Na računu može biti nekoliko direktorija (npr. za hladnjaka prodaje gotovih jela ili prodaje smrznute proizvode). Birajući odgovarajući direktorij, možete brzo prebaciti s jednog popisa robe na drugi. Ovdje je potrebno stvoriti ovaj katalog.',
  catalog_name: 'Naziv direktorija',

  portion: 'porcija',

  non_tax: 'Ne plaća se porez',
  payment: 'Naselje',
  standard: 'Standardni',
  altogether: 'Ukupno',
  units: 'Mjerna jedinica',
  age_limit: 'Dobne ograničenje,godina',
  composition_of_item: 'Sastav',
  calories: 'Kalorije',
  optional_description: 'Opis (obavezno)',
  vat_mode: 'Režim PDV-a',
  vat_rate: 'Stopa PDV-a, %',
  block_with_modifications: 'Blok s модами',
  item_add_action: 'Dodavanje proizvoda',
  item_edit_action: 'Uređivanje robe',
  dimension: 'Dimenzije',
  item_add_action2: 'Dodajte proizvode',
  add_and_edit_catalog: 'Dodavanje/uređivanje kataloga',
  need_select_catalog: 'Potrebno je odabrati direktorij',
  do_add_more_categories: 'Dodaj još kategoriju',
  goods_name: 'Naziv robe',
  expiration_date: 'Rok trajanja',
  barkod: 'Barkod',
  sticker_quantity: 'Količina naljepnica',
  manufacturing_by: 'Proizvedeno',
  h: 's',
  d: 'd',
  m: 'm',
  y: 'g',
  composition: 'Sastav',
  do_not_specifiedSuffix: 'Ne указанна',
  do_not_specified: 'Nije naveden',
  fats: 'Masti',
  proteins: 'Proteini',
  carbohydrates: 'Ugljikohidrati',
  calories_full: 'Energetska vrijednost',
  ccal: 'kcal',
  nutritional_value_per_100g_of_product: 'Prehrambena vrijednost(na 100гр proizvoda)',
  at_the_goods: 'Kod proizvoda',
  with_barcode: 'S баркодом',
  error_with_bar_code: 'Greška s generacije Баркода!',
  maybe_doest_valid_control_digit: 'Možda: Netočan kontrolni broj!',
  add_goods: 'Dodaj robe',
  do_delete_category: 'Izbriši kategoriju',
  be_deteted: 'Bit će uklonjeni',
  need_detals: 'Treba pojasniti',
  good_with_no_valid_barcode_need_write_it: 'Kod ovog proizvoda ne валидный Bar kod, ↵ ispunite ga',
  go_to_edit: 'Idi u uređivanje',
  later: 'Kasnije',
  good_suffix: 'proizvod',
  article: 'Broj artikla',
  photo: 'Foto',
  dimension_units: 'Jedinica. mjerenje',
  active: 'Aktivan',
  sticker: 'Naljepnica',
  items: 'Roba',
  do_create_catalog: 'Stvoriti direktorij',
  do_create_new_catalog: 'Stvoriti novi direktorij',
  stickers_print: 'Ispis naljepnica',
  write_name_of_new_catalog: 'Unesite naziv novog kataloga',
  write_name_of_new_category: 'Unesite naziv nove kategorije',
  do_add: 'Dodaj',
  do_go_back: 'Pre',
  do_continue: 'Nastavak',
  katalog: 'Katalog',
  doesnt_have_category: 'Nema kategorije',
  stickers: 'Naljepnice',
  do_print_stickers: 'Ispisati naljepnice',
  manufacturer_name: 'Naziv proizvođača',
  manufacturing_address: 'Adresa proizvodnje',
  production_date: 'Datum proizvodnje',
  time: 'Vrijeme',
  hours: 'Sat',
  minutes: 'Minuta',
  days: 'Dana',
  weeks: 'Tjedna',
  months: 'Mjeseci',
  years: 'Godina',
  do_apply: 'Primijeniti',
  general_expiration_date: 'Opći rok trajanja',
  amount_of_stickers_per_position: 'Količina naljepnica za jednu poziciju',
  do_mark_all: 'Označi sve',
  do_download_agreement: 'Preuzmite ugovor',
  personal_lowercase: 'osobni',
  cabinet_lowercase: 'kabinet',
  choose_option_or_continue_input: 'Odaberite opciju ili da nastavite pisanje',
  unknown_organization: 'Nepoznata organizacija',
  organization_info: 'Podaci o organizaciji',
  organization_name: 'Naziv organizacije',
  organization_phone: 'Telefon organizacije',
  inn: 'INN',
  head_of_company: 'Voditelj',
  registration_date: 'Datum prijave',
  okved: 'ОКВЭД',
  capital: 'Temeljni kapital',
  taxes_system: 'Porezni sustav',
  do_write_inn: 'Unesite INN',
  osno: 'D',
  usn1: 'USN (prihodi-rashodi)',
  usn2: 'USN (prihodi 6%)',
  innLabel1: '{label} ili naziv organizacije',
  innLabel2: '{label} ili prezime',
  acting: 'Aktivni',
  first_name_placeholder: 'Michael',
  second_name: 'Prezime',
  second_name_placeholder: 'Mikhaylov',
  third_name: 'Prezime',
  third_name_placeholder: 'Alexis',
  position_label: 'Post (kao i u povelji organizacije)',
  position_placeholder: 'Generalni direktor',
  okopf_name_short_label: 'Pravni oblik u skraćenom obliku',
  okopf_name_full_label: 'Pravni oblik u punom obliku',
  ooo_full_name: 'Društvo s ograničenom odgovornošću',
  name_short_with_opf: 'Kratko ime sa ОПФ',
  name_short_with_opf_placeholder: "OOO 'Багратион'",
  type_of_company_placeholder: 'OOO, PI',
  name_short: 'Kratki naziv',
  name_short_placeholder: 'Багратион',
  ogrn: 'OGRN',
  first_passport_page: 'Prva stranica pasoša',
  passport_page_with_place_of_live: 'Stranica s prebivalištem',
  bank_account: 'Broj tekućeg računa',
  bank_account_register_placeholder:
    'Unesite 20-znamenkasti računa u banci (ako se račun sastoji se od 22 znamenki, navedite prvih 20)',
  bik: 'BIK',
  bik_register_placeholder: 'Unesite 9 znamenki sobe BIK banke',
  bank_credentials: 'Bankovni podaci',
  receivers_bank: 'Banka primatelja',
  bank_account_number: 'Broj računa',
  bank_correspondent_number: 'Corr. račun',
  your_name: 'Vaše ime',
  phone_number: 'Broj telefona',
  type_of_employment: 'Vrsta zaposlenja',
  ip_as_type_of_organization: 'IP',
  legal_as_type_of_organization: 'Organizacija',
  date: 'Datum',
  inventory_system: 'Sustav robe',
  integration_errors: 'Uz integraciju s greškama',
  integration_with_system_were_succeed: 'Integracija sa {sustav} je bila uspješna',
  do_change_info: 'Izmjena podataka',
  do_skip: 'Preskočiti',
  select_other_system: 'Odabrati sustav',
  select_other_system2: 'Odaberite drugi sustav',
  do_check_integration: 'Provjerite integraciju',
  integration_entry_label:
    ' Ovdje ste dodali proizvode koji će se prodavati. To možete učiniti na nekoliko načina',
  integration_entry_label_option1: 'Integrirati sustav Briskly s vašeg sustava računovodstva.',
  integration_entry_label_option2: 'Preuzimanje datoteka s robom.',
  integration_entry_label_option3: 'Odaberite postojeći katalog',
  integration_entry_label_option4: 'Dodajte proizvode ručno',
  select_catalog: 'Odaberite direktorij',
  upload_or_drag_file: 'Preuzmite ili povucite datoteku',
  integracija: 'Integracija',
  integration_file_label:
    'Za ispravno učitavanje kataloga, соотнесите naša imena polja s načinom na koji su imenovani od vas.',
  item_add_action2_label: 'Napravite kategorije i dodajte u njih robe',
  write_category_name: 'Upišite naziv kategorije',
  do_create_category: 'Stvoriti kategoriju',
  actions: 'Akcije',
  do_send_claim: 'Pošaljite žalbu',
  claim_text: 'Tekst žalbe',
  for_order: 'na nalogu',
  order: 'Naručivanje',
  client_id: 'ID klijenta',
  total_discount: 'Iznos popusta',
  order_info: 'Informacije o narudžbi',
  claim_sended: 'Prigovor je podnesen',
  total_amount: 'Ukupna vrijednost',
  order_composition: 'Sastav rezervacije',
  additionally: 'Dodatno',
  fields_are_optional: 'polja nisu obavezna',
  show_by: 'Prikaži na',
  do_briskly_module_active_new: 'Aktivirati novi modul',
  do_briskly_module_finish: 'Završetak',
  briskly_module_reassign_title: 'Uvez novog modula',
  briskly_module_reassign_message: 'Jeste li sigurni da želite povezati novi Briskly modul?',
  error: 'Greška',
  fail_bind_employee_to_store: 'Nije uspio pričvrstiti zaposlenika',
  fail_unbind_employee_to_store: 'Nije uspio отвязать zaposlenika',
  fail_init_employee_store_list: 'Ne mogu učitati podaci o prodajnim mjestima',
  fail_onboarding_init_company_info: 'Ne mogu učitati podaci tvrtke ispunili ranije',
  unknown_error: 'Nešto je pošlo po zlu',
  delete_category_text_confirm:
    'Ova akcija će izbrisati direktorij, a roba koje se nalaze u njemu će se naći u kategoriji sve""',
  delete_catalog_text_confirm_1: 'Prilikom uklanjanja direktorija',
  delete_catalog_text_confirm_2: 'od njega će удаленны sve kategorije i proizvode koji su u njemu',
  do_collapse: 'Sažmi',
  exchange_settigns: 'Postavke dijeljenja',
  create_catalog_label_2:
    "'Na računu može biti više direktorija. \\nNa primjer za hladnjaka prodaje gotovih jela ili prodaje smrznute proizvode. \\nBirajući odgovarajući direktorij, možete brzo prebaciti s jednog popisa robe na drugi. \\nOvdje možete promijeniti ovaj katalog.',",
  report_about_item: 'Statistika o robi',
  report_order_id: 'Broj Naloga',
  mailings: 'Liste',
  do_add_mailing: 'Stvoriti newsletter',
  mailing_new: 'Novi newsletter',
  mailing_edit: 'Uređivanje liste',
  mailing_status_complete: 'Izvedena',
  mailing_status_saved: 'Sačuvana',
  mailing_status_moderation: 'Na umjerenost',
  mailing_status_wait: 'Čeka na slanje',
  mailing_status_cancelled: 'Otkazan',
  mailing_status_ready: 'Plati',
  mailing_add_description:
    'Dodajte vaš newsletter. Odredite vrijeme za slanje, kao i publiku kupaca i tekst poruke.',
  mailing_moderation_description:
    'Nakon prolaska umjerenost će vam trebati platiti newsletter. Uzmite u obzir da se obvezno se održava u roku od 1-2 radna dana, plaćanje je pušten na račun do 2 radna dana. Uzmite to u obzir pri planiranju listu.',
  mailing_name: 'Naziv liste',
  mailing_date: 'Datum slanja',
  mailing_audience: 'Publika za obavljanje liste',
  mailing_client_count: 'Broj klijenata',
  mailing_cost: 'Troškovi slanja',
  mailing_text: 'Tekst liste',
  mailing_only_save: 'Zadržati, ali ne i pokrenuti',
  mailing_save_and_start: 'Pokrenuti newsletter',
  mailing_invalid_time: 'Postavite vremenski interval od 9:00 do 21:00',
  mailing_may_not_pass_moderation: 'Vaša mailing može ne imati vremena proći moderiranje.',
  mailing_all_clients: 'Svi klijenti',
  mailing_created_filters: 'Gotove filteri',
  mailing_selecting_filters: 'Izbor filtera',
  mailing_save_filters: 'Spremi filteri',

  stock_balances: 'Stanje zaliha',
  do_add_items_list: 'Dodavanje stavki kao popisa',
  accounting_for_stock_balances: 'Računovodstvo stanja zaliha',
  rebase_catalogs: 'Uklanjanje kataloga> Uređivanje prodajnih mjesta',
  clarification_in_items_side_bar:
    '* Ako su proizvodi u vašoj datoteci već podijeljeni u kategorije, samo odaberite Katalog i prenesite datoteku, kategorije će se stvoriti automatski.',
  do_download_xlsx: 'Učitaj .xlsx datoteku',
  stock_accounting_balances_info:
    'Za dobivanje najnovijih podataka o količini i vrijednosti robe u vašim prodajnim mjestima koristite funkciju "Računovodstvo stanja zaliha". ',
  or: 'Ili',
  category_info_tip:
    'Koristite ovu funkciju za brže i automatski punjenje nove kategorije proizvodima.',
  good_must_be_in_catalog_or_category: 'Proizvod mora biti vezan za katalog ili kategoriju.',
  items_add_side_bar_info:
    'Ako trebate stvoriti novi direktorij ili kategoriju u trenutnom direktoriju, idite na odjeljak',
  do_delete_catalog: 'Izbriši katalog',
  delete_catalog: 'Izbriši katalog',
  trade_point_edit: 'Uređivanje prodajnih mjesta',
  rebase_catalog_1: 'Da biste uklonili katalog "{name}"',
  rebase_catalog_2: 'veži nove kataloge na sva prodajna mjesta',
  rebase_catalog_3: 'u donjoj tablici.',
  cant_start_from_dot: 'Ne može se početi od točke',
  aprove_category_delete: 'Izbrisat ćete kategoriju',
  delete_category_warn: 'Brisanjem kategorije izbrisat će se svi proizvodi u ovoj kategoriji',
  delete_catalog_confirm_text_5: 'Izbrisat ćete prazan direktorij bez kategorija ili proizvoda',
  delete_catalog_confirm_text_6: 'Izbrisat ćete katalog koji sadrži proizvode',
  trade_point_type_name: 'vrsta trgovinske točke',
  select_new_catalog: 'Odaberi novi katalog',
  barcoders_prefix_for_weight_good: 'Prefiksi barkodova stavke težine',
  barcoders_prefix_info:
    'Vrijednošću ovog prefiksa, sustav prepoznaje stavku po težini, ističući digitalni kod predmeta i njezinu težinu u gramima. Dostupni prefiksi su od 20 do 29. Ako nije naveden prefiks, roba će se tretirati kao komadna roba. ',
  age_limit_2: 'godine',
  age_limit_1: 'Dobna granica,',

  requisites_personal_data_descr_part_1:
    ' tražimo od vas da ispunite ove informacije kako biste mogli svakodnevno prenijeti sredstva iz prodaje svoje prodajne točke. Da biste to učinili, morate dati informacije slične onome što pružate Prilikom povezivanja stjecanja.',
  requisites_personal_data_descr_part_2:
    ' vaši se podaci traže na zahtjev Centralne banke i banke. Oni će biti pohranjeni u šifriranom obliku. Također., tvrtka Briskly sastoji se u registru operatera osobnih podataka Roskomnadzor.',
  add_good: 'Dodaj stavku',
  external_id: 'vanjski id',
  do_save_and_go_back: 'Spremi i vrati se na popis',
  print_shelf_life: 'Ispiši rok trajanja',
  succes_saved: 'uspješno spremljeno',
  quantity_cut: 'količina',
  additional_data_about_good: 'dodatni podaci o proizvodu',
  doest_not_req_field: 'izborna polja',
  do_upload_photo: 'učitaj fotografiju',
  upload_good_list: 'Učitaj stavke s popisom',
  show_filters: 'Prikaži filteri',
  hide_filters: 'Sakrij filtre',
  available_table_filters: 'filtri tablice',
  deletion: 'brisanje',
  item_warning_info: 'proizvod mora biti vezan za bilo koji katalog i kategoriju.',
  item_create_info:
    'ako trebate proslijediti novi direktorij ili kategoriju u trenutnom direktoriju, idite na',
  aprove_catalog_delete: 'izbrisat ćete direktorij',
  delete_catalog_text_confirm_3:
    'sve kategorije i proizvodi unutar njega bit će izbrisani kada izbrišete direktorij.',
  delete_catalog_text_confirm_4:
    'prvo morate povezati novi katalog sa svim prodajnim mjestima u zamjenu za izbrisano.',
  go_to_edit_store: 'Idi na uređivanje poslovnica',
  choose_trade_point: 'odaberite prodajnu točku',
  do_catalog_edit: 'uređivanje Direktorija',
  Do_category_edit: 'uređivanje kategorije',
  category_warn_text_warn: 'Kategorija mora biti vezana za bilo koju katalog.',
  category_warn_text: 'ako trebate promovirati novi direktorij, idite na link',
  category_warn_text_link: 'Kreiraj novi direktorij.',
  catalog_rebase_info: 'više nije povezan ni s jednom od prodajnih mjesta, sada možete',

  cancel: 'Otkazivanje',
  agreement: 'Sporazum',
  fuel: 'Gorivo',
  profit: 'Prihodi',
  no_design_to_select: 'nema spremljenih дизайнов',
  refund: 'Povrat',
  quantity: 'Broj',
  commission: 'Komisija',
  valid_fill_the_field: 'ispunite polje',
  valid_incorrect_date: 'netočan datum',
  valid_incorrect_phone: 'pogrešan telefon',
  valid_passport_series: 'netočna serija',
  valid_passport_number: 'pogrešan broj putovnice',
  valid_passport_authority_code: 'neispravan kod jedinice',
  valid_inn: 'odaberite Inn',
  valid_address: 'odaberite adresu',
  valid_bic: 'odaberite BIC',
  valid_account_number: 'duljina izračunate računi moraju biti 20 znakova',
  valid_maximum: 'maksimalni broj znakova',
  valid_select_some_filter: 'Odaberite barem jedan filtar',
  barcode: 'Barkod',
  integration: 'Integracija',
  need_to_select_catalog_and_category:
    'za stvaranje stavke \n morate odabrati \n Katalog i kategoriju',
  categories_of_catalogs: 'kategorije direktorij',
  add_catalog: 'Dodaj direktorij',
  add_category: 'Dodaj kategoriju',
  add_catalog_action: 'Dodaj direktorij',
  add_category_action: 'Dodaj kategoriju',
  category_name: 'naziv kategorije',
  catalogs: 'direktoriji',
  search_by_categories: 'traži po kategorije',
  search_by_catalogs: 'Pretraži direktorije',
  warning: 'Pozor!',
  write_new_catalog_name: 'unesite naziv direktorija',
  write_new_category_name: 'unesite naziv kategorije',
  add_catalog_warning:
    'možete povezati direktorij na više prodajnih mjesta, za to koristite' +
    ' za dodavanje dodatnog polja za odabir trgovačke točke.',
  add_catalog_info:
    'možete dodati više kategorija odjednom u jedan direktorij, za to koristite ' +
    ' za dodavanje dodatnog polja za unos naziva nove kategorije. ',
  product_product_date: 'ispis datuma proizvodnje',
  mailing_going_to_delete: 'uklonit ćete newsletter',
  address_doesnt_correct: 'adresa nije ispravna',
  no_saved_templates: 'nema spremljenih predložaka',
  add_schedule_and_save_as_template: 'dodajte opcije i spremite ih kao predložak',
  service_mts_kassa: 'mts blagajna',
  create_new_catalog: 'Izradi novi direktorij',
  use_existing_catalog: 'koristi postojeći direktorij',
  log_out_question: 'želite li točno završiti trenutnu sesiju?',
  do_category_edit: 'uređivanje kategorije',
  item_photo_note:
    '(možete prenijeti fotografiju stavke ili ostavite zadanu fotografiju prikazanu u nastavku)',
  store_doesnt_have_catalog: 'da li prodajna točka {name} Nema veze s katalogom za uređivanje?',
  success: 'Uspješno',

  uploaded_image_min_width: 'Minimalna širina slike',
  uploaded_image_max_width: 'Maksimalna širina slike',
  uploaded_image_min_height: 'Minimalna visina slike',
  uploaded_image_max_height: 'Maksimalna visina slike',
  allowed_types: 'Dopuštene vrste',
  max_file_size: 'Maksimalna veličina datoteke',
  standard_no_suf: 'Standard',
  orders_and_video: 'Narudžbe i videozapisi',
  payment_type_undefined: 'Nije određeno',

  service_csv: 'csv',
  time_of_creation: 'Vrijeme stvaranja',
  id_trade_point: 'ID trgovine',
  find_by_order_number: 'Pretraživanje po broju narudžbe',
  item_id: 'Identifikatori elemenata',
  price_with_currency_inject: 'Cijena, {currency}',
  sum_with_currency_inject: 'Iznos, {currency}',
  total_amount_price_with_currency_inject: 'Ukupni trošak, {currency}',
  total_amount_sum_with_currency_inject: 'Ukupan iznos, {currency}',
  option_discount_percent_cut: 'Popust, %',
  good_barcode: 'Barkod proizvoda',
  create_refund: 'Stvaranje povrata',
  created_at: 'Vrijeme stvaranja',
  customer_id: 'Klijent',
  ok: 'Ok',
  store: 'Dućan',
  orders_not_exist: 'Još nema naloga na vašim prodajnim mjestima.',

  //Новые ключи

  valid_minimum: 'Minimalni broj znakova',

  coords_lng: 'Dužina',
  coords_lat: 'Širina',

  invalid_intervals_filled: 'Polja se ne ispunjavaju',
  invalid_intervals_to_less_then_from: 'Nevažeći intervali ',
  invalid_intervals_intersections: 'Intervali se ne smiju preklapati ',
  valid_max_250: 'Maksimalno 250 znakova ',

  postal_code: 'Poštanski Broj',
  module_description_temp:
    'Briskly modul je mozak vašeg mikrorynka. Kada kupujete, izdali ste kôd koji bi trebao biti povezan s vašim računom.',
  value_must_be_more_than_zero: 'Ova vrijednost mora biti veća od 0',
  valid_min_13: 'Najmanje 13 znakova',
  dosesnt_correct_number: 'Nevažeći broj',
  success_deleted: 'Uspješno daljinski',
  do_return_back: 'Povratak natrag',
  abort_procedure: 'Postupak prekida',
  abort: 'Izbaci',
  maybe_data_not_saved: 'Promjene se ne mogu spremiti',
  empty_catalog: 'Direktorij je prazan',
  fileupload_page_info:
    'Ako su stavke u vašoj datoteci već podijeljene u kategorije, jednostavno odaberite direktorij i preuzmite datoteku, kategorije će se automatski stvoriti.',
  do_download_xlsx_example: 'Preuzimanje primjer xlsx',
  valid_max_11: 'Maksimalno 11 znakova',
  payment_type: 'Vrsta plaćanja',
  app: 'Aplikacija',
  terminal: 'Terminal',
  order_with_id_inject: 'ID narudžbe {orderId}',
  refund_with_order_id_inject: 'Povrat: ID narudžbe {orderId}',
  barcode_can_includes_only_digits: 'Samo brojevi',
  copy_to_clipboard: 'Kopiranje u međuspremnik',
  copied_to_clipboard: 'Kopirano u međuspremnik',
  about_order: 'O narudžbama',
  refund_title: 'Povrat novca',
  amout_goods_to_refund: 'Ukupna roba koja se vraća',
  amount_goods_payment: 'Iznos povrata',
  technical_support: 'Tehnička podrška',
  add_refund: 'Dodaj povrat',
  view_refund: 'Prikaz povrataka',
  onboarding_virtual_terminal: 'Virtualni terminal',
  virtual_terminal: 'Virtualni terminal',
  select_virtual_terminal: 'Odaberite virtualni terminal',
  virtual_terminal_description:
    'Ispunite detalje vašeg virtualnog terminala, potrebni su za upis sredstava na vaš račun, kao i za izradu čeka za kupca.',
  add_new_virtual_terminal: 'Dodaj terminal',
  do_create_refund: 'Stvaranje povrata',
  approve_refund: 'Potvrdite povrat',
  refund_by_id_goes_successed: 'Povrat sredstava za ID narudžbe {refundId} uspješno je prošao',
  no_one_good_selected: 'Nema odabranog proizvoda za povratak',

  delete_goods_modal_info: 'i {stringsQuantity} ... više proizvoda',
  client_show_settings_2: 'Postavke prikaza mobilne aplikacije',
  no_changes_to_update: 'Nema promjena za spremanje',
  for_go_to_exchange_need_say_store: 'Odaberite prodajnu točku',
  link_to_showcase: 'Veza na izlog proizvoda',
  catalog_successfully_added: 'Uspješno dodan direktorij',
  refund_has_been_created: 'Povrat je stvoren',
  no_trans_action: 'Bez operacija',
  no_refund_text_info: 'Nije bilo povrata za ovu narudžbu.',
  date_should_not_earlier: 'Datum ne bi trebao biti prije',
  date_should_not_later: 'Datum bi trebao biti najkasnije',
  cant_play_video: 'Ne mogu reproducirati videozapis',
  check_refund_possibility: 'Provjerite mogućnosti povrata novca',
  refund_helper_text: 'Da biste brzo pronašli stavku, unesite njegov ID, barkod ili naziv',

  errors_occurred_while_creating_the_refund: 'Pogreške su nastale prilikom stvaranja povrata:',

  refund_by_id_goes_accept: 'Potvrdite povrat za ID narudžbe {refundId}',
  refund_technical_support_notice: 'Da biste izvršili povrat novca, obratite se tehničkoj podršci.',
  refund_money_back_notice:
    'Novac će biti uplaćen na karticu klijenta u roku od 2 dana od povratka.',
  refund_ready_title: 'Povrat narudžbe',
  refund_check_success_info: 'Povrat se može uspješno stvoriti',
  refund_check_success_info_with_order_id: 'Identifikator povrata {orderId} uspješno je izrađen',

  video_not_available: 'Video nije dostupan',
  video_tooltip_error: 'Video datoteka je oštećena ili privremeno nedostupna. Obratite se podršci',
  do_upload: 'Preuzimanje',
  amout_for_refund: 'Ukupno robe za povrat',
  does_not_correct_control_sum: 'Nevažeća kontrolna suma',
  active_status: 'Čeka se plaćanje',
  paid_status: 'Plaćeno',
  refund_status: 'Vratio se',
  refund_awaiting_status: 'Povratak se očekuje',
  refund_error_status: 'Povratna pogreška',
  refund_partial_status: 'Djelomično vraćen',
  removed_status: 'Izbrisano',

  online_store_layouts: 'Izgled za ispis na mikromarketu',
  promo_stickers: 'Naljepnice',
  promo_side_stickers: 'Bočne Naljepnice',
  promo_on_glas: 'Na staklu',
  promo_on_advertising_block: 'Na oglasnu jedinicu',

  promo_without_ad_block: '(bez bloka oglasa)',
  promo_with_ad_block: '(s oglasnom jedinicom)',
  attention: 'Pozor',
  requisites_skip_warning:
    'Ako propustite korak odabira / dodavanja pojedinosti, vaša prodajna točka će raditi u testnom načinu rada.',
  select_full_party_description:
    'Možete odabrati prethodno korištene podatke ili dodati nove jednostavnim ispunjavanjem obrasca!',
  select_partial_party_description: 'Povežite svoj virtualni terminal s osobnim kabinetom!',
  pick_terminal: 'Odaberite Terminal',
  add_new_full_party: 'Dodaj novi',
  add_new_partial_party: 'Dodaj novi',
  swift_bic: 'SWIFT / BIC',
  secure3d: '3DS',
  non_secure3d: 'sobe 3DS',
  full_party_choosing: 'Odabir dijelova',
  partial_party_choosing: 'Odabir terminala',
  requisites_and_terminals: 'Pravne osobe',
  terminals: 'Virtualni terminali',
  full_party_status_active: 'Aktivno',
  full_party_status_pending: 'Provjera',
  full_party_status_replacement: 'Zamjena',
  full_party_edit: 'Uređivanje rekvizita',
  partial_party_edit: 'Uređivanje virtualnog terminala',
  adding_new_terminal: 'Dodavanje novog terminala',
  add_full_party: 'Dodaj rekvizite',
  add_partial_party: 'Dodaj virtualni terminal',
  full_party_edit_warning:
    'Nakon promjene, dijelovi će biti testirani neko vrijeme. Novi podaci bit će spremljeni za prodajna mjesta odmah nakon provjere.',
  partial_party_edit_warning:
    'Nakon promjene, terminal će biti testiran neko vrijeme. Novi terminal bit će spremljen za prodajna mjesta odmah nakon provjere.',
  kpp: 'CPR',
  upload_logo_in_format: 'Preuzmite logotip u formatu',
  upload_logo_with_min_size: 'Minimalna veličina slike',
  upload_logo_with_min_file_size: 'Maksimalna veličina datoteke je',
  BYN: 'Bjeloruska rublja dolar(BYN)',
  USD: 'SAD(USD)',
  EUR: 'Euro(EUR)',
  KZT: 'Kazahstan tenge(KZT)',
  CAD: 'Kanadski dolar(CAD)',
  CNY: 'Kineski yuan(CNY)',
  RUB: 'Ruska rublja(RUB)',
  TRY: 'Turska lira(TRY)',
  UAH: 'Ukrajinska grivna(UAH)',
  GBP: 'POUND STERLING(GBP)',
  CHF: 'Švicarski franak(CHF)',
  currency: 'Valuta',

  bank_name: 'Naziv banke',
  company_name: 'Puno ime tvrtke',

  serial_number: 'Serijski broj',
  coffee_reeboot: 'Ponovo pokreni',
  coffee_reeboot_coffee_machine: 'Ponovno pokrenite aparat za kavu',
  coffee_reeboot_text: 'Ponovno pokretanje uređaja pomaže u rješavanju brojnih problema.',
  defer_service: 'Odvojite obveznu uslugu',
  coffee_postpone: 'Odgoda',
  coffee_service_maintenance_text: 'Stanje održavanja bit će odgođeno do sljedeće aktivacije.',
  block_drinks: 'Jedinica za pripremu pića',
  coffee_block_text:
    'Aparat za kavu će ostati aktivan, ali priprema pića neće biti dostupna kupcima',
  block_drink_change: 'Blok promjena parametara pića',
  coffee_props_block_text:
    'Dostupno u vrijeme pripreme. Nakon aktivacije korisnici neće moći mijenjati standardno piće.',
  block_menu_access: 'Blokiraj pristup korisničkom izborniku',
  coffee_menu_text: 'Kupci neće moći koristiti izbornik na aparatu za kavu.',
  block_drinks_when_offline:
    'Blokirajte pripremu pića ako nema veze s poslužiteljem dulje od 48 sati',
  coffee_reloading: 'Ponovno podizanje sustava ...',
  search_by_items: 'Pretraživanje proizvoda',
  coffee_item_photo: 'Fotografija proizvoda',
  coffee_item_photo_info:
    '(možete prenijeti vlastitu fotografiju proizvoda ili ostaviti zadanu fotografiju prikazanu u nastavku)',
  coffee_item_type: 'Vrsta pića',
  item_recept: 'Recept proizvoda',
  value_ml: 'ml',
  cup_with_units_injection: 'staklo {veličina šalice} ml',
  drink_with_units_injection: 'Piće {Drinksize} ml',
  coffee_foam: 'Mliječna pjena',
  coffee_milk: 'Mlijeko',
  coffee_water: 'Voda',
  coffee_coffee_size: 'Kava',
  return_values_to_default: 'Vraćanje zadanih vrijednosti',
  price_must_be_more_then_inject: 'Cijena bi trebala biti veća {price}',
  coffee_item_photo_info__temp:
    'Možete ostaviti zadanu fotografiju ili odabrati jednu od dostupnih klikom na gumb "odaberi"',
  block_drink_changes: 'Blokiraj promjenu parametara pića',

  file_name_uploaded: 'Datoteka {fileName} preuzeta',
  columns: 'Stupci',
  file_upload_rules: 'Zahtjevi datoteka za preuzimanje proizvoda:',
  file_upload_rules_1: 'Format -. xlsx;',
  file_upload_rules_2: 'Datoteka ne smije sadržavati spojene ćelije.',
  file_upload_rules_3: 'Nazivi polja ne smiju biti dulji od jednog retka.',
  file_upload_rules_4:
    "U slučaju pogrešaka u poljima 'mjerne jedinice' i ' količina ... 'automatski se uvodi'1 komad'.",
  are_mandatory: 'su obvezni',
  file_upload_page_info:
    'Da biste ispravno učitali katalog, podudarajte imena naših polja s onim što su vam imenovani.',
  edit_file_and_try_again: 'Popravite bugove i pokušajte ponovo',
  do_stop_now: 'Upravo završavam.',
  no_save_upload_remember:
    "Klikom na gumb 'Gotovo sada' potvrđujete ... neispravno prikazivanje podataka o vašim proizvodima.",
  exchange_drpops_with_exception: 'Pogreške nastale tijekom integracije',
  good_must_be_in_catalog_or_category_rewrite: 'Proizvod mora biti povezan s katalogom.',
  fileupload_page_info_rewrite:
    '* Ako su proizvodi u vašoj datoteci već podijeljeni u kategorije, samo odaberite Katalog i prenesite datoteku, kategorije će se automatski stvoriti.',
  loading: 'Utovar...',
  show_detail_info: 'Prikaži detaljne informacije',
  file_upload_success_info:
    "Klikom na gumb 'Dodaj' potvrđujete Učitavanje robe u odgovarajuće kataloge i kategorije.",
  be_created: 'Stvoreni su',
  selected_catalog: 'Odabran je direktorij',
  file_uploaded: 'Preuzimanje datoteke',
  was_successful: 'bilo je uspješno.',
  and: 'i ...',
  valid_min: 'minimalni {min}',
  value_mm: 'mm.',
  unit_796: 'stvar',
  unit_657: 'Proizvod',
  unit_111: 'količina (ml)',
  unit_112: 'Litra',
  unit_163: 'Gram(g)',
  unit_625: 'List',
  unit_704: 'Set',
  unit_715: 'Par',
  unit_166: 'Killogram (kg)',
  unit_736: 'Roll',
  unit_778: 'Ambalaža',
  item_from_upload_info: 'Da biste brzo dodali proizvode u katalog, upotrijebite funkciju',
  promo_briskly_link: 'Promo',
  store_name_placeholder: 'Naziv utičnice',
  serial_number: 'Serijski broj',
  coffee_reeboot: 'Ponovo pokreni',
  coffee_reeboot_coffee_machine: 'Ponovno pokrenite aparat za kavu',
  coffee_reeboot_text: 'Ponovno pokretanje uređaja pomaže u rješavanju brojnih problema.',
  defer_service: 'Odvojite obveznu uslugu',
  coffee_postpone: 'Odgoda',
  coffee_service_maintenance_text: 'Stanje održavanja bit će odgođeno do sljedeće aktivacije.',
  block_drinks: 'Jedinica za pripremu pića',
  coffee_block_text:
    'Aparat za kavu će ostati aktivan, ali priprema pića neće biti dostupna kupcima',
  block_drink_change: 'Blok promjena parametara pića',
  coffee_props_block_text:
    'Dostupno u vrijeme pripreme. Nakon aktivacije korisnici neće moći mijenjati standardno piće.',
  block_menu_access: 'Blokiraj pristup korisničkom izborniku',
  coffee_menu_text: 'Kupci neće moći koristiti izbornik na aparatu za kavu.',
  block_drinks_when_offline:
    'Blokirajte pripremu pića ako nema veze s poslužiteljem dulje od 48 sati',
  coffee_reloading: 'Ponovno podizanje sustava ...',
  search_by_items: 'Pretraživanje proizvoda',
  coffee_item_photo: 'Fotografija proizvoda',
  coffee_item_photo_info:
    '(možete prenijeti vlastitu fotografiju proizvoda ili ostaviti zadanu fotografiju prikazanu u nastavku)',
  coffee_item_type: 'Vrsta pića',
  item_recept: 'Recept proizvoda',
  value_ml: 'ml',
  cup_with_units_injection: 'staklo {veličina šalice} ml',
  drink_with_units_injection: 'Piće {Drinksize} ml',
  coffee_foam: 'Mliječna pjena',
  coffee_milk: 'Mlijeko',
  coffee_water: 'Voda',
  coffee_coffee_size: 'Kava',
  return_values_to_default: 'Vraćanje zadanih vrijednosti',
  price_must_be_more_then_inject: 'Cijena bi trebala biti veća {price}',
  coffee_item_photo_info__temp:
    'Možete ostaviti zadanu fotografiju ili odabrati jednu od dostupnih klikom na gumb "odaberi"',
  block_drink_changes: 'Blokiraj promjenu parametara pića',
  heating: 'grijanje',
};
