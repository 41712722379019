export class ScheduleInterval {
  static timeToMin = (time, type) => {
    const [h, m] = time.split(':');
    const minutes = h * 60 + Number(m);

    if (!minutes && type === 'to') {
      return 60 * 24;
    }

    return minutes;
  };

  static stringToObject = (interval) => {
    const timeToObj = (time) => {
      const [h, m] = time.split(':');
      return { h, m };
    };

    const [from, to] = interval.split('-');
    return { from: timeToObj(from), to: timeToObj(to) };
  };

  constructor(interval) {
    if (typeof interval === 'string') {
      interval = ScheduleInterval.stringToObject(interval);
    }

    this.from = interval.from;
    this.to = interval.to;
  }

  toMin() {
    return {
      from: this.from.h * 60 + Number(this.from.m),
      to: this.to.h * 60 + Number(this.to.m),
    };
  }

  toString() {
    return `${this.from.h}:${this.from.m}-${this.to.h}:${this.to.m}`;
  }

  compare(interval) {
    if (!(interval instanceof ScheduleInterval)) {
      interval = new ScheduleInterval(interval);
    }

    return this.toMin().from > interval.toMin().from ? 1 : -1;
  }
}
