import { uniqBy } from 'lodash';
import { supportAPI } from '@/core/api/support';
import { format, compareAsc } from 'date-fns';
import { bus } from 'shared/core';

const state = {
  init: false,
  show: false,
  loading: false,
  items: [],
  meta: { page: 0 },
  input: '',
};

const getters = {
  init: (state) => state.init,
  show: (state) => state.show,
  loading: (state) => state.loading,
  items: (state) => state.items,
  meta: (state) => state.meta,
  input: (state) => state.input,

  sortMessages: (state) => (messages) => {
    // return messages.sort((m1, m2) => compareAsc(new Date(m1.created_at), new Date(m2.created_at)));
    return messages.sort((m1, m2) => (m1.id > m2.id ? 1 : -1));
  },

  groupedItems: (state, getters) => {
    const formatDate = (date) => format(new Date(date), 'yyyy-MM-dd');

    const dates = uniqBy(
      state.items
        .map((item) => item.created_at)
        .sort((d1, d2) => compareAsc(new Date(d1), new Date(d2)))
        .map((item) => formatDate(item)),
    );

    const store = new Map();

    dates.forEach((date) => store.set(date, []));

    state.items.forEach((item) => {
      const key = formatDate(item.created_at);
      store.get(key).push(item);
    });

    const result = [];

    for (const [date, items] of store.entries()) {
      result.push({ date, items: getters.sortMessages(items) });
    }

    return result;
  },
};

const actions = {
  open: ({ commit }) => {
    commit('_setShow', true);
  },

  startInit: async ({ getters, commit, dispatch }) => {
    if (getters.init) return;

    if (await dispatch('fetchMessages')) commit('_setInit', true);
  },

  setInput: ({ commit }, value) => {
    commit('_setInput', value);
  },

  close: ({ commit }) => {
    commit('_setShow', false);
  },

  sendMessage: async ({ dispatch, commit, getters }, opts = {}) => {
    if (getters.loading) return;

    try {
      const { text = getters.input } = opts;

      await supportAPI.sendMessage({ text });

      commit('_setLoading', true);
    } catch (error) {
      console.error(error);
    } finally {
      commit('_setLoading', false);
    }
  },

  fetchMessages: async ({ commit, getters }, opts = {}) => {
    if (getters.meta.page >= getters.meta.total_pages) return;
    if (getters.loading) return;

    try {
      commit('_setLoading', true);

      const payload = {
        ...getters.meta,
        page: getters.meta.page + 1,
      };

      const { items, meta } = await supportAPI.getMessages(payload);

      commit('_setMeta', meta);
      commit('_setItems', items);

      return { hasNewItems: !!items.length };
    } catch (error) {
      console.error(error);
    } finally {
      commit('_setLoading', false);
    }
  },

  markMessageAsRead: async ({ commit }, opts = {}) => {
    try {
      const { id } = opts;

      commit('_markMessageAsRead', { id, value: true });

      await supportAPI.setAsRead({ id });
    } catch (error) {
      console.error(error);
      commit('_markMessageAsRead', { id, value: false });
    }
  },

  addMessage: ({ commit }, opts = {}) => {
    const { message } = opts;
    commit('_setItems', [message]);
    bus.emit('core$chat/addMessage:OK', message);
  },
};

const mutations = {
  _setShow: (state, value) => {
    state.show = value;
  },

  _setLoading: (state, value) => {
    state.loading = value;
  },

  _setMeta: (state, value) => {
    state.meta = value;
  },

  _setItems: (state, values) => {
    state.items = uniqBy(state.items.concat(values), 'id');
  },

  _setInput: (state, value) => {
    state.input = value;
  },

  _setInit: (state, value) => {
    state.init = value;
  },

  _markMessageAsRead: (state, { id, value }) => {
    state.items = state.items.map((item) => {
      if (item.id === id) item.is_read = value;
      return item;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
