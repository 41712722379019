import { bus } from 'shared/core';
import router from '@/core/router';

class MainEvent {
  store;

  init(_store) {
    this.store = _store;
    bus.on('authInit', this.onAuthInit);
    bus.on('shared$auth/tokenExpired', this.onTokenExpired);
  }

  onAuthInit = () => {
    // this.store.dispatch('core$main/init');
  };

  onTokenExpired = () => {
    if (!router.currentRoute.name.includes('auth')) {
      router.push({ name: 'logout', params: { isTokenExpired: true } });
    }
  };
}

export default new MainEvent();
