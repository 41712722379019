import { ScheduleInterval } from '@/apps/trade-point/utils/ScheduleInterval';
import { week } from '@/core/data/schedule';

const flagsSerializer = (day) => {
  if (day.weekend) return [];
  if (day.permanently) return [{ from: { h: '0', m: '00' }, to: { h: '0', m: '00' } }];
  return day.intervals;
};

const flagsDeserializer = (intervals, idx) => {
  const weekend = !intervals.length;
  const title = week[idx];

  if (weekend) {
    const emptyInterval = { from: { h: '', m: '' }, to: { h: '', m: '' } };
    return { title, weekend, permanently: false, intervals: [emptyInterval] };
  }

  const first = new ScheduleInterval(intervals[0]);
  const isMidnight = Object.values(first.toMin()).every((v) => !v);
  const permanently = intervals.length === 1 && isMidnight;

  return { title, weekend, permanently, intervals };
};

export const createScheduleSerializer = (flagsSerializer) => (days) => {
  return days
    .map(flagsSerializer)
    .map((intervals) => intervals.map((i) => new ScheduleInterval(i).toString()).join(', '))
    .reduce((acc, intervals, idx) => {
      return intervals ? { ...acc, [idx + 1]: intervals } : acc;
    }, {});
};

export const createScheduleDeserializer = (flagsDeserializer) => (schedule) => {
  return new Array(7)
    .fill()
    .map((_, idx) => schedule[idx + 1] || [])
    .map((intervals) => intervals.map(ScheduleInterval.stringToObject))
    .map(flagsDeserializer);
};

export const serializeSchedule = createScheduleSerializer(flagsSerializer);
export const deserializeSchedule = createScheduleDeserializer(flagsDeserializer);
