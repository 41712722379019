export default [
  {
    path: 'docs',
    name: 'documets$docs',
    component: () => import('@/apps/documents/view/Docs/Docs'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: 'main',
        },
        {
          label: 'documents',
          name: 'documets$docs',
        },
      ],
    },
  },
  {
    path: 'docs/:id',
    name: 'documets$docs-document',
    component: () => import('@/apps/documents/view/Doc/Doc'),
    props: true,
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: 'main',
        },
        {
          label: 'documents',
          name: 'documets$docs',
        },
        {
          label: 'get:docId',
          name: '',
        },
      ],
    },
  },
];
