export default {
  valid_upload_both_photos: 'Завантажте обидва зображення',
  mailing_status_rejected: 'Відхилена',
  catalogs_and_categories: 'Каталоги та категорії',

  filter_gender: 'Стать',
  filter_age: 'Вік',
  filter_registered_days_ago: 'Дата реєстрації',
  filter_purchases_quantity: 'Кількість покупок',
  filter_purchase_days_ago: 'Давність покупок',
  filter_birth_date_month: 'День народження',

  gender_male: 'Чоловіча',
  gender_female: 'Жіноча',

  before_num: 'До {num}',
  older_then: 'Старше {num}',

  before_days: '{_} день | До {_} дня | До {_} днiв',
  year_ago: 'Рік тому',

  more_then: 'Бiльше {num}',

  in_this_month: 'В цьому мiсяцi',
  in_next_month: 'У наступному мiсяцi',

  select_employee_for_test_message: 'Виберіть працівника для відправки тестового повідомлення',
  filter_will_be_available_soon: 'Цей функціонал скоро буде доступний',

  documents_subtitle: `У цьому розділі вам необхідно ознайомитися з правилами роботи платформи та іншими юридичними документами. <br /> <br /> Також, тут відображається актуальний статус по документам, необхідним для коректної взаємодії між вами і компанією Briskly. <br/> В тому числі, є можливість дистанційно підписати ряд документів, скориставшись функцією смс-підтвердження.`,

  reports_subtitle: `Розділ "Звіти" дозволяє вам будувати наочні аналітичні звіти вашого бізнесу на технологічній платформі b: pay. <br /> Отримуйте зрозумілу достовірну інформацію по цікавлять вас параметрам і вибірках, і на підставі цих даних покращуйте свої бізнес-процеси, підвищуючи ефективність торгових точок і збільшуйте свій дохід!`,

  videos_subtitle: `У цьому розділі ви можете отримати записи відео з камер, встановлених на ваших торгових точках`,

  support_service_greeting: 'Привіт! Ми будемо раді допомогти вам!',
  homeTitle: 'Головний екран',
  homeSubtitle: 'На цьому екрані скоро буде статистика з продажів у ваших торгових точках',
  homeSubtitleWithStat:
    'На цьому екрані відображається статистика з продажів у ваших торгових точках',
  homeReferalProgramNote:
    'Користуєшся сам, приведи друга. Як тільки він зареєструється в системі, ви↵ отримаєте кэшбэк від Briskly у розмірі % від',
  referalProgram: 'Реферальна програма',
  trade_points: 'Торгові точки',
  on_map: 'На карті',
  do_add_trade_point: 'Додати торгову точку',
  bind: "Прив'язати",
  doesnt_select: 'Не вибрано',
  select_dates: 'Виберіть дати',
  trade_point_list: 'Список торгових точок',
  extended_types_washer_fluid: 'Омиває рідина',
  number: 'Номер',
  cost: 'Вартість',
  id: 'ID',
  do_open: 'Відкрити',
  do_close: 'Закрити',
  do_search: 'Шукати',
  results: 'Результати',
  by_list: 'Списком',
  name: 'Назва',
  continue: 'Продовжити',
  revenue: 'Виручка',
  averageCheck: 'Середній Чек',
  save: 'Зберегти',
  skip: 'Пропустити',
  metr: 'м',
  km: 'км',
  can_come_back_later: 'Ви можете повернутися до цього кроку пізніше',
  million_short: 'млн',
  do_select: 'Вибрати',
  thousand_short: 'т',
  do_change: 'Змінити',
  first_name: "Ім'я",
  required_fields: "Обов'язкові стовпці",
  do_repeat: 'Повторити',
  do_check: 'Перевірити',
  last_name: 'Прізвище',
  middle_name: 'По батькові',
  trade_point: 'Торгова точка',
  trade_point_count: 'Кількість точок',
  role: 'Роль',
  next_step: 'Наступний крок',
  yes: 'Так',
  no: 'Немає',
  do_skip_and_finish: 'Пропустити та закінчити',
  do_end_registration: 'Завершити реєстрацію',
  do_skip_and_end_registration: 'Пропустити і завершити реєстрацію',
  from: 'Від',
  before: 'До',
  do_cancel: 'Скасувати',
  format: 'Формат',
  do_download_file: 'Завантажити файл',
  total: 'Разом',
  pick_or_input: 'Виберіть варіант або продовжуйте введення',
  search: 'Пошук',
  step: 'Крок',
  all: 'Всі',
  price: 'Ціна',
  here: 'Тут',
  go_to: 'Перейти',
  price_with_currency2: 'Ціна, ₽*',
  do_create: 'Створити',
  active_status_yes: 'Активний',
  active_status_no: 'Неактивний',
  pack_min: 'упак',
  go_to_page: 'Перейти на сторінку',
  do_send: 'Відправити',
  yesterday: 'Вчора',
  today: 'Сьогодні',
  you: 'Ви',
  main_page: 'Головна',
  logout: 'Вийти',
  shortcut_week: '7 днів',
  shortcut_month: '31 день',
  among: 'З',
  time_zone: 'Часовий пояс',
  pick_time_zone: 'Виберіть тимчасову зону',
  ready: 'Готово',
  extended_types_store: 'Магазин',
  extended_types_refrigerator: 'Мiкромаркет',
  extended_types_store_online: 'Інтернет-Магазин',
  extended_types_cafe: 'Кафе',
  extended_types_production_with_delivery: 'Виробництво з доставкою',
  extended_types_filling_station: 'АЗС',
  extended_types_cigarettes: 'Сигаретний шафа',
  extended_types_coffee_machine: 'Кофемашина',
  stage_type_title: 'Додайте торгову точку',
  stage_type_edit_title: 'Основна інформація',
  stage_type_subtitle: 'Виберіть тип торгової точки',
  stage_type_description:
    'Виберіть вашу категорію: магазин, кафе або іншу із запропонованого списку. Детальніше про можливості кожної категорії тут',
  stage_type_label_phone: 'Телефон торгової точки',
  address: 'Адреса',
  stage_type_label_floor: 'Поверх',
  stage_type_store_radius: 'Радіус дії↵системи Scan&amp;Go',
  stage_type_refrigerator_radius: 'Радіус з якого буде↵відкриватися двері',
  stage_type_cigarettes_radius: 'Радіус з якого буде↵відкриватися двері',
  stage_type_filling_station_radius: 'Радіус дії↵системи Briskly',
  stage_type_coffee_machine_radius: 'Радіус дії↵кавомашини',
  stage_design_description:
    'Виберіть, як буде виглядати ваша торгова точка в додатку B-Pay. Або завантажте логотип. Він буде відображатися в картці товару',
  stage_design_label_select: 'Виберіть дизайн',
  stage_design_create_new: 'Створити новий дизайн',
  agreement: 'Угода',
  promo_briskly: 'Промо-матеріали Briskly',
  fuel: 'Паливо',
  date_input_placeholder: 'дд.мм.рррр',
  finish_registration: 'Завершіть реєстрацію',
  purchases_quantity: 'Кількість покупок',
  profit: 'Дохід',
  do_open_new_trade_point: 'Відкрити нову точку',
  support: 'Служба підтримки',
  profile: 'Особистий кабінет',
  error_modal_title: 'Неможливо активувати торгову точку',
  design_title: 'Дизайн',
  card_is_missing: "Не прив'язана",
  your_logo: 'Ваш логотип',
  your_logo_in_format: 'Ваш логотип у форматі',
  preview_for_app: 'Превью для магазину у додатку',
  enter_text: 'Введіть текст',
  pick_background: 'Виберіть фон',
  little_background: "Фон для маленького прев'ю у форматі",
  big_background: "Фон для великого прев'ю у форматі",
  do_change_preview: 'Змінити превью',
  mock_address: 'р. Москва, вул. Академіка Янгеля, д. 26',
  store_photos: 'Фото торгової точки',
  schedule: 'Розклад',
  schedule_title: 'Розклад',
  work_schedule: 'Розклад роботи',
  schedule__description:
    'Встановіть розклад роботи вашого мiкромаркета: в який час він відкривається для покупок, а в який час блокується.',
  do_download_from_template: 'Завантажити шаблон',
  start_day_after_visiting_courier: "Починати робочий день після відвідування кур'єра",
  save_as_template: 'Зберегти як шаблон',
  do_enter_name: 'Введіть назву',
  week_mon: 'Пн',
  week_tue: 'Вт',
  week_wed: 'Ср',
  week_thu: 'Чт',
  week_fri: 'Пт',
  week_sat: 'Сб',
  week_sun: 'Вс',
  week_all_day: 'Всі дні',
  permanently: 'Цілодобово',
  weekend: 'Вихідний',
  do_add_break: 'Додати перерва',
  schedule_from: 'з',
  schedule_to: 'за',
  templates: 'Шаблони',
  create_employees_title: 'Додавання співробітників',
  employees_description:
    "На цьому екрані ви додаєте співробітників своєї торгової точки, вибирайте їх із списку або заносьте дані. Призначайте їм ролі у відповідності з обов'язками. За замовчуванням керівник підключений до всіх ролей. Детальніше у розділі довідки.",
  pick_employees: 'Виберіть співробітників',
  delete_employee_modal_title: 'Ви збираєтеся видалити співробітника',
  employees_selected: 'Вибрано',
  employee_change_modal_title: 'Зміна даних',
  employee_create_modal_title: 'Додавання співробітника',
  employee_change_modal_role_title: 'Призначте роль на этойт торговій точці',
  employee_create_modal_role_title: 'Виберіть ролі',
  add_employee: 'Додати співробітника',
  role_administrator_name: 'Адміністратор',
  role_administrator_access: 'Доступ до повного функціоналу особистого кабінету',
  role_courier_name: "Кур'єр",
  role_courier_access:
    "Доступна можливість відкривати холодильник, міняти товар, робити фото, завантажувати фото в інтерфейс кур'єра",
  role_checker_name: 'Чекер',
  role_checker_access:
    'Приймає замовлення покупців і збирає їх в торговому залі для відправки клієнтові',
  employees: 'Співробітники',
  to_add_new_employee: 'Додати нового співробітника',
  requisites_title: 'Реквізити',
  requisites_description:
    'Додайте реквізити вашої торгової точки. Вони потрібні для відправки грошей на ваш розрахунковий рахунок. Дані компанії будуть вказані при видачі чека клієнтам.',
  requisites_single_description:
    'В цьому розділі вказані всі реквізити ваших поточних торгових точок, а також ви можете додати нові. Це необхідно для відправки грошей на розрахунковий рахунок і під час видачі чека клієнтам. Виручені від продажу кошти надходять на розрахунковий рахунок раз на добу.',
  pick_requisites: 'Вибрати реквізити',
  requisites_company_info: 'Дані про компанії',
  requisites_client_info: 'Дані про клієнта',
  requisites_fio: 'ПІБ',
  requisites_phone: 'Номер телефону',
  requisites_mail: 'Електронна пошта',
  requisites_account_number: 'Номер рахунку',
  requisites_current_account_number: 'Номер розрахункового рахунку',
  requisites_bank_bic: 'БИК банку',
  requisites_bic: 'БИК',
  add_new_requisites: 'Додати нові реквізити',
  adding_new_requisites: 'Додавання нових реквізитів',
  requisites_cheque: 'Касовий чек',
  requisites_non_cash: 'Безготівковими',
  requisites_buyer_phone: 'Телефон покупця',
  requisites_items: 'Товари',
  requisites_item: 'Товар',
  requisites_provider_info: 'Дані постачальника',
  requisites_name: 'Найменування',
  requisites_inn: 'ІПН або найменування юридичної особи',
  requisites_step1: 'Тип зайнятості',
  requisites_step2: 'Персональні дані',
  requisites_step2_legal: 'Персональні дані керівника',
  requisites_step3: 'Реквізити організації',
  requisites_step4: 'Платіжна інформація',
  requisites_pick_employment_type: 'Виберіть тип зайнятості',
  employment_type_individual: 'ІП',
  employment_type_legal: 'Організація',
  requisites_position: 'Посада (як у статуті)',
  passport_first_page: 'Перша сторінка паспорта',
  passport_second_page: 'Сторінка з пропискою',
  passport_series: 'Серія',
  passport_number: 'Номер',
  passport_issue_date: 'Дата видачі',
  passport_authority_code: 'Код підрозділу',
  passport_authority_name: 'Найменування підрозділу, що видав документ',
  passport_citizenship: 'Громадянство',
  passport_registration_place: 'Адреса реєстрації (як у паспорті)',
  passport_birth_place: 'Місце народження',
  passport_birth_date: 'Дата народження',
  requisites_capital: 'Статутний капітал, ₽',
  requisites_tax_system: 'Виберіть систему оподаткування',
  tax_system_general: 'ОСН',
  tax_system_simplified_1: 'УСН (доходи-витрати)',
  tax_system_simplified_2: 'УСН (доходи-6%)',
  requisites_org_phone: 'Телефон організації',
  requisites_company_iname: "Ім'я компанії",
  requisites_okopf_code: 'OKOPF',
  requisites_ogrn: 'OGRN',
  requisites_okved_code: 'OKVED',
  requisites_bank_account_info: 'Дані про банківський рахунок',
  requisites_cheque_hint: 'Тепер ви можете подивитися як буде виглядати чек вашої торгової точки.',
  requisites_recipient_bank_name: 'Банк одержувача',
  requisites_bank_correspondent_account: 'Корр. рахунок',
  requisites_missing: 'Відсутні',
  want_to_deliver: 'Хочете доставляти свої товари за допомогою своєї нашій доставки?',
  delivery_own_radius: 'Радіус власної доставки',
  delivery_briskly_radius: 'Радіус доставки партнерами Briskly',
  delivery_own: 'Власна доставка',
  delivery_briskly: 'Доставка партнерами Briskly',
  delivery_both: 'Обидва варіанти',
  delivery_own_tariffs: 'Тарифи за власною доставки',
  delivery_briskly_tariffs: 'Тарифи на доставку партнерами briskly',
  delivery_briskly_description:
    'Зверніть увагу, якщо встановлена вами вартість доставки для клієнта буде менше, ніж фактична вартість, наприкінці місяця вам прийде рахунок на оплату цієї різниці. Якщо ваша вартість буде більше, то вам прийдуть гроші в розмірі цієї різниці.',
  delivery_briskly_price: 'Вартість доставки партнерами Briskly',
  delivery_pickup: 'Можливість самовивозу',
  delivery_tariffs: 'Тарифи по доставці',
  order_sum: 'Сума замовлення',
  delivery_cost: 'Вартість доставки',
  delivery_invalid_rates_text:
    'Інтервали сум замовлення повинні бути різними, між ними різниця повинна бути не більше 1 рубля.',
  do_add_rate: 'Додати умову',
  delivery_warning_modal_title: 'Ви збираєтеся змінити спосіб доставки',
  delivery_warning_modal_body: 'Після збереження, попередній спосіб доставки буде вилучений',
  delivery_cost_free: 'Безкоштовно',
  delivery_cost_discount_percent: 'Знижка від ціни доставки у відсотках',
  delivery_cost_discount_fixed: 'Знижка від ціни доставки в рублях',
  delivery_cost_fixed: 'Фіксована ціна',
  delivery_cost_unknown: 'Доставку оплачує клієнт',
  offer: 'Оферта',
  do_download_offer: 'Скачати оферту',
  do_sign_document: 'Підписати документ',
  code_from_sms: 'Код з смс',
  try_again_in: 'Якщо код не прийшов, спробуйте ще раз через',
  in_seconds: 'секунд',
  do_send_code_again: 'Відправити повторно код',
  do_send_confirmation_code: 'Відправити код підтвердження',
  planogram: 'Планограма',
  planogram_block_hint: 'Необхідно створити каталог',
  will_be_deleted: 'Буде вилучено',
  planogram_photo: 'Фото планограмы',
  do_add_items_on_shelf: 'Додайте товари на полицю',
  your_micromarket: 'Ваш микромаркет',
  shelf: 'полиця',
  shelf_is_overflow: 'На цій полиці більше не поміститься',
  do_add_items_on_another_shelfs: 'додайте товари на інші полки',
  do_add_shelf: 'додати полку',
  going_to_remove_all_items: 'Ви собираетсь видалити всі товари з полиці',
  going_to_remove_shelf: 'Ви собираетсь видалити полку',
  planogram_item: 'товар',
  planogram_list: 'Список планограм',
  do_download_new_planogram: 'Завантажити нову планограма',
  group_by: 'Групувати за',
  period: 'Період',
  order_status: 'Статус замовлення',
  pick_store: 'Виберіть магазин',
  option_selection_by_number: 'Вибірка за номером',
  paid: 'Оплачено',
  processed: 'Оброблений',
  refund: 'Повернення',
  refund_awaiting: 'В очікуванні повернення',
  refund_error: 'Помилка повернення',
  removed: 'Вилучений',
  quantity: 'Кількість',
  year: 'Рік',
  id_local: 'ІД',
  order_number: 'Номер замовлення',
  client: 'Клієнт',
  item_quantity: 'Кількість товарів',
  items_sufix: 'товарів',
  commission: 'Комісія',
  income: 'Прибуток',
  item_price: 'Ціна за товар',
  period_start: 'Початок періоду',
  period_end: 'Кінець періоду',
  sum_with_discount: 'Сума зі знижкою',
  sum: 'Сума',
  orders: 'Замовлення',
  clients: 'Клієнти',
  unique_clients: 'Унікальні денні клієнти',
  sales: 'Продажу',
  completed_order: 'Завершений замовлення',
  refund_awaiting_from_client: 'Очікується доплата з клієнта',
  service_one_s: '1C',
  service_iiko: 'Айко',
  service_iiko_biz: 'Айко Біз',
  service_moy_sklad: 'Мій Склад',
  service_supermag: 'Супермаг',
  service_yml: 'YML',
  service_xlsx: 'XLSX',
  unit_id: 'OKEI (Од. Вимірювання)',
  something_wrong_try_again: 'Щось пішло не так. Спробуйте ще раз.',
  use_suggestions_begin: 'Скористайтеся нашими',
  use_suggestions_action: 'підказками',
  use_suggestions_end: 'для інтеграції системи',
  payment_description:
    'Відправте запит на додавання способу оплати відповідальній особі магазину, де буде встановлений сигаретний шафа.',
  send_request: 'Відправити запит',
  do_change_payment_method: 'Змінити спосіб оплати',
  current_card: 'Поточна карта',
  follow_us_begin: 'Підписуйтесь на нас',
  follow_us_end: 'у соцмережах',
  bpay_title: 'Скачайте додаток b-pay',
  bpay_subtitle: 'Купуйте без черг',
  checker_title: 'Використовуйте додаток ЧЕК-ЕР',
  checker_subtitle: 'Збирайте замовлення ваших клієнтів віддалено',
  valid_required: "Обов'язково",
  valid_article_required: "Артикул обов'язковий",
  valid_min_3: 'Мінімум 3 символи',
  valid_min_1: 'Мінімум 1 символ',
  valid_min_6: 'Мінімум 6 символів',
  valid_max_60: 'Максимум 60 символів',
  valid_max_5: 'Максимум 5 символів',
  valid_max_6: 'Максимум 6 символів',
  valid_max_13: 'Максимум 13 символів',
  valid_max_20: 'Максимум 20 символів',
  valid_max_32: 'Максимум 32 символу',
  valid_max_350: 'Максимум 350 символів',
  valid_special_symbols_not_allow: 'Спецсимволи неприпустимі',
  valid_mail_format: 'Електронна пошта у форматі: welcome@brskl.io',
  valid_phone_format: 'Телефон у форматі +7 (123) 456 78 90',
  valid_no_less_1_percent: 'Не менше 1 %',
  valid_no_more_99_percent: 'Не більше 99 %',
  module_tittle: 'Модуль',
  briskly_module: 'Briskly Модуль',
  module_description:
    "Briskly модуль — це мозок вашого мiкромаркета. При покупці вам видали код, який необхідно прив'язати до аккаунту. Код можна подивитись",
  module_to_buy:
    'Якщо Ви ще не оплатили Мiкромаркет (холодильник + Briskly Модуль) або окремо Briskly Модуль для вашого холодильника,',
  module_link_to_buy: 'зробіть це зараз',
  indicate_divice_code: 'Введіть код з пристрою',
  module_data: 'Дані про Briskly модулі',
  network_status: 'Стан мережі',
  working: 'Працює',
  not_working: 'Не працює',
  lock_status: 'Статус замку',
  lock_close: 'Закритий',
  lock_open: 'Відкритий',
  air_temp: 'Температура повітря',
  last_time_in_network: 'Останній раз в мережі',
  lock_control: 'Управління замком',
  module_error_title: 'Дані недоступні. Це могло відбутися з-за',
  module_check: 'Перевірити модуль',
  buy_module: 'Купівля Briskly Модуля',
  to_retry: 'Повторити спробу',
  setup_module_in_you_ref: 'Установка системи у ваш холодильник',
  smart_ref_all_options: 'Розумний холодильник «під ключ»',
  popular_format: 'Популярний формат',
  big_market_with_all_options: 'Великий кіоск «під ключ»',
  lock_mount: 'Установка ел. замку',
  connect_to_b_pay: 'Підключення до додатка B-Pay',
  goods_integration: 'Інтеграція асортименту',
  temperature_sensor: 'Датчики температури',
  spy_camera: 'Камера спостереження',
  refregerator_in_set: 'Холодильник в комплекті',
  trade_point_branding: 'Брендинг мiкромаркета',
  electronic_lock: 'Електронний замок',
  refregerator_with_two_doors: 'Холодильник з двома стулками',
  module_apply: 'Подати заявку',
  left_a_little: 'Залишилося небагато',
  fill_in_the_required_sections_for_your_tt_start_working:
    "Будь ласка, заповніть обов'язкові розділи, щоб ваша торгова точка почала працювати",
  continue_filling: 'Продовжити заповнення',
  copy_link: 'Скопіювати посилання',
  lock: 'Замок',
  last_check: 'Остання перевірка',
  stages_description_delivery: 'Виберіть один з варіантів доставки',
  stages_description_design:
    'Зараз ваша торгова точка в додатку B-pay виглядає стандартно. Щоб виділитися, ви можете змінити дизайн',
  stages_description_employees: '',
  stages_description_exchange: 'Завантажте свої товари в каталог, щоб клієнти змогли побачити їх.',
  stages_description_briskly_module:
    'Всі торгові точки відображаються в системі Briskly тільки після підключення Briskly Модуля.',
  stages_description_offer:
    'Вам потрібно підписати договір оферти, щоб користуватися Briskly кабінетом',
  stages_description_planogram:
    'Розташуйте на полицях мiкромаркета товари, як їх будуть бачити ваші клієнти.',
  stages_description_requisites: 'Статус: перевіряються.↵Перевірка займає 1-3 робочих дні.',
  stages_description_schedule: 'Щоб торгова точка працювала, заповніть поля розкладу.',
  stages_description_type: '',
  stages_description_fuel: '',
  stages_description_payment:
    'Клієнт ще не підключив картку для оплати.↵ Скопіюйте це посилання і відправте йому, якщо ще не зробили цього.',
  trade_point_registation_complite: 'Реєстрація завершена',
  congratulations_your_tt_is_ready_to_go: 'Вітаємо! Ваша торгова точка готова до роботи',
  fine: 'Відмінно',
  congratulations_your_tt_is_ready_we_need_check:
    'Вітаємо! Ви завершили реєстрацію! Тепер нам потрібно 1-3 робочих дня, щоб перевірити ваші реквізити. Після цього вам буде відкритий доступ до торговельної точки.',
  download_qr_code: 'Скачати QR-код ',
  download_sticker: 'Скачати етикетку',
  download_table_banner: 'Скачати настільний банер',
  do_edit_sufix: 'Редагування',
  delete_trade_point: 'Видалити торгову точку',
  this_action_cant_canceled: 'Це приведе до безповоротного видалення',
  employees_title: 'Співробітники',
  planogram_title: 'Планограма',
  payment_method: 'Спосіб оплати',
  payment_status: 'Статус оплати',
  temperature_setup: 'Налаштування температури',
  temperature_setup_title:
    'Налаштуйте температурний режим роботи вашого мiкромаркета. Коли температура всередині мiкромаркета буде виходити за встановлені вами межі, ми надішлемо вам повідомлення.',
  time_of_ordering: 'Час замовлення',
  Video: 'Відео',
  video_by_order: 'Відео до замовлення',
  pick_date: 'Виберіть дату',
  video_recording_date: 'Дата запису відео',
  video_not_found: 'Відео не знайдено',
  download: 'Завантажити',
  is_signet: 'Підписано',
  documents: 'Документи',
  is_doesnt_signed: 'Не підписаний',
  is_touched: 'Переглянутий',
  to_assign: 'Підписати',
  time_stamp: 'Час додавання',
  type: 'Тип',
  offer_for_partners: 'Оферта для партнерів',
  tariff_plans: 'Тарифні плани',
  personal_account_rules: 'Правила користування ЛК',
  reports: 'Звіти',
  report_about_clients: 'Звіт по клієнтам',
  report_about_items: 'Звіт по товарах',
  report_about_orders: 'Статистика по замовленнях',
  report_about_sales: 'Статистика з продажу',
  report_about_unique_clients: 'Статистика по унікальним денним клієнтам',
  do_download_data: 'Завантажити дані',
  do_download_promo_briskly: 'Завантажити промо-матеріали Briskly',
  download_file_format: 'Формат скачування',
  your_account_has_run_out_of_money: 'На вашому рахунку закінчились гроші.',
  do_top_up_an_account: 'Поповнити баланс',
  air_temperature: 'Температура повітря',
  inside: 'Всередині',
  outside: 'Зовні',
  status: 'Статус',
  actual_address: 'Фактична адреса',
  card: 'Карта',
  phone: 'Телефон',
  credentials: 'Реквізити',
  delivery: 'Доставка',
  do_edit: 'Редагувати',
  most_popular_items: 'Популярні товари',
  item_name: 'Назва товару',
  price_with_currency: 'Ціна, ₽',
  revenue_with_currency: 'Виручка, ₽',
  sold_items_with_amount: 'Продано, шт',
  option_unknown: 'Не встановлено',
  option_min: 'Мінімальний',
  option_max: 'Максимальний',
  option_discount_percent: 'Знижка у відсотках',
  option_discount_amount: 'Знижка в рублях',
  option_discount_new_price: 'Нова ціна',
  option_usage_common: 'Загальна знижка',
  option_usage_first_order: 'Перша покупка без урахування часу',
  option_usage_first_order_after_timestamp: 'Перша покупка після призначеного тимчасового ліміту',
  discount_size: 'Розмір знижки',
  discount: 'Знижка',
  excluded_cost: 'Виключена вартість',
  applying_features: 'Особливості застосування',
  applying: 'Застосування',
  do_add_discount: 'Додати знижку',
  editing_of_discount: 'Редагування знижки',
  adding_of_discount: 'Додавання нової знижки',
  applying_order: 'Порядок застосування',
  save_changes: 'Зберегти зміни',
  do_save: 'Зберегти',
  excluded_cost_tooltip:
    'Сума в чеку на яку не діє знижка. ↵Приклад: знижка 10% на покупку понад 500руб (виключена вартість 500р) ↵Сума покупки 900р знижка 10% діє на 400р і складе 40р.',
  client_show_settings: 'Налаштування показу для клієнтів',
  do_show: 'Показувати',
  title: 'Заголовок',
  description: 'Опис',
  image: 'Зображення',
  add_image_proposal: 'Додайте зображення',
  adding_of_discount_group: 'Додавання набору знижок',
  editing_of_discount_group: 'Редагування набору знижок',
  do_add_discount_group: 'Додати набір',
  do_add_discount_group_v2: 'Додати набір знижок',
  do_assign_discount_group_to_store: 'Призначити знижки торговельним точкам',
  discounts: 'Знижки',
  do_delete: 'Видалити',
  not_delete: 'Не видаляти',
  do_reset_changes: 'Скасувати зміни',
  you_are_trying_to_delete_discount: 'Ви збираєтеся видалити знижку',
  you_are_trying_to_delete_discount_group: 'Ви збираєтеся видалити набір знижок',
  discount_assign: 'Призначення знижок',
  store_name: 'Назва торгової точки',
  discount_group: 'Набір знижок',
  title_for_clients: 'Заголовок для клієнтів',
  is_enabled: 'Включений',
  is_disabled: 'Вимкнено',
  is_enabled_female: 'Включена',
  is_disabled_female: 'Вимкнено',
  show_for_clients: 'Показувати для клієнтів',
  type_of_discount: 'Тип знижки',
  set: 'Набір',
  around_the_clock: 'Цілодобово',
  doesnt_act: 'Не діє',
  good_photo: 'Фотографія товару',
  volume: 'Обсяг',
  step_reminder: 'Ви можете повернутися до цього кроку пізніше.',
  category: 'Категорія',
  categories: 'Категорії',
  category_sufix: 'Категорій',
  create_catalog: 'Створіть каталог',
  create_catalog_label:
    'У вашому акаунті може бути кілька каталогів (наприклад, для мiкромаркета торгує готовою їжею або торгує замороженою продукцією). Вибираючи відповідний каталог, ви швидко переключаєтеся з одного списку товарів на інший. Тут необхідно створити цей каталог.',
  catalog_name: 'Назва каталогу',

  portion: 'порція',
  non_tax: 'Не оподатковується',
  payment: 'Розрахунковий',
  standard: 'Стандартний',
  взагалі: 'Всього',
  units: 'Одиниці виміру',
  age_limit: 'Вікове обмеження,років',
  composition_of_item: 'Склад',
  calories: 'Калорії',
  optional_description: "Опис (необов'язково)",
  vat_mode: 'Режим ПДВ',
  vat_rate: 'Ставка ПДВ, %',
  block_with_modifications: 'Блок з модами',
  item_add_action: 'Додавання товару',
  item_edit_action: 'Редагування товару',
  dimension: 'Розмірність',
  item_add_action2: 'Додайте товари',
  add_and_edit_catalog: 'Додавання/редагування каталогу',
  need_select_catalog: 'Потрібно вибрати каталог',
  do_add_more_categories: 'Додати категорію',
  goods_name: 'Найменування товару',
  expiration_date: 'Термін придатності',
  barcode: 'Код',
  sticker_quantity: 'Кількість наклейок',
  manufacturing_by: 'Вироблено',
  h: 'г',
  d: 'д',
  m: 'м',
  y: 'р',
  composition: 'Склад',
  do_not_specifiedSuffix: 'Не вказана',
  do_not_specified: 'Не вказано',
  fats: 'Жири',
  proteins: 'Білки',
  carbohydrates: 'Вуглеводи',
  calories_full: 'Енергетична цінність',
  ccal: 'ккал',
  nutritional_value_per_100g_of_product: 'Харчова цінність(на 100г продукту)',
  at_the_goods: 'У товару',
  with_barcode: 'З баркодом',
  error_with_bar_code: 'Помилка з генерацією Баркода!',
  maybe_doest_valid_control_digit: 'Можливо: Неправильна контрольна цифра!',
  add_goods: 'Додати товари',
  do_delete_category: 'Видалити категорію',
  be_deteted: 'Буде вилучено',
  need_detals: 'Потрібно уточнення',
  good_with_no_valid_barcode_need_write_it:
    'У даного товару не валідний Штрих-код, ↵ заповніть його',
  go_to_edit: 'Перейти до редагування',
  later: 'Пізніше',
  good_suffix: 'продукт',
  article: 'Артикул',
  photo: 'Фото',
  dimension_units: 'Од. вимірювання',
  active: 'Активний',
  sticker: 'Стікер',
  items: 'Товари',
  do_create_catalog: 'Створити каталог',
  do_create_new_catalog: 'Створити новий каталог',
  stickers_print: 'Друк стікерів',
  write_name_of_new_catalog: 'Введіть назву нового каталогу',
  write_name_of_new_category: 'Введіть назву нової категорії',
  do_add: 'Додати',
  do_go_back: 'Назад',
  do_continue: 'Продовжити',
  catalog: 'Каталог',
  doesnt_have_category: 'Не має категорії',
  stickers: 'Стікери',
  do_print_stickers: 'Роздрукувати стікери',
  manufacturer_name: 'Найменування виробника',
  manufacturing_address: 'Адреса виготовлення',
  production_date: 'Дата виготовлення',
  time: 'Час',
  hours: 'Годинник',
  minutes: 'Хвилини',
  days: 'Дні',
  weeks: 'Тижня',
  months: 'Місяці',
  years: 'Роки',
  do_apply: 'Застосувати',
  general_expiration_date: 'Загальний термін придатності',
  amount_of_stickers_per_position: 'Кількість наклейок для однієї позиції',
  do_mark_all: 'Зазначити всі',
  do_download_agreement: 'Скачать угода',
  personal_lowercase: 'особистий',
  cabinet_lowercase: 'кабінет',
  choose_option_or_continue_input: 'Виберіть варіант або продовжуйте введення',
  unknown_organization: 'Невідома організація',
  organization_info: 'Дані про організацію',
  organization_name: 'Назва організації',
  organization_phone: 'Телефон організації',
  inn: 'ИНН',
  head_of_company: 'Керівник',
  registration_date: 'Дата реєстрації',
  okved: 'ОКВЕД',
  capital: 'Статутний капітал',
  taxes_system: 'Система оподаткування',
  do_write_inn: 'Введіть ИНН',
  osno: 'ОСН',
  usn1: 'УСН (доходи-витрати)',
  usn2: 'УСН (доходи 6%)',
  innLabel1: '{label} або назва організації',
  innLabel2: '{label} або прізвище',
  діючий: 'Діючий',
  first_name_placeholder: 'Михайло',
  second_name: 'Прізвище',
  second_name_placeholder: 'Михайлов',
  third_name: 'По батькові',
  third_name_placeholder: 'Михайлович',
  position_label: 'Посада (як у статуті організації)',
  position_placeholder: 'Генеральний директор',
  okopf_name_short_label: 'Правова форма у скороченому вигляді',
  okopf_name_full_label: 'Правова форма у повному вигляді',
  ooo_full_name: 'Товариство з обмеженою відповідальністю',
  name_short_with_opf: 'Коротка назва з ОПФ',
  name_short_with_opf_placeholder: "ТОВ 'Багратіон'",
  type_of_company_placeholder: 'ТОВ, ІП',
  name_short: 'Коротка назва',
  name_short_placeholder: 'Багратіон',
  ogrn: 'OGRN',
  first_passport_page: 'Перша сторінка паспорта',
  passport_page_with_place_of_live: 'Сторінка з пропискою',
  bank_account: 'Номер розрахункового рахунку',
  bank_account_register_placeholder:
    'Введіть 20 цифр рахунку в банку (якщо рахунок складається з 22 цифр, вкажіть перші 20)',
  bik: 'БИК',
  bik_register_placeholder: 'Введіть 9 цифр номеру БИК банку',
  bank_credentials: 'Реквізити банку',
  receivers_bank: 'Банк одержувача',
  bank_account_number: 'Номер рахунку',
  bank_correspondent_number: 'Корр. рахунок',
  your_name: "Ваше ім'я",
  phone_number: 'Номер телефону',
  type_of_employment: 'Тип зайнятості',
  ip_as_type_of_organization: 'ІП',
  legal_as_type_of_organization: 'Організація',
  date: 'Дата',
  inventory_system: 'Система обліку запасів',
  integration_errors: 'При інтеграції виникли помилки',
  integration_with_system_were_succeed: 'Інтеграція з {system} пройшла успішно',
  do_change_info: 'Змінити інформацію',
  do_skip: 'Пропустити',
  select_other_system: 'Вибрати систему',
  select_other_system2: 'Вибрати іншу систему',
  do_check_integration: 'Перевірити інтеграцію',
  integration_entry_label:
    ' Тут ви додаєте товари, які будете продавати. Це можна зробити декількома способами',
  integration_entry_label_option1: 'Інтегрувати систему Briskly з вашою системою обліку.',
  integration_entry_label_option2: 'Завантажити файл з товарами.',
  integration_entry_label_option3: 'Вибрати існуючий каталог',
  integration_entry_label_option4: 'Додати товари вручну',
  select_catalog: 'Виберіть каталог',
  upload_or_drag_file: 'Завантажте або перетягніть файл',
  integration: 'Інтеграція',
  integration_file_label:
    'Для правильної завантаження каталогу, співвіднесіть наші назви полів з тим, як вони названі у вас.',
  item_add_action2_label: 'Створюйте категорії і додайте в них товари',
  write_category_name: 'Введіть назву категорії',
  do_create_category: 'Створити категорію',
  actions: 'Дії',
  do_send_claim: 'Надіслати скаргу',
  claim_text: 'Текст скарги',
  for_order: 'до замовлення',
  order: 'Замовлення',
  client_id: 'ID клієнта',
  total_discount: 'Сума знижки',
  order_info: 'Інформація про замовлення',
  claim_sended: 'Скарга відправлена',
  total_amount: 'Загальна вартість',
  order_composition: 'Склад замовлення',
  additionally: 'Додатково',
  fields_are_optional: "поля обов'язкові для заповнення",
  show_by: 'Показати по',
  do_briskly_module_active_new: 'Активувати новий модуль',
  do_briskly_module_finish: 'Завершити',
  briskly_module_reassign_title: "Прив'язка нового модуля",
  briskly_module_reassign_message: "Ви точно хочете прив'язати новий Briskly модуль?",
  error: 'Помилка',
  fail_bind_employee_to_store: 'Не вдалося прикріпити співробітника',
  fail_unbind_employee_to_store: "Не вдалося відв'язати співробітника",
  fail_init_employee_store_list: 'Не вдалося завантажити дані про торгових точках',
  fail_onboarding_init_company_info: 'Не вдалося завантажити дані компанії, заповнюються раніше',
  unknown_error: 'Щось пішло не так',
  delete_category_text_confirm:
    'Ця дія призведе до видалення каталогу, а товари, які знаходяться в ньому виявляться в категорії усе""',
  delete_catalog_text_confirm_1: 'При видаленні каталогу',
  delete_catalog_text_confirm_2:
    'з нього будуть видалені всі категорії і товари, що перебувають у ньому',
  do_collapse: 'Згорнути',
  exchange_settigns: 'Налаштування обміну',
  create_catalog_label_2:
    "'У вашому акаунті може бути кілька каталогів. \\nНаприклад для мiкромаркета торгує готовою їжею або торгує замороженою продукцією. \\nВибираючи відповідний каталог, ви швидко переключаєтеся з одного списку товарів на інший. \\nТут можна поміняти цей каталог.',",
  report_about_item: 'Статистика по товару',
  report_order_id: 'Номер Замовлення',
  mailings: 'Розсилки',
  do_add_mailing: 'Створити розсилку',
  mailing_new: 'Нова розсилка',
  mailing_edit: 'Редагування розсилки',
  mailing_status_complete: 'Виконана',
  mailing_status_saved: 'Збережена',
  mailing_status_moderation: 'На модерації',
  mailing_status_wait: 'Чекає відправки',
  mailing_status_cancelled: 'Скасовано',
  mailing_status_ready: 'Сплатити',
  mailing_add_description:
    'Додайте вашу розсилку. Вкажіть час відправки аудиторію клієнтів і текст повідомлення.',
  mailing_moderation_description:
    'Після проходження модерації вам потрібно буде оплатити розсилку. Прийміть до уваги, що модерація проходить протягом 1-2 робочих днів, оплата надходить на рахунок до 2 робочих днів. Враховуйте це при плануванні розсилки.',
  mailing_name: 'Назва розсилки',
  mailing_date: 'Дата відправки',
  mailing_audience: 'Аудиторія для проведення розсилки',
  mailing_client_count: 'Кількість клієнтів',
  mailing_cost: 'Вартість розсилки',
  mailing_text: 'Текст розсилки',
  mailing_only_save: 'Зберегти, але не запускати',
  mailing_save_and_start: 'Запустити розсилку',
  mailing_invalid_time: 'Встановіть інтервал часу з 9:00 до 21:00',
  mailing_may_not_pass_moderation: 'Ваша розсилка може не встигнути пройти модерацію.',
  mailing_all_clients: 'Всі клієнти',
  mailing_created_filters: 'Готові фільтри',
  mailing_selecting_filters: 'Вибір фільтра',
  mailing_save_filters: 'Зберегти фільтри',

  stock_balances: 'Рештки запасів',
  do_add_items_list: 'Додавання елементів як списку',
  accounting_for_stock_balances: 'Облік залишків запасів',
  rebase_catalogs: 'Видалення каталогу> Редагування торгових точок',
  clarification_in_items_side_bar:
    '* Якщо товари у вашому файлі вже розділені на категорії, просто виберіть Каталог і завантажте файл, Категорії будуть створені автоматично.',
  do_download_xlsx: 'Завантажити .xlsx файл',
  stock_accounting_balances_info:
    'Щоб отримати актуальну інформацію про кількість та вартість товарів у ваших торгових точках, використовуйте функцію« Облік залишків запасів',
  or: 'Або',
  category_info_tip:
    'Використовуйте цю функцію, щоб швидше, автоматично заповнювати нову категорію продуктами.',
  good_must_be_in_catalog_or_category: "Товар повинен бути прив'язаний до каталогу або категорії.",
  items_add_side_bar_info:
    'Якщо вам потрібно створити новий каталог або категорію в поточному каталозі, перейдіть до розділу',
  do_delete_catalog: 'Видалити каталог',
  delete_catalog: 'Видалити каталог',
  trade_point_edit: 'Редагування точок продажу',
  rebase_catalog_1: 'Щоб видалити каталог "{name}", вам потрібно',
  rebase_catalog_2: "прив'язати нові каталоги до всіх торгових точок",
  rebase_catalog_3: 'у таблиці нижче.',
  cant_start_from_dot: 'Неможливо почати з крапки',
  aprove_category_delete: 'Ви збираєтеся видалити категорію',
  delete_category_warn: 'Видалення категорії призведе до видалення всіх продуктів цієї категорії',
  delete_catalog_confirm_text_5:
    'Ви збираєтеся видалити порожній каталог без категорій або продуктів',
  delete_catalog_confirm_text_6: 'Ви збираєтеся видалити каталог, що містить товари',
  trade_point_type_name: 'Тип торгової точки',
  select_new_catalog: 'Виберіть новий каталог',
  barcoders_prefix_for_weight_good: 'Префікси штрих-коду ваги товару',
  barcoders_prefix_info:
    'За значенням цього префіксу система розпізнає товар за вагою, виділяючи цифровий код товару та його вагу в грамах. Доступні префікси від 20 до 29. Якщо префікс не вказаний, товари будуть розглядатися як відрядні товари. ',
  age_limit_2: 'рокiв',
  age_limit_1: 'Віковi обмеження,',

  requisites_personal_data_descr_part_1:
    'ми просимо вас заповнити дану інформацію, щоб мати можливість щодня переводити вам виручені кошти з продажів вашої торгової точки. Для цього, необхідно надати відомості, аналогічні тим, що ви надаєте при підключенні еквайрингу.',
  requisites_personal_data_descr_part_2:
    'Ваші дані запитуються на вимогу ЦБ і банку-еквайєра. Вони будуть зберігатися в зашифрованому вигляді. Також, компанія Briskly складається в реєстрі операторів персональних даних Роскомнадзора.',
  add_good: 'додати товар',
  external_id: 'зовнішній id',
  do_save_and_go_back: 'Зберегти і повернутися до списку',
  print_shelf_life: 'друкувати термін придатності',
  succes_saved: 'успішно збережено',
  quantity_cut: 'Кількість',
  additional_data_about_good: 'додаткові дані про товар',
  doest_not_req_field: "Необов'язкові поля",
  do_upload_photo: 'завантажити фото',
  upload_good_list: 'Завантажити товари списком',
  show_filters: 'показати фільтри',
  hide_filters: 'приховати фільтри',
  available_table_filters: 'фільтри таблиці',
  deletion: 'видалення',
  item_warning_info:
    "Товар обов'язково повинен бути прив'язаний до будь-якого каталогу і категорії.",
  item_create_info:
    'якщо вам необхідно створити новий каталог або категорію в поточному каталозі, перейдіть в розділ',
  aprove_catalog_delete: 'ви збираєтеся видалити каталог',
  delete_catalog_text_confirm_3:
    'при видаленні каталогу будуть видалені всі категорії і товари всередині нього.',
  delete_catalog_text_confirm_4:
    "для видалення катлога спочатку необхідно прив'язати новий каталог до всіх торгових точок замість видаляється.",
  go_to_edit_store: 'Перейти до редагування торгових точок',
  choose_trade_point: 'виберіть торгову точку',
  do_catalog_edit: 'редагування каталогу',
  do_category_edit: 'редагування категорії',
  category_warn_text_warn: "Категорія повинна бути прив'язана до будь-якого каталог.",
  category_warn_text: 'якщо вам необхідно створити новий каталог перейдіть за посиланням',
  category_warn_text_link: 'створити новий каталог.',
  catalog_rebase_info: "більше не прив'язаний до жодної з торгових точок, тепер ви можете",

  no_design_to_select: 'немає збережених дизайнів',
  valid_fill_the_field: 'заповніть поле',
  valid_incorrect_date: 'Невірна дата',
  valid_incorrect_phone: 'невірний телефон',
  valid_passport_series: 'Невірна серія',
  valid_passport_number: 'невірний номер паспорта',
  valid_passport_authority_code: 'невірний код підрозділу',
  valid_inn: 'виберіть ІПН',
  valid_address: 'виберіть адресу',
  valid_bic: 'виберіть Бик',
  valid_account_number: 'довжина розрахункового рахунку повинна бути 20 символів',
  valid_maximum: 'максимальне число символів',
  valid_select_some_filter: 'виберіть хоча б один фільтр',
  altogether: 'Всього',
  acting: 'діючий',
  need_to_select_catalog_and_category:
    'для створення товару N потрібно вибрати N каталог і категорію',
  categories_of_catalogs: 'категорії каталогу',
  add_catalog: 'додати каталог',
  add_category: 'Додати категорію',
  add_catalog_action: 'додавання каталогу',
  add_category_action: 'додавання категорії',
  category_name: 'назва категорії',
  catalogs: 'каталоги',
  search_by_categories: 'пошук за категоріями',
  search_by_catalogs: 'пошук за каталогами',
  warning: 'Увага!',
  write_new_catalog_name: 'введіть назву каталогу',
  write_new_category_name: 'введіть назву категорії',
  add_catalog_warning:
    "ви можете прив'язати каталог до декількох торгових точок, для цього скористайтеся" +
    ' для додавання додаткового поля вибору торгової точки.',
  add_catalog_info:
    'ви можете додати відразу кілька категорій в один каталог, для цього скористайтеся' +
    ' для додавання додаткового поля для введення назви нової категорії. ',
  product_product_date: 'друкувати дату виготовлення',
  mailing_going_to_delete: 'ви збираєтеся видалити розсилку',
  address_doesnt_correct: 'адреса не коректний',
  no_saved_templates: 'немає збережених шаблонів',
  add_schedule_and_save_as_template: 'додайте опції і потім збережіть їх як шаблон',
  service_mts_kassa: 'МТС Каса',
  create_new_catalog: 'створити новий каталог',
  use_existing_catalog: 'використовувати існуючий каталог',
  log_out_question: 'ви точно хочете закінчити поточну сесію?',
  item_photo_note:
    '(ви можете завантажити своє фото товару або залишити фото за замовчуванням показане нижче)',
  store_doesnt_have_catalog:
    "у торгової точки {name} немає прив'язки до каталогу перейти до редагування ?",
  success: 'Успішно',

  uploaded_image_min_width: 'Мінімальна ширина зображення',
  uploaded_image_max_width: 'Максимальна ширина зображення',
  uploaded_image_min_height: 'Мінімальна висота зображення',
  uploaded_image_max_height: 'Максимальна висота зображення',
  allowed_types: 'Дозволені типи',
  max_file_size: 'Максимальний розмір файлу',
  standard_no_suf: 'Стандарт',
  orders_and_video: 'Замовлення і відео',
  payment_type_undefined: 'Не визначений',
  orders_not_exist: 'У ваших торгових точках ще немає ордерів.',
  service_csv: 'csv',
  ok: 'Ok',
  price_with_currency_inject: 'Ціна, {currency}',
  sum_with_currency_inject: 'Сума, {currency}',
  total_amount_price_with_currency_inject: 'Загальна вартість, {currency}',
  total_amount_sum_with_currency_inject: 'Загальна сума, {currency}',
  time_of_creation: 'Час створення',
  find_by_order_number: 'Пошук за номером замовлення',
  id_trade_point: 'ID магазину',
  item_id: 'ID елементів',
  good_barcode: 'Штрих-код товару',
  option_discount_percent_cut: 'Знижка, %',
  create_refund: 'Повернення грошей',
  created_at: 'Час створення',
  customer_id: 'Клієнт',
  store: 'Магазин',

  valid_minimum: 'Мінімальне число символів',

  coords_lng: 'Довгота',
  coords_lat: 'Широта',

  invalid_intervals_filled: 'Поля не заповнюються',

  invalid_intervals_to_less_then_from: 'Неприпустимі інтервали',
  invalid_intervals_intersections: 'Інтервали не повинні перекриватися',
  valid_max_250: 'Максимум 250 символів',
  postal_code: 'Поштовий Індекс',
  module_description_temp:
    "Модуль Briskly-це мозок вашого мікроринку. При покупці вам був виданий код, який повинен бути прив'язаний до вашого аккаунту.",
  value_must_be_more_than_zero: 'Це значення має бути більше 0',
  valid_min_13: 'Мінімум 13 символів',
  dosesnt_correct_number: 'Неприпустиме число',
  success_deleted: 'Успішно віддалено',
  do_return_back: 'Повертатися назад',
  abort_procedure: 'Процедура переривання',
  abort: 'Викинувши',
  maybe_data_not_saved: 'Внесені зміни не можуть бути збережені',
  empty_catalog: 'Каталог порожній',
  fileupload_page_info:
    'Якщо товари у вашому файлі вже розділені на категорії, просто виберіть каталог і завантажте файл, категорії будуть створені автоматично.',
  do_download_xlsx_example: 'Скачавши .xlsx приклад',
  valid_max_11: 'Максимум 11 символів',
  payment_type: 'Вид платежу',
  app: 'Докладання',
  terminal: 'Термінал',
  order_with_id_inject: 'ID замовлення {orderId}',
  refund_with_order_id_inject: 'Повернення коштів: iD замовлення {orderId}',
  barcode_can_includes_only_digits: 'Тільки цифри',
  copy_to_clipboard: 'Копіювання в буфер',
  copied_to_clipboard: 'Скопійовано в буфер обміну',
  about_order: 'Про замовлення',
  refund_title: 'Повернення грошей',
  amout_goods_to_refund: 'Всього товарів, що підлягають поверненню',
  amount_goods_payment: 'Сума повернення',
  technical_support: 'Технічна підтримка',
  add_refund: 'Додати повернення',
  view_refund: 'Перегляд повернень',
  onboarding_virtual_terminal: 'Віртуальний термінал',
  virtual_terminal: 'Віртуальний термінал',
  select_virtual_terminal: 'Виберіть віртуальний термінал',
  virtual_terminal_description:
    'Заповніть реквізити вашого віртуального терміналу, вони необхідні для зарахування коштів на ваш рахунок, а також для формування чека для клієнта.',
  add_new_virtual_terminal: 'Додати термінал',
  do_create_refund: 'Повернення грошей',

  delete_goods_modal_info: 'і {stringsQuantity} ... більше продуктів',
  client_show_settings_2: 'Налаштування відображення мобільного додатка',
  no_changes_to_update: 'Ніяких змін для збереження',
  for_go_to_exchange_need_say_store: 'Виберіть торгову точку',
  link_to_showcase: 'Посилання на вітрину продукту',
  catalog_successfully_added: 'Каталог успішно додано',
  refund_has_been_created: 'Повернення коштів створено',
  no_trans_action: 'Без операцій',
  no_refund_text_info: 'Ніяких повернень за це замовлення не було.',
  date_should_not_earlier: 'Дата не повинна бути раніше',
  date_should_not_later: 'Дата повинна бути не пізніше',
  cant_play_video: 'Не можу відтворити відео',
  check_refund_possibility: 'Перевірте варіанти повернення грошей',
  refund_helper_text: 'Щоб швидко знайти товар, введіть його Id, штрих-код або назву',
  no_one_good_selected: 'Немає товару, обраного для повернення.',
  errors_occurred_while_creating_the_refund: 'При створенні повернення виникли помилки:',
  refund_by_id_goes_successed: 'Повернення коштів за ID замовлення {refundId} пройшов успішно',
  refund_by_id_goes_accept: 'Підтвердьте повернення для ID замовлення {refundId}',
  refund_technical_support_notice:
    "Щоб зробити повернення грошей, будь ласка, зв'яжіться зі службою технічної підтримки.",
  refund_money_back_notice:
    'Гроші будуть зараховані на карту клієнта протягом 2 днів з моменту повернення.',
  refund_ready_title: 'Повернення замовлення',
  refund_check_success_info: 'Повернення може бути успішно створений',
  refund_check_success_info_with_order_id: 'Повернення замовлення ID {orderId} створено успішно',
  approve_refund: 'Підтвердьте повернення',
  video_not_available: 'Відео недоступне',
  video_tooltip_error:
    "Відеофайл пошкоджений або тимчасово недоступний. Будь ласка, зв'яжіться зі службою підтримки",
  do_upload: 'Скачавши',
  amout_for_refund: 'Всього товарів для повернення',

  // delete_goods_modal_info: '',
  // client_show_settings_2: '',
  // no_changes_to_update: '',

  // for_go_to_exchange_need_say_store: '',
  // link_to_showcase: '',
  // catalog_successfully_added: '',
  // refund_has_been_created: '',
  // no_trans_action: '',
  // no_refund_text_info: '',
  // date_should_not_earlier: '',
  // date_should_not_later: '',
  // cant_play_video: '',
  // check_refund_possibility: '',
  // refund_helper_text: '',
  // no_one_good_selected: '',
  // errors_occurred_while_creating_the_refund: '',

  // refund_by_id_goes_accept: '',
  // refund_technical_support_notice: '',
  // refund_money_back_notice: '',
  // refund_ready_title: '',
  // refund_check_success_info: '',
  // refund_check_success_info_with_order_id: '',
  // approve_refund: '',
  // video_not_available: '',
  // video_tooltip_error: '',
  // do_upload: '',
  // amout_for_refund: '',
  does_not_correct_control_sum: 'Невірна контрольна сума',
  active_status: 'Очікує оплати',
  paid_status: 'Оплачено',
  refund_status: 'Повернуто',
  refund_awaiting_status: 'Очікується повернення',
  refund_error_status: 'Помилка повернення',
  refund_partial_status: 'Частково повернутий',
  removed_status: 'Вилучений',

  online_store_layouts: 'Макети для друку на мікромаркеті ',
  promo_stickers: 'Наклейка',
  promo_side_stickers: 'Бічні Наклейки',
  promo_on_glas: 'На склі',
  promo_on_advertising_block: 'На рекламний блок',

  promo_without_ad_block: '(немає рекламного блоку)',
  promo_with_ad_block: '(з рекламним блоком)',
  coords_lng: 'Довгота',
  coords_lat: 'Широта',

  attention: 'Увага',
  requisites_skip_warning:
    'Якщо ви пропускаєте крок вибору / додавання реквізитів, ваша торгова точка буде працювати в тестовому режимі.',
  select_full_party_description:
    'Ви можете вибрати вже раніше використані реквізити або додати нові просто заповнивши форму!',
  select_partial_party_description: 'Підключіть свій віртуальний термінал до Особистого кабінету!',
  pick_terminal: 'Вибрати термінал',
  add_new_full_party: 'Додати нові',
  add_new_partial_party: 'Додати новий',
  swift_bic: 'SWIFT / BIC',
  secure3d: '3DS',
  non_secure3d: 'non 3DS',
  full_party_choosing: 'Вибір реквізитів',
  partial_party_choosing: 'Вибір терміналу',
  requisites_and_terminals: 'Юридичний особа',
  terminals: 'Віртуальні термінали',
  full_party_status_active: 'Активний',
  full_party_status_pending: 'Перевіряти',
  full_party_status_replacement: 'Заміняти',
  full_party_edit: 'Редагування реквізитів',
  partial_party_edit: 'Редагування віртуального терміналу',
  adding_new_terminal: 'Додавання нового терміналу',
  add_full_party: 'Додати реквізити',
  add_partial_party: 'Додати віртуальний термінал',
  full_party_edit_warning:
    'Після зміни, реквізити будуть перевірятися протягом деякого часу. Нові реквізити будуть збережені за торговими точками відразу, за підсумком перевірки.',
  partial_party_edit_warning:
    'Після зміни термінал буде перевірятися протягом деякого часу. Новий термінал буде збережений за торговими точками відразу, за підсумком перевірки.',
  kpp: 'КПП',
  upload_logo_in_format: 'Завантажте ваш логотип у форматі',
  upload_logo_with_min_size: 'Мінімальний розмір картинки',
  upload_logo_with_min_file_size: 'Максимальний розмір файлу',
  BYN: 'Білоруський рубль Долар(BYN)',
  USD: 'США(USD)',
  EUR: 'Євро(EUR)',
  KZT: 'Казахстанський тенге(KZT)',
  CAD: 'Канадський долар(CAD)',
  CNY: 'Китайський юань(CNY)',
  RUB: 'Російський рубль(RUB)',
  TRY: 'Турецька Ліра(TRY)',
  UAH: 'Українська гривня(UAH)',
  GBP: 'ФУНТ СТЕРЛІНГІВ(GBP)',
  CHF: 'Швейцарський франк(CHF)',
  currency: 'Валюта',

  bank_name: 'Найменування Банку',
  company_name: 'Повне найменування компанії',

  serial_number: 'Серійний номер',
  coffee_reeboot: 'Перезавантажити',
  coffee_reeboot_coffee_machine: 'Перезавантажити кофемашину',
  coffee_reeboot_text: 'Перезавантаження пристрою допомагає вирішити ряд проблем.',
  defer_service: "Відкласти обов'язкове сервісне обслуговування",
  coffee_postpone: 'Відклавши',
  coffee_service_maintenance_text: 'Сервісна умова буде відкладено до наступного включення.',
  block_drinks: 'Блокувати приготування напоїв',
  coffee_block_text:
    'Кавоварка остентся активною, але приготування напоїв буде недоступно для клієнтів',
  block_drink_change: 'Блокувати зміну параметрів напоїв',
  coffee_props_block_text:
    'Доступно в момент приготування. Після активації, клієнти не зможуть додавати зміни в стандартний напій.',
  block_menu_access: 'Блокувати доступ в меню користувача',
  coffee_menu_text: 'Клієнти не зможуть використовувати меню на кавомашині.',
  block_drinks_when_offline:
    "Блокувати приготування напоїв при відсутності  n з'єднання з сервером більше 48 годин",
  coffee_reloading: 'Виконується перезавантаження...',
  search_by_items: 'Пошук по товарах',
  coffee_item_photo: 'Фотографія товара',
  coffee_item_photo_info:
    '(ви можете завантажити своє фото товару або залишити фото за замовчуванням показане нижче)',
  coffee_item_type: 'Тип напою',
  item_recept: 'Рецептура товару',
  value_ml: 'мл',
  cup_with_units_injection: 'Стакан {cupsize} мл',
  drink_with_units_injection: 'Напій {drinksize} мл',
  coffee_foam: 'Молочна піна',
  coffee_milk: 'Молоко',
  coffee_water: 'Вода',
  coffee_coffee_size: 'Кава',
  return_values_to_default: 'Відновити значення за замовчуванням',
  price_must_be_more_then_inject: 'Ціна повинна бути більше {price}',
  coffee_item_photo_info__temp:
    "Ви можете залишити фото за замовчуванням або вибрати з доступних по кнопці 'вибрати'",
  block_drink_changes: 'Блокувати зміну параметрів напоїв',

  file_name_uploaded: 'Файл {fileName} завантажено',
  columns: 'Стовпці',
  file_upload_rules: 'Вимоги до файлу для завантаження товарів:',
  file_upload_rules_1: 'Формат — .xlsx;',
  file_upload_rules_2: "Файл не повинен містити об'єднані комірки.",
  file_upload_rules_3: 'Назви полів повинні займати не більше одного рядка.',
  file_upload_rules_4:
    "У разі помилок в полях' одиниці виміру 'і' Кол... '— буде автоматичні проставлятися'1 штука'.",
  are_mandatory: "є обов'язковими",
  file_upload_page_info:
    'Для правильного завантаження каталогу, співвіднесіть наші назви полів з тим, як вони названі у вас.',
  edit_file_and_try_again: 'Виправити помилки і спробувати знову',
  do_stop_now: 'Завершити зараз',
  no_save_upload_remember:
    "Натискаючи кнопку' Завершити зараз', ви підтверджує...некоректному відображенню даних про Ваші товари.",
  exchange_drpops_with_exception: 'При інтеграції відбулися помилки',
  good_must_be_in_catalog_or_category_rewrite:
    "Товар обов'язково повинен бути прив'язаний до будь-якого каталогу.",
  fileupload_page_info_rewrite:
    '* Якщо товари в вашому файлі вже розбиті на категорії, досить вибрати Каталог і завантажити файл, категорії при цьому будуть створені автоматично.',
  loading: 'Завантаження...',
  show_detail_info: 'Показати детальну інформацію',
  file_upload_success_info:
    "Натискаючи 'Додати', ви підтверджуєте завантаження товарів у відповідні Каталог і категорії.",
  be_created: 'Були створені',
  selected_catalog: 'Вибраний каталог',
  file_uploaded: 'Завантаження файлу',
  was_successful: 'пройшла успішно',
  and: 'i',
  valid_min: 'Мінімум {min}',
  value_mm: 'мм',
  unit_796: 'штука',
  unit_657: 'Продукт',
  unit_111: "об'єм (мл)",
  unit_112: 'Літр',
  unit_163: 'Грам(г)',
  unit_625: 'Листя',
  unit_704: 'Набір',
  unit_715: 'Пара',
  unit_166: 'кілограм (кг)',
  unit_736: 'Рулон',
  unit_778: 'Упаковка',

  item_from_upload_info: 'Для швидкого добаленія товарів в каталог скористайтеся функцією',
  promo_briskly_link: 'Промо-матеріали',
  store_name_placeholder: 'Найменування торговельної точки',
  serial_number: 'Серійний номер',
  coffee_reeboot: 'Перезавантажити',
  coffee_reeboot_coffee_machine: 'Перезавантажити кофемашину',
  coffee_reeboot_text: 'Перезавантаження пристрою допомагає вирішити ряд проблем.',
  defer_service: "Відкласти обов'язкове сервісне обслуговування",
  coffee_postpone: 'Відклавши',
  coffee_service_maintenance_text: 'Сервісна умова буде відкладено до наступного включення.',
  block_drinks: 'Блокувати приготування напоїв',
  coffee_block_text:
    'Кавоварка остентся активною, але приготування напоїв буде недоступно для клієнтів',
  block_drink_change: 'Блокувати зміну параметрів напоїв',
  coffee_props_block_text:
    'Доступно в момент приготування. Після активації, клієнти не зможуть додавати зміни в стандартний напій.',
  block_menu_access: 'Блокувати доступ в меню користувача',
  coffee_menu_text: 'Клієнти не зможуть використовувати меню на кавомашині.',
  block_drinks_when_offline:
    "Блокувати приготування напоїв при відсутності  n з'єднання з сервером більше 48 годин",
  coffee_reloading: 'Виконується перезавантаження...',
  search_by_items: 'Пошук по товарах',
  coffee_item_photo: 'Фотографія товара',
  coffee_item_photo_info:
    '(ви можете завантажити своє фото товару або залишити фото за замовчуванням показане нижче)',
  coffee_item_type: 'Тип напою',
  item_recept: 'Рецептура товару',
  value_ml: 'мл',
  cup_with_units_injection: 'Стакан {cupsize} мл',
  drink_with_units_injection: 'Напій {drinksize} мл',
  coffee_foam: 'Молочна піна',
  coffee_milk: 'Молоко',
  coffee_water: 'Вода',
  coffee_coffee_size: 'Кава',
  return_values_to_default: 'Відновити значення за замовчуванням',
  price_must_be_more_then_inject: 'Ціна повинна бути більше {price}',
  coffee_item_photo_info__temp:
    "Ви можете залишити фото за замовчуванням або вибрати з доступних по кнопці 'вибрати'",
  block_drink_changes: 'Блокувати зміну параметрів напоїв',
  heating: 'Опалення',
};
