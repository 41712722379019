export default [
  {
    path: '/onboarding',
    name: 'onboarding$onboarding',
    component: () => import('@/apps/onboardingv2/views/Entry'),
    children: [
      {
        path: 'party',
        name: 'onboarding$party',
        component: () => import('@/apps/onboardingv2/views/Party/Party'),
      },
      {
        path: 'terminal',
        name: 'onboarding$terminal',
        component: () => import('@/apps/onboardingv2/views/Terminal/Terminal'),
      },
    ],
  },
];
