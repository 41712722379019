import { bus } from 'shared/core';
import store from '@/core/store';

class ChatEvent {
  init() {
    bus.on('core$chat/open', this.onChatOpen);
    bus.on('core$chat/close', this.onChatClose);
    bus.on('core$chat/newMessage', this.onNewMessage);
  }

  onChatOpen = () => {
    store.dispatch('core$chat/open');
  };

  onChatClose = () => {
    store.dispatch('core$chat/close');
  };

  onNewMessage = (message) => {
    store.dispatch('core$chat/addMessage', { message });
  };
}

export default new ChatEvent();
