import { companyAPI } from '@/core/api/company';
import { getI18n } from '@/core/i18n';
import { bus } from 'shared/core';

const state = {
  storeCatalogs: [],
};

const getters = {
  catalogs: (state) => state.storeCatalogs,
  catalogsForItem: (state) => state.storeCatalogs.slice(1),
};

const actions = {
  mutateCatalogsStore: async ({ commit }, { id, name, method }) => {
    commit('_mutateCatalogsStore', { id, name, method });
  },

  async addCatalog({ commit }, { name, barcode_prefix_2, currency_code }) {
    const i18n = getI18n();
    try {
      const catalog = await companyAPI.createCatalog({
        name,
        barcode_prefix_2,
        currency_code,
      });

      commit('_addcatalog', catalog);

      bus.emit('core$notifications/add', {
        meta: { message: i18n.t('catalog_successfully_added') },
      });
      return catalog;
    } catch (error) {
      console.error(error);
    }
  },

  fetchCatalogs: async ({ commit }, opts = {}) => {
    const { q, order } = opts;
    const payload = {
      limit: 200,
      ...(order && { order }),
      ...(q && { q }),
    };
    try {
      const { items } = await companyAPI.getCatalogList(payload);
      commit('setCatalogs', { items });
    } catch (error) {
      bus.emit('core$notifications/add', { error });
    }
  },

  deleteCatalog: async ({ commit, dispatch }, catalog) => {
    try {
      await companyAPI.deleteCatalogById({ id: catalog.id });
      commit('setDeletedCatalog', catalog);
      await dispatch('items$items/setActiveCatalog', { catalog: 0 }, { root: true });
      await dispatch('items$items/setActiveCategory', { category: 0 }, { root: true });
      await dispatch('items$items/setCurrentPage', 1, { root: true });
      return { status: 'ok' };
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setCatalogs: (state, { items }) => {
    const i18n = getI18n();
    state.storeCatalogs = [{ id: 0, name: i18n.t('all') }].concat(items);
  },

  setDeletedCatalog: (state, { id }) => {
    state.storeCatalogs = state.storeCatalogs.filter((cat) => cat.id !== id);
  },

  _addcatalog: (state, catalog) => {
    const isExist = state.storeCatalogs.find((c) => c.id === catalog.id);
    if (!isExist) state.storeCatalogs.push(catalog);
  },

  _mutateCatalogsStore: (state, { id, name, method }) => {
    if (method === 'update') {
      return (state.storeCatalogs = state.storeCatalogs.map((catalog) => {
        if (catalog.id === id) {
          return { ...catalog, id, name };
        }
        return catalog;
      }));
    }
    if (state.storeCatalogs.length !== 0) return;
    return state.storeCatalogs.push({ id, name });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
