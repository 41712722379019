export default {
  valid_upload_both_photos: 'Екі суретті де жүктеңіз',
  mailing_status_rejected: 'Қабылданбады',
  catalogs_and_categories: 'Каталогтар мен санаттар',

  filter_gender: 'Жынысы',
  filter_age: 'Жасы',
  filter_registered_days_ago: 'Тіркеу күні',
  filter_purchases_quantity: 'Сатып алу саны',
  filter_purchase_days_ago: 'Сатып алу жасы',
  filter_birth_date_month: 'Туған күн',

  gender_male: 'Ер',
  gender_female: 'Әйел',

  before_num: '{num} жасқа дейін',
  older_then: '{num} жастан асқан',

  before_days: '{_} күн | {_} күнге дейін | {_} күнге дейін',
  year_ago: 'Бір жыл бұрын',

  more_then: 'Толығырақ {num}',

  in_this_month: 'Осы айда',
  in_next_month: 'Келесі айда',

  select_employee_for_test_message: 'Тесттік хабарлама жіберетін қызметкерді таңдаңыз',
  filter_will_be_available_soon: 'Бұл функция жақын арада қол жетімді болады',

  documents_subtitle: `Бұл бөлімде сіз платформаның жұмыс ережелерімен және басқа да заңды құжаттармен таныс болуыңыз керек. <br /> <br /> Сонымен қатар, сіз және Брискли арасындағы дұрыс өзара әрекеттесу үшін қажетті құжаттардың қазіргі жағдайы көрсетілген. <br/> Соның ішінде SMS растау функциясы арқылы бірқатар құжаттарға қашықтықтан қол қоюға болады.`,

  reports_subtitle: `«Есептер» бөлімі бизнесіңіздің визуалды аналитикалық есептерін b: pay технологиялық платформасында құруға мүмкіндік береді. <br /> Сізді қызықтыратын параметрлер мен үлгілер туралы нақты, сенімді ақпарат алыңыз және осы мәліметтерге сүйене отырып, бизнес процестеріңізді жақсартыңыз, сауда нүктелерінің тиімділігін арттырыңыз және табыстарыңызды арттырыңыз!`,

  videos_subtitle: `Бұл бөлімде сіз өзіңіздің сауда нүктелеріңізде орнатылған камералардан бейне жазбаларды ала аласыз`,

  support_service_greeting: 'Сәлеметсіз бе! Біз сізге көмектесуге қуаныштымыз!',
  homeTitle: 'Басты экран',
  homeSubtitle: 'Бұл экран жақында статистика сату бойынша сіздің сауда орындарында',
  homeSubtitleWithStat: 'Бұл экранда бейнеленеді статистика сату бойынша сіздің сауда орындарында',
  homeReferalProgramNote:
    'Пользуешься өзі, пригласи друга. Ол тіркелетін жүйесі, сіз↵ кэшбэк алыңыз жылғы Briskly көлеміндегі % - ы',
  referalProgram: 'Реферальная бағдарламасы',
  trade_points: 'Сауда нүктелері',
  on_map: 'Картада',
  do_add_trade_point: 'Қосу сауда нүктесі',
  bind: 'Привязать',
  doesnt_select: 'Емес таңдалды',
  select_dates: 'Таңдаңыз күннен',
  trade_point_list: 'Сауда нүктелерінің тізімі',
  extended_types_washer_fluid: 'Омывающая сұйықтық',
  number: 'Нөмірі',
  cost: 'Құны',
  id: 'ID',
  do_open: 'Ашу',
  do_close: 'Жабу',
  do_search: 'Іздеу',
  results: 'Нәтижелері',
  by_list: 'Тізіммен',
  name: 'Атауы',
  continue: 'Жалғастыру',
  revenue: 'Түсім',
  averageCheck: 'Орташа Чек',
  save: 'Сақтау',
  skip: 'Елемеу',
  metr: 'м',
  km: 'км',
  can_come_back_later: 'Сіз қайтадан осы қадамға кейінірек',
  million_short: 'млн.',
  do_select: 'Таңдау',
  thousand_short: 'т',
  do_change: 'Өзгерту',
  first_name: 'Аты',
  required_fields: 'Міндетті бағандар',
  do_repeat: 'Қайталау',
  do_check: 'Тексеру',
  last_name: 'Тегі',
  middle_name: 'Әкесінің',
  trade_point: 'Сауда нүктесі',
  trade_point_count: 'Нүктелерінің саны',
  role: 'Рөлі',
  next_step: 'Келесі қадам',
  yes: 'Иә',
  no: 'Жоқ',
  do_skip_and_finish: 'Жіберіп және аяқтау',
  do_end_registration: 'Тіркеуді аяқтау',
  do_skip_and_end_registration: 'Жіберіп және тіркеуді аяқтау',
  from: 'От',
  before: 'Дейін',
  do_cancel: 'Жойылсын',
  format: 'Формат',
  do_download_file: 'Файлды жүктеу',
  total: 'Жиыны',
  pick_or_input: 'Нұсқасын таңдаңыз немесе жалғастырыңыз енгізу',
  search: 'Іздеу',
  step: 'Қадам',
  all: 'Барлық',
  price: 'Бағасы',
  here: 'Мұнда',
  go_to: 'Көшу',
  price_with_currency2: 'Баға ₽*',
  do_create: 'Құру',
  active_status_yes: 'Белсенді',
  active_status_no: 'Белсенді емес',
  pack_min: 'бума',
  go_to_page: 'Бетіне өту',
  do_send: 'Жіберу',
  yesterday: 'Кеше',
  today: 'Бүгін',
  you: 'Сіз',
  main_page: 'Басты бет',
  logout: 'Шығу',
  shortcut_week: '7 күн',
  shortcut_month: '31 күні',
  among: 'Бірі',
  time_zone: 'Уақыт белдеуі',
  pick_time_zone: 'Таңдаңыз уақытша аймаққа',
  ready: 'Дайын',
  extended_types_store: 'Дүкен',
  extended_types_refrigerator: 'Тоңазытқыш',
  extended_types_store_online: 'Интернет-Дүкен',
  extended_types_cafe: 'Кафе',
  extended_types_production_with_delivery: 'Өндіру, жеткізілуімен',
  extended_types_filling_station: 'ЖМС',
  extended_types_cigarettes: 'Темекі шкаф',
  extended_types_coffee_machine: 'Кофемашина',
  stage_type_title: 'Қосыңыз сауда нүктесі',
  stage_type_edit_title: 'Негізгі ақпарат',
  stage_type_subtitle: 'Түрін таңдаңыз сауда нүктелері',
  stage_type_description:
    'Таңдап санат: дүкен, кафе немесе басқа ұсынылған тізімнен. Жан-жақты мүмкіндіктері туралы әр санаты мындасыз',
  stage_type_label_phone: 'Телефон сауда нүктелері',
  address: 'Мекен жайы',
  stage_type_label_floor: 'Қабат',
  stage_type_store_radius: 'Радиусы↵жүйесін Scan&amp;Go',
  stage_type_refrigerator_radius: 'Радиусы байланысты болады↵ашылатын есік',
  stage_type_cigarettes_radius: 'Радиусы байланысты болады↵ашылатын есік',
  stage_type_filling_station_radius: 'Радиусы↵жүйесін Briskly',
  stage_type_coffee_machine_radius: 'Радиусы↵кофемашины',
  stage_design_description:
    'Таңдаңыз қалай көрінетіні және сіздің сауда нүктесі қосымшада B-Pay. Немесе жүктеп алыңыз логотипі. Ол бейнеленетін болады карточка тауардың',
  stage_design_label_select: 'Таңдаңыз дизайн',
  stage_design_create_new: 'Жаңа дизайн',
  agreement: 'Келісім',
  promo_briskly: 'Промо материалдар Briskly',
  fuel: 'Отын',
  date_input_placeholder: 'кк.мм.жжжж',
  finish_registration: 'Тіркеуді аяқтаңыз',
  purchases_quantity: 'Сатып алу саны',
  profit: 'Табыс',
  do_open_new_trade_point: 'Открыть новую точку',
  support: 'Қолдау қызметі',
  profile: 'Жеке кабинет',
  error_modal_title: 'Мүмкін емес іске қосу сауда нүктесі',
  design_title: 'Дизайн',
  card_is_missing: 'Емес байланыстырылады',
  your_logo: 'Сіздің логотипі',
  your_logo_in_format: 'Сіздің логитип форматында',
  preview_for_app: 'Кезекті қарсылас-дүкені үшін-қосымшада',
  enter_text: 'Мәтінін енгізіңіз',
  pick_background: 'Таңдаңыз фон',
  little_background: 'Фон үшін кішкентай кезекті қарсылас -  форматында"',
  big_background: 'Фон үшін үлкен кезекті қарсылас -  форматында"',
  do_change_preview: 'Өзгертуге кезекті қарсылас -"',
  mock_address: 'Мәскеу қ., к. Академик Янгель, д. 26',
  store_photos: 'Фото сауда нүктелері',
  schedule: 'Кестесі',
  schedule_title: 'Кестесі',
  work_schedule: 'Жұмыс кестесі',
  schedule__description:
    'Орнатыңыз жұмыс кестесі сіздің микромаркета: қандай ол үшін ашылатын сауда, ал қандай уақытта қолдана аласыз.',
  do_download_from_template: 'Жүктеу келген шаблондарды',
  start_day_after_visiting_courier: 'Бастау жұмыс күні өткеннен кейін бару, курьер',
  save_as_template: 'Шаблон ретінде сақтау',
  do_enter_name: 'Атауы енгізіңіз',
  week_mon: 'Дс',
  week_tue: 'Вт',
  week_wed: 'Ср',
  week_thu: 'Ср',
  week_fri: 'Жм',
  week_sat: 'Сб',
  week_sun: 'Әк',
  week_all_day: 'Барлық күндері',
  permanently: 'Тәулік бойы',
  weekend: 'Демалыс',
  do_add_break: 'Қосу үзіліс',
  schedule_from: 'с',
  schedule_to: 'бойынша',
  templates: 'Үлгілер',
  create_employees_title: 'Қосу қызметкерлердің',
  employees_description:
    'Бұл экранда сіз қосу қызметкерлерінің өз сауда нүктелері, таңдаңыз оларды тізімнен немесе заносите жаңа деректер. Назначайте атындағы рөлін, өкімдерінің, міндеттері. Әдепкі бойынша, жетекшісі қосылған барлық үлес қосты. Толығырақ анықтама.',
  pick_employees: 'Таңдаңыз қызметкерлерінің',
  delete_employee_modal_title: 'Сіз жою қызметкері',
  employees_selected: 'Таңдалған',
  employee_change_modal_title: 'Деректерді өзгерту',
  employee_create_modal_title: 'Қосу қызметкері',
  employee_change_modal_role_title: 'Тағайындаңыз рөлі этойт сауда нүктесінде',
  employee_create_modal_role_title: 'Таңдаңыз рөлі',
  add_employee: 'Қосу қызметкері',
  role_administrator_name: 'Әкімшісі',
  role_administrator_access: 'Барлық кіруге функционалына жеке кабинеті',
  role_courier_name: 'Курьер',
  role_courier_access:
    'Мүмкіндігі қолжетімді ашуға тоңазытқыш, не тауар, не фото, жүктеп алуға фото интерфейс курьер',
  role_checker_name: 'Чекер',
  role_checker_access:
    'Тапсырыстар қабылдайды сатып алушыларды жинайды, оларды сауда залында жіберу үшін клиентке',
  employees: 'Қызметкерлері',
  to_add_new_employee: 'Жаңа қызметкерді қосу',
  requisites_title: 'Деректемелері',
  requisites_description:
    'Қосыңыз деректемелері сіздің сауда нүктелері. Олар бізге жіберу үшін ақша сіздің шотыңызға. Компания деректері көрсетілген қолма-клиенттерге.',
  requisites_single_description:
    'Бұл бөлімде сіздің ағымдағы сауда нүктелеріңіздің барлық мәліметтері бар, сонымен қатар сіз жаңаларын қоса аласыз. Бұл ағымдағы шотқа ақша аудару үшін және клиенттерге чек берген кезде қажет. Сатудан түскен қаражат ағымдағы шотқа күніне бір рет аударылады.',
  pick_requisites: 'Таңдау деректемелері',
  requisites_company_info: 'Деректер компания туралы',
  requisites_client_info: 'Клиент туралы деректер',
  requisites_fio: 'Аты-ЖӨНІ',
  requisites_phone: 'Телефон нөмірі',
  requisites_mail: 'Электрондық пошта',
  requisites_account_number: 'Шот нөмірі',
  requisites_current_account_number: 'Есептік шот нөмірі',
  requisites_bank_bic: 'Банктің БСК',
  requisites_bic: 'БСК',
  add_new_requisites: 'Қосу жаңа деректемелері',
  adding_new_requisites: 'Жаңа деректемелерін қосу',
  requisites_cheque: 'Кассалық чек',
  requisites_non_cash: 'Безналичными',
  requisites_buyer_phone: 'Телефон сатып алушының',
  requisites_items: 'Тауарлар',
  requisites_item: 'Тауар',
  requisites_provider_info: 'Деректер өнім берушінің',
  requisites_name: 'Атауы',
  requisites_inn: 'СТН немесе заңды тұлғаның атауы',
  requisites_step1: 'Жұмыспен қамту типі',
  requisites_step2: 'Дербес деректер',
  requisites_step2_legal: 'Басшысының дербес деректері',
  requisites_step3: 'Ұйымның деректемелері',
  requisites_step4: 'Төлем ақпарат',
  requisites_pick_employment_type: 'Түрін таңдаңыз жұмыспен қамту',
  employment_type_individual: 'ЖК',
  employment_type_legal: 'Ұйымдастыру',
  requisites_position: 'Лауазымы (сияқты жарғысында)',
  passport_first_page: 'Бірінші бет паспорт',
  passport_second_page: 'Бет тіркелуге тиіс',
  passport_series: 'Серия',
  passport_number: 'Нөмірі',
  passport_issue_date: 'Берілген күні',
  passport_authority_code: 'Код подразделения',
  passport_authority_name: 'Бөлімшенің атауы, құжат берген',
  passport_citizenship: 'Азаматтығы',
  passport_registration_place: 'Тіркелген мекен-жайы (паспорттағыдай)',
  passport_birth_place: 'Туған жері',
  passport_birth_date: 'Туған күні',
  requisites_capital: 'Жарғылық капитал, ₽',
  requisites_tax_system: 'Таңдаңыз салық салу жүйесін',
  tax_system_general: 'НЕГ',
  tax_system_simplified_1: 'ІСН (кірістер-шығыстар)',
  tax_system_simplified_2: 'ІСН (кірістер-6%)',
  requisites_org_phone: 'Ұйымның телефоны',
  requisites_company_iname: 'Компанияның аты',
  requisites_okopf_code: 'OKOPF',
  requisites_ogrn: 'OGRN',
  requisites_okved_code: 'OKVED',
  requisites_bank_account_info: 'Деректер банктік шоты туралы',
  requisites_cheque_hint: 'Енді сіз қалай көрінеді чек сіздің сауда нүктелері.',
  requisites_recipient_bank_name: 'Алушының банкі',
  requisites_bank_correspondent_account: 'Корр. шот',
  requisites_missing: 'Жоқ',
  want_to_deliver: 'Келсе жеткізуге өз тауарларын пайдалана отырып, немесе біздің жеткізу?',
  delivery_own_radius: 'Радиусы өзіндік жеткізу',
  delivery_briskly_radius: 'Радиусы жеткізу серіктестермен Briskly',
  delivery_own: 'Меншікті жеткізу',
  delivery_briskly: 'Жеткізу серіктестермен Briskly',
  delivery_both: 'Екі',
  delivery_own_tariffs: 'Тарифтер бойынша өзіндік жеткізу',
  delivery_briskly_tariffs: 'Тарифтер жеткізу бойынша серіктестер briskly',
  delivery_briskly_description:
    'Назар аударыңыз, егер белгіленген сізбен құны жеткізу үшін клиенттің аз нақты құны, айдың соңында сізге келеді есебінен төлеуге, бұл айырма. Егер құны көп болса, онда сізге келеді мөлшерде ақша осы айырма.',
  delivery_briskly_price: 'Жеткізу құны серіктестермен Briskly',
  delivery_pickup: 'Өзі шығару мүмкіндігі',
  delivery_tariffs: 'Тарифтер бойынша жеткізу',
  order_sum: 'Тапсырыс сомасы',
  delivery_cost: 'Жеткізу құны',
  delivery_invalid_rates_text:
    'Арақашықтық сомасын тапсырыс тиіс әр түрлі, олардың арасында болуы тиіс айырмашылық болуы тиіс артық емес 1 рублі.',
  do_add_rate: 'Қосу шарты',
  delivery_warning_modal_title: 'Сіз өзгертуге жеткізу тәсілі',
  delivery_warning_modal_body: 'Сақтағаннан кейін, алдыңғы жеткізу тәсілі жойылады',
  delivery_cost_free: 'Тегін',
  delivery_cost_discount_percent: 'Жеңілдік бағасы жеткізу пайызбен',
  delivery_cost_discount_fixed: 'Жеңілдік бағасы жеткізу рублінде',
  delivery_cost_fixed: 'Тіркелген баға',
  delivery_cost_unknown: 'Жеткізуді төлейді клиент',
  offer: 'Оферта',
  do_download_offer: 'Скачать оферта',
  do_sign_document: 'Құжатқа қол қою',
  code_from_sms: 'Кодты смс',
  try_again_in: 'Егер код келді, көріңіз тағы бір рет арқылы',
  in_seconds: 'секунд',
  do_send_code_again: 'Жіберу кодын қайта',
  do_send_confirmation_code: 'Жіберу коды-растау',
  planogram: 'Планограмма',
  planogram_block_hint: 'Құру қажет каталог',
  will_be_deleted: 'Болады шығарылуы',
  planogram_photo: 'Фото планограммы',
  do_add_items_on_shelf: 'Қосыңыз тауарларға арналған сөре',
  your_micromarket: 'Сіздің микромаркет',
  shelf: 'сөре',
  shelf_is_overflow: 'Осы сөреде көп симайтын',
  do_add_items_on_another_shelfs: 'қосыңыз тауарлар басқа сөрелер',
  do_add_shelf: 'қосу сөре',
  going_to_remove_all_items: 'Сіз собираетсь жою барлық тауарлар сөрелер',
  going_to_remove_shelf: 'Сіз собираетсь жою сөре',
  planogram_item: 'тауар',
  planogram_list: 'Тізімі планограмм',
  do_download_new_planogram: 'Жүктеу, жаңа планограмму',
  group_by: 'Бойынша топтастыру',
  period: 'Кезең',
  order_status: 'Тапсырыс мәртебесі',
  pick_store: 'Таңдаңыз дүкен',
  option_selection_by_number: 'Іріктеме нөмірі бойынша',
  paid: 'Төленді',
  processed: 'Өңделді',
  refund: 'Қайтару',
  refund_awaiting: 'Күтіп қайтару',
  refund_error: 'Қате қайтару',
  removed: 'Алып тасталды',
  quantity: 'Саны',
  year: 'Жыл',
  id_local: 'ИД',
  order_number: 'Тапсырыс нөмірі',
  client: 'Клиент',
  item_quantity: 'Саны тауарларды',
  items_sufix: 'тауарларды',
  commission: 'Коммисиясы',
  income: 'Пайда',
  item_price: 'Тауар құны',
  period_start: 'Кезеңнің басындағы',
  period_end: 'Кезең соңына,',
  sum_with_discount: 'Сомасы жеңілдікпен',
  sum: 'Сомасы',
  orders: 'Тапсырыстар',
  clients: 'Клиенттер',
  unique_clients: 'Бірегей күндізгі клиенттер',
  sales: 'Сату',
  completed_order: 'Аяқталған тапсырыс',
  refund_awaiting_from_client: 'Күтілуде доплата клиенттен',
  service_one_s: '1C',
  service_iiko: 'Айко',
  service_iiko_biz: 'Айко Биз',
  service_moy_sklad: 'Менің Қоймасы',
  service_supermag: 'Супермаг',
  service_yml: 'YML',
  service_xlsx: 'XLSX',
  unit_id: 'OKEI (Бірл. Өлшем)',
  something_wrong_try_again: 'Нәрсе емес. Тағы да байқап көріңіз.',
  use_suggestions_begin: 'Пайдаланыңыз біздің',
  use_suggestions_action: 'подсказками',
  use_suggestions_end: 'үшін жүйесін интеграциялау',
  payment_description:
    'Сұрау жіберіңіз қосу төлем тәсілін жауапты адамына дүкен, онда орнатылған темекі шкаф.',
  send_request: 'Сұрау жіберу',
  do_change_payment_method: 'Ауыстыру тәсілі',
  current_card: 'Ағымдағы картасы',
  follow_us_begin: 'Бізге жазылыңыз',
  follow_us_end: 'әлеуметтік желілерде',
  bpay_title: 'Onedrive b-pay',
  bpay_subtitle: 'Алыңыздар кезексіз',
  checker_title: 'Қолданбасын пайдаланыңыз CHECK-ER',
  checker_subtitle: 'Тапсырыстар жинаңыз сіздің клиенттерге қашықтан',
  valid_required: 'Міндетті түрде',
  valid_article_required: 'Артикул міндетті',
  valid_min_3: 'Кем дегенде 3 символ',
  valid_min_1: 'Минимум 1 таңба',
  valid_min_6: 'Кем дегенде 6 символдан',
  valid_max_60: 'Максимум 60 рәміздер',
  valid_max_5: 'Максимум 5 символов',
  valid_max_6: 'Ең көп дегенде 6 символдан',
  valid_max_13: 'Максимум 13 рәміздер',
  valid_max_20: 'Максимум 20 символов',
  valid_max_32: 'Максимум 32 символ',
  valid_max_350: 'Максимум 350 рәміздер',
  valid_special_symbols_not_allow: 'Спецсимволы жол берілмейді',
  valid_mail_format: 'Электрондық пошта форматта: welcome@brskl.io',
  valid_phone_format: 'Телефон форматы: +7 (123) 456 78 90',
  valid_no_less_1_percent: 'Кем дегенде 1 %',
  valid_no_more_99_percent: 'Артық 99 %',
  module_tittle: 'Модуль',
  briskly_module: 'Briskly Модуль',
  module_description:
    'Briskly модуль — бұл сіздің миы микромаркета. Сатып алу кезінде жұмыста коды қажет привязать к аккаунту. Коды көруге болады',
  module_to_buy:
    'Егер Сіз тағы не оплатили Тоңазытқыш (тоңазытқыш + Briskly Модуль) немесе жеке Briskly Модуль сіздің тоңазытқыш,',
  module_link_to_buy: 'мұны қазір',
  indicate_divice_code: 'Кодын көрсетіңіз құрылғылары',
  module_data: 'Туралы деректер модулінде Briskly',
  network_status: 'Желі жағдайы',
  working: 'Жұмыс істейді',
  not_working: 'Жұмыс істемейді',
  lock_status: 'Мәртебесі құлып',
  lock_close: 'Жабық',
  lock_open: 'Ашылды',
  air_temp: 'Ауа температурасы',
  last_time_in_network: 'Соңғы рет желісінде',
  lock_control: 'Басқармасы замком',
  module_error_title: 'Деректер қол жетімді емес. Бұл еді өтуі үшін',
  module_check: 'Тексеру модулі',
  buy_module: 'Сатып Алу Briskly Модуль',
  to_retry: 'Қайталау',
  setup_module_in_you_ref: 'Жүйесін орнату сіздің тоңазытқыш',
  smart_ref_all_options: 'Ақылды тоңазытқыш под ключ""',
  popular_format: 'Танымал формат',
  big_market_with_all_options: 'Үлкен киоск под ключ""',
  lock_mount: 'Орнату эл. құлып',
  connect_to_b_pay: 'Қосу-қосымшаға B-Pay',
  goods_integration: 'Интеграция ассортиментін',
  temperature_sensor: 'Температура датчиктері',
  spy_camera: 'Камера бақылау',
  refregerator_in_set: 'Тоңазытқыш жиынтықта',
  trade_point_branding: 'Брендинг микромаркета',
  electronic_lock: 'Электрондық құлып',
  refregerator_with_two_doors: 'Тоңазытқыш екі створками',
  module_apply: 'Өтінім',
  left_a_little: 'Осталось немного',
  fill_in_the_required_sections_for_your_tt_start_working:
    'Толтырыңыз міндетті бөлімдер үшін сіздің сауда нүктесі жұмыс істей бастады',
  continue_filling: 'Толтыру жалғастыру',
  copy_link: 'Көшіруге сілтеме',
  lock: 'Құлып',
  last_check: 'Соңғы тексеру',
  stages_description_delivery: 'Опциялардың бірін жеткізу',
  stages_description_design:
    'Қазір сіздің сауда нүктесі қосымшада B-pay көрінеді стандартты түрде. Ерекшелену үшін, сіз дизайн өзгерту',
  stages_description_employees: '',
  stages_description_exchange: 'Жүктеңіз өз тауарларын каталог үшін клиенттер көре алды оларды.',
  stages_description_briskly_module:
    'Барлық сауда нүктелері көрсетіледі жүйесінде Briskly кейін ғана қосу Briskly Модуль.',
  stages_description_offer: 'Сізге шартқа қол қою оферта пайдалану үшін Briskly кабинеті',
  stages_description_planogram:
    'Қойсаңыз, сөрелерде микромаркета тауарлар, оларды қалай көруге болады сіздің клиенттер.',
  stages_description_requisites: 'Мәртебесі: тексеріледі.↵Тексеру орын 1-3 жұмыс күні.',
  stages_description_schedule: 'Үшін сауда нүктесі жұмыс істеді, жолақтарды толтырыңыз кесте.',

  stages_description_payment:
    'Клиент әлі подключил картасына төлеу үшін.↵ Сілтемені көшіру жіберіңіз оған, егер емес, жасады бұл.',
  trade_point_registation_complite: 'Тіркеу аяқталды',
  congratulations_your_tt_is_ready_to_go: 'Құттықтаймыз! Егер сауда нүктесі жұмысқа дайын',
  fine: 'Өте жақсы',
  congratulations_your_tt_is_ready_we_need_check:
    'Құттықтаймыз! Сіз аяқтады тіркеуді! Енді бізге 1-3 жұмыс күннің ішінде тексеру үшін деректеме. Осыдан кейін сізге  ғашықтар сауда нүктесінде."',
  download_qr_code: 'Скачать QR-коды ',
  download_sticker: 'Скачать затбелгіге',
  download_table_banner: 'Скачать үстел баннер',
  do_edit_sufix: 'Редакциялау',
  delete_trade_point: 'Удалить сауда нүктесі',
  this_action_cant_canceled: 'Бұл әкеледі безвозвратному жою',
  employees_title: 'Қызметкерлері',
  planogram_title: 'Планограмма',
  payment_method: 'Төлеу тәсілі',
  payment_status: 'Мәртебесі төлеу',
  temperature_setup: 'Температураны орнату',
  temperature_setup_title:
    'Реттеңіз температуралық жұмыс режимі сіздің микромаркета. Кезде ішіндегі температура микромаркета шығатын болады белгіленген сізбен шектері, біз пришлем сізге хабарлама.',
  time_of_ordering: 'Тапсырыс уақытын',
  Video: 'Видео',
  video_by_order: 'Видео тапсырыс',
  pick_date: 'Күнді таңдаңыз',
  video_recording_date: 'Жазған күні видео',
  video_not_found: 'Бейне табылмады',
  download: 'Скачать',
  is_signet: 'Қол қойылды',
  documents: 'Құжаттар',
  is_doesnt_signed: 'Мемлекет',
  is_touched: 'Көрілді',
  to_assign: 'Қол қою',
  time_stamp: 'Уақыт қосу',
  type: 'Түрі',
  offer_for_partners: 'Оферта серіктестер үшін',
  tariff_plans: 'Тариф жоспарлары',
  personal_account_rules: 'Пайдалану ережесі ЛК',
  reports: 'Есептер',
  report_about_clients: 'Есеп бойынша клиенттерге',
  report_about_items: 'Есеп бойынша тауарлар',
  report_about_orders: 'Статистика бойынша тапсырыс',
  report_about_sales: 'Статистика бойынша сату',
  report_about_unique_clients: 'Статистика бойынша бірегей күндізгі клиенттерге',
  do_download_data: 'Скачать деректер',
  do_download_promo_briskly: 'Көшіріп алу промо материалдар Briskly',
  download_file_format: 'Формат көшіріп алу',
  your_account_has_run_out_of_money: 'Шотыңыздағы күні ақша.',
  do_top_up_an_account: 'Теңгерімді',
  air_temperature: 'Ауа температурасы',
  inside: 'Ішінде',
  outside: 'Сыртынан',
  status: 'Мәртебесі',
  actual_address: 'Нақты мекен-жайы',
  card: 'Карта',
  phone: 'Телефон',
  credentials: 'Деректемелері',
  delivery: 'Жеткізу',
  do_edit: 'Редакциялау',
  most_popular_items: 'Кең танымал тауарлар',
  item_name: 'Тауардың атауы',
  price_with_currency: 'Баға ₽',
  revenue_with_currency: 'Түсім, ₽',
  sold_items_with_amount: 'Сатылды, дана',
  option_unknown: 'Белгіленбесе,',
  option_min: 'Ең төменгі',
  option_max: 'Максималды',
  option_discount_percent: 'Жеңілдік пайызбен',
  option_discount_amount: 'Жеңілдік рублінде',
  option_discount_new_price: 'Жаңа баға',
  option_usage_common: 'Жалпы жеңілдікпен',
  option_usage_first_order: 'Бірінші сатып алу уақытын есепке алмағанда',
  option_usage_first_order_after_timestamp: 'Алғашқы сатып кейін тағайындалған уақытша лимит',
  discount_size: 'Жеңілдік мөлшері',
  discount: 'Жеңілдікпен',
  excluded_cost: 'Исключенная құны',
  applying_features: 'Қолдану ерекшеліктері',
  applying: 'Қолдану',
  do_add_discount: 'Қосу жеңілдік',
  editing_of_discount: 'Редакциялау жеңілдіктер',
  adding_of_discount: 'Қосу жаңа жеңілдіктер',
  applying_order: 'Қолдану тәртібі',
  save_changes: 'Өзгерістерді сақтау',
  do_save: 'Сақтау',
  excluded_cost_tooltip:
    'Сомасы чекте оған мүмкін жеңілдік жасалады. ↵Мысалы: 10% жеңілдікпен сатып алуға астам 500руб (исключенная құны 500р) ↵сатып алу Сомасы 900р 10% жеңілдік қолданылады 400р құрайды 40р.',
  client_show_settings: 'Параметрлерді көрсету үшін клиенттер',
  do_show: 'Көрсету',
  title: 'Тақырыбы',
  description: 'Сипаттамасы',
  image: 'Сурет',
  add_image_proposal: 'Қосыңыз бейнесі',
  adding_of_discount_group: 'Қосу жиынтығы жеңілдіктер',
  editing_of_discount_group: 'Редакциялау теру жеңілдіктер',
  do_add_discount_group: 'Қосу жинағы',
  do_add_discount_group_v2: 'Қосу жинағы жеңілдіктер',
  do_assign_discount_group_to_store: 'Болып тағайындалсын жеңілдіктер сауда нүктелеріне',
  discounts: 'Жеңілдіктер',
  do_delete: 'Жою',
  not_delete: 'Өшірілмеу',
  do_reset_changes: 'Жойылсын өзгерістер',
  you_are_trying_to_delete_discount: 'Сіз жою жеңілдік',
  you_are_trying_to_delete_discount_group: 'Сіз жою жинағы жеңілдіктер',
  discount_assign: 'Мақсаты жеңілдіктер',
  store_name: 'Атауы сауда нүктелері',
  discount_group: 'Жинағы жеңілдіктер',
  title_for_clients: 'Тақырыбы клиенттер үшін',
  is_enabled: 'Қосылған',
  is_disabled: 'Өшірулі',
  is_enabled_female: 'Қосылған',
  is_disabled_female: 'Ажыратылған',
  show_for_clients: 'Көрсету клиенттер үшін',
  type_of_discount: 'Түрі жеңілдіктер',
  set: 'Жинағы',
  around_the_clock: 'Тәулік бойы',
  doesnt_act: 'Әрекет етпейді',
  good_photo: 'Тауардың фотосуреті',
  volume: 'Көлемі',
  step_reminder: 'Сіз қайтадан осы қадамға кейінірек.',
  category: 'Категория',
  categories: 'Санаты',
  category_sufix: 'Санат',
  create_catalog: 'Каталог құрыңыз',
  create_catalog_label:
    'Сіздің аккаунтында мүмкін бірнеше каталогтар үшін (мысалы, микромаркета сатушыдан дайын тамақтың немесе сатушыдан мұздатылған өнімдермен). Таңдау тиісті каталог, сіз тез переключаетесь бір тауарлар тізімінің басқа. Мұнда құру қажет бұл каталог.',
  catalog_name: 'Атауы каталог',

  portion: 'порция',

  non_tax: 'Салынбайды',
  payment: 'Есептік',
  standard: 'Стандартты',
  altogether: 'Барлығы',
  units: 'Өлшем бірліктері',
  age_limit: 'Возрастное ограничение,жыл',
  composition_of_item: 'Құрамы',
  calories: 'Калория',
  optional_description: 'Сипаттамасы (необязательно)',
  vat_mode: 'Режимі ҚҚС',
  vat_rate: 'ҚҚС ставкасы, %',
  block_with_modifications: 'Блок с модами',
  item_add_action: 'Қосу тауардың',
  item_edit_action: 'Редакциялау тауардың',
  dimension: 'Өлшемі',
  item_add_action2: 'Қосыңыз тауарлар',
  add_and_edit_catalog: 'Қосу/өңдеу каталог',
  need_select_catalog: 'Таңдау керек каталог',
  do_add_more_categories: 'Тағы категорию',
  goods_name: 'Тауардың атауы',
  expiration_date: 'Жарамдылық мерзімі',
  barcode: 'Штрихкод',
  sticker_quantity: 'Кол-во жапсырмалар',
  manufacturing_by: 'Өндірілді',
  h: 'с',
  d: 'к',
  m: 'ай',
  y: 'ж',
  composition: 'Құрамы',
  do_not_specifiedSuffix: 'Жоқ ретін',
  do_not_specified: 'Көрсетілмесе',
  fats: 'Майлар',
  proteins: 'Белоктар',
  carbohydrates: 'Көмірсулар',
  calories_full: 'Энергетикалық құндылығы',
  ccal: 'ккал',
  nutritional_value_per_100g_of_product: 'Тағамдық құндылығы(на 100гр өнімнің)',
  at_the_goods: 'У тауардың',
  with_barcode: 'С баркодом',
  error_with_bar_code: 'Қатесі генерацией Баркода!',
  maybe_doest_valid_control_digit: 'Мүмкін: Қате бақылау саны!',
  add_goods: 'Қосу тауарлары',
  do_delete_category: 'Санатты жою',
  be_deteted: 'Болады шығарылуы',
  need_detals: 'Керек нақтылау',
  good_with_no_valid_barcode_need_write_it: 'Осы тауар валидный Штрих код, ↵, оны толтыру',
  go_to_edit: 'Көшу редакциялау',
  later: 'Кейінірек',
  good_suffix: 'өнім',
  article: 'Артикул',
  photo: 'Фото',
  dimension_units: 'Ед. өлшеу',
  active: 'Актив',

  sticker: 'Стикер',
  items: 'Тауарлар',
  do_create_catalog: 'Каталог құру',
  do_create_new_catalog: 'Жаңа каталог',
  stickers_print: 'Мөр стикерлер',
  write_name_of_new_catalog: 'Атын енгізіп, жаңа каталог',
  write_name_of_new_category: 'Атын енгізіп, жаңа санаты',
  do_add: 'Қосу',
  do_go_back: 'Бұрын',
  do_continue: 'Жалғастыру',
  catalog: 'Каталог',
  doesnt_have_category: 'Жоқ, санат',
  stickers: 'Стикерлер',
  do_print_stickers: 'Басып стикерлер',
  manufacturer_name: 'Өндірушінің атауы',
  manufacturing_address: 'Мекен-жайы дайындау',
  production_date: 'Дайындау күні',
  time: 'Уақыты',
  hours: 'Часы',
  minutes: 'Минут',
  days: 'Күндері',
  weeks: 'Апта',
  months: 'Айлар',
  years: 'Жылдарға арналған',
  do_apply: 'Қолдану',
  general_expiration_date: 'Жалпы жарамдылық мерзімі',
  amount_of_stickers_per_position: 'Кол-во жапсырмалар үшін бір позициясы',
  do_mark_all: 'Бәрін',
  do_download_agreement: 'Скачать келісім',
  personal_lowercase: 'жеке',
  cabinet_lowercase: 'кабинет',
  choose_option_or_continue_input: 'Нұсқасын таңдаңыз немесе жалғастырыңыз енгізу',
  unknown_organization: 'Белгісіз ұйымдастыру',
  organization_info: 'Ұйым туралы деректер',
  organization_name: 'Ұйымның атауы',
  organization_phone: 'Ұйымның телефоны',
  inn: 'INN',
  head_of_company: 'Жетекшісі',
  registration_date: 'Тіркеу күні',
  okved: 'Эқтжж-ға',
  capital: 'Жарғылық капитал',
  taxes_system: 'Салық салу жүйесі',
  do_write_inn: 'Енгізіңіз INN',
  osno: 'НЕГ',
  usn1: 'ІСН (кірістер-шығыстар)',
  usn2: 'ІСН (кіріс 6%)',
  innLabel1: '{label} немесе ұйымның атауы',
  innLabel2: '{label} немесе тегі',
  acting: 'Қазіргі',
  first_name_placeholder: 'Михаил',
  second_name: 'Тегі',
  second_name_placeholder: 'Михайлов',
  third_name: 'Әкесінің',
  third_name_placeholder: 'Михайлович',
  position_label: 'Лауазымы (қалай ұйымының жарғысында)',
  position_placeholder: 'Бас директоры',
  okopf_name_short_label: 'Құқықтық нысаны, қысқартылған түрінде',
  okopf_name_full_label: 'Құқықтық нысаны, толық түрде',
  ooo_full_name: 'Жауапкершілігі шектеулі қоғамы',
  name_short_with_opf: 'Қысқа атауы НӨҚ',
  name_short_with_opf_placeholder: "ООО 'Багратион'",
  type_of_company_placeholder: 'ООО, ЖК',
  name_short: 'Қысқа атауы',
  name_short_placeholder: 'Багратион',
  ogrn: 'OGRN',
  first_passport_page: 'Бірінші бет паспорт',
  passport_page_with_place_of_live: 'Бет тіркелуге тиіс',
  bank_account: 'Есептік шот нөмірі',
  bank_account_register_placeholder:
    'Енгізіңіз 20 санды банкте шот (егер шот тұрады 22 сандарды көрсетіңіз алғашқы 20)',
  bik: 'БСК',
  bik_register_placeholder: 'Енгізіңіз 9 нөмірінің БСК банк',
  bank_credentials: 'Банк деректемелері',
  receivers_bank: 'Алушының банкі',
  bank_account_number: 'Шот нөмірі',
  bank_correspondent_number: 'Корр. шот',
  your_name: 'Сіздің аты',
  phone_number: 'Телефон нөмірі',
  type_of_employment: 'Жұмыспен қамту типі',
  ip_as_type_of_organization: 'ЖК',
  legal_as_type_of_organization: 'Ұйымдастыру',
  date: 'Күні',
  inventory_system: 'Тауарлы-материалдық қорларды есепке алу жүйесі',
  integration_errors: 'Ықпалдасу кезінде туындаған қателіктер',
  integration_with_system_were_succeed: 'Интеграция {system} сәтті өтсе',
  do_change_info: 'Деректерді өзгерту',
  do_skip: 'Елемеу',
  select_other_system: 'Таңдау жүйесі',
  select_other_system2: 'Таңдау басқа жүйесі',
  do_check_integration: 'Тексеру кірігуін',
  integration_entry_label: ' Мұнда сіз қосу тауарлар сатуға алатындарыңыз. Мұны бірнеше тәсілмен',
  integration_entry_label_option1: 'Жүйені ықпалдастыру Briskly сіздің жүйесі есепке алу.',
  integration_entry_label_option2: 'Файлды жүктеу тауарлармен.',
  integration_entry_label_option3: 'Таңдау қолданыстағы каталог',
  integration_entry_label_option4: 'Қосу қолмен тауарлар',
  select_catalog: 'Таңдаңыз каталог',
  upload_or_drag_file: 'Жүктеңіз немесе сүйреңіз файл',
  integration: 'Интеграция',
  integration_file_label:
    'Үшін дұрыс жүктеу каталог, соотнесите біздің атаулар алаңдарын отырып, олар қалай аталды сізде.',
  item_add_action2_label: 'Жасаңыз санат қосыңыз немесе оларға тауарлар',
  write_category_name: 'Санат атауын енгізіңіз',
  do_create_category: 'Құру категорию',
  actions: 'Қолданылу',
  do_send_claim: 'Шағым жіберу',
  claim_text: 'Шағым мәтінін',
  for_order: 'тапсырыс',
  order: 'Тапсырыс',
  client_id: 'ID клиенттің',
  total_discount: 'Сомасы жеңілдіктер',
  order_info: 'Туралы ақпарат тапсырыс',
  claim_sended: 'Шағым жіберілді',
  total_amount: 'Жалпы құны',
  order_composition: 'Құрамы және тапсырыс',
  additionally: 'Қосымша',
  fields_are_optional: 'өріс міндетті толтыру үшін',
  show_by: 'Көрсету бойынша',
  do_briskly_module_active_new: 'Жаңа модуль қосу',
  do_briskly_module_finish: 'Аяқтау',
  briskly_module_reassign_title: 'Байламы жаңа модуль',
  briskly_module_reassign_message: 'Сіз дәл келсе, привязать жаңа Briskly модуль?',
  error: 'Қате',
  fail_bind_employee_to_store: 'Болмады қызметкерінің тіркеуі',
  fail_unbind_employee_to_store: 'Болмады отвязать қызметкері',
  fail_init_employee_store_list: 'Қотарылмады туралы деректер сауда орындарында',
  fail_onboarding_init_company_info: 'Қотарылмады деректер компанияның толтырылатын бұрын',
  unknown_error: 'Нәрсе емес',
  delete_category_text_confirm:
    'Бұл іс-әрекет әкеледі жою каталог, ал тауарлар олар онда болып санатындағы, барлық""',
  delete_catalog_text_confirm_1: 'Жойғанда каталог',
  delete_catalog_text_confirm_2: 'одан болады удаленны барлық санаттағы тауарлар орналасқан, онда',
  do_collapse: 'Свернуть',
  exchange_settigns: 'Параметрлер алмасу',
  create_catalog_label_2:
    "'Сіздің аккаунтында мүмкін бірнеше каталогтар. \\nМысалы үшін микромаркета сатушыдан дайын тамақтың немесе сатушыдан мұздатылған өнімдермен. \\nТаңдау тиісті каталог, сіз тез переключаетесь бір тауарлар тізімінің басқа. \\nМұнда ауыстыруға болады бұл каталог.',",
  report_about_item: 'Статистика бойынша тауар',
  report_order_id: 'Тапсырыс Нөмірі',
  mailings: 'Тарату',
  do_add_mailing: 'Құру, тарату',
  mailing_new: 'Жаңа тарату',
  mailing_edit: 'Редакциялау тарату',
  mailing_status_complete: 'Орындалды',
  mailing_status_saved: 'Сақталған',
  mailing_status_moderation: 'Жаңғыртуда',
  mailing_status_wait: 'Күтеді жіберу',
  mailing_status_cancelled: 'Жойылды',
  mailing_status_ready: 'Төлеуге',
  mailing_add_description:
    'Қосыңыз сіздің таратуға. Көрсетіңіз жөнелту уақыты, аудитория клиенттердің және мәтін.',
  mailing_moderation_description:
    'Өткеннен кейін модерация керек болады тарату. Екендігін назарға алыңыз модерация өтуде ішінде 1-2 жұмыс күн ішінде ақы төлеу түседі есебінен 2 жұмыс күніне дейін. Ескеріңіз, бұл жоспарлау кезінде тарату.',
  mailing_name: 'Атауы тарату',
  mailing_date: 'Жіберу күні',
  mailing_audience: 'Аудитория жүргізу үшін жіберу',
  mailing_client_count: 'Клиенттердің саны',
  mailing_cost: 'Таратылым құны',
  mailing_text: 'Мәтін тарату',
  mailing_only_save: 'Сақтап, бірақ іске',
  mailing_save_and_start: 'Таратуды іске қосу',
  mailing_invalid_time: 'Орнатыңыз уақыт аралығы, сағат 9:00-ден 21:00 дейін',
  mailing_may_not_pass_moderation: 'Сіздің тарату мүмкін қарсыластар өтуге модерацию.',
  mailing_all_clients: 'Барлық клиенттер',
  mailing_created_filters: 'Дайын сүзгілер',
  mailing_selecting_filters: 'Сүзгіні таңдау',
  mailing_save_filters: 'Сақтау сүзгілер',

  stock_balances: 'Қор қалдықтары',
  do_add_items_list: 'Тізім ретінде элементтер қосу',
  stock_for_stock_balances: 'Қор қалдықтарын есепке алу',
  rebase_catalogs: 'Каталогты жою > Өңдеу нүктелері',
  clarification_in_items_side_bar:
    '* Егер сіздің файлыңыздағы өнімдер санаттарға бөлінген болса, Каталогты таңдап, файлды жүктеп салсаңыз, Санаттар автоматты түрде жасалады.',
  do_download_xlsx: '.xlsx файлын жүктеу',
  stock_accounting_balances_info:
    'Сіздің сауда нүктелеріңіздегі тауарлардың саны мен құны туралы заманауи ақпарат алу үшін «Акциялардың қалдықтарын есепке алу» функциясын қолданыңыз. ',
  or: 'Немесе',
  category_info_tip:
    'Бұл функцияны автоматты түрде жаңа санатты өнімдермен толтыру үшін пайдаланыңыз.',
  good_must_be_in_catalog_or_category: 'Өнім каталогқа немесе санатқа байланған болуы керек.',
  items_add_side_bar_info:
    'Ағымдағы каталогта жаңа каталог немесе категория жасау қажет болса, бөлімге өтіңіз',
  do_delete_catalog: 'Каталогты жою',
  delete_catalog: 'Каталогты жою',
  trade_point_edit: 'Сату нүктелерін өңдеу',
  rebase_catalog_1: '«{name}» каталогын алып тастау үшін сізге',
  rebase_catalog_2: 'жаңа каталогтарды барлық сауда нүктелерімен байланыстыру',
  rebase_catalog_3: 'төмендегі кестеде.',
  cant_start_from_dot: 'нүктеден бастау мүмкін емес',
  aprove_category_delete: 'Сіз санатты жойғалы жатырсыз',
  delete_category_warn: 'Санатты жою осы санаттағы барлық өнімдерді жояды',
  delete_catalog_confirm_text_5:
    'Сіз ешқандай санатсыз немесе өнімсіз бос каталогты жойғалы жатырсыз',
  delete_catalog_confirm_text_6: 'Сіз өнімі бар каталогты жойғалы жатырсыз',
  trade_point_type_name: 'сауда нүктесінің түрі',
  select_new_catalog: 'Жаңа каталог таңдаңыз',
  barcoders_prefix_for_weight_good: 'Салмағы бар штрих-код префикстері',
  barcoders_prefix_info:
    'Осы префикстің мәні бойынша жүйе элементтің сандық кодын және оның салмағын граммен бөліп көрсете отырып, салмағы бойынша элементті таниды. Қол жетімді префикстер 20-дан 29-ға дейін. Егер префикс көрсетілмесе, тауарлар бөлшек тауарлар ретінде қарастырылады. ',
  age_limit_2: 'жылдар',
  age_limit_1: 'Жас шегі',

  requisites_personal_data_descr_part_1:
    'сізге сауда нүктеңіздің сатылымынан түскен қаражатты күнделікті аудару үшін осы ақпаратты толтыруды сұраймыз. Ол үшін эквайрингті қосқан кезде ұсынатын мәліметтерге ұқсас мәліметтерді ұсыну қажет.',
  requisites_personal_data_descr_part_2:
    'сіздің деректеріңіз Орталық банк пен эквайер Банктің талабы бойынша сұралады. Олар шифрланған түрде сақталады. Сондай-ақ,, Briskly Роскомнадзордың жеке деректер операторларының тізілімінде.',
  add_good: 'өнімді қосу',
  external_id: 'Сыртқы идентификатор',
  do_save_and_go_back: 'сақтау және тізімге оралу',
  print_shelf_life: 'жарамдылық мерзімін Басып шығару',
  succes_saved: 'сәтті сақталды',
  quantity_cut: 'Саны',
  additional_data_about_good: 'өнім туралы қосымша мәліметтер',
  doest_not_req_field: 'қосымша өрістер',
  do_upload_photo: 'фотосуретті жүктеу',
  upload_good_list: 'Тауарларды тізіммен жүктеу',
  show_filters: 'көрсету сүзгілер',
  hide_filters: 'сүзгілерді жасыру',
  available_table_filters: 'кесте сүзгілері',
  deletion: 'жою',
  ' item_warning_info': 'өнім кез-келген каталог пен санатқа байланысты болуы керек.',
  item_create_info:
    'егер Сізге ағымдағы каталогта жаңа каталог немесе санат жасау қажет болса, бөлімге өтіңіз ',
  aprove_catalog_delete: 'сіз каталогты жойғыңыз келеді',
  delete_catalog_text_confirm_3:
    'каталог жойылған кезде оның ішіндегі барлық санаттар мен заттар жойылады.',
  delete_catalog_text_confirm_4:
    'каталогты жою үшін алдымен жойылғанның орнына барлық сауда нүктелеріне жаңа каталогты байлау керек.',
  go_to_edit_store: 'сауда нүктелерін өңдеуге өтіңіз',
  choose_trade_point: 'сауда нүктесін таңдаңыз',
  do_catalog_edit: 'каталогты өңдеу',
  do_category_edit: 'санатты өңдеу',
  category_warn_text_warn: 'санат кез-келген адамға байланысты болуы керек каталог.',
  category_warn_text: 'егер сізге жаңа каталог жасау қажет болса, сілтемені нұқыңыз',
  category_warn_text_link: 'жаңа каталог жасаңыз.',
  catalog_rebase_info: 'енді ешқандай сауда нүктелеріне байланбайды, енді сіз жасай аласыз',
  no_design_to_select: 'сақталған дизайн жоқ',
  valid_fill_the_field: 'өрісті толтырыңыз',
  valid_incorrect_date: 'дұрыс емес күн',
  valid_incorrect_phone: 'дұрыс емес телефон',
  valid_passport_series: 'дұрыс емес серия',
  valid_passport_number: 'жарамсыз төлқұжат нөмірі',
  valid_passport_authority_code: 'бөлімшенің дұрыс емес коды',
  valid_inn: 'СТН таңдаңыз',
  valid_address: 'мекенжайды таңдаңыз',
  valid_bic: 'БСК таңдаңыз',
  valid_account_number: 'есептік шоттың ұзындығы 20 таңбадан тұруы керек',
  valid_maximum: 'таңбалардың максималды саны',
  valid_select_some_filter: 'кем дегенде біреуін таңдаңыз сүзгі',
  need_to_select_catalog_and_category: 'өнімді жасау үшін N каталог пен санатты таңдау керек',
  categories_of_catalogs: 'каталог категориялары',
  add_catalog: 'каталог қосу',
  add_category: 'Санат қосу',
  add_catalog_action: 'Каталог қосу',
  category_name: 'санат атауы',
  catalogs: 'каталогтар',
  search_by_categories: 'санат бойынша іздеу',
  search_by_catalogs: 'каталог бойынша іздеу',
  warning: 'Назар аударыңыз!',
  write_new_catalog_name: 'каталог атауын енгізіңіз',
  write_new_category_name: 'атауды енгізіңіз Санаттар',
  add_catalog_warning:
    'сіз каталогты бірнеше сауда нүктелеріне байланыстыра аласыз, ол үшін қосымша сауда нүктесін таңдау өрісін қосу үшін' +
    ' пайдаланыңыз.',
  add_catalog_info:
    'бір каталогқа бірден бірнеше санатты қосуға болады, ол үшін жаңа санат атауын енгізу үшін қосымша өрісті қосу үшін' +
    ' пайдаланыңыз. ',
  product_product_date: 'шығарылған күнін Басып шығару',
  mailing_going_to_delete: 'Сіз ақпараттық бюллетеньді жойғыңыз келеді',
  address_doesnt_correct: 'Мекен-жайы дұрыс емес',
  no_saved_templates: 'сақталған шаблондар жоқ',
  add_schedule_and_save_as_template:
    'опцияларды қосыңыз және содан кейін оларды үлгі ретінде сақтаңыз',
  service_mts_kassa: 'МТС кассасы',
  create_new_catalog: 'жаңа каталог жасау',
  use_existing_catalog: 'бар каталогты пайдалану',
  log_out_question: 'сіз ағымдағы сессияны аяқтағыңыз келе ме?',
  item_warning_info: ' өнім кез-келген каталогқа байлануы керек және санатты.',
  accounting_for_stock_balances: 'тауар қалдықтарын есепке алу',
  item_photo_note:
    '(тауардың фотосуретін жүктей аласыз немесе төменде көрсетілген әдепкі фотосуретті қалдыра аласыз)',
  store_doesnt_have_catalog:
    '{name} сауда нүктесінде каталогқа сілтеме жоқ редакциялауға өту керек пе ?',
  success: 'Сәтті',

  uploaded_image_min_width: 'Суреттің минималды ені',
  uploaded_image_max_width: 'Кескіннің максималды ені',
  uploaded_image_min_height: 'Ең төменгі сурет биіктігі',
  uploaded_image_max_height: 'Максималды сурет биіктігі',
  allowed_types: 'Рұқсат етілген түрлері',
  max_file_size: 'Файлдың ең үлкен өлшемі',
  standard_no_suf: 'Стандарт',
  orders_and_video: 'Тапсырыстар мен бейнематериалдар',
  payment_type_undefined: 'Анықталмаған',

  service_csv: 'csv',
  ok: 'Ok',
  time_of_creation: 'Құру уақыты',
  find_by_order_number: 'Тапсырыс нөмірі бойынша іздеу',
  item_id: 'Элемент ID',
  id_trade_point: 'Дүкен ID',
  price_with_currency_inject: 'Бағасы, {currency}',
  sum_with_currency_inject: 'Сомасы, {currency}',
  option_discount_percent_cut: 'Жеңілдік, %',
  total_amount_price_with_currency_inject: 'Жалпы құны, {currency}',
  total_amount_sum_with_currency_inject: 'Жалпы сомасы,, {currency}',
  good_barcode: 'Тауардың Штрих-коды',
  customer_id: 'Клиент',
  created_at: 'Құру уақыты',
  store: 'Дүкен',
  create_refund: 'Қайтару жасау',
  orders_not_exist: 'Сіздің сауда нүктелеріңізде әлі тапсырыс жоқ.',

  valid_minimum: 'Таңбалардың ең аз саны',
  coords_lng: 'Бойлық',
  coords_lat: 'Ендік',
  invalid_intervals_filled: 'Жолдар толтырылмайды',
  invalid_intervals_to_less_then_from: 'Жол берілмейтін аралықтар',
  invalid_intervals_intersections: 'Арақашықтық тиіс жабылуы',
  valid_max_250: 'Ең көбі 250 таңба',

  postal_code: 'Пошта индексі -',
  module_description_temp:
    'Briskly модулі-бұл сіздің микроарықтың миы. Сатып алу кезінде сізге есептік жазбаңызға байланысты код берілді.',
  value_must_be_more_than_zero: 'Бұл мән 0-ден көп болуы керек',
  valid_min_13: 'Кем дегенде 13 таңба',
  dosesnt_correct_number: 'Жарамсыз Сан',
  success_deleted: 'Сәтті қашықтан',
  do_return_back: 'Кері қайту',
  abort_procedure: 'Үзіліс процедурасы',
  abort: 'Лақтыру',
  maybe_data_not_saved: 'Енгізілген өзгерістерді сақтау мүмкін емес',
  empty_catalog: 'Каталог бос',
  fileupload_page_info:
    'Егер сіздің файлыңыздағы заттар санаттарға бөлінген болса, каталогты таңдап, файлды жүктеңіз, Санаттар автоматты түрде жасалады.',
  do_download_xlsx_example: 'Скачать .xlsx мысалы',
  valid_max_11: 'Ең көбі 11 таңба',
  payment_type: 'Төлем түрі',
  app: 'Қосымша',
  terminal: 'Терминал',
  order_with_id_inject: 'Тапсырыс ID {orderId}',
  refund_with_order_id_inject: 'Қайтару: Тапсырыс ID {orderId}',
  barcode_can_includes_only_digits: 'Тек сандар',
  copy_to_clipboard: 'Буферге көшіру',
  copied_to_clipboard: 'Алмасу буферіне көшірілген',
  about_order: 'Тапсырыстар туралы',
  refund_title: 'Ақшаны қайтару',
  amout_goods_to_refund: 'Қайтаруға жататын барлық тауарлар',
  amount_goods_payment: 'Қайтару сомасы',
  technical_support: 'Техникалық қолдау',
  add_refund: 'Қайтару қосу',
  view_refund: 'Қайтару көрінісі',
  onboarding_virtual_terminal: 'Виртуалды терминал',
  virtual_terminal: 'Виртуалды терминал',
  select_virtual_terminal: 'Виртуалды терминалды таңдаңыз',
  virtual_terminal_description:
    'Виртуалды терминалдың деректемелерін толтырыңыз, олар сіздің шотыңызға қаражат аудару үшін, сондай-ақ клиент үшін чек қалыптастыру үшін қажет.',
  add_new_virtual_terminal: 'Терминалды қосу',
  do_create_refund: 'Қайтару жасау',

  delete_goods_modal_info: 'және {stringsQuantity} ... қосымша өнімдер',
  client_show_settings_2: 'Мобильді қосымшаның дисплей параметрлері',
  no_changes_to_update: 'Сақтау үшін ешқандай өзгеріс жоқ',
  for_go_to_exchange_need_say_store: 'Сауда нүктесін таңдаңыз',
  link_to_showcase: 'Өнім дүкеніне сілтеме',
  catalog_successfully_added: 'Каталог сәтті қосылды',
  refund_has_been_created: 'Қаражатты қайтару құрылды',
  no_trans_action: 'Операциясыз',
  no_refund_text_info: 'Бұл тапсырыс үшін ешқандай қайтарым болған жоқ.',
  date_should_not_earlier: 'Күн бұрын болмауы керек',
  date_should_not_later: 'Күні кеш болмауы керек',
  cant_play_video: 'Бейне ойнату мүмкін емес',
  check_refund_possibility: 'Ақшаны қайтару опцияларын тексеріңіз',
  refund_helper_text:
    'Өнімді тез табу үшін оның идентификаторын, штрих-кодын немесе атауын енгізіңіз',
  no_one_good_selected: 'Қайтару үшін таңдалған өнім жоқ.',
  errors_occurred_while_creating_the_refund: 'Қайтару кезінде қателер пайда болды:',
  refund_by_id_goes_successed: 'Тапсырыс ID үшін қайтару {refundId} сәтті өтті',
  refund_by_id_goes_accept: 'Тапсырыс ID үшін қайтаруды растаңыз {refundId}',
  refund_technical_support_notice: 'Ақшаны қайтару үшін техникалық қолдау қызметіне хабарласыңыз.',
  refund_money_back_notice:
    'Ақша қайтарылған сәттен бастап 2 күн ішінде клиенттің картасына аударылады.',
  refund_ready_title: 'Тапсырысты қайтару',
  refund_check_success_info: 'Қайтару сәтті жасалуы мүмкін',
  refund_check_success_info_with_order_id: 'ID тапсырысын қайтару {orderId}  сәтті жасалды',
  approve_refund: 'Қайтаруды растаңыз',
  video_not_available: 'Бейне қол жетімді емес',
  video_tooltip_error:
    'Бейнені зақымдалған немесе уақытша жұмыс істемейді. Қолдау қызметіне хабарласыңыз',
  do_upload: 'Скачать',
  amout_for_refund: 'Қайтару үшін барлық тауарлар',
  does_not_correct_control_sum: 'Салық сомасы жарамсыз',

  //  Новые ключи
  // stages_description_type: '',
  // stages_description_fuel: '',

  active_status: 'Төлем күтілуде',
  paid_status: 'Ақылы',
  refund_status: 'Оралды',
  refund_awaiting_status: 'Оралу күтілуде',
  refund_error_status: 'Қайтару қателігі',
  refund_partial_status: 'Ішінара қайтарылды',
  removed_status: 'Жойылды',
  online_store_layouts: 'Микромаркетаны желімдеу макеттері',
  promo_stickers: 'Жапсырмалар',
  promo_side_stickers: 'Бүйірлік Жапсырмалар',
  promo_on_glas: 'Шыныда',
  promo_on_advertising_block: 'Жарнамалық блокқа',

  promo_without_ad_block: '(жарнамалық блоксыз)',
  promo_with_ad_block: '(жарнама блогымен)',
  attention: 'Назар',
  requisites_skip_warning:
    'Егер сіз реквизиттерді таңдау / қосу қадамын өткізіп жіберсеңіз, Сіздің сауда нүктеңіз сынақ режимінде жұмыс істейді.',
  select_full_party_description:
    'Сіз бұрын пайдаланылған деректемелерді таңдай аласыз немесе нысанды толтыру арқылы жаңаларын қоса аласыз!',
  select_partial_party_description: 'Виртуалды терминалды жеке кабинетке қосыңыз!',
  pick_terminal: 'Терминалды таңдау',
  add_new_full_party: 'Жаңаларын қосу',
  add_new_partial_party: 'Жаңасын қосу',
  swift_bic: 'SWIFT / BIC',
  secure3d: '3DS',
  non_secure3d: 'non 3DS',
  full_party_choosing: 'Реквизиттерді таңдау',
  partial_party_choosing: 'Терминалды таңдау',
  requisites_and_terminals: 'Заңды тұлғалар',
  terminals: 'Виртуалды терминалдар',
  full_party_status_active: 'Белсенді',
  full_party_status_pending: 'Тексеріледі',
  full_party_status_replacement: 'Ауыстырылады',
  full_party_edit: 'Деректемелерді өңдеу',
  partial_party_edit: 'Виртуалды терминалды өңдеу',
  adding_new_terminal: 'Жаңа терминалды қосу',
  add_full_party: 'Деректемелерді қосу',
  add_partial_party: 'Виртуалды терминалды қосыңыз',
  full_party_edit_warning:
    'Өзгергеннен кейін мәліметтер біраз уақыт тексеріледі. Жаңа деректемелер тексеру қорытындысы бойынша бірден сауда нүктелерінде сақталатын болады.',
  partial_party_edit_warning:
    'Өзгергеннен кейін терминал біраз уақыт тексеріледі. Жаңа терминал тексеру қорытындысы бойынша бірден сауда нүктелеріне сақталады.',
  kpp: 'БӨП',

  upload_logo_in_format: 'Логотипті форматта жүктеңіз',
  upload_logo_with_min_size: 'Суреттің минималды өлшемі',
  upload_logo_with_min_file_size: 'Ең үлкен файл өлшемі',
  BYN: 'Беларусь рублі доллар(BYN)',
  USD: 'АҚШ(USD)',
  EUR: 'Еуро(EUR)',
  KZT: 'Қазақстандық теңге(KZT)',
  CAD: 'Канадалық доллар(CAD)',
  CNY: 'Қытай юаны(CNY)',
  RUB: 'Ресей рублі(RUB)',
  TRY: 'Түрік лирасы(TRY)',
  UAH: 'Украина гривнасы(UAH)',
  GBP: 'ФУНТ СТЕРЛИНГ(GBP)',
  CHF: 'Швейцариялық франк(CHF)',
  bank_name: 'Банктің атауы',
  company_name: 'Компанияның толық атауы',
  serial_number: 'Сериялық нөмірі',
  coffee_reeboot: 'Қайта жүктеу',
  coffee_reeboot_coffee_machine: 'Кофе қайнатқышты қайта қосыңыз',
  coffee_reeboot_text: 'Құрылғыны қайта іске қосу бірқатар мәселелерді шешуге көмектеседі.',
  defer_service: 'Міндетті қызмет көрсетуді кейінге қалдырыңыз',
  coffee_postpone: 'Кейінге қалдыру',
  coffee_service_maintenance_text: 'Сервистік шарт келесі қосуға дейін кейінге қалдырылады.',
  block_drinks: 'Сусындарды дайындауға тыйым салу',
  coffee_block_text:
    'Кофемашина белсенді остентся, бірақ сусындарды дайындау клиенттер үшін қолжетімсіз болады',
  block_drink_change: 'Сусын параметрлерінің өзгеруіне жол бермеңіз',
  coffee_props_block_text:
    'Пісіру кезінде қол жетімді. Іске қосылғаннан кейін клиенттер стандартты сусынға өзгерістер қоса алмайды.',
  block_menu_access: 'Пайдаланушы мәзіріне кіруді бұғаттау',
  coffee_menu_text: 'Клиенттер кофе машинасындағы мәзірді пайдалана алмайды.',
  block_drinks_when_offline:
    '48 сағаттан артық сервермен N қосылыс болмаған кезде сусындарды дайындауды бұғаттау',
  coffee_reloading: 'Орындалады қайта қосу...',
  search_by_items: 'Тауарлар бойынша іздеу',
  coffee_item_photo: 'Тауардың фотосуреті',
  coffee_item_photo_info:
    '(сіз тауардың фотосуретін жүктей аласыз немесе төменде көрсетілген әдепкі фотосуретті қалдыра аласыз)',
  coffee_item_type: 'Сусынның түрі',
  item_recept: 'Тауардың рецептурасы',
  value_ml: 'мл',
  cup_with_units_injection: 'Шыны {cupsize} мл',
  drink_with_units_injection: 'Сусын {drinksize} мл',
  coffee_foam: 'Сүт көбігі',
  coffee_milk: 'Сүт',
  coffee_water: 'Су',
  coffee_coffee_size: 'Кофе',
  return_values_to_default: 'Әдепкі мәндерді қалпына келтіріңіз',
  price_must_be_more_then_inject: 'Бағасы жоғары болуы керек {price}',
  coffee_item_photo_info__temp:
    'Сіз «таңдау» батырмасын басу арқылы әдепкі фотосуретті қалдыра аласыз немесе қол жетімді фотосуреттерден таңдай аласыз',
  block_drink_changes: 'Сусын параметрлерінің өзгеруі',

  file_name_uploaded: '{FileName} файлы жүктелді',
  columns: 'Бағандар',
  file_upload_rules: 'Тауарларды жүктеу үшін файлға қойылатын талаптар:',
  file_upload_rules_1: 'Пішімі — .xlsx;',
  file_upload_rules_2: 'Файлда біріктірілген ұяшықтар болмауы керек.',
  file_upload_rules_3: 'Өрістердің атаулары бір жолдан аспауы керек.',
  file_upload_rules_4:
    "Өлшем бірлігі және 'Кол...' өрістерінде қателер болған жағдайда '1 дана'автоматты түрде қойылады.",
  are_mandatory: 'міндетті болып табылады',
  file_upload_page_info:
    'Каталогты дұрыс жүктеу үшін біздің өріс атауларымызды сізде қалай аталатынымен байланыстырыңыз.',
  edit_file_and_try_again: 'Қателерді түзетіп, қайтадан көріңіз',
  do_stop_now: 'Қазір аяқтау',
  no_save_upload_remember:
    'Қазір Аяқтау батырмасын басу арқылы сіз тауарларыңыз туралы деректердің дұрыс көрсетілмегенін растайсыз.',
  exchange_drpops_with_exception: 'Интеграция кезінде қателіктер болды',
  good_must_be_in_catalog_or_category_rewrite: 'Өнім кез-келген каталогқа байланған болуы керек.',
  fileupload_page_info_rewrite:
    '* Егер сіздің файлыңыздағы өнімдер санаттарға бөлінген болса, тек Каталогты таңдап, файлды жүктеңіз, санаттар автоматты түрде жасалады.',
  loading: 'Тиеу...',
  show_detail_info: 'Толық ақпаратты көрсету',
  file_upload_success_info:
    'Қосу түймесін басу арқылы сіз тауарлардың тиісті каталогтар мен санаттарға жүктелгенін растайсыз.',
  be_created: 'Құрылды',
  selected_catalog: 'Таңдалған каталог',
  file_uploaded: 'Файлды жүктеу',
  was_successful: 'сәтті өтсе',
  and: 'және',
  valid_min: 'Минимум {min}',
  value_mm: 'мм',

  unit_796: 'штука',
  unit_657: 'Өнім',
  unit_111: 'көлемі (мл)',
  unit_112: 'Литр',
  unit_163: 'Грамм(г)',
  unit_625: 'Парақ',
  unit_704: 'Жинағы',
  unit_715: 'Бу',
  unit_166: 'Киллограм (кг)',
  unit_736: 'Орам',
  unit_778: 'Қаптама',
  item_from_upload_info: 'Өнімдерді каталогқа жылдам қосу үшін функцияны қолданыңыз',
  promo_briskly_link: 'Жарнамалық материалдар',

  store_name_placeholder: 'Розетка атауы',
  serial_number: 'Сериялық нөмірі',
  coffee_reeboot: 'Қайта жүктеу',
  coffee_reeboot_coffee_machine: 'Кофе қайнатқышты қайта қосыңыз',
  coffee_reeboot_text: 'Құрылғыны қайта іске қосу бірқатар мәселелерді шешуге көмектеседі.',
  defer_service: 'Міндетті қызмет көрсетуді кейінге қалдырыңыз',
  coffee_postpone: 'Кейінге қалдыру',
  coffee_service_maintenance_text: 'Сервистік шарт келесі қосуға дейін кейінге қалдырылады.',
  block_drinks: 'Сусындарды дайындауға тыйым салу',
  coffee_block_text:
    'Кофемашина белсенді остентся, бірақ сусындарды дайындау клиенттер үшін қолжетімсіз болады',
  block_drink_change: 'Сусын параметрлерінің өзгеруіне жол бермеңіз',
  coffee_props_block_text:
    'Пісіру кезінде қол жетімді. Іске қосылғаннан кейін клиенттер стандартты сусынға өзгерістер қоса алмайды.',
  block_menu_access: 'Пайдаланушы мәзіріне кіруді бұғаттау',
  coffee_menu_text: 'Клиенттер кофе машинасындағы мәзірді пайдалана алмайды.',
  block_drinks_when_offline:
    '48 сағаттан артық сервермен N қосылыс болмаған кезде сусындарды дайындауды бұғаттау',
  coffee_reloading: 'Орындалады қайта қосу...',
  search_by_items: 'Тауарлар бойынша іздеу',
  coffee_item_photo: 'Тауардың фотосуреті',
  coffee_item_photo_info:
    '(сіз тауардың фотосуретін жүктей аласыз немесе төменде көрсетілген әдепкі фотосуретті қалдыра аласыз)',
  coffee_item_type: 'Сусынның түрі',
  item_recept: 'Тауардың рецептурасы',
  value_ml: 'мл',
  cup_with_units_injection: 'Шыны {cupsize} мл',
  drink_with_units_injection: 'Сусын {drinksize} мл',
  coffee_foam: 'Сүт көбігі',
  coffee_milk: 'Сүт',
  coffee_water: 'Су',
  coffee_coffee_size: 'Кофе',
  return_values_to_default: 'Әдепкі мәндерді қалпына келтіріңіз',
  price_must_be_more_then_inject: 'Бағасы жоғары болуы керек {price}',
  coffee_item_photo_info__temp:
    'Сіз «таңдау» батырмасын басу арқылы әдепкі фотосуретті қалдыра аласыз немесе қол жетімді фотосуреттерден таңдай аласыз',
  block_drink_changes: 'Сусын параметрлерінің өзгеруі',
  heating: 'жылыту',
};
