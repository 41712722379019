import { TIME_MODES } from '@/core/data/timeModes';
import { utcToLocale } from '@/core/utils/timeZone';
import { getHours } from 'date-fns';

const convertHoursToLocale = (hours) => {
  const date = new Date();
  date.setHours(Number(hours));

  const localeDate = utcToLocale(date);
  return getHours(new Date(localeDate));
}

const parseDate = (date, short = false) => {
  if (!date) return '';
  const [ year, month, day ] = date.split('-');

  return `${day}.${month}` + (short ? '' : `.${year}`);
};

const getYOptionsByMode = (mode, item) => {
  const { period } = item;
  if (!period) return { label: '', tooltip: '' };

  if (mode === TIME_MODES.HOURS) {
    const hours = convertHoursToLocale(period);
    
    const tooltip = `${hours}:00`;
    const label = `${hours}:00`;

    return { tooltip, label };
  }

  if (mode === TIME_MODES.DAYS) {
    const label = parseDate(period, true);
    const tooltip = parseDate(period);

    return { label, tooltip };
  }

  if (mode === TIME_MODES.WEEKS) {
    const { week_start = '', week_end = '' } = item;

    const [ , label ] = period.split('-');
    const tooltip = (week_start && week_end) 
      ? `${parseDate(week_start)} - ${parseDate(week_end)}`
      : '';

    return { label, tooltip };
  }

  if (mode === TIME_MODES.MONTHS) {
    const [ year, month ] = period.split('-');

    let label = '', tooltip = '';
    if (!month) return { label, tooltip };

    label = tooltip = `${month.length > 1 ? '' : '0'}${month}.${year}`;
    return { tooltip, label };
  }

  return { tooltip: period, label: period };
};

export {
  getYOptionsByMode,
};