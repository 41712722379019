export const formatShelfLife = (timeMS) => {
  if (timeMS <= 0) {
    return '0 3600';
  }
  switch (0) {
    case timeMS % 31536000: // 365 days
      return `${timeMS / 31536000} 31536000`;
    case timeMS % 2592000: // 30 days
      return `${timeMS / 2592000} 2592000`;
    case timeMS % 86400: // 24 hours
      return `${timeMS / 86400} 86400`;
    case timeMS % 3600: // 1 hour
      return `${timeMS / 3600} 3600`;
    default:
      return '1 3600';
  }
};
