/* eslint-disable */
import { companyAPI } from '@/core/api/company';
import { bus } from 'shared/core';

const initialState = () => ({
  doc: null,
});

const state = initialState();

const getters = {
  doc: (state) => state.doc,
  canSkipStage: () => false,
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  completeStage: () => Promise.resolve(true),

  signOfferDoc: ({ state }, secure) => {
    return companyAPI.signDocsConfirm({
      id: state.doc.legal_document_id,
      ...secure,
    });
  },

  getOfferDoc: async ({ commit }) => {
    try {
      const { items } = await companyAPI.getDocsByType({ type: 302 });
      const [doc] = items;
      if (!doc) return;

      commit('_setDoc', doc);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setDoc: (state, doc) => {
    state.doc = doc;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
