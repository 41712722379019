const accessToken =
  'pk.eyJ1IjoiYnJpc2tseSIsImEiOiJjazB2NWo5bGkwMWRvM2lueGUwcjZzZ25jIn0.Zte1Kx00vFTpMGfT2AKfeg';

async function init() {
  const link = document.createElement('link');
  link.href = 'https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css';
  link.rel = 'stylesheet';

  const script = document.createElement('script');
  script.src = 'https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.js';
  script.async = true;

  const fragment = document.createDocumentFragment();
  fragment.appendChild(link);
  fragment.appendChild(script);
  document.body.appendChild(fragment);
}

function waitForLoad() {
  return new Promise((resolve) => {
    if (window.mapboxgl) return resolve();

    const interval = setInterval(() => {
      if (window.mapboxgl) {
        clearInterval(interval);
        resolve();
      }
    }, 200);
  });
}

export async function getMapBox() {
  await waitForLoad();
  if (!window.mapboxgl.accessToken) window.mapboxgl.accessToken = accessToken;
  return window.mapboxgl;
}

window.addEventListener('DOMContentLoaded', init);
