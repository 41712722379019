export async function validate({ context, validator, name, value }) {
  try {
    await validator.validateSync(value);
    return { ...context, [name]: null };
  } catch ({ errors }) {
    return { ...context, [name]: errors };
  }
}

export function phoneAlignment(phoneNumber) {
  return phoneNumber.replace(/[^\d+]/g, '');
}
