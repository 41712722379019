export var ru = {
    documents_subtitle: "\u0412 \u044D\u0442\u043E\u043C \u0440\u0430\u0437\u0434\u0435\u043B\u0435 \u0432\u0430\u043C \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u043E\u0437\u043D\u0430\u043A\u043E\u043C\u0438\u0442\u044C\u0441\u044F \u0441 \u043F\u0440\u0430\u0432\u0438\u043B\u0430\u043C\u0438 \u0440\u0430\u0431\u043E\u0442\u044B \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u044B \u0438 \u0434\u0440\u0443\u0433\u0438\u043C\u0438 \u044E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u0438\u043C\u0438 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0430\u043C\u0438. \n\n\u0422\u0430\u043A\u0436\u0435, \u0437\u0434\u0435\u0441\u044C \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F \u0430\u043A\u0442\u0443\u0430\u043B\u044C\u043D\u044B\u0439 \u0441\u0442\u0430\u0442\u0443\u0441 \u043F\u043E \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0430\u043C, \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u044B\u043C \u0434\u043B\u044F \u043A\u043E\u0440\u0440\u0435\u043A\u0442\u043D\u043E\u0433\u043E \u0432\u0437\u0430\u0438\u043C\u043E\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F \u043C\u0435\u0436\u0434\u0443 \u0432\u0430\u043C\u0438 \u0438 \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0435\u0439 Briskly. \n\u0412 \u0442\u043E\u043C \u0447\u0438\u0441\u043B\u0435, \u0435\u0441\u0442\u044C \u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E\u0441\u0442\u044C \u0434\u0438\u0441\u0442\u0430\u043D\u0446\u0438\u043E\u043D\u043D\u043E \u043F\u043E\u0434\u043F\u0438\u0441\u0430\u0442\u044C \u0440\u044F\u0434 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u043E\u0432, \u0432\u043E\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0432\u0448\u0438\u0441\u044C \u0444\u0443\u043D\u043A\u0446\u0438\u0435\u0439 \u0441\u043C\u0441-\u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F.",
    reports_subtitle: "\u0420\u0430\u0437\u0434\u0435\u043B \u201C\u041E\u0442\u0447\u0451\u0442\u044B\u201D \u043F\u043E\u0437\u0432\u043E\u043B\u044F\u0435\u0442 \u0432\u0430\u043C \u0441\u0442\u0440\u043E\u0438\u0442\u044C \u043D\u0430\u0433\u043B\u044F\u0434\u043D\u044B\u0435 \u0430\u043D\u0430\u043B\u0438\u0442\u0438\u0447\u0435\u0441\u043A\u0438\u0435 \u043E\u0442\u0447\u0451\u0442\u044B \u0432\u0430\u0448\u0435\u0433\u043E \u0431\u0438\u0437\u043D\u0435\u0441\u0430 \u043D\u0430 \u0442\u0435\u0445\u043D\u043E\u043B\u043E\u0433\u0438\u0447\u0435\u0441\u043A\u043E\u0439 \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u0435 b:pay. <br /> \u041F\u043E\u043B\u0443\u0447\u0430\u0439\u0442\u0435 \u043F\u043E\u043D\u044F\u0442\u043D\u0443\u044E \u0434\u043E\u0441\u0442\u043E\u0432\u0435\u0440\u043D\u0443\u044E \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044E \u043F\u043E \u0438\u043D\u0442\u0435\u0440\u0435\u0441\u0443\u044E\u0449\u0438\u043C \u0432\u0430\u0441 \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u0430\u043C \u0438 \u0432\u044B\u0431\u043E\u0440\u043A\u0430\u043C, \u0438 \u043D\u0430 \u043E\u0441\u043D\u043E\u0432\u0430\u043D\u0438\u0438 \u044D\u0442\u0438\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u0443\u043B\u0443\u0447\u0448\u0430\u0439\u0442\u0435 \u0441\u0432\u043E\u0438 \u0431\u0438\u0437\u043D\u0435\u0441-\u043F\u0440\u043E\u0446\u0435\u0441\u0441\u044B, \u043F\u043E\u0432\u044B\u0448\u0430\u044F \u044D\u0444\u0444\u0435\u043A\u0442\u0438\u0432\u043D\u043E\u0441\u0442\u044C \u0442\u043E\u0440\u0433\u043E\u0432\u044B\u0445 \u0442\u043E\u0447\u0435\u043A \u0438 \u0443\u0432\u0435\u043B\u0438\u0447\u0438\u0432\u0430\u0439\u0442\u0435 \u0441\u0432\u043E\u0439 \u0434\u043E\u0445\u043E\u0434!",
    videos_subtitle: "\u0412 \u044D\u0442\u043E\u043C \u0440\u0430\u0437\u0434\u0435\u043B\u0435 \u0432\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0437\u0430\u043F\u0438\u0441\u0438 \u0432\u0438\u0434\u0435\u043E \u0441 \u043A\u0430\u043C\u0435\u0440, \u0443\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u044B\u0445 \u043D\u0430 \u0432\u044B\u0448\u0438\u0445 \u0432 \u0442\u043E\u0440\u0433\u043E\u0432\u044B\u0445 \u0442\u043E\u0447\u043A\u0430\u0445",
    //Главный Экран
    homeTitle: 'Главный экран',
    homeSubtitle: 'На этом экране скоро будет статистика по продажам в ваших торговых точках',
    homeSubtitleWithStat: 'На этом экране отображается статистика по продажам в ваших торговых точках',
    homeReferalProgramNote: "\u041F\u043E\u043B\u044C\u0437\u0443\u0435\u0448\u044C\u0441\u044F \u0441\u0430\u043C, \u043F\u0440\u0438\u0433\u043B\u0430\u0441\u0438 \u0434\u0440\u0443\u0433\u0430. \u041A\u0430\u043A \u0442\u043E\u043B\u044C\u043A\u043E \u043E\u043D \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0435\u0442\u0441\u044F \u0432 \u0441\u0438\u0441\u0442\u0435\u043C\u0435, \u0432\u044B\n  \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u0435 \u043A\u044D\u0448\u0431\u044D\u043A \u043E\u0442 Briskly \u0432 \u0440\u0430\u0437\u043C\u0435\u0440\u0435 % \u043E\u0442",
    referalProgram: 'Реферальная программа',
    trade_points: 'Торговые точки',
    on_map: 'На карте',
    do_add_trade_point: 'Добавить торговую точку',
    //Общее
    bind: 'Привязать',
    doesnt_select: 'Не выбран',
    select_dates: 'Выберите даты',
    trade_point_list: 'Список торговых точек',
    extended_types_washer_fluid: 'Омывающая жидкость',
    number: 'Номер',
    cost: 'Стоимость',
    id: 'ID',
    do_open: 'Открыть',
    do_close: 'Закрыть',
    do_search: 'Искать',
    results: 'Результаты',
    by_list: 'Списком',
    name: 'Название',
    continue: 'Продолжить',
    revenue: 'Выручка',
    averageCheck: 'Средний Чек',
    save: 'Сохранить',
    skip: 'Пропустить',
    metr: 'м',
    km: 'км',
    can_come_back_later: 'Вы можете вернуться к этому шагу позже',
    million_short: 'млн',
    do_select: 'Выбрать',
    thousand_short: 'т',
    do_change: 'Изменить',
    first_name: 'Имя',
    required_fields: 'Обязательные столбцы',
    do_repeat: 'Повторить',
    do_check: 'Проверить',
    last_name: 'Фамилия',
    middle_name: 'Отчество',
    trade_point: 'Торговая точка',
    trade_point_count: 'Количество точек',
    role: 'Роль',
    next_step: 'Следующий шаг',
    yes: 'Да',
    no: 'Нет',
    do_skip_and_finish: 'Пропустить и завершить',
    do_end_registration: 'Завершить регистрацию',
    do_skip_and_end_registration: 'Пропустить и завершить регистрацию',
    from: 'От',
    before: 'До',
    do_cancel: 'Отменить',
    cancel: 'Отмена',
    format: 'Формат',
    do_download_file: 'Загрузить файл',
    total: 'Итого',
    pick_or_input: 'Выберите вариант или продолжайте ввод',
    search: 'Поиск',
    step: 'Шаг',
    all: 'Все',
    price: 'Цена',
    here: 'Здесь',
    go_to: 'Перейти',
    price_with_currency2: 'Цена, ₽',
    do_create: 'Создать',
    active_status_yes: 'Активен',
    active_status_no: 'Неактивен',
    go_to_page: 'Перейти на страницу',
    do_send: 'Отправить',
    yesterday: 'Вчера',
    today: 'Сегодня',
    you: 'Вы',
    main_page: 'Главная',
    logout: 'Выйти',
    shortcut_week: '7 дней',
    shortcut_month: '31 день',
    among: 'Из',
    time_zone: 'Часовой пояс',
    pick_time_zone: 'Выберите временную зону',
    ready: 'Готово',
    // Типы торговых точек
    extended_types_store: 'Магазин',
    extended_types_refrigerator: 'Микромаркет',
    extended_types_store_online: 'Интернет-Магазин',
    extended_types_cafe: 'Кафе',
    extended_types_production_with_delivery: 'Производство с доставкой',
    extended_types_filling_station: 'АЗС',
    extended_types_cigarettes: 'Сигаретный шкаф',
    extended_types_coffee_machine: 'Кофемашина',
    stage_type_title: 'Добавьте торговую точку',
    stage_type_edit_title: 'Основная информация',
    stage_type_subtitle: 'Выберите тип торговой точки',
    stage_type_description: 'Выберите вашу категорию: магазин, кафе или другую из предложенного списка. Подробнее о возможностях каждой категории здесь',
    stage_type_label_phone: 'Телефон торговой точки',
    address: 'Адрес',
    stage_type_label_floor: 'Этаж',
    stage_type_store_radius: 'Радиус действия\nсистемы Scan&Go',
    stage_type_refrigerator_radius: 'Радиус с которого будет\nоткрываться дверь',
    stage_type_cigarettes_radius: 'Радиус с которого будет\nоткрываться дверь',
    stage_type_filling_station_radius: 'Радиус действия\nсистемы Briskly',
    stage_type_coffee_machine_radius: 'Радиус действия\nкофемашины',
    stage_design_description: 'Выберите, как будет выглядеть ваша торговая точка в приложении B-Pay. Или загрузите логотип. Он будет отображаться в карточке товара',
    stage_design_label_select: 'Выберите дизайн',
    stage_design_create_new: 'Создать новый дизайн',
    agreement: 'Соглашение',
    promo_briskly: 'Промо-материалы Briskly',
    fuel: 'Топливо',
    date_input_placeholder: 'дд.мм.гггг',
    finish_registration: 'Завершите регистрацию',
    purchases_quantity: 'Количество покупок',
    profit: 'Доход',
    do_open_new_trade_point: 'Открыть новую точку',
    support: 'Служба поддержки',
    profile: 'Личный кабинет',
    error_modal_title: 'Невозможно активировать торговую точку',
    design_title: 'Дизайн',
    card_is_missing: 'Не привязана',
    your_logo: 'Ваш логотип',
    your_logo_in_format: 'Ваш логитип в формате',
    preview_for_app: 'Превью для магазина в приложении',
    enter_text: 'Введите текст',
    pick_background: 'Выберите фон',
    little_background: 'Фон для маленького превью в формате',
    big_background: 'Фон для большого превью в формате',
    do_change_preview: 'Изменить превью',
    mock_address: 'г.Москва, ул. Академика Янгеля, д.26',
    store_photos: 'Фото торговой точки',
    no_design_to_select: 'Нет сохраненных дизайнов',
    //Распписание
    schedule: 'Расписание',
    schedule_title: 'Расписание',
    work_schedule: 'Расписание работы',
    schedule__description: 'Установите расписание работы вашего микромаркета: в какое время он открывается для покупок, а в какое время блокируется.',
    do_download_from_template: 'Загрузить из шаблонов',
    start_day_after_visiting_courier: 'Начинать рабочий день после посещения курьера',
    save_as_template: 'Сохранить как шаблон',
    do_enter_name: 'Введите название',
    week_mon: 'Пн',
    week_tue: 'Вт',
    week_wed: 'Ср',
    week_thu: 'Чт',
    week_fri: 'Пт',
    week_sat: 'Сб',
    week_sun: 'Вс',
    week_all_day: 'Все дни',
    permanently: 'Круглосуточно',
    weekend: 'Выходной',
    do_add_break: 'Добавить перерыв',
    schedule_from: 'с',
    schedule_to: 'по',
    templates: 'Шаблоны',
    invalid_intervals_filled: 'Не заполнены поля',
    invalid_intervals_to_less_then_from: 'Неверно введены интервалы',
    invalid_intervals_intersections: 'Интервалы не должны пересекаться',
    // Сотрудники
    create_employees_title: 'Добавление сотрудников',
    employees_description: 'На этом экране вы добавляете сотрудников своей торговой точки, выбирайте их из списка или заносите данные новых. Назначайте им роли в соответсвии с обязанностями. По умолчанию руководитель подключен ко всем ролям. Подробнее в разделе справки.',
    pick_employees: 'Выберите сотрудников',
    delete_employee_modal_title: 'Вы собираетесь удалить сотрудника',
    employees_selected: 'Выбрано',
    employee_change_modal_title: 'Изменение данных',
    employee_create_modal_title: 'Добавление сотрудника',
    employee_change_modal_role_title: 'Назначьте роль на этойт торговой точке',
    employee_create_modal_role_title: 'Выберите роли',
    add_employee: 'Добавить сотрудника',
    role_administrator_name: 'Администратор',
    role_administrator_access: 'Доступ ко всему функционалу личного кабинета',
    role_courier_name: 'Курьер',
    role_courier_access: 'Доступна возможность открывать микромаркет, менять товар, делать фото, загружать фото в интерфейс курьера',
    role_checker_name: 'Чекер',
    role_checker_access: 'Принимает заказы покупателей и собирает их в торговом зале для отправки клиенту',
    employees: 'Сотрудники',
    to_add_new_employee: 'Добавить нового сотрудника',
    // Реквизиты
    requisites_title: 'Реквизиты',
    requisites_description: 'Добавьте реквизиты вашей торговой точки или заполните данные вашего виртуального терминала. Они нужны для отправки денег на ваш расчетный счет. Данные компании будут указаны при выдаче чека клиентам.',
    requisites_single_description: 'В этом разделе указаны все реквизиты ваших текущих торговых точек, а также вы можете внести новые. Это необходимо для отправки денег на расчетный счет и при выдаче чека клиентам. Вырученные от продаж средства поступают на расчётный счёт раз в сутки.',
    pick_requisites: 'Выбрать реквизиты',
    requisites_company_info: 'Данные о компании',
    requisites_client_info: 'Данные о клиенте',
    requisites_fio: 'ФИО',
    requisites_phone: 'Номер телефона',
    requisites_mail: 'Электронная почта',
    requisites_account_number: 'Номер счёта',
    requisites_current_account_number: 'Номер расчетного счета',
    requisites_bank_bic: 'БИК банка',
    requisites_bic: 'БИК',
    add_new_requisites: 'Добавить новые реквизиты',
    adding_new_requisites: 'Добавление новых реквизитов',
    requisites_cheque: 'Кассовый чек',
    requisites_non_cash: 'Безналичными',
    requisites_buyer_phone: 'Телефон покупателя',
    requisites_items: 'Товары',
    requisites_item: 'Товар',
    requisites_provider_info: 'Данные поставщика',
    requisites_name: 'Наименование',
    requisites_inn: 'ИНН или наименование юридического лица',
    requisites_step1: 'Тип занятости',
    requisites_step2: 'Персональные данные',
    requisites_step2_legal: 'Персональные данные руководителя',
    requisites_step3: 'Реквизиты организации',
    requisites_step4: 'Платежная информация',
    requisites_pick_employment_type: 'Выберите тип занятости',
    employment_type_individual: 'ИП',
    employment_type_legal: 'Организация',
    requisites_position: 'Должность (как в уставе)',
    passport_first_page: 'Первая страница паспорта',
    passport_second_page: 'Страница с пропиской',
    passport_series: 'Серия',
    passport_number: 'Номер',
    passport_issue_date: 'Дата выдачи',
    passport_authority_code: 'Код подразделения',
    passport_authority_name: 'Наименование подразделения, выдавшего документ',
    passport_citizenship: 'Гражданство',
    passport_registration_place: 'Адрес регистрации (как в паспорте)',
    passport_birth_place: 'Место рождения',
    passport_birth_date: 'Дата рождения',
    requisites_capital: 'Уставный капитал, ₽',
    requisites_tax_system: 'Выберите систему налогообложения',
    tax_system_general: 'ОСН',
    tax_system_simplified_1: 'УСН (доходы-расходы)',
    tax_system_simplified_2: 'УСН (доходы-6%)',
    requisites_org_phone: 'Телефон организации',
    requisites_company_iname: 'Имя компании',
    requisites_okopf_code: 'ОКОПФ',
    requisites_ogrn: 'ОГРН',
    requisites_okved_code: 'ОКВЭД',
    requisites_bank_account_info: 'Данные о банковском счёте',
    requisites_cheque_hint: 'Теперь вы можете посмотреть как будет выглядеть чек вашей торговой точки.',
    requisites_recipient_bank_name: 'Банк получателя',
    requisites_bank_correspondent_account: 'Корр. счёт',
    requisites_missing: 'Отсутствуют',
    requisites_personal_data_descr_part_1: 'Мы просим вас заполнить данную информацию, чтобы иметь возможность ежедневно переводить вам вырученные средства с продаж вашей торговой точки. Для этого, необходимо предоставить сведения, аналогичные тем, что вы предоставляете при подключении эквайринга.',
    requisites_personal_data_descr_part_2: 'Ваши данные запрашиваются по требованию ЦБ и банка-эквайера. Они будут храниться в зашифрованном виде. Также, компания Briskly состоит в реестре операторов персональных данных Роскомнадзора.',
    //Доставка
    want_to_deliver: 'Хотите доставлять свои товары с помощью своей или нашей доставки?',
    delivery_own_radius: 'Радиус собственной доставки',
    delivery_briskly_radius: 'Радиус доставки партнерами Briskly',
    delivery_own: 'Собственная доставка',
    delivery_briskly: 'Доставка партнерами Briskly',
    delivery_both: 'Оба варианта',
    delivery_own_tariffs: 'Тарифы по собственной доставке',
    delivery_briskly_tariffs: 'Тарифы по доставке партнерами briskly',
    delivery_briskly_description: 'Обратите внимание, если установленная вами стоимость доставки для клиента будет меньше, чем фактическая стоимость, в конце месяца вам придёт счёт на оплату этой разницы. Если ваша стоимость будет больше, то вам придут деньги в размере данной разницы.',
    delivery_briskly_price: 'Стоимость доставки партнерами Briskly',
    delivery_pickup: 'Возможность самовывоза',
    delivery_tariffs: 'Тарифы по доставке',
    order_sum: 'Сумма заказа',
    delivery_cost: 'Стоимость доставки',
    delivery_invalid_rates_text: 'Интервалы сумм заказа должны быть разными, между ними должна разница быть не более 1 рубля.',
    do_add_rate: 'Добавить условие',
    delivery_warning_modal_title: 'Вы собираетесь изменить способ доставки',
    delivery_warning_modal_body: 'После сохранения, предыдущий способ доставки будет удален',
    delivery_cost_free: 'Бесплатно',
    delivery_cost_discount_percent: 'Скидка от цены доставки в процентах',
    delivery_cost_discount_fixed: 'Скидка от цены доставки в рублях',
    delivery_cost_fixed: 'Фиксированная цена',
    delivery_cost_unknown: 'Доставку оплачивает клиент',
    offer: 'Оферта',
    do_download_offer: 'Скачать оферту',
    do_sign_document: 'Подписать документ',
    code_from_sms: 'Код из смс',
    try_again_in: 'Если код не пришел, попробуйте еще раз через',
    in_seconds: 'секунд',
    do_send_code_again: 'Отправить код повторно',
    do_send_confirmation_code: 'Отправить код-подтверждение',
    //Планограмма
    planogram: 'Планограмма',
    planogram_block_hint: 'Необходимо создать каталог',
    will_be_deleted: 'Будет удалено',
    planogram_photo: 'Фото планограммы',
    do_add_items_on_shelf: 'Добавьте товары на полку',
    your_micromarket: 'Ваш микромаркет',
    shelf: 'полка',
    shelf_is_overflow: 'На этой полке больше не поместится',
    do_add_items_on_another_shelfs: 'добавьте товары на другие полки',
    do_add_shelf: 'добавить полку',
    going_to_remove_all_items: 'Вы собираетсь удалить все товары с полки',
    going_to_remove_shelf: 'Вы собираетсь удалить полку',
    planogram_item: 'товар',
    planogram_list: 'Список планограмм',
    do_download_new_planogram: 'Загрузить новую планограмму',
    //Оплата
    group_by: 'Группировать по',
    period: 'Период',
    order_status: 'Статус заказа',
    pick_store: 'Выберите магазин',
    pick_stores: 'Выберите магазины',
    option_selection_by_number: 'Выборка по номеру',
    paid: 'Оплачено',
    processed: 'Обработан',
    refund: 'Возврат',
    refund_awaiting: 'В ожидании возврата',
    refund_error: 'Ошибка возврата',
    removed: 'Удалён',
    quantity: 'Количество',
    year: 'Год',
    id_local: 'ИД',
    order_number: 'Номер заказа',
    client: 'Клиент',
    item_quantity: 'Кол-во товаров',
    items_sufix: '{_} товар | {_} товара | {_} товаров',
    commission: 'Комиссия',
    income: 'Прибыль',
    item_price: 'Цена за товар',
    period_start: 'Начало периода',
    period_end: 'Конец периода',
    sum_with_discount: 'Сумма со скидкой',
    sum: 'Сумма',
    orders: 'Заказы',
    clients: 'Клиенты',
    unique_clients: 'Уникальные дневные клиенты',
    sales: 'Продажи',
    completed_order: 'Завершённый заказ',
    refund_awaiting_from_client: 'Ожидается доплата с клиента',
    // Учет Товара
    service_one_s: '1C',
    service_iiko: 'Айко',
    service_iiko_biz: 'Айко Биз',
    service_moy_sklad: 'Мой Склад',
    service_supermag: 'Супермаг',
    service_yml: 'YML',
    service_xlsx: 'XLSX',
    service_csv: 'csv',
    service_store_house: 'StoreHouse',
    service_poster: 'Poster',
    service_ya_badge: 'Яндекс.Бейдж',
    unit_id: 'ОКЕИ (Ед. Измерения)',
    something_wrong_try_again: 'Что-то пошло не так. Попробуйте еще раз.',
    use_suggestions_begin: 'Воспользуйтесь нашими',
    use_suggestions_action: 'подсказками',
    use_suggestions_end: 'для интеграции системы',
    payment_description: 'Отправьте запрос на добавление способа оплаты ответственному лицу магазина, где будет установлен сигаретный шкаф.',
    send_request: 'Отправить запрос',
    do_change_payment_method: 'Сменить способ оплаты',
    current_card: 'Текущая карта',
    //Соц.сети
    follow_us_begin: 'Подписывайтесь на нас',
    follow_us_end: 'в соцсетях',
    bpay_title: 'Скачайте приложение b-pay',
    bpay_subtitle: 'Покупайте без очередей',
    checker_title: 'Используйте приложение чекер',
    checker_subtitle: 'Собирайте заказы ваших клиентов удаленно',
    //Валидация
    valid_required: 'Обязательно',
    valid_article_required: 'Артикул обязателен',
    valid_min_3: 'Минимум 3 символа',
    valid_min_1: 'Минимум 1 символ',
    valid_min_6: 'Минимум 6 символов',
    valid_max_60: 'Максимум 60 символов',
    valid_max_5: 'Максимум 5 символов',
    valid_max_6: 'Максимум 6 символов',
    valid_max_13: 'Максимум 13 символов',
    valid_max_20: 'Максимум 20 символов',
    valid_max_32: 'Максимум 32 символа',
    valid_max_350: 'Максимум 350 символов',
    valid_max_250: 'Максимум 250 символов',
    valid_special_symbols_not_allow: 'Спецсимволы недопустимы',
    valid_mail_format: 'Электронная почта в формате: welcome@brskl.io',
    valid_phone_format: 'Телефон в формате +7 (123) 456 78 90',
    valid_no_less_1_percent: 'Не меньше 1 %',
    valid_no_more_99_percent: 'Не больше 99 %',
    valid_fill_the_field: 'Заполните поле',
    valid_upload_both_photos: 'Загрузите оба изображения',
    valid_incorrect_date: 'Неверная дата',
    valid_incorrect_phone: 'Неверный телефон',
    valid_passport_series: 'Неверная серия',
    valid_passport_number: 'Неверный номер паспорта',
    valid_passport_authority_code: 'Неверный код подразделения',
    valid_inn: 'Выберите ИНН',
    valid_address: 'Выберите адрес',
    valid_bic: 'Выберите БИК',
    valid_account_number: 'Длина расчетного счета должна быть 20 символов',
    valid_maximum: 'Максимальное число символов',
    valid_select_some_filter: 'Выберите хотя бы один фильтр',
    //Брискли Модуль
    module_tittle: 'Модуль',
    briskly_module: 'Briskly Модуль',
    module_description: 'Briskly модуль — это мозг вашего микромаркета.  При покупке вам выдали код, который необходимо привязать к аккаунту.  Код можно посмотреть',
    module_to_buy: 'Если Вы еще не оплатили Микромаркет (холодильник + Briskly Модуль) или отдельно Briskly Модуль для вашего холодильника,',
    module_link_to_buy: 'сделайте это сейчас',
    indicate_divice_code: 'Укажите код с устройства',
    module_data: 'Данные о Briskly модуле',
    network_status: 'Состояние сети',
    working: 'Работает',
    not_working: 'Не работает',
    lock_status: 'Статус замка',
    lock_close: 'Закрыт',
    lock_open: 'Открыт',
    air_temp: 'Температура воздуха',
    last_time_in_network: 'Последний раз в сети',
    lock_control: 'Управление замком',
    module_error_title: 'Данные недоступны. Это могло произойти из-за',
    module_check: 'Проверить модуль',
    buy_module: 'Покупка Briskly Модуля',
    to_retry: 'Повторить попытку',
    setup_module_in_you_ref: 'Установка системы в ваш холодильник',
    smart_ref_all_options: 'Умный холодильник «под ключ»',
    popular_format: 'Популярный формат',
    big_market_with_all_options: 'Большой киоск «под ключ»',
    lock_mount: 'Установка эл. замка',
    connect_to_b_pay: 'Подключение к приложению B-Pay',
    goods_integration: 'Интеграция ассортимента',
    temperature_sensor: 'Датчики температуры',
    spy_camera: 'Камера наблюдения',
    refregerator_in_set: 'Холодильник в комплекте',
    trade_point_branding: 'Брендинг микромаркета',
    electronic_lock: 'Электронный замок',
    refregerator_with_two_doors: 'Холодильник с двумя створками',
    module_apply: 'Подать заявку',
    left_a_little: 'Осталось немного',
    fill_in_the_required_sections_for_your_tt_start_working: 'Заполните, пожалуйста, обязательные разделы, чтобы ваша торговая точка начала работать',
    continue_filling: 'Продолжить заполнение',
    copy_link: 'Скопировать ссылку',
    lock: 'Замок',
    last_check: 'Последняя проверка',
    //Доставка
    stages_description_delivery: 'Выберите один из вариантов доставки',
    stages_description_design: 'Сейчас ваша торговая точка в приложении B-pay выглядит стандартно. Чтобы выделиться, вы можете изменить дизайн',
    stages_description_employees: '',
    stages_description_exchange: 'Загрузите свои товары в каталог, чтобы клиенты смогли увидеть их.',
    stages_description_briskly_module: 'Все торговые точки отображаются в системе Briskly только после подключения Briskly Модуля.',
    stages_description_offer: 'Вам нужно подписать договор оферты, чтобы пользоваться Briskly кабинетом',
    stages_description_planogram: 'Расположите на полках микромаркета товары, как их будут видеть ваши клиенты.',
    stages_description_requisites: 'Статус: проверяются.\nПроверка занимает 1-3 рабочих дня.',
    stages_description_schedule: 'Чтобы торговая точка работала, заполните поля расписания.',
    stages_description_type: '',
    stages_description_fuel: '',
    stages_description_payment: 'Клиент ещё не подключил карту для оплаты.\n Скопируйте ссылку и отправьте ему, если ещё не сделали этого.',
    trade_point_registation_complite: 'Регистрация завершена',
    congratulations_your_tt_is_ready_to_go: 'Поздравляем! Ваша торговая точка готова к работе',
    fine: 'Отлично',
    congratulations_your_tt_is_ready_we_need_check: 'Поздравляем! Вы завершили регистрацию! Теперь нам нужно 1-3 рабочих дня, чтобы проверить ваши реквизиты. После этого вам будет открыт доступ к торговой точке.',
    download_qr_code: 'Скачать QR-код ',
    download_sticker: 'Скачать инструкцию',
    download_table_banner: 'Скачать настольный баннер',
    do_edit_sufix: 'Редактирование',
    delete_trade_point: 'Удалить торговую точку',
    this_action_cant_canceled: 'Это приведет к безвозвратному удалению',
    employees_title: 'Сотрудники',
    planogram_title: 'Планограмма',
    payment_method: 'Способ оплаты',
    payment_status: 'Статус оплаты',
    temperature_setup: 'Настройка температуры',
    temperature_setup_title: 'Настройте температурный режим работы вашего микромаркета. Когда температура внутри  микромаркета будет выходить за установленные вами пределы, мы пришлём вам уведомление.',
    time_of_ordering: 'Время заказа',
    Video: 'Видео',
    video_by_order: 'Видео к заказу',
    pick_date: 'Выберите дату',
    video_recording_date: 'Дата записи видео',
    video_not_found: 'Видео не найдено',
    //Документы
    download: 'Скачать',
    is_signet: 'Подписан',
    documents: 'Документы',
    is_doesnt_signed: 'Не подписан',
    is_touched: 'Просмотрен',
    to_assign: 'Подписать',
    time_stamp: 'Время добавления',
    type: 'Тип',
    offer_for_partners: 'Оферта для партнеров',
    tariff_plans: 'Тарифные планы',
    personal_account_rules: 'Правила пользования ЛК',
    //Отчеты
    reports: 'Отчеты',
    report_about_clients: 'Отчет по клиентам',
    report_about_items: 'Отчет по товарам',
    report_about_delivery: 'Отчет по доставкам',
    report_about_promocodes: 'Отчет по промокодам',
    report_about_orders: 'Статистика по заказам',
    report_about_sales: 'Статистика по продажам',
    report_about_unique_clients: 'Статистика по уникальным дневным клиентам',
    do_download_data: 'Скачать данные',
    do_download_promo_briskly: 'Скачать промо-материалы Briskly',
    download_file_format: 'Формат скачивания',
    //Персональные данные
    your_account_has_run_out_of_money: 'На вашем счёте закончились деньги.',
    do_top_up_an_account: 'Пополнить баланс',
    air_temperature: 'Температура воздуха',
    inside: 'Внутри',
    outside: 'Снаружи',
    status: 'Статус',
    actual_address: 'Фактический адрес',
    card: 'Карта',
    phone: 'Телефон',
    credentials: 'Реквизиты',
    delivery: 'Доставка',
    do_edit: 'Редактировать',
    most_popular_items: 'Популярные товары',
    item_name: 'Название товара',
    price_with_currency: 'Цена, ₽',
    revenue_with_currency: 'Выручка, ₽',
    sold_items_with_amount: 'Продано, шт',
    // Скидки
    option_unknown: 'Не установлен',
    option_min: 'Минимальный',
    option_max: 'Максимальный',
    option_discount_percent: 'Скидка в процентах',
    option_discount_amount: 'Скидка в рублях',
    option_discount_new_price: 'Новая цена',
    option_usage_common: 'Общая скидка',
    option_usage_first_order: 'Первая покупка без учета времени',
    option_usage_first_order_after_timestamp: 'Первая покупка после назначенного временного лимита',
    discount_size: 'Размер скидки',
    discount: 'Скидка',
    excluded_cost: 'Исключенная стоимость',
    applying_features: 'Особенности применения',
    applying: 'Применение',
    do_add_discount: 'Добавить скидку',
    editing_of_discount: 'Редактирование скидки',
    adding_of_discount: 'Добавление новой скидки',
    applying_order: 'Порядок применения',
    save_changes: 'Сохранить изменения',
    do_save: 'Сохранить',
    excluded_cost_tooltip: "\u0421\u0443\u043C\u043C\u0430 \u0432 \u0447\u0435\u043A\u0435 \u043D\u0430 \u043A\u043E\u0442\u043E\u0440\u0443\u044E \u043D\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0443\u0435\u0442 \u0441\u043A\u0438\u0434\u043A\u0430. \n\u041F\u0440\u0438\u043C\u0435\u0440: \u0441\u043A\u0438\u0434\u043A\u0430 10% \u043D\u0430 \u043F\u043E\u043A\u0443\u043F\u043A\u0443 \u0441\u0432\u044B\u0448\u0435 500\u0440\u0443\u0431 (\u0438\u0441\u043A\u043B\u044E\u0447\u0435\u043D\u043D\u0430\u044F \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C 500\u0440) \n\u0421\u0443\u043C\u043C\u0430 \u043F\u043E\u043A\u0443\u043F\u043A\u0438 900\u0440 \u0441\u043A\u0438\u0434\u043A\u0430 10% \u0434\u0435\u0439\u0441\u0442\u0432\u0443\u0435\u0442 \u043D\u0430 400\u0440 \u0438 \u0441\u043E\u0441\u0442\u0430\u0432\u0438\u0442 40\u0440.",
    client_show_settings: 'Настройки показа для клиентов',
    do_show: 'Показывать',
    title: 'Заголовок',
    description: 'Описание',
    image: 'Изображение',
    add_image_proposal: 'Добавьте изображение',
    adding_of_discount_group: 'Добавление набора скидок',
    editing_of_discount_group: 'Редактирование набора скидок',
    do_add_discount_group: 'Добавить набор',
    do_add_discount_group_v2: 'Добавить набор скидок',
    do_assign_discount_group_to_store: 'Назначить скидки торговым точкам',
    discounts: 'Скидки',
    do_delete: 'Удалить',
    not_delete: 'Не удалять',
    do_reset_changes: 'Отменить изменения',
    you_are_trying_to_delete_discount: 'Вы собираетесь удалить скидку',
    you_are_trying_to_delete_discount_group: 'Вы собираетесь удалить набор скидок',
    discount_assign: 'Назначение скидок',
    store_name: 'Название торговой точки',
    discount_group: 'Набор скидок',
    title_for_clients: 'Заголовок для клиентов',
    is_enabled: 'Включен',
    is_disabled: 'Выключен',
    is_enabled_female: 'Включена',
    is_disabled_female: 'Выключена',
    show_for_clients: 'Показывать для клиентов',
    type_of_discount: 'Тип скидки',
    set: 'Набор',
    around_the_clock: 'Круглосуточно',
    doesnt_act: 'Не действует',
    // Товары
    good_photo: 'Фотография товара',
    volume: 'Объем',
    step_reminder: 'Вы можете вернуться к этому шагу позже.',
    category: 'Категория',
    categories: 'Категории',
    category_sufix: 'Категорий',
    create_catalog: 'Создайте каталог',
    create_catalog_label: 'В вашем аккаунте может быть несколько каталогов (например для микромаркета торгующего готовой едой или торгующего замороженной продукцией). Выбирая соответствующий каталог, вы быстро переключаетесь с одного списка товаров на другой. Здесь необходимо создать этот каталог.',
    catalog_name: 'Название каталога',
    non_tax: 'Не облагается',
    payment: 'Расчетный',
    standard: 'Стандартный',
    altogether: 'Всего',
    units: 'Единицы измерения',
    age_limit: 'Возрастное ограничение, лет',
    composition_of_item: 'Состав',
    calories: 'Калории',
    optional_description: 'Описание (необязательно)',
    vat_mode: 'Режим НДС',
    vat_rate: 'Ставка НДС, %',
    block_with_modifications: 'Блок с модами',
    item_add_action: 'Добавление товара',
    item_edit_action: 'Редактирование товара',
    dimension: 'Размерность',
    item_add_action2: 'Добавьте товары',
    add_and_edit_catalog: 'Добавление/редактирование каталога',
    need_select_catalog: 'Нужно выбрать каталог',
    do_add_more_categories: 'Добавить ещё категорию',
    goods_name: 'Наименование товара',
    expiration_date: 'Cрок годности',
    barcode: 'Штрихкод',
    sticker_quantity: 'Кол-во наклеек',
    manufacturing_by: 'Произведено',
    h: 'ч',
    d: 'д',
    m: 'м',
    y: 'г',
    composition: 'Состав',
    do_not_specifiedSuffix: 'Не указанна',
    do_not_specified: 'Не указан',
    fats: 'Жиры',
    proteins: 'Белки',
    carbohydrates: 'Углеводы',
    calories_full: 'Энергетическая ценность',
    ccal: 'ккал',
    nutritional_value_per_100g_of_product: 'Пищевая ценность(на 100гр продукта)',
    at_the_goods: 'У товара',
    with_barcode: 'С баркодом',
    error_with_bar_code: 'Ошибка с генерацией Баркода!',
    maybe_doest_valid_control_digit: 'Возможно: Неверная контрольная цифра!',
    add_goods: 'Добавить товары',
    add_good: 'Добавить товар',
    do_delete_category: 'Удалить категорию',
    be_deteted: 'Будет удалено',
    need_detals: 'Нужно уточнение',
    good_with_no_valid_barcode_need_write_it: 'У данного товара невалидный штрих-код, \n заполните его',
    go_to_edit: 'Перейти в редактирование',
    later: 'Позже',
    good_suffix: 'продукт',
    article: 'Артикул',
    photo: 'Фото',
    dimension_units: 'Ед. измерения',
    active: 'Активный',
    sticker: 'Стикер',
    items: 'Товары',
    do_create_catalog: 'Создать каталог',
    do_create_new_catalog: 'Создать новый каталог',
    stickers_print: 'Печать стикеров',
    write_name_of_new_catalog: 'Введите название нового каталога',
    write_name_of_new_category: 'Введите название новой категории',
    do_add: 'Добавить',
    do_go_back: 'Назад',
    do_continue: 'Продолжить',
    catalog: 'Каталог',
    doesnt_have_category: 'Не имеет категории',
    stickers: 'Стикеры',
    do_print_stickers: 'Распечатать стикеры',
    manufacturer_name: 'Наименование производителя',
    manufacturing_address: 'Адрес изготовления',
    production_date: 'Дата изготовления',
    time: 'Время',
    hours: 'Часы',
    minutes: 'Минуты',
    days: 'Дни',
    weeks: 'Недели',
    months: 'Месяцы',
    years: 'Годы',
    do_apply: 'Применить',
    general_expiration_date: 'Общий срок годности',
    amount_of_stickers_per_position: 'Кол-во наклеек для одной позиции',
    do_mark_all: 'Отметить все',
    heating: 'Разогрев блюда',
    // Документы
    do_download_agreement: 'Скачать соглашение',
    personal_lowercase: 'личный',
    cabinet_lowercase: 'кабинет',
    choose_option_or_continue_input: 'Выберите вариант или продолжайте ввод',
    unknown_organization: 'Неизвестная организация',
    organization_info: 'Данные об организации',
    organization_name: 'Название организации',
    organization_phone: 'Телефон организации',
    inn: 'ИНН',
    head_of_company: 'Руководитель',
    registration_date: 'Дата регистрации',
    okved: 'ОКВЭД',
    capital: 'Уставной капитал',
    taxes_system: 'Система налогообложения',
    do_write_inn: 'Введите ИНН',
    osno: 'ОСН',
    usn1: 'УСН (доходы-расходы)',
    usn2: 'УСН (доходы 6%)',
    innLabel1: '{label} или название организации',
    innLabel2: '{label} или фамилию',
    acting: 'Действующий',
    first_name_placeholder: 'Михаил',
    second_name: 'Фамилия',
    second_name_placeholder: 'Михайлов',
    third_name: 'Отчество',
    third_name_placeholder: 'Михайлович',
    position_label: 'Должность (как в уставе организации)',
    position_placeholder: 'Генеральный директор',
    okopf_name_short_label: 'Правовая форма в сокращенном виде',
    okopf_name_full_label: 'Правовая форма в полном виде',
    ooo_full_name: 'Общество с ограниченной ответственностью',
    name_short_with_opf: 'Короткое название с ОПФ',
    name_short_with_opf_placeholder: "ООО 'Багратион'",
    type_of_company_placeholder: 'ООО, ИП',
    name_short: 'Короткое название',
    name_short_placeholder: 'Багратион',
    ogrn: 'ОГРН',
    first_passport_page: 'Первая страница паспорта',
    passport_page_with_place_of_live: 'Страница с пропиской',
    bank_account: 'Номер расчетного счета',
    bank_account_register_placeholder: 'Введите 20 цифр счета в банке (если счет состоит из 22 цифр, укажите первые 20)',
    bik: 'БИК',
    bik_register_placeholder: 'Введите 9 цифр номера БИК банка',
    bank_credentials: 'Реквизиты банка',
    receivers_bank: 'Банк получателя',
    bank_account_number: 'Номер счёта',
    bank_correspondent_number: 'Корр. счёт',
    your_name: 'Ваше имя',
    phone_number: 'Номер телефона',
    type_of_employment: 'Тип занятости',
    ip_as_type_of_organization: 'ИП',
    legal_as_type_of_organization: 'Организация',
    date: 'Дата',
    inventory_system: 'Система товароучета',
    integration_errors: 'При интеграции возникли ошибки',
    integration_with_system_were_succeed: 'Интеграция с {system} прошла успешно',
    do_change_info: 'Изменить данные',
    do_skip: 'Пропустить',
    select_other_system: 'Выбрать систему',
    select_other_system2: 'Выбрать другую систему',
    do_check_integration: 'Проверить интеграцию',
    integration_entry_label: ' Здесь вы добавляете товары, которые будете продавать. Это можно сделать несколькими способами',
    integration_entry_label_option1: 'Интегрировать систему Briskly с вашей системой учета.',
    integration_entry_label_option2: 'Загрузить файл с товарами.',
    integration_entry_label_option3: 'Выбрать существующий каталог',
    integration_entry_label_option4: 'Добавить товары вручную',
    select_catalog: 'Выберите каталог',
    upload_or_drag_file: 'Загрузите или перетащите файл',
    integration: 'Интеграция',
    integration_file_label: 'Для правильной загрузки каталога, соотнесите наши названия полей с тем, как они названы у вас.',
    item_add_action2_label: 'Создавайте категории и добавляйте в них товары',
    write_category_name: 'Введите название категории',
    do_create_category: 'Создать категорию',
    actions: 'Действия',
    do_send_claim: 'Отправить жалобу',
    claim_text: 'Tекст жалобы',
    for_order: 'к заказу',
    order: 'Заказ',
    client_id: 'ID клиента',
    total_discount: 'Cумма скидки',
    order_info: 'Информация о заказе',
    claim_sended: 'Жалоба отправлена',
    total_amount: 'Общая стоимость',
    order_composition: 'Состав заказа',
    additionally: 'Дополнительно',
    fields_are_optional: 'поля не обязательные для заполнения',
    show_by: 'Показать по',
    do_briskly_module_active_new: 'Активировать новый модуль',
    do_briskly_module_finish: 'Завершить',
    briskly_module_reassign_title: 'Привязка нового модуля',
    briskly_module_reassign_message: 'Вы точно хотите привязать новый Briskly модуль?',
    error: 'Ошибка',
    fail_bind_employee_to_store: 'Не удалось прикрепить сотрудника',
    fail_unbind_employee_to_store: 'Не удалось отвязать сотрудника',
    fail_init_employee_store_list: 'Не удалось загрузить данные о торговых точках',
    fail_onboarding_init_company_info: 'Не удалось загрузить данные компании, заполняемые ранее',
    unknown_error: 'Что-то пошло не так',
    //Товары
    delete_category_text_confirm: 'Это действие приведет к удалению каталога, а товары которые находятся в нем окажутся в категории "все"',
    delete_catalog_text_confirm_1: 'При удалении каталога',
    delete_catalog_text_confirm_2: 'из него будут удаленны все категории и товары находящиеся в нем',
    do_collapse: 'Свернуть',
    exchange_settigns: 'Настройки обмена',
    create_catalog_label_2: 'В вашем аккаунте может быть несколько каталогов. \nНапример для микромаркета торгующего готовой едой или торгующего замороженной продукцией. \nВыбирая соответствующий каталог, вы быстро переключаетесь с одного списка товаров на другой. \nЗдесь можно поменять этот каталог.',
    need_to_select_catalog_and_category: 'Для создания товара \n нужно выбрать \n каталог и категорию',
    catalogs_and_categories: 'Каталоги и категории',
    categories_of_catalogs: 'Категории каталога',
    add_catalog: 'Добавить каталог',
    add_category: 'Добавить категорию',
    add_catalog_action: 'Добавление каталога',
    add_category_action: 'Добавление категории',
    category_name: 'Название категории',
    catalogs: 'Каталоги',
    search_by_categories: 'Поиск по категориям',
    search_by_catalogs: 'Поиск по каталогам',
    warning: 'Внимание!',
    write_new_catalog_name: 'Введите название каталога',
    write_new_category_name: 'Введите название категории',
    add_catalog_warning: 'Вы можете привязать каталог к нескольким торговым точкам, для этого воспользуйтесь “+” для добавления дополнительного поля выбора торговой точки.',
    add_catalog_info: 'Вы можете добавить сразу несколько категорий в один каталог, для этого воспользуйтесь “+” для добавления дополнительного поля для ввода названия новой категории. ',
    product_product_date: 'Печатать дату изготовления',
    // Репорты
    report_about_item: 'Статистика по товару',
    report_order_id: 'Номер Заказа',
    //Рассылки
    mailings: 'Рассылки',
    do_add_mailing: 'Создать рассылку',
    mailing_new: 'Новая рассылка',
    mailing_edit: 'Редактирование рассылки',
    mailing_status_complete: 'Выполнена',
    mailing_status_saved: 'Сохранена',
    mailing_status_moderation: 'На модерации',
    mailing_status_wait: 'Ждёт отправки',
    mailing_status_cancelled: 'Отменена',
    mailing_status_ready: 'Оплатить',
    mailing_add_description: 'Добавьте вашу рассылку. Укажите время отправки, аудиторию клиентов и текст сообщения.',
    mailing_moderation_description: 'После прохождения модерации вам нужно будет оплатить рассылку. Примите во внимание, что модерация проходит в течении 1-2 рабочих дней, оплата поступает на счет до 2 рабочих дней. Учитывайте это при планировании рассылки.',
    mailing_name: 'Название рассылки',
    mailing_date: 'Дата отправки',
    mailing_audience: 'Аудитория для проведения рассылки',
    mailing_client_count: 'Количество клиентов',
    mailing_cost: 'Стоимость рассылки',
    mailing_text: 'Текст рассылки',
    mailing_only_save: 'Сохранить, но не запускать',
    mailing_save_and_start: 'Запустить рассылку',
    mailing_invalid_time: 'Установите интервал времени с 9:00 до 21:00',
    mailing_may_not_pass_moderation: 'Ваша рассылка может не успеть пройти модерацию.',
    mailing_all_clients: 'Все клиенты',
    mailing_created_filters: 'Готовые фильтры',
    mailing_selecting_filters: 'Выбор фильтра',
    mailing_save_filters: 'Сохранить фильтры',
    mailing_going_to_delete: 'Вы собираетесь удалить рассылку',
    mailing_status_rejected: 'Отклонена',
    filter_will_be_available_soon: 'Этот функционал скоро будет доступен',
    select_employee_for_test_message: 'Выберите сотрудника для отправки тестового сообщения',
    filter_gender: 'Пол',
    filter_age: 'Возраст',
    filter_registered_days_ago: 'Дата регистрации',
    filter_purchases_quantity: 'Количество покупок',
    filter_purchase_days_ago: 'Давность покупок',
    filter_birth_date_month: 'День рождения',
    gender_male: 'Мужской',
    gender_female: 'Женский',
    in_this_month: 'В этом месяце',
    in_next_month: 'В следующем месяце',
    year_ago: 'Год назад',
    // Support service
    support_service_greeting: 'Здравствуйте! Мы будем рады помочь вам!',
    address_doesnt_correct: 'Адрес не корректен',
    no_saved_templates: 'Нет сохраненных шаблонов',
    add_schedule_and_save_as_template: 'Добавьте опции и затем сохраните их как шаблон',
    service_mts_kassa: 'МТС Касса',
    create_new_catalog: 'Создать новый каталог',
    use_existing_catalog: 'Использовать существующий каталог',
    log_out_question: 'Вы точно хотите закончить текущую сессию?',
    external_id: 'Внешний id',
    // Перевод в ручную
    more_then: 'Больше {num}',
    before_num: 'До {num}',
    older_then: 'Старше {num}',
    before_days: '{_} день | До {_} дня | До {_} дней',
    do_save_and_go_back: 'Сохранить и вернуться к списку',
    print_shelf_life: 'Печатать срок годности',
    succes_saved: 'Успешно сохранено',
    quantity_cut: 'Кол-во',
    additional_data_about_good: 'Дополнительные данные о товаре',
    doest_not_req_field: 'необязательные поля',
    do_upload_photo: 'Загрузить фото',
    upload_good_list: 'Загрузить товары списком',
    show_filters: 'Показать фильтры',
    hide_filters: 'Скрыть фильтры',
    available_table_filters: 'Фильтры таблицы',
    deletion: 'Удаление',
    item_warning_info: ' Товар обязательно должен быть привязан к какому-либо каталогу.',
    item_create_info: 'Если вам необходимо создать новый каталог или категорию в текущем каталоге, перейдите в раздел ',
    aprove_catalog_delete: 'Вы собираетесь удалить каталог',
    delete_catalog_text_confirm_3: 'При удалении Каталога будут удалены все Категории и Товары внутри него.',
    delete_catalog_text_confirm_4: 'Для удаления катлога сначала необходимо привязать новый каталог ко всем торговым точкам взамен удаляемого.',
    go_to_edit_store: 'Перейти к редактированию торговых точек',
    choose_trade_point: 'Выберите торговую точку',
    do_catalog_edit: 'Редактирование каталога',
    do_category_edit: 'Редактирование категории',
    category_warn_text_warn: 'Категория должна быть привязана к какому либо каталогу.',
    category_warn_text: 'Если вам необходимо создать новый каталог перейдите по ссылке',
    category_warn_text_link: 'Создать новый каталог.',
    stock_balances: 'Товарные остатки',
    do_add_items_list: 'Добавление товаров списком',
    accounting_for_stock_balances: 'Учёт товарных остатков',
    rebase_catalogs: 'Удаление каталога > Редактирование торговых точек',
    clarification_in_items_side_bar: '* Если товары в вашем файле уже разбиты на категории, достаточно выбрать Каталог и загрузить файл, категории при этом будут созданы автоматичнески.',
    do_download_xlsx: 'Загрузить файл .xlsx',
    stock_accounting_balances_info: 'Для получения актуальной информации о количестве и стоимости товаров в ваших торговых точках воспользуйтесь функцией “Учёт товарных остатков”. ',
    or: 'Или',
    category_info_tip: 'Воспользуйтесь данной функцией для более быстрого, автоматического заполнения новой категории товарами.',
    good_must_be_in_catalog_or_category: 'Товар обязательно должен быть привязан к какому либо каталогу и категории.',
    items_add_side_bar_info: 'Если вам необходимо создать новый каталог или категорию в текущем каталоге, перейдите в раздел ',
    do_delete_catalog: 'Удалить каталог',
    delete_catalog: 'Удаление каталога',
    trade_point_edit: 'Редактирование торговых точек',
    rebase_catalog_1: 'Для удаления каталога “{name}”, вам необходимо',
    rebase_catalog_2: 'привязать новые каталоги ко всем торговым точкам',
    rebase_catalog_3: 'в таблице ниже.',
    cant_start_from_dot: 'Не может начинатся с точки',
    aprove_category_delete: 'Вы собираетесь удалить категорию',
    delete_category_warn: 'Удаление категории повлечет удаление всех товаров в этой категории',
    delete_catalog_confirm_text_5: 'Вы собираетесь удалить пустой каталог в котором нет категорий и товаров',
    delete_catalog_confirm_text_6: 'Вы собираетесь удалить каталог в котором есть категории или товары',
    trade_point_type_name: 'Тип торговой точки',
    select_new_catalog: 'Выберите новый каталог',
    barcoders_prefix_for_weight_good: 'Префиксы штрихкода весового товара',
    barcoders_prefix_info: 'По значению этого префикса система распознаёт весовой товар с выделением цифрового кода товара и его веса в граммах. Доступные префиксы — от 20 до 29. Если префикс не указан, то товары будут обработаны, как штучные.',
    age_limit_2: 'лет',
    age_limit_1: 'Возрастное ограничение,',
    catalog_rebase_info: 'больше не привязан ни к одной из торговых точек, теперь вы можете',
    item_photo_note: '(вы можете загрузить своё фото товара или оставить фото по умолчанию показанное ниже)',
    store_doesnt_have_catalog: 'У торговой точки {name} нету привязки к каталогу. Перейти к редактированию?',
    postal_code: 'Почтовый индекс',
    module_description_temp: 'Briskly модуль — это мозг вашего микромаркета.  При покупке вам выдали код, который необходимо привязать к аккаунту.',
    value_must_be_more_than_zero: 'Значение должно быть больше 0',
    valid_min_13: 'Минимум 13 символов',
    ok: 'Ok',
    dosesnt_correct_number: 'Некорректное число',
    success_deleted: 'Успешно удаленно',
    do_return_back: 'Вернутся назад',
    abort_procedure: 'Прервать процедуру',
    abort: 'Прервать',
    maybe_data_not_saved: 'Возможно, внесенные изменения не сохранятся',
    empty_catalog: 'Каталог пуст',
    fileupload_page_info: '* Если товары в вашем файле уже разбиты на категории, достаточно выбрать Каталог и загрузить файл, категории при этом будут созданы автоматичнески.',
    do_download_xlsx_example: 'Скачать пример .xlsx',
    valid_max_11: 'Максимум 11 символов',
    file_name_uploaded: ' Файл {fileName} загружен',
    columns: 'Столбцы',
    file_upload_rules: 'Требования к файлу для загрузки товаров:',
    file_upload_rules_1: 'Формат — .xlsx;',
    file_upload_rules_2: 'Файл не должен содержать объединённые ячейки.',
    file_upload_rules_3: 'Названия полей должны занимать не более одной строки.',
    file_upload_rules_4: 'В случае ошибок в полях “Единицы измерения“ и “Количество“ — будет автоматические проставляться “1 штука”.',
    are_mandatory: 'являются обязательными',
    file_upload_page_info: 'Для правильной загрузки каталога, соотнесите наши названия полей с тем, как они названы  у вас.',
    edit_file_and_try_again: 'Исправить ошибки и попробовать снова',
    do_stop_now: 'Завершить сейчас',
    payment_type: 'Тип оплаты',
    app: 'Приложение',
    terminal: 'Терминал',
    payment_type_undefined: 'Не определен',
    time_of_creation: 'Время создания',
    id_trade_point: 'ID торговой  точки',
    delete_goods_modal_info: 'и еще {stringsQuantity}... товаров',
    client_show_settings_2: 'Настройки показа для Мобильного приложения',
    no_changes_to_update: 'Нет изменений для сохранения',
    success: 'Успешно',
    order_with_id_inject: 'Заказ ID {orderId}',
    refund_with_order_id_inject: 'Оформление возврата: заказ ID {orderId}',
    barcode_can_includes_only_digits: 'Только числа',
    for_go_to_exchange_need_say_store: 'Для перехода нужно выбрать торговую точку ',
    link_to_showcase: 'Ссылка на витрину товаров',
    catalog_successfully_added: 'Каталог успешно добавлен',
    copy_to_clipboard: 'Скопировать в буффер',
    copied_to_clipboard: 'Скопировано в буффер обмена',
    created_at: 'Время создания',
    customer_id: 'Клиент',
    store: 'Торговая точка',
    about_order: 'О Заказе',
    item_id: 'ID товара',
    good_barcode: 'ШК товара',
    price_with_currency_inject: 'Цена, {currency}',
    sum_with_currency_inject: 'Сумма, {currency}',
    total_amount_price_with_currency_inject: 'Итоговая цена, {currency}',
    total_amount_sum_with_currency_inject: 'Итоговая сумма, {currency}',
    create_refund: 'Создать возврат',
    refund_has_been_created: 'Оформлен возврат',
    no_trans_action: 'Нет транзакций',
    no_refund_text_info: 'По данному заказу не было возвратов.',
    refund_title: 'Оформление возврата',
    find_by_order_number: 'Поиск по номеру заказа',
    orders_not_exist: 'В ваших торговых точках пока нет заказов.',
    bbo_welcome: 'Добро пожаловать в<br>Briskly Business Backoffice!',
    complete_registration: 'Для начала пользования Личным кабинетом пройдите регистрацию.',
    your_company_number: 'Регистрационный номер вашей компании',
    your_company_inn: 'ИНН вашей компании',
    contact_name: 'Имя контактного лица',
    accept_terms: 'Нажимая “Зарегистрироваться”, вы принимаете условия',
    user_agreement_terms: 'пользовательского соглашения',
    register: 'Зарегистрироваться',
    exit: 'Выход',
    license_agreement: 'Лицензионное соглашение с пользователем сайта',
    your_company_country: 'Страна Вашей компании',
    date_should_not_earlier: 'Дата должна быть не раньше',
    date_should_not_later: 'Дата должна быть не позже',
    cant_play_video: 'Не удается воспроизвести видео',
    uploaded_image_min_width: 'Минимальная ширина изображения',
    uploaded_image_max_width: 'Максимальная ширина изображения',
    uploaded_image_min_height: 'Минимальная высота изображения',
    uploaded_image_max_height: 'Максимальная высота изображения',
    allowed_types: 'Поддерживаемые форматы',
    max_file_size: 'Файл должен быть не больше',
    orders_and_video: 'Заказы и видео',
    standard_no_suf: 'Стандарт',
    check_refund_possibility: 'Проверить возможность возврата',
    amout_goods_to_refund: 'Всего товаров к возврату',
    amount_goods_payment: 'Сумма возврата',
    refund_helper_text: 'Для ускоренного поиска товара введите его id, штрих-код или название',
    no_one_good_selected: 'Ни один товар для возврата не выбран.',
    errors_occurred_while_creating_the_refund: 'при создании возврата произошли ошибки:',
    refund_by_id_goes_successed: 'Возврат по заказу id {refundId} прошёл успешно',
    refund_by_id_goes_accept: 'Подтвердить возврат по заказу id {refundId}',
    technical_support: 'Техническая поддержка',
    refund_technical_support_notice: 'Для осуществления возврата обратитесь в службу технической поддержки',
    refund_money_back_notice: 'Деньги поступят на карту клиента в течение 2 суток с момента оформления возврата.',
    refund_ready_title: 'Возврат заказа',
    refund_check_success_info: 'Возврат может быть успешно создан',
    refund_check_success_info_with_order_id: 'Возврат заказа ID {orderId} успешно создан',
    option_discount_percent_cut: 'Скидка, %',
    approve_refund: 'Подтвердить воврат',
    video_not_available: 'Видео недоступно',
    video_tooltip_error: 'Видеофайл повреждён или временно недоступен. Обратитесь в поддержку',
    do_upload: 'Загрузить',
    amout_for_refund: 'Всего товаров к возврату',
    add_refund: 'Добавить возврат',
    view_refund: 'Просмотр возвратов',
    valid_minimum: 'Минимальное число символов',
    onboarding_virtual_terminal: 'Виртуальный терминал',
    bank_name: 'Наименование банка',
    company_name: 'Полное наименование компании',
    party_filling: 'Заполнение реквизитов',
    virtual_terminal: 'Виртуальный терминал',
    select_virtual_terminal: 'Выберите виртуальный терминал',
    virtual_terminal_description: 'Заполните данные вашего виртуального терминала, они необходимы для зачисления денежных средств на ваш счёт, а так же для формирования чека для клиента.',
    add_new_virtual_terminal: 'Добавить терминал',
    coords_lng: 'Долгота',
    coords_lat: 'Широта',
    do_create_refund: 'Оформить возврат',
    serial_number: 'Серийный номер',
    coffee_reeboot: 'Перезагрузить',
    coffee_reeboot_coffee_machine: 'Перезагрузить кофемашину',
    coffee_reeboot_text: 'Перезагрузка устройства помогает решить ряд проблем.',
    defer_service: 'Отложить обязательное сервисное обслуживание',
    coffee_postpone: 'Отложить',
    coffee_service_maintenance_text: 'Сервисное условие будет отложено до следующего включения.',
    block_drinks: 'Блокировать приготовление напитков',
    coffee_block_text: 'Кофемашина остентся активной, но приготовление напитков будет недоступно для клиентов',
    block_drink_changes: 'Блокировать изменение параметров напитков',
    coffee_props_block_text: 'Доступно в момент приготоволения. После активации, клиенты не смогут добавлять изменения в стандартный напиток.',
    block_menu_access: 'Блокировать доступ в меню',
    coffee_menu_text: 'Клиенты не смогут использовать меню на кофемашине. ',
    block_drinks_when_offline: 'Блокировать приготовление напитков при отсутствии \n соединения с сервером более 48 часов',
    coffee_reloading: 'Выполняется перезагрузка...',
    search_by_items: 'Поиск по товарам',
    coffee_item_photo: 'Фотография товара',
    coffee_item_photo_info: '(вы можете загрузить своё фото товара или оставить фото по-умолчанию показанное ниже)',
    coffee_item_type: 'Тип напитка',
    item_recept: 'Рецептура товара',
    value_ml: 'мл',
    cup_with_units_injection: 'Стакан {cupsize} мл',
    drink_with_units_injection: 'Напиток {drinksize} мл',
    coffee_foam: 'Молочная пена',
    coffee_milk: 'Молоко ',
    coffee_water: 'Вода',
    coffee_coffee_size: 'Кофе',
    valid_min: 'Минимум {min}',
    coffee_item_photo_info__temp: 'Вы можете оставить фото по-умолчанию или выбрать  из доступных по кнопке "выбрать" ',
    no_save_upload_remember: 'Нажимая кнопку “Завершить сейчас”, вы подтверждаете сохранение файла с ошибками, что может привести к некорректному отображению данных о ваших товарах.',
    exchange_drpops_with_exception: 'При интеграции произошли ошибки',
    file_is_corrupted: 'Файл поврежден',
    good_must_be_in_catalog_or_category_rewrite: 'Товар обязательно должен быть привязан к какому-либо каталогу.',
    fileupload_page_info_rewrite: '* Если товары в вашем файле уже разбиты на категории, достаточно выбрать Каталог и загрузить файл, категории при этом будут созданы автоматически.',
    loading: 'Загрузка...',
    show_detail_info: 'Показать детальную информацию',
    file_upload_success_info: 'Нажимая “Добавить”, вы подтверждаете загрузку товаров в соответствующие Каталог и Категории.',
    be_created: 'Были созданны',
    selected_catalog: 'Выбран каталог',
    file_uploaded: 'Загрузка файла',
    was_successful: 'прошла успешно',
    generate_automatic: 'Сгенирировать автоматически',
    does_not_correct_control_sum: 'Неверная контрольная сумма',
    active_status: 'Ожидает оплаты',
    paid_status: 'Оплачен',
    refund_status: 'Возвращен',
    refund_awaiting_status: 'Ожидается возврат',
    refund_error_status: 'Ошибка возврата',
    refund_partial_status: 'Частично возвращен',
    removed_status: 'Удален',
    online_store_layouts: 'Макеты оклейки микромаркета',
    promo_stickers: 'Наклейки',
    promo_side_stickers: 'Боковые стикеры',
    promo_on_glas: 'На стекло',
    promo_on_advertising_block: 'На рекламный блок',
    value_mm: 'мм',
    promo_without_ad_block: '(без рекламного блока)',
    promo_with_ad_block: '(с рекламным блоком)',
    return_values_to_default: 'Восстановить значения по-умолчанию ',
    attention: 'Внимание',
    requisites_skip_warning: 'Если вы пропускаете шаг выбора / добавления реквизитов, ваша Торговая точка будет работать в тестовом режиме.',
    select_full_party_description: 'Вы можете выбрать уже ранее использованные реквизиты или добавить новые просто заполнив форму!',
    select_partial_party_description: 'Подключите свой виртуальный терминал к Личному кабинету!',
    pick_terminal: 'Выбрать терминал',
    add_new_full_party: 'Добавить новые',
    add_new_partial_party: 'Добавить новый',
    swift_bic: 'SWIFT / БИК',
    secure3d: '3DS',
    non_secure3d: 'non 3DS',
    full_party_choosing: 'Выбор реквизитов',
    partial_party_choosing: 'Выбор терминала',
    requisites_and_terminals: 'Юридические лица',
    terminals: 'Виртуальные терминалы',
    full_party_status_active: 'Активны',
    full_party_status_pending: 'Проверяются',
    full_party_status_replacement: 'Заменяются',
    full_party_edit: 'Редактирование реквизитов',
    partial_party_edit: 'Редактирование виртуального терминала',
    adding_new_terminal: 'Добавление нового терминала',
    add_full_party: 'Добавить реквизиты',
    add_partial_party: 'Добавить виртуальный терминал',
    full_party_edit_warning: 'После изменения реквизиты будут проверяться в течении некоторого времени. Новые реквизиты будут сохранены за торговыми точками сразу, по итогу проверки.',
    partial_party_edit_warning: 'После изменения терминал будет проверяться в течении некоторого времени. Новый терминал будет сохранен за торговыми точками сразу, по итогу проверки.',
    kpp: 'КПП',
    and: 'и',
    upload_logo_in_format: 'Загрузите ваш логотип в формате',
    upload_logo_with_min_size: 'Минимальный размер картинки',
    upload_logo_with_min_file_size: 'Максимальный размер файла',
    currency: 'Валюта',
    BYN: 'Белорусский рубль (BYN)',
    USD: 'Доллар США (USD)',
    EUR: 'Евро (EUR)',
    KZT: 'Казахский тенге (KZT)',
    CAD: 'Канадский доллар (CAD)',
    CNY: 'Китайский юань (CNY)',
    RUB: 'Российский рубль (RUB)',
    TRY: 'Турецкая лира (TRY)',
    UAH: 'Украинская гривна (UAH)',
    GBP: 'Фунт стерлингов (GBP)',
    CHF: 'Швейцарский франк (CHF)',
    price_must_be_more_then_inject: 'Цена должна быть больше {price}',
    unit_796: 'Штука',
    unit_657: 'Изделие',
    unit_111: 'Миллилитр',
    unit_112: 'Литр',
    unit_163: 'Грамм',
    unit_625: 'Лист',
    unit_704: 'Набор',
    unit_715: 'Пара',
    unit_166: 'Килограмм',
    unit_736: 'Рулон',
    unit_778: 'Упаковка',
    item_from_upload_info: 'Для быстрого добаления товаров в каталог воспользуйтесь функцией',
    promo_briskly_link: 'Промо-материалы',
    store_name_placeholder: 'Наименование торговой точки',
    door_status: 'Статус двери',
    store_condition: 'Состояние микромаркета',
    temperature: 'Температура',
    sticker_size: 'Размер наклейки, мм',
    report_delivery: 'Доставки',
    delivery_type: 'Тип доставки',
    internal: 'Собственная',
    dostavista: 'Достависта',
    Dostavista: 'Достависта',
    tm: 'Такси Мастер',
    gett: 'GETT',
    yandex: 'Яндекс',
    main: 'Основные',
    additional: 'Дополнительные',
    promo_codes: ' Промокоды',
    do_select_tt: 'Выбрать торговые точки',
    promo_code: 'Промокод',
    company_id: 'Компания',
    store_id: 'ID ТТ',
    promo_code_amount: 'Неперечисленный остаток',
    company_inn: 'ИНН Компании',
    store_external_id: 'Внешний индификатор',
    customer_phone: 'Клиент',
    order_total_quantity: 'Кол-во товаров',
    order_total_amount: 'Сумма',
    delivery_price: 'Расчетная цена доставки',
    delivery_price_to_pay: 'Цена доставки к оплате',
    delivery_service_type: 'Тип доставки',
    delivery_service_name: 'Выбранная доставка',
    order_ready_status: 'Статус готовности',
    completed: 'Завершена',
    payout_id: 'ID транзакции',
    payout_date: 'Дата транзакции',
    payment_id: 'ID платёжного поручения',
    amount_without_commission: 'Исчисленная комиссия',
    requiseites_and_virtual: 'Реквизиты и терминалы',
    acts: 'Акты',
    month: 'Месяц',
    half_year: 'Полгода',
    ActForTheReportingPeriod1: 'Акт за отчетный период',
    ActForTheReportingPeriod2: 'Акт взаиморасчетов за отчетный период',
    OrdersReport1: 'Отчет о продажах',
    Invoice: 'Счет на оплату',
    UpdStatus1: 'Универсальный передаточный документ',
    doc: 'Документ',
    v_id_exchange: 'Внешний идентификатор в системе товароучета',
    is_closed_area: 'Ограниченный доступ',
    coffee_machine_status: 'Статус кофемашины',
    coffee_machine_controls: 'Управление кофемашиной',
    unblock_menu_access: 'Разблокировать доступ в меню',
    coffee_menu_unblock_text: 'Клиенты смогут использовать меню на кофемашине. ',
    user_menu: 'Пользовательсткое меню',
    do_select_company: 'Выбор компании',
    doest_active: 'Неактивная',
    blocked: 'Заблокированная',
    suf_active: 'Активная',
    discount_percent: 'Процент скидки',
    discount_sum: 'Сумма скидки',
    round_mode: 'Режим округления',
    aditional_code: 'Дополнительный код',
    aditional_code_help: 'Используется, если для товара необходимо сканирование дополнительного кода (Data Matrix, ЕГАИС)',
    none_group: 'Без группировки',
    store_box_notification: 'Не получать уведомления о проблемах с',
    store_box_notification_connection: 'Соединением',
    store_box_notification_temperature: 'Температурой',
    briskly_requisites: 'Реквизиты Briskly',
    download_horizontal_sticker: 'Скачать горизонтальную инструкцию',
};
