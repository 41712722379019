/* eslint-disable */
import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import { companyAPI } from '@/core/api/company';
import { parseDate, parseDateFoBe } from '@/apps/items/scripts/dateParse';
import { formatShelfLife } from '@/apps/items/scripts/formatShelfLife';
import { EAN13, EAN8 } from '@/apps/items/views/Item/barcodes';
import { format } from 'date-fns';

const state = {
  stickers: [],
  stickersQuantity: '1',
  stickerSet: {},
  currentStickerSetItems: [],

  currentPage: 1,
  showOtemsPerPage: 200,

  stickersMeta: {},
  sorted: {
    barcode: 'ASC',
  },
};

const getters = {
  stickers: (state) => state.stickers,
  stickerSetId: (state) => state.stickerSet.id,
  currentStickerSetItems: (state) => state.currentStickerSetItems,

  currentPage: (state) => state.currentPage,
  sorted: (state) => state.sorted,
  goodsMeta: (state) => state.goodsMeta,
};

const actions = {
  setSorted: async ({ commit }, payload) => {
    commit('_setSorted', payload);
  },

  setGoodsMeta: async ({ state, commit }, meta) => {
    const newMeta = { ...meta, limit: state.showOtemsPerPage };
    commit('_setStickersMeta', newMeta);
  },

  getStickersSet: async ({ commit, dispatch }, opts) => {
    // TODO: Описать  meta
    const errorOptions = { passive: true };
    try {
      const result = await companyAPI.getStickerSet({ errorOptions });

      if (result.items.length === 0) {
        await dispatch('createOrUpdateStickerSet', { payload: '', method: 'create' });
      } else {
        commit('_setStickerSet', result);
      }
    } catch (error) {
      console.error(error);
    }
  },

  createOrUpdateStickerSet: async ({ commit, dispatch }, { payload, method }) => {
    const { manufacturer_name, manufacturing_address } = payload;
    const errorOptions = { passive: true };

    try {
      if (method === 'create') {
        const { id } = await companyAPI.createOrUpdateStickerSet({ errorOptions });
        id ? dispatch('getStickersSet') : (id = 0);
      }
      const response = await companyAPI.createOrUpdateStickerSet({
        manufacturer_name,
        manufacturing_address,
        errorOptions,
      });
    } catch (error) {
      console.error(error);
    }
  },

  // getStickers: async ({ commit, dispatch, getters, state }) => {
  //   try {
  //     await dispatch('getStickersSet');

  //     const stickerSetId = getters.stickerSetId;
  //     const result = await companyAPI.getGeneratedStickers({ id: stickerSetId });

  //     if (state.currentStickerSetItems.length === 0) await dispatch('getStickerCurrentSet');

  //     commit('_setStickers', result);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },

  setStickersQuantity: async ({ commit, getters }, { value, id }) => {
    if (!id) {
      commit('_setAllStickersQuantity', value);
      return;
    } else {
      let payload = getters.currentStickerSetItems.find((sticker) => sticker.item_barcode === id);
      if (payload) {
        payload = { ...payload, quantity: value };
        // try {
        //   console.log
        //   await companyAPI.updateItemInStickerSet(payload);
        // } catch (error) {
        //   console.error(error);
        // }
      }
      commit('_setOneStickerQuantity', { value, id });
      return;
    }
  },

  setStickersShelfDate: async ({ commit, getters }, { value, mode, id }) => {
    if (!id) {
      commit('_setAllStickersShelfDate', { value, mode });
    } else {
      let payload = getters.stickers.find((sticker) => sticker.id === id);
      if (payload) {
        commit('_setOneStickerShelfDate', { value, mode, id });
        const newPayload = {
          ...payload,
          manufacturing_shelf_life: {
            value,
            mode,
          },
        };
        commit('_updateCurrentStickerSet', newPayload);
      }
    }
  },

  setUnionStickerManufacturingDate: async ({ commit }, { value, id }) => {
    const { date, hour, minutes } = value;
    await commit('_setStickerMFDate', { value: date, id });
    await commit('_setStickerMFHour', { value: hour, id });
    await commit('_setStickerMFMinutes', { value: minutes, id });
  },

  setStickerManufacturingDate: ({ commit }, { value, field, id }) => {
    switch (field) {
      case 'date':
        return commit('_setStickerMFDate', { value, id });
      case 'hour':
        return commit('_setStickerMFHour', { value, id });
      case 'minutes':
        return commit('_setStickerMFMinutes', { value, id });
      default:
        return;
    }
  },

  setStickersNeedToSave: ({ commit }, id) => {
    if (!id) {
      commit('__setAllStickersNeedToSave');
      return;
    } else {
      commit('__setStickerNeedToSave', id);
      return;
    }
  },

  async getStickerCurrentSet({ commit, getters }) {
    try {
      const errorOptions = { passive: true };
      const result = await companyAPI.getStickerSetItemsList({
        sticker_set_id: getters.stickerSetId,
        order: getters.sorted,
        errorOptions,
      });
      commit('_setCurrentStickerSet', result);
      commit('_setStickers', result.items);

      // TODO: META
    } catch (error) {
      console.error(error);
    }
  },

  setEditMode: async ({ commit }, id) => {
    commit('_setEditMode', id);
  },

  updateOneSticker: async ({ commit, getters }, id) => {
    const curSticker = getters.stickers.find((stk) => stk.id === id);
    if (!curSticker) return;
    const manufacturing_date = parseDateFoBe(
      curSticker.manufacturing_date.date,
      `${curSticker.manufacturing_date.hour}:${curSticker.manufacturing_date.minutes}:00`,
    );
    const payload = {
      ...curSticker,
      manufacturing_date,
      manufacturing_shelf_life:
        curSticker.manufacturing_shelf_life.value * curSticker.manufacturing_shelf_life.mode,
    };
    try {
      const resp = await companyAPI.updateItemInStickerSet(payload);
    } catch (error) {}
  },
};

const mutations = {
  _setCurrentPage: (state, page) => {
    state.currentPage = page;
  },

  _setStickerSet: (state, { items }) => {
    // TODO: Временно, пока не готов бек верем 1 элемент
    state.stickerSet = items[0];
  },

  _setStickersMeta: (state, meta) => {
    state.stickersMeta = meta;
  },

  _setEditMode: (state, id) => {
    const idx = state.stickers.findIndex((el) => el.id === id);

    const sticker = { ...state.stickers[idx] };
    sticker.editMode = !sticker.editMode;
    state.stickers = produce(cloneDeep(state.stickers), (draft) => {
      draft.splice(idx, 1, sticker);
    });
  },
  _setSorted: (state, payload) => {
    state.sorted = { ...payload };
  },

  _setStickers: (state, stickers) => {
    const getBarcodeFormat = (barcode) => {
      if (/^[a-zA-Z]*$/.exec(barcode)) {
        return 'invalid';
      }
      switch (barcode.length) {
        case 6:
          return 'UPC';
        case 7:
          return 'UPC';
        case 8:
          return 'EAN8';
        case 9:
          return 'UPC';
        case 10:
          return 'UPC';
        case 11:
          return 'UPC';
        case 12:
          return 'UPC';

        case 13:
          return 'EAN13';
        default:
          return 'invalid';
      }
    };

    state.stickers = produce(cloneDeep(stickers), (draft) => {
      const validateBarcodeControlSum = (value) => {
        let codeInstanse;
        if (typeof value !== 'string') return '';
        switch (value.length) {
          case 8:
            codeInstanse = new EAN8(value, false);
            break;
          case 13:
            codeInstanse = new EAN13(value, false);
            break;
        }
        if (codeInstanse && !codeInstanse.valid()) {
          return true;
        } else {
          return false;
        }
      };

      draft.map((sticker) => {
        if (!sticker) return;
        sticker.props = sticker.props
          ? sticker.props
          : { calories: '', carbohydrates: '', composition: '', fats: '', proteins: '' };
        sticker.editMode = false;
        sticker.barCodeFormat = getBarcodeFormat(sticker.item_barcode);

        // const mode = sticker.manufacturing_shelf_life;
        const processed = formatShelfLife(Number(sticker.manufacturing_shelf_life)).split(' ');

        sticker.manufacturing_shelf_life = {
          value: Number(processed[0]),
          mode: Number(processed[1]),
        };
        const newday = new Date();
        newday.setDate(newday.getDate() + 1);

        const manufacturingDate = parseDate(newday);
        sticker.manufacturing_date = {
          date: manufacturingDate.split(' ')[0],
          hour: '7',
          minutes: '00',
        };
        sticker.inValid = validateBarcodeControlSum(sticker.item_barcode);

        sticker.needToSave = !sticker.inValid;
        // if (sticker.item_barcode.length < 6) {
        //   sticker.inValid = true;
        //   sticker.needToSave = false;
        // } else if (sticker.item_barcode.length > 13) {
        //   sticker.inValid = true;
        //   sticker.needToSave = false;
        // } else if (/^[a-zA-Z]*$/.exec(sticker.item_barcode)) {
        //   sticker.inValid = true;
        //   sticker.needToSave = false;
        // }
      });
    });
  },

  _setAllStickersQuantity: (state, value) => {
    state.stickers = produce(cloneDeep(state.stickers), (draft) => {
      draft.map((i) => {
        i.quantity = value;
      });
    });
    state.stickersQuantity = value;
  },

  _setOneStickerQuantity: (state, { value, id }) => {
    state.stickers = produce(cloneDeep(state.stickers), (draft) => {
      draft.map((i) => {
        if (i.id === id) i.quantity = value;
        return i;
      });
    });
  },

  __setAllStickersNeedToSave: (state) => {
    state.stickers = produce(cloneDeep(state.stickers), (draft) => {
      draft.map((i) => {
        if (i.inValid) return i;
        i.needToSave = !i.needToSave;
        return i;
      });
    });
  },

  __setStickerNeedToSave: (state, id) => {
    state.stickers = produce(cloneDeep(state.stickers), (draft) => {
      draft.map((i) => {
        if (i.id === id) {
          i.needToSave = !i.needToSave;
          return i;
        }
        return i;
      });
    });
  },

  _setAllStickersShelfDate: (state, { value, mode }) => {
    state.stickers = produce(cloneDeep(state.stickers), (draft) => {
      draft.map((sticker) => {
        sticker.manufacturing_shelf_life.value = value;
        sticker.manufacturing_shelf_life.mode = mode;
      });
    });
  },

  _setOneStickerShelfDate: (state, { value, mode, id }) => {
    // debugger;
    state.stickers = state.stickers.map((sticker) => {
      if (sticker.id === id) {
        const processedSticker = {
          ...sticker,
          manufacturing_shelf_life: {
            value,
            mode,
          },
        };
        return processedSticker;
      }
      return sticker;
    });
  },

  _setCurrentStickerSet: (state, { items, meta }) => {
    state.currentStickerSetItems = items;
  },

  _updateCurrentStickerSet: (state, item) => {
    const idx = state.currentStickerSetItems.findIndex(
      (sticker) => sticker.item_id === item.item_id,
    );
    state.currentStickerSetItems.splice(idx, 1, item);
  },

  _setStickerMFDate: (state, { value, id }) => {
    state.stickers = produce(cloneDeep(state.stickers), (draft) => {
      draft.map((sticker) => {
        if (sticker.id === id) {
          sticker.manufacturing_date.date = value;
          return sticker;
        }
        return;
      });
    });
  },
  _setStickerMFHour: (state, { value, id }) => {
    state.stickers = produce(cloneDeep(state.stickers), (draft) => {
      draft.map((sticker) => {
        if (sticker.id === id) {
          sticker.manufacturing_date.hour = value.toString();
          return sticker;
        }
        return;
      });
    });
  },
  _setStickerMFMinutes: (state, { value, id }) => {
    state.stickers = produce(cloneDeep(state.stickers), (draft) => {
      draft.map((sticker) => {
        if (sticker.id === id) {
          sticker.manufacturing_date.minutes = value.toString();
          return sticker;
        }
        return;
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
