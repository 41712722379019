import { api, bus } from 'shared/core';

class SupportAPI {
  constructor() {
    Object.getOwnPropertyNames(this).forEach((fnName) => {
      const fn = this[fnName];

      this[fnName] = async (...props) => {
        const { errorOptions = {} } =
          props.find((p) => typeof p === 'object' && p && !(p instanceof Array)) || {};

        return fn(...props)
          .then((r) => r)
          .catch((error) => {
            bus.emit('core$notifications/add', { error, ...errorOptions });
            return Promise.reject(error);
          });
      };
    });
  }

  sendMessage = (opts = {}) => {
    const { text, chat_id } = opts;
    return api.endpoints.business.support.post('chat/message/send', { text, chat_id });
  };

  getMessages = (opts = {}) => {
    const { page, limit, chat_id } = opts;
    return api.endpoints.business.support.get('chat/message/get-list', { page, limit, chat_id });
  };

  setAsRead = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.support.post('chat/message/set-as-read', { id });
  };

  downloadFile = (opts = {}) => {
    const { id } = opts;

    const { access_token } = api.endpoint.getTokens();

    return api.endpoints.business.support.get(
      'chat/message/file/download',
      { id, token: access_token },
      { linkOnly: true },
    );
  };
}

export const supportAPI = new SupportAPI();
