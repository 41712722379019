const DELIVERY_TARIFFS = {
  OWN: 'own',
  BRISKLY: 'briskly',
  BOTH: 'both',
};

const CALC_TYPES = {
  UNKNOWN: 0,
  DISCOUNT_PERCENT: 1,
  DISCOUNT_FIXED: 2,
  FIXED: 3,
  FREE: 4,
};

const DELIVERY_COSTS = [
  {
    title: 'delivery_cost_free',
    value: CALC_TYPES.FREE,
  },
  {
    title: 'delivery_cost_discount_percent',
    value: CALC_TYPES.DISCOUNT_PERCENT,
  },
  {
    title: 'delivery_cost_discount_fixed',
    value: CALC_TYPES.DISCOUNT_FIXED,
  },
  {
    title: 'delivery_cost_fixed',
    value: CALC_TYPES.FIXED,
  },
  {
    title: 'delivery_cost_unknown',
    value: CALC_TYPES.UNKNOWN,
  },
];

export { 
  DELIVERY_TARIFFS, 
  DELIVERY_COSTS,
  CALC_TYPES,
};
