import { companyAPI } from '@/core/api/company';
import { formatDate } from '@/core/utils/date';
import { notificationService } from '@/core/services/NotificationService';
import { PARTY_TYPES } from '@/core/data/partyTypes';

const initialState = () => ({
  selectedRequisites: null,
  unsavedChangesInCreate: false,
});

const state = initialState();

const getters = {
  selectedRequisites: (state) => state.selectedRequisites,
  canSkipStage: () => false,
  hasUnsavedChanges: (state) => !!state.selectedRequisites || state.unsavedChangesInCreate,
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  setUnsavedChangesInCreate({ commit }, value) {
    commit('_setUnsavedChangesInCreate', value);
  },

  completeStage: async ({ state, dispatch, rootGetters }) => {
    const store = rootGetters['trade-point$create/store'];

    try {
      const party_id = state.selectedRequisites.id;
      await dispatch('bindPartyToStore', { party_id });

      if (store) {
        await dispatch('trade-point$create/setStore', { ...store, party_id }, { root: true });
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  setSelectedRequisites: async ({ commit }, value) => {
    commit('_setSelectedRequisites', value);
  },

  createRequisites: async ({ dispatch, commit, rootGetters }, requisites) => {
    let { party, account, files, doc, type } = requisites;
    const isFull = type === PARTY_TYPES.FULL;

    const [internationalParty] = rootGetters['core$main/internationalPartyList'];
    if (internationalParty) {
      party = { ...internationalParty, ...party };
    }

    const createdParty = await dispatch('createOrUpdateParty', { party, type });

    const { id: party_id } = createdParty;

    if (isFull) {
      await dispatch('createPartyAccount', { account, party_id });
      await dispatch('createDoc', { files, doc, party_id });
    }

    await dispatch('sendDoc', { party_id });
    notificationService.sendSuccessSave();
    commit('_setSelectedRequisites', createdParty);
  },

  createOrUpdateParty: ({ dispatch }, { party, type }) => {
    const isFull = type === PARTY_TYPES.FULL;
    const create = isFull ? companyAPI.createParty : companyAPI.createPartialParty;
    const update = isFull ? companyAPI.updateParty : companyAPI.updatePartialParty;

    if (!party.id) {
      return create(party);
    }

    return update(party).then((updatedParty) => {
      dispatch('core$main/updatePartyInPartyList', updatedParty, { root: true });
      return updatedParty;
    });
  },

  createPartyAccount: (_, { account, party_id }) => {
    const payload = { ...account, party_id };

    return companyAPI.createAccount(payload);
  },

  bindPartyToStore: ({ rootGetters }, opts) => {
    const { party_id, store_id } = opts;
    const id = store_id || rootGetters['trade-point$create/store'].id;

    return companyAPI.bindPartyToStore({ id, party_id });
  },

  createDoc: async (_, { doc, files, party_id }) => {
    const { first, second } = files;
    const { issue_date, birth_date } = doc;

    const uploadedFiles = await Promise.all(
      [first, second].map((file, idx) => {
        if (!file) return Promise.resolve(null);

        return companyAPI.uploadFile({
          party_id,
          type: `physical-person-passport-page-${idx + 1}`,
          file: file.preview,
        });
      }),
    );

    return companyAPI.createDoc({
      ...doc,
      issue_date: formatDate(issue_date),
      birth_date: formatDate(birth_date),
      party_id,
      files_ids: uploadedFiles.filter((f) => !!f).map(({ id }) => id),
    });
  },

  sendDoc: (_, { party_id }) => {
    return companyAPI.sendDoc({ id: party_id });
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setSelectedRequisites: (state, value) => {
    state.selectedRequisites = value;
  },

  _setUnsavedChangesInCreate: (state, value) => {
    state.unsavedChangesInCreate = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
