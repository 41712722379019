export default {
  valid_upload_both_photos: 'Lütfen her iki resmi de yükleyin',
  mailing_status_rejected: 'Reddedildi',
  items: 'Eşya',
  catalogs_and_categories: 'Kataloglar ve kategoriler',

  filter_gender: 'Cinsiyeti',
  filter_age: 'Yaş',
  filter_registered_days_ago: 'Kayıt Tarihi',
  filter_purchases_quantity: 'Satın alma sayısı',
  filter_purchase_days_ago: 'Satın alma yaşı',
  filter_birth_date_month: 'Doğum günü',

  gender_male: 'Erkek',
  gender_female: 'Kadın',

  before_num: '{num} yıla kadar',
  older_then: '{num} yıl sonra',

  before_days: '{_} gün | {_} güne kadar | {_} güne kadar',
  year_ago: 'Bir yıl önce',

  more_then: 'Daha {num}',

  in_this_month: 'Bu ay içinde',
  in_next_month: 'Önümüzdeki ay',

  select_employee_for_test_message: 'Test mesajı göndermek için bir çalışan seçin',
  filter_will_be_available_soon: 'Bu işlevsellik yakında kullanılabilecektir',

  documents_subtitle: `Bu bölümde, platformun çalışma kurallarına ve diğer yasal belgelere aşina olmanız gerekir. <br /> <br /> Ayrıca Briskly ile aranızdaki doğru etkileşim için gerekli belgelerin mevcut durumunu görüntüler. <br/> Dahil olmak üzere, SMS doğrulama işlevini kullanarak bir dizi belgeyi uzaktan imzalamak mümkündür.`,

  reports_subtitle: `"Raporlar" bölümü, b: pay teknoloji platformunda işletmenizin görsel analitik raporlarını oluşturmanıza olanak tanır. <br /> İlgilendiğiniz parametreler ve örnekler hakkında net, güvenilir bilgi edinin ve bu verilere dayanarak iş süreçlerinizi iyileştirin, perakende satış mağazalarının verimliliğini artırın ve gelirinizi artırın!`,

  videos_subtitle: `Bu bölümde perakende satış noktalarınızda kurulu kameralardan video kayıtları alabilirsiniz.`,

  support_service_greeting: 'Merhaba! Size yardımcı olmaktan mutluluk duyarız!',
  homeTitle: 'Ana ekran',
  homeSubtitle: 'Bu ekranda yakında istatistikler satış satış noktaları',
  homeSubtitleWithStat: 'Bu ekranda görüntülenir istatistik satış satış noktaları',
  homeReferalProgramNote:
    'Kullanıyor, kendisi için bir arkadaşınızı davet edin. Bir kez o kayıt sisteminde, bir↵ ziyaret кэшбэк yer Briskly tutarında ve % ',
  referalProgram: 'Sevk programı',
  trade_points: 'Satış noktaları',
  on_map: 'Harita',
  do_add_trade_point: 'Eklemek ticaret noktası',
  bind: 'Çekin',
  doesnt_select: 'Seçili değil',
  select_dates: 'Tarihleri seçin',
  trade_point_list: 'Liste satış noktaları',
  extended_types_washer_fluid: 'Yıkama sıvısı',
  number: 'Numarası',
  cost: 'Maliyeti',
  id: 'İD',
  do_open: 'Aç',
  do_close: 'Kapat',
  do_search: 'Ara',
  results: 'Sonuçlar',
  by_list: 'Liste',
  name: 'Adı',
  continue: 'Devam',
  revenue: 'Gelir',
  averageCheck: 'Ortalama Bir Çek',
  save: 'Kaydet',
  skip: 'Özledim',
  metr: 'km',
  km: 'km',
  can_come_back_later: 'Eğer geri bu adım daha sonra',
  million_short: 'milyon',
  do_select: 'Seçmek',
  thousand_short: 't',
  do_change: 'Değiştirmek',
  first_name: 'Adı',
  required_fields: 'Gerekli sütunları',
  do_repeat: 'Tekrar',
  do_check: 'Kontrol',
  last_name: 'Soyadı',
  middle_name: 'İlk, orta',
  trade_point: 'Ticaret noktası',
  trade_point_count: 'Noktalarının sayısı',
  role: 'Rol',
  next_step: 'Bir sonraki adım',
  yes: 'Evet',
  no: 'Yok',
  do_skip_and_finish: 'Atla ve bitir',
  do_end_registration: 'Kayıt işlemini tamamlamak için',
  do_skip_and_end_registration: 'Atla ve kayıt işlemini tamamlamak için',
  from: 'Yer',
  before: 'Önce',
  do_cancel: 'Iptal',
  format: 'Format',
  do_download_file: 'Dosya upload',
  total: 'Toplam',
  pick_or_input: 'Seçeneğini belirleyin ya da yazmaya devam edin',
  search: 'Arama',
  step: 'Adım',
  all: 'Tüm',
  price: 'Fiyat',
  here: 'Burada',
  go_to: 'Git',
  price_with_currency2: 'Fiyat, ₽*',
  do_create: 'Oluşturun',
  active_status_yes: 'Aktif',
  active_status_no: 'İnaktif',
  pack_min: 'ambalaj',
  go_to_page: 'Sayfaya git',
  do_send: 'Gönder',
  yesterday: 'Dün',
  today: 'Bugün',
  you: 'Sen',
  main_page: 'Ana sayfa',
  logout: 'Çıkmak',
  shortcut_week: '7 gün',
  shortcut_month: '31 gün',
  among: 'Biri',
  time_zone: 'Zaman dilimi',
  pick_time_zone: 'Zaman dilimini seçin',
  hazır: 'Hazır',
  extended_types_store: 'Dükkanı',
  extended_types_refrigerator: 'Buzdolabı',
  extended_types_store_online: 'Online Mağaza',
  extended_types_cafe: 'Yemek',
  extended_types_production_with_delivery: 'Üretim ile teslim',
  extended_types_filling_station: 'BENZİN istasyonu',
  extended_types_cigarettes: 'Sigara dumanı dolap',
  extended_types_coffee_machine: 'Kahve makinesi',
  stage_type_title: 'Ekleyin bir ticaret noktası',
  stage_type_edit_title: 'Temel bilgiler',
  stage_type_subtitle: 'Türü seçin, bir ticaret noktası',
  stage_type_description:
    'Seçin kategori: mağaza, cafe veya başka bir listeden. Hakkında daha fazla bilgi için, her bir kategori burada',
  stage_type_label_phone: 'Telefon ticaret noktası',
  address: 'Adres',
  stage_type_label_floor: 'Kat',
  stage_type_store_radius: 'Menzili↵sistemi Scan&amp;Go',
  stage_type_refrigerator_radius: 'Yarıçapı ile istediğiniz↵açılır kapı',
  stage_type_cigarettes_radius: 'Yarıçapı ile istediğiniz↵açılır kapı',
  stage_type_filling_station_radius: 'Menzili↵sistemi Briskly',
  stage_type_coffee_machine_radius: 'Menzili↵makinanın',
  stage_design_description:
    'Seçin nasıl görüneceğini da ticaret noktası uygulaması B-Pay. Ya da bir logo yükleyin. Görüntülenir, öğe kartı',
  stage_design_label_select: 'Bir tasarım seçin',
  stage_design_create_new: 'Yeni bir tasarım oluşturmak',
  agreement: 'Anlaşma',
  promo_briskly: 'Promosyon malzemeleri Briskly',
  fuel: 'Yakıt',
  date_input_placeholder: 'gg.mm.yyyy',
  finish_registration: 'Kayıt işlemini tamamlayın',
  purchases_quantity: 'Bir miktar alışveriş',
  profit: 'Gelir',
  do_open_new_trade_point: 'Aç yeni bir nokta',
  support: 'Destek',
  profile: 'Kişisel',
  error_modal_title: 'Etkinleştirmek imkansız bir ticaret noktası',
  design_title: 'Tasarım',
  card_is_missing: 'Bağlı değil',
  your_logo: 'Logo',
  your_logo_in_format: 'Senin логитип formatında',
  preview_for_app: 'Önizleme için in-app mağaza',
  enter_text: 'Yazın',
  pick_background: 'Bir arka plan seçin',
  little_background: 'Arka plan için küçük önizleme formatında',
  big_background: 'Arka plan için büyük bir önizleme formatında',
  do_change_preview: 'Değiştirmek önizleme',
  mock_address: 'Moskova, ulitsa Akademika Yangel, d. 26',
  store_photos: 'Fotoğraf ticaret noktası',
  schedule: 'Program',
  schedule_title: 'Program',
  work_schedule: 'Çalışma saatleri',
  schedule__description:
    'Yükleyin programı iş buzdolabı: ne zaman açılır için alışveriş, ve ne zaman bloke olur.',
  do_download_from_template: 'İndirmek için şablonlar',
  start_day_after_visiting_courier: 'Başlangıç çalışma günü ziyaret ettikten sonra kurye',
  save_as_template: 'Şablon olarak kaydet',
  do_enter_name: 'Bir başlık girin',
  week_mon: 'Pzt',
  week_tue: 'W',
  week_wed: 'Çar',
  week_thu: 'Prş',
  week_fri: 'Cum',
  week_sat: 'Sat',
  week_sun: 'Güneş',
  week_all_day: 'Tüm gün',
  permanently: 'Saat',
  weekend: 'Çıkış',
  do_add_break: 'Ekle bir ara',
  schedule_from: 'ile',
  schedule_to: 've',
  templates: 'Şablonları',
  create_employees_title: 'Ekleme çalışanları',
  employees_description:
    'Bu ekranda eklediğiniz çalışanların kendi ticaret noktası, seçin, listeden bir veya getirin içeri veriler yeni. Atayın onlara rol соответсвии sorumlulukları ile. Varsayılan başkanı bağlı tüm rolleri. Daha fazla bilgi için yardım konusuna bakın.',
  pick_employees: 'Seçin çalışanları',
  delete_employee_modal_title: 'Kaldırmak üzeresiniz çalışan',
  employees_selected: 'Seçili',
  employee_change_modal_title: 'Veri değiştirme',
  employee_create_modal_title: 'Ekleme çalışanın',
  employee_change_modal_role_title: 'Atayın rol этойт satış noktası',
  employee_create_modal_role_title: 'Rolleri seçin',
  add_employee: 'Çalışan ekle',
  role_administrator_name: 'Resepsiyon',
  role_administrator_access: 'Her şeyi erişim işlevlerine kişisel dolap',
  role_courier_name: 'Kurye',
  role_courier_access:
    'Kullanılabilir açmak için yeteneği buzdolabı, değiştirmek için bir mal yapmak, fotoğraf, fotoğraf yüklemek arayüzü kurye',
  role_checker_name: 'Damalı içinde',
  role_checker_access:
    'Siparişleri alır alıcılar ve toplar bunları ticaret odasında istemciye göndermek için',
  employees: 'Personel',
  to_add_new_employee: 'Yeni bir çalışan eklemek',
  requisites_title: 'Detayları',
  requisites_description:
    'Ekleyin detayları, ticaret noktası. Onlar göndermek için para çek hesabınıza. Firma bilgileri listelenir verirken, çek müşterilerine.',
  requisites_single_description:
    'Bu bölüm mevcut prizlerinizin tüm ayrıntılarını içerir ve ayrıca yenilerini de ekleyebilirsiniz. Bu, cari hesaba para göndermek için ve müşterilere çek düzenlerken gereklidir. Satışlardan elde edilen gelirler günde bir kez cari hesaba aktarılır.',
  pick_requisites: 'Seçim detayları',
  requisites_company_info: 'Şirket verilerini',
  requisites_client_info: 'Müşteri verileri',
  requisites_fio: 'ADI',
  requisites_phone: 'Telefon numarası',
  requisites_mail: 'E-posta',
  requisites_account_number: 'Hesap numarası',
  requisites_current_account_number: 'Oda çek hesabı',
  requisites_bank_bic: 'BIC banka',
  requisites_bic: 'BİK',
  add_new_requisites: 'Ekle yeni sahne',
  adding_new_requisites: 'Yeni detayları',
  requisites_cheque: 'Banka çeki',
  requisites_non_cash: 'Безналичными',
  requisites_buyer_phone: 'Telefon alıcı',
  requisites_items: 'Mal',
  requisites_item: 'Mal',
  requisites_provider_info: 'Veri sağlayıcı',
  requisites_name: 'İsim',
  requisites_inn: 'TIN veya tüzel kişinin adı',
  requisites_step1: 'İstihdam türü',
  requisites_step2: 'Kişisel bilgiler',
  requisites_step2_legal: 'Kişisel veri yöneticisi',
  requisites_step3: 'Sahne organizasyon',
  requisites_step4: 'Ödeme bilgileri',
  requisites_pick_employment_type: 'Türünü seçin ve istihdam',
  employment_type_individual: 'YİP',
  employment_type_legal: 'Organizasyon',
  requisites_position: 'Yazı olarak (tüzük)',
  passport_first_page: 'Pasaport ilk sayfa',
  passport_second_page: 'Kayıt sayfası',
  passport_series: 'Serisi',
  passport_number: 'Numarası',
  passport_issue_date: 'Verildiği tarih',
  passport_authority_code: 'Departman kodu',
  passport_authority_name: 'İsim birimleri tarafından verilen belge',
  passport_citizenship: 'Milliyet',
  passport_registration_place: 'Adres kayıt (pasaport)',
  passport_birth_place: 'Doğum yeri',
  passport_birth_date: 'Doğum tarihi',
  requisites_capital: 'Kayıtlı sermaye, ₽',
  requisites_tax_system: 'Seçin bir vergi sistemi',
  tax_system_general: 'TEMEL',
  tax_system_simplified_1: 'УСН (gelir-gider)',
  tax_system_simplified_2: 'УСН (gelirleri 6%)',
  requisites_org_phone: 'Telefon kuruluş',
  requisites_company_iname: 'Şirket adı',
  requisites_okopf_code: 'OKOPF',
  requisites_ogrn: 'OGRN',
  requisites_okved_code: 'OKVED',
  requisites_bank_account_info: 'Verileri, banka hesap',
  requisites_cheque_hint: 'Şimdi sen-ebilmek görmek nasıl görüneceğini çek ticaret noktası.',
  requisites_recipient_bank_name: 'Banka alıcı',
  requisites_bank_correspondent_account: 'Corr. hesap',
  requisites_missing: 'Eksik',
  want_to_deliver: 'İsterseniz, teslim, mallarını kullanarak kendi veya bizim teslim?',
  delivery_own_radius: 'Yarıçapı özel teslimat',
  delivery_briskly_radius: 'Yarıçapı teslim ortakları Briskly',
  delivery_own: 'Özel oda',
  delivery_briskly: 'Oda ortaklar Briskly',
  delivery_both: 'Her iki seçenek de',
  delivery_own_tariffs: 'Fiyatlar özel teslimat',
  delivery_briskly_tariffs: 'Fiyatlar nakliye ortakları briskly',
  delivery_briskly_description:
    'Unutmayın, eğer yüklü size nakliye maliyeti, müşteri için daha az olacaktır gerçek maliyeti, ay sonunda gelecek fatura bu fark. Eğer maliyeti daha fazla olacak, size gelecek para miktarı bu fark.',
  delivery_briskly_price: 'Nakliye maliyeti ortakları Briskly',
  delivery_pickup: 'Teslim alma olasılığı',
  delivery_tariffs: 'Fiyatlar nakliye',
  order_sum: 'Sipariş miktarı',
  delivery_cost: 'Nakliye maliyeti',
  delivery_invalid_rates_text:
    'Aralıklarla miktarda sipariş farklı olmalıdır arasında olmalıdır fark en fazla 1 ruble.',
  do_add_rate: 'Koşul ekle',
  delivery_warning_modal_title: 'Değiştirmek için gidiyoruz yolu nakliye',
  delivery_warning_modal_body: 'Kaydettikten sonra, bir önceki dağıtım yöntemi silinecektir',
  delivery_cost_free: 'Ücretsiz',
  delivery_cost_discount_percent: 'İndirim fiyat, teslim, yüzde',
  delivery_cost_discount_fixed: 'İndirimli fiyat teslim ruble',
  delivery_cost_fixed: 'Sabit fiyat',
  delivery_cost_unknown: 'Nakliye müşteriye aittir',
  offer: 'Teklif',
  do_download_offer: 'Download opsiyonu',
  do_sign_document: 'Bir belgeyi imzalamak için',
  code_from_sms: 'Kod sms',
  try_again_in: 'Eğer kod gelmedi sonra tekrar deneyin',
  in_seconds: 'saniye',
  do_send_code_again: 'Göndermek için kodu yeniden',
  do_send_confirmation_code: 'Gönder kodu onay',
  planogram: 'Planogram',
  planogram_block_hint: 'Dizin oluşturmanız gerekir',
  will_be_deleted: 'Silinir',
  planogram_photo: 'Fotoğraf planogram',
  do_add_items_on_shelf: 'Ekleyin malların raf',
  your_micromarket: 'Senin микромаркет',
  shelf: 'raf',
  shelf_is_overflow: 'Bu raf devamı olarak gösterildi',
  do_add_items_on_another_shelfs: 'ekleyin mallar diğer raflar',
  do_add_shelf: 'ekle raf',
  going_to_remove_all_items: 'Eğer собираетсь kaldırmak nleri ile raflar',
  going_to_remove_shelf: 'Eğer собираетсь kaldır rafa',
  planogram_item: 'mal',
  planogram_list: 'Liste planogram',
  do_download_new_planogram: 'Yükle, yeni bir planogram',
  group_by: 'Grup hakkında',
  period: 'Bir süre',
  order_status: 'Sipariş durumu',
  pick_store: 'Bir mağaza seçin',
  option_selection_by_number: 'Örnekleme numarası',
  paid: 'Ödendi',
  processed: 'İşlenmiş',
  refund: 'Iade',
  refund_awaiting: 'Bekleyen iade',
  refund_error: 'Hata iade',
  removed: 'Silindi',
  quantity: 'Sayısı',
  year: 'Yıl',
  id_local: 'BAYRAM',
  order_number: 'Sipariş numarası',
  client: 'Müşteri',
  item_quantity: 'Ürün sayısı',
  items_sufix: 'mal',
  commission: 'Komisyon',
  ıncome: 'Kar',
  item_price: 'Fiyat, bir ürün için',
  period_start: 'Dönem başlangıcı',
  period_end: 'Dönem sonu',
  sum_with_discount: 'Miktar indirimli',
  sum: 'Sınıflar',
  orders: 'Emir',
  clients: 'Müşteriler',
  unique_clients: 'Benzersiz gündüz müşteriler',
  sales: 'Satış',
  completed_order: 'Tamamlanan sipariş',
  refund_awaiting_from_client: 'Bekleniyor harcı ile müşteri',
  service_one_s: '1C',
  service_iiko: 'Aiko',
  service_iiko_biz: 'Aiko İş',
  service_moy_sklad: 'Benim Depo',
  service_supermag: 'Супермаг',
  service_yml: 'YML',
  service_xlsx: 'XLSX',
  unit_id: 'OKEI (Ed. Ölçüm)',
  something_wrong_try_again: 'Bir şey yanlış gitti. Bir kez daha deneyin.',
  use_suggestions_begin: 'Yararlanın',
  use_suggestions_action: 'ipuçları',
  use_suggestions_end: 'entegre sistem',
  payment_description:
    'Eklemek için bir istek gönderin ödeme yöntemi sorumlu kişi mağazası nerede yüklü sigara dumanı dolap.',
  send_request: 'İsteği gönderebilirsiniz',
  do_change_payment_method: 'Değiştirme, ödeme yöntemi',
  current_card: 'Güncel harita',
  follow_us_begin: 'Abone olun bize',
  follow_us_end: 'sosyal ağlarda',
  bpay_title: 'Uygulamayı indirmek b-pay',
  bpay_subtitle: 'Satın kuyruk olmadan',
  checker_title: 'Uygulamasını kullanın damalı içinde',
  checker_subtitle: 'Toplayın müşterilerinizin siparişlerini uzaktan',
  valid_required: 'Mutlaka',
  valid_article_required: 'Madde no gerekli',
  valid_min_3: 'En az 3 karakter',
  valid_min_1: 'En az 1 karakter',
  valid_min_6: 'En az 6 karakter',
  valid_max_60: 'En fazla 60 karakter',
  valid_max_5: 'En fazla 5 karakter',
  valid_max_6: 'Maksimum 6 karakter',
  valid_max_13: 'En fazla 13 karakter',
  valid_max_20: 'En fazla 20 karakter',
  valid_max_32: 'En fazla 32 karakter',
  valid_max_350: 'Maksimum 350 karakter',
  valid_special_symbols_not_allow: 'Özel desen sahip izin verilmez',
  valid_mail_format: 'E-posta biçimi: welcome@brskl.io',
  valid_phone_format: 'Telefona format +7 (123) 456 78 90',
  valid_no_less_1_percent: 'Daha az 1 %',
  valid_no_more_99_percent: 'Daha fazla 99 %',
  module_tittle: 'Modülü',
  briskly_module: 'Briskly Modülü',
  module_description:
    'Briskly modülü bu beyin buzdolabı. Satın alırken size verilen kodu, bağlamak istediğiniz hesabı. Kodu görebilirsiniz',
  module_to_buy:
    'Eğer henüz ödeme Buzdolabı (buzdolabı + Briskly Modülü) veya ayrı olarak Briskly Modülü için buzdolabı,',
  module_link_to_buy: 'şimdi bunu',
  indicate_divice_code: 'Belirtin kodu ile cihaz',
  module_data: 'Verileri Briskly modülü',
  network_status: 'Ağ durumu',
  working: 'Çalışır',
  not_working: 'Çalışmıyor',
  lock_status: 'Durum kilit',
  lock_close: 'Kapalı',
  lock_open: 'Açıldı',
  air_temp: 'Hava sıcaklığı',
  last_time_in_network: 'Son kez ağ',
  lock_control: 'Kontrol kilidi',
  module_error_title: 'Verileri kullanılamaz. Bu durum olabilir',
  module_check: 'Kontrol modülü',
  buy_module: 'Alış Briskly Modülü',
  to_retry: 'Tekrar deneyin',
  setup_module_in_you_ref: 'Yükleme sistemi, buzdolabı',
  smart_ref_all_options: 'Akıllı buzdolabı «anahtar teslimi»',
  popular_format: 'Popüler formatı',
  big_market_with_all_options: 'Büyük bayi «anahtar teslimi»',
  lock_mount: 'Yükleme al. kale',
  connect_to_b_pay: 'Bağlantısı uygulama B-Pay',
  goods_integration: 'Entegrasyon çeşitleri',
  temperature_sensor: 'Sıcaklık sensörleri',
  spy_camera: 'Güvenlik kamerası',
  refregerator_in_set: 'Buzdolabı dahil',
  trade_point_branding: 'Marka buzdolabı',
  electronic_lock: 'Elektronik kilit',
  refregerator_with_two_doors: 'Buzdolabı iki kapı',
  module_apply: 'Başvuru',
  left_a_little: 'Az kaldı',
  fill_in_the_required_sections_for_your_tt_start_working:
    'Doldurun lütfen, gerekli kayıt için ticaret noktası çalışmaya başladı',
  continue_filling: 'Dolum devam etmek',
  copy_link: 'Linki kopyalayıp',
  lock: 'Kale',
  last_check: 'Son kontrol',
  stages_description_delivery: 'Bir seçenek belirleyin teslim',
  stages_description_design:
    'Şimdi senin ticaret noktası uygulaması B-pay görünüyor standarttır. Öne, tasarım değiştirebilirsiniz',
  stages_description_employees: '',
  stages_description_exchange: 'İndirin ürünlerini katalog, müşterilerin onları görmek mümkün.',
  stages_description_briskly_module:
    'Tüm satış noktaları sistemde görünür Briskly sadece bağlandıktan sonra Briskly Modülü.',
  stages_description_offer:
    'Gerek sözleşme imzalamak tüm konaklamalar için kullanmak Briskly bulunmaktadır',
  stages_description_planogram:
    'Yerleştirin raflarda buzdolabı mal gibi onları göreceksiniz müşterileriniz.',
  stages_description_requisites: 'Durum: denetlenir.↵Onay alır 1-3 iş günü.',
  stages_description_schedule: 'İçin ticaret noktası çalıştı, alanları doldurun tarifeler.',
  stages_description_type: '',
  stages_description_fuel: '',
  stages_description_payment:
    'Müşteri henüz takılı bir kart için ödeme.↵ Bağlantıyı kopyalayın ve gönderin ona, eğer yapmadıysanız.',
  trade_point_registation_complite: 'Check tamamlandı',
  congratulations_your_tt_is_ready_to_go: 'Tebrikler! Ticaret noktası hazır',
  fine: 'Sizin',
  congratulations_your_tt_is_ready_we_need_check:
    'Tebrikler! Tamamlamış kaydı! Şimdi ihtiyacımız 1-3 işgünü içinde kontrol etmek için hesap bilgilerini. Bundan sonra size açık erişim noktasında.',
  download_qr_code: 'İndir QR-kodu ',
  download_sticker: 'İndirme etiket',
  download_table_banner: 'İndir masa banner',
  do_edit_sufix: 'Düzenleme',
  delete_trade_point: 'Kaldırmak için ticaret noktası',
  this_action_cant_canceled: 'Bu yol безвозвратному kaldırma',
  employees_title: 'Personel',
  planogram_title: 'Planogram',
  payment_method: 'Ödeme yöntemi',
  payment_status: 'Ödeme durumu',
  temperature_setup: 'Sıcaklık ayarı',
  temperature_setup_title:
    'Ayarlayın sıcaklık çalışma modu sizin buzdolabı. İçindeki sıcaklık buzdolabı dışına gidecek ve sizin tarafınızdan belirlenen limitleri, size bir bildirim göndeririz.',
  time_of_ordering: 'Sipariş süresi',
  Video: 'Video',
  video_by_order: 'Video üzerine',
  pick_date: 'Bir tarih seçin',
  video_recording_date: 'Kayıt tarihi video',
  video_not_found: 'Video bulunamadı',
  download: 'İndir',
  is_signet: 'İmzalı',
  documents: 'Belgeler',
  is_doesnt_signed: 'İmzalı değil',
  is_touched: 'İzlendi',
  to_assign: 'İmzalamak',
  time_stamp: 'Zaman eklemek',
  type: 'Türü',
  offer_for_partners: 'Teklif için ortaklar',
  tariff_plans: 'Tarife planları',
  personal_account_rules: 'Kullanım kuralları LK',
  reports: 'Raporlar',
  report_about_clients: 'Rapora göre, müşterilerin',
  report_about_items: 'Raporu kaleme',
  report_about_orders: 'İstatistikler siparişleri',
  report_about_sales: 'İstatistikler satış',
  report_about_unique_clients: 'İstatistikler, benzersiz gündüz müşterilerine',
  do_download_data: 'Veri indir',
  do_download_promo_briskly: 'Promosyon materyallerini indirin Briskly',
  download_file_format: 'Format download',
  your_account_has_run_out_of_money: 'Hesabınızda para bitti.',
  do_top_up_an_account: 'Kontör denge',
  air_temperature: 'Hava sıcaklığı',
  ınside: 'İçinde',
  outside: 'Açık',
  status: 'Durumu',
  actual_address: 'Gerçek adresi',
  kart: 'Harita',
  phone: 'Telefon',
  credentials: 'Detayları',
  delivery: 'Oda',
  do_edit: 'Düzenle',
  most_popular_items: 'Popüler ürünler',
  item_name: 'Ürün adı',
  price_with_currency: 'Fiyat, ₽',
  revenue_with_currency: 'Gelir, ₽',
  sold_items_with_amount: 'Satılan adet',
  option_unknown: 'Yüklü değil',
  option_min: 'Minimum',
  option_max: 'En',
  option_discount_percent: 'İndirim yüzdesi',
  option_discount_amount: 'İndirim ruble',
  option_discount_new_price: 'Yeni fiyat',
  option_usage_common: 'Genel indirim',
  option_usage_first_order: 'İlk satın almadan zaman',
  option_usage_first_order_after_timestamp: 'İlk satın alma sonra atanan geçici limiti',
  discount_size: 'Boyut indirimler',
  discount: 'İndirim',
  excluded_cost: 'Alarak maliyeti',
  applying_features: 'Uygulama özellikleri',
  applying: 'Uygulama',
  do_add_discount: 'İndirim ekle',
  editing_of_discount: 'Düzenleme indirimler',
  adding_of_discount: 'Yeni indirim',
  applying_order: 'Uygulama sırası',
  save_changes: 'Değişiklikleri kaydet',
  do_save: 'Kaydet',
  excluded_cost_tooltip:
    'Bir miktar makbuzda hangi geçerli değil indirim. ↵Örnek: %10 indirim satın alma üzerinde 500руб (alarak, maliyeti 500р) ↵satın alma Tutarı 900р %10 indirim için geçerlidir 400р ve sıcaklığı 40r.',
  client_show_settings: 'Ayarları görüntülemek için bir müşteri',
  do_show: 'Göster',
  title: 'Başlık',
  description: 'Açıklama',
  ımage: 'Görüntü',
  add_image_proposal: 'Bir görüntü ekleyin',
  adding_of_discount_group: 'Ekleme seti indirim',
  editing_of_discount_group: 'Düzenleme seti indirim',
  do_add_discount_group: 'Bir dizi ekleyin',
  do_add_discount_group_v2: 'Bir dizi eklemek indirim',
  do_assign_discount_group_to_store: 'Ata indirimli alışveriş noktaları',
  discounts: 'İndirim',
  do_delete: 'Sil',
  not_delete: 'Silme',
  do_reset_changes: 'Değişiklikleri iptal et',
  you_are_trying_to_delete_discount: 'Kaldırmak üzeresiniz indirim',
  you_are_trying_to_delete_discount_group: 'Kaldırmak üzeresiniz seti indirim',
  discount_assign: 'Randevu indirim',
  store_name: 'Adı ticaret noktası',
  discount_group: 'Set indirim',
  title_for_clients: 'Başlık için müşteri',
  is_enabled: 'Etkin',
  is_disabled: 'Kapalı',
  is_enabled_female: 'Etkin',
  is_disabled_female: 'Kapalı',
  show_for_clients: 'Gösteren müşterilerimiz için',
  type_of_discount: 'İndirim türü',
  set: 'Set',
  around_the_clock: 'Saat',
  doesnt_act: 'Geçerli değil',
  good_photo: 'Fotoğraf ürün',
  volume: 'Hacim',
  step_reminder: 'Eğer geri bu adım daha sonra.',
  category: 'Kategori',
  categories: 'Kategori',
  category_sufix: 'Kategoriler',
  create_catalog: 'Bir dizin oluşturun',
  create_catalog_label:
    'Hesabınızda birden fazla olabilir, bir dizin (örneğin, buzdolabı торгующего hazır yemek veya торгующего dondurulmuş ürünler). Seçerek uygun dizine, hızlı geçiş tek bir ürün listesi başka. Burada oluşturmanız gerekir bu dizinin.',
  catalog_name: 'Dizin adı',

  portion: 'porsiyon',
  non_tax: 'Değil tabi',
  payment: 'Çek',
  standard: 'Standart',
  tamamen: 'Sadece',
  units: 'Bir ölçü birimi',
  age_limit: 'Yaş sınırı,yaş',
  composition_of_item: 'Kompozisyon',
  calories: 'Kalori',
  optional_description: 'Açıklama (isteğe bağlı)',
  vat_mode: 'Modu KDV',
  vat_rate: 'KDV oranı, %',
  block_with_modifications: 'Blok mods',
  item_add_action: 'Ürün ekleme',
  item_edit_action: 'Düzenleme ürün',
  dimension: 'Boyut',
  item_add_action2: 'Mal ekleyin',
  add_and_edit_catalog: 'Ekleme/düzenleme dizini',
  need_select_catalog: 'Gerek bir dizin seçin',
  do_add_more_categories: 'Eklemek için başka bir kategori',
  goods_name: 'Ürün adı',
  expiration_date: 'Raf ömrü',
  barcode: 'Ürün barkodu',
  sticker_quantity: 'Adet etiket',
  manufacturing_by: 'Değerlendirmeler',
  h: 's',
  d: 'g',
  m: 'ay',
  y: 'y',
  kompozisyon: 'Kompozisyon',
  do_not_specifiedSuffix: 'Değil указанна',
  do_not_specified: 'Belirtilmemiş',
  fats: 'Yağ',
  proteins: 'Proteinler',
  carbohydrates: 'Karbonhidratlar',
  calories_full: 'Enerji değeri',
  ccal: 'kcal',
  nutritional_value_per_100g_of_product: 'Besin değeri(üzerinde 100гр ürün)',
  at_the_goods: 'Var mal',
  with_barcode: 'İle баркодом',
  error_with_bar_code: 'Hata ile üretimi Баркода!',
  maybe_doest_valid_control_digit: 'Belki: Yanlış sağlama rakam!',
  add_goods: 'Ekle ürün',
  do_delete_category: 'Bir kategoriyi silmek',
  be_deteted: 'Silinir',
  need_detals: 'Gereken açıklama',
  good_with_no_valid_barcode_need_write_it: 'Bu ürün hiçbir geçerli Barkod, ↵ doldurun',
  go_to_edit: 'Git düzenle',
  later: 'Daha sonra',
  good_suffix: 'ürün',
  article: 'Madde no',
  photo: 'Fotoğraf',
  dimension_units: 'U. ölçüm',
  active: 'Aktif',
  sticker: 'Etiket',
  ıtems: 'Mal',
  do_create_catalog: 'Katalog oluşturmak',
  do_create_new_catalog: 'Yeni bir dizin oluşturmak için',
  stickers_print: 'Baskı çıkartmalar',
  write_name_of_new_catalog: 'Yeni bir dizin adı girin',
  write_name_of_new_category: 'Yeni bir kategori adı girin',
  do_add: 'Ekle',
  do_go_back: 'Önce',
  do_continue: 'Devam',
  catalog: 'Katalog',
  doesnt_have_category: 'Kategorileri',
  stickers: 'Etiketler',
  do_print_stickers: 'Baskı etiketler',
  manufacturer_name: 'Üretici firma adı',
  manufacturing_address: 'İmalat adres',
  production_date: 'Üretim tarihi',
  time: 'Zaman',
  hours: 'İzle',
  minutes: 'Dakika',
  days: 'Gün',
  weeks: 'Hafta',
  months: 'Ay',
  years: 'Yıllar',
  do_apply: 'Uygula',
  general_expiration_date: 'Genel raf ömrü',
  amount_of_stickers_per_position: 'Sayısı: etiket için bir pozisyon',
  do_mark_all: 'Tümünü işaretle',
  do_download_agreement: 'İndir sözleşme',
  personal_lowercase: 'kişisel',
  cabinet_lowercase: 'kabine',
  choose_option_or_continue_input: 'Seçeneğini belirleyin ya da yazmaya devam edin',
  unknown_organization: 'Bilinmeyen bir organizasyon',
  organization_info: 'Veri organizasyon hakkında',
  organization_name: 'Kuruluş adı',
  organization_phone: 'Telefon kuruluş',
  ınn: 'INN',
  head_of_company: 'Başkanı',
  registration_date: 'Kayıt tarihi',
  okved: 'ОКВЭД',
  capital: 'Capitale',
  taxes_system: 'Vergi sistemi',
  do_write_inn: 'Yazın INN',
  osno: 'TEMEL',
  usn1: 'УСН (gelir-gider)',
  usn2: 'УСН (gelirleri 6%)',
  innLabel1: '{label} veya kuruluş adı',
  innLabel2: '{label} veya soyadı',
  acting: 'Geçerli',
  first_name_placeholder: 'Michael',
  second_name: 'Soyadı',
  second_name_placeholder: 'Mikhailov',
  third_name: 'İlk, orta',
  third_name_placeholder: 'Mihayloviç',
  position_label: 'Sonrası (nasıl bir tüzük organizasyon)',
  position_placeholder: 'Genel müdür',
  okopf_name_short_label: 'Hukuki şekli kısaltılmış olarak',
  okopf_name_full_label: 'Yasal bir formu tam olarak',
  ooo_full_name: 'Limited şirket',
  name_short_with_opf: 'Kısa adı ile OPF',
  name_short_with_opf_placeholder: "LTD. 'Bagration'",
  type_of_company_placeholder: 'LTD, SP',
  name_short: 'Kısa adı',
  name_short_placeholder: 'Bagration',
  ogrn: 'OGRN',
  first_passport_page: 'Pasaport ilk sayfa',
  passport_page_with_place_of_live: 'Kayıt sayfası',
  bank_account: 'Oda çek hesabı',
  bank_account_register_placeholder:
    'Yazın 20 basamaklı bir banka hesabı (eğer fatura oluşan 22 sayı, belirtin, ilk 20)',
  bık: 'BİK',
  bik_register_placeholder: 'Yazın 9 basamak BIC banka',
  bank_credentials: 'Banka bilgileri',
  receivers_bank: 'Banka alıcı',
  bank_account_number: 'Hesap numarası',
  bank_correspondent_number: 'Corr. hesap',
  your_name: 'Adınızı',
  phone_number: 'Telefon numarası',
  type_of_employment: 'İstihdam türü',
  ip_as_type_of_organization: 'YİP',
  legal_as_type_of_organization: 'Organizasyon',
  date: 'Tarih',
  inventory_system: 'Envanter muhasebe sistemi',
  integration_errors: 'Entegrasyonu sırasında bir hata oluştu',
  integration_with_system_were_succeed: 'İle entegrasyon {system} başarılı',
  do_change_info: 'Verileri değiştirmek',
  do_skip: 'Özledim',
  select_other_system: 'Seçim sistemi',
  select_other_system2: 'Başka bir sistem',
  do_check_integration: 'Kontrol entegrasyonu',
  integration_entry_label: ' Burada eklediğiniz ürünler satacak. Bu çeşitli şekillerde yapılabilir',
  integration_entry_label_option1: 'Sistem entegre Briskly ile muhasebe sistemi.',
  integration_entry_label_option2: 'Dosyayı indirmek mal ile.',
  integration_entry_label_option3: 'Varolan bir dizini seçmek için',
  integration_entry_label_option4: 'Ekle ürün elle',
  select_catalog: 'Bir dizin seçin',
  upload_or_drag_file: 'Karşıdan yükleyin veya dosyayı sürükleyin',
  ıntegration: 'Entegrasyon',
  integration_file_label:
    'Doğru yüklemek için bir dizin relate bizim alan adı ile adlandırılırlar var.',
  item_add_action2_label: 'Oluşturun ve kategoriler eklemek ürünleri',
  write_category_name: 'Kategori adını girin',
  do_create_category: 'Bir kategori oluşturmak',
  actions: 'Eylem',
  do_send_claim: 'Şikayette bulunmak',
  claim_text: 'Metin şikayetleri',
  for_order: 'sipariş',
  order: 'Sipariş',
  client_id: 'ID müşteri',
  total_discount: 'İskonto tutarı',
  order_info: 'Sipariş bilgileri',
  claim_sended: 'Şikayet gönderildi',
  total_amount: 'Toplam maliyeti',
  order_composition: 'Malzemeler sipariş',
  additionally: 'Ayrıca',
  fields_are_optional: 'alanları doldurulması zorunludur',
  show_by: 'Göster',
  do_briskly_module_active_new: 'Etkinleştirmek için yeni bir modül',
  do_briskly_module_finish: 'Tamamlamak',
  briskly_module_reassign_title: 'Bağlama, yeni bir modül',
  briskly_module_reassign_message: 'Eğer doğru bağlamak istiyorum yeni bir Briskly modülü?',
  error: 'Hata',
  fail_bind_employee_to_store: 'Başarısız çalışanın ekleyebilirsiniz',
  fail_unbind_employee_to_store: 'Başarısız salmak çalışan',
  fail_init_employee_store_list: 'Yüklenemedi ile ilgili verileri satış noktaları',
  fail_onboarding_init_company_info: 'Yüklenemedi şirket verileri, doldurulabilir, daha önce',
  unknown_error: 'Bir şey yanlış gitti',
  delete_category_text_confirm:
    'Bu adımı kaldırılır katalog ve mal vardır olacağına kategorisinde tüm""',
  delete_catalog_text_confirm_1: 'Dizin silme işlemi',
  delete_catalog_text_confirm_2: 'ondan kaldırılır tüm kategoriler ve ürünler içindeki',
  do_collapse: 'Rulo',
  exchange_settigns: 'Paylaşım ayarları',
  create_catalog_label_2:
    "'Olabilir hesabınızdaki birden çok dizin. \\nÖrneğin, buzdolabı торгующего hazır yemek veya торгующего dondurulmuş ürünler. \\nSeçerek uygun dizine, hızlı geçiş tek bir ürün listesi başka. \\nBurada bir takas bu dizini.',",
  report_about_item: 'İstatistikler kaleme',
  report_order_id: 'Sipariş Numarası',
  mailings: 'Dağıtım',
  do_add_mailing: 'Bir posta listesi oluşturmak için',
  mailing_new: 'Yeni yayın',
  mailing_edit: 'Düzenleme, dağıtım',
  mailing_status_complete: 'Başarılı',
  mailing_status_saved: 'Muhafaza',
  mailing_status_moderation: 'Üzerinde ılımlılık',
  mailing_status_wait: 'Bekliyor gönderme',
  mailing_status_cancelled: 'İptal edildi',
  mailing_status_ready: 'Ödeme',
  mailing_add_description:
    'Ekleyin haber bültenleri. Belirtin göndermek için zaman, hedef kitle, müşteri ve metin mesajları.',
  mailing_moderation_description:
    'Geçtikten sonra check-in ödemek zorunda kaldım. Unutmayın moderasyon geçer önümüzdeki 1-2 iş günü ödeme gelir hesabına 2 iş günü. Bunu göz önünde bulundurun planlarken dağıtım.',
  mailing_name: 'Adı dağıtım',
  mailing_date: 'Gönderme tarihi',
  mailing_audience: 'Seyirci için postalama',
  mailing_client_count: 'Müşteri sayısı',
  mailing_cost: 'Maliyet dağıtım',
  mailing_text: 'Metin dağıtım',
  mailing_only_save: 'Kaydetmek, ama çalıştırmak için değil',
  mailing_save_and_start: 'Çalıştırmak bülteni',
  mailing_invalid_time: 'Set zaman aralığı 9:00-21:00',
  mailing_may_not_pass_moderation: 'Senin gönderme olabilir zaman geçmesi yönetilir.',
  mailing_all_clients: 'Tüm müşteriler',
  mailing_created_filters: 'Hazır filtreler',
  mailing_selecting_filters: 'Filtre seçimi',
  mailing_save_filters: 'Kaydet filtreler',

  stock_balances: 'Stok bakiyeleri',
  do_add_items_list: 'Öğeleri liste olarak ekleme',
  accounting_for_stock_balances: 'Stok bakiyeleri için muhasebe',
  rebase_catalogs: 'Bir kataloğu kaldırma> Çıkışları düzenleme',
  clarification_in_items_side_bar:
    '* Dosyanızdaki ürünler zaten kategorilere ayrılmışsa, sadece Kataloğu seçin ve dosyayı yükleyin, Kategoriler otomatik olarak oluşturulacaktır.',
  do_download_xlsx: '.xlsx dosyasını yükle',
  stock_accounting_balances_info:
    'Perakende satış noktalarınızdaki malların miktarı ve değeri hakkında güncel bilgiler edinmek için, "Stok bakiyeleri için muhasebe" işlevini kullanın. ',
  or: 'yada',
  category_info_tip:
    'Yeni bir kategoriyi ürünlerle daha hızlı ve otomatik olarak doldurmak için bu işlevi kullanın.',
  good_must_be_in_catalog_or_category: 'Ürün bir kataloğa veya kategoriye bağlanmalıdır.',
  items_add_side_bar_info:
    'Mevcut dizinde yeni bir dizin veya kategori oluşturmanız gerekiyorsa, bölüme gidin',
  do_delete_catalog: 'Kataloğu sil',
  delete_catalog: 'Kataloğu sil',
  trade_point_edit: 'Satış noktalarını düzenleme',
  rebase_catalog_1: '"{name}" kataloğunu kaldırmak için ihtiyacınız var',
  rebase_catalog_2: 'yeni katalogları tüm satış noktalarına bağla',
  rebase_catalog_3: 'aşağıdaki tabloda.',
  cant_start_from_dot: 'Noktadan başlayamaz',
  aprove_category_delete: 'Bir kategoriyi silmek üzeresiniz',
  delete_category_warn: 'Bir kategoriyi silmek, bu kategorideki tüm ürünleri siler',
  delete_catalog_confirm_text_5: 'Kategori veya ürün içermeyen boş bir dizini silmek üzeresiniz',
  delete_catalog_confirm_text_6: 'Ürünleri içeren bir kataloğu silmek üzeresiniz',
  trade_point_type_name: 'Ticaret noktası türü',
  select_new_catalog: 'Yeni bir katalog seçin',
  barcoders_prefix_for_weight_good: 'Ağırlık Öğesi Barkod Önekleri',
  barcoders_prefix_info:
    'Bu ön ekin değerine göre, sistem, öğenin dijital kodunu ve gram cinsinden ağırlığını vurgulayarak öğeyi ağırlıkla tanır. Mevcut ön ekler 20 ile 29 arasındadır. Ön ek belirtilmezse, mallar parça mal olarak kabul edilecektir. ',
  age_limit_2: 'yıllar',
  age_limit_1: 'Yaş sınırı',

  requisites_personal_data_descr_part_1:
    'satış noktanızın satış gelirlerini günlük olarak aktarabilmek için bu bilgileri doldurmanızı rica ediyoruz. Bunu yapmak için, edinme bağlanırken sağladığınıza benzer bilgiler sağlamanız gerekir.',
  requisites_personal_data_descr_part_2:
    "verileriniz Merkez Bankası ve alıcı bankanın talebi üzerine talep edilir. Bunlar şifreli olarak saklanacaktır. Da, briskly şirketi, roskomnadzor'un kişisel veri operatörlerinin kayıt defterindedir.",
  add_good: 'ürün Ekle',
  external_id: 'Açık kimliği',
  do_save_and_go_back: 'Kaydet ve geri dön',
  print_shelf_life: 'Yazdır raf ömrü',
  succes_saved: 'Başarıyla kaydedildi',
  quantity_cut: 'Adet',
  additional_data_about_good: 'Ek verileri hakkında ürün',
  doest_not_req_field: 'isteğe bağlı alanları',
  do_upload_photo: 'fotoğraf Yükle',
  upload_good_list: 'İndir"mal listesi',
  show_filters: 'Göstermek filtreler',
  hide_filters: 'filtreleri Gizle',
  available_table_filters: 'Tablo filtreleri',
  deletion: 'Sil',
  ' ıtem_warning_info': 'Ürün herhangi bir dizine ve kategoriye bağlı olmalıdır.',
  ıtem_create_info: 'geçerli dizinde yeni bir dizin veya kategori oluşturmanız gerekiyorsa, ',
  aprove_catalog_delete: 'dizini silmek üzeresiniz',
  delete_catalog_text_confirm_3: 'dizini sildiğinizde içindeki tüm kategoriler ve ürünler silinir.',
  delete_catalog_text_confirm_4:
    'Bir kataloğu silmek için önce silinen yerine tüm satış noktalarına yeni bir katalog bağlamanız gerekir.',
  go_to_edit_store: 'İşlemi düzenlemeye git points ',
  'choose_trade_point ': ' Bir satıcı seçin ',
  'do_catalog_edit ': ' Bir kataloğu düzenleme ',
  'do_category_edit ': ' Kategori düzenleme ',
  'category_warn_text_warn ': ' Bir kategori bir kataloğa bağlanmalıdır. ',
  'category_warn_text ': 'Yeni bir katalog oluşturmanız gerekiyorsa, şu bağlantıyı izleyin',
  category_warn_text_link: 'Yeni bir katalog oluşturun.',
  catalog_rebase_info: 'artık herhangi bir çıkışa bağlı değil, Şimdi yapabilirsin',

  ready: 'Bitti',
  no_design_to_select: 'Hayır kaydedilen tasarımlar',
  income: 'Kar',
  valid_fill_the_field: 'alanını Doldurun',
  valid_incorrect_date: 'Geçersiz tarih',
  valid_incorrect_phone: 'Hatalı telefon',
  valid_passport_series: 'Yanlış serisi',
  valid_passport_number: 'Geçersiz pasaport numarası',
  valid_passport_authority_code: 'Geçersiz kod birimi',
  valid_inn: 'Seçin, INN',
  valid_address: 'Bir adres seçin',
  valid_bic: 'Seçin BİK',
  valid_account_number: 'Uzunluk çek hesabı olmalıdır 20 karakter',
  valid_maximum: 'en fazla sayısı karakter',
  valid_select_some_filter: 'lütfen en az bir фильтр',
  inside: 'Внутри',
  card: 'Карта',
  image: 'Изображение',
  altogether: 'Всего',
  composition: 'Состав',
  inn: 'INN',
  bik: 'BIK',
  integration: 'Интеграция',
  need_to_select_catalog_and_category:
    'Oluşturmak için kalem \n seçmek gerekir \n katalog ve kategori',
  categories_of_catalogs: 'dizin Kategorileri',
  add_catalog: 'dizin Ekle',
  add_category: 'kategori Ekle',
  add_catalog_action: 'dizin Ekleme',
  add_category_action: 'kategori Ekle',
  category_name: 'Paket kategoriler',
  catalogs: 'kataloglar',
  search_by_categories: 'Kategoriye göre ara',
  search_by_catalogs: 'kataloglara göre ara',
  warning: 'Dikkat!',
  write_new_catalog_name: 'dizin adını girin',
  write_new_category_name: 'Kategori adını girin',
  add_catalog_warning:
    ' dizini birden fazla satış noktasına bağlayabilirsiniz, bunun için ek bir satış noktası seçim alanı eklemek için " + " kullanın.',
  add_catalog_info:
    'bir dizine aynı anda birden fazla kategori ekleyebilirsiniz, bunu yapmak için, yeni kategorinin adını girmek için ek bir alan eklemek için “+” kullanın. ',
  product_product_date: 'Baskı üretim tarihi',
  mailing_going_to_delete: 'gidiyoruz kaldırmak bülteni',
  address_doesnt_correct: 'Adresi doğru değil',
  no_saved_templates: 'No kaydedilmiş şablonlar',
  add_schedule_and_save_as_template: 'seçenekleri Ekleyin ve sonra kaydedin şablon',
  service_mts_kassa: 'MTS Ofisi',
  create_new_catalog: 'yeni bir dizin Oluşturmak için',
  use_existing_catalog: 'Kullanmak için varolan bir dizini',
  log_out_question: 'tam olarak bitirmek istiyorum geçerli oturumu?',
  item_warning_info: 'Ürün mutlaka bir dizine bağlı olmalı ve Kategoriler.',
  item_create_info: 'geçerli dizinde yeni bir dizin veya kategori oluşturmanız gerekiyorsa, ',
  choose_trade_point: 'bir satış noktası seçin',
  do_catalog_edit: 'Katalog düzenleme',
  do_category_edit: 'Kategori düzenleme',
  category_warn_text_warn: 'Kategori herhangi bir dizine bağlı olmalıdır.',
  category_warn_text: 'yeni bir katalog hazırlamanız gerekiyorsa, lütfen bağlantıyı takip edin',
  item_photo_note:
    '(ürün fotoğrafınızı yükleyebilir veya aşağıda gösterilen varsayılan fotoğrafı bırakabilirsiniz)',
  store_doesnt_have_catalog:
    'satış noktası {name} kataloga düzenleme yapmak için bir bağlantı yok mu ?',
  success: 'Başarılı',

  uploaded_image_min_width: 'Görüntünün Minimum genişliği',
  uploaded_image_max_width: 'Görüntünün maksimum genişliği',
  uploaded_image_min_height: 'Görüntünün Minimum yüksekliği',
  uploaded_image_max_height: 'Görüntünün maksimum yüksekliği',
  allowed_types: 'İzin türleri',
  max_file_size: 'Maksimum dosya boyutu',
  standard_no_suf: 'Standart',
  orders_and_video: 'Siparişler ve videolar',
  payment_type_undefined: 'Belirlenmemiş',
  orders_not_exist: 'Ticaret noktalarınızda henüz sipariş yok.',
  service_csv: 'csv',
  ok: 'Ok',
  price_with_currency_inject: 'Fiyat, {currency}',
  sum_with_currency_inject: 'Miktar, {currency}',
  total_amount_price_with_currency_inject: 'Toplam fiyat,, {currency}',
  total_amount_sum_with_currency_inject: 'Toplam tutar, {currency}',
  time_of_creation: 'Oluşturma zamanı',
  find_by_order_number: 'Arama emriyle numarası',
  id_trade_point: 'Mağaza kimliği',
  item_id: 'Öğe kimliği',
  good_barcode: 'Ürün barkodu',
  option_discount_percent_cut: 'İndirim, %',
  create_refund: 'Geri ödeme oluştur',
  created_at: 'Oluşturma zamanı',
  customer_id: 'Müşteri',

  valid_minimum: 'Minimum karakter sayısı',
  coords_lng: 'Boylam',
  coords_lat: 'Enlem',

  invalid_intervals_filled: 'Alanlar doldurulmadı',
  invalid_intervals_to_less_then_from: 'Geçersiz aralıklar',
  invalid_intervals_intersections: 'Aralıklar çakışmamalıdır',
  valid_max_250: 'Maksimum 250 karakter',
  postal_code: 'posta Kodu',
  module_description_temp:
    'Briskly modülü, mikro marketinizin beynidir. Satın aldıktan sonra, hesabınıza bağlı olması gereken bir kod verildi.',
  value_must_be_more_than_zero: "Değer 0'dan büyük olmalıdır",
  valid_min_13: 'En az 13 karakter',
  dosesnt_correct_number: 'Geçersiz numara',
  success_deleted: 'Başarıyla uzaktan',
  do_return_back: 'Dönmek',
  abort_procedure: 'İptal prosedürü',
  abort: 'İptal etmek',
  maybe_data_not_saved: 'Yapılan değişiklikler kaydedilmeyebilir',
  empty_catalog: 'Katalog boş',
  fileupload_page_info:
    'Dosyanızdaki mallar zaten Kategorilere ayrılmışsa, sadece Kataloğu seçin ve dosyayı yükleyin, Kategoriler otomatik olarak oluşturulacaktır.',
  do_download_xlsx_example: 'İndirmek .xlsx örneği',
  valid_max_11: 'En fazla 11 karakter',
  payment_type: 'Ödeme şekli',
  app: 'Uygulama',
  terminal: 'Terminal',
  order_with_id_inject: 'Sipariş kimliği {orderıd}',
  refund_with_order_id_inject: 'İadeler: sipariş kimliği {orderıd}',
  barcode_can_includes_only_digits: 'Sadece sayılar',
  copy_to_clipboard: 'Arabelleğe kopyala',
  copied_to_clipboard: 'Panoya kopyalandı',
  about_order: 'Sipariş Hakkında',
  refund_title: 'Geri ödeme yapmak',
  amout_goods_to_refund: 'İade edilecek toplam ürün sayısı',
  amount_goods_payment: 'Geri ödeme tutarı',
  technical_support: 'Teknik destek',
  add_refund: 'Geri ödeme Ekle',
  view_refund: 'İadeleri görüntüle',
  onboarding_virtual_terminal: 'Sanal terminal',
  virtual_terminal: 'Sanal terminal',
  select_virtual_terminal: 'Sanal bir terminal seçin',
  virtual_terminal_description:
    'Sanal terminalinizin ayrıntılarını doldurun, hesabınıza para yatırmak ve bir müşteri için bir çek oluşturmak için gereklidir.',
  add_new_virtual_terminal: 'Terminal Ekle',
  do_create_refund: 'Geri ödeme oluştur',

  delete_goods_modal_info: 've {stringsQuantity}... daha fazla ürün',
  client_show_settings_2: 'Mobil uygulama için Ekran Ayarları',
  no_changes_to_update: 'Kaydedilecek değişiklik yok',
  for_go_to_exchange_need_say_store: 'Bir ticaret noktası seçin',
  link_to_showcase: 'Ürün vitrinine bağlantı',
  catalog_successfully_added: 'Katalog başarıyla eklendi',
  refund_has_been_created: 'Geri ödeme oluşturuldu',
  no_trans_action: 'İşlem yok',
  no_refund_text_info: 'Bu sipariş için herhangi bir geri ödeme yapılmadı.',
  date_should_not_earlier: 'Tarih daha erken olmamalıdır',
  date_should_not_later: 'Tarih en geç olmalıdır',
  cant_play_video: 'Video oynatılamıyor',
  check_refund_possibility: 'Geri ödeme seçeneklerini kontrol edin',
  refund_helper_text:
    'Bir ürünü hızlı bir şekilde aramak için kimliğini, barkodunu veya adını girin',
  no_one_good_selected: 'Geri ödeme için hiçbir öğe seçilmemiştir.',
  errors_occurred_while_creating_the_refund: 'Geri ödeme oluşturulurken hatalar oluştu:',
  refund_by_id_goes_successed: 'Sipariş kimliği için geri ödeme {refundıd} başarılı oldu',
  refund_by_id_goes_accept: 'Sipariş kimliği için geri ödeme onaylayın {refundıd}',
  refund_technical_support_notice: 'Geri ödeme yapmak için lütfen teknik desteğe başvurun.',
  refund_money_back_notice:
    'Para, geri ödeme tarihinden itibaren 2 gün içinde müşterinin kartına yatırılacaktır.',
  refund_ready_title: 'Sipariş iadesi',
  refund_check_success_info: 'Geri ödeme başarıyla oluşturulabilir',
  refund_check_success_info_with_order_id: 'İade sipariş kimliği {orderıd} başarıyla oluşturuldu',
  approve_refund: 'Geri ödemeyi Onayla',
  video_not_available: 'Video mevcut değil',
  video_tooltip_error:
    'Video dosyası bozuk veya geçici olarak kullanılamıyor. Lütfen Destek ekibiyle iletişime geçin',
  do_upload: 'İndirmek',
  amout_for_refund: 'Toplam öğeler iadesi',
  does_not_correct_control_sum: 'Geçersiz sağlama toplamı',

  // store: '',

  active_status: 'Bekleyen ödeme',
  paid_status: 'Ücretli',
  refund_status: 'İade',
  refund_awaiting_status: 'Dönüş bekleniyor',
  refund_error_status: 'Dönüş hatası',
  refund_partial_status: 'Kısmen iade edildi',
  removed_status: 'Silindi',
  online_store_layouts: "Micromarket'te baskı için düzenle",
  promo_stickers: 'Etiketler',
  promo_side_stickers: 'Yan Çıkartmalar',
  promo_on_glas: 'Cam üzerinde',
  promo_on_advertising_block: 'Reklam birimi başına',

  promo_without_ad_block: '(reklam birimi yok)',
  promo_with_ad_block: '(reklam birimi ile)',
  attention: 'Dikkat',
  requisites_skip_warning:
    'Ayrıntıları seçme / ekleme adımını atlarsanız, ticaret noktanız test modunda çalışır.',
  select_full_party_description:
    'Daha önce kullanılan ayrıntıları seçebilir veya formu doldurarak yenilerini ekleyebilirsiniz!',
  select_partial_party_description: 'Sanal terminalinizi kişisel hesabınıza bağlayın!',
  pick_terminal: 'Terminali seç',
  add_new_full_party: 'Yeni Ekle',
  add_new_partial_party: 'Yeni Ekle',
  swift_bic: 'SWIFT / BIC',
  secure3d: '3DS',
  non_secure3d: 'olmayan 3DS',
  full_party_choosing: 'Ayrıntıları seçme',
  partial_party_choosing: 'Terminal seçimi',
  requisites_and_terminals: 'Tüzelkişi',
  terminals: 'Sanal terminaller',
  full_party_status_active: 'Etkin',
  full_party_status_pending: 'Kontrol',
  full_party_status_replacement: 'Değişmiş',
  full_party_edit: 'Düzenleme şartları',
  partial_party_edit: 'Sanal terminali düzenleme',
  adding_new_terminal: 'Yeni bir terminal ekleme',
  add_full_party: 'Ayrıntıları Ekle',
  add_partial_party: 'Sanal terminal Ekle',
  full_party_edit_warning:
    'Değişiklikten sonra, detaylar bir süre kontrol edilecektir. Çekden hemen sonra ticaret noktaları için yeni detaylar kaydedilecektir.',
  partial_party_edit_warning:
    'Değişiklikten sonra terminal bir süre kontrol edilecektir. Yeni terminal, çekden hemen sonra ticaret noktaları için kaydedilecektir.',
  kpp: 'KPP',

  upload_logo_in_format: 'Logonuzu yükleyin',
  upload_logo_with_min_size: 'Minimum resim boyutu',
  upload_logo_with_min_file_size: 'Maksimum dosya boyutu',

  currency: 'Para birimi',

  BYN: 'Belarus rublesi(BYN)',
  USD: 'ABD doları(USD)',
  EUR: 'Avro(EUR)',
  KZT: 'Kazak tenge(KZT)',
  CAD: 'Kanada Doları (CAD)',
  CNY: 'CNY(CNY)',
  RUB: 'Rus rublesi(RUB)',
  TRY: 'Türk Lirası(TRY)',
  UAH: 'Ukrayna grivnası(UAH)',
  GBP: 'GBP(GBP)',
  CHF: 'İsviçre frank(CHF)',
  bank_name: 'Banka adı',
  company_name: 'Şirketin tam adı',

  serial_number: 'Seri numarası',
  coffee_reeboot: 'Başlatmak',
  coffee_reeboot_coffee_machine: 'Kahve makinesini yeniden başlatın',
  coffee_reeboot_text: 'Cihazı yeniden başlatmak, bir dizi sorunu çözmeye yardımcı olur.',
  defer_service: 'Zorunlu hizmeti Ertele',
  coffee_postpone: 'Ertelemek',
  coffee_service_maintenance_text: 'Servis koşulu bir sonraki aktivasyona kadar ertelenecektir.',
  block_drinks: 'Blok içecek hazırlama',
  coffee_block_text:
    'Kahve makinesi aktif kalacaktır, ancak içeceklerin hazırlanması müşteriler için mevcut olmayacaktır',
  block_drink_change: 'İçecek parametrelerinin blok değişimi',
  coffee_props_block_text:
    'Hazırlık sırasında kullanılabilir. Etkinleştirildikten sonra, müşteriler standart içeceğe değişiklik ekleyemeyeceklerdir.',
  block_menu_access: 'Özel menüye erişimi engelle',
  coffee_menu_text: 'Müşteriler kahve makinesindeki menüyü kullanamayacaklar.',
  block_drinks_when_offline:
    'Sunucuya 48 saatten fazla bağlantı yoksa içeceklerin hazırlanmasını engelleyin',
  coffee_reloading: 'Başlatır ...',
  search_by_items: 'Ürünlere göre ara',
  coffee_item_photo: 'Ürün fotoğraf',
  coffee_item_photo_info:
    '(kendi ürün fotoğrafınızı yükleyebilir veya aşağıda gösterilen varsayılan fotoğrafı bırakabilirsiniz)',
  coffee_item_type: 'İçecek türü',
  item_recept: 'Ürün tarifi',
  value_ml: 'ml',
  cup_with_units_injection: 'Cam {cupsize} ml',
  drink_with_units_injection: 'İçecek {Drinksize} ml',
  coffee_foam: 'Süt köpüğü',
  coffee_milk: 'Süt',
  coffee_water: 'Su',
  coffee_coffee_size: 'Kahveci',
  return_values_to_default: 'Varsayılan değerleri geri yükle',

  price_must_be_more_then_inject: 'Fiyat daha yüksek olmalı {price}',
  coffee_item_photo_info__temp:
    "Varsayılan fotoğrafı bırakabilir veya 'seç' düğmesini tıklayarak mevcut fotoğraflardan birini seçebilirsiniz.",
  block_drink_changes: 'İçecek parametrelerinin değiştirilmesini engelle',

  file_name_uploaded: 'Dosya {fileName} yüklendi',
  columns: 'Sütun',
  file_upload_rules: 'Ürün yükleme için dosya gereksinimleri:',
  file_upload_rules_1: 'Biçimli — .xlsx;',
  file_upload_rules_2: 'Dosya birleştirilmiş hücreler içermemelidir.',
  file_upload_rules_3: 'Alan adları en fazla bir satır uzunluğunda olmalıdır.',
  file_upload_rules_4:
    "Ölçü birimleri ve 'miktar' alanlarında hata olması durumunda ...— - '1 parça' otomatik olarak girilecektir.",
  are_mandatory: 'zorunludur',
  file_upload_page_info:
    'Kataloğu doğru şekilde yüklemek için, alan adlarımızı sizin için nasıl adlandırıldıklarıyla eşleştirin.',
  edit_file_and_try_again: 'Hataları düzeltin ve tekrar deneyin',
  do_stop_now: 'Şimdi bitir',
  no_save_upload_remember:
    'Şimdi bitir düğmesine tıklayarak onaylarsınız ... ürünlerinizle ilgili verilerin yanlış görüntülenmesi.',
  exchange_drpops_with_exception: 'Entegrasyon sırasında hatalar oluştu',
  good_must_be_in_catalog_or_category_rewrite: 'Ürün bir kataloğa bağlı olmalıdır.',
  fileupload_page_info_rewrite:
    '* Dosyanızdaki ürünler halihazırda kategorilere ayrılmışsa, sadece Kataloğu seçin ve dosyayı yükleyin, kategoriler otomatik olarak oluşturulacaktır.',
  loading: 'Yükleniyor...',
  show_detail_info: 'Ayrıntılı bilgileri göster',
  file_upload_success_info:
    '“Ekle” yi tıklayarak, ürünlerin ilgili kataloğa ve Kategorilere yüklenmesini onaylarsınız.',
  be_created: 'Oluşturuldu',
  selected_catalog: 'Katalog seçildi',
  file_uploaded: 'Dosya yükleme',
  was_successful: 'başarılı oldu',
  and: 've',
  valid_min: 'Minimum {min}',
  value_mm: 'milimetre',
  unit_796: 'bir şey',
  unit_657: 'Ürün',
  unit_111: 'hacim (ml)',
  unit_112: 'Litrelik',
  unit_163: 'Gram(g)',
  unit_625: 'Sayfa',
  unit_704: 'Koymak',
  unit_715: 'Çiftleştirmek',
  unit_166: 'Killogram (kg)',
  unit_736: 'Rulo',
  unit_778: 'Ambalaj',
  item_from_upload_info: 'Kataloğa hızlı bir şekilde ürün eklemek için işlevini kullanın',
  promo_briskly_link: 'Promosyon malzemeleri',
  store_name_placeholder: 'Çıkış adı',
  serial_number: 'Seri numarası',
  coffee_reeboot: 'Başlatmak',
  coffee_reeboot_coffee_machine: 'Kahve makinesini yeniden başlatın',
  coffee_reeboot_text: 'Cihazı yeniden başlatmak, bir dizi sorunu çözmeye yardımcı olur.',
  defer_service: 'Zorunlu hizmeti Ertele',
  coffee_postpone: 'Ertelemek',
  coffee_service_maintenance_text: 'Servis koşulu bir sonraki aktivasyona kadar ertelenecektir.',
  block_drinks: 'Blok içecek hazırlama',
  coffee_block_text:
    'Kahve makinesi aktif kalacaktır, ancak içeceklerin hazırlanması müşteriler için mevcut olmayacaktır',
  block_drink_change: 'İçecek parametrelerinin blok değişimi',
  coffee_props_block_text:
    'Hazırlık sırasında kullanılabilir. Etkinleştirildikten sonra, müşteriler standart içeceğe değişiklik ekleyemeyeceklerdir.',
  block_menu_access: 'Özel menüye erişimi engelle',
  coffee_menu_text: 'Müşteriler kahve makinesindeki menüyü kullanamayacaklar.',
  block_drinks_when_offline:
    'Sunucuya 48 saatten fazla bağlantı yoksa içeceklerin hazırlanmasını engelleyin',
  coffee_reloading: 'Başlatır ...',
  search_by_items: 'Ürünlere göre ara',
  coffee_item_photo: 'Ürün fotoğraf',
  coffee_item_photo_info:
    '(kendi ürün fotoğrafınızı yükleyebilir veya aşağıda gösterilen varsayılan fotoğrafı bırakabilirsiniz)',
  coffee_item_type: 'İçecek türü',
  item_recept: 'Ürün tarifi',
  value_ml: 'ml',
  cup_with_units_injection: 'Cam {cupsize} ml',
  drink_with_units_injection: 'İçecek {Drinksize} ml',
  coffee_foam: 'Süt köpüğü',
  coffee_milk: 'Süt',
  coffee_water: 'Su',
  coffee_coffee_size: 'Kahveci',
  return_values_to_default: 'Varsayılan değerleri geri yükle',

  price_must_be_more_then_inject: 'Fiyat daha yüksek olmalı {price}',
  coffee_item_photo_info__temp:
    "Varsayılan fotoğrafı bırakabilir veya 'seç' düğmesini tıklayarak mevcut fotoğraflardan birini seçebilirsiniz.",
  block_drink_changes: 'İçecek parametrelerinin değiştirilmesini engelle',
  heating: 'ısıtma',
};
