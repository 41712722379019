import { bus } from 'shared/core';
import store from '@/core/store';

class NotificationsEvent {
  init() {
    bus.on('core$notifications/add', this.onNotificationAdd);

    // TODO: add client-to-server logger communication here

    window.onerror = (error, url, lineNumber) => {
      this.onNotificationAdd({ error: new Error(error), onlyChunks: true });
      return false;
    };
  }

  onNotificationAdd = (opts = {}) => {
    const {
      error = {},
      meta = {},
      cb = () => {},
      type = 'notification',
      onlyChunks = false,
      passive = false,
    } = opts;

    if (error && error.message) {
      console.error(error);

      if (this.isChunkError(error.message)) {
        this.onChunkError();
        return;
      }
    }

    if (!onlyChunks && !passive)
      store.dispatch('core$notifications/add', { error, meta, cb, type });
  };

  isChunkError = (error) => {
    const errorText = error.toLowerCase();
    const syntaxErrorMessages = [
      "syntaxerror: expected expression, got '<'",
      "syntaxerror: unexpected token '<'",
    ];

    const isChunkFailedMessage = errorText.includes('chunk') && errorText.includes('failed');
    const isSyntaxErrorMessage = syntaxErrorMessages.some((e) => errorText.includes(e));

    console.warn('ATTENTION', new Date(), isChunkFailedMessage, isSyntaxErrorMessage, errorText);

    // chunk load error: exists
    // me:
    return isChunkFailedMessage || isSyntaxErrorMessage;
  };

  onChunkError = () => {
    const cb = () => window.location.reload(true);

    store.dispatch('core$notifications/add', {
      meta: {
        message:
          'Вышла новая версия Briskly Business, нажмите кнопку "Перезагрузить" для продолжения работы',
        title: 'Важная информация',
        withClose: false,
        buttons: [
          {
            text: 'Перезагрузить',
            type: 'success',
            callback: cb,
          },
        ],
      },
      cb,
      type: 'modal',
    });
  };
}

export default new NotificationsEvent();
