const state = {
  show: false,
  active: 0,
};

const getters = {
  active: (state) => state.active,
  show: (state) => state.show,
};

const actions = {
  start: ({ getters, commit }) => {
    commit('_addActive');
    if (getters.active > 0) commit('_setShow', true);
  },

  stop: ({ getters, commit }) => {
    commit('_removeActive');
    if (getters.active <= 0) commit('_setShow', false);
  },
};

const mutations = {
  _setShow: (state, value) => {
    state.show = value;
  },

  _addActive: (state) => {
    state.active = state.active + 1;
  },

  _removeActive: (state) => {
    state.active = state.active - 1 < 0 ? 0 : state.active - 1;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
