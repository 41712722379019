import { companyAPI } from '@/core/api/company';
import { getI18n } from '@/core/i18n';
import { pick, isEmpty } from 'lodash';
import { checkRefundStatus } from '@/apps/orders/data/utils';

const state = {
  statusDictionary: {},
  currentPage: 1,
  ordersMeta: {},

  storeName: '',
  selectedStore: {},
  search: '',

  clientID: '',

  activeStatus: 0,

  periodRange: { start: null, end: null },

  sorted: {
    id: 'DESC',
  },

  orderInfo: [],
};

const getters = {
  currentPage: (state) => state.currentPage,
  sorted: (state) => state.sorted,
  search: (state) => state.search,
  ordersMeta: (state) => state.ordersMeta,
  selectedStore: (state) => state.selectedStore,
  storeName: (state) => state.storeName,
  clientID: (state) => state.clientID,
  periodRange: (state) => state.periodRange,

  statusDictionary: (state) => state.statusDictionary,
  activeStatus: (state) => state.activeStatus,

  orderInfo: (state) => state.orderInfo,
};

const actions = {
  // Statuses
  getStatusesDictionary: async ({ commit }) => {
    try {
      const list = await companyAPI.getStatusesDictionary();
      commit('_setStatusesDictionary', list);
    } catch (error) {
      console.log(error);
    }
  },

  setActiveStatus: ({ commit }, status) => {
    commit('_setActiveStatus', status);
  },

  setCurrentPage: async ({ commit }, page) => {
    commit('_setCurrentPage', page);
  },

  setSorted: async ({ commit }, payload) => {
    commit('_setSorted', payload);
  },

  setSearch: async ({ commit }, search) => {
    commit('_setSearch', search);
  },

  setOrdersMeta: async ({ state, commit }, meta) => {
    commit('_setOrdersMeta', meta);
  },

  setStoreName: async ({ commit }, value) => {
    commit('_setStoreName', value);
  },

  setSelectedStore: async ({ commit }, store) => {
    commit('_setSelectedStore', store);
  },

  setPhoneOrId: async ({ commit }, value) => {
    commit('_setPhoneOrId', value);
  },
  setPeriodRange: async ({ commit }, period) => {
    commit('_setPeriodRange', period);
  },

  buildOrderInfo: async ({ commit, dispatch, state }, { order, fields }) => {
    const i18n = getI18n();
    const checkedOrder = checkRefundStatus(order);
    const necessaryFields = pick(checkedOrder, fields);

    necessaryFields.store = order.store && order.store.name;

    if (isEmpty(state.statusDictionary)) {
      await dispatch('getStatusesDictionary');
    }

    const modifiedOrder = Object.keys(necessaryFields).reduce((acc, ord) => {
      const orderInfo = {
        value:
          ord === 'status'
            ? i18n.t(state.statusDictionary[necessaryFields[ord]])
            : necessaryFields[ord],
        label: i18n.t(`${ord}`),
      };
      return [...acc, orderInfo];
    }, []);
    commit('_buildOrderInfo', modifiedOrder);
  },
};

const mutations = {
  _setCurrentPage: (state, page) => {
    state.currentPage = page;
  },

  _setSorted: (state, payload) => {
    state.sorted = { ...payload };
  },
  _setSearch: (state, search) => {
    state.search = search;
  },
  _setOrdersMeta: (state, meta) => {
    state.ordersMeta = meta;
  },
  _setStoreName: (state, value) => {
    state.storeName = value;
  },
  _setSelectedStore: (state, store) => {
    state.selectedStore = store;
  },
  _setPhoneOrId: (state, value) => {
    state.clientID = value;
  },
  _setPeriodRange: (state, period) => {
    state.periodRange = { ...state.periodRange, ...period };
  },
  _setStatusesDictionary: (state, dictionary) => {
    state.statusDictionary = { ...dictionary };
  },
  _setActiveStatus: (state, status) => {
    state.activeStatus = status.id;
  },
  _buildOrderInfo: (state, order) => {
    state.orderInfo = order;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
