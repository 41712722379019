import { canHaveDelivery } from '@/apps/trade-point/utils/types';
import { getYOptionsByMode } from '@/core/utils/graph';
import { EXTENDED_TYPES } from '@/apps/trade-point/data/types';
import { getDateGroupMode, getDateInterval } from '@/core/utils/date';
import { companyAPI } from '@/core/api/company';
import { bus } from 'shared/core';
import router from '@/core/router';

const buildPayload = (interval, popularItemsOrder) => {
  const { start, end } = getDateInterval(interval);
  const mode = getDateGroupMode({ start, end });

  const payload = {
    date_start: start,
    date_end: end,
    store_id: state.store.id,
    status: 'paid',
    timestamp_func: 'timestamp_payment',
    ...(popularItemsOrder && { order: popularItemsOrder }),
    mode,
  };
  return payload;
};

const initialState = () => ({
  store: null,
  party: null,
  geoList: null,
  boxState: null,
  schedule: null,
  statistic: {
    items: [],
    total: null,
    mode: '',
  },
  popularItems: [],
  loading: true,
  dayInterval: {
    range: { start: null, end: null },
    shortcut: 'today',
  },

  coffeeMachineState: null,
  isAdvSideBarOpen: false,
});

const state = initialState();

const getters = {
  store: (state) => state.store,
  party: (state) => state.party,
  boxState: (state) => state.boxState,
  loading: (state) => state.loading,
  dayInterval: (state) => state.dayInterval,
  popularItems: (state) => state.popularItems,
  schedule: (state) => state.schedule,

  averageAmount: (state) =>
    state.statistic.items.map((item) => ({
      value: Number(item.avg_amount),
      label: getYOptionsByMode(state.statistic.mode, item),
    })),

  totalAmount: (state) =>
    state.statistic.items.map((item) => ({
      value: Number(item.total_amount),
      label: getYOptionsByMode(state.statistic.mode, item),
    })),

  deliveries: (state) => {
    if (!state.geoList) return [];

    return state.geoList.items.map((geo) => {
      const type = geo.delivery_service_type === 2 ? 'delivery_own' : 'delivery_briskly';
      const radius = Math.round((geo.radius / 1000) * 10) / 10;

      return { type, radius };
    });
  },
  avgCheckTotal: (state) => state.statistic.total.avg_amount,
  isAdvSideBarOpen: (state) => state.isAdvSideBarOpen,
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  init: async ({ dispatch, commit }, id) => {
    commit('_setLoading', true);

    const isStoreFound = await dispatch('tryToGetStoreById', id);

    if (!isStoreFound) {
      return router
        .replace({ name: 'trade-point$trade-point' })
        .finally(() => commit('_setLoading', false));
    }

    await dispatch('getParty');
    await dispatch('getSchedule');
    await dispatch('getGeoList');
    await dispatch('getBoxState');
    await dispatch('getBoxNoticeState');

    commit('_setLoading', false);
  },

  tryToGetStoreById: async ({ commit }, id) => {
    try {
      const store = await companyAPI.getStoreById({ id });
      commit('_setStore', store);

      return true;
    } catch (error) {
      return false;
    }
  },

  getParty: async ({ commit, state }) => {
    const id = state.store && state.store.party_id;

    try {
      if (id) {
        const party = await companyAPI.getPartyById({ id });
        commit('_setParty', party);
      }
    } catch (error) {
      console.error(error);
    }
  },

  getGeoList: async ({ commit, state }) => {
    const type = state.store.extended_type;
    if (!canHaveDelivery(type)) return;

    const { id: store_id } = state.store;

    try {
      const geoList = await companyAPI.getGeoList({ store_id });
      commit('_setGeoList', geoList);
    } catch (error) {
      console.error(error);
    }
  },

  getBoxState: async ({ state, commit }) => {
    const _getBoxState = ({ id, extended_type }) => {
      switch (extended_type) {
        case EXTENDED_TYPES.COFFEE_MACHINE:
          return console.log('coffeMashineStatus');
        // return companyAPI
        //   .getCoffeeMachineStats({ id })
        //   .then((res) => commit('_setCoffeeState', res));
        case EXTENDED_TYPES.CIGARETTES:
        case EXTENDED_TYPES.REFRIGERATOR:
          return companyAPI.getStoreBoxState({ id }).then((res) => commit('_setBoxState', res));
        default:
          return Promise.resolve();
      }
    };

    try {
      await _getBoxState(state.store);
    } catch (error) {
      console.error(error);
    }
  },

  getBoxNoticeState: async ({ state, commit }) => {
    try {
      const noticeState = await companyAPI.getBoxNoticeState({ store_id: state.store.id });
      commit('_setBoxNoticeState', noticeState);
    } catch (error) {}
  },

  updateBoxNoticeState: async ({ state, commit }, noticeState) => {
    const payload = {
      store_id: state.store.id,
      connection: noticeState.connection,
      temperature: noticeState.temperature,
    };
    try {
      await companyAPI.setBoxNoticeState(payload);
    } catch (error) {}
  },

  getSchedule: async ({ state, commit }) => {
    const id = state.store.schedule_set_id;
    if (!id) return;

    try {
      const { schedule } = await companyAPI.getScheduleById({ id });
      commit('_setSchedule', schedule);
    } catch (error) {
      console.error(error);
    }
  },

  closeStoreBox: async ({ state, dispatch }) => {
    const { id } = state.store;

    try {
      await companyAPI.closeStoreBox({ id });
      dispatch('getBoxState');
    } catch (error) {
      console.error(error);
    }
  },

  openStoreBox: async ({ state, dispatch }) => {
    const { id } = state.store;

    try {
      await companyAPI.openStoreBox({ id });
      dispatch('getBoxState');
    } catch (error) {
      console.error(error);
    }
  },
  getPopularItems: async ({ commit }, { interval, popularItemsOrder }) => {
    if (!interval) return;

    const payload = buildPayload(interval, popularItemsOrder);

    const { items: popularItems } = await companyAPI.getPopularItems(payload);
    commit('_setPopularItems', popularItems);
  },

  getAllStatistics: async ({ dispatch, commit }, { interval, popularItemsOrder }) => {
    const payload = buildPayload(interval, popularItemsOrder);
    try {
      await dispatch('getPopularItems', { interval, popularItemsOrder });
      const statistic = await companyAPI.getStatistics(payload);

      commit('_setStatistic', { ...statistic, mode: payload.mode });

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  setDayInterval: ({ commit }, data) => {
    commit('_setDayInterval', data);
  },

  setAdvSideBar: ({ commit }, open) => {
    commit('_setAdvSideBar', open);
  },

  updateStore: ({ commit }, store) => {
    commit('_setStore', store);
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setLoading: (state, value) => {
    state.loading = value;
  },

  _setStore: (state, store) => {
    state.store = store;
  },

  _setParty: (state, party) => {
    state.party = party;
  },

  _setGeoList: (state, list) => {
    state.geoList = list;
  },

  _setSchedule: (state, schedule = []) => {
    state.schedule = schedule;
  },

  _setBoxState: (state, boxState) => {
    state.boxState = boxState;
  },

  _setCoffeeState: (state, boxState) => {
    state.coffeeMachineState = boxState;
  },

  _setStatistic: (state, statistic) => {
    if (statistic.items.length !== 1) {
      state.statistic = statistic;
      return;
    }
    const [singleItem] = statistic.items;
    const emptyItem = { ...singleItem, avg_amount: '0', total_amount: '0' };

    state.statistic = { ...statistic, items: [emptyItem, singleItem] };
  },

  _setPopularItems: (state, items) => {
    state.popularItems = items;
  },

  _setDayInterval: (state, { interval, shortcut }) => {
    state.dayInterval.range = interval;
    state.dayInterval.shortcut = shortcut;
  },

  _setAdvSideBar: (state, open) => {
    state.isAdvSideBarOpen = open;
  },

  _setBoxNoticeState: (state, { notice }) => {
    state.boxState = { ...state.boxState, notice };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
