import { api, bus } from 'shared/core';

class SearchAPI {
  constructor() {
    Object.getOwnPropertyNames(this).forEach((fnName) => {
      const fn = this[fnName];

      this[fnName] = async (...props) => {
        const { errorOptions = {} } =
          props.find((p) => typeof p === 'object' && p && !(p instanceof Array)) || {};

        return fn(...props)
          .then((r) => r)
          .catch((error) => {
            bus.emit('core$notifications/add', { error, ...errorOptions });
            return Promise.reject(error);
          });
      };
    });
  }

  getAddress = (opts = {}) => {
    const { q, limit = 10 } = opts;

    return api.endpoints.business.company.get('dashboard/utils/get-address', {
      q,
      limit,
    });
  };
}

export const searchAPI = new SearchAPI();
