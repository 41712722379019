export default [
  {
    path: 'discounts',
    component: () => import('@/apps/discounts/views/Discounts'),
    children: [
      {
        path: '',
        name: 'discounts$main',
        component: () => import('@/apps/discounts/views/DiscountList/DiscountList'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'discounts',
              name: 'discounts$main',
            },
          ],
        },
      },
      {
        path: 'assign',
        props: true,
        name: 'discounts$assign',
        component: () => import('@/apps/discounts/views/DiscoutAssign/DiscoutAssign'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'discounts',
              name: 'discounts$main',
            },
            {
              label: 'discount_assign',
              name: '',
            },
          ],
        },
      },
      {
        path: 'discount-group/new',
        name: 'discounts$create-discount-group',
        component: () => import('@/apps/discounts/views/DiscountGroup/DiscountGroup'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'discounts',
              name: 'discounts$main',
            },
            {
              label: 'adding_of_discount_group',
              name: '',
            },
          ],
        },
      },
      {
        path: 'discount-group/:id',
        props: true,
        name: 'discounts$update-discount-group',
        component: () => import('@/apps/discounts/views/DiscountGroup/DiscountGroup'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'discounts',
              name: 'discounts$main',
            },
            {
              label: 'editing_of_discount_group',
              name: '',
            },
          ],
        },
      },
      {
        path: 'discount-group/:group_id/new',
        props: true,
        name: 'discounts$create-discount',
        component: () => import('@/apps/discounts/views/Discount/Discount'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'discounts',
              name: 'discounts$main',
            },
            {
              label: 'get:discountGroupId',
              name: '',
            },
            {
              label: 'adding_of_discount',
              name: '',
            },
          ],
        },
      },
      {
        path: 'discount-group/:group_id/:id',
        props: true,
        name: 'discounts$update-discount',
        component: () => import('@/apps/discounts/views/Discount/Discount'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'discounts',
              name: 'discounts$main',
            },
            {
              label: 'get:discountGroupId',
              name: '',
            },
            {
              label: 'get:discountId',
              name: '',
            },
          ],
        },
      },
    ],
  },
];
