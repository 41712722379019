import { bus } from 'shared/core';
import store from '@/core/store';

class LoaderEvent {
  init() {
    bus.on('shared$http/request', this.onHttpRequest);
    bus.on('shared$http/response', this.onHttpResponse);
  }

  onHttpRequest = () => {
    store.dispatch('core$loader/start');
  };

  onHttpResponse = () => {
    store.dispatch('core$loader/stop');
  };
}

export default new LoaderEvent();
