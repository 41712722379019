const initialState = () => ({});

const state = initialState();

const getters = {};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
