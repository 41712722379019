export default [
  {
    path: 'mailings',
    component: () => import('@/apps/mailings/views/Entry'),
    children: [
      {
        path: '',
        name: 'mailings$main',
        component: () => import('@/apps/mailings/views/Mailings/Mailings'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'mailings',
              name: 'mailings$main',
            },
          ],
        },
      },
      {
        path: 'create',
        name: 'mailings$create',
        component: () => import('@/apps/mailings/views/Mailing/Mailing'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'mailings',
              name: 'mailings$main',
            },
            {
              label: 'mailing_new',
              name: '',
            },
          ],
        },
      },
      {
        path: 'edit/:id',
        name: 'mailings$update',
        props: true,
        component: () => import('@/apps/mailings/views/Mailing/Mailing'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'mailings',
              name: 'mailings$main',
            },
            {
              label: 'mailing_edit',
              name: '',
            },
          ],
        },
      },
      {
        path: 'payment/:id',
        name: 'mailings$payment',
        props: true,
        component: () => import('@/apps/mailings/views/Payment/Payment'),
      },
    ],
  },
];
