import { api, bus } from 'shared/core';

class CompanyAPI {
  //// COMPANY

  constructor() {
    Object.getOwnPropertyNames(this).forEach((fnName) => {
      const fn = this[fnName];

      this[fnName] = async (...props) => {
        const { errorOptions = {} } =
          props.find((p) => typeof p === 'object' && p && !(p instanceof Array)) || {};

        return fn(...props)
          .then((r) => r)
          .catch((error) => {
            bus.emit('core$notifications/add', { error, ...errorOptions });
            return Promise.reject(error);
          });
      };
    });
  }

  getSelfCompany = () => {
    return api.endpoints.business.company.get('dashboard/company/get');
  };

  updateSelfCompany = (opts = {}) => {
    const { name = '', country_code } = opts;
    return api.endpoints.business.company.post('dashboard/company/update', {
      name,
      ...(country_code && { country_code }),
    });
  };

  //// CATALOG

  /**
   * Создать каталог
   * @param {{name: string}} opts
   */
  createCatalog = (opts = {}) => {
    let { name, barcode_prefix_2, currency_code } = opts;
    if (!currency_code) {
      IS_DEV && console.warn('Дополнено поле currency_code = RUB потому-что с бека пришла дич!');
      currency_code = 'RUB';
    }

    return api.endpoints.business.company.post('dashboard/catalog/create', {
      name,
      barcode_prefix_2,
      currency_code,
    });
  };

  duplicateCatalog = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/catalog/duplicate', { id });
  };

  /**
   * Получить каталог по ID
   * @param {{id: number}} opts
   */
  getCatalogById = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/catalog/get-by-id', { id });
  };

  getCatalogStatsById = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('/dashboard/catalog/get-stats', { id });
  };

  deleteCatalogById = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/catalog/delete', { id });
  };

  /**
   * Получить список каталогов
   * @param {{page?: number, limit?: number, q?: string}} opts
   */
  getCatalogList = (opts = {}) => {
    const { page = 1, limit = 100, order, q } = opts;
    return api.endpoints.business.company.get('dashboard/catalog/get-list', {
      page,
      limit,
      order,
      q,
    });
  };

  /**
   * Обновить каталог
   * @param {{id: number, name: string}} opts
   */
  updateCatalog = (opts = {}) => {
    let { id, name, barcode_prefix_2, currency_code } = opts;
    if (!currency_code) {
      IS_DEV && console.warn('Дополнено поле currency_code = RUB потому-что с бека пришла дич!');
      currency_code = 'RUB';
    }
    return api.endpoints.business.company.post('dashboard/catalog/update', {
      id,
      name,
      barcode_prefix_2,
      currency_code,
    });
  };

  /**
   * Создать планограму
   * @param {{company_id: number, name: string}} opts
   */
  createPlanogram = (opts = {}) => {
    const { company_id, name } = opts;
    return api.endpoints.business.company.post('dashboard/catalog/planogram/create', {
      company_id,
      name,
    });
  };

  /**
   * Обновить планограму
   * @param {{name: string}} opts
   */
  updatePlanogram = (opts = {}) => {
    const { name, id } = opts;
    return api.endpoints.business.company.post('dashboard/catalog/planogram/update', {
      id,
      name,
    });
  };

  /**
   * Создать позиции для планограмы
   * @param {{items: Array<object>, planogram_id: number}} opts
   */
  createPlanogramItems = (opts = {}) => {
    const { planogram_id, items = [] } = opts;
    return api.endpoints.business.company.post('dashboard/catalog/planogram/item/create', {
      planogram_id,
      items,
    });
  };

  /**
   * Обновить позиции для планограмы
   * @param {{items: Array<object>, planogram_id: number}} opts
   */
  updatePlanogramItems = (opts = {}) => {
    const { planogram_id, items = [] } = opts;
    return api.endpoints.business.company.post('dashboard/catalog/planogram/item/update', {
      planogram_id,
      items,
    });
  };

  /**
   * Получить список планограм
   */
  getPlanogramList = () => {
    return api.endpoints.business.company.get('dashboard/catalog/planogram/get-list');
  };

  /**
   * Загрузить фото планограмы
   * @param {{id: number, file: string}} opts
   */
  uploadPlanogramPhoto = (opts = {}) => {
    const { file, id } = opts;

    return api.endpoints.business.company.post('dashboard/catalog/planogram/photo/upload', {
      file,
      id,
    });
  };

  //// EMPLOYEE

  /**
   * Создать сотрудника
   * @param {{
   *  store_id: number,
      phone: string,
      email: string,
      first_name: string,
      last_name: string,
      post?: string,
      role_administrator_status?: 0 | 1,
      role_checker_status?: 0 | 1,
      role_courier_status?: 0 | 1
    }} opts
   */
  createEmployee = (opts = {}) => {
    const {
      store_id,
      phone,
      email,
      first_name,
      last_name,
      post,
      role_administrator_status,
      role_checker_status,
      role_courier_status,
    } = opts;

    return api.endpoints.business.company.post('dashboard/employee/create', {
      store_id,
      phone,
      email,
      first_name,
      last_name,
      post,
      role_administrator_status,
      role_checker_status,
      role_courier_status,
    });
  };

  saveEmployees = (opts = {}) => {
    const { id, employees_ids } = opts;
    return api.endpoints.business.company.post('dashboard/store/employee/save', {
      employees_ids,
      id,
    });
  };

  /**
   * Получить сотрудника по ID
   * @param {{id: number}} opts
   */
  getEmployeeById = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/employee/get-by-id', { id });
  };

  /**
   * Удалить сотрудника
   * @param {{id: number}} opts
   */
  deleteEmployee = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/employee/delete', { id });
  };

  /**
   * Получить список сотрудников
   * @param {{page?: number, limit?: number, q?: string, order: object}} opts
   */
  getEmployeeList = (opts = {}) => {
    const { page = 1, limit = 20, q, order } = opts;

    return api.endpoints.business.company.get('dashboard/employee/get-list', {
      page,
      limit,
      order,
      q,
    });
  };

  /**
   * Обновить данные о сотруднике
   * @param {{
   *  id: number,
      store_id?: number,
      phone?: string,
      email?: string,
      first_name?: string,
      last_name?: string,
      post?: string,
      role_administrator_status?: 0 | 1,
      role_checker_status?: 0 | 1,
      role_courier_status?: 0 | 1
   * }} opts
   */
  updateEmployee = (opts = {}) => {
    const {
      id,
      store_id,
      phone,
      email,
      first_name,
      last_name,
      post,
      role_administrator_status,
      role_checker_status,
      role_courier_status,
    } = opts;

    return api.endpoints.business.company.post('dashboard/employee/update', {
      id,
      store_id,
      phone,
      email,
      first_name,
      last_name,
      post,
      role_administrator_status,
      role_checker_status,
      role_courier_status,
    });
  };

  //// EXCHANGE

  /**
   * Создать обмен
   * @param {{
      "access_dir_name": string,
      "access_file_name": string,
      "access_login": string,
      "access_password": string,
      "access_token": string,
      "access_url": string,
      "cron_export_time": string,
      "cron_import_time": string,
      "external_company_id": string,
      "external_price_type_id": string,
      "external_price_type_id_by_name": string,
      "external_service": string,
      "external_service_version": string,
      "external_store_id": string,
      "extra_brand_name": string,
      "mode": "scan_n_go",
      "mode_barcode_is_optional": 0 | 1,
      "mode_journal_entry": 0 | 1,
      "status_export_orders": 0 | 1,
      "status_import_catalog": 0 | 1
    }} opts
   */
  createExchange = (opts = {}) => {
    const {
      access_dir_name,
      access_file_name,
      access_login,
      access_password,
      access_token,
      access_url,
      cron_export_time,
      cron_import_time,
      external_company_id,
      external_price_type_id,
      external_price_type_id_by_name,
      external_service,
      external_service_version,
      external_store_id,
      extra_brand_name,
      mode,
      mode_barcode_is_optional,
      mode_journal_entry,
      status_export_orders,
      status_import_catalog,
    } = opts;

    return api.endpoints.business.company.post('dashboard/exchange/settings/create', {
      access_dir_name,
      access_file_name,
      access_login,
      access_password,
      access_token,
      access_url,
      cron_export_time,
      cron_import_time,
      external_company_id,
      external_price_type_id,
      external_price_type_id_by_name,
      external_service,
      external_service_version,
      external_store_id,
      extra_brand_name,
      mode,
      mode_barcode_is_optional,
      mode_journal_entry,
      status_export_orders,
      status_import_catalog,
    });
  };

  /**
   * Получить список сервисов обмена
   */
  getExchangeServiceList = () => {
    return api.endpoints.business.company.get('dashboard/exchange/settings/service/get-list');
  };

  /**
   * Получить требуемые поля для определенного сервиса
   * @param {{service: string}} opts
   */
  getExchangeServiceRequiredFields = (opts = {}) => {
    const { service } = opts;
    return api.endpoints.business.company.get(
      'dashboard/exchange/settings/service/get-required-fields',
      {
        service,
      },
    );
  };

  //// PARTY

  /**
   * Получить список реквизитов
   */
  getExchangePartyList = (opts = {}) => {
    const { page = 1, limit = 20, type, q = '' } = opts;
    return api.endpoints.business.company.get('dashboard/party/get-list', { page, limit, type, q });
  };

  getPartyAccountList = (opts = {}) => {
    const { page = 1, limit = 20, party_id } = opts;
    return api.endpoints.business.company.get('dashboard/party/account/get-list', {
      page,
      limit,
      party_id,
    });
  };

  getPartyAccountItem = (opts = {}) => {
    const { party_id } = opts;
    return api.endpoints.business.company.get('dashboard/party/account/get-first', { party_id });
  };

  /**
 * Получить реквизиты по id
 * @param {{
    id: number,
  }} opts
*/
  getPartyById = (opts = {}) => {
    const { id } = opts;

    return api.endpoints.business.company.get('dashboard/party/get-by-id', { id });
  };

  /**
 * Добавить реквизиты
 * @param {{
    actual_address: string,
    actuality_date: string,
    address: string,
    capital: string,
    emails: string,
    founders: string,
    fts_code: string,
    fts_name: string,
    inn: string,
    kpp: string,
    liquidation_date: string,
    manager_name: string,
    manager_post: string,
    name_full: string,
    name_full_with_opf: string,
    name_latin: string,
    name_short: string,
    name_short_with_opf: string,
    ogrn: string,
    orgn_date: string,
    okopf_code: string,
    okopf_name_full: string,
    okopf_name_short: string,
    okopf_version: string,
    okved_code: string,
    okved_name: string,
    okved_version: string,
    phones: string,
    postal_box: string,
    postal_code: string,
    registration_date: string,
    status: string,
    taxation_system: string,
    type: string
  }} opts
*/

  createParty = (opts = {}) => {
    const {
      actual_address = '',
      actuality_date = '',
      address = '',
      capital = '',
      emails = '',
      founders = '',
      fts_code = '',
      fts_name = '',
      inn = '',
      kpp = '',
      liquidation_date = '',
      manager_name = '',
      manager_post = '',
      name_full = '',
      name_full_with_opf = '',
      name_latin = '',
      name_short = '',
      name_short_with_opf = '',
      ogrn = '',
      orgn_date = '',
      okopf_code = '',
      okopf_name_full = '',
      okopf_name_short = '',
      okopf_version = '',
      okved_code = '',
      okved_name = '',
      okved_version = '',
      phones = '',
      postal_box = '',
      postal_code = '',
      registration_date = '',
      status = '',
      taxation_system = '',
      type = '',
    } = opts;

    return api.endpoints.business.company.post('dashboard/party/create', {
      actual_address,
      actuality_date,
      address,
      capital,
      emails,
      founders,
      fts_code,
      fts_name,
      inn,
      kpp,
      liquidation_date,
      manager_name,
      manager_post,
      name_full,
      name_full_with_opf,
      name_latin,
      name_short,
      name_short_with_opf,
      ogrn,
      orgn_date,
      okopf_code,
      okopf_name_full,
      okopf_name_short,
      okopf_version,
      okved_code,
      okved_name,
      okved_version,
      phones,
      postal_box,
      postal_code,
      registration_date,
      status,
      taxation_system,
      type,
    });
  };

  updateParty = (opts = {}) => {
    const {
      actual_address = '',
      actuality_date = '',
      address = '',
      capital = '',
      emails = '',
      founders = '',
      fts_code = '',
      fts_name = '',
      inn = '',
      kpp = '',
      liquidation_date = '',
      manager_name = '',
      manager_post = '',
      name_full = '',
      name_full_with_opf = '',
      name_latin = '',
      name_short = '',
      name_short_with_opf = '',
      ogrn = '',
      orgn_date = '',
      okopf_code = '',
      okopf_name_full = '',
      okopf_name_short = '',
      okopf_version = '',
      okved_code = '',
      okved_name = '',
      okved_version = '',
      phones = '',
      postal_box = '',
      postal_code = '',
      registration_date = '',
      status = '',
      taxation_system = '',
      type = '',
      id,
    } = opts;

    return api.endpoints.business.company.post('dashboard/party/update', {
      actual_address,
      actuality_date,
      address,
      capital,
      emails,
      founders,
      fts_code,
      fts_name,
      inn,
      kpp,
      liquidation_date,
      manager_name,
      manager_post,
      name_full,
      name_full_with_opf,
      name_latin,
      name_short,
      name_short_with_opf,
      ogrn,
      orgn_date,
      okopf_code,
      okopf_name_full,
      okopf_name_short,
      okopf_version,
      okved_code,
      okved_name,
      okved_version,
      phones,
      postal_box,
      postal_code,
      registration_date,
      status,
      taxation_system,
      type,
      id,
    });
  };

  createPartialParty = (opts = {}) => {
    const {
      secure3d,
      non_secure3d,
      swift_bic,
      contact_name,
      registration_number,
      bank_name_full,
      name_full,
    } = opts;

    return api.endpoints.business.company.post('dashboard/party/create-partial', {
      secure3d,
      non_secure3d,
      swift_bic,
      contact_name,
      registration_number,
      bank_name_full,
      name_full,
    });
  };

  updatePartialParty = (opts = {}) => {
    const {
      secure3d,
      non_secure3d,
      swift_bic,
      contact_name,
      registration_number,
      id,
      bank_name_full,
      name_full,
    } = opts;

    return api.endpoints.business.company.post('dashboard/party/update-partial', {
      secure3d,
      non_secure3d,
      swift_bic,
      contact_name,
      registration_number,
      id,
      bank_name_full,
      name_full,
    });
  };

  /**
 * Добавить расчетный счет
 * @param {{
  * party_id: number,
  * account: string,
  * bank_bic: string,
  * bank_correspondent_account: string,
  * bank_name_payment: string
  }} opts
*/

  createAccount = (opts = {}) => {
    const { party_id, account, bank_bic, bank_correspondent_account, bank_name_payment } = opts;

    return api.endpoints.business.company.post('dashboard/party/account/create', {
      party_id,
      account,
      bank_bic,
      bank_correspondent_account,
      bank_name_payment,
    });
  };

  /**
 * Загрузить файл
 * @param {{
  * party_id: number,
  * type: string,
  * file: string
  }} opts
*/

  uploadFile = (opts = {}) => {
    const { party_id, type, file } = opts;

    return api.endpoints.business.company.post('dashboard/party/file/upload', {
      party_id,
      type,
      file,
    });
  };

  /** Создать документ физ лица
   *
   * @param {{
    party_id: number,
    authority_code: string,
    authority_name: string,
    citizenship: string,
    name_first: string,
    name_last: string,
    name_middle: string,
    number: string,
    registration_place: string,
    series: string,
    birth_place: string,
    birth_date: string,
    issue_date: string,
    files_ids: Array,
  }} opts
 */
  createDoc = (opts = {}) => {
    const {
      party_id,
      authority_code,
      authority_name,
      citizenship,
      name_first,
      name_last,
      name_middle,
      number,
      registration_place,
      series,
      birth_place,
      birth_date,
      issue_date,
      files_ids,
    } = opts;

    return api.endpoints.business.company.post('dashboard/party/doc-2/create', {
      party_id,
      authority_code,
      authority_name,
      citizenship,
      name_first,
      name_last,
      name_middle,
      number,
      registration_place,
      series,
      birth_place,
      birth_date,
      issue_date,
      files_ids,
    });
  };

  sendDoc = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/party/send-doc', { id });
  };

  getAvailableTaxSystems = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/party/get-available-tax-systems');
  };

  createInternationalParty = (opts = {}) => {
    const { registration_number, country_code } = opts;
    return api.endpoints.business.company.post('dashboard/party/create-international', {
      registration_number,
      country_code,
    });
  };

  bindPartyToUpdate = (opts = {}) => {
    const { new_id, old_id } = opts;

    return api.endpoints.business.company.post('dashboard/party/bind-update', {
      new_id,
      old_id,
    });
  };

  //// STORE_GROUP

  /**
   * Создать торговую точку
   * @param {{
    name: string,
    address: string,
    coords_lng: number,
    coords_lat: number,
    type: string,
    status: number
  }} opts
 */
  createStoreGroup = (opts = {}) => {
    const { name, address, coords_lng, coords_lat, type, status = 1, address_floor } = opts;

    return api.endpoints.business.company.post('dashboard/store-group/create', {
      name,
      address,
      coords_lng,
      coords_lat,
      type,
      status,
      address_floor,
    });
  };

  /**
   * Создать магазин
   * @param {{
   *  name: string,
      address: string,
      coords_lat: number,
      coords_lng: number,
      extended_type: string,
      min_distance_to_open?: number,
      phones?: string,
      schedule?: string,
      schedule_set_id?: number,
      company_brand_id?: number,
      store_group_id?: number,
      text?: string,
      is_pickup_enabled?: number
      address_floor: string
    }} opts
   */
  createStore = (opts = {}) => {
    const {
      name,
      address,
      coords_lat,
      coords_lng,
      extended_type,
      min_distance_to_open,
      phones,
      schedule,
      schedule_set_id,
      company_brand_id,
      store_group_id,
      text,
      is_pickup_enabled,
      address_floor,
      external_id,
      timezone_name,
      is_closed_area,
    } = opts;

    return api.endpoints.business.company.post('dashboard/store/create', {
      name,
      address,
      coords_lat,
      coords_lng,
      extended_type,
      min_distance_to_open,
      phones,
      schedule,
      schedule_set_id,
      company_brand_id,
      store_group_id,
      text,
      is_pickup_enabled,
      address_floor,
      external_id,
      timezone_name,
      is_closed_area,
    });
  };
  createStoreCoffee = (opts = {}) => {
    const {
      name,
      address,
      coords_lat,
      coords_lng,
      extended_type,
      min_distance_to_open,
      phones,
      schedule,
      schedule_set_id,
      company_brand_id,
      store_group_id,
      text,
      is_pickup_enabled,
      address_floor,
      external_id,
      timezone_name,
      catalog_id,
      is_closed_area,
    } = opts;

    return api.endpoints.business.company.post('dashboard/store/coffee-machine/create', {
      name,
      address,
      coords_lat,
      coords_lng,
      extended_type,
      min_distance_to_open,
      phones,
      schedule,
      schedule_set_id,
      company_brand_id,
      store_group_id,
      text,
      is_pickup_enabled,
      address_floor,
      external_id,
      timezone_name,
      catalog_id,
      is_closed_area,
    });
  };

  getStoreAcceptedTypes = () => {
    return api.endpoints.business.company.get('dashboard/store/get-accepted-types');
  };

  deleteStore = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/store/delete', { id });
  };

  activateStore = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/store/activate', { id });
  };

  /**
   * Обновить магазин
   * @param {{
    *  id: number,
    *  name?: string,
    address?: string,
    coords_lat?: number,
    coords_lng?: number,
    extended_type?: string,
    min_distance_to_open?: number,
    phones?: string,
    schedule?: string,
    schedule_set_id?: number,
    company_brand_id?: number,
    store_group_id?: number,
    text?: string,
    is_pickup_enabled?: number,
    planogram_id?: number,
    address_floor: string,
    external_id: string,
  }} opts
 */

  updateStore = (opts = {}) => {
    const {
      id,
      name,
      address,
      coords_lat,
      coords_lng,
      extended_type,
      min_distance_to_open,
      phones,
      schedule,
      schedule_set_id,
      company_brand_id,
      store_group_id,
      catalog_id,
      planogram_id,
      text,
      is_pickup_enabled,
      address_floor,
      discount_set_id,
      external_id,
      timezone_name,
      is_closed_area,
    } = opts;

    return api.endpoints.business.company.post('dashboard/store/update', {
      id,
      name,
      address,
      coords_lat,
      coords_lng,
      extended_type,
      min_distance_to_open,
      phones,
      schedule,
      schedule_set_id,
      company_brand_id,
      store_group_id,
      text,
      is_pickup_enabled,
      catalog_id,
      planogram_id,
      address_floor,
      discount_set_id,
      external_id,
      timezone_name,
      is_closed_area,
    });
  };
  updateStoreCoffee = (opts = {}) => {
    const {
      id,
      name,
      address,
      coords_lat,
      coords_lng,
      extended_type,
      min_distance_to_open,
      schedule,
      schedule_set_id,
      company_brand_id,
      store_group_id,
      catalog_id,
      planogram_id,
      text,
      is_pickup_enabled,
      address_floor,
      discount_set_id,
      external_id,
      timezone_name,
      is_closed_area,
    } = opts;

    return api.endpoints.business.company.post('dashboard/store/coffee-machine/update', {
      id,
      name,
      address,
      coords_lat,
      coords_lng,
      extended_type,
      min_distance_to_open,
      schedule,
      schedule_set_id,
      company_brand_id,
      store_group_id,
      text,
      is_pickup_enabled,
      catalog_id,
      planogram_id,
      address_floor,
      discount_set_id,
      external_id,
      timezone_name,
      is_closed_area,
    });
  };

  getCardLink = (opts = {}) => {
    const { id, phone } = opts;
    return api.endpoints.business.company.post('dashboard/store/card/get-link', { id, phone });
  };

  getCardByStoreId = (opts = {}) => {
    const { store_id } = opts;
    return api.endpoints.business.company.get('dashboard/store/card/get-by-store', { store_id });
  };

  bindInnToCard = (opts = {}) => {
    const { store_id, party_inn } = opts;
    return api.endpoints.business.company.post('dashboard/store/card/update', {
      store_id,
      party_inn,
    });
  };

  /**
   * Обновить торговую точку
   * @param {{
    id: number,
    name: string,
    type: string,
    address: string,
    coords_lng: number,
    coords_lat: number,
    status: number
  }} opts
 */
  updateStoreGroup = (opts = {}) => {
    const {
      id,
      name,
      address,
      coords_lng,
      coords_lat,
      type,
      status = 1,
      company_brand_id,
      address_floor,
    } = opts;

    return api.endpoints.business.company.post('dashboard/store-group/update', {
      id,
      name,
      address,
      coords_lng,
      coords_lat,
      status,
      type,
      company_brand_id,
      address_floor,
    });
  };

  getStoreGroupById = (opts = {}) => {
    const { id } = opts;

    return api.endpoints.business.company.get('dashboard/store-group/get-by-id', { id });
  };

  getStoreById = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/store/get-by-id', { id });
  };

  selectEmployeeToStore = (opts = {}) => {
    const { id, employee_id } = opts;
    return api.endpoints.business.company.post('dashboard/store/employee/create', {
      id,
      employee_id,
    });
  };

  deselectEmployeeToStore = (opts = {}) => {
    const { id, employee_id } = opts;
    return api.endpoints.business.company.post('dashboard/store/employee/delete', {
      id,
      employee_id,
    });
  };

  createCompanyBrand = (opts = {}) => {
    const { name, background_color, text_color } = opts;

    return api.endpoints.business.company.post('dashboard/company-brand/create', {
      name,
      background_color,
      text_color,
    });
  };

  updateCompanyBrand = (opts = {}) => {
    const { id, name, background_color, text_color } = opts;

    return api.endpoints.business.company.post('dashboard/company-brand/update', {
      id,
      name,
      background_color,
      text_color,
    });
  };

  uploadCompanyBrandLogo = (opts = {}) => {
    const { id, file, type = '' } = opts;

    return api.endpoints.business.company.post('dashboard/company-brand/logo/upload', {
      id,
      file,
      type,
    });
  };

  deleteCompanyBrandLogo = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/company-brand/logo/delete', { id });
  };

  uploadStoreGroupPhotos = (opts = {}) => {
    const { id, files = [] } = opts;
    return api.endpoints.business.company.post('dashboard/store-group/photo/upload', { id, files });
  };

  //// SCHEDULE

  /**
   * Получить список режимов работы магазина
   * @param {{
    q: string
  }} opts
   */
  getScheduleList = (opts = {}) => {
    const { q = '' } = opts;

    return api.endpoints.business.company.get('dashboard/schedule/get-list', { q });
  };

  /**
   * Получить режим работы магазина по id
   * @param {{
    id: number
  }} opts
   */
  getScheduleById = (opts = {}) => {
    const { id } = opts;

    return api.endpoints.business.company.get('dashboard/schedule/get-by-id', { id });
  };

  /**
   * Создать новое расписание
   * @param {{
    name: string,
    is_template:number,
    status: number,
    text: string,
    days_of_week: object
  }} opts
   */
  createSchedule = (opts = {}) => {
    const { name, status = 1, days_of_week = {}, text, is_template = 0 } = opts;

    return api.endpoints.business.company.post('dashboard/schedule/create', {
      days_of_week,
      name,
      text,
      status,
      is_template,
    });
  };

  /**
   * Обновить расписание
   * @param {{
    id: number,
    name: string,
    is_template:number,
    status: number,
    text: string,
    days_of_week: object
  }} opts
   */
  updateSchedule = (opts = {}) => {
    const { name, status = 1, days_of_week = {}, text, is_template = 0, id } = opts;

    return api.endpoints.business.company.post('dashboard/schedule/update', {
      id,
      days_of_week,
      name,
      text,
      status,
      is_template,
    });
  };

  //// ITEM

  /**
   * Получить список товаров
   * @param {{
    store_id: number,
    catalog_id: number,
    category_id: number,
    order: Array<string>,
    page: number,
    limit: number,
    q: string,
   * }} opts
   */
  getItemList = (opts = {}) => {
    const { store_id, catalog_id, category_id, order, page = 1, limit = 25, q } = opts;

    return api.endpoints.business.company.get('dashboard/item/get-list', {
      store_id,
      catalog_id,
      category_id,
      order,
      page,
      limit,
      q,
    });
  };

  /**
   * Активировать коробку
   * @param {{id: number, code: string}} opts
   * @deprecated
   */
  activateStoreBox = (opts = {}) => {
    const { id, code } = opts;
    return api.endpoints.business.company.post('dashboard/store/box/activate', { id, code });
  };

  /**
   * Закрыть коробку
   * @param {{id: number}} opts
   */
  closeStoreBox = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/store/box/close', { id });
  };

  /**
   * Открыть коробку
   * @param {{id: number}} opts
   */
  openStoreBox = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/store/box/open', { id });
  };

  /**
   * Получить состояние коробки
   * @param {{id: number}} opts
   */
  getStoreBoxState = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/store/box/get-state', { id });
  };

  uploadExchangeFile = (opts = {}) => {
    const { catalog_id, columns, file } = opts;

    const payload = new FormData();
    payload.append('file', file, file.name);
    payload.append('catalog_id', String(catalog_id));
    payload.append('columns', JSON.stringify(columns));

    return api.endpoints.business.company.post('dashboard/item/file/upload', payload);
  };

  uploadExchangeFileWithBuffer = (opts = {}) => {
    const { catalog_id, columns, file, generate_barcode } = opts;

    const payload = new FormData();
    payload.append('file', file, file.name);
    payload.append('catalog_id', String(catalog_id));
    payload.append('columns', JSON.stringify(columns));
    payload.append('generate_barcode', JSON.stringify(generate_barcode));

    return api.endpoints.business.company.post('dashboard/item/file/upload-with-buffer', payload);
  };

  parseExchangeBufferChanks = (opts = {}) => {
    const { buffer_id, row_start, row_end } = opts;

    return api.endpoints.business.company.post('dashboard/item/file/buffer/parse-chunk', {
      buffer_id,
      row_start,
      row_end,
    });
  };

  parseExchangeBufferApprove = (opts = {}) => {
    const { buffer_id } = opts;
    return api.endpoints.business.company.post('dashboard/item/file/buffer/approve', {
      buffer_id,
    });
  };

  parseExchangeBufferDelete = (opts = {}) => {
    const { buffer_id } = opts;
    return api.endpoints.business.company.post('dashboard/item/file/buffer/delete', {
      buffer_id,
    });
  };

  getExchangeFileFields = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/item/file/get-fields');
  };

  getItemDiscountFields = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/item/discount/get-fields');
  };

  getExchangeServiceFields = (opts = {}) => {
    const { service } = opts;

    return api.endpoints.business.company.get(
      'dashboard/exchange/settings/service/get-required-fields',
      { service },
    );
  };

  getExchangeAvailableServices = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/exchange/settings/service/get-list');
  };

  createExchangeSettings = (opts = {}) => {
    return api.endpoints.business.company.post('dashboard/exchange/settings/create', opts);
  };

  updateExchangeSettings = (opts = {}) => {
    return api.endpoints.business.company.post('dashboard/exchange/settings/update', opts);
  };

  testExchangeSettings = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/exchange/settings/test', { id });
  };

  /**
   * Получить документ по типу
   */
  getDocsByType = (opts = {}) => {
    const { type } = opts;
    return api.endpoints.business.company.get('dashboard/docs/get-by-type', { type });
  };

  /**
   * Отправить запрос на подтверждение телефона при подписании документа
   */
  signDocs = (opts = {}) => {
    const { phone } = opts;

    return api.endpoints.business.company.post('dashboard/docs/send-message', {
      phone,
    });
  };

  /**
   * Подписать документ
   */
  signDocsConfirm = (opts = {}) => {
    const { id, confirmation_code, key } = opts;

    return api.endpoints.business.company.post('dashboard/docs/confirm', {
      id,
      confirmation_code,
      key,
    });
  };

  setDocSigned(opts = {}) {
    const { legal_document_id } = opts;
    return api.endpoints.business.company.post('dashboard/utils/set-doc-signed', {
      id: legal_document_id,
    });
  }

  //// DELIVERY

  /**
   * Создание формы доставки
   * @param {{
    company_id: number,
    external_service: string,
    name: string,
   * }} opts
   */
  createService = (opts = {}) => {
    const { company_id, external_service, name } = opts;

    return api.endpoints.business.company.post('dashboard/delivery/service/create', {
      company_id,
      external_service,
      name,
    });
  };

  /**
   * Обновление данных формы доставки
   * @param {{
    id: number,
    company_id: number,
    external_service: string,
    name: string,
   * }} opts
   */
  updateService = (opts = {}) => {
    const { company_id, external_service, name, id } = opts;

    return api.endpoints.business.company.post('dashboard/delivery/service/update', {
      id,
      company_id,
      external_service,
      name,
    });
  };

  /**
   * Удаление данных формы доставки
   * @param {{
    id: number,
   * }} opts
   */
  deleteService = (opts = {}) => {
    const { id } = opts;

    return api.endpoints.business.company.post('dashboard/delivery/service/delete', { id });
  };

  /**
   * Получить список форм доставки
   */
  getServiceList = () => {
    return api.endpoints.business.company.get('dashboard/delivery/service/get-list');
  };

  /**
   * Создание гео данных доставки
   * @param {{
    center_x: number,
    center_y: number,
    company_id: number,
    delivery_service_id: number,
    delivery_service_type: number,
    radius: number,
    store_id: number,
   * }} opts
   */
  createGeo = (opts = {}) => {
    const {
      center_x,
      center_y,
      company_id,
      delivery_service_type,
      radius,
      store_id,
      delivery_service_id,
    } = opts;

    return api.endpoints.business.company.post('dashboard/delivery/geo/create', {
      center_x,
      center_y,
      company_id,
      delivery_service_id,
      delivery_service_type,
      radius,
      store_id,
    });
  };

  /**
   * Список гео данных доставки
   * @param {{
    store_id: number,
   * }} opts
   */
  getGeoList = (opts = {}) => {
    const { store_id } = opts;

    return api.endpoints.business.company.get('dashboard/delivery/geo/get-list', { store_id });
  };

  hasDelivery = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/delivery/has-delivery');
  };

  /**
   * Удаление гео данных доставки
   * @param {{
    id: number,
   * }} opts
   */
  deleteGeo = (opts = {}) => {
    const { id } = opts;

    return api.endpoints.business.company.post('dashboard/delivery/geo/delete', { id });
  };

  /**
   * Обновление гео данных доставки
   * @param {{
    id: number,
    delivery_service_id: number,
    center_x: number,
    center_y: number,
    company_id: number,
    delivery_service_type: number,
    radius: number,
    store_id: number,
   * }} opts
   */
  updateGeo = (opts = {}) => {
    const {
      id,
      center_x,
      center_y,
      company_id,
      delivery_service_type,
      radius,
      store_id,
      delivery_service_id,
    } = opts;

    return api.endpoints.business.company.post('dashboard/delivery/geo/update', {
      id,
      center_x,
      center_y,
      company_id,
      delivery_service_type,
      radius,
      store_id,
      delivery_service_id,
    });
  };

  /**
   * Список тарифных сеток
   * @param {{
    store_id: number,
   * }} opts
   */
  getRateList = (opts = {}) => {
    const { store_id } = opts;

    return api.endpoints.business.company.get('dashboard/delivery/rate/get-list', { store_id });
  };

  /**
   * Создание тарифной сетки
   * @param {{
    store_id: number,
    delivery_service_type: number,
    rates: Array<object>,
   * }} opts
   */
  createRate = (opts = {}) => {
    const { store_id, delivery_service_type, delivery_service_id, rates } = opts;

    return api.endpoints.business.company.post('dashboard/delivery/rate/create', {
      delivery_service_type,
      delivery_service_id,
      store_id,
      rates,
    });
  };

  /**
   * Удаление тарифной сетки
   * @param {{
    id: number,
   * }} opts
   */
  deleteRate = (opts = {}) => {
    const { id } = opts;

    return api.endpoints.business.company.post('dashboard/delivery/rate/delete', { id });
  };

  /**
   * Обновление данных тарифной сетки
   * @param {{
    id: number,
    store_id: number,
    company_id: number,
    order_amount_min: number,
    order_amount_max: number,
    calculation_type: number,
    calculation_val: number,
    calculation_round_mode: number,
    calculation_round_precision: number,
    delivery_service_type: number,
   * }} opts
   */
  updateRate = (opts = {}) => {
    const {
      id,
      store_id,
      company_id,
      order_amount_min,
      order_amount_max,
      calculation_type,
      calculation_val,
      calculation_round_mode,
      calculation_round_precision,
      delivery_service_type,
      delivery_service_id,
    } = opts;

    return api.endpoints.business.company.post('dashboard/delivery/rate/update', {
      id,
      store_id,
      company_id,
      order_amount_min,
      order_amount_max,
      calculation_type,
      calculation_val,
      calculation_round_mode,
      calculation_round_precision,
      delivery_service_type,
      delivery_service_id,
    });
  };

  createCategory = (opts = {}) => {
    const { age_limit = 0, catalog_id, name, status = 0 } = opts;

    return api.endpoints.business.company.post('dashboard/category/create', {
      age_limit,
      catalog_id,
      name,
      status,
    });
  };

  getCategoryById = (opts = {}) => {
    const { id } = opts;

    return api.endpoints.business.company.get('dashboard/category/get-by-id', {
      id,
    });
  };

  applyDiscountToAllItemsInCategory = (opts = {}) => {
    const { category_id, discount, round_mode, round_precision, status, type } = opts;
    return api.endpoints.business.company.post('dashboard/category/discount/bulk-apply-items', {
      category_id,
      discount,
      round_mode,
      round_precision,
      status,
      type,
    });
  };

  createItem = (opts = {}) => {
    const {
      category_id,
      catalog_id,
      name,
      barcode,
      barcodes,
      article,
      price,
      text,
      unit_id,
      unit_dimension,
      age_limit,
      status,
      vat_rate,
      vat_mode,
      extra_code_type,
      heating_enabled,
      heating_duration,
    } = opts;

    return api.endpoints.business.company.post('dashboard/item/create', {
      category_id,
      catalog_id,
      name,
      barcode,
      barcodes,
      article,
      price,
      text,
      unit_id,
      unit_dimension,
      age_limit,
      status,
      vat_rate,
      vat_mode,
      extra_code_type,
      heating_enabled,
      heating_duration,
    });
  };

  addItemProps = (opts = {}) => {
    const { props, item_id } = opts;
    return api.endpoints.business.company.post('dashboard/item/prop/create', { props, item_id });
  };

  getItemQuantity = (opts = {}) => {
    const { item_id, order, page, limit } = opts;
    return api.endpoints.business.company.get('dashboard/item/price/get-list', {
      item_id,
      order,
      page,
      limit,
    });
  };

  getCategoryList = (opts = {}) => {
    const { order, q, catalog_id, store_id, page = 1, limit = 20 } = opts;

    return api.endpoints.business.company.get('dashboard/category/get-list', {
      ...(catalog_id && { catalog_id }),
      ...(store_id && { store_id }),
      q,
      order,
      page,
      limit,
    });
  };

  getCategoryDiscountById = (opts = {}) => {
    const { category_id } = opts;
    return api.endpoints.business.company.get('dashboard/category/discount/get', { category_id });
  };

  setCategoryDiscount = (opts = {}) => {
    const { category_id, round_mode, round_precision, discount, status, type } = opts;
    return api.endpoints.business.company.post('dashboard/category/discount/save', {
      category_id,
      round_mode,
      round_precision,
      discount,
      status,
      type,
    });
  };

  getStoreList = (opts = {}) => {
    const { limit, page, q = '', order = {}, type, catalog_id, party_id } = opts;

    return api.endpoints.business.company.get('dashboard/store/get-list', {
      limit,
      page,
      order,
      q,
      type,
      catalog_id,
      party_id,
    });
  };

  getStoreGroupList = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/store-group/get-list', opts);
  };

  getCompanyBrandById = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/company-brand/get-by-id', opts);
  };

  getCompanyBrandList = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/company-brand/get-list', opts);
  };

  updateCategory = (opts = {}) => {
    const { id, age_limit, catalog_id, name, status } = opts;

    return api.endpoints.business.company.post('dashboard/category/update', {
      id,
      age_limit,
      catalog_id,
      name,
      status,
    });
  };

  deleteCategory = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/category/delete', { id });
  };

  uploadItemPhoto = (opts = {}) => {
    const { id, file } = opts;
    return api.endpoints.business.company.post('dashboard/item/photo/upload', { id, file });
  };

  deleteItemPhoto = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/item/photo/delete', { id });
  };

  deleteItems = (opts = {}) => {
    const { item_ids } = opts;
    return api.endpoints.business.company.post('dashboard/item/delete', { item_ids });
  };

  getStatistics = (opts = {}) => {
    const { date_start, date_end, status, mode, store_id } = opts;
    return api.endpoints.business.company.get('dashboard/reports/orders/get-statistics', {
      date_start,
      date_end,
      status,
      mode,
      store_id,
    });
  };

  getExtendedOrderListReport = (opts = {}) => {
    const { date_start, date_end, status, mode } = opts;
    return api.endpoints.business.company.get('dashboard/reports/orders/get-extended-list', {
      date_start,
      date_end,
      status,
      mode,
    });
  };

  /**
   * Получить список популярных товаров
   * @param {{
    store_id: number,
    date_start: string,
    date_end: string,
    status: string,
    mode: string
   * }} opts
   */
  getPopularItems = (opts = {}) => {
    const { store_id, date_start, date_end, status, mode, timestamp_func, order } = opts;

    return api.endpoints.business.company.get('dashboard/reports/items/get-popular-items', {
      store_id,
      date_start,
      date_end,
      status,
      mode,
      timestamp_func,
      order,
    });
  };

  getDocsList = (opts = {}) => {
    const { page = 1, limit = 20, q, order, list = 0 } = opts;

    return api.endpoints.business.company.get('dashboard/docs/get-list', {
      page,
      limit,
      order,
      q,
      list,
    });
  };

  getReportAboutOrders = (opts = {}) => {
    const {
      date_start,
      date_end,
      status,
      mode,
      store_id,
      format,
      page,
      order,
      timestamp_func,
      timezone,
      payment_service,
    } = opts;

    const payload = {
      date_start,
      date_end,
      status,
      mode,
      store_id,
      format,
      page,
      order,
      timestamp_func,
      timezone,
      payment_service,
    };

    if (!order) delete payload.order;
    if (!timestamp_func) delete payload.timestamp_func;
    if (!payment_service) delete payload.payment_service;

    return api.endpoints.business.company.get(
      'dashboard/reports/orders/get-default-report',
      payload,
    );
  };

  getReportAboutUniqueCustomers = (opts = {}) => {
    const { date_start, date_end, status, mode, store_id, format, page, order, timezone } = opts;

    const payload = {
      date_start,
      date_end,
      status,
      mode,
      store_id,
      format,
      page,
      order,
      timezone,
    };

    if (!order) delete payload.order;

    return api.endpoints.business.company.get('dashboard/reports/customers/get-unique', payload);
  };

  getReportAboutItems = (opts = {}) => {
    const {
      date_start,
      date_end,
      status,
      mode,
      store_id,
      format,
      page,
      order,
      timestamp_func,
      timezone,
    } = opts;

    const payload = {
      date_start,
      date_end,
      status,
      mode,
      store_id,
      format,
      page,
      order,
      timestamp_func,
      timezone,
    };

    if (!order) delete payload.order;

    return api.endpoints.business.company.get('dashboard/reports/items/get-popular-items', payload);
  };

  getReportAboutItem = (opts = {}) => {
    const {
      date_start,
      date_end,
      status,
      mode,
      store_id,
      format,
      page,
      order,
      timestamp_func,
      item_id,
    } = opts;

    const payload = {
      item_id,
      date_start,
      date_end,
      status,
      mode,
      store_id,
      format,
      page,
      order,
      timestamp_func,
    };

    if (!order) delete payload.order;

    return api.endpoints.business.company.get('dashboard/reports/items/get-by-id', payload);
  };

  getReportAboutDelivery = (opts = {}) => {
    const {
      store_id,
      status,
      timestamp_func,
      mode,
      delivery_service_type,
      limit,
      order,
      page,
      date_start,
      date_end,
    } = opts;

    return api.endpoints.business.company.get('dashboard/reports/orders/get-deliveries1-report', {
      store_id,
      status,
      timestamp_func,
      mode,
      delivery_service_type,
      limit,
      order,
      page,
      date_start,
      date_end,
    });
  };

  getReportSpecialOrder = (opts = {}) => {
    const {
      store_id,
      stores_ids,
      party_id,
      status,
      timestamp_func,
      mode,
      type,
      mode2,
      limit,
      order,
      page,
      date_start,
      date_end,
    } = opts;

    return api.endpoints.business.company.get('dashboard/reports/orders/get-special3-report', {
      store_id,
      stores_ids,
      party_id,
      status,
      timestamp_func,
      mode,
      type,
      mode2,
      limit,
      order,
      page,
      date_start,
      date_end,
    });
  };

  getReportPromoCodes = (opts = {}) => {
    const {
      store_id,
      stores_ids,
      party_id,
      status,
      timestamp_func,
      mode,
      type,
      limit,
      order,
      page,
      date_start,
      date_end,
    } = opts;

    return api.endpoints.business.company.get('dashboard/reports/orders/get-special4-report', {
      store_id,
      stores_ids,
      party_id,
      status,
      timestamp_func,
      mode,
      type,
      limit,
      order,
      page,
      date_start,
      date_end,
    });
  };

  downloadReport = (opts = {}) => {
    const { file_name, format, id } = opts;
    return api.endpoints.business.company.get(
      'dashboard/reports/download',
      { file_name, format, id },
      { linkOnly: true },
    );
  };

  getPlanogramById = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/catalog/planogram/get-by-id', { id });
  };

  getReportAboutCustomers = async (opts = {}) => {
    const {
      date_start,
      date_end,
      status,
      mode,
      store_id,
      format,
      page,
      order,
      customer_id,
      timestamp_func,
      timezone,
    } = opts;

    const payload = {
      date_start,
      date_end,
      status,
      mode,
      store_id,
      format,
      page,
      order,
      customer_id,
      timestamp_func,
      timezone,
    };

    if (!order) delete payload.order;

    return api.endpoints.business.company.get(
      'dashboard/reports/orders/get-with-customers',
      payload,
    );
  };

  getTradePointStatus = async (opts = {}) => {
    const { store_id } = opts;
    return api.endpoints.business.company.get('dashboard/utils/get-current-progress', { store_id });
  };

  getItemById = async (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/item/get-by-id', { id });
  };

  updateItemById = async (opts = {}) => {
    return api.endpoints.business.company.post('dashboard/item/update', opts);
  };

  getItemDiscountById = async (opts = {}) => {
    const { item_id } = opts;
    return api.endpoints.business.company.get('dashboard/item/discount/get', { item_id });
  };

  updateItemDiscount = async (opts = {}) => {
    const { item_id, discount, round_mode, round_precision, status, type } = opts;
    return api.endpoints.business.company.post('dashboard/item/discount/save', {
      item_id,
      discount,
      round_mode,
      round_precision,
      status,
      type,
    });
  };

  getSelfProfile = async (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/profile/get');
  };

  updateSelfProfile = async (opts = {}) => {
    const { profile } = opts;
    return api.endpoints.business.company.post('dashboard/profile/update', profile);
  };

  //// DISCOUNT

  getDiscountGroups = (opts = {}) => {
    const { page, limit } = opts;
    return api.endpoints.business.company.get('dashboard/discount-set/get-list', { page, limit });
  };

  getDiscountGroupById = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/discount-set/get-by-id', { id });
  };

  createDiscountGroup = (opts = {}) => {
    const { name, order, is_show_for_customers, name_for_customers, text, status } = opts;

    return api.endpoints.business.company.post('dashboard/discount-set/create', {
      name,
      order,
      is_show_for_customers,
      name_for_customers,
      text,
      status,
    });
  };

  createDiscount = (opts = {}) => {
    const {
      discount,
      discount_set_id,
      days_of_week,
      excluded_cost,
      round_mode,
      round_precision,
      status,
      type,
      use,
    } = opts;

    return api.endpoints.business.company.post('dashboard/discount/create', {
      discount,
      discount_set_id,
      days_of_week,
      excluded_cost,
      round_mode,
      round_precision,
      status,
      type,
      use,
    });
  };

  updateDiscount = (opts = {}) => {
    const {
      id,
      discount,
      discount_set_id,
      days_of_week,
      excluded_cost,
      round_mode,
      round_precision,
      status,
      type,
      use,
    } = opts;

    return api.endpoints.business.company.post('dashboard/discount/update', {
      id,
      discount,
      discount_set_id,
      days_of_week,
      excluded_cost,
      round_mode,
      round_precision,
      status,
      type,
      use,
    });
  };

  updateDiscountGroup = (opts = {}) => {
    const { id, name, order, is_show_for_customers, name_for_customers, text, status } = opts;

    return api.endpoints.business.company.post('dashboard/discount-set/update', {
      id,
      name,
      order,
      is_show_for_customers,
      name_for_customers,
      text,
      status,
    });
  };

  uploadDiscountGroupImage = (opts = {}) => {
    const { id, file } = opts;

    return api.endpoints.business.company.post('dashboard/discount-set/image/upload', { id, file });
  };

  deleteDiscountGroup = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/discount-set/delete', { id });
  };

  getDiscountById = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/discount/get-by-id', { id });
  };

  getDiscountsByGroupId = (opts = {}) => {
    const { id, page, limit, order } = opts;
    return api.endpoints.business.company.get('dashboard/discount/get-list', {
      id,
      page,
      limit,
      order,
    });
  };

  deleteDiscount = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/discount/delete', { id });
  };

  getCompanyBrandBackgrounds = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/company-brand/logo/get-presets');
  };

  //STICKERS
  getStickerSet = (opts = {}) => {
    return api.endpoints.business.company.post('dashboard/sticker-set/get-list');
  };

  createOrUpdateStickerSet = (opts = {}) => {
    const { manufacturer_name, manufacturing_address } = opts;
    return api.endpoints.business.company.post('dashboard/sticker-set/create', {
      manufacturer_name,
      manufacturing_address,
    });
  };

  // getGeneratedStickers = (opts = {}) => {
  //   const { id } = opts;
  //   return api.endpoints.business.company.post('dashboard/sticker-set/generate', { id });
  // };

  getStickerSetItemsList = (opts = {}) => {
    const { order, sticker_set_id } = opts;
    return api.endpoints.business.company.get('dashboard/sticker-set/item/get-list', {
      sticker_set_id,
      limit: 400,
      order,
      page: 1,
    });
  };

  addItemToStickerSet = (opts = {}) => {
    const { item_id } = opts;
    return api.endpoints.business.company.post('dashboard/sticker-set/item/create', { item_id });
  };

  deleteItemFromStickerSet = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/sticker-set/item/delete', { id });
  };

  updateItemInStickerSet = (opts = {}) => {
    const {
      id,
      item,
      item_id,
      manufacturing_date,
      manufacturing_shelf_life,
      quantity,
      sticker_set_id,
    } = opts;
    return api.endpoints.business.company.post('dashboard/sticker-set/item/update', {
      sticker_set_item_id: id,
      item,
      item_id,
      sticker_set_id,
      manufacturing_date,
      manufacturing_shelf_life,
      quantity,
    });
  };

  // PROMO

  getPromoQRcode = (opts = {}) => {
    const { id } = opts;
    const { access_token: token } = api.endpoints.business.company.getTokens();

    return api.endpoints.business.company.get(
      'dashboard/store/adv/qr',
      { id, token },
      { linkOnly: true },
    );
  };

  getPromoSticker = (opts = {}) => {
    const { id } = opts;
    const { access_token: token } = api.endpoints.business.company.getTokens();
    return api.endpoints.business.company.get(
      'dashboard/store/adv/sticker',
      { id, token },
      { linkOnly: true },
    );
  };
  getPromoHorizontalSticker = (opts = {}) => {
    const { id } = opts;
    const { access_token: token } = api.endpoints.business.company.getTokens();
    return api.endpoints.business.company.get(
      'dashboard/store/adv/sticker-horizontal',
      { id, token },
      { linkOnly: true },
    );
  };

  getPromoTableBanner = (opts = {}) => {
    const { id } = opts;
    const { access_token: token } = api.endpoints.business.company.getTokens();
    return api.endpoints.business.company.get(
      'dashboard/store/adv/table-banner',
      { id, token },
      { linkOnly: true },
    );
  };

  getMarketPlaceLink = (opts = {}) => {
    const { id } = opts;
    // const { access_token: token } = api.endpoints.business.company.getTokens();
    return api.endpoints.business.company.post('dashboard/store/get-link', { id });
  };

  //

  getOrdersByStore = (opts = {}) => {
    const { id, page, limit, date, order } = opts;

    return api.endpoints.business.company.get('dashboard/video/get-by-store', {
      id,
      page,
      limit,
      date,
      order,
    });
  };

  getVideoByOrder = (opts = {}) => {
    const { id, page, limit, date } = opts;
    return api.endpoints.business.company.get('dashboard/video/get-by-order', {
      id,
      page,
      limit,
      date,
    });
  };

  generateVideoLink = (opts = {}) => {
    const { video_id } = opts;
    const { access_token: token } = api.endpoints.business.company.getTokens();

    return api.endpoints.business.company.get(
      'dashboard/video/download',
      { video_id, token },
      { linkOnly: true },
    );
  };

  postClaimAboutOrder = (opts = {}) => {
    const { id, text } = opts;
    return api.endpoints.business.company.post('dashboard/video/send-message', { id, text });
  };

  resetStoreBox = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/store/box/reset', { id });
  };

  //Push Distributions

  getMailingList = (opts = {}) => {
    const { limit, page, q = '' } = opts;
    return api.endpoints.business.company.get('dashboard/push-distribution/get-list', {
      limit,
      page,
      q,
    });
  };

  getMailingById = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/push-distribution/get-by-id', { id });
  };

  createMailing = (opts = {}) => {
    const {
      message_text = '',
      message_title = '',
      name = '',
      recipients_filter = [],
      scheduled_at = '',
      send_to_moderation = 0,
    } = opts;
    return api.endpoints.business.company.post('dashboard/push-distribution/create', {
      message_text,
      message_title,
      name,
      recipients_filter,
      scheduled_at,
      send_to_moderation,
    });
  };

  updateMailing = (opts = {}) => {
    const {
      id,
      message_text = '',
      message_title = '',
      name = '',
      recipients_filter = [],
      scheduled_at = '',
      send_to_moderation = 0,
    } = opts;
    return api.endpoints.business.company.post('dashboard/push-distribution/update', {
      id,
      message_text,
      message_title,
      name,
      recipients_filter,
      scheduled_at,
      send_to_moderation,
    });
  };

  deleteMailing = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/push-distribution/delete', { id });
  };

  cancelMailing = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/push-distribution/cancel', { id });
  };

  getRecipientFilterList = (opts = {}) => {
    const { limit, page } = opts;
    return api.endpoints.business.company.get(
      'dashboard/push-distribution/recipients-filter/get-list',
      { limit, page },
    );
  };

  getRecipientFilterById = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get(
      'dashboard/push-distribution/recipients-filter/get-by-id',
      { id },
    );
  };

  createRecipientFilter = (opts = {}) => {
    const { name = '', recipients_filter = [] } = opts;
    return api.endpoints.business.company.post(
      'dashboard/push-distribution/recipients-filter/create',
      {
        name,
        recipients_filter,
      },
    );
  };

  updateRecipientFilter = (opts = {}) => {
    const { id, name = '', recipients_filter = [] } = opts;
    return api.endpoints.business.company.post(
      'dashboard/push-distribution/recipients-filter/update',
      {
        id,
        name,
        recipients_filter,
      },
    );
  };

  deleteRecipientFilter = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post(
      'dashboard/push-distribution/recipients-filter/delete',
      { id },
    );
  };

  findRecipients = (opts = {}) => {
    const { recipients_filter } = opts;
    return api.endpoints.business.company.post('dashboard/push-distribution/find-recipients/', {
      recipients_filter,
    });
  };

  sendTestPush = (opts = {}) => {
    const { message_title = '', message_text = '', employee_id } = opts;
    return api.endpoints.business.company.post('dashboard/push-distribution/send-test', {
      message_title,
      message_text,
      employee_id,
    });
  };

  getSettings = () => {
    return api.endpoints.business.company.get('dashboard/get-settings');
  };

  setCommodityAccounting = (opts = {}) => {
    const { id, is_enabled } = opts;
    return api.endpoints.business.company.post(
      'dashboard/store/settings/save-commodity-accounting/',
      { id, is_enabled },
    );
  };

  bindCatalogToStore = (opts = {}) => {
    const { id, catalog_id } = opts;
    return api.endpoints.business.company.post('dashboard/store/bind/catalog', { id, catalog_id });
  };

  bindCompanyBrandToStore = (opts = {}) => {
    const { id, company_brand_id } = opts;
    return api.endpoints.business.company.post('dashboard/store/bind/company-brand', {
      id,
      company_brand_id,
    });
  };

  bindDiscountToStore = (opts = {}) => {
    const { id, discount_set_id } = opts;
    return api.endpoints.business.company.post('dashboard/store/bind/discount-set', {
      id,
      discount_set_id,
    });
  };

  bindPartyToStore = (opts = {}) => {
    const { id, party_id } = opts;
    return api.endpoints.business.company.post('dashboard/store/bind/party', { id, party_id });
  };

  bindPlanogramToStore = (opts = {}) => {
    const { id, planogram_id } = opts;
    return api.endpoints.business.company.post('dashboard/store/bind/planogram', {
      id,
      planogram_id,
    });
  };

  bindScheduleToStore = (opts = {}) => {
    const { id, schedule_set_id } = opts;
    return api.endpoints.business.company.post('dashboard/store/bind/schedule-set', {
      id,
      schedule_set_id,
    });
  };

  bindStoreGroupToStore = (opts = {}) => {
    const { id, store_group_id } = opts;
    return api.endpoints.business.company.post('dashboard/store/bind/store-group', {
      id,
      store_group_id,
    });
  };

  // ORDERS
  getStatusesDictionary = (opts = {}) => {
    // const { id, store_group_id } = opts;
    return api.endpoints.business.company.get('dashboard/order/get-statuses');
  };

  getOrdersList = (opts = {}) => {
    const { store_id, customer_id, status, created_at_min, created_at_max, limit, order, page, q } =
      opts;
    return api.endpoints.business.company.get('dashboard/order/get-list', {
      store_id,
      customer_id,
      status,
      created_at_min,
      created_at_max,
      limit,
      order,
      page,
      q,
    });
  };

  generateOrdersReport = (opts = {}) => {
    const { store_id, customer_id, status, created_at_min, created_at_max, limit, order, page, q } =
      opts;
    return api.endpoints.business.company.get('dashboard/order/generate-report', {
      store_id,
      customer_id,
      status,
      created_at_min,
      created_at_max,
      limit,
      order,
      page,
      q,
    });
  };

  getOrderById = (opts = {}) => {
    const { id, order, q } = opts;
    return api.endpoints.business.company.get('dashboard/order/get-by-id', { id, order, q });
  };

  createRefund = (opts = {}) => {
    const { id, mode, parent_order_id, items } = opts;
    return api.endpoints.business.company.post('dashboard/order/refund/create', {
      id,
      mode,
      parent_order_id,
      items,
    });
  };

  acceptRefund = (opts = {}) => {
    const { pay_u_order_id, mode, order_id } = opts;
    return api.endpoints.business.company.post('dashboard/order/refund/refund', {
      pay_u_order_id,
      mode,
      order_id,
    });
  };

  // ORDERS END

  getTimeZoneList = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/store/get-timezones');
  };

  //COFFEE

  getCoffeeMachineSettings = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/store/coffee-machine/get-settings', {
      id,
    });
  };

  getCoffeeMachineStats = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.get('dashboard/store/coffee-machine/get-stats', {
      id,
    });
  };

  tryCoffeeMachineReboot = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/store/coffee-machine/reboot', {
      id,
    });
  };

  tryCoffeeMachineLock = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/store/coffee-machine/lock', {
      id,
    });
  };
  tryCoffeeMachineUnlock = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/store/coffee-machine/unlock', {
      id,
    });
  };

  setCoffeeMachineSettings = (opts = {}) => {
    const {
      id,
      block_drink_changes,
      block_drinks,
      block_drinks_when_offline,
      block_menu_access,
      defer_service,
    } = opts;
    return api.endpoints.business.company.post('dashboard/store/coffee-machine/set-settings', {
      id,
      block_drink_changes,
      block_drinks,
      block_drinks_when_offline,
      block_menu_access,
      defer_service,
    });
  };

  getDrinkTypes = (opts = {}) => {
    return api.endpoints.business.company.get(
      'dashboard/store/coffee-machine/item/get-drink-types',
    );
  };

  coffeeMachineCreateItem = (opts = {}) => {
    const { catalog_id, category_id, name, external_id, price, status, text, unit_dimension } =
      opts;
    return api.endpoints.business.company.post('dashboard/store/coffee-machine/item/create', {
      catalog_id,
      category_id,
      name,
      external_id,
      price,
      status,
      text,
      unit_dimension,
      unit_id: 111,
    });
  };

  coffeeMachineUpdateItem = (opts = {}) => {
    const { catalog_id, category_id, name, external_id, price, status, id, text, unit_dimension } =
      opts;
    return api.endpoints.business.company.post('dashboard/store/coffee-machine/item/update', {
      catalog_id,
      category_id,
      name,
      external_id,
      price,
      status,
      id,
      text,
      unit_id: 111,
      unit_dimension,
    });
  };

  setPredefinedImage = (opts = {}) => {
    const { image_name, id } = opts;
    return api.endpoints.business.company.post('dashboard/store/coffee-machine/item/set-image', {
      image_name,
      id,
    });
  };

  unsetPredefinedImage = (opts = {}) => {
    return api.endpoints.business.company.post('dashboard/store/coffee-machine/item/set-image', {
      image_name: 'delete',
    });
  };

  getCurrencyCode = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/get-currency-codes');
  };

  getUnitsCode = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/get-units');
  };

  generateActs = (opts = {}) => {
    const { date_start, date_end, party_id } = opts;
    return api.endpoints.business.company.post('dashboard/docs/act/generate', {
      date_start,
      date_end,
      party_id,
    });
  };

  // downLoadAct = (opts = {}) => {
  //   const { path } = opts;
  //   return api.endpoints.business.company.post('dashboard/docs/act/download', {
  //     path,
  //   });
  // };
  getAvailableCompanies = (opts = {}) => {
    return api.endpoints.business.company.get('dashboard/employee/get-available-companies');
  };

  bindEmployeeToCompany = (opts = {}) => {
    const { company_id } = opts;
    return api.endpoints.business.company.post('dashboard/employee/bind-company', { company_id });
  };

  getBoxNoticeState = (opts = {}) => {
    const { store_id } = opts;
    return api.endpoints.business.company.get('dashboard/store/box/state/get-notice', {
      store_id,
    });
  };
  setBoxNoticeState = (opts = {}) => {
    const { store_id, connection, temperature } = opts;
    return api.endpoints.business.company.post('dashboard/store/box/state/set-notice', {
      store_id,
      connection,
      temperature,
    });
  };

  signDocAsBriskly = (opts = {}) => {
    const { id } = opts;
    return api.endpoints.business.company.post('dashboard/store/party/select-as-briskly', {
      id,
    });
  };

  deviceBind = (opts = {}) => {
    const { store_id, bind_key } = opts;
    return api.endpoints.business.company.post('dashboard/store/device/bind', {
      store_id,
      bind_key,
    });
  };
}

export const companyAPI = new CompanyAPI();
