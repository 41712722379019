export default {
  //ЛАТЫШСКИЙ
  documents_subtitle:
    'šajā sadaļā jums ir nepieciešams iepazīties ar noteikumiem darba platformu un citiem juridiskajiem dokumentiem. <br /> <br /> Arī, šeit tiek parādīts aktuālais statuss pēc dokumentiem, kas vajadzīgi, lai nodrošinātu pareizu mijiedarbību starp jums un uzņēmumu Briskly. <br/> ieskaitot, ir iespēja attālināti parakstīt vairākus dokumentus, izmantojot funkciju sms apstiprināšanas.',

  reports_subtitle:
    'Sadaļā "Atskaites", kas ļauj jums veidot uzskates analītiskie pārskati jūsu uzņēmumu tehnoloģiskās platformas b:pay. <br /> Saņemt saprotamu, ticamu informāciju par jūs interesējošiem parametriem un izvēlnēm, un uz šo datu pamata uzlabojiet savus biznesa procesus, uzlabojot efektivitāti, kā arī noieta tirgus un palielināt savus ienākumus!',
  homeTitle: 'Sākuma ekrāns',
  homeSubtitle: 'šajā ekrānā drīz būs statistika par pārdošanas jūsu tirdzniecības vietās',
  homeSubtitleWithStat:
    'šajā skatā tiek parādīta statistika par pārdošanas jūsu tirdzniecības vietās',

  referalProgram: 'Referāļu programma',
  trade_points: 'Tirdzniecības vietas',
  on_map: 'Uz kartes',
  do_add_trade_point: 'Pievienot tirgotavu',
  bind: 'Pieķerties',
  doesnt_select: 'Nav norādīts',
  select_dates: 'Izvēlieties datuma',
  trade_point_list: 'Sarakstu tirdzniecības vietās',

  number: 'Numurs',
  cost: 'Vērtība',
  id: 'ID',
  do_open: 'Atvērt',
  do_close: 'Aizvērt',
  do_search: 'Meklēt',
  results: 'Rezultāti',
  by_list: 'Sarakstu',
  name: 'Nosaukums',
  continue: 'Turpināt',
  revenue: 'Ienākumi',
  averageCheck: 'Vidējais čeks',
  save: 'Saglabāt',
  skip: 'Ignorēt',
  metr: 'm',
  km: 'km',

  do_select: 'Izvēlēties',
  thousand_short: 't',

  do_change: 'Mainīt',
  first_name: 'Nosaukums',
  required_fields: 'Saistošie kolonnas',
  do_repeat: 'Atkārtot',
  do_check: 'Pārbaudīt',
  last_name: 'Uzvārds',
  middle_name: 'Uzvārds',
  trade_point: 'Preču punkts',
  trade_point_count: 'punktu Skaits',
  role: 'Loma',
  next_step: 'Nākamais solis',
  yes: 'Jā',
  no: 'Nē',
  do_skip_and_finish: 'Ignorēt un pabeigt',
  do_end_registration: 'Pabeigt reģistrāciju',
  do_skip_and_end_registration: 'Ignorēt un pabeigt регистрацию',
  from: 'No',
  before: 'Līdz',
  do_cancel: 'Atcelt',
  format: 'Formāts',
  do_download_file: 'Ielādēt failu',
  total: 'Kopā',
  pick_or_input: 'Izvēlieties iespēju, vai arī turpiniet enter',
  meklēšana: 'Meklēt',
  step: 'Solis',
  all: 'Visi',
  price: 'Cena',
  here: 'Šeit',
  go_to: 'Pāriet',
  price_with_currency2: 'Cena, ₽',
  do_create: 'Izveidot',
  active_status_yes: 'Aktīvs',
  active_status_no: 'Neaktīvs',
  pack_min: 'pak',
  go_to_page: 'Pāriet uz lapu',
  do_send: 'Nosūtīt',
  yesterday: 'Vakar',
  today: 'Šodien',
  you: 'Jūs',
  main_page: 'Sākums',
  logout: 'Iziet',
  shortcut_week: '7 dienas',
  shortcut_month: '31 diena',
  among: 'No',
  time_zone: 'Laika josla',
  pick_time_zone: 'Izvēlieties pagaidu zonu',
  ready: 'Gatavs',
  extended_types_store: 'Veikals',
  extended_types_refrigerator: 'Ledusskapis',
  extended_types_store_online: 'Interneta Veikals',
  extended_types_cafe: 'Kafejnīca',
  extended_types_production_with_delivery: 'Ražošana ar piegādi',
  extended_types_filling_station: 'DUS',
  extended_types_cigarettes: 'Cigarešu skapis',
  extended_types_coffee_machine: 'Kafijas automāts',
  stage_type_title: 'Pievienojiet tirdzniecības punkts',
  stage_type_edit_title: 'pamatinformācija',
  stage_type_subtitle: 'Izvēlieties, kāda veida tirdzniecības punkts',
  stage_type_description:
    'Izvēlieties savu kategoriju: veikals, kafejnīca, vai kādu citu no piedāvātā saraksta. Vairāk par iespējām katrā kategorijā šeit',
  stage_type_label_phone: 'Tālrunis tirdzniecības punkts',
  address: 'Adrese',
  stage_type_label_floor: 'Stāvs',

  stage_design_description:
    'Izvēlieties, kā izskatīsies jūsu tirdzniecības punkts B pielikumā-Pay. Vai lejupielādējiet logo. Tas būs parādīt uz kartes preces',
  stage_design_label_select: 'Izvēlieties dizains',
  stage_design_create_new: 'jauns dizains',
  līgums: 'Vienošanās',
  promo_briskly: 'Promo materiāli Briskly',
  fuel: 'Degviela',
  date_input_placeholder: 'dd.mm. gggg',
  finish_registration: 'Pabeigt reģistrāciju',
  purchases_quantity: 'pirkumu Skaits',
  profit: 'Ieņēmumi',
  do_open_new_trade_point: 'Atvērt jaunu punktu',
  support: 'atbalsta Dienests',
  profile: 'Personīgais kabinets',
  error_modal_title: 'Nevar aktivizēt tirdzniecības punkts',
  design_title: 'Dizains',
  card_is_missing: 'Nav piesiets',
  your_logo: 'Jūsu logo',
  your_logo_in_format: 'Jūsu logo formātā',
  preview_for_app: 'Preview, lai veikala katalogā',
  enter_text: 'Ierakstiet',
  pick_background: 'Izvēlieties fons',
  little_background: 'Fona mazajam īkšķiem formātā',
  big_background: 'Fons lielu īkšķiem formātā',
  do_change_preview: 'Mainīt īkšķiem',

  store_photos: 'Foto tirdzniecības punkts',
  no_design_to_select: 'Nav saglabāti dizainu',
  schedule: 'Kalendārs',
  schedule_title: 'Kalendārs',
  work_schedule: 'Grafiks',
  schedule__description:
    'Atzīmējiet darba grafiku jūsu ledusskapis: cikos tas tiek atvērts, lai iepirktos, un laiku, kāds tiek bloķēts.',
  do_download_from_template: 'Augšupielādēt no veidņu',
  start_day_after_visiting_courier: 'Sākt darba dienā pēc apmeklējuma kurjers',
  save_as_template: 'Saglabāt kā veidni',
  do_enter_name: 'Ievadiet nosaukums',
  week_all_day: 'Visas dienas',
  permanently: 'visu Diennakti',
  weekend: 'Brīvdiena',
  do_add_break: 'Pievienot pārtraukums',
  templates: 'Veidne',
  create_employees_title: 'Pievienot darbinieku',
  employees_description:
    'šajā ekrānā, jūs pievienojat darbiniekiem savu tirdzniecības punktu, izvēlieties to no saraksta vai заносите dati ir jauni. Piešķiriet viņiem lomu, saskaņā ar pienākumiem. Pēc noklusējuma vadītājs ir savienots ar visām lomām. Sīkāk sk. sadaļā palīdzība.',
  pick_employees: 'Izvēlieties darbiniekiem',
  delete_employee_modal_title: 'Jūs gatavojas, lai novērstu darbinieka',
  employees_selected: 'Atlasīts',
  employee_change_modal_title: 'Mainīt datus',
  employee_create_modal_title: 'Pievienošana darbinieka',
  employee_change_modal_role_title: 'Piešķiriet lomu этойт tirdzniecības vietā',
  employee_create_modal_role_title: 'Izvēlieties lomas',
  add_employee: 'Pievienot darbinieks',
  role_administrator_name: 'Administrators',
  role_administrator_access: 'Piekļuve visam funkcionāli personas kabinets',
  role_courier_name: 'Kurjers',
  role_courier_access:
    'ir Pieejama iespēja atvērt ledusskapi, mainīt preci, veikt foto, bilžu interfeiss kurjers',

  role_checker_access:
    'Pieņem pasūtījumus no pircējiem un apkopo tos tirdzniecības zālē nosūtīšanai klientam',
  employees: 'Darbinieki',
  to_add_new_employee: 'Pievienot jaunu darbinieku',
  requisites_title: 'Rekvizīti',
  requisites_description:
    'Pievienojiet rekvizīti jūsu tirdzniecības vietas. Tie ir nepieciešami, lai sūtītu naudu uz jūsu norēķinu kontu. Uzņēmuma dati tiks norādīti izsniedzot čeku klientiem.',
  requisites_single_description:
    'šajā sadaļā ir norādīti visi rekvizīti jūsu pašreizējo tirdzniecības vietās, kā arī jūs varat ienest jaunas.',
  pick_requisites: 'Izvēlēties rekvizīti',
  requisites_company_info: 'Informācija par kompāniju',
  requisites_client_info: 'Informācija par klientu',
  requisites_fio: 'VĀRDS',
  requisites_phone: 'tālruņa Numurs',
  requisites_mail: 'E-pasts',
  requisites_account_number: 'konta Numurs',
  requisites_current_account_number: 'norēķinu konta Numurs',
  requisites_bank_bic: 'BIK bankas',
  requisites_bic: 'BIK',
  add_new_requisites: 'Pievienot jaunus rekvizītus',
  adding_new_requisites: 'Pievienot jaunu informāciju par',
  requisites_cheque: 'Kases čeku',
  requisites_non_cash: 'Bezskaidras',
  requisites_buyer_phone: 'Tālrunis pircēja',
  requisites_items: 'Produkti',
  requisites_item: 'Preci',
  requisites_provider_info: 'sniedzēja Dati',
  requisites_name: 'Nosaukums',
  requisites_inn: 'PVN numurs vai juridiskās personas nosaukums',
  requisites_step1: 'Tips nodarbinātības',
  requisites_step2: 'Personas dati',
  requisites_step2_legal: 'Personas datus vadītāja',
  requisites_step3: 'organizācijas Rekvizīti',
  requisites_step4: 'Norēķinu informācija',
  requisites_pick_employment_type: 'Izvēlieties, kāda veida nodarbinātības',
  employment_type_individual: 'PI',
  employment_type_legal: 'Organizācija',
  requisites_position: 'Amats (piemēram, nolikumā)',
  passport_first_page: 'Pirmā lapa pases',
  passport_second_page: 'Lapa ar pierakstu',
  passport_series: 'Sērija',
  passport_number: 'Numurs',
  passport_issue_date: 'izdošanas Datums',
  passport_authority_code: 'Kods vienības',
  passport_authority_name: 'Nosaukums vienības, izsniedz dokumentu',
  passport_citizenship: 'Pilsonība',
  passport_registration_place: 'reģistrācijas Adrese (kā pasē)',
  passport_birth_place: 'dzimšanas',
  passport_birth_date: 'dzimšanas Datums',
  requisites_capital: 'pamatkapitālu, ₽',
  requisites_tax_system: 'Izvēlieties nodokļu sistēmu',
  tax_system_general: 'APSKATI',

  requisites_org_phone: 'Tālrunis organizācijas',
  requisites_company_iname: 'uzņēmuma Nosaukums',

  requisites_bank_account_info: 'Informācija par bankas kontu',

  requisites_cheque_hint: 'Tagad jūs varat redzēt, kā izskatīsies čeku jūsu tirdzniecības vietas.',
  requisites_recipient_bank_name: 'saņēmēja Banka',

  requisites_missing: 'Nav',
  requisites_personal_data_descr_part_1:
    'Mēs lūdzam jūs aizpildīt šo informāciju, lai varētu katru dienu, tulkot, jums iegūtos līdzekļus no pārdošanas jūsu tirdzniecības vietas. Lai to izdarītu, jums ir jāsniedz informācija, kas ir analogi tiem, ko jūs sniedzat, kad pievienojat эквайринга.',

  //Piegāde
  want_to_deliver: 'vai Vēlaties piegādāt savas preces, izmantojot savu vai mūsu piegādes?',
  delivery_own_radius: 'Rādiuss savu piegādes',
  delivery_briskly_radius: 'Rādiuss piegādes partneriem Briskly',
  delivery_own: 'Privāta piegāde',
  delivery_briskly: 'Piegāde partneriem Briskly',
  delivery_both: 'Abi',
  delivery_own_tariffs: 'Tarifi pēc savas piegādā',
  delivery_briskly_tariffs: 'Tarifi piegādes partneriem briskly',
  delivery_briskly_description:
    'Lūdzu, ņemiet vērā, ja uzstādītā jums piegādes izmaksas klientam būs mazāk, nekā faktiskās izmaksas, mēneša beigās jūs saņemsiet rēķinu ar šo atšķirību. Ja jūsu izmaksas būs lielākas, tad jums atnāks naudu apmērā no šīs starpības.',
  delivery_briskly_price: 'piegādes Izmaksas partneriem Briskly',

  delivery_tariffs: 'Tarifi piegādā',
  order_sum: 'pasūtījuma Summa',
  delivery_cost: 'piegādes Izmaksas',
  delivery_invalid_rates_text:
    'Intervālus pasūtījuma summas ir dažādi, starp tiem ir atšķirība būt ne vairāk par 1 rubli.',
  do_add_rate: 'Pievienot nosacījums',
  delivery_warning_modal_title: 'Jūs vēlaties mainīt piegādes metodi',
  delivery_warning_modal_body: 'Pēc saglabāšanas, iepriekšējais piegādes veids ir izdzēsts',
  delivery_cost_free: 'bez Maksas',
  delivery_cost_discount_percent: 'Atlaide no cenas piegādes procentos',
  delivery_cost_discount_fixed: 'Atlaide no piegādes cenas rubļos',
  delivery_cost_fixed: 'Fiksētā cena',
  delivery_cost_unknown: 'Piegādi apmaksā klients',
  offer: 'Piedāvāt',
  do_download_offer: 'Lejupielādēt piedāvājumu',
  do_sign_document: 'Parakstīt dokumentu',
  code_from_sms: 'Kodu no sms',
  try_again_in: 'Ja kods nav atnācis, mēģiniet vēlreiz pēc',
  in_seconds: 'sekundes',
  do_send_code_again: 'Nosūtīt kodu vēlreiz',
  do_send_confirmation_code: 'Nosūtīt kods-apstiprinājums',

  //Планограмма

  planogram_block_hint: 'Nepieciešams, lai izveidotu direktoriju',
  will_be_deleted: 'likvidēt',

  do_add_items_on_shelf: 'Pievienojiet produktu uz plaukta',
  your_micromarket: 'Jūsu ledusskapis',
  shelf: 'pulka',
  shelf_is_overflow: 'šajā plauktā vairs nav vietas',
  do_add_items_on_another_shelfs: 'pievienot preces uz citām plaukti',
  do_add_shelf: 'pievienot plaukta',

  planogram_item: 'preci',

  //Maksājums
  group_by: 'kārtot pēc',
  period: 'Periods',
  order_status: 'pasūtījuma Statusu',
  pick_store: 'Izvēlieties veikals',
  option_selection_by_number: 'Picks pa',
  paid: 'Samaksāts',
  processed: 'Apstrādāts',
  refund: 'Atlaist',
  refund_awaiting: 'gaidot atpakaļ',
  refund_error: 'Kļūda atmaksas',
  removed: 'Dzēsts',
  quantity: 'Skaits',
  year: 'Gads',
  id_local: 'ID',
  order_number: 'pasūtījuma Numurs',
  client: 'Klients',
  item_quantity: 'Skaits preces',
  items_sufix: '{_} preci | {_} preces | {_} preces',
  income: 'Peļņa',
  item_price: 'Cena ir par vienu preci',
  period_start: 'Pirmā perioda',
  period_end: 'perioda Beigām',
  sum_with_discount: 'Summa ar atlaidi',
  sum: 'Summa',
  orders: 'Pasūtījumi',
  clients: 'Klienti',
  unique_clients: 'Unikālas dienas klienti',
  sales: 'Pārdod',
  completed_order: 'Pabeigtu pasūtījumu',
  refund_awaiting_from_client: 'Gaidāms piemaksa no klienta',

  // Preču Uzskaite
  service_one_s: '1C',

  service_moy_sklad: 'Mans Noliktavā',

  service_yml: 'YML',
  service_xlsx: 'XLSX',

  something_wrong_try_again: 'kaut Kas nogāja greizi. Mēģiniet vēlreiz.',
  use_suggestions_begin: 'Izmantojiet mūsu',
  use_suggestions_action: 'komandas',
  use_suggestions_end: 'lai integrētu sistēmu',
  payment_description:
    'Nosūtiet pieprasījumu pievienot apmaksas veida atbildīgajai personai veikalu, kur tiks uzstādīts cigarešu skapis.',
  send_request: 'Sūtīt vaicājumu',
  do_change_payment_method: 'Nomainīt apmaksas veids',
  current_card: 'Pašreizējā karte',

  //Soc.tīkls
  follow_us_begin: 'Paraksties uz mums',
  follow_us_end: 'sociālajos tīklos',
  bpay_title: 'Lejupielādējiet b pielikums-pay',
  bpay_subtitle: 'Nopirkt bez rindas',

  checker_subtitle: 'Savākt pasūtījumus saviem klientiem attālināti',

  //Validācija
  valid_required: 'Obligāti',
  valid_article_required: 'Artikuls obligāta',
  valid_min_3: 'Vismaz 3 simboli',
  valid_min_1: 'Vismaz 1 simbols',
  valid_min_6: 'Vismaz 6 simboli',
  valid_max_60: 'Maksimums 60 rakstzīmes',
  valid_max_5: 'Maksimums 5 rakstzīmes',
  valid_max_6: 'Maksimums 6 simboli',
  valid_max_13: 'Maksimums 13 rakstzīmes',
  valid_max_20: 'Maksimāli 20 rakstzīmes',
  valid_max_32: 'Maksimums ir 32 rakstzīmes',
  valid_max_350: 'Maksimums 350 rakstzīmes',

  valid_mail_format: 'E-pasts formātā: welcome@brskl.io',
  valid_phone_format: 'Tālrunis formātā +7 (123) 456 78 90',
  valid_no_less_1_percent: 'Nav mazāks par 1 %',
  valid_no_more_99_percent: 'Nav lielāks par 99 %',
  valid_fill_the_field: 'Aizpildiet laukumu',
  valid_upload_both_photos: 'Lejupielādējiet abi attēli',
  valid_incorrect_date: 'Nederīgs datums',
  valid_incorrect_phone: 'Nederīgs telefons',
  valid_passport_series: 'Nepareiza sērija',
  valid_passport_number: 'Nepareizs pases numurs',
  valid_passport_authority_code: 'Nederīgs kods vienības',
  valid_inn: 'Izvēlieties INN',
  valid_address: 'Izvēlieties adrese',
  valid_bic: 'Izvēlieties BIK',

  valid_account_number: 'Garums norēķinu konts būtu 20 rakstzīmes',
  valid_maximum: 'Maksimālais simbolu skaits',
  valid_select_some_filter: 'Izvēlieties vismaz vienu filtru',

  //Брискли Modulis
  module_tittle: 'Modulis',
  briskly_module: 'Briskly Modulis',
  module_description:
    'Briskly modulis — tas ir smadzeņu jūsu ledusskapis. Pērkot jums ir dots kods, kas nepieciešams piesaistīt kontam. Kods var apskatīt',
  module_to_buy:
    'Ja Jums vēl nav apmaksājuši Ledusskapis (ledusskapis + Briskly Modulis) vai atsevišķi Briskly Modulis, lai jūsu ledusskapja,',
  module_link_to_buy: 'dariet to tūlīt',
  indicate_divice_code: 'Ievadiet kodu no ierīces',
  module_data: 'Informācija par Briskly modulī',
  network_status: 'tīkla Statuss',
  working: 'Strādā',
  not_working: 'nedarbojas',
  lock_status: 'Statuss pils',
  lock_close: 'Slēgts',
  lock_open: 'Atvērts',
  air_temp: 'gaisa Temperatūra',
  last_time_in_network: 'Pēdējo reizi tīklā',
  lock_control: 'Vadība pili',
  module_error_title: 'Dati nav pieejami. Tas varēja notikt, jo',
  module_check: 'Pārbaudīt modulis',
  buy_module: 'Pērk Briskly Moduļa',
  to_retry: 'vēlreiz',
  setup_module_in_you_ref: 'sistēmu Uzstādīšana jūsu ledusskapis',
  smart_ref_all_options: 'Gudrs ledusskapis "zem atslēgas"',
  popular_format: 'Tautas formātu',
  big_market_with_all_options: 'Liels kiosks "zem atslēgas"',
  lock_mount: 'Uzstādīšana, el. pils',
  connect_to_b_pay: 'Pieslēgšana programmai B-Pay',
  goods_integration: 'Integrācija sortimenta',
  temperature_sensor: 'temperatūras Sensoriem',
  spy_camera: 'Kamera',
  refregerator_in_set: 'Ledusskapis komplektā',
  trade_point_branding: 'Zīmolu ledusskapis',
  electronic_lock: 'E-pils',
  refregerator_with_two_doors: 'Ledusskapis ar divām durvīm',
  module_apply: 'Pieteikties',
  left_a_little: 'Atlicis nedaudz',
  fill_in_the_required_sections_for_your_tt_start_working:
    'Aizpildiet, lūdzu, obligātās sadaļas, lai jūsu tirdzniecības punkts sāka strādāt',
  continue_filling: 'Turpināt aizpildīšana',
  copy_link: 'Kopēt',
  lock: 'Pils',
  last_check: 'Pēdējā pārbaude',

  //Piegāde
  stages_description_delivery: 'Izvēlieties vienu no piegādes opcijas',
  stages_description_design:
    'Tagad jūsu tirdzniecības punkts B pielikumā-pay izskatās standartam. Lai izceltos, jūs varat mainīt dizains',
  stages_description_employees: '',
  stages_description_exchange:
    'Lejupielādējiet savu produktu katalogs, lai klienti varētu redzēt to.',
  stages_description_briskly_module:
    'Visi tirdzniecības punkti ir redzami sistēmā Briskly tikai pēc savienojuma Briskly Moduli.',
  stages_description_offer:
    'Jums ir nepieciešams parakstīt līgumu piedāvājuma, lai baudīt Briskly ministru',
  stages_description_planogram:
    'Novietojiet uz plaukta ledusskapis preces, kā to redz jūsu klienti.',

  stages_description_schedule: 'Lai preču punkts strādāja, aizpildiet laukus grafika.',
  stages_description_type: '',
  stages_description_fuel: '',
  stages_description_payment:
    'Klients vēl nav sasaistīta karti, lai samaksātu.\n Nokopē saiti un nosūti viņam, ja vēl nav to izdarījis.',
  trade_point_registation_complite: 'Reģistrācija ir pabeigta',
  congratulations_your_tt_is_ready_to_go: 'Apsveicam! Jūsu preču punkts ir gatava',
  fine: 'Lieliski',
  congratulations_your_tt_is_ready_we_need_check:
    'Apsveicam! Jūs esat pabeiguši reģistrāciju! Tagad mums ir nepieciešams 1-3 darba dienas, lai pārbaudītu jūsu rekvizītiem. Pēc tam jums būs iespēja piekļūt tirdzniecības vietā.',
  download_qr_code: 'Lejupielādēt QR kodu ',
  download_sticker: 'Lejupielādēt etiķeti',
  download_table_banner: 'Lejupielādēt galda banner',
  do_edit_sufix: 'Rediģēšana',
  delete_trade_point: 'Dzēst tirdzniecības vietai',

  employees_title: 'Darbinieki',

  payment_method: 'apmaksas Veids',
  payment_status: 'Statuss apmaksas',
  temperature_setup: 'temperatūras Iestatījumi',
  temperature_setup_title:
    'Iestatiet temperatūras režīms jūsu ledusskapis. Kad temperatūra iekšpusē ledusskapis iziet ārpus jūsu noteiktie ierobežojumi, mēs nosūtīsim jums paziņojumu.',
  time_of_ordering: 'pasūtījuma Laiks',
  Video: 'Video',
  video_by_order: 'Video pasūtījumam',
  pick_date: 'Izvēlieties datumu',
  video_recording_date: 'Datums ieraksta video',
  video_not_found: 'Video nav atrasts',

  //Dokuments
  download: 'Download',

  is_signet: 'Parakstīts',
  documents: 'Dokumenti',
  is_doesnt_signed: 'Nav parakstīts',
  is_touched: 'Apskatīt',
  to_assign: 'parakstīties uz',
  time_stamp: 'pievienojot',
  type: 'Tips',
  offer_for_partners: 'Piedāvājums sadarbības partneriem',
  tariff_plans: 'Tarifu plāni',
  personal_account_rules: 'lietošanas Noteikumi LK',

  //Atskaite
  reports: 'Atskaites',
  report_about_clients: 'Atskaite par klientiem',
  report_about_items: 'Atskaite par precēm',
  report_about_orders: 'Statistika par pasūtījumiem',
  report_about_sales: 'pārdošanas Statistika',
  report_about_unique_clients: 'Statistika par unikālu dienas klientiem',
  do_download_data: 'Lejupielādēt dati',
  do_download_promo_briskly: 'Lejupielādēt promo materiāli Briskly',
  download_file_format: 'Formāts lejupielādēt',

  //Personas dati
  your_account_has_run_out_of_money: 'jūsu kontā nepietiek naudas.',
  do_top_up_an_account: 'Papildināt',
  air_temperature: 'gaisa Temperatūra',
  inside: 'Iekšā',
  outside: 'no Ārpuses',
  status: 'Statuss',
  actual_address: 'Faktiskā adrese',
  card: 'Karte',
  phone: 'Telefons',
  credentials: 'Informāciju',
  delivery: 'Piegāde',
  do_edit: 'Labot',
  most_popular_items: 'Populārākās preces',
  item_name: 'preces Nosaukums',
  price_with_currency: 'Cena, ₽',
  revenue_with_currency: 'Ieņēmumi, ₽',
  sold_items_with_amount: 'Pārdod, gab',

  // Atlaide
  option_unknown: 'Nav atzīmēta',
  option_min: 'Minimālais',
  option_max: 'Maksimālais',
  option_discount_percent: 'Atlaide procentos',
  option_discount_amount: 'Atlaide rubļos',
  option_discount_new_price: 'Jauna cena',
  option_usage_common: 'Kopējā atlaide',
  option_usage_first_order: 'Pirmais pirkums bez laika uzskaites',
  option_usage_first_order_after_timestamp: 'Pirmais pirkums pēc norunātā laika limita',
  discount_size: 'Izmērs atlaides',
  discount: 'Atlaidi',

  applying_features: 'piemērošanas Īpatnības',
  applying: 'Pielietojums',
  do_add_discount: 'Pievienot atlaidi',
  editing_of_discount: 'Rediģēt atlaides',
  adding_of_discount: 'Pievienot jaunu atlaides',
  applying_order: 'piemērošanas Kārtība',
  save_changes: 'Saglabāt izmaiņas',
  do_save: 'Saglabāt',

  client_show_settings: 'Uzstādījumi rādīšanas klientiem',
  do_show: 'Rādīt',
  title: 'Virsraksts',
  description: 'Apraksts',
  image: 'Attēls',
  add_image_proposal: 'Pievienot attēlu',
  adding_of_discount_group: 'Pievienot kopas atlaižu',
  editing_of_discount_group: 'Rediģēt kopas atlaižu',
  do_add_discount_group: 'Pievienot komplekts',
  do_add_discount_group_v2: 'Pievienot kolekcija atlaižu',
  do_assign_discount_group_to_store: 'Piešķirt atlaides tirdzniecības vietām',
  discounts: 'Atlaides',
  do_delete: 'Dzēst',
  not_delete: 'dzēst',
  do_reset_changes: 'Atsaukt',
  you_are_trying_to_delete_discount: 'Jūs gatavojas, lai novērstu atlaidi',
  you_are_trying_to_delete_discount_group: 'Jūs gatavojas, lai novērstu virkni atlaižu',
  discount_assign: 'atlaižu Piešķiršana',
  store_name: 'Nosaukums tirdzniecības vietas',
  discount_group: 'Kopa, atlaižu',
  title_for_clients: 'Virsraksts klientiem',
  is_enabled: 'Ieslēgts',
  is_disabled: 'Izslēgts',
  is_enabled_female: 'Ieslēgts',
  is_disabled_female: 'Izslēgts',
  show_for_clients: 'Rādīt klientiem',
  type_of_discount: 'Tips atlaides',
  set: 'Kopa',
  around_the_clock: 'visu Diennakti',
  doesnt_act: 'nedarbojas',

  // Prece
  good_photo: 'Foto preces',
  volume: 'Tilpums',

  step_reminder: 'Jūs varat iet uz šo soli vēlāk.',
  category: 'Kategorija',
  categories: 'Kategorijas',
  category_sufix: 'Kategorijas',
  create_catalog: 'Izveidot direktoriju',

  catalog_name: 'Nosaukums direktorija',
  portion: 'apkalpo',
  non_tax: 'Nav apliekams ar',
  payment: 'Norēķinu',
  standard: 'Standarta',
  altogether: 'Kopā',
  units: 'Vienības',
  age_limit: 'Vecuma ierobežojums, gadu',
  composition_of_item: 'Sastāvu',
  calories: 'Kalorijas',
  optional_description: 'Apraksts (neobligāts)',
  vat_mode: 'Režīms PVN',
  vat_rate: 'PVN Likme, %',

  item_add_action: 'Pievienošana preces',
  item_edit_action: 'Rediģēt preces',
  dimension: 'Izmērs',
  item_add_action2: 'Pievienot preces',
  add_and_edit_catalog: 'Pievienošana/rediģēšana direktorija',
  need_select_catalog: 'Nepieciešams izvēlēties direktoriju',
  do_add_more_categories: 'vēl Pievienot kategoriju',
  goods_name: 'produkta Nosaukums',
  expiration_date: 'derīguma Termiņš',
  barcode: 'Kods',
  sticker_quantity: 'Skaits uzlīmju',
  manufacturing_by: 'Ražo',
  h: 's',
  d: 'd',
  m: 'm',
  y: 'g',
  composition: 'Sastāvu',
  do_not_specifiedSuffix: 'Nav attēlota',
  do_not_specified: 'Nav norādīts',
  fats: 'Tauki',
  proteins: 'Olbaltumvielas',
  carbohydrates: 'Ogļhidrāti',
  calories_full: 'Enerģētiskā vērtība',
  ccal: 'kcal',
  nutritional_value_per_100g_of_product: 'Uzturvērtība(uz 100 g produkta)',
  at_the_goods: 'Pie preces',

  maybe_doest_valid_control_digit: 'Var būt: Nepareiza kontrolcipars!',
  add_goods: 'Pievienot preces',
  add_good: 'Pievienot preci',
  do_delete_category: 'Izdzēst kategoriju',
  be_deteted: 'likvidēt',
  need_detals: 'Nepieciešams precizēt',
  go_to_edit: 'Pāriet rediģēšanas',
  later: 'Vēlāk',
  good_suffix: 'produkts',
  article: 'Artikuls',
  photo: 'Foto',

  dimension_units: 'Vien. mērījumi',
  active: 'Aktīvā',
  sticker: 'Uzlīmes',
  items: 'Produkti',
  do_create_catalog: 'Izveidot direktoriju',
  do_create_new_catalog: 'Izveidot jaunu direktoriju',
  stickers_print: 'Drukāt uzlīmes',
  write_name_of_new_catalog: 'nosaukums Ievadiet jaunā kataloga',
  write_name_of_new_category: 'nosaukums Ievadiet jaunu kategoriju',
  do_add: 'Pievienot',
  do_go_back: 'Atpakaļ',
  do_continue: 'Turpināt',
  catalog: 'Directory',
  doesnt_have_category: 'Nav kategorijas',
  stickers: 'Uzlīmes',
  do_print_stickers: 'Izdrukāt uzlīmes',
  manufacturer_name: 'Nosaukums ražotāja',
  manufacturing_address: 'Adrese izgatavošanas',
  production_date: 'izgatavošanas Datums',
  time: 'Laiks',
  hours: 'Pulkstenis',
  minutes: 'Minūtes',
  days: 'Dienas',
  weeks: 'Nedēļas',
  months: 'Mēneši',
  years: 'Gadi',
  do_apply: 'Pielietot',
  general_expiration_date: 'Kopējais derīguma termiņš',
  amount_of_stickers_per_position: 'Skaits uzlīmju vienu pozīciju',
  do_mark_all: 'iezīmēt visu',

  // Dokuments
  do_download_agreement: 'Lejupielādēt līgums',
  personal_lowercase: 'personas',
  cabinet_lowercase: 'kabinets',
  choose_option_or_continue_input: 'Izvēlieties iespēju, vai arī turpiniet rakstīt',
  unknown_organization: 'Nezināma organizācija',
  organization_info: 'Informācija par organizācijas',
  organization_name: ', uzņēmuma Nosaukums',
  organization_phone: 'Tālrunis organizācijas',
  inn: 'PVN',
  head_of_company: 'Vadītājs',
  registration_date: 'reģistrācijas Datums',

  capital: 'pašu kapitāls',
  taxes_system: 'nodokļu Sistēma',
  do_write_inn: 'Ievadiet INN',
  osno: 'APSKATI',

  innLabel1: '{label} vai organizācijas nosaukums',
  innLabel2: '{label} vai uzvārdu',
  acting: 'Derīgs',
  first_name_placeholder: 'Mihails',
  second_name: 'Uzvārds',
  second_name_placeholder: 'Mihailovs',
  third_name: 'Uzvārds',
  third_name_placeholder: 'Rakstnieks',

  position_label: 'Amats (piemēram, statūtos organizācijas)',
  position_placeholder: 'ceo',
  okopf_name_short_label: 'Tiesiskā forma saīsinātā veidā',
  okopf_name_full_label: 'Tiesiskā forma pilnā veidā',
  ooo_full_name: 'Sabiedrība ar ierobežotu atbildību',
  name_short_with_opf: 'Īsais nosaukums ar VFS',
  name_short_with_opf_placeholder: "SIA 'Bagrations'",
  type_of_company_placeholder: 'SIA, IU',
  name_short: 'Īsais nosaukums',
  name_short_placeholder: 'Bagrations',
  ogrn: 'OGRN',
  first_passport_page: 'Pirmā lapa pases',
  passport_page_with_place_of_live: 'Lapa ar pierakstu',
  bank_account: 'norēķinu konta Numurs',
  bank_account_register_placeholder:
    'Ievadiet 20 ciparu konta bankā (ja konts sastāv no 22 cipari, norādiet pirmajās 20)',
  bik: 'BIK',
  bik_register_placeholder: 'Ievadiet 9 cipari BIK bankas',
  bank_credentials: 'bankas Rekvizīti',
  receivers_bank: 'saņēmēja Banka',
  bank_account_number: 'konta Numurs',

  your_name: 'Jūsu vārds',
  phone_number: 'tālruņa Numurs',
  type_of_employment: 'Tips nodarbinātības',
  ip_as_type_of_organization: 'PI',
  legal_as_type_of_organization: 'Organizācija',
  date: 'Datums',

  integration_errors: 'integrācijas radušās kļūdas',
  integration_with_system_were_succeed: 'Integrācija ar {system} notikusi veiksmīgi',
  do_change_info: 'Rediģēt informāciju',
  do_skip: 'Ignorēt',
  select_other_system: 'Izvēlēties sistēmu',
  select_other_system2: 'Izvēlēties citu sistēmu',
  do_check_integration: 'Pārbaudīt integrāciju',
  integration_entry_label:
    'Šeit jūs pievienot preces, kas būs pārdot. To var izdarīt vairākos veidos',

  integration_entry_label_option1: 'Integrēt sistēmu Briskly ar savu uzskaites sistēmu.',
  integration_entry_label_option2: 'Augšupielādēt failu ar precēm.',
  integration_entry_label_option3: 'Atlasīt esošu direktoriju',
  integration_entry_label_option4: 'Pievienot preces ar rokām',
  select_catalog: 'Izvēlieties direktoriju',
  upload_or_drag_file: 'Lejupielādējiet vai velciet failu',
  integration: 'Integrācija',

  item_add_action2_label: 'Izveidot kategoriju un pievienojiet preces',
  write_category_name: 'Ievadiet kategorijas nosaukumu',
  do_create_category: 'Izveidot jaunu kategoriju',
  actions: 'Darbības',
  do_send_claim: 'Iesniegt sūdzību',
  claim_text: 'sūdzības Teksts',
  for_order: 'uz pasūtījumu',
  order: 'Pasūtījums',

  client_id: 'klienta ID',
  total_discount: 'atlaižu Summa',
  order_info: 'Informācija par pasūtīšanu',
  claim_sended: 'Sūdzība nosūtīta',
  total_amount: 'Kopējā vērtība',
  order_composition: 'pasūtījuma Sastāvs',
  additionally: 'Izvērstais',
  fields_are_optional: 'lauki nav obligāti aizpildāmie',
  show_by: 'Parādīt',

  do_briskly_module_active_new: 'Aktivizēt jaunu moduli',
  do_briskly_module_finish: 'Beigt',
  briskly_module_reassign_title: 'Piesaiste jaunu moduli',
  briskly_module_reassign_message: 'vai Jūs tiešām vēlaties piesaistīt jaunu Briskly moduli?',

  error: 'Kļūda',
  fail_bind_employee_to_store: 'Nav iespējams piestiprināt darbinieku',

  fail_init_employee_store_list: 'Nav iespējams lejupielādēt datus par tirdzniecības punktos',
  fail_onboarding_init_company_info:
    'Nav iespējams lejupielādēt datus uzņēmuma aizpildāmie iepriekš',
  unknown_error: 'kaut Kas nogāja greizi',

  //Prece
  delete_category_text_confirm:
    'Šo darbību, tiks dzēsti direktorijā, kā arī preces, kas atrodas to būs kategorijā "visi"',
  delete_catalog_text_confirm_1: 'dzēšot direktorija',

  do_collapse: 'Roll',
  exchange_settigns: 'koplietošanas Iestatījumi',

  need_to_select_catalog_and_category:
    'Lai izveidotu preces \n jāizvēlas \n, katalogs un kategorijā',
  catalogs_and_categories: 'Katalogi un kategorijā',
  categories_of_catalogs: 'Kategorijas direktorijā',
  add_catalog: 'Pievienot direktoriju',
  add_category: 'Pievienot kategoriju',
  add_catalog_action: 'Pievienot direktoriju',
  add_category_action: 'Pievienot kategorijā',
  category_name: 'kategorijas Nosaukums',
  catalogs: 'Katalogi',
  search_by_categories: 'Meklēt pa kategorijām',
  search_by_catalogs: 'Meklēt pēc katalogiem',
  warning: 'Uzmanību!',
  write_new_catalog_name: 'lūdzu Ievadiet vārdu direktorija',
  write_new_category_name: 'Ievadiet kategorijas nosaukumu',
  add_catalog_warning:
    'Jūs varat saistīt katalogs ar vairākiem tirdzniecības punktiem, lai to izdarītu, izmantojiet "+", lai pievienotu papildu laukus, izvēlētos tirdzniecības vietas.',
  add_catalog_info:
    'Jūs varat pievienot uzreiz vairākās kategorijās vienu direktoriju, lai to izdarītu, izmantojiet "+", lai pievienotu papildu laukus, lai ievadītu nosaukumus jaunu kategoriju. ',
  product_product_date: 'Drukāt izgatavošanas datumu',

  // Ziņojumi
  report_about_item: 'Statistika par preci',
  report_order_id: 'Pasūtījuma Numurs',
  //Adresātu
  mailings: 'Mailing',

  do_add_mailing: 'Izveidot newsletter',
  mailing_new: 'Jauna newsletter',
  mailing_edit: 'Rediģēt adresātu',
  mailing_status_complete: 'Izpildīts',
  mailing_status_saved: 'Saglabāt',
  mailing_status_moderation: 'Uz mērenību',
  mailing_status_wait: 'Gaida došanos',
  mailing_status_cancelled: 'Atcelta',
  mailing_status_ready: 'Samaksāt',
  mailing_add_description:
    'Pievienojiet savu biļetenu. Norādiet nosūtīšanas laiku, klientu mērķauditoriju, un ziņas teksts.',
  mailing_moderation_description:
    'Pēc pagājušo uzraudzību, jums būs jāmaksā biļetenu. Lūdzu, ņemiet vērā, ka moderācija notiek 1-2 darba dienu laikā, samaksa kontā tiek ieskaitīta līdz 2 darba dienām. Ņemiet to vērā, plānojot adresātu.',
  mailing_name: 'Nosaukums adresātu',
  mailing_date: 'Datums nosūtītu',
  mailing_audience: 'Auditorija, lai veiktu adresātu',
  mailing_client_count: 'klientu Skaits',
  mailing_cost: 'Vērtība adresātu',
  mailing_text: 'Teksts adresātu',
  mailing_only_save: 'Saglabāt, bet nevar palaist',
  mailing_save_and_start: 'Palaist biļetenu',
  mailing_invalid_time: 'Uzstādiet laika intervālu no 9:00 līdz 21:00',
  mailing_may_not_pass_moderation: 'Jūsu izsūtīšana, nevar paspēt iziet regulēti.',
  mailing_all_clients: 'klienti',
  mailing_created_filters: 'Gatavus filtrus',
  mailing_selecting_filters: 'Izvēlieties filtra',
  mailing_save_filters: 'Saglabāt filtri',
  mailing_going_to_delete: 'Jūs gatavojas, lai novērstu biļetenu',
  mailing_status_rejected: 'Noraidīts',
  filter_will_be_available_soon: 'Šī funkcionālā drīz būs pieejama',
  select_employee_for_test_message: 'Izvēlieties darbinieku, lai nosūtītu īsziņu ziņas',
  filter_gender: 'Paul',
  filter_age: 'Vecums',
  filter_registered_days_ago: 'reģistrācijas Datums',
  filter_purchases_quantity: 'pirkumu Skaits',

  filter_birth_date_month: 'dzimšanas Diena',
  gender_male: 'Vīriešu',
  gender_female: 'Sieviešu',
  in_this_month: 'šajā mēnesī',
  in_next_month: 'nākamais mēnesis',
  year_ago: 'pirms Gada',

  // Support service
  support_service_greeting: 'Labdien! Mēs būsim priecīgi jums palīdzēt!',

  address_doesnt_correct: 'Adrese nav korekts',
  no_saved_templates: 'Nav saglabātās veidnes',
  add_schedule_and_save_as_template: 'Pievienot opciju, un pēc tam saglabājiet to kā šablonu',

  service_mts_kassa: 'MTS Kase',

  create_new_catalog: 'Izveidot jaunu direktoriju',
  use_existing_catalog: 'Lietot esošu direktoriju',

  log_out_question: 'vai Jūs tiešām vēlaties, lai pabeigtu pašreizējo sesiju?',

  external_id: 'Ārējais id',

  // Tulkošanas kabīnes
  more_then: 'Vairāk {num}',
  before_num: 'Līdz {num}',

  older_then: 'Vecāki {num}',
  before_days: '{_} dienas | Līdz {_} dienas | Līdz {_} dienām',
  do_save_and_go_back: 'Saglabāt un atgriezties pie saraksta',
  print_shelf_life: 'Drukāt derīguma termiņš',
  succes_saved: 'Veiksmīgi saglabāts',
  quantity_cut: 'Skaits',
  additional_data_about_good: 'Papildu informāciju par preci',

  doest_not_req_field: 'obligāti lauki',
  do_upload_photo: 'Augšupielādēt foto',
  upload_good_list: 'Augšupielādēt preces sarakstu',

  show_filters: 'Parādīt filtri',
  hide_filters: 'filtri Paslēpt',
  available_table_filters: 'Filtri tabulas',
  deletion: 'Dzēst',
  item_warning_info: 'Preci ir obligāti jābūt piesaistīts kādai katalogam un kategorijas.',

  aprove_catalog_delete: 'Jūs gatavojas noņemt direktoriju',
  delete_catalog_text_confirm_3:
    'Dzēšot Katalogs tiks izdzēsti visi šīs Kategorijas Produkti iekšpusē.',

  go_to_edit_store: 'Iet uz rediģēšanas noieta',
  choose_trade_point: 'Izvēlieties tirgotavu',
  do_catalog_edit: 'Rediģēt direktorija',
  do_category_edit: 'Rediģēt kategoriju',
  category_warn_text_warn: 'Kategorija būtu piesaistīta kādai katalogu.',

  category_warn_text_link: 'Izveidot jaunu direktoriju.',
  stock_balances: 'Preču atlikumi',
  do_add_items_list: 'Pievienošana preču sarakstu',
  accounting_for_stock_balances: 'Uzskaiti, preču atlikumu',
  rebase_catalogs: 'Dzēst katalogs > Rediģēt noieta',

  do_download_xlsx: 'Augšupielādēt failu .xlsx',
  stock_accounting_balances_info:
    'Lai iegūtu aktuālu informāciju par to, cik un preču izmaksas jūsu tirdzniecības vietās izmantojiet funkciju "Uzskaite, preču atlikumu". ',
  or: 'Vai',
  category_info_tip: 'Izmantojiet šo funkciju, lai ātrāk aizpildītu jaunu kategoriju precēm.',
  good_must_be_in_catalog_or_category:
    'Preci ir obligāti jābūt piesaistīts kādam katalogam un kategorijas.',
  items_add_side_bar_info:
    'Ja jums ir nepieciešams izveidot jaunu direktoriju vai kategoriju pašreizējā direktorijā, dodieties uz sadaļu ',
  do_delete_catalog: 'Noņemt direktoriju',
  delete_catalog: 'Noņemt direktoriju',
  trade_point_edit: 'Rediģēt noieta',
  rebase_catalog_1: ', Lai izdzēstu direktoriju "{name}", jums ir nepieciešams, lai',
  rebase_catalog_2: 'piesaistīt jaunus katalogus visām tirdzniecības vietām',
  rebase_catalog_3: 'šajā tabulā zemāk.',

  aprove_category_delete: 'Jūs gatavojas izdzēst kategoriju',
  delete_category_warn: 'kategorijas Noņemšana radīs noņemt visas preces šajā kategorijā',
  delete_catalog_confirm_text_5:
    'Jūs gatavojas noņemt tukšu direktoriju, kurā nav kategoriju un preci',
  delete_catalog_confirm_text_6: 'Jūs gatavojas noņemt direktoriju kurā ir kategorijas vai preces',
  trade_point_type_name: 'Tips tirdzniecības vietas',
  select_new_catalog: 'Izvēlieties jaunu direktoriju',
  barcoders_prefix_for_weight_good: 'Kodiem svītrkodu svēršanas preces',
  barcoders_prefix_info:
    'Pēc vērtības šo prefiksu sistēma atpazīst svara preces, izmantojot piešķirto ciparu kodu un preces svaru gramos. Pieejamie prefiksiem — no 20 līdz 29. Ja prefikss nav norādīts, tad preces tiks apstrādāti, piemēram, izgatavošana pēc pasūtījuma.',
  age_limit_2: 'gadi',
  age_limit_1: 'Vecuma ierobežojums,',
  catalog_rebase_info: 'vairs nebūs fiksēts ne pret vienu no tirdzniecības vietām, tagad jūs varat',

  item_photo_note:
    '(jūs varat ielādēt savu foto preces vai atstāt foto noklusējuma parādīts zemāk)',
  store_doesnt_have_catalog:
    'Pie Tirdzniecības punkta {name} nē saistās ar ral pārvietošanās uz rediģēšanu ?',

  valid_min_13: 'Vismaz 13 rakstzīmes',
  ok: 'Ok',

  agreement: 'Līgums',
  commission: 'Komisija',
  search: 'meklējot',

  do_download_xlsx_example: 'Lejupielādēt piemērs .xlsx',

  payment_type_undefined: 'Nav definēts',
  videos_subtitle:
    'Šajā sadaļā jūs varat saņemt ierakstītu video no videokameras, kas uzstādītas uz jūsu tirdzniecības vietās',
  homeReferalProgramNote:
    'Lieto pats un atved draugu. Kā tikai viņš reģistrēsies sistēmā, jūs \n saņemsiet cash back no Briskly apmērā % no',
  extended_types_washer_fluid: 'Омывающая šķidrums',
  can_come_back_later: 'Jūs varat iet uz šo soli vēlāk',
  million_short: 'miljons',
  do_skip_and_and_registration: 'Ignorēt un pabeigtu reģistrāciju',
  stage_type_store_radius: 'darbības Rādiuss \n sistēmas Scan&Go',
  stage_type_refrigerator_radius: 'Rādiuss no kura būs \n atvērt durvis',
  stage_type_cigarettes_radius: 'Rādiuss no kura būs \n atvērt durvis',
  stage_type_filling_station_radius: 'darbības Rādiuss \n sistēmas Briskly',
  stage_type_coffee_machine_radius: 'darbības Rādiuss \n kafijas automāti',
  mock_address: 'gada Maskava, v Akadēmiķis Jangila, tālāk, 26',
  week_mon: 'Пн',
  week_tue: 'Вт',
  week_wed: 'Ср',
  week_thu: 'Чт',
  week_fri: 'Пт',
  week_sat: 'Сб',
  week_sun: 'Вс',
  schedule_from: 'no',
  schedule_to: 'tai',

  employee_change_model_role_title: 'Piešķirt lomu šajā tirdzniecības vietā',
  role_checker_name: 'Checker',
  tax_system_simplified_1: 'VNS (ieņēmumi-izdevumi)',
  tax_system_simplified_2: 'VNS (ieņēmumi-6%)',
  requisites_okopf_code: 'OKOPF',
  requisites_ogrn: 'OGRN',
  requisites_okved_code: 'OKVED',
  requisites_bank_correspondent_account: 'Korespondējošais konts',

  requisites_personal_data_descr_part_2:
    'Jūsu dati tiek pieprasīti pēc pieprasījuma CENTRĀLĀS bankas un esquire bankas. Tie tiek glabāti šifrētā veidā. Kompānija Briskly sastāv reģistra personas datu operatoru Roskomnadzor.',
  delivery_pickup: 'Iespēja eksportu',
  planogram: 'Planogramma',
  planogram_photo: 'Foto planogramma',
  going_to_remove_all_items: 'Jūs gatavojas, lai novērstu visas preces ar plauktiem',
  going_to_remove_shelf: 'Jūs gatavojas, lai novērstu plauktā',
  planogram_list: 'Saraksts planogram',
  do_download_new_planogram: 'Lejupielādēt jaunu planogramma',
  service_iiko: 'iiko',
  service_iiko_biz: 'iiko biz',
  service_supermag: 'Supermag',
  unit_id: 'OKEI (Mērvienība)',
  checker_title: 'Izmantojiet programmu checker',
  valid_special_symbols_not_allow: 'Speciālās rakstzīmes, kas nav derīgas',
  stages_description_requisites: 'Statuss: tiek pārbaudītas.\n Pārbaude aizņem 1-3 darba dienas.',
  this_action_cant_canceled: 'Tas noņems',
  planogram_title: 'Planogramma',
  excluded_cost: 'Izslēgt izmaksas',
  excluded_cost_tooltip:
    'Summa čekā nav spēkā atlaide. \n Piemērs: 10% atlaide pirkumam virs 500 rubļu (izslēgt izmaksas 500р) \n pirkuma Summa 900р 10% atlaide darbojas arī uz 400р un būs 40р.',
  create_catalog_label:
    'Kontā var būt vairāki katalogi (piemēram, ledusskapja, kas pārdod gatavu pārtiku vai saldēto produkciju). Izvēloties atbilstošu direktoriju, jums ātri pārslēgties no viena produktu saraksta uz citu. Šeit jums ir nepieciešams, lai izveidotu direktoriju.',
  block_with_modifications: 'Bloks ar šīs',
  with_barcode: 'Ar svītrkodiem',
  error_with_bar_code: 'Kļūda paaudzes svītru kodu!',
  good_with_no_valid_barcode_need_write_it: 'Pie šī produkta nepareizs kods, \n aizpildiet to',
  okved: 'OKVED',
  usn1: 'VNS (ieņēmumi-izdevumi)',
  usn2: 'VNS (ieņēmumi 6%)',

  bank_correspondent_number: 'Korespondentkonts',
  inventory_system: 'Sistēma preču uzskaite',
  integration_file_label:
    'Lai pareizi boot direktorijā, saskaņojiet mūsu lauku nosaukumi ar to, kā tie ir nosaukti par jums.',
  fail_unbind_employee_to_store: 'Nav izdevies nokopt darbinieks',
  delete_catalog_text_confirm_2: 'no tā tiks dzēsti visas kategorijas un preces, kas atrodas to',
  create_catalog_label_2:
    'Kontā var būt vairāki katalogi. \n Piemēram, ledusskapi, kas pārdod gatavu pārtiku vai saldēto produkciju. \n Izvēloties atbilstošu direktoriju, jums ātri pārslēgties no viena produktu saraksta uz citu. \n Šeit ir iespējams nomainīt direktoriju.',
  filter_purchase_days_ago: 'Datums pirkumu',
  item_create_info:
    'Ja jums ir nepieciešams izveidot jaunu direktoriju vai kategoriju pašreizējā direktorijā, dodieties uz sadaļu',
  delete_catalog_text_confirm_4:
    'Lai izdzēstu direktoriju vispirms ir nepieciešams piesaistīt jaunu direktoriju uz visām tirdzniecības vietām, pretī tiek noņemts.',
  category_warn_text: 'Ja jums ir nepieciešams izveidot jaunu direktoriju spied',
  clarification_in_items_side_bar:
    '* Ja preces failā jau ir sadalīti kategorijās, vienkārši izvēlieties Katalogs un lejupielādēt failu Kategorijas tiek izveidoti automātiski.',
  cant_start_from_dot: 'Nevar sākt ar punktu',
  fileupload_page_info:
    '* Ja preces failā jau ir sadalīti kategorijās, vienkārši izvēlieties Katalogs un lejupielādēt failu Kategorijas tiek izveidoti automātiski.',
  success: 'Veiksmīgi',

  uploaded_image_min_width: 'Minimālā attēla platums',
  uploaded_image_max_width: 'Attēla Maksimālais platums',
  uploaded_image_min_height: 'Attēla minimālais augstums',
  uploaded_image_max_height: 'Attēla maksimālais augstums',
  allowed_types: 'Atļautie veidi',
  max_file_size: 'Maksimālais faila lielums',
  standard_no_suf: 'Standarts',
  orders_and_video: 'Pasūtījumi un video',
  orders_not_exist: 'Jūsu tirdzniecības punktos vēl nav pasūtījumu.',
  service_csv: 'csv',

  price_with_currency_inject: 'Cena, {currency}',
  sum_with_currency_inject: 'Summa, {currency}',
  total_amount_price_with_currency_inject: 'Kopējā cena,, {currency}',
  total_amount_sum_with_currency_inject: 'Kopsumma,, {currency}',
  time_of_creation: 'Radīšanas laiks',
  find_by_order_number: 'Meklēt pēc pasūtījuma numura',
  id_trade_point: 'Veikala ID',
  item_id: 'Vienuma ID',
  good_barcode: 'Prece svītrkods',
  option_discount_percent_cut: 'Atlaide, %',
  create_refund: 'Izveidot kompensāciju',
  created_at: 'Radīšanas laiks',
  customer_id: 'Klients',
  store: 'Uzglabāt',

  //Новые ключи

  valid_minimum: 'Minimālais rakstzīmju skaits',

  coords_lng: 'Ģeogrāfiskais garums',
  coords_lat: 'Latitude',

  invalid_intervals_filled: 'Lauki nav aizpildīti',
  invalid_intervals_to_less_then_from: 'Nederīgi intervāli',
  invalid_intervals_intersections: 'Intervāli nedrīkst pārklāties',
  valid_max_250: 'Ne vairāk kā 250 rakstzīmes',

  module_description_temp:
    'Brašais modulis ir jūsu mikromarketa smadzenes. Iegādājoties, jums tika piešķirts kods, kas jāsaista ar jūsu kontu.',
  value_must_be_more_than_zero: 'Vērtībai jābūt lielākai par 0',

  dosesnt_correct_number: 'Nederīgs numurs',
  success_deleted: 'Veiksmīgi attālināti',
  do_return_back: 'Atgriezties',
  abort_procedure: 'Pārtraukt procedūru',
  abort: 'Pārtraukt',
  maybe_data_not_saved: 'Veiktās izmaiņas var netikt saglabātas',
  empty_catalog: 'Katalogs ir tukšs',

  valid_max_11: 'Maksimāli 11 rakstzīmes',
  payment_type: 'Maksājuma veids',
  app: 'Pieteikums',
  terminal: 'Termināls',
  order_with_id_inject: 'Pasūtījuma ID {orderId}',
  refund_with_order_id_inject: 'Kompensācijas: pasūtījuma ID {orderId}',
  barcode_can_includes_only_digits: 'Tikai numuri',
  copy_to_clipboard: 'Kopēt uz buferi',
  copied_to_clipboard: 'Kopēts starpliktuvē',
  about_order: 'Par Pasūtījumu',
  refund_title: 'Kompensācijas veikšana',
  amout_goods_to_refund: 'Kopā atmaksājamie posteņi',
  amount_goods_payment: 'Kompensācijas apmērs',
  technical_support: 'Tehniskais atbalsts',
  add_refund: 'Pievienot kompensāciju',
  view_refund: 'Skatīt kompensācijas',
  onboarding_virtual_terminal: 'Virtuālais terminālis',
  virtual_terminal: 'Virtuālais terminālis',
  select_virtual_terminal: 'Izvēlieties virtuālo termināli',
  virtual_terminal_description:
    'Aizpildiet informāciju par savu virtuālo termināli, tie ir nepieciešami, lai ieskaitītu līdzekļus savā kontā, kā arī lai izveidotu čeku klientam.',
  add_new_virtual_terminal: 'Pievienot termināli  ',
  do_create_refund: 'Izveidot kompensāciju',

  delete_goods_modal_info: 'un {stringsQuantity}... vairāk produktu',
  client_show_settings_2: 'Mobilās lietotnes displeja iestatījumi',
  no_changes_to_update: 'Nav izmaiņu, lai saglabātu',
  for_go_to_exchange_need_say_store: 'Izvēlieties tirdzniecības punktu',
  link_to_showcase: 'Saite uz produkta vitrīnu',
  catalog_successfully_added: 'Katalogs veiksmīgi pievienots',
  refund_has_been_created: 'Atmaksa izveidota',
  no_trans_action: 'Nav darījumu',
  no_refund_text_info: 'Par šo pasūtījumu nav bijis nekādu kompensāciju.',
  date_should_not_earlier: 'Datums nedrīkst būt agrāk',
  date_should_not_later: 'Datumam jābūt ne vēlāk kā',
  cant_play_video: 'Nevar atskaņot video',
  check_refund_possibility: 'Pārbaudiet atmaksas iespējas',
  refund_helper_text: 'Lai ātri meklētu produktu, ievadiet tā id, svītrkodu vai nosaukumu',
  no_one_good_selected: 'Neviena prece nav izvēlēta atmaksai.',
  errors_occurred_while_creating_the_refund: 'Kļūdas radās, veidojot kompensāciju:',
  refund_by_id_goes_successed: 'Atmaksa par pasūtījuma id {refundId} bija veiksmīga',
  refund_by_id_goes_accept: 'Apstipriniet atmaksu par pasūtījuma id {refundId}',
  refund_technical_support_notice: 'Lai veiktu atmaksu, lūdzu, sazinieties ar tehnisko atbalstu.',
  refund_money_back_notice: 'Nauda tiks ieskaitīta klienta kartē 2 dienu laikā no atmaksas dienas.',
  refund_ready_title: 'Pasūtījuma atmaksa',
  refund_check_success_info: 'Atmaksu var veiksmīgi izveidot',
  refund_check_success_info_with_order_id: 'Atmaksas pasūtījuma ID {orderId} izveidots veiksmīgi',
  approve_refund: 'Apstipriniet atmaksu',
  video_not_available: 'Video nav pieejams',
  video_tooltip_error:
    'Video fails ir bojāts vai īslaicīgi nav pieejams. Lūdzu, sazinieties ar atbalsta dienestu',
  do_upload: 'Lejupielādēt',
  amout_for_refund: 'Kopā atmaksājamie posteņi',
  does_not_correct_control_sum: 'Nederīga kontrolsumma',

  active_status: 'Gaida samaksu',
  paid_status: 'Apmaksāts',
  refund_status: 'Viņš ir atgriezies',
  refund_awaiting_status: 'Gaidāma atdeve',
  refund_error_status: 'Atgriešanās kļūda',
  refund_partial_status: 'Daļēji atmaksāta',
  removed_status: 'Izdzēsts',

  online_store_layouts: 'Izkārtojumi līmēšana ledusskapis',
  promo_stickers: 'Uzlīmes',
  promo_side_stickers: 'Sānu Uzlīmes',
  promo_on_glas: 'Uz stikla',
  promo_on_advertising_block: 'Par reklāmu vienību',

  promo_without_ad_block: '(bez reklāmu bloķēšanas)',
  promo_with_ad_block: '(ar reklāmu vienību)',
  attention: 'Uzmanība',
  requisites_skip_warning:
    'Ja jūs izlaist soli izvēloties / pievienojot informāciju, jūsu tirdzniecības punkts darbosies testa režīmā.',
  select_full_party_description:
    'Jūs varat izvēlēties iepriekš izmantotās detaļas vai pievienot jaunas, vienkārši aizpildot veidlapu!',
  select_partial_party_description: 'Savienojiet savu virtuālo termināli ar savu personīgo kontu!',
  pick_terminal: 'Izvēlieties termināli',
  add_new_full_party: 'Pievienot jaunu',
  add_new_partial_party: 'Pievienot jaunu',
  swift_bic: 'SWIFT / BIC',
  secure3d: '3DS',
  non_secure3d: 'nav 3DS',
  full_party_choosing: 'Izvēloties detaļas',
  partial_party_choosing: 'Termināļa izvēle',
  requisites_and_terminals: 'Juridiskās personas',
  terminals: 'Virtuālie termināli',
  full_party_status_active: 'Aktīvo',
  full_party_status_pending: 'Pārbaude',
  full_party_status_replacement: 'Aizstāt',
  full_party_edit: 'Rekvizītu rediģēšana',
  partial_party_edit: 'Virtuālā termināļa rediģēšana',
  adding_new_terminal: 'Jauna termināļa pievienošana',
  add_full_party: 'Pievienot rekvizītus',
  add_partial_party: 'Pievienot virtuālo termināli',
  full_party_edit_warning:
    'Pēc izmaiņām detaļas tiks pārbaudītas kādu laiku. Jaunas detaļas tiks saglabātas tirdzniecības punktiem tūlīt pēc pārbaudes.',
  partial_party_edit_warning:
    'Pēc izmaiņām terminālis tiks pārbaudīts kādu laiku. Jaunais terminālis tiks saglabāts tirdzniecības punktiem tūlīt pēc pārbaudes.',
  kpp: 'KPP',
  upload_logo_in_format: 'Augšupielādējiet savu logotipu',
  upload_logo_with_min_size: 'Minimālais attēla izmērs',
  upload_logo_with_min_file_size: 'Maksimālais faila lielums',
  currency: 'Valūta',

  BYN: 'Baltkrievijas rublis(BYN)',
  USD: 'ASV dolārs(USD)',
  EUR: 'Eiro(EUR)',
  KZT: 'Kazahstānas tenge(KZT)',
  CAD: 'Kanādas dolārs(CAD)',
  CNY: 'CNY(CNY)',
  RUB: 'Rublis(RUB)',
  TRY: 'Turcijas lira(TRY)',
  UAH: 'Ukrainas grivna(UAH)',
  GBP: 'GBP(GBP)',
  CHF: 'Šveices franks(CHF)',

  bank_name: 'Bankas nosaukums',
  company_name: 'Pilns uzņēmuma nosaukums',
  serial_number: 'Sērijas numurs',
  coffee_reeboot: 'Atsāknēšana',
  coffee_reeboot_coffee_machine: 'Restartējiet kafijas automātu',
  coffee_reeboot_text: 'Ierīces restartēšana palīdz atrisināt vairākas problēmas.',
  defer_service: 'Atlikt obligāto pakalpojumu',
  coffee_postpone: 'Atlikt',
  coffee_service_maintenance_text:
    'Pakalpojuma nosacījums tiks atlikts līdz nākamajai aktivizēšanai.',
  block_drinks: 'Bloķēt dzērienu pagatavošanu',
  coffee_block_text:
    'Kafijas automāts paliks aktīvs, bet dzērienu pagatavošana klientiem nebūs pieejama',
  block_drink_change: 'Bloķēt dzēriena parametru maiņu',
  coffee_props_block_text:
    'Pieejams sagatavošanas laikā. Kad tas ir aktivizēts, klienti nevarēs Pievienot izmaiņas standarta dzērienā.',
  block_menu_access: 'Bloķēt piekļuvi pielāgotajai izvēlnei',
  coffee_menu_text: 'Klienti nevarēs izmantot kafijas automāta izvēlni.',
  block_drinks_when_offline:
    'Bloķējiet dzērienu sagatavošanu, ja nav savienojuma ar serveri ilgāk par 48 stundām',
  coffee_reloading: 'Atsāknēšana ...',
  search_by_items: 'Meklēt pēc produktiem',
  coffee_item_photo: 'Produkta foto',
  coffee_item_photo_info:
    '(varat augšupielādēt savu produkta fotoattēlu vai atstāt zemāk redzamo noklusējuma fotoattēlu)',
  coffee_item_type: 'Dzērienu veids',
  item_recept: 'Produkta recepte',
  value_ml: 'ml',
  cup_with_units_injection: 'Stikls {cupsize} ml',
  drink_with_units_injection: 'Dzēriens {Drinksize} ml',
  coffee_foam: 'Piena putas',
  coffee_milk: 'Piens',
  coffee_water: 'Ūdens',
  coffee_coffee_size: 'Kafija',
  return_values_to_default: 'Atjaunot noklusējuma vērtības',
  price_must_be_more_then_inject: 'Cenai jābūt augstākai {price}',
  coffee_item_photo_info__temp:
    'Varat atstāt noklusējuma fotoattēlu vai izvēlēties kādu no pieejamajiem, noklikšķinot uz pogas Atlasīt',
  block_drink_changes: 'Bloķēt dzēriena parametru maiņu',

  file_name_uploaded: 'Fails {fileName} augšupielādēts',
  columns: 'Kolonna',
  file_upload_rules: 'Faila prasības produkta augšupielādei:',
  file_upload_rules_1: 'Formāts — .xlsx;',
  file_upload_rules_2: 'Failā nedrīkst būt apvienotas šūnas.',
  file_upload_rules_3: 'Lauku nosaukumiem jābūt ne vairāk kā vienai rindai.',
  file_upload_rules_4:
    "Kļūdu gadījumā laukos 'mērvienības' un ' daudzums ...'- '1 gabals' tiks automātiski ievadīts.",
  are_mandatory: 'ir obligāti',
  file_upload_page_info:
    'Lai pareizi ielādētu katalogu, saskaņojiet mūsu lauku nosaukumus ar to, kā tie jums ir nosaukti.',
  edit_file_and_try_again: 'Labot kļūdas un mēģiniet vēlreiz',
  do_stop_now: 'Pabeigt tagad',
  no_save_upload_remember:
    "Noklikšķinot uz pogas 'Pabeigt tūlīt', jūs apstiprināt ... nepareiza datu parādīšana par jūsu produktiem.",
  exchange_drpops_with_exception: 'Integrācijas laikā radās kļūdas',
  good_must_be_in_catalog_or_category_rewrite: 'Produktam jābūt saistītam ar katalogu.',
  fileupload_page_info_rewrite:
    '* Ja failā esošie produkti jau ir sadalīti kategorijās, vienkārši atlasiet Katalogs un augšupielādējiet failu, kategorijas tiks izveidotas automātiski.',
  loading: 'Iekraušana...',
  show_detail_info: 'Rādīt detalizētu informāciju',
  file_upload_success_info:
    "Noklikšķinot uz 'Pievienot', jūs apstiprināt produktu ielādi attiecīgajā katalogā un kategorijās.",
  be_created: 'Tika izveidoti',
  selected_catalog: 'Katalogs izvēlēts',
  file_uploaded: 'Failu augšupielāde',
  was_successful: 'bija veiksmīgs',
  and: 'un',
  valid_min: 'Minimālais {min}',
  value_mm: 'mm',
  unit_796: 'lieta',
  unit_657: 'Produkts',
  unit_111: 'tilpums (ml)',
  unit_112: 'Litrs',
  unit_163: 'Grami(g)',
  unit_625: 'Lapa',
  unit_704: 'Noteikt',
  unit_715: 'Pāris',
  unit_166: 'Kilogramu(kg)',
  unit_736: 'Roll',
  unit_778: 'Iepakojums',
  item_from_upload_info: 'Lai ātri pievienotu produktus katalogam, izmantojiet funkciju',

  promo_briskly_link: 'Promo materiāli',
  store_name_placeholder: 'Tirdzniecības vietas nosaukums',
  serial_number: 'Sērijas numurs',
  coffee_reeboot: 'Atsāknēšana',
  coffee_reeboot_coffee_machine: 'Restartējiet kafijas automātu',
  coffee_reeboot_text: 'Ierīces restartēšana palīdz atrisināt vairākas problēmas.',
  defer_service: 'Atlikt obligāto pakalpojumu',
  coffee_postpone: 'Atlikt',
  coffee_service_maintenance_text:
    'Pakalpojuma nosacījums tiks atlikts līdz nākamajai aktivizēšanai.',
  block_drinks: 'Bloķēt dzērienu pagatavošanu',
  coffee_block_text:
    'Kafijas automāts paliks aktīvs, bet dzērienu pagatavošana klientiem nebūs pieejama',
  block_drink_change: 'Bloķēt dzēriena parametru maiņu',
  coffee_props_block_text:
    'Pieejams sagatavošanas laikā. Kad tas ir aktivizēts, klienti nevarēs Pievienot izmaiņas standarta dzērienā.',
  block_menu_access: 'Bloķēt piekļuvi pielāgotajai izvēlnei',
  coffee_menu_text: 'Klienti nevarēs izmantot kafijas automāta izvēlni.',
  block_drinks_when_offline:
    'Bloķējiet dzērienu sagatavošanu, ja nav savienojuma ar serveri ilgāk par 48 stundām',
  coffee_reloading: 'Atsāknēšana ...',
  search_by_items: 'Meklēt pēc produktiem',
  coffee_item_photo: 'Produkta foto',
  coffee_item_photo_info:
    '(varat augšupielādēt savu produkta fotoattēlu vai atstāt zemāk redzamo noklusējuma fotoattēlu)',
  coffee_item_type: 'Dzērienu veids',
  item_recept: 'Produkta recepte',
  value_ml: 'ml',
  cup_with_units_injection: 'Stikls {cupsize} ml',
  drink_with_units_injection: 'Dzēriens {Drinksize} ml',
  coffee_foam: 'Piena putas',
  coffee_milk: 'Piens',
  coffee_water: 'Ūdens',
  coffee_coffee_size: 'Kafija',
  return_values_to_default: 'Atjaunot noklusējuma vērtības',
  price_must_be_more_then_inject: 'Cenai jābūt augstākai {price}',
  coffee_item_photo_info__temp:
    'Varat atstāt noklusējuma fotoattēlu vai izvēlēties kādu no pieejamajiem, noklikšķinot uz pogas Atlasīt',
  block_drink_changes: 'Bloķēt dzēriena parametru maiņu',
  heating: 'šildymas',
};
