export default [
  {
    path: 'orders',
    name: 'orders$orders',
    component: () => import('@/apps/orders/views/Orders/Orders'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: 'main',
        },
        {
          label: 'orders',
          name: 'orders$orders',
        },
      ],
    },
  },
  {
    path: 'orders/order/:orderId',
    name: 'orders$orders-order',
    component: () => import('@/apps/orders/views/Order/Order'),
    props: true,
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: 'main',
        },
        {
          label: 'orders',
          name: 'orders$orders',
        },
        {
          label: 'order',
          name: '',
        },
      ],
    },
  },
  {
    path: 'orders/order/refund/create/:orderId',
    name: 'orders$orders-refund-create-order',
    component: () => import('@/apps/orders/views/CreateRefund/CreateRefund'),
    props: true,
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: 'main',
        },
        {
          label: 'orders',
          name: 'orders$orders',
        },
        {
          label: 'refund_title',
          name: '',
        },
      ],
    },
  },
  {
    path: 'orders/order/refund/:refundId',
    name: 'orders$orders-refund-order',
    component: () => import('@/apps/orders/views/Refund/Refund'),
    props: true,
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: 'main',
        },
        {
          label: 'orders',
          name: 'orders$orders',
        },
        {
          label: 'refund_ready_title',
          name: '',
        },
      ],
    },
  },
];
