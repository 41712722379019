import { sortBy, uniq } from 'lodash';
import { CALC_TYPES } from '@/apps/trade-point/data/delivery';

const getEmptyRate = (min, max) => ({ 
  order_amount_min: min || '0',
  order_amount_max: max || '0',
  calculation_type: CALC_TYPES.FIXED,
  calculation_val: '1',
  calculation_round_mode: '0',
  calculation_round_precision: '0',
});

const buildInitialRates = () => [getEmptyRate('1', '999 999')];

const normalizeNum = (num) => typeof num === 'string' 
  ? Number(num.replace(/\s/g, ''))
  : num;

const processRates = (rates) => rates.map(({ rate, idx }) => {
  const processedRate = Object
    .entries(rate)
    .reduce((acc, [key, val]) => {
      const value = normalizeNum(val);

      return { ...acc, [key]: value };
    }, {});

  switch (processedRate.calculation_type) {
    case CALC_TYPES.UNKNOWN:
    case CALC_TYPES.FREE: return { 
      rate: { ...processedRate, calculation_val: 0 }, 
      idx 
    };

    default: return { rate: processedRate, idx };
  }
});

const isRatesValid = (rates) => rates.length && rates.every((rate) => {
  const min = normalizeNum(rate.order_amount_min);
  const max = normalizeNum(rate.order_amount_max);
  const calcVal = normalizeNum(rate.calculation_val);
  const calcType = rate.calculation_type;

  return !!(
    min &&
    max &&
    min < max &&
    ([CALC_TYPES.UNKNOWN, CALC_TYPES.FREE].includes(calcType) || calcVal) &&
    (calcType !== CALC_TYPES.DISCOUNT_PERCENT || calcVal <= 100)
  );
});

const isRadiusValid = (radius) => normalizeNum(radius) > 0;

const sortRatesByInterval = (rates) => sortBy(rates, (rate) => {
  return normalizeNum(rate.order_amount_min);
});

const getInvalidFields = (rates, type) => rates.reduce(
  (acc, rate, idx, rates) => {
    const prevRate = rates[idx - 1];
    if (!prevRate) return acc;

    const prevMax = normalizeNum(prevRate.order_amount_max);
    const curMin = normalizeNum(rate.order_amount_min);

    return curMin - prevMax === 1
      ? acc
      : uniq([
        ...acc,
        `${type}_max_${idx - 1}`,
        `${type}_min_${idx}`,
      ]);
  },
  [],
);

export { 
  getEmptyRate, 
  processRates, 
  buildInitialRates,
  isRatesValid,
  sortRatesByInterval,
  getInvalidFields,
  isRadiusValid,
};
