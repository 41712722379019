import { companyAPI } from '@/core/api/company';
// import { shuffle } from 'lodash';

const initialState = () => ({
  presetItems: [],
  showGoodsOnPage: 50,
  currentPage: 1,
  coffeeListMeta: {},
  currentCatalogId: 0,
  search: '',
});

const state = initialState();

const getters = {
  drinkTypes: (state) => state.presetItems,
  filteredPresetItems: (state) => state.presetItems.filter((i) => i.is_preset),
  showGoodsOnPage: (state) => state.showGoodsOnPage,
  currentPage: (state) => state.currentPage,
  coffeeListMeta: (state) => state.coffeeListMeta,
  currentCatalogId: (state) => state.currentCatalogId,
  search: (state) => state.search,
};

const actions = {
  setDrinkTypes: async ({ commit }) => {
    try {
      const presetItems = await companyAPI
        .getDrinkTypes()
        .then((items) => items.map((itm) => ({ ...itm, isExist: true })));
      commit('_setDrinkTypes', presetItems);
    } catch (error) {}
  },

  setImageExist: ({ commit }, { id, value }) => {
    commit('_setImageExist', { id, value });
  },

  setCurrentPage: async ({ commit }, page) => {
    commit('_setCurrentPage', page);
  },

  setShowGoodsOnPage: async ({ commit }, perPage) => {
    commit('_setShowGoodsOnPage', perPage);
  },

  setMeta: async ({ commit, state }, meta) => {
    const newMeta = { ...meta, limit: state.showGoodsOnPage };
    commit('_setMeta', newMeta);
  },

  setSearch: async ({ commit }, search) => {
    commit('_setSearch', search);
  },

  setCatalogIdandGetStore: async ({ commit }, { storeId }) => {
    try {
      const store = await companyAPI.getStoreById({ id: storeId });
      commit('_setCatalogId', store.catalog_id);
      return store;
    } catch (error) {}
  },
};

const mutations = {
  _setDrinkTypes: (state, items) => {
    state.presetItems = items;
  },
  _setImageExist: (state, { id, value }) => {
    state.presetItems = state.presetItems.map((item) => {
      if (item.external_id !== id) return item;
      return { ...item, isExist: value };
    });
  },
  _setCurrentPage: (state, page) => {
    state.currentPage = page;
  },
  _setMeta: (state, meta) => {
    state.coffeeListMeta = meta;
  },
  _setShowGoodsOnPage: (state, perPage) => {
    state.showGoodsOnPage = perPage;
  },
  _setCatalogId: (state, id) => {
    state.currentCatalogId = id;
  },
  _setSearch: (state, search) => {
    state.search = search;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
