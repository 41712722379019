import { fill } from 'lodash';
import { nanoid } from 'nanoid';

const getEmptyShelf = (id) => ({ items: [], id });

const buildInitialShelfs = () =>
  fill(Array(3), getEmptyShelf(0)).map((shelf, idx) => ({ ...shelf, id: idx + 1 }));

const flatShelfs = (shelfs) =>
  shelfs
    .map((shelf, shelfIndex) => {
      return shelf.items.map((item, itemIndex) => ({
        item_id: item.id,
        order: itemIndex + 1,
        shelf: shelfIndex + 1,
      }));
    })
    .flat();

const normalizeData = (data) => {
  let planItems = data

    .map((i) => ({
      ...i.item,
      order: i.order,
      shelf: i.shelf,
    }))
    .reduce((acc, item, index) => {
      const shelf = item.shelf - 1;

      if (!acc[shelf]) acc[shelf] = { id: index + 1, items: [] };
      acc[shelf].items.push(item);
      return acc;
    }, []);
  planItems = [...planItems]
    .map((i) => {
      if (!i) return { items: [] };
      return i;
    })
    .map((shelf, _, shelfs) => {
      const newItems = shelf.items.map((item) => {
        const _id = nanoid();
        return { ...item, _id };
      });
      return { ...shelf, items: newItems };
    });

  return planItems;
};
export { getEmptyShelf, buildInitialShelfs, flatShelfs, normalizeData };
