export default {
  valid_upload_both_photos: 'Ανεβάστε και τις δύο εικόνες',
  mailing_status_rejected: 'Απορρίφθηκε',
  mailings: 'Ενημερωτικά δελτία',
  catalog: 'Κατάλογος',
  catalogs_and_categories: 'Κατάλογοι και κατηγορίες',

  filter_gender: 'ΓΕΝΟΣ',
  filter_age: 'Ηλικία',
  filter_registered_days_ago: 'Ημερομηνία Εγγραφής',
  filter_purchases_quantity: 'Αριθμός αγορών',
  filter_purchase_days_ago: 'Ηλικία αγορών',
  filter_birth_date_month: 'Γενέθλια',

  gender_male: 'ΑΡΣΕΝΙΚΟ',
  gender_female: 'θηλυκός',

  before_num: 'Κάτω των {num}',
  older_then: 'Πάνω από {num}',

  before_days: '{_} μέρα | έως {_} μέρες | έως {_} μέρες',
  year_ago: 'Πριν από ένα χρόνο',

  more_then: 'Περισσότερα {num}',

  in_this_month: 'Σε αυτόν τον μήνα',
  in_next_month: 'Τον επόμενο μήνα',

  select_employee_for_test_message: 'Επιλέξτε έναν υπάλληλο για να στείλετε ένα δοκιμαστικό μήνυμα',
  filter_will_be_available_soon: 'Αυτή η λειτουργικότητα θα είναι σύντομα διαθέσιμη',

  documents_subtitle: `Σε αυτήν την ενότητα, πρέπει να εξοικειωθείτε με τους κανόνες λειτουργίας της πλατφόρμας και άλλα νομικά έγγραφα. <br /> <br /> Επίσης, εμφανίζει την τρέχουσα κατάσταση των εγγράφων που απαιτούνται για τη σωστή αλληλεπίδραση μεταξύ εσάς και του Briskly. <br/> Συμπεριλαμβανομένου, είναι δυνατή η απομακρυσμένη υπογραφή ορισμένων εγγράφων χρησιμοποιώντας τη λειτουργία επιβεβαίωσης SMS.`,

  reports_subtitle: `Η ενότητα "Αναφορές" σάς επιτρέπει να δημιουργήσετε οπτικές αναλυτικές αναφορές της επιχείρησής σας στην πλατφόρμα τεχνολογίας b: pay. <br /> Λάβετε σαφείς, αξιόπιστες πληροφορίες σχετικά με τις παραμέτρους και τα δείγματα που σας ενδιαφέρουν, και με βάση αυτά τα δεδομένα, βελτιώστε τις επιχειρηματικές σας διαδικασίες, αυξήστε την αποτελεσματικότητα των καταστημάτων λιανικής και αυξήστε το εισόδημά σας!`,

  videos_subtitle: `Σε αυτήν την ενότητα μπορείτε να λάβετε εγγραφές βίντεο από κάμερες εγκατεστημένες στα καταστήματα λιανικής πώλησης`,

  support_service_greeting: 'Γειά σου! Θα χαρούμε να σας βοηθήσουμε!',
  homeTitle: 'Αρχική οθόνη',
  homeSubtitle: 'Στην οθόνη αυτή, σύντομα θα τα στατιστικά στοιχεία πωλήσεων για τα σημεία πώλησης',
  homeSubtitleWithStat:
    'Στην οθόνη αυτή εμφανίζεται στατιστικά στοιχεία πωλήσεων για τα σημεία πώλησης',
  homeReferalProgramNote:
    'Χρησιμοποιείς μόνος σου, ζήτα από ένα φίλο. Όπως μόνο αυτός που θα εγγραφούν στο σύστημα, θα↵ λάβετε επιστροφή μετρητών από Briskly σε ποσοστό % του',
  referalProgram: 'Ένα πρόγραμμα παραπομπής',
  trade_points: 'Σημεία πώλησης',
  on_map: 'Στο χάρτη',
  do_add_trade_point: 'Προσθέστε το εμπορικό σημείο',
  bind: 'Snap',
  doesnt_select: 'Δεν έχει επιλεγεί',
  select_dates: 'Ημερομηνία',
  trade_point_list: 'Λίστα με καταστήματα λιανικής πώλησης',
  extended_types_washer_fluid: 'Омывающая υγρό',
  number: 'Δωμάτιο',
  cost: 'Το κόστος',
  id: 'ID',
  do_open: 'Άνοιγμα',
  do_close: 'Κλείσιμο',
  do_search: 'Αναζήτηση',
  results: 'Τα αποτελέσματα',
  by_list: 'Λίστα',
  name: 'Όνομα',
  continue: 'Συνεχίστε',
  revenue: 'Τα έσοδα',
  averageCheck: 'Το Μέσο Επιταγή',
  save: 'Αποθήκευση',
  skip: 'Χάσετε',
  metr: 'μ.',
  χλμ: 'χλμ.',
  can_come_back_later: 'Μπορείτε να επιστρέψετε σε αυτό το βήμα αργότερα',
  million_short: 'εκατομμύρια',
  do_select: 'Επιλέξτε',
  thousand_short: 't',
  do_change: 'Αλλαγή',
  first_name: 'Όνομα',
  required_fields: 'Υποχρεωτικά στήλες',
  do_repeat: 'Επαναλάβετε',
  do_check: 'Ελέγξτε',
  last_name: 'Επώνυμο',
  middle_name: 'Πατρώνυμο',
  trade_point: 'Εμπορικό σημείο',
  trade_point_count: 'Ο αριθμός των σημείων',
  role: 'Ρόλο',
  next_step: 'Το επόμενο βήμα',
  yes: 'Ναι',
  no: 'Όχι',
  do_skip_and_finish: 'Να χάσετε και να ολοκληρώσει το',
  do_end_registration: 'Ολοκληρώσετε την εγγραφή',
  do_skip_and_end_registration: 'Να χάσετε και να ολοκληρώσετε την εγγραφή',
  from: 'Από',
  before: 'Πριν',
  do_cancel: 'Ακύρωση',
  cancel: 'Ακύρωση',
  format: 'Μορφή',
  do_download_file: 'Κατεβάστε το αρχείο',
  total: 'Συνολικά',
  pick_or_input: 'Επιλέξτε επιλογή ή συνεχίστε τάση',
  search: 'Αναζήτηση',
  step: 'Βήμα',
  all: 'Όλα',
  price: 'Τιμή',
  here: 'Εδώ',
  go_to: 'Πηγαίνετε',
  price_with_currency2: 'Τιμή, ₽*',
  do_create: 'Δημιουργία',
  active_status_yes: 'Είναι ενεργή',
  active_status_no: 'Είναι ανενεργό',
  pack_min: 'σ',
  go_to_page: 'Επισκεφτείτε τη σελίδα',
  do_send: 'Αποστολή',
  yesterday: 'Χθες',
  today: 'Σήμερα',
  you: 'Θα',
  main_page: 'Αρχική σελίδα',
  logout: 'Έξοδος',
  shortcut_week: '7 ημέρες',
  shortcut_month: '31 ημέρα',
  among: 'Από',
  time_zone: 'Ζώνη ώρας',
  pick_time_zone: 'Επιλέξτε χρονική ζώνη',
  ready: 'Τέλος',
  extended_types_store: 'Κατάστημα',
  extended_types_refrigerator: 'Ψυγείο',
  extended_types_store_online: 'Το Ηλεκτρονικό Κατάστημα',
  extended_types_cafe: 'Καφέ',
  extended_types_production_with_delivery: 'Η παραγωγή με την παράδοση',
  extended_types_filling_station: 'ΒΕΝΖΙΝΆΔΙΚΟ',
  extended_types_cigarettes: 'Τσιγάρου γραφείο',
  extended_types_coffee_machine: 'Καφετιέρα',
  stage_type_title: 'Προσθέστε το εμπορικό σημείο',
  stage_type_edit_title: 'Βασικές πληροφορίες',
  stage_type_subtitle: 'Επιλέξτε τον τύπο καταστήματος',
  stage_type_description:
    'Επιλέξτε την κατηγορία: κατάστημα καφέ ή μια άλλη από τη λίστα που εμφανίζεται. Διαβάστε περισσότερα σχετικά με τις δυνατότητες κάθε κατηγορία εδώ',
  stage_type_label_phone: 'Τηλέφωνο καταστήματος',
  address: 'Διεύθυνση',
  stage_type_label_floor: 'Όροφος',
  stage_type_store_radius: 'Ακτίνα δράσης↵συστημάτων Scan&amp;Go',
  stage_type_refrigerator_radius: 'Ακτίνα με την οποία θα↵ανοίξει την πόρτα',
  stage_type_cigarettes_radius: 'Ακτίνα με την οποία θα↵ανοίξει την πόρτα',
  stage_type_filling_station_radius: 'Ακτίνα δράσης↵σύστημα Briskly',
  stage_type_coffee_machine_radius: 'Ακτίνα δράσης↵μηχανής',
  stage_design_description:
    'Επιλέξτε πώς θα φαίνεται η εμπορική σημείο στο παράρτημα Β-Pay. Ή ανεβάστε το λογότυπο. Θα εμφανιστεί στην κάρτα του προϊόντος',
  stage_design_label_select: 'Επιλέξτε το σχεδιασμό',
  stage_design_create_new: 'Δημιουργήστε ένα νέο σχεδιασμό',
  agreement: 'Συμφωνία',
  promo_briskly: 'Promo υλικά Briskly',
  fuel: 'Καύσιμα',
  date_input_placeholder: 'ηη.χιλιοστά.εεεε',
  finish_registration: 'Ολοκληρώστε την εγγραφή',
  purchases_quantity: 'Αριθμός αγορές',
  profit: 'Εισόδημα',
  do_open_new_trade_point: 'Ανοίξτε ένα νέο σημείο',
  support: 'Η υπηρεσία υποστήριξης',
  profile: 'Προσωπικό γραφείο',
  error_modal_title: 'Δεν μπορείτε να ενεργοποιήσετε το εμπορικό σημείο',
  design_title: 'Σχεδιασμός',
  card_is_missing: 'Δεν δεμένη',
  your_logo: 'Το λογότυπο',
  your_logo_in_format: 'Το логитип σε μορφή',
  preview_for_app: 'Προεπισκόπηση για το κατάστημα στην εφαρμογή',
  enter_text: 'Πληκτρολογήστε το κείμενο',
  pick_background: 'Επιλέξτε φόντο',
  little_background: 'Φόντο για το μικρό προεπισκόπηση σε μορφή',
  big_background: 'Φόντο για το μεγάλο προεπισκόπηση σε μορφή',
  do_change_preview: 'Αλλαγή αντίχειρες',
  mock_address: 'Μόσχα, οδός Σεδ Янгеля, 26 λπ.',
  store_photos: 'Φωτογραφία εμπορικό σημείο',
  schedule: 'Το πρόγραμμα',
  schedule_title: 'Το πρόγραμμα',
  work_schedule: 'Το πρόγραμμα εργασίας',
  schedule__description:
    'Εγκαταστήστε το πρόγραμμα εργασίας του ψυγείο: τι ώρα ανοίγει για ψώνια, και σε ποια χρονική στιγμή ο χρήστης.',
  do_download_from_template: 'Λήψη από τα πρότυπα',
  start_day_after_visiting_courier: 'Έναρξη εργάσιμη ημέρα μετά την επίσκεψη του courier',
  save_as_template: 'Αποθήκευση ως πρότυπο',
  do_enter_name: 'Πληκτρολογήστε το όνομα του',
  week_mon: 'Δευ',
  week_tue: 'W',
  week_wed: 'Τετ',
  week_thu: 'Πέμ',
  week_fri: 'Παρ',
  week_sat: 'Sat',
  week_sun: 'Κυρ',
  week_all_day: 'Όλες τις ημέρες',
  permanently: 'Όλο το εικοσιτετράωρο',
  weekend: 'Ρεπό',
  do_add_break: 'Προσθέστε ένα διάλειμμα',
  schedule_from: 'με',
  schedule_to: 'με',
  templates: 'Πρότυπα',
  create_employees_title: 'Προσθήκη προσωπικού',
  employees_description:
    'Σε αυτή την οθόνη μπορείτε να προσθέσετε το προσωπικό του καταστήματος, επιλέξτε τα από τη λίστα ή φέρτε τα δεδομένα των νέων. Μπορείτε να την εκχωρήσετε το ρόλο τους συμφωνα με τα καθήκοντα. Από προεπιλογή, ο επικεφαλής συνδεδεμένο με όλους τους ρόλους. Διαβάστε περισσότερα στην ενότητα αναφοράς.',
  pick_employees: 'Επιλέξτε το προσωπικό',
  delete_employee_modal_title: 'Πρόκειται να διαγράψετε υπαλλήλου',
  employees_selected: 'Έχετε επιλέξει',
  employee_change_modal_title: 'Αλλαγή δεδομένων',
  employee_create_modal_title: 'Προσθήκη υπαλλήλου',
  employee_change_modal_role_title: 'Αντιστοιχίστε ρόλο στην этойт εμπορικό σημείο',
  employee_create_modal_role_title: 'Επιλέξτε το ρόλο του',
  add_employee: 'Προσθήκη υπαλλήλου',
  role_administrator_name: 'Υποδοχή',
  role_administrator_access:
    'Πρόσβαση σε όλη την λειτουργικότητα, την προσωπική του υπουργικού συμβουλίου',
  role_courier_name: 'Μεταφορέας',
  role_courier_access:
    'Έχετε τη δυνατότητα να ανοίξετε το ψυγείο, να αλλάξετε το προϊόν, να τραβήξετε μια φωτογραφία, να ανεβάσετε φωτογραφίες στο περιβάλλον courier',
  role_checker_name: 'Επιθεωρητής',
  role_checker_access:
    'Δέχεται παραγγελίες των αγοραστών και συγκεντρώνει τους στο εμπορικό αίθουσα για την αποστολή στον πελάτη',
  employees: 'Το προσωπικό',
  to_add_new_employee: 'Προσθέσετε ένα νέο εργαζόμενο',
  requisites_title: 'Τα στοιχεία',
  requisites_description:
    'Προσθέστε τα στοιχεία σας καταστήματος. Χρειάζονται για την αποστολή των χρημάτων στο λογαριασμό όψεώς σας. Τα στοιχεία της εταιρείας σας θα πρέπει να αναφέρονται κατά την έκδοση της επιταγής προς τους πελάτες.',
  requisites_single_description:
    'Αυτή η ενότητα περιέχει όλες τις λεπτομέρειες των τρεχόντων καταστημάτων σας και μπορείτε επίσης να προσθέσετε καινούργια. Αυτό είναι απαραίτητο για την αποστολή χρημάτων στον τρέχοντα λογαριασμό και κατά την έκδοση επιταγής σε πελάτες. Τα έσοδα από τις πωλήσεις μεταφέρονται στον τρέχοντα λογαριασμό μία φορά την ημέρα.',
  pick_requisites: 'Να επιλέξετε τα στοιχεία',
  requisites_company_info: 'Τα στοιχεία για την εταιρεία',
  requisites_client_info: 'Τα δεδομένα για τον πελάτη',
  requisites_fio: 'ΟΝΟΜΑΤΕΠΏΝΥΜΟ',
  requisites_phone: 'Αριθμός τηλεφώνου',
  requisites_mail: 'E-mail',
  requisites_account_number: 'Αριθμός λογαριασμού',
  requisites_current_account_number: 'Αριθμός τραπεζικού λογαριασμού',
  requisites_bank_bic: 'BIC της τράπεζας',
  requisites_bic: 'ΜΠΙΚ',
  add_new_requisites: 'Να προσθέσετε νέα στοιχεία',
  adding_new_requisites: 'Προσθήκη νέων στοιχείων',
  requisites_cheque: 'Τραπεζική επιταγή',
  requisites_non_cash: 'Μη χρηματικών',
  requisites_buyer_phone: 'Το τηλέφωνο του αγοραστή',
  requisites_items: 'Τα αγαθά',
  requisites_item: 'Το προϊόν',
  requisites_provider_info: 'Στοιχεία του προμηθευτή',
  requisites_name: 'Σήμα',
  requisites_inn: 'ΑΦΜ ή επωνυμία νομικού προσώπου',
  requisites_step1: 'Τύπος απασχόλησης',
  requisites_step2: 'Τα προσωπικά δεδομένα',
  requisites_step2_legal: 'Τα προσωπικά δεδομένα του διευθυντή',
  requisites_step3: 'Τα στοιχεία του οργανισμού',
  requisites_step4: 'Πληροφορίες χρέωσης',
  requisites_pick_employment_type: 'Επιλέξτε τον τύπο απασχόλησης',
  employment_type_individual: 'IP',
  employment_type_legal: 'Οργάνωση',
  requisites_position: 'Θέση (όπως στο καταστατικό)',
  passport_first_page: 'Η πρώτη σελίδα του διαβατηρίου',
  passport_second_page: 'Η σελίδα εγγραφής',
  passport_series: 'Σειρά',
  passport_number: 'Δωμάτιο',
  passport_issue_date: 'Ημερομηνία έκδοσης',
  passport_authority_code: 'Το τμήμα κώδικα',
  passport_authority_name: 'Σήμα μονάδες που εξέδωσε το έγγραφο',
  passport_citizenship: 'Ιθαγένεια',
  passport_registration_place: 'Η διεύθυνση εγγραφής (όπως στο διαβατήριο)',
  passport_birth_place: 'Τόπος γέννησης',
  passport_birth_date: 'Ημερομηνία γέννησης',
  requisites_capital: 'Μετοχικό κεφάλαιο, ₽',
  requisites_tax_system: 'Επιλέξτε το φορολογικό σύστημα',
  tax_system_general: 'ΒΑΣΙΚΗ',
  tax_system_simplified_1: 'УСН (έσοδα-έξοδα)',
  tax_system_simplified_2: 'УСН (έσοδα-6%)',
  requisites_org_phone: 'Το τηλέφωνο του οργανισμού',
  requisites_company_iname: 'Το όνομα της εταιρείας',
  requisites_okopf_code: 'OKOPF',
  requisites_ogrn: 'OGRN',
  requisites_okved_code: 'OKVED',
  requisites_bank_account_info: 'Τα στοιχεία του τραπεζικού λογαριασμού μου',
  requisites_cheque_hint: 'Τώρα μπορείτε να δείτε πώς θα φαίνεται η επιταγή της αγοράς.',
  requisites_recipient_bank_name: 'Η τράπεζα του παραλήπτη',
  requisites_bank_correspondent_account: 'Corr. το σκορ',
  requisites_missing: 'Δεν υπάρχουν',
  want_to_deliver: 'Θέλετε να παραδώσει τα προϊόντα με τη δική του ή δική μας παράδοση;',
  delivery_own_radius: 'Ακτίνα δική του παράδοση',
  delivery_briskly_radius: 'Ακτίνα παράδοση εταίρους Briskly',
  delivery_own: 'Ιδιωτικό delivery',
  delivery_briskly: 'Delivery εταίρους Briskly',
  delivery_both: 'Και οι δύο επιλογές',
  delivery_own_tariffs: 'Οι τιμές με δική του παράδοση',
  delivery_briskly_tariffs: 'Οι τιμές αποστολής τους εταίρους briskly',
  delivery_briskly_description:
    'Παρακαλώ σημειώστε, αν τοποθετημένο σας το κόστος αποστολής για τον πελάτη είναι μικρότερη από την πραγματική αξία του, στο τέλος του μήνα θα έρθει το τιμολόγιο για την πληρωμή αυτή τη διαφορά. Αν το κόστος θα είναι μεγαλύτερο, τότε θα έρθουν τα χρήματα στο μέγεθος αυτής της διαφοράς.',
  delivery_briskly_price: 'Το κόστος αποστολής τους εταίρους Briskly',
  delivery_pickup: 'Δυνατότητα παραλαβής',
  delivery_tariffs: 'Οι τιμές αποστολής',
  order_sum: 'Το ποσό της παραγγελίας',
  delivery_cost: 'Το κόστος αποστολής',
  delivery_invalid_rates_text:
    'Διαστήματα ποσό της παραγγελίας πρέπει να είναι διαφορετικά, μεταξύ τους, πρέπει η διαφορά να είναι πάνω από 1 ρούβλι.',
  do_add_rate: 'Προσθέστε όρος',
  delivery_warning_modal_title: 'Θα έχετε την ευκαιρία να αλλάξετε τον τρόπο παράδοσης',
  delivery_warning_modal_body: 'Μετά την αποθήκευση, την προηγούμενη μέθοδο αποστολής θα διαγραφεί',
  delivery_cost_free: 'Δωρεάν',
  delivery_cost_discount_percent: 'Έκπτωση από τις τιμές παράδοσης σε ποσοστό',
  delivery_cost_discount_fixed: 'Έκπτωση από τις τιμές παράδοσης σε ρούβλια',
  delivery_cost_fixed: 'Σταθερή τιμή',
  delivery_cost_unknown: 'Παράδοση πληρώνει ο πελάτης',
  offer: 'Η προσφορά',
  do_download_offer: 'Κατεβάστε προσφορά',
  do_sign_document: 'Να υπογράψουν το έγγραφο',
  code_from_sms: 'Κώδικα από sms',
  try_again_in: 'Εάν ο κωδικός δεν ήρθε, δοκιμάστε ξανά σε',
  in_seconds: 'δευτερόλεπτα',
  do_send_code_again: 'Στείλετε τον κωδικό ξανά',
  do_send_confirmation_code: 'Αποστολή κωδικού επιβεβαίωσης',
  planogram: 'Πλανόγραμμα',
  planogram_block_hint: 'Πρέπει να δημιουργήσετε έναν κατάλογο',
  will_be_deleted: 'Θα πρέπει να αφαιρεθεί',
  planogram_photo: 'Φωτογραφία πλανόγραμμα',
  do_add_items_on_shelf: 'Προσθέστε τα προϊόντα στο ράφι',
  your_micromarket: 'Το микромаркет',
  ράφι: 'ράφι',
  shelf_is_overflow: 'Σε αυτό το ράφι, δεν χωράει',
  do_add_items_on_another_shelfs: 'προσθέστε τα αγαθά σε άλλα ράφια',
  do_add_shelf: 'προσθέστε ένα ράφι',
  going_to_remove_all_items: 'Θα собираетсь να διαγράψετε όλα τα στοιχεία από το ράφι',
  going_to_remove_shelf: 'Θα собираетсь να αφαιρέσετε ένα ράφι',
  planogram_item: 'το προϊόν',
  planogram_list: 'Λίστα πλανόγραμμα',
  do_download_new_planogram: 'Να κατεβάσετε το νέο πλανόγραμμα',
  group_by: 'Ομάδα από το',
  period: 'Την περίοδο',
  order_status: 'Κατάσταση παραγγελίας',
  pick_store: 'Επιλέξτε το κατάστημα',
  option_selection_by_number: 'Δείγμα με αριθμό',
  paid: 'Πληρωμένο',
  processed: 'Επεξεργασία',
  refund: 'Επιστροφή',
  refund_awaiting: 'Σε αναμονή της επιστροφής',
  refund_error: 'Σφάλμα επιστροφής',
  removed: 'Αφαιρεθεί',
  quantity: 'Ποσότητα',
  έτος: 'Έτος',
  id_local: 'EID',
  order_number: 'Τον αριθμό της παραγγελίας σας',
  client: 'Πελάτης',
  item_quantity: 'Αριθμός προϊόντων',
  items_sufix: 'αγαθών',
  commission: 'Προμήθεια',
  income: 'Το κέρδος',
  item_price: 'Τιμή για το προϊόν',
  period_start: 'Την έναρξη της περιόδου',
  period_end: 'Το τέλος της περιόδου',
  sum_with_discount: 'Το ποσό με έκπτωση',
  sum: 'Το ποσό',
  orders: 'Παραγγελίες',
  clients: 'Οι πελάτες',
  unique_clients: 'Μοναδικά φθορισμού πελάτες',
  sales: 'Πώληση',
  completed_order: 'Ολοκλήρωσε την παραγγελία',
  refund_awaiting_from_client: 'Αναμένεται επιβάρυνση του πελάτη',
  service_one_s: '1C',
  service_iiko: 'Aiko',
  service_iiko_biz: 'Aiko Επιχείρηση',
  service_moy_sklad: 'Αποθήκη Μου',
  service_supermag: 'Супермаг',
  service_yml: 'YML',
  service_xlsx: 'XLSX',
  unit_id: 'OKEI (Μον. Μέτρησης)',
  something_wrong_try_again: 'Κάτι πήγε στραβά. Δοκιμάστε ξανά.',
  use_suggestions_begin: 'Επωφεληθείτε από',
  use_suggestions_action: 'άκρες',
  use_suggestions_end: 'για την ενσωμάτωση του συστήματος',
  payment_description:
    'Στείλτε ένα αίτημα για προσθήκη τρόποι πληρωμής υπεύθυνο πρόσωπο το κατάστημα, όπου θα εγκατασταθεί τσιγάρου γραφείο.',
  send_request: 'Στείλτε το αίτημά σας',
  do_change_payment_method: 'Να αλλάξετε τον τρόπο πληρωμής',
  current_card: 'Τρέχουσα χάρτης',
  follow_us_begin: 'Ακολουθήστε μας',
  follow_us_end: 'σε κοινωνικά δίκτυα',
  bpay_title: 'Κατεβάστε το app β-pay',
  bpay_subtitle: 'Αγοράστε χωρίς ουρές',
  checker_title: 'Χρησιμοποιήστε την εφαρμογή CHECK-ER',
  checker_subtitle: 'Συλλέγουν τις παραγγελίες των πελατών σας εξ αποστάσεως',
  valid_required: 'Απαραίτητα',
  valid_article_required: 'Κωδικός προϊόντος απαιτείται',
  valid_min_3: 'Τουλάχιστον 3 σύμβολα',
  valid_min_1: 'Τουλάχιστον 1 σύμβολο',
  valid_min_6: 'Τουλάχιστον 6 χαρακτήρες',
  valid_max_60: 'Μέγιστο 60 χαρακτήρες',
  valid_max_5: 'Το μέγιστο 5 χαρακτήρες',
  valid_max_6: 'Μέγιστο 6 χαρακτήρες',
  valid_max_13: 'Μέγιστο 13 χαρακτήρες',
  valid_max_20: 'Έως και 20 χαρακτήρες',
  valid_max_32: 'Το μέγιστο 32 χαρακτήρες',
  valid_max_350: 'Μέγιστο 350 χαρακτήρες',
  valid_special_symbols_not_allow: 'Спецсимволы δεν επιτρέπονται',
  valid_mail_format: 'E-mail σε μορφή: welcome@brskl.io',
  valid_phone_format: 'Τηλέφωνο σε μορφή +7 (123) 456 78 90',
  valid_no_less_1_percent: 'Δεν λιγότερο 1 %',
  valid_no_more_99_percent: 'Δεν είναι περισσότερο από 99 %',
  module_tittle: 'Ενότητα',
  briskly_module: 'Briskly Ενότητα',
  module_description:
    'Briskly ενότητα είναι το μυαλό σας ψυγείο. Κατά την αγορά σας δοθεί ο κωδικός που θέλετε να συνδέσετε με το λογαριασμό σας. Τον κώδικα μπορείτε να δείτε',
  module_to_buy:
    'Αν δεν Έχετε ακόμα πληρώσει Ψυγείο (ψυγείο + Briskly Ενότητα) ή χωριστά Briskly Ενότητα για το ψυγείο,',
  module_link_to_buy: 'κάντε το τώρα',
  indicate_divice_code: 'Εισάγετε τον κωδικό από τη συσκευή σας',
  module_data: 'Τα δεδομένα σχετικά με Briskly ενότητα',
  network_status: 'Κατάσταση δικτύου',
  working: 'Λειτουργεί',
  not_working: 'Δεν λειτουργεί',
  lock_status: 'Το καθεστώς του κάστρο',
  lock_close: 'Κλειστό',
  lock_open: 'Ανοιχτό',
  air_temp: 'Η θερμοκρασία του αέρα',
  last_time_in_network: 'Τελευταία φορά στο δίκτυο',
  lock_control: 'Έλεγχος κλειδαριά',
  module_error_title: 'Τα στοιχεία να μην είναι διαθέσιμες. Αυτό μπορεί να συμβεί λόγω',
  module_check: 'Ελέγξτε την ενότητα',
  buy_module: 'Αγορά Briskly Ενότητας',
  to_retry: 'Προσπαθήστε ξανά',
  setup_module_in_you_ref: 'Η εγκατάσταση του συστήματος στο ψυγείο σας',
  smart_ref_all_options: 'Το έξυπνο ψυγείο κλειδί στο χέρι""',
  popular_format: 'Δημοφιλής μορφή',
  big_market_with_all_options: 'Μεγάλο περίπτερο κλειδί στο χέρι""',
  lock_mount: 'Εγκατάσταση ελ. το κάστρο',
  connect_to_b_pay: 'Σύνδεση στην εφαρμογή Β-Pay',
  goods_integration: 'Ενσωμάτωση κατατάξεων',
  temperature_sensor: 'Αισθητήρες θερμοκρασίας',
  spy_camera: 'Κάμερα επιτήρησης',
  refregerator_in_set: 'Ψυγείο πλήρης',
  trade_point_branding: 'Branding ψυγείο',
  electronic_lock: 'Ηλεκτρονική κλειδαριά',
  refregerator_with_two_doors: 'Ψυγείο με δύο πόρτες',
  module_apply: 'Να υποβάλουν αίτηση',
  left_a_little: 'Έμεινε λίγο',
  fill_in_the_required_sections_for_your_tt_start_working:
    'Παρακαλούμε συμπληρώστε υποχρεωτικά τα τμήματα, να σας εμπορικό σημείο άρχισε να τρέχει',
  continue_filling: 'Συνεχίστε την πλήρωση',
  copy_link: 'Να αντιγράψετε το σύνδεσμο',
  lock: 'Το κάστρο',
  last_check: 'Ο τελικός έλεγχος',
  stages_description_delivery: 'Επιλέξτε μία από τις επιλογές παράδοσης',
  stages_description_design:
    'Τώρα η εμπορική σημείο στο παράρτημα Β-pay φαίνεται αρκετά φυσιολογική. Για να ξεχωρίζουν, μπορείτε να αλλάξετε το σχεδιασμό',
  stages_description_employees: '',
  stages_description_exchange:
    'Τοποθετήστε τα προϊόντα σε έναν κατάλογο, έτσι ώστε οι πελάτες μπορούν να δουν τους.',
  stages_description_briskly_module:
    'Όλα τα εμπορικά σημεία εμφανίζονται στο σύστημα Briskly μόνο μετά τη σύνδεση Briskly Ενότητας.',
  stages_description_offer:
    'Θα πρέπει να υπογράψει σύμβαση οι προσφορές για να απολαύσετε Briskly μασάζ',
  stages_description_planogram:
    'Τοποθετήστε στα ράφια ψυγείο αγαθά, όπως θα δείτε τους πελάτες σας.',
  stages_description_requisites: 'Κατάσταση: ελέγχονται.↵Ο έλεγχος διαρκεί 1-3 εργάσιμες ημέρες.',
  stages_description_schedule: 'Για την εμπορική σημείο δούλευε, συμπληρώστε τα πεδία δρομολόγια.',
  stages_description_type: '',
  stages_description_fuel: '',
  stages_description_payment:
    'Ο πελάτης δεν το έχεις συνδέσει την κάρτα για πληρωμή.↵ Αντιγράψτε το σύνδεσμο και στείλτε αυτόν, αν δεν το έχετε κάνει ήδη.',
  trade_point_registation_complite: 'Check-in έχει ολοκληρωθεί',
  congratulations_your_tt_is_ready_to_go: 'Συγχαρητήρια! Η εμπορική σημείο έτοιμο για λειτουργία',
  fine: 'Τέλεια',
  congratulations_your_tt_is_ready_we_need_check:
    'Συγχαρητήρια! Έχετε ολοκληρώσει την εγγραφή! Τώρα μας χρειάζεται 1-3 εργάσιμες ημέρες, για να ελέγξετε τις λεπτομέρειες του λογαριασμού σας. Μετά από αυτό θα σας ανοίξει την πρόσβαση σε εμπορικό σημείο.',
  download_qr_code: 'Κατεβάστε το QR code ',
  download_sticker: 'Κατεβάστε την ετικέτα',
  download_table_banner: 'Κατεβάστε το πινγκ banner',
  do_edit_sufix: 'Επεξεργασία',
  delete_trade_point: 'Διαγραφή εμπορικό σημείο',
  this_action_cant_canceled: 'Αυτό θα οδηγήσει σε безвозвратному αφαίρεσης',
  employees_title: 'Το προσωπικό',
  planogram_title: 'Πλανόγραμμα',
  payment_method: 'Τρόπος πληρωμής',
  payment_status: 'Κατάσταση πληρωμής',
  temperature_setup: 'Ρύθμιση θερμοκρασίας',
  temperature_setup_title:
    'Ρυθμίστε την θερμοκρασία λειτουργίας του ψυγείο. Όταν η θερμοκρασία στο εσωτερικό ψυγείο θα βγει για τα εγκατεστημένα σας όρια, θα σας στείλουμε μια ειδοποίηση.',
  time_of_ordering: 'Την ώρα της παραγγελίας',
  Video: 'Βίντεο',
  video_by_order: 'Το βίντεο στην τάξη.',
  pick_date: 'Επιλέξτε την ημερομηνία',
  video_recording_date: 'Ημερομηνία εγγραφής βίντεο',
  video_not_found: 'Το βίντεο δεν βρέθηκε',
  download: 'Κατεβάστε το',
  is_signet: 'Υπεγράφη',
  documents: 'Έγγραφα',
  is_doesnt_signed: 'Δεν έχει υπογραφεί',
  is_touched: 'Εμφανίσεις',
  to_assign: 'Να υπογράψει',
  time_stamp: 'Ώρα να προσθέσετε',
  type: 'Τύπος',
  offer_for_partners: 'Η προσφορά για τους εταίρους',
  tariff_plans: 'Σχέδια τιμολόγησης',
  personal_account_rules: 'Όροι χρήσης ΛΟΥΚΆΣ',
  reports: 'Εκθέσεις',
  report_about_clients: 'Έκθεση πελάτες',
  report_about_items: 'Έκθεση για τα εμπορεύματα',
  report_about_orders: 'Στατιστικά στοιχεία για τις παραγγελίες',
  report_about_sales: 'Στατιστικά πωλήσεων',
  report_about_unique_clients: 'Στατιστικά στοιχεία από το μοναδικό φθορισμού πελάτες',
  do_download_data: 'Κατεβάστε τα δεδομένα',
  do_download_promo_briskly: 'Κατεβάστε το promo υλικά Briskly',
  download_file_format: 'Μορφή download',
  your_account_has_run_out_of_money: 'Στο λογαριασμό σας ξεμείνει από χρήματα.',
  do_top_up_an_account: 'Να γεμίσουν ισορροπία',
  air_temperature: 'Η θερμοκρασία του αέρα',
  inside: 'Μέσα',
  outside: 'Έξω',
  status: 'Το καθεστώς',
  actual_address: 'Η πραγματική διεύθυνση',
  card: 'Χάρτης',
  phone: 'Τηλέφωνο',
  credentials: 'Στηρίγματα',
  εντολής: 'Τα στοιχεία',
  delivery: 'Διανομή',
  do_edit: 'Επεξεργασία',
  most_popular_items: 'Δημοφιλή προϊόντα',
  item_name: 'Το όνομα του προϊόντος',
  price_with_currency: 'Τιμή, ₽',
  revenue_with_currency: 'Τα έσοδα, ₽',
  sold_items_with_amount: 'Πωλείται, τεμ',
  option_unknown: 'Δεν έχει εγκατασταθεί',
  option_min: 'Ελάχιστη',
  option_max: 'Το μέγιστο',
  option_discount_percent: 'Έκπτωση σε ποσοστό',
  option_discount_amount: 'Έκπτωση σε ρούβλια',
  option_discount_new_price: 'Νέα τιμή',
  option_usage_common: 'Η συνολική έκπτωση',
  option_usage_first_order: 'Πρώτη αγορά χωρίς να λαμβάνεται υπόψη το χρόνο',
  option_usage_first_order_after_timestamp: 'Πρώτη αγορά μετά την προγραμματισμένη προσωρινή όριο',
  discount_size: 'Το μέγεθος της έκπτωσης',
  discount: 'Έκπτωση',
  excluded_cost: 'Αποβλήθηκε κόστος',
  applying_features: 'Χαρακτηριστικά της εφαρμογής',
  applying: 'Εφαρμογή',
  do_add_discount: 'Προσθέστε έκπτωση',
  editing_of_discount: 'Επεξεργασία εκπτώσεις',
  adding_of_discount: 'Προσθήκη νέου εκπτώσεις',
  applying_order: 'Η σειρά εφαρμογής',
  save_changes: 'Να αποθηκεύσετε τις αλλαγές',
  do_save: 'Αποθήκευση',
  excluded_cost_tooltip:
    'Το ποσό στην απόδειξη που δεν ισχύει η έκπτωση. ↵Παράδειγμα: 10% έκπτωση για την αγορά πάνω από 500руб (αποβλήθηκε κόστος 500р) ↵το Ποσό της αγοράς 900р έκπτωση 10% ισχύει για 400р και μειώνεται 40р.',
  client_show_settings: 'Ρυθμίσεις εμφάνισης για τους πελάτες',
  do_show: 'Παρουσίαση',
  title: 'Τίτλος',
  description: 'Περιγραφή',
  image: 'Εικόνα',
  add_image_proposal: 'Προσθέστε μια εικόνα',
  adding_of_discount_group: 'Προσθήκη κλήσης εκπτώσεων',
  editing_of_discount_group: 'Επεξεργασία του συνόλου των εκπτώσεων',
  do_add_discount_group: 'Προσθέστε σύνολο',
  do_add_discount_group_v2: 'Προσθέστε το σύνολο των εκπτώσεων',
  do_assign_discount_group_to_store: 'Να αντιστοιχίσετε εκπτώσεις εμπορικό σημεία',
  discounts: 'Οι εκπτώσεις',
  do_delete: 'Διαγραφή',
  not_delete: 'Μην διαγράψετε',
  do_reset_changes: 'Να ακυρώσετε τις αλλαγές',
  you_are_trying_to_delete_discount: 'Πρόκειται να διαγράψετε έκπτωση',
  you_are_trying_to_delete_discount_group:
    'Θα έχετε την ευκαιρία να αφαιρέσετε το σύνολο των εκπτώσεων',
  discount_assign: 'Ο διορισμός των εκπτώσεων',
  store_name: 'Το όνομα του καταστήματος',
  discount_group: 'Το σύνολο των εκπτώσεων',
  title_for_clients: 'Τίτλος για τους πελάτες',
  is_enabled: 'Περιλαμβάνεται',
  is_disabled: 'Είναι απενεργοποιημένη',
  is_enabled_female: 'Είναι ενεργοποιημένη',
  is_disabled_female: 'Είναι απενεργοποιημένη',
  show_for_clients: 'Παρουσίαση για τους πελάτες',
  type_of_discount: 'Τύπος εκπτώσεις',
  set: 'Σύνολο',
  around_the_clock: 'Όλο το εικοσιτετράωρο',
  doesnt_act: 'Δεν ισχύει',
  good_photo: 'Η φωτογραφία του προϊόντος',
  volume: 'Πεδίο',
  step_reminder: 'Μπορείτε να επιστρέψετε σε αυτό το βήμα αργότερα.',
  category: 'Κατηγορία',
  categories: 'Κατηγορίες',
  category_sufix: 'Κατηγορίες',
  create_catalog: 'Δημιουργήστε έναν κατάλογο',
  create_catalog_label:
    'Στο λογαριασμό σας μπορεί να υπάρχουν πολλαπλές καταλόγους (π. χ. για ψυγείο πουλά έτοιμο φαγητό ή πουλά τα κατεψυγμένα προϊόντα). Επιλέγοντας το κατάλληλο κατάλογο, μπορείτε γρήγορα να μεταβείτε από το ένα της λίστας των αγαθών στο άλλο. Εδώ πρέπει να δημιουργήσετε αυτόν τον κατάλογο.',
  catalog_name: 'Το όνομα του καταλόγου',

  portion: 'μερίδα',

  non_tax: 'Δεν εκπίπτουν από',
  payment: 'Έλεγχος',
  standard: 'Standard',
  συνολικά: 'Μόλις',
  units: 'Μονάδες μέτρησης',
  age_limit: 'Όριο ηλικίας,χρόνια',
  composition_of_item: 'Σύνθεση',
  calories: 'Θερμίδες',
  optional_description: 'Περιγραφή (προαιρετικά)',
  vat_mode: 'Λειτουργία ΦΠΑ',
  vat_rate: 'Ποσοστό ΦΠΑ %',
  block_with_modifications: 'Μονάδα με mods',
  item_add_action: 'Προσθήκη του προϊόντος',
  item_edit_action: 'Επεξεργασία του προϊόντος',
  dimension: 'Διάσταση',
  item_add_action2: 'Προσθέστε τα αγαθά',
  add_and_edit_catalog: 'Προσθήκη/επεξεργασία καταλόγου',
  need_select_catalog: 'Θα πρέπει να επιλέξετε τον κατάλογο',
  do_add_more_categories: 'Προσθέστε την κατηγορία',
  goods_name: 'Σήμα του προϊόντος',
  expiration_date: 'Διάρκεια ζωής',
  barcode: 'Γραμμωτός κώδικας',
  sticker_quantity: 'Αριθμός ετικετών',
  manufacturing_by: 'Παράγεται',
  h: 'ώ',
  d: 'η',
  m: 'μ',
  y: 'έ',
  composition: 'Σύνθεση',
  do_not_specifiedSuffix: 'Δεν указанна',
  do_not_specified: 'Δεν έχει καθοριστεί',
  fats: 'Τα λίπη',
  proteins: 'Πρωτεΐνες',
  υδατάνθρακες: 'Υδατάνθρακες',
  calories_full: 'Ενεργειακή αξία',
  ccal: 'kcal',
  nutritional_value_per_100g_of_product: 'Διατροφική αξία(ανά 100 γραμμάρια προϊόντος)',
  at_the_goods: 'Ο εμπορευμάτων',
  with_barcode: 'Με баркодом',
  error_with_bar_code: 'Σφάλμα με τη δημιουργία Баркода!',
  maybe_doest_valid_control_digit: 'Ίσως: Εσφαλμένο ελεγκτικό αριθμός!',
  add_goods: 'Προσθέστε τα αγαθά',
  do_delete_category: 'Διαγραφή κατηγορίας',
  be_deteted: 'Θα πρέπει να αφαιρεθεί',
  need_detals: 'Χρειάζεται βελτίωση',
  good_with_no_valid_barcode_need_write_it:
    'Του προϊόντος δεν валидный Γραμμωτού κώδικα, ↵ συμπληρώστε τον',
  go_to_edit: 'Πηγαίνετε στο επεξεργασία',
  later: 'Αργότερα',
  good_suffix: 'το προϊόν',
  article: 'Κωδικός προϊόντος',
  photo: 'Φωτογραφία',
  dimension_units: 'Μον. μέτρησης',
  active: 'Ενεργό',
  sticker: 'Αυτοκόλλητη ετικέττα',
  items: 'Τα αγαθά',
  do_create_catalog: 'Δημιουργία του καταλόγου',
  do_create_new_catalog: 'Να δημιουργήσει ένα νέο κατάλογο',
  stickers_print: 'Εκτύπωση αυτοκόλλητων ετικεττών',
  write_name_of_new_catalog: 'Πληκτρολογήστε το όνομα του νέου καταλόγου',
  write_name_of_new_category: 'Πληκτρολογήστε ένα όνομα για τη νέα κατηγορία',
  do_add: 'Προσθήκη',
  do_go_back: 'Πριν',
  do_continue: 'Συνεχίστε',
  κατάλογος: 'Κατάλογος',
  doesnt_have_category: 'Δεν έχει κατηγορίες',
  stickers: 'Αυτοκόλλητες ετικέττες',
  do_print_stickers: 'Εκτύπωση αυτοκόλλητες ετικέττες',
  manufacturer_name: 'Σήμα του κατασκευαστή',
  manufacturing_address: 'Διεύθυνση του κατασκευαστή',
  production_date: 'Η ημερομηνία κατασκευής',
  time: 'Ώρα',
  hours: 'Ρολόι',
  minutes: 'Λεπτά',
  'το days': 'Ημέρες',
  weeks: 'Εβδομάδες',
  months: 'Μήνες',
  years: 'Χρόνια',
  do_apply: 'Εφαρμόστε',
  general_expiration_date: 'Η συνολική διάρκεια ζωής',
  amount_of_stickers_per_position: 'Αριθμός ετικετών για μία θέση',
  do_mark_all: 'Να σημειωθεί ό',
  do_download_agreement: 'Κατεβάστε συμφωνία',
  personal_lowercase: 'προσωπική',
  cabinet_lowercase: 'γραφείο',
  choose_option_or_continue_input: 'Επιλέξτε επιλογή ή συνεχίστε τάση',
  unknown_organization: 'Άγνωστη οργάνωση',
  organization_info: 'Τα δεδομένα σχετικά με την οργάνωση',
  organization_name: 'Το όνομα του οργανισμού',
  organization_phone: 'Το τηλέφωνο του οργανισμού',
  inn: 'ΑΦΜ',
  head_of_company: 'Ο επικεφαλής',
  registration_date: 'Ημερομηνία εγγραφής',
  okved: 'ОКВЭД',
  capital: 'Μετοχικό κεφάλαιο',
  taxes_system: 'Το φορολογικό σύστημα',
  do_write_inn: 'Πληκτρολογήστε το ΑΦΜ',
  osno: 'ΒΑΣΙΚΗ',
  usn1: 'УСН (έσοδα-έξοδα)',
  usn2: 'УСН (έσοδα 6%)',
  innLabel1: '{label} ή το όνομα του οργανισμού',
  innLabel2: '{label} ή το επώνυμο',
  acting: 'Διπλής ενέργειας',
  first_name_placeholder: 'Μιχαήλ',
  second_name: 'Επώνυμο',
  second_name_placeholder: 'Mikhailov',
  third_name: 'Πατρώνυμο',
  third_name_placeholder: 'Mikhailovich',
  position_label: 'Θέση (όπως στο καταστατικό του οργανισμού)',
  position_placeholder: 'Ο γενικός διευθυντής',
  okopf_name_short_label: 'Νομική μορφή σε συντομευμένη μορφή',
  okopf_name_full_label: 'Νομική μορφή σε πλήρη μορφή',
  ooo_full_name: 'Εταιρεία περιορισμένης ευθύνης',
  name_short_with_opf: 'Σύντομο όνομα με ОПФ',
  name_short_with_opf_placeholder: "LTD 'Багратион'",
  type_of_company_placeholder: 'LLC, YIP',
  name_short: 'Σύντομο όνομα',
  name_short_placeholder: 'Багратион',
  ogrn: 'OGRN',
  first_passport_page: 'Η πρώτη σελίδα του διαβατηρίου',
  passport_page_with_place_of_live: 'Η σελίδα εγγραφής',
  bank_account: 'Αριθμός τραπεζικού λογαριασμού',
  bank_account_register_placeholder:
    'Πληκτρολογήστε 20 ψηφία λογαριασμό στην τράπεζα (εάν το νομοσχέδιο αποτελείται από 22 ψηφία, εισάγετε τα πρώτα 20)',
  bik: 'ΜΠΙΚ',
  bik_register_placeholder: 'Πληκτρολογήστε 9 ψηφία του αριθμού BIC της τράπεζας',
  bank_credentials: 'Τα στοιχεία της τράπεζας',
  receivers_bank: 'Η τράπεζα του παραλήπτη',
  bank_account_number: 'Αριθμός λογαριασμού',
  bank_correspondent_number: 'Corr. το σκορ',
  your_name: 'Το όνομα',
  phone_number: 'Αριθμός τηλεφώνου',
  type_of_employment: 'Τύπος απασχόλησης',
  ip_as_type_of_organization: 'IP',
  legal_as_type_of_organization: 'Οργάνωση',
  date: 'Ημερομηνία',
  inventory_system: 'Λογιστικό σύστημα αποθέματος',
  integration_errors: 'Με την ενσωμάτωση να αντιμετωπίσετε σφάλματα',
  integration_with_system_were_succeed: 'Ενσωμάτωση με το {system} είναι επιτυχής',
  do_change_info: 'Να αλλάξει τα δεδομένα',
  do_skip: 'Χάσετε',
  select_other_system: 'Να επιλέξετε το σύστημα',
  select_other_system2: 'Να επιλέξετε ένα άλλο σύστημα',
  do_check_integration: 'Ελέγξτε την ενσωμάτωση',
  integration_entry_label:
    ' Εδώ μπορείτε να προσθέσετε τα προϊόντα που θα πουλήσει. Αυτό μπορεί να γίνει με διάφορους τρόπους',
  integration_entry_label_option1: 'Να ενσωματώσει ένα σύστημα Briskly με το σύστημα λογιστικής.',
  integration_entry_label_option2: 'Λήψη του αρχείου με τα εμπορεύματα.',
  integration_entry_label_option3: 'Επιλέξτε έναν υπάρχοντα κατάλογο',
  integration_entry_label_option4: 'Προσθήκη στοιχείων με μη αυτόματο τρόπο',
  select_catalog: 'Επιλέξτε τον κατάλογο',
  upload_or_drag_file: 'Κατεβάστε ή σύρετε το αρχείο',
  integration: 'Ενσωμάτωση',
  integration_file_label:
    'Για τη σωστή λήψη του καταλόγου, соотнесите τα ονόματα των πεδίων με θέματα, όπως είναι το όνομα σας.',
  item_add_action2_label: 'Δημιουργήστε κατηγορίες και να προσθέσετε σε αυτά τα αγαθά',
  write_category_name: 'Πληκτρολογήστε το όνομα της κατηγορίας',
  do_create_category: 'Δημιουργία κατηγορίας',
  actions: 'Βήματα',
  do_send_claim: 'Για να υποβάλετε καταγγελία',
  claim_text: 'Το κείμενο της καταγγελίας',
  for_order: 'στη διαταγή',
  order: 'Παραγγελία',
  client_id: 'ID του πελάτη',
  total_discount: 'Το ποσό έκπτωσης',
  order_info: 'Πληροφορίες σχετικά με την παραγγελία',
  claim_sended: 'Καταγγελία σταλεί',
  total_amount: 'Το συνολικό κόστος',
  order_composition: 'Σύνθεση παραγγελίας',
  additionally: 'Επιπλέον',
  fields_are_optional: 'τα πεδία δεν είναι υποχρεωτικά',
  show_by: 'Εμφάνιση',
  do_briskly_module_active_new: 'Ενεργοποιήσετε μια νέα ενότητα',
  do_briskly_module_finish: 'Να ολοκληρώσει',
  briskly_module_reassign_title: 'Η σύνδεση της νέας ενότητας',
  briskly_module_reassign_message:
    'Είστε σίγουρος ότι θέλετε να συσχετίσετε ένα νέο Briskly ενότητα;',
  error: 'Σφάλμα',
  fail_bind_employee_to_store: 'Δεν κατάφερε να επισυνάψετε υπαλλήλου',
  fail_unbind_employee_to_store: 'Δεν κατάφερε να λύσω υπαλλήλου',
  fail_init_employee_store_list: 'Δεν ήταν δυνατό να φορτώσει τα δεδομένα για τα σημεία πώλησης',
  fail_onboarding_init_company_info:
    'Δεν ήταν δυνατό να φορτώσει τα δεδομένα της εταιρείας, θεωρούμε προηγουμένως',
  unknown_error: 'Κάτι πήγε στραβά',
  delete_category_text_confirm:
    'Η δράση αυτή θα οδηγήσει σε απομάκρυνση τον κατάλογο, καθώς και τα αγαθά που βρίσκονται σε αυτό θα αποδειχθεί στην κατηγορία όλα""',
  delete_catalog_text_confirm_1: 'Όταν διαγράφετε καταλόγου',
  delete_catalog_text_confirm_2:
    'από αυτό θα μ όλες οι κατηγορίες και τα αγαθά που βρίσκονται σε αυτό',
  do_collapse: 'Σύμπτυξη',
  exchange_settigns: 'Ρυθμίσεις κοινής χρήσης',
  create_catalog_label_2:
    "'Στο λογαριασμό σας μπορεί να υπάρχουν πολλαπλές καταλόγους. \\nΓια παράδειγμα, για ψυγείο πουλά έτοιμο φαγητό ή πουλά τα κατεψυγμένα προϊόντα. \\nΕπιλέγοντας το κατάλληλο κατάλογο, μπορείτε γρήγορα να μεταβείτε από το ένα της λίστας των αγαθών στο άλλο. \\nΕδώ μπορείτε να αλλάξετε αυτόν τον κατάλογο.',",
  report_about_item: 'Στατιστικά στοιχεία για το προϊόν',
  report_order_id: 'Τον Αριθμό Της Παραγγελίας Σας',
  'οι αποστολές': 'Διανομής',
  do_add_mailing: 'Δημιουργία newsletter',
  mailing_new: 'Το νέο ενημερωτικό δελτίο',
  mailing_edit: 'Επεξεργασία διανομής',
  mailing_status_complete: 'Ολοκληρώθηκε',
  mailing_status_saved: 'Αποθηκεύτηκε',
  mailing_status_moderation: 'Στην μετριοπάθεια',
  mailing_status_wait: 'Περιμένει την αποστολή',
  mailing_status_cancelled: 'Ακυρώθηκε',
  mailing_status_ready: 'Να πληρώσει',
  mailing_add_description:
    'Προσθέστε το ενημερωτικό δελτίο σας. Καθορίστε το χρόνο αποστολής, το κοινό των πελατών και το κείμενο του μηνύματος.',
  mailing_moderation_description:
    'Μετά από να περάσει το μέτρο θα πρέπει να πληρώσει το ενημερωτικό δελτίο. Λάβετε υπόψη ότι η έγκριση υποβάλλεται μέσα σε 1-2 εργάσιμες ημέρες, η πληρωμή έρχεται στο σκορ σε 2 εργάσιμες ημέρες. Να λαμβάνουν υπόψη κατά το σχεδιασμό διανομής.',
  mailing_name: 'Το όνομα της διανομής',
  mailing_date: 'Ημερομηνία αποστολής',
  mailing_audience: 'Το κοινό για τη λίστα',
  mailing_client_count: 'Τον αριθμό των πελατών',
  mailing_cost: 'Το κόστος διανομής',
  mailing_text: 'Το κείμενο διανομής',
  mailing_only_save: 'Αποθήκευση, αλλά δεν τρέχει',
  mailing_save_and_start: 'Εκτελέστε το ενημερωτικό δελτίο',
  mailing_invalid_time: 'Ρυθμίστε το χρονικό διάστημα από 9:00 έως τις 21:00',
  mailing_may_not_pass_moderation:
    'Το ενημερωτικό δελτίο μπορεί να μην προλάβει να περάσει μετριοπάθεια.',
  mailing_all_clients: 'Όλοι οι πελάτες',
  mailing_created_filters: 'Έτοιμα φίλτρα',
  mailing_selecting_filters: 'Επιλογή φίλτρου',
  mailing_save_filters: 'Αποθήκευση φίλτρα',

  stock_balances: 'Υπόλοιπα αποθεμάτων',
  do_add_items_list: 'Προσθήκη στοιχείων ως λίστα',
  accounting_for_stock_balances: 'Λογιστική για υπόλοιπα μετοχών',
  rebase_catalogs: 'Κατάργηση καταλόγου > Επεξεργασία καταστημάτων',
  clarification_in_items_side_bar:
    '* Εάν τα προϊόντα στο αρχείο σας έχουν ήδη χωριστεί σε κατηγορίες, απλώς επιλέξτε τον Κατάλογο και ανεβάστε το αρχείο, οι Κατηγορίες θα δημιουργηθούν αυτόματα.',
  do_download_xlsx: 'Μεταφόρτωση αρχείου .xlsx',
  stock_accounting_balances_info:
    'Για να λάβετε ενημερωμένες πληροφορίες σχετικά με την ποσότητα και την αξία των αγαθών στα καταστήματα λιανικής πώλησης, χρησιμοποιήστε τη λειτουργία« Λογιστική για τα υπόλοιπα αποθεμάτων ».',
  or: 'Or',
  category_info_tip:
    'Χρησιμοποιήστε αυτήν τη λειτουργία για να γεμίσετε μια νέα κατηγορία με προϊόντα πιο γρήγορα, αυτόματα.',
  good_must_be_in_catalog_or_category:
    'Το προϊόν πρέπει να είναι συνδεδεμένο με έναν κατάλογο ή κατηγορία.',
  item_add_side_bar_info:
    'Εάν πρέπει να δημιουργήσετε έναν νέο κατάλογο ή κατηγορία στον τρέχοντα κατάλογο, μεταβείτε στην ενότητα',
  do_delete_catalog: 'Διαγραφή καταλόγου',
  delete_catalog: 'Διαγραφή καταλόγου',
  trade_point_edit: 'Επεξεργασία σημείων πώλησης',
  rebase_catalog_1:
    'Για να καταργήσετε τον κατάλογο "Όνομα του καταλόγου που θα αφαιρεθεί", χρειάζεστε "',
  rebase_catalog_2: 'δεσμεύστε νέους καταλόγους σε όλα τα καταστήματα',
  rebase_catalog_3: 'στον παρακάτω πίνακα.',
  cant_start_from_dot: 'Δεν μπορώ να ξεκινήσω από κουκκίδα',
  aprove_category_delete: 'Πρόκειται να διαγράψετε μια κατηγορία',
  delete_category_warn:
    'Η διαγραφή μιας κατηγορίας θα διαγράψει όλα τα προϊόντα αυτής της κατηγορίας',
  delete_catalog_confirm_text_5:
    'Πρόκειται να διαγράψετε έναν κενό κατάλογο χωρίς κατηγορίες ή προϊόντα',
  delete_catalog_confirm_text_6: 'Πρόκειται να διαγράψετε έναν κατάλογο που περιέχει προϊόντα',
  trade_point_type_name: 'Τύπος σημείου συναλλαγής',
  select_new_catalog: 'Επιλογή νέου καταλόγου',
  barcoders_prefix_for_weight_good: 'Βάρος στοιχείου Προθέματα γραμμωτού κώδικα',
  barcoders_prefix_info:
    'Με την τιμή αυτού του προθέματος, το σύστημα αναγνωρίζει το είδος κατά βάρος, επισημαίνοντας τον ψηφιακό κωδικό του αντικειμένου και το βάρος του σε γραμμάρια. Τα διαθέσιμα προθέματα είναι από 20 έως 29. Εάν δεν έχει καθοριστεί πρόθεμα, τα εμπορεύματα θα αντιμετωπίζονται ως κομμάτια. ',
  age_limit_2: 'χρόνια',
  age_limit_1: 'Όριο ηλικίας',

  requisites_personal_data_descr_part_1:
    'ζητάμε από σας να συμπληρώσετε αυτές τις πληροφορίες, για να έχουν τη δυνατότητα καθημερινά να μεταφράσει σας τα έσοδα από τις πωλήσεις της αγοράς. Για να το κάνετε αυτό, πρέπει να δώσετε πληροφορίες, παρόμοια με εκείνα που παρέχετε κατά τη σύνδεση эквайринга.',
  requisites_personal_data_descr_part_2:
    'Τα στοιχεία που ζητούνται σύμφωνα με την απαίτηση από την κεντρική τράπεζα και την τράπεζα-эквайера. Θα πρέπει να αποθηκεύονται σε κρυπτογραφημένη μορφή. Επίσης, η εταιρεία Briskly αποτελείται στο μητρώο των φορέων των προσωπικών δεδομένων στη χώρα.',
  add_good: 'Προσθέστε το προϊόν',
  external_id: 'Εξωτερικός id',
  do_save_and_go_back: 'Αποθήκευση και επιστροφή στη λίστα',
  print_shelf_life: 'Εκτύπωση διάρκεια ζωής',
  succes_saved: 'έχει αποθηκευτεί',
  quantity_cut: 'Ποσότητα',
  additional_data_about_good: 'Περισσότερες πληροφορίες για το προϊόν',
  doest_not_req_field: 'προαιρετικά πεδία',
  do_upload_photo: 'Λήψη φωτογραφιών',
  upload_good_list: 'Λήψη αγαθά λίστα',
  show_filters: 'Εμφάνιση φίλτρα',
  hide_filters: 'Απόκρυψη φίλτρων',
  available_table_filters: 'τα Φίλτρα του πίνακα',
  deletion: 'Αφαίρεση',
  item_warning_info: ' το Προϊόν πρέπει να είναι συνδεδεμένο σε κάποιο ή κατάλογο και κατηγορίες.',
  item_create_info:
    'Αν χρειαστεί να содать νέο κατάλογο ή κατηγορία στον τρέχοντα κατάλογο, μεταβείτε στην ενότητα ',
  aprove_catalog_delete: 'πρόκειται να διαγράψετε τον κατάλογο',
  delete_catalog_text_confirm_3:
    'Όταν διαγράφετε ένα Κατάλογο θα διαγραφούν όλες οι Κατηγορίες και τα Αγαθά μέσα σε αυτό.',
  delete_catalog_text_confirm_4:
    'Για την αφαίρεση катлога θα πρέπει πρώτα να δεσμεύσει το νέο κατάλογο σε όλα τα εμπορικά σημεία σε αντάλλαγμα να αφαιρεθεί.',
  go_to_edit_store: 'Πηγαίνετε στο επεξεργασία καταστήματα',
  choose_trade_point: 'Επιλέξτε μια διέξοδο',
  do_catalog_edit: 'Επεξεργασία καταλόγου',
  do_category_edit: 'Επεξεργασία κατηγορίας',
  category_warn_text_warn: 'Κατηγορία πρέπει να είναι δεμένη σε ποιο είτε κατάλογος.',
  category_warn_text: 'Αν χρειαστεί να содать νέο κατάλογο κάνοντας κλικ στο link',
  category_warn_text_link: 'Δημιουργήστε ένα νέο κατάλογο.',
  catalog_rebase_info: 'δεν συνδέεται ούτε σε ένα από τα καταστήματα, τώρα μπορείτε να',

  km: 'χιλιόμετρα',
  no_design_to_select: 'Δεν υπάρχουν αποθηκευμένα σχέδια',
  shelf: 'ράφι',
  year: 'Έτος',
  valid_fill_the_field: 'Συμπληρώστε το πεδίο',
  valid_incorrect_date: 'μη έγκυρη ημερομηνία',
  valid_incorrect_phone: 'Λάθος τηλέφωνο',
  valid_passport_series: 'μη έγκυρη σειρά',
  valid_passport_number: 'μη έγκυρος αριθμός διαβατηρίου',
  valid_passport_authority_code: 'Λάθος κωδικός μονάδες',
  valid_inn: 'Επιλέξτε ΑΦΜ',
  valid_address: 'Επιλέξτε μια διεύθυνση',
  valid_bic: 'Επιλέξτε ΜΠΙΚ',
  valid_account_number: 'το Μήκος του τραπεζικού λογαριασμού πρέπει να είναι 20 χαρακτήρες',
  valid_maximum: 'Μέγιστος αριθμός χαρακτήρες',
  valid_select_some_filter: 'Επιλέξτε τουλάχιστον ένα φίλτρο',
  altogether: 'Τις',
  carbohydrates: 'Υδατάνθρακας',
  days: 'Ημέρα',
  need_to_select_catalog_and_category:
    'Για τη δημιουργία του προϊόντος \n πρέπει να επιλέξετε \n κατάλογο και την κατηγορία',
  categories_of_catalogs: 'Κατηγορίες καταλόγου',
  add_catalog: 'Προσθήκη καταλόγου',
  add_category: 'Προσθήκη κατηγορίας',
  add_catalog_action: 'Προσθήκη καταλόγου',
  add_category_action: 'Προσθήκη κατηγορίας',
  category_name: 'Όνομα κατηγορίας',
  catalogs: 'Κατάλογοι',
  search_by_categories: 'Αναζήτηση ανά κατηγορία',
  search_by_catalogs: 'Αναζήτηση καταλόγους',
  warning: 'Προσοχή!',
  write_new_catalog_name: 'Εισάγετε ένα όνομα καταλόγου',
  write_new_category_name: 'Εισάγετε το όνομα της κατηγορίας',
  add_catalog_warning:
    'Μπορείτε να το συνδέσετε τον κατάλογο σε αρκετά εμπορικά σημεία, για να το κάνετε αυτό, χρησιμοποιήστε το ' +
    ' για να προσθέσετε ένα επιπλέον πεδίο επιλογής καταστήματος.',
  add_catalog_info:
    'Μπορείτε να προσθέσετε ταυτόχρονα πολλές κατηγορίες σε έναν κατάλογο, για να το κάνετε αυτό, χρησιμοποιήστε το ' +
    ' για να προσθέσετε ένα επιπλέον πεδίο για να εισάγετε το όνομα της νέας κατηγορίας. ',
  product_product_date: 'Εκτύπωση ημερομηνία κατασκευής',
  mailing_going_to_delete: 'πρόκειται να διαγράψετε το ενημερωτικό δελτίο',
  address_doesnt_correct: 'η Διεύθυνση δεν είναι έγκυρη',
  no_saved_templates: 'Δεν υπάρχουν αποθηκευμένα πρότυπα',
  add_schedule_and_save_as_template:
    'Προσθέστε επιλογές και, στη συνέχεια, αποθηκεύστε τα ως πρότυπο',
  service_mts_kassa: 'MTS Ταμείο',
  create_new_catalog: 'Δημιουργήστε ένα νέο κατάλογο',
  use_existing_catalog: 'για να Χρησιμοποιήσετε έναν υπάρχοντα κατάλογο',
  log_out_question: 'Είστε σίγουρος ότι θέλετε να ολοκληρώσετε την τρέχουσα συνεδρία;',
  items_add_side_bar_info:
    'Αν θα πρέπει να δημιουργήσει ένα νέο κατάλογο ή κατηγορία στον τρέχοντα κατάλογο, μεταβείτε στην ενότητα ',
  item_photo_note:
    '(μπορείτε να ανεβάσετε την φωτογραφία του προϊόντος ή να το αφήσετε φωτογραφία από προεπιλογή, το παρακάτω)',
  store_doesnt_have_catalog:
    'Η Εμπορική σημεία {name} δεν υπάρχει αναφορά σε κατάλογο прейти την επεξεργασία ;',
  success: 'Επιτυχημένη',

  uploaded_image_min_width: 'Ελάχιστο πλάτος της εικόνας',
  uploaded_image_max_width: 'Μέγιστο πλάτος της εικόνας',
  uploaded_image_min_height: 'Ελάχιστο ύψος της εικόνας',
  uploaded_image_max_height: 'Μέγιστο ύψος της εικόνας',
  allowed_types: 'Επιτρεπόμενοι τύποι',
  max_file_size: 'Μέγιστο μέγεθος αρχείου',
  standard_no_suf: 'Πρότυπο',
  orders_and_video: 'Παραγγελίες και βίντεο',
  payment_type_undefined: 'Δεν έχει καθοριστεί',

  time_of_creation: 'Χρόνος δημιουργίας',
  find_by_order_number: 'Αναζήτηση με αριθμό παραγγελίας',
  id_trade_point: 'ID εμπορικό σημείο',
  item_id: 'Αναγνωριστικό στοιχείου',
  good_barcode: 'Γραμμωτός κώδικας στοιχείων',
  option_discount_percent_cut: 'Έκπτωση, %',
  price_with_currency_inject: 'Τιμή, {currency}',
  sum_with_currency_inject: 'Ποσό, {currency}',
  total_amount_price_with_currency_inject: 'Συνολική τιμή, {currency}',
  total_amount_sum_with_currency_inject: 'Συνολικό ποσό, {currency}',
  create_refund: 'Επιστροφή',
  created_at: 'Χρόνος δημιουργίας',
  customer_id: 'Πελάτης',
  store: 'Αποθηκεύονται',
  ok: 'Ok',
  service_csv: 'csv',
  orders_not_exist: 'Δεν υπάρχουν παραγγελίες στα σημεία συναλλαγών σας ακόμα.',

  //Новые коючи

  valid_minimum: 'Ελάχιστος αριθμός χαρακτήρων',
  coords_lng: 'Γεωγραφικό μήκος',
  coords_lat: 'Γεωγραφικό πλάτος',

  invalid_intervals_filled: 'Τα πεδία δεν συμπληρώνονται',

  invalid_intervals_to_less_then_from: 'Μη έγκυρα διαστήματα',
  invalid_intervals_intersections: 'Τα διαστήματα δεν πρέπει να επικαλύπτονται',
  valid_max_250: 'Maksimum 250 karakter',

  postal_code: 'Ταχυδρομικό',
  module_description_temp:
    'Η ζωηρή ενότητα είναι ο εγκέφαλος της μικρο-αγοράς σας. Κατά την αγορά, σας δόθηκε ένας κωδικός που πρέπει να συνδεθεί με το λογαριασμό σας.',
  value_must_be_more_than_zero: 'Η τιμή πρέπει να είναι μεγαλύτερη από 0',
  valid_min_13: 'Ελάχιστοι 13 χαρακτήρες',
  dosesnt_correct_number: 'Μη έγκυρος αριθμός',
  success_deleted: 'Με επιτυχία εξ αποστάσεως',
  do_return_back: 'Επιστρέψετε',
  abort_procedure: 'Διαδικασία ματαίωσης',
  abort: 'Ματαίωση',
  maybe_data_not_saved: 'Οι αλλαγές που έγιναν ενδέχεται να μην αποθηκευτούν',
  empty_catalog: 'Ο κατάλογος είναι άδειος',
  fileupload_page_info:
    '* Εάν τα προϊόντα στο αρχείο σας είναι ήδη χωρισμένα σε κατηγορίες, απλά επιλέξτε τον κατάλογο και μεταφορτώστε το αρχείο, οι κατηγορίες θα δημιουργηθούν αυτόματα.',
  do_download_xlsx_example: 'Λήψη .xlsx παράδειγμα',
  valid_max_11: 'Μέγιστο 11 χαρακτήρες',
  payment_type: 'Τύπος πληρωμής',
  app: 'Αίτηση',
  terminal: 'Τερματικό',
  order_with_id_inject: 'Αναγνωριστικό παραγγελίας {orderId}',
  refund_with_order_id_inject: 'Επιστροφές: αναγνωριστικό παραγγελίας {orderId}',
  barcode_can_includes_only_digits: 'Αριθμοί μόνο',
  copy_to_clipboard: 'Αντιγραφή σε προσωρινή μνήμη',
  copied_to_clipboard: 'Αντιγραφή στο πρόχειρο',
  about_order: 'Περίπου Διαταγή',
  refund_title: 'Κάνοντας μια επιστροφή',
  amout_goods_to_refund: 'Σύνολο στοιχείων που πρέπει να επιστραφούν',
  amount_goods_payment: 'Ποσό επιστροφής',
  technical_support: 'Τεχνική υποστήριξη',
  add_refund: 'Προσθήκη επιστροφής χρημάτων',
  view_refund: 'Προβολή επιστροφών',
  onboarding_virtual_terminal: 'Εικονικό τερματικό',
  virtual_terminal: 'Εικονικό τερματικό',
  select_virtual_terminal: 'Επιλέξτε ένα εικονικό τερματικό',
  virtual_terminal_description:
    'Συμπληρώστε τα στοιχεία του εικονικού τερματικού σας, είναι απαραίτητα για την πίστωση χρημάτων στο λογαριασμό σας, καθώς και για τη δημιουργία επιταγής για έναν πελάτη.',
  add_new_virtual_terminal: 'Προσθήκη τερματικού',
  do_create_refund: 'Επιστροφή',

  // delete_goods_modal_info: '',
  // client_show_settings_2: '',
  // no_changes_to_update: '',
  // for_go_to_exchange_need_say_store: '',
  // link_to_showcase: '',
  // catalog_successfully_added: '',
  // refund_has_been_created: '',
  // no_trans_action: '',
  // no_refund_text_info: '',
  // date_should_not_earlier: '',
  // date_should_not_later: '',
  // cant_play_video: '',
  // check_refund_possibility: '',
  // refund_helper_text: '',
  // no_one_good_selected: '',
  // errors_occurred_while_creating_the_refund: '',
  // refund_by_id_goes_successed: '',
  // refund_by_id_goes_accept: '',
  // refund_money_back_notice: '',
  // refund_ready_title: '',
  // refund_check_success_info: '',
  // refund_check_success_info_with_order_id: '',
  // video_not_available: '',
  // video_tooltip_error: '',
  // do_upload: '',Επιστροφή παραγγελίας ID

  delete_goods_modal_info: 'και {stringsQuantity}... περισσότερα προϊόντα',
  client_show_settings_2: 'Ρυθμίσεις εμφάνισης για την εφαρμογή για κινητά',
  no_changes_to_update: 'Δεν υπάρχουν αλλαγές για αποθήκευση',
  for_go_to_exchange_need_say_store: 'Επιλέξτε ένα σημείο εμπορίου',
  link_to_showcase: 'Σύνδεση με προθήκη προϊόντων',
  catalog_successfully_added: 'Κατάλογος προστέθηκε με επιτυχία',
  refund_has_been_created: 'Επιστροφή δημιουργήθηκε',
  no_trans_action: 'Δεν υπάρχουν συναλλαγές',
  no_refund_text_info: 'Δεν υπήρξαν επιστροφές για αυτήν την παραγγελία.',
  date_should_not_earlier: 'Η ημερομηνία δεν πρέπει να είναι νωρίτερα',
  date_should_not_later: 'Ημερομηνία πρέπει να είναι το αργότερο',
  cant_play_video: 'Δεν είναι δυνατή η αναπαραγωγή βίντεο',
  check_refund_possibility: 'Ελέγξτε τις επιλογές επιστροφής χρημάτων',
  refund_helper_text:
    'Για να αναζητήσετε γρήγορα ένα προϊόν, πληκτρολογήστε το αναγνωριστικό, τον γραμμωτό κώδικα ή το όνομά του',
  no_one_good_selected: 'Δεν έχει επιλεγεί στοιχείο για επιστροφή χρημάτων.',
  errors_occurred_while_creating_the_refund:
    'Παρουσιάστηκαν σφάλματα κατά τη δημιουργία της επιστροφής χρημάτων:',
  refund_by_id_goes_successed:
    'Η επιστροφή χρημάτων για το αναγνωριστικό παραγγελίας {refundId} ήταν επιτυχής',
  refund_by_id_goes_accept:
    'Επιβεβαιώστε την επιστροφή χρημάτων για το αναγνωριστικό παραγγελίας {refundId}',
  refund_technical_support_notice:
    'Για να κάνετε επιστροφή χρημάτων, επικοινωνήστε με την τεχνική υποστήριξη.',
  refund_money_back_notice:
    'Τα χρήματα θα πιστωθούν στην κάρτα του πελάτη εντός 2 ημερών από την ημερομηνία επιστροφής χρημάτων.',
  refund_ready_title: 'Επιστροφή παραγγελίας',
  refund_check_success_info: 'Επιστροφή χρημάτων μπορεί να δημιουργηθεί με επιτυχία',
  refund_check_success_info_with_order_id:
    'Επιστροφή παραγγελίας ID  {orderId} δημιουργήθηκε με επιτυχία',
  approve_refund: 'Επιβεβαιώστε την επιστροφή',
  video_not_available: 'Το βίντεο δεν είναι διαθέσιμο',
  video_tooltip_error:
    'Το αρχείο βίντεο είναι κατεστραμμένο ή προσωρινά μη διαθέσιμο. Παρακαλώ, επικοινωνήστε με την υποστήριξη',
  do_upload: 'Λήψη',
  amout_for_refund: 'Σύνολο στοιχείων προς επιστροφή',
  does_not_correct_control_sum: 'Μη έγκυρο άθροισμα ελέγχου',
  active_status: 'Εν αναμονή πληρωμής',
  paid_status: 'επί πληρωμή',
  refund_status: 'Επέστρεψαν ',
  refund_awaiting_status: 'Αναμενόμενη επιστροφή',
  refund_error_status: 'Σφάλμα επιστροφής',
  refund_partial_status: 'Επιστροφή χρημάτων μερικώς',
  removed_status: 'Διαγράφηκε',

  online_store_layouts: 'Διατάξεις για εκτύπωση σε μικροαγορά',
  promo_stickers: 'Αυτοκόλλητο',
  promo_side_stickers: 'Πλαϊνά Αυτοκόλλητα',
  promo_on_glas: 'Σε γυαλί',
  promo_on_advertising_block: 'Ανά ενότητα διαφημίσεων',

  promo_without_ad_block: '(χωρίς μπλοκ διαφημίσεων)',
  promo_with_ad_block: '(με ενότητα διαφημίσεων)',
  // do_upload: '',
  // amout_for_refund: '',
  // add_refund: '',
  // view_refund: '',
  valid_minimum: 'Ελάχιστος αριθμός χαρακτήρων',
  coords_lng: 'Γεωγραφικό μήκος',
  coords_lat: 'Γεωγραφικό πλάτος',

  attention: 'Προσοχή',
  requisites_skip_warning:
    'Εάν παραλείψετε το βήμα επιλογής / προσθήκης λεπτομερειών, το εμπορικό σας σημείο θα λειτουργήσει σε δοκιμαστική λειτουργία.',
  select_full_party_description:
    'Μπορείτε να επιλέξετε προηγουμένως χρησιμοποιημένα στοιχεία ή να προσθέσετε νέα απλά συμπληρώνοντας τη φόρμα!',
  select_partial_party_description:
    'Συνδέστε το εικονικό τερματικό σας στον προσωπικό σας λογαριασμό!',
  pick_terminal: 'Επιλέξτε Τερματικό',
  add_new_full_party: 'Προσθήκη νέου',
  add_new_partial_party: 'Προσθήκη νέου',
  swift_bic: 'SWIFT / BIC',
  secure3d: '3DS',
  non_secure3d: 'non 3DS',
  full_party_choosing: 'Επιλογή λεπτομερειών',
  partial_party_choosing: 'Επιλογή τερματικού',
  requisites_and_terminals: 'Οντότητα',
  terminals: 'Εικονικά τερματικά',
  full_party_status_active: 'Ενεργή',
  full_party_status_pending: 'Έλεγχος',
  full_party_status_replacement: 'Αντικατάσταση',
  full_party_edit: 'Λεπτομέρειες επεξεργασίας',
  partial_party_edit: 'Επεξεργασία εικονικού τερματικού',
  adding_new_terminal: 'Προσθήκη νέου τερματικού',
  add_full_party: 'Προσθήκη απαιτήσεων',
  add_partial_party: 'Προσθήκη εικονικού τερματικού',
  full_party_edit_warning:
    'Μετά την αλλαγή, οι λεπτομέρειες θα ελεγχθούν για κάποιο χρονικό διάστημα. Τα νέα στοιχεία θα αποθηκευτούν για τα εμπορικά σημεία αμέσως μετά τον έλεγχο.',
  partial_party_edit_warning:
    'Μετά την αλλαγή, το τερματικό θα ελεγχθεί για κάποιο χρονικό διάστημα. Το νέο τερματικό θα αποθηκευτεί για σημεία συναλλαγών αμέσως μετά τον έλεγχο.',
  kpp: 'KPP',
  upload_logo_in_format: 'Ανεβάστε το λογότυπό σας στο',
  upload_logo_with_min_size: 'Το ελάχιστο μέγεθος εικόνας',
  upload_logo_with_min_file_size: 'Μέγιστο μέγεθος αρχείου',
  currency: 'Νόμισμα',

  BYN: 'Λευκορωσικό ρούβλι(BYN)',
  USD: 'δολαρίου(USD)',
  EUR: 'Ευρώ(EUR)',
  KZT: 'Καζαχικό τενγκέ(KZT)',
  CAD: 'Δολάριο Καναδά(CAD)',
  CNY: 'CNY(CNY)',
  RUB: 'Ρούβλι(RUB)',
  TRY: 'Τουρκική λίρα(TRY)',
  UAH: 'Ουκρανικά εθνικού νομίσματος(UAH)',
  GBP: 'GBP(GBP)',
  CHF: 'Ελβετός Φρανκ(CHF)',

  bank_name: 'Σήμα τράπεζας',
  company_name: 'Το πλήρες όνομα της εταιρείας',

  serial_number: 'Σειράς',
  coffee_reeboot: 'Επανεκκίνηση',
  coffee_reeboot_coffee_machine: 'Επανεκκινήστε τη μηχανή καφέ',
  coffee_reeboot_text: 'Η επανεκκίνηση της συσκευής βοηθά στην επίλυση ορισμένων προβλημάτων.',
  defer_service: 'Αναβολή υποχρεωτικής υπηρεσίας',
  coffee_postpone: 'Αναβληθεί',
  coffee_service_maintenance_text:
    'Η κατάσταση υπηρεσίας θα αναβληθεί μέχρι την επόμενη ενεργοποίηση.',
  block_drinks: 'Αποκλεισμός παρασκευής ποτών',
  coffee_block_text:
    'Η μηχανή καφέ θα παραμείνει ενεργή, αλλά η παρασκευή ποτών δεν θα είναι διαθέσιμη στους πελάτες',
  block_drink_change: 'Αποκλείστε την αλλαγή των παραμέτρων ποτών',
  coffee_props_block_text:
    'Διατίθεται κατά τη στιγμή της προετοιμασίας. Μόλις ενεργοποιηθεί, οι πελάτες δεν θα μπορούν να προσθέσουν αλλαγές στο πρότυπο ποτό.',
  block_menu_access: 'Αποκλεισμός πρόσβασης στο Προσαρμοσμένο μενού',
  coffee_menu_text: 'Οι πελάτες δεν θα μπορούν να χρησιμοποιήσουν το μενού στη μηχανή καφέ.',
  block_drinks_when_offline:
    'Αποκλείστε την παρασκευή ποτών εάν δεν υπάρχει σύνδεση με το διακομιστή για περισσότερο από 48 ώρες',
  coffee_reloading: 'Επανεκκίνηση ...',
  search_by_items: 'Αναζήτηση με προϊόντα',
  coffee_item_photo: 'Φωτογραφία προϊόντων',
  coffee_item_photo_info:
    '(μπορείτε να ανεβάσετε τη δική σας Φωτογραφία Προϊόντος ή να αφήσετε την προεπιλεγμένη φωτογραφία που φαίνεται παρακάτω)',
  coffee_item_type: 'Τύπος ποτών',
  item_recept: 'Συνταγή προϊόντος',
  value_ml: 'ml',
  cup_with_units_injection: 'Γυαλί {cupsize} ml',
  drink_with_units_injection: 'Ποτό {Drinksize} ml',
  coffee_foam: 'Αφρός γάλακτος',
  coffee_milk: 'Γάλα',
  coffee_water: 'Νερό',
  coffee_coffee_size: 'Καφέ',
  return_values_to_default: 'Επαναφορά προεπιλεγμένων τιμών',
  price_must_be_more_then_inject: 'Η τιμή πρέπει να είναι υψηλότερη {price}',
  coffee_item_photo_info__temp:
    'Μπορείτε να αφήσετε την προεπιλεγμένη φωτογραφία ή να επιλέξετε από τις διαθέσιμες κάνοντας κλικ στο κουμπί «Επιλογή»',
  block_drink_changes: 'Αποκλεισμός αλλαγής παραμέτρων ποτών',

  file_name_uploaded: 'Αρχείο {fileName} φορτώθηκε',
  columns: 'Στήλες',
  file_upload_rules: 'Απαιτήσεις αρχείου για μεταφόρτωση προϊόντος:',
  file_upload_rules_1: 'Μορφή — .xlsx;',
  file_upload_rules_2: 'Το αρχείο δεν πρέπει να περιέχει συγχωνευμένα κελιά.',
  file_upload_rules_3: 'Τα ονόματα πεδίων δεν πρέπει να υπερβαίνουν τη μία γραμμή.',
  file_upload_rules_4:
    "Σε περίπτωση σφαλμάτων στα πεδία 'μονάδες μέτρησης' και 'ποσότητα ...'- '1 κομμάτι' θα εισαχθεί αυτόματα.",
  are_mandatory: 'είναι υποχρεωτικά',
  file_upload_page_info:
    'Για να φορτώσετε σωστά τον κατάλογο, ταιριάξτε τα ονόματα πεδίων με τον τρόπο με τον οποίο ονομάζονται για εσάς.',
  edit_file_and_try_again: 'Διορθώστε τα λάθη και δοκιμάστε ξανά',
  do_stop_now: 'Τελειώστε τώρα',
  no_save_upload_remember:
    "Κάνοντας κλικ στο κουμπί' Τέλος τώρα', επιβεβαιώνετε ... η εσφαλμένη εμφάνιση δεδομένων σχετικά με τα προϊόντα σας.",
  exchange_drpops_with_exception: 'Παρουσιάστηκαν σφάλματα κατά την ενσωμάτωση',
  good_must_be_in_catalog_or_category_rewrite: 'Το προϊόν πρέπει να συνδέεται με έναν κατάλογο.',
  fileupload_page_info_rewrite:
    '* Εάν τα προϊόντα στο αρχείο σας έχουν ήδη χωριστεί σε κατηγορίες, απλώς επιλέξτε τον Κατάλογο και ανεβάστε το αρχείο, οι κατηγορίες θα δημιουργηθούν αυτόματα.',
  loading: 'Φόρτωση...',
  show_detail_info: 'Εμφάνιση λεπτομερών πληροφοριών',
  file_upload_success_info:
    "Κάνοντας κλικ στην επιλογή 'Προσθήκη', επιβεβαιώνετε τη φόρτωση των προϊόντων στον αντίστοιχο κατάλογο και κατηγορίες.",
  be_created: 'Δημιουργήθηκαν',
  selected_catalog: 'Κατάλογος επιλεγμένος',
  file_uploaded: 'Μεταφόρτωση αρχείου',
  was_successful: 'ήταν επιτυχής',
  and: 'και',
  valid_min: 'Ελάχιστο {min}',
  value_mm: 'ΜΜ',
  bank_name: 'Σήμα τράπεζας',
  company_name: 'Το πλήρες όνομα της εταιρείας',
  unit_796: 'το πράγμα',
  unit_657: 'Προϊόν',
  unit_111: 'ο όγκος (ml)',
  unit_112: 'Λίτρο',
  unit_163: 'Γραμμάρια(g)',
  unit_625: 'Φύλλο',
  unit_704: 'Ορίσετε',
  unit_715: 'Ζευγάρι',
  unit_166: 'Χιλιόγραμμο(kg)',
  unit_736: 'Ρολό',
  unit_778: 'Συσκευασία',
  item_from_upload_info:
    'Για να προσθέσετε γρήγορα προϊόντα στον κατάλογο, χρησιμοποιήστε τη συνάρτηση',

  promo_briskly_link: 'Υλικά προώθησης',

  store_name_placeholder: 'Όνομα εξόδου',
  serial_number: 'Σειράς',
  coffee_reeboot: 'Επανεκκίνηση',
  coffee_reeboot_coffee_machine: 'Επανεκκινήστε τη μηχανή καφέ',
  coffee_reeboot_text: 'Η επανεκκίνηση της συσκευής βοηθά στην επίλυση ορισμένων προβλημάτων.',
  defer_service: 'Αναβολή υποχρεωτικής υπηρεσίας',
  coffee_postpone: 'Αναβληθεί',
  coffee_service_maintenance_text:
    'Η κατάσταση υπηρεσίας θα αναβληθεί μέχρι την επόμενη ενεργοποίηση.',
  block_drinks: 'Αποκλεισμός παρασκευής ποτών',
  coffee_block_text:
    'Η μηχανή καφέ θα παραμείνει ενεργή, αλλά η παρασκευή ποτών δεν θα είναι διαθέσιμη στους πελάτες',
  block_drink_change: 'Αποκλείστε την αλλαγή των παραμέτρων ποτών',
  coffee_props_block_text:
    'Διατίθεται κατά τη στιγμή της προετοιμασίας. Μόλις ενεργοποιηθεί, οι πελάτες δεν θα μπορούν να προσθέσουν αλλαγές στο πρότυπο ποτό.',
  block_menu_access: 'Αποκλεισμός πρόσβασης στο Προσαρμοσμένο μενού',
  coffee_menu_text: 'Οι πελάτες δεν θα μπορούν να χρησιμοποιήσουν το μενού στη μηχανή καφέ.',
  block_drinks_when_offline:
    'Αποκλείστε την παρασκευή ποτών εάν δεν υπάρχει σύνδεση με το διακομιστή για περισσότερο από 48 ώρες',
  coffee_reloading: 'Επανεκκίνηση ...',
  search_by_items: 'Αναζήτηση με προϊόντα',
  coffee_item_photo: 'Φωτογραφία προϊόντων',
  coffee_item_photo_info:
    '(μπορείτε να ανεβάσετε τη δική σας Φωτογραφία Προϊόντος ή να αφήσετε την προεπιλεγμένη φωτογραφία που φαίνεται παρακάτω)',
  coffee_item_type: 'Τύπος ποτών',
  item_recept: 'Συνταγή προϊόντος',
  value_ml: 'ml',
  cup_with_units_injection: 'Γυαλί {cupsize} ml',
  drink_with_units_injection: 'Ποτό {Drinksize} ml',
  coffee_foam: 'Αφρός γάλακτος',
  coffee_milk: 'Γάλα',
  coffee_water: 'Νερό',
  coffee_coffee_size: 'Καφέ',
  return_values_to_default: 'Επαναφορά προεπιλεγμένων τιμών',
  price_must_be_more_then_inject: 'Η τιμή πρέπει να είναι υψηλότερη {price}',
  coffee_item_photo_info__temp:
    'Μπορείτε να αφήσετε την προεπιλεγμένη φωτογραφία ή να επιλέξετε από τις διαθέσιμες κάνοντας κλικ στο κουμπί «Επιλογή»',
  block_drink_changes: 'Αποκλεισμός αλλαγής παραμέτρων ποτών',
  heating: 'გათბობა',
};
