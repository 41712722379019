import { EXTENDED_TYPES } from '@/apps/trade-point/data/types';
import { cloneDeep } from 'lodash';

const STAGES = {
  type: {
    name: 'type',
    title: 'type',
    route: 'trade-point$create',
  },
  design: {
    name: 'design',
    title: 'design_title',
    route: 'trade-point$stage-design',
  },
  schedule: {
    name: 'schedule',
    title: 'schedule_title',
    route: 'trade-point$stage-schedule',
  },
  employees: {
    name: 'employees',
    title: 'employees',
    route: 'trade-point$stage-employees',
  },
  requisites: {
    name: 'requisites',
    title: 'credentials',
    route: 'trade-point$stage-requisites',
  },
  brisklyModule: {
    name: 'brisklyModule',
    title: 'module_tittle',
    route: 'trade-point$stage-briskly-module',
  },
  exchange: {
    name: 'exchange',
    title: 'items',
    route: 'trade-point$stage-exchange',
  },
  planogram: {
    name: 'planogram',
    title: 'planogram',
    route: 'trade-point$stage-planogram',
    blockHint: 'planogram_block_hint',
  },
  offer: {
    name: 'offer',
    title: 'offer',
    route: 'trade-point$stage-offer',
  },
  delivery: {
    name: 'delivery',
    title: 'delivery',
    route: 'trade-point$stage-delivery',
  },
  fuel: {
    name: 'fuel',
    title: 'fuel',
    route: 'trade-point$stage-fuel',
  },
  payment: {
    name: 'payment',
    title: 'payment_method',
    route: 'trade-point$stage-payment',
  },
  final: {
    name: 'final',
    route: 'trade-point$stage-almostdone',
  },
};

const STAGE_ORDERS = {
  [EXTENDED_TYPES.REFRIGERATOR]: [
    { name: 'type', required: true },
    { name: 'design' },
    { name: 'schedule' },
    { name: 'employees' },
    { name: 'requisites', required: true },
    { name: 'brisklyModule' },
    { name: 'exchange' },
    { name: 'planogram', block: true },
  ],
  [EXTENDED_TYPES.COFFEE_MACHINE]: [
    { name: 'type', required: true },
    { name: 'design' },
    { name: 'schedule' },
    { name: 'employees' },
    { name: 'requisites', required: true },
    // { name: 'brisklyModule' },
    // { name: 'exchange' },
  ],
  [EXTENDED_TYPES.STORE]: [
    { name: 'type', required: true },
    { name: 'design' },
    { name: 'schedule' },
    { name: 'employees' },
    { name: 'requisites', required: true },
    { name: 'exchange' },
    { name: 'delivery' },
  ],
  [EXTENDED_TYPES.STORE_ONLINE]: [
    { name: 'type', required: true },
    { name: 'design' },
    { name: 'schedule' },
    { name: 'employees' },
    { name: 'requisites', required: true },
    { name: 'exchange' },
    { name: 'delivery', required: true },
  ],
  [EXTENDED_TYPES.PRODUCTION_WITH_DELIVERY]: [
    { name: 'type', required: true },
    { name: 'design' },
    { name: 'schedule' },
    { name: 'employees' },
    { name: 'requisites', required: true },
    { name: 'exchange' },
    { name: 'delivery', required: true },
  ],
  [EXTENDED_TYPES.CAFE]: [
    { name: 'type', required: true },
    { name: 'design' },
    { name: 'schedule' },
    { name: 'employees' },
    { name: 'requisites', required: true },
    { name: 'exchange', required: true },
    { name: 'delivery' },
  ],
  [EXTENDED_TYPES.FILLING_STATION]: [
    { name: 'type', required: true },
    { name: 'design' },
    { name: 'schedule' },
    { name: 'employees' },
    { name: 'requisites', required: true },
    { name: 'fuel', required: true },
  ],
  [EXTENDED_TYPES.CIGARETTES]: [
    { name: 'type', required: true },
    { name: 'requisites', required: true },
    { name: 'employees' },
    { name: 'brisklyModule' },
    { name: 'exchange' },
    { name: 'payment' },
  ],
};

const buildInitialStages = () => {
  const stages = cloneDeep(STAGES);

  Object.keys(stages).forEach((stage) => {
    stages[stage].complete = false;
    stages[stage].hide = false;
    stages[stage].visited = stage === 'type';
  });

  return stages;
};

const buildInitialOrder = (type) => cloneDeep(STAGE_ORDERS[type] || []);

export { buildInitialStages, buildInitialOrder };
