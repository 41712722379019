import { bus } from 'shared/core';
import { getI18n } from '@/core/i18n';
import omit from 'lodash/omit';

export default class NotificationService {
  constructor() {
    this.sendSuccessSave = this.sendSuccessSave.bind(this);
    this.sendSuccessDelete = this.sendSuccessDelete.bind(this);
  }

  sendSuccess = (opts = {}) => {
    const i18n = getI18n();
    const { message = i18n.t('success') } = opts;
    const meta = omit({ ...opts, message }, 'error');

    bus.emit('core$notifications/add', { type: 'notification', meta });
  };

  sendSuccessSave() {
    const i18n = getI18n();
    this.sendSuccess({ message: i18n.t('succes_saved') });
  }

  sendSuccessDelete() {
    const i18n = getI18n();
    this.sendSuccess({ message: i18n.t('success_deleted') });
  }

  sendError(opts = {}) {
    const { error = { message: '' } } = opts;
    const meta = omit(opts, 'error');

    bus.emit('core$notifications/add', { type: 'notification', error, meta });
  }
}

export const notificationService = new NotificationService();
