import { nanoid } from 'nanoid';

const state = {
  queue: [],
};

const getters = {
  queue: (state) => state.queue,
  modals: (state) => state.queue.filter(({ type }) => type === 'modal'),
  notifications: (state) => state.queue.filter(({ type }) => type === 'notification'),
  firstFromQueue: (state) => state.queue[0],
};

const actions = {
  add: ({ commit }, opts = {}) => {
    commit('_add', { id: nanoid(), ...opts });
  },

  remove: ({ commit }, id) => {
    commit('_remove', id);
  },
};

const mutations = {
  _add: (state, value) => {
    state.queue = state.queue.concat(value);
  },

  _remove: (state, id) => {
    state.queue = state.queue.filter((notification) => notification.id !== id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
