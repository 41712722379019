export default [
  {
    path: 'reports',
    name: 'reports$reports',
    component: () => import('@/apps/reports/views/Reports'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'reports',
          name: 'reports$reports',
        },
      ],
    },
  },

  {
    path: 'reports/orders',
    name: 'reports$reports-orders',
    component: () => import('@/apps/reports/views/Orders/Orders'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'reports',
          name: 'reports$reports',
        },
        {
          label: 'report_about_orders',
          name: '',
        },
      ],
    },
  },

  {
    path: 'reports/items',
    name: 'reports$reports-items',
    component: () => import('@/apps/reports/views/Items/Items'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'reports',
          name: 'reports$reports',
        },
        {
          label: 'report_about_items',
          name: '',
        },
      ],
    },
  },

  {
    path: 'reports/item',
    name: 'reports$reports-item',
    component: () => import('@/apps/reports/views/Item/Item'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'reports',
          name: 'reports$reports',
        },
        {
          label: 'report_about_item',
          name: '',
        },
      ],
    },
  },

  {
    path: 'reports/sales',
    name: 'reports$reports-sales',
    component: () => import('@/apps/reports/views/Sales/Sales'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'reports',
          name: 'reports$reports',
        },
        {
          label: 'report_about_sales',
          name: '',
        },
      ],
    },
  },

  {
    path: 'reports/customers',
    name: 'reports$reports-customers',
    component: () => import('@/apps/reports/views/Customers/Customers'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'reports',
          name: 'reports$reports',
        },
        {
          label: 'report_about_clients',
          name: '',
        },
      ],
    },
  },

  {
    path: 'reports/unique-customers',
    name: 'reports$reports-unique-customers',
    component: () => import('@/apps/reports/views/UniqueCustomers/UniqueCustomers'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'reports',
          name: 'reports$reports',
        },
        {
          label: 'report_about_unique_clients',
          name: '',
        },
      ],
    },
  },

  {
    path: 'reports/delivery',
    name: 'reports$reports-delivery',
    component: () => import('@/apps/reports/views/Delivery/Delivery'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'reports',
          name: 'reports$reports',
        },
        {
          label: 'report_about_delivery',
          name: '',
        },
      ],
    },
  },
  {
    path: 'reports/promo-codes',
    name: 'reports$reports-promo_codes',
    component: () => import('@/apps/reports/views/PromoCodes/PromoCodes'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'reports',
          name: 'reports$reports',
        },
        {
          label: 'report_about_promocodes',
          name: '',
        },
      ],
    },
  },
  {
    path: 'reports/order-special',
    name: 'reports$reports-order-special',
    component: () => import('@/apps/reports/views/OrderSpecial/OrderSpecial'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'reports',
          name: 'reports$reports',
        },
        {
          label: 'report_about_orders',
          name: '',
        },
      ],
    },
  },

  {
    path: 'reports/delivery',
    name: 'reports$reports-delivery',
    component: () => import('@/apps/reports/views/Delivery/Delivery'),
  },
  {
    path: 'reports/promo-codes',
    name: 'reports$reports-promo_codes',
    component: () => import('@/apps/reports/views/PromoCodes/PromoCodes'),
  },
  {
    path: 'reports/order-special',
    name: 'reports$reports-order-special',
    component: () => import('@/apps/reports/views/OrderSpecial/OrderSpecial'),
  },
];
