import { debounce } from 'lodash';

const state = {
  clientWidth: 0,
  clientHeight: 0,
};

const getters = {
  clientWidth: (state) => state.clientWidth,
  clientHeight: (state) => state.clientHeight,
  isMobile: (state) => state.clientWidth < 719,
};

const actions = {
  useDimensions: debounce(function ({ dispatch }) {
    dispatch('getClientWidth');
    dispatch('getClientHeight');
  }, 500),

  getClientWidth: ({ commit }) => {
    const viewportWidth = document.documentElement.clientWidth;
    commit('_setClientWidth', viewportWidth);
  },

  getClientHeight: ({ commit }) => {
    const viewportHeight = document.documentElement.clientHeight;
    commit('_setClientHeight', viewportHeight);
  },
};

const mutations = {
  _setClientWidth: (state, width) => {
    state.clientWidth = width;
  },

  _setClientHeight: (state, height) => {
    state.clientHeight = height;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
