import { produce } from 'immer';
import { cloneDeep, isEmpty } from 'lodash';
import { companyAPI } from '@/core/api/company';
import { DELIVERY_TARIFFS } from '@/apps/trade-point/data/delivery';
import {
  getEmptyRate,
  processRates,
  buildInitialRates,
  isRatesValid,
  sortRatesByInterval,
  getInvalidFields,
  isRadiusValid,
} from '@/apps/trade-point/utils/delivery';

import { hasChanges } from '@/apps/trade-point/utils/changes';
import { bus } from 'shared/core';
import { notificationService } from '@/core/services/NotificationService';

const initialState = () => ({
  skip: false,
  tariff: DELIVERY_TARIFFS.OWN,
  coordinates: {},
  isPickupEnabled: false,
  ownRates: buildInitialRates(),
  brisklyRates: buildInitialRates(),
  ownRadius: '100',
  brisklyRadius: '150',
  ownService: null,
  geoList: [],
  invalidFields: [],
  removedRates: [],
});

const state = initialState();

const getters = {
  tariff: (state) => state.tariff,
  coordinates: (state) => state.coordinates,
  hasCoordinates: (state) => !isEmpty(state.coordinates),
  isPickupEnabled: (state) => state.isPickupEnabled,
  geoList: (state) => state.geoList,
  ownRates: (state) => state.ownRates,
  brisklyRates: (state) => state.brisklyRates,
  ownRadius: (state) => state.ownRadius,
  brisklyRadius: (state) => state.brisklyRadius,
  invalidFields: (state) => state.invalidFields,

  isValid: (state) => {
    const validMap = {
      [DELIVERY_TARIFFS.OWN]: () => isRatesValid(state.ownRates) && isRadiusValid(state.ownRadius),
      [DELIVERY_TARIFFS.BRISKLY]: () =>
        isRatesValid(state.brisklyRates) && isRadiusValid(state.brisklyRadius),
    };

    if (state.tariff === DELIVERY_TARIFFS.BOTH) {
      return validMap[DELIVERY_TARIFFS.OWN]() && validMap[DELIVERY_TARIFFS.BRISKLY]();
    }

    return validMap[state.tariff]();
  },

  canSkipStage: (state) => state.skip,
  hasUnsavedChanges: (state) =>
    hasChanges(state, initialState(), [
      'skip',
      'coordinates',
      'ownService',
      'geoList',
      'invalidFields',
      'removedRates',
    ]),
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  completeStage: async ({ dispatch }) => {
    try {
      await dispatch('createOrUpdateDelivery');

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  setSkip: ({ commit }, value) => {
    commit('_setSkip', value);
  },

  setTariff: ({ commit, dispatch }, tariff) => {
    commit('_setTariff', tariff);
    // if (tariff === DELIVERY_TARIFFS.OWN) {
    //   dispatch('removeOfferStage');
    //   return;
    // }

    // dispatch('addOfferStage');
  },

  setCoordinates: ({ commit }, coordinates) => {
    commit('_setCoordinates', coordinates);
  },

  setPickupEnabled: ({ commit }, value) => {
    commit('_setPickupEnabled', value);
  },

  setOwnRate: ({ commit }, { rate, idx }) => {
    commit('_setOwnRate', { rate, idx });
    commit('_setInvalidFields', []);
  },

  setOwnRates: ({ commit }, rates) => {
    commit('_setOwnRates', rates);
    commit('_setInvalidFields', []);
  },

  setBrisklyRate: ({ commit }, { rate, idx }) => {
    commit('_setBrisklyRate', { rate, idx });
    commit('_setInvalidFields', []);
  },

  setBrisklyRates: ({ commit }, rates) => {
    commit('_setBrisklyRates', rates);
    commit('_setInvalidFields', []);
  },

  addOwnRate: ({ commit }) => {
    commit('_addOwnRate');
  },

  addBrisklyRate: ({ commit }) => {
    commit('_addBrisklyRate');
  },

  removeOwnRate: ({ state, commit }, idx) => {
    commit('_removeOwnRate', idx);

    if (state.ownRates.length === 1) {
      const [singleRate] = state.ownRates;
      const validSingleRate = { ...singleRate, order_amount_min: 1 };
      commit('_setOwnRate', { rate: validSingleRate, idx: 0 });
    }
  },

  removeBrisklyRate: ({ state, commit }, idx) => {
    commit('_removeBrisklyRate', idx);

    if (state.brisklyRates.length === 1) {
      const [singleRate] = state.brisklyRates;
      const validSingleRate = { ...singleRate, order_amount_min: 1 };
      commit('_setBrisklyRate', { rate: validSingleRate, idx: 0 });
    }
  },

  setOwnRadius: ({ commit }, radius) => {
    commit('_setOwnRadius', radius);
  },

  setBrisklyRadius: ({ commit }, radius) => {
    commit('_setBrisklyRadius', radius);
  },

  setOwnService: ({ commit }, service) => {
    commit('_setOwnService', service);
  },

  setGeoList: ({ commit }, geoList) => {
    commit('_setGeoList', geoList);
  },

  sortRates: ({ state, commit }) => {
    const sortMap = {
      [DELIVERY_TARIFFS.OWN]: () => commit('_sortOwnRates'),
      [DELIVERY_TARIFFS.BRISKLY]: () => commit('_sortBrisklyRates'),
    };

    state.tariff === DELIVERY_TARIFFS.BOTH
      ? Object.values(sortMap).forEach((sort) => sort())
      : sortMap[state.tariff]();
  },

  checkInvalidIntervals: ({ commit, state }) => {
    const invalidMap = {
      [DELIVERY_TARIFFS.OWN]: () => getInvalidFields(state.ownRates, DELIVERY_TARIFFS.OWN),
      [DELIVERY_TARIFFS.BRISKLY]: () =>
        getInvalidFields(state.brisklyRates, DELIVERY_TARIFFS.BRISKLY),
    };

    const invalidFields =
      state.tariff === DELIVERY_TARIFFS.BOTH
        ? Object.values(invalidMap).flatMap((getFields) => getFields())
        : invalidMap[state.tariff]();

    commit('_setInvalidFields', invalidFields);
  },

  createOrUpdateDelivery: async ({ dispatch, state }, store) => {
    const create = async (tariff) => {
      await dispatch('createOrUpdateService', { tariff });
      await dispatch('createOrUpdateGeo', { tariff, store });
      await dispatch('createOrUpdateRate', { tariff, store });
    };

    await dispatch('setPickupEnabledToStore', store);

    if (state.tariff !== DELIVERY_TARIFFS.BOTH) {
      return create(state.tariff).then(notificationService.sendSuccessSave);
    }

    await create(DELIVERY_TARIFFS.OWN);
    await create(DELIVERY_TARIFFS.BRISKLY);
    notificationService.sendSuccessSave();
  },

  deleteDelivery: async ({ dispatch }, tariff) => {
    await dispatch('deleteService', tariff);
    await dispatch('deleteGeo', tariff);
    await dispatch('deleteRates', tariff);
  },

  deleteService: async ({ state, commit }, tariff) => {
    if (tariff === DELIVERY_TARIFFS.BRISKLY || !state.ownService) return;

    await companyAPI.deleteService({ id: state.ownService.id });
    commit('_setOwnService', null);
  },

  deleteGeo: async ({ state, commit }, tariff) => {
    const serviceType = tariff === DELIVERY_TARIFFS.OWN ? 2 : 1;

    const geo = state.geoList.find((geo) => geo.delivery_service_type === serviceType);
    if (!geo) return;

    await companyAPI.deleteGeo({ id: geo.id });
    const newGeoList = state.geoList.filter(({ id }) => id !== geo.id);

    commit('_setGeoList', newGeoList);
  },

  deleteRates: async ({ state, commit }, tariff) => {
    const isOwn = tariff === DELIVERY_TARIFFS.OWN;

    const rates = isOwn ? state.ownRates : state.brisklyRates;
    const commitRate = isOwn ? '_setOwnRates' : '_setBrisklyRates';

    rates.forEach((rate) => commit('_relieveRemovedRate', rate.id));

    await Promise.all(rates.map((rate) => companyAPI.deleteRate({ id: rate.id })));

    commit(commitRate, []);
  },

  createOrUpdateService: async ({ commit, state }, { tariff }) => {
    if (tariff === DELIVERY_TARIFFS.BRISKLY) return;

    const payload = {
      external_service: 'internal',
      name: '',
    };

    const ownService = state.ownService
      ? await companyAPI.updateService({ ...state.ownService, ...payload })
      : await companyAPI.createService(payload);

    commit('_setOwnService', ownService);
  },

  createOrUpdateGeo: async ({ commit, state, rootGetters }, opts = {}) => {
    const { tariff, store } = opts;
    const isOwn = tariff === DELIVERY_TARIFFS.OWN;

    const service = state.ownService;
    const { id: store_id } = store || rootGetters['trade-point$create/store'];

    if (isOwn && !service) return;

    const radius = String(isOwn ? state.ownRadius : state.brisklyRadius);

    const payload = {
      center_x: state.coordinates.coords_lat,
      center_y: state.coordinates.coords_lng,
      delivery_service_type: isOwn ? 2 : 1,
      radius: Number(radius.replace(/[^\d]/g, '')),
      store_id,
    };

    if (isOwn) payload.delivery_service_id = service.id;

    const geo = state.geoList.find(
      (geo) => geo.delivery_service_type === payload.delivery_service_type,
    );
    const newGeo = geo
      ? await companyAPI.updateGeo({ ...geo, ...payload })
      : await companyAPI.createGeo(payload);

    geo ? commit('_updateGeo', newGeo) : commit('_addGeo', newGeo);
  },

  createOrUpdateRate: async ({ commit, state, rootGetters }, opts = {}) => {
    const { tariff, store } = opts;
    const isOwn = tariff === DELIVERY_TARIFFS.OWN;
    const service = state.ownService;

    const { id: store_id } = store || rootGetters['trade-point$create/store'];

    const payload = {
      store_id,
      delivery_service_type: isOwn ? 2 : 1,
    };
    if (isOwn) payload.delivery_service_id = service.id;

    const currentRates = isOwn ? state.ownRates : state.brisklyRates;
    const commitRate = isOwn ? '_setOwnRate' : '_setBrisklyRate';

    await Promise.all(
      state.removedRates.map((id) => {
        return companyAPI.deleteRate({ id }).then(() => commit('_relieveRemovedRate', id));
      }),
    );

    currentRates.forEach((rate) => commit('_relieveRemovedRate', rate.id));

    const { create, update } = currentRates.reduce(
      (acc, rate, idx) => {
        return rate.id
          ? { ...acc, update: [...acc.update, { rate, idx }] }
          : { ...acc, create: [...acc.create, { rate, idx }] };
      },
      { create: [], update: [] },
    );

    if (create.length) {
      await Promise.all(
        processRates(create).map(async ({ rate, idx }) => {
          const items = await companyAPI.createRate({ ...payload, rates: [rate] });
          if (items.length) commit(commitRate, { rate: items[0], idx });
        }),
      );
    }

    if (update.length) {
      await Promise.all(
        processRates(update).map(async ({ rate, idx }) => {
          const newRate = await companyAPI.updateRate({ ...rate, ...payload });
          commit(commitRate, { rate: newRate, idx });
        }),
      );
    }
  },

  setPickupEnabledToStore: async ({ state, rootGetters, dispatch }, store) => {
    const currentStore = store || rootGetters['trade-point$create/store'];
    if (!currentStore) return;
    if (currentStore.is_pickup_enabled === state.isPickupEnabled) return;

    const is_pickup_enabled = Number(state.isPickupEnabled);
    const updatedStore = await companyAPI.updateStore({ ...currentStore, is_pickup_enabled });

    if (!rootGetters['trade-point$create/store']) return;

    return dispatch('trade-point$create/setStore', updatedStore, { root: true });
  },

  addOfferStage: ({ dispatch }) => {
    return dispatch('trade-point$create/addStageInOrder', 'offer', { root: true });
  },

  removeOfferStage: ({ dispatch }) => {
    return dispatch('trade-point$create/removeStageFromOrder', 'offer', { root: true });
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setSkip: (state, value) => {
    state.skip = value;
  },

  _setTariff: (state, tariff) => {
    state.tariff = tariff;
  },

  _setCoordinates: (state, coordinates) => {
    state.coordinates = coordinates;
  },

  _setPickupEnabled: (state, value) => {
    state.isPickupEnabled = value;
  },

  _setOwnRate: (state, { rate, idx }) => {
    state.ownRates = produce(cloneDeep(state.ownRates), (draft) => {
      draft[idx] = rate;
    });
  },

  _setBrisklyRate: (state, { rate, idx }) => {
    state.brisklyRates = produce(cloneDeep(state.brisklyRates), (draft) => {
      draft[idx] = rate;
    });
  },

  _setOwnRates: (state, rates) => {
    state.ownRates = rates;
  },

  _setBrisklyRates: (state, rates) => {
    state.brisklyRates = rates;
  },

  _addOwnRate: (state) => {
    const newRate = state.ownRates.length ? getEmptyRate() : getEmptyRate('1', '999 999');

    state.ownRates = produce(cloneDeep(state.ownRates), (draft) => {
      draft.push(newRate);
    });
  },

  _addBrisklyRate: (state) => {
    const newRate = state.brisklyRates.length ? getEmptyRate() : getEmptyRate('1', '999 999');

    state.brisklyRates = produce(cloneDeep(state.brisklyRates), (draft) => {
      draft.push(newRate);
    });
  },

  _removeOwnRate: (state, idx) => {
    const rate = state.ownRates[idx];

    state.ownRates = produce(cloneDeep(state.ownRates), (draft) => {
      draft.splice(idx, 1);
    });

    if (rate.id) state.removedRates = [...state.removedRates, rate.id];
  },

  _removeBrisklyRate: (state, idx) => {
    const rate = state.brisklyRates[idx];

    state.brisklyRates = produce(cloneDeep(state.brisklyRates), (draft) => {
      draft.splice(idx, 1);
    });

    if (rate.id) state.removedRates = [...state.removedRates, rate.id];
  },

  _relieveRemovedRate: (state, id) => {
    state.removedRates = state.removedRates.filter((removedId) => removedId !== id);
  },

  _sortOwnRates: (state) => {
    state.ownRates = sortRatesByInterval(state.ownRates);
  },

  _setInvalidFields: (state, invalidFields) => {
    state.invalidFields = invalidFields;
  },

  _sortBrisklyRates: (state) => {
    state.brisklyRates = sortRatesByInterval(state.brisklyRates);
  },

  _setOwnRadius: (state, radius) => {
    state.ownRadius = radius;
  },

  _setBrisklyRadius: (state, radius) => {
    state.brisklyRadius = radius;
  },

  _setOwnService: (state, service) => {
    state.ownService = service;
  },

  _updateGeo: (state, newGeo) => {
    state.geoList = state.geoList.map((geo) =>
      geo.delivery_service_type === newGeo.delivery_service_type ? newGeo : geo,
    );
  },

  _addGeo: (state, newGeo) => {
    state.geoList = [...state.geoList, newGeo];
  },

  _setGeoList: (state, geoList) => {
    state.geoList = geoList;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
