import { format } from 'date-fns';

export const parseDate = (date) => {
  if (date === '0000-00-00 00:00:00') {
    return format(new Date(), 'yyyy-MM-dd HH:mm');
  }
  date = format(date, 'yyyy-MM-dd HH:mm');
  const [onlyDate, time] = date.split(' ');
  const [year, month, day] = onlyDate.split('-');
  const parsetDate = `${day}.${month}.${year} ${time}`;

  return parsetDate;
};

export const getHour = (date) => {
  const [_, time] = date.split(' ');
  const [hour, minute] = time.split(':');
  return { hour, minute };
};

export const parseDateFoBe = (date, time) => {
  let parsedDate;
  const [day, mouth, year] = date.split('.');
  if (time) {
    // const [hour, min] = time;
    console.log(year, mouth, day, time);

    parsedDate = format(new Date(`${year}, ${mouth}, ${day} ${time}:00`), 'yyyy-MM-dd HH:mm:ss');
  }

  return parsedDate;
};
