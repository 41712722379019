import { companyAPI } from '@/core/api/company';
import { getI18n } from '@/core/i18n';

const state = {
  activeCatalog: 0,
  activeCategory: 0,
  units: [],
  discountsFields: {},
  currentPage: 1,
  showGoodsOnPage: 50,
  goodsMeta: {},

  search: '',

  sorted: {
    id: 'ASC',
  },
};

const getters = {
  currentPage: (state) => state.currentPage,
  showGoodsOnPage: (state) => state.showGoodsOnPage,
  sorted: (state) => state.sorted,
  search: (state) => state.search,
  goodsMeta: (state) => state.goodsMeta,
  activeCatalog: (state) => state.activeCatalog,
  activeCategory: (state) => state.activeCategory,
  units: (state) => {
    const i18n = getI18n();
    return state.units.map((unit) => ({ ...unit, title: i18n.t(unit.title) }));
  },
  discountsFields: (state) => state.discountsFields,
};

const actions = {
  setCurrentPage: async ({ commit }, page) => {
    commit('_setCurrentPage', page);
  },

  setShowGoodsOnPage: async ({ commit }, perPage) => {
    commit('_setShowGoodsOnPage', perPage);
  },

  setSorted: async ({ commit }, payload) => {
    commit('_setSorted', payload);
  },

  setSearch: async ({ commit }, search) => {
    commit('_setSearch', search);
  },

  setGoodsMeta: async ({ state, commit }, meta) => {
    const newMeta = { ...meta, limit: state.showGoodsOnPage };
    commit('_setGoodsMeta', newMeta);
  },

  setActiveCatalog: async ({ commit }, catalog) => {
    commit('_setActiveCatalog', catalog);
  },

  setActiveCategory: async ({ commit }, category) => {
    commit('_setActiveCategory', category);
  },

  setUnitsList: async ({ commit }) => {
    try {
      const unitList = await companyAPI.getUnitsCode();
      commit('_setUnitsList', unitList);
    } catch (error) {}
  },

  setDiscountFieldsList: async ({ commit }) => {
    try {
      const list = await companyAPI.getItemDiscountFields();
      commit('_setDiscountFieldsList', list);
    } catch (error) {}
  },
};

const mutations = {
  _setCurrentPage: (state, page) => {
    state.currentPage = page;
  },
  _setShowGoodsOnPage: (state, perPage) => {
    state.showGoodsOnPage = perPage;
  },
  _setSorted: (state, payload) => {
    state.sorted = { ...payload };
  },
  _setSearch: (state, search) => {
    state.search = search;
  },
  _setGoodsMeta: (state, meta) => {
    state.goodsMeta = meta;
  },
  _setActiveCatalog: (state, { catalog }) => {
    state.activeCatalog = catalog;
  },
  _setActiveCategory: (state, { category }) => {
    state.activeCategory = category;
  },
  _setUnitsList: (state, unitList) => {
    state.units = unitList.map((unit) => {
      return {
        value: unit,
        title: `unit_${unit}`,
      };
    });
  },

  _setDiscountFieldsList: (state, list) => {
    state.discountsFields = list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
