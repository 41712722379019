<template>
  <router-view />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  methods: {
    ...mapActions({
      useDimensions: 'core$clientmeta/useDimensions',
    }),
  },

  created() {
    this.useDimensions();
    this.$nextTick(() => {
      window.addEventListener('resize', this.useDimensions);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.useDimensions);
  },
};
</script>
