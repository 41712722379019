export default [
  {
    path: 'catalogs',
    component: () => import('@/apps/catalogs/views/Wrapper'),
    meta: {
      breadCrumb: [
        {
          name: 'main',
          label: 'main_page',
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'catalogs$catalogs-list',
        component: () => import('@/apps/catalogs/views/Entry'),
        meta: {
          breadCrumb: [
            {
              name: 'main',
              label: 'main_page',
            },
            {
              label: 'items',
              name: 'items$items',
            },
            {
              name: 'catalogs$catalogs-list',
              label: 'catalogs_and_categories',
            },
          ],
        },
      },
      {
        path: 'catalog/add',
        name: 'catalogs$add-catalog',
        meta: {
          breadCrumb: [
            {
              name: 'main',
              label: 'main_page',
            },
            {
              label: 'items',
              name: 'items$items',
            },
            {
              name: 'catalogs$catalogs-list',
              label: 'catalogs_and_categories',
            },
            {
              name: 'catalogs$add-catalog',
              label: 'add_catalog',
            },
          ],
        },
        component: () => import('@/apps/catalogs/views/Catalog/Catalog'),
      },
      {
        path: 'catalog/:catalogId',
        name: 'catalogs$edit-catalog',
        props: true,
        meta: {
          breadCrumb: [
            {
              name: 'main',
              label: 'main_page',
            },
            {
              label: 'items',
              name: 'items$items',
            },
            {
              name: 'catalogs$catalogs-list',
              label: 'catalogs_and_categories',
            },
            {
              name: 'catalogs$edit-catalog',
              label: 'do_catalog_edit',
            },
          ],
        },
        component: () => import('@/apps/catalogs/views/Catalog/Catalog'),
      },
      {
        path: 'category/add',
        name: 'catalogs$add-category',
        meta: {
          breadCrumb: [
            {
              name: 'main',
              label: 'main_page',
            },
            {
              label: 'items',
              name: 'items$items',
            },
            {
              name: 'catalogs$catalogs-list',
              label: 'catalogs_and_categories',
            },

            { name: 'catalogs$add-category', label: 'add_category' },
          ],
        },
        component: () => import('@/apps/catalogs/views/Category/Category'),
      },
      {
        path: 'category/:categoryId',
        name: 'catalogs$edit-category',
        props: true,
        meta: {
          breadCrumb: [
            {
              name: 'main',
              label: 'main_page',
            },
            {
              label: 'items',
              name: 'items$items',
            },
            {
              name: 'catalogs$catalogs-list',
              label: 'catalogs_and_categories',
            },

            { name: 'catalogs$edit-category', label: 'do_category_edit' },
          ],
        },
        component: () => import('@/apps/catalogs/views/Category/Category'),
      },
      {
        path: 'catalog-rebase/:catalogID',
        name: 'catalogs$rebase-catalog',
        props: true,
        component: () => import('@/apps/catalogs/views/CategoryRebase/CategoryRebase'),

        meta: {
          breadCrumb: [
            {
              name: 'main',
              label: 'main_page',
            },
            { label: 'items', name: 'items$items' },
            {
              name: 'catalogs$catalogs-list',
              label: 'catalogs_and_categories',
            },
            {
              name: 'catalogs$rebase-catalog',
              label: 'rebase_catalogs',
            },
          ],
        },
      },
    ],
  },
];
