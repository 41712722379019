import { companyAPI } from '@/core/api/company';
import { cloneDeep } from 'lodash';

const state = {
  activeStore: null,
  activeGroupBy: 'none',
  activePaymentType: null,
  activePeriod: null,
  activeType: null,
  activeFileType: 'xlsx',
  activeStatuses: {},
  searchStore: '',

  activeDeliveryType: {},
  canHasDeliveryService: false,
  selectedStores: [],
};

const getters = {
  activeStore: (state) => state.activeStore,
  activeGroupBy: (state) => state.activeGroupBy,
  activePaymentType: (state) => state.activePaymentType,
  activePeriod: (state) => state.activePeriod,
  activeFileType: (state) => state.activeFileType,
  activeStatuses: (state) => state.activeStatuses,
  activeType: (state) => state.activeType,
  searchStore: (state) => state.searchStore,

  activeDeliveryType: (state) => state.activeDeliveryType,
  canHasDeliveryService: (state) => state.canHasDeliveryService,
  selectedStores: (state) => state.selectedStores,
};

const actions = {
  setSearchStore: ({ commit }, value) => {
    commit('_setSearchStore', value);
  },

  setActiveStore: ({ commit }, value) => {
    commit('_setActiveStore', value);
  },

  setActiveGroupBy: ({ commit }, value) => {
    commit('_setActiveGroupBy', value);
  },

  setActivePaymentType: ({ commit }, value) => {
    commit('_setActivePaymentType', value);
  },

  setActivePeriod: ({ commit }, value) => {
    commit('_setActivePeriod', value);
  },

  setActiveFileType: ({ commit }, value) => {
    commit('_setActiveFileType', value);
  },

  setActiveStatuses: ({ commit }, value) => {
    commit('_setActiveStatuses', value);
  },

  setActiveDeliveryType: ({ commit }, value) => {
    commit('_setActiveDeliveryType', value);
  },

  setActiveType: ({ commit }, value) => {
    commit('_setActiveType', value);
  },

  setSelectedStores: ({ commit }, selectedStore) => {
    commit('_setSelectedStores', selectedStore);
  },

  checkCanHasDelivery: async ({ commit }) => {
    let value = false;

    try {
      const { meta } = await companyAPI.hasDelivery();
      value = Boolean(meta.total_items);
    } catch (error) {
      console.log(error);
    }
    commit('_setHasDelivery', value);
  },
};

const mutations = {
  _setSearchStore: (state, v) => {
    state.searchStore = v;
  },

  _setActiveStore: (state, v) => {
    state.activeStore = v;
  },

  _setActiveGroupBy: (state, v) => {
    state.activeGroupBy = v;
  },

  _setActivePaymentType: (state, v) => {
    state.activePaymentType = v;
  },

  _setActivePeriod: (state, v) => {
    state.activePeriod = v;
  },

  _setActiveFileType: (state, v) => {
    state.activeFileType = v;
  },

  _setActiveStatuses: (state, v) => {
    state.activeStatuses = v;
  },

  _setActiveDeliveryType: (state, v) => {
    state.activeDeliveryType = v;
  },

  _setActiveType: (state, v) => {
    state.activeType = v;
  },
  _setHasDelivery: (state, v) => {
    state.canHasDeliveryService = v;
  },
  _setSelectedStores: (state, v) => {
    if (typeof v === 'string') {
      state.selectedStores = [];
      return;
    }

    const processedStores = cloneDeep(state.selectedStores);
    const processedStore = state.selectedStores.find((store) => store.id === v.id);
    if (!processedStore) processedStores.push(v);
    else {
      processedStores = processedStores.filter((store) => store.id !== v.id);
    }
    state.selectedStores = processedStores;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
