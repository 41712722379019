export default {
  documents_subtitle: `In this section, you need to familiarize yourself with the platform's operating rules and other legal documents. <br /> <br /> Also, the current status of the documents required for the correct interaction between you and Briskly is displayed here. <br/> Including, it is possible to remotely sign a number of documents using the SMS confirmation function.`,

  reports_subtitle: `The “Reports” section allows you to build visual analytical reports of your business on the b: pay technology platform. <br /> Get clear, reliable information on the parameters and samples you are interested in, and based on this data, improve your business processes, increase the efficiency of trade points and increase your income!`,

  videos_subtitle: `In this section you can get video recordings from cameras installed at your trade points`,

  log_out_question: 'Are you sure you want to end the current session?',
  use_existing_catalog: 'Use an existing catalog',
  create_new_catalog: 'Create a new catalog',
  service_mts_kassa: 'MTS Cashier',
  add_schedule_and_save_as_template: 'Add options and then save them as a template',
  no_saved_templates: 'No templates saved',
  address_doesnt_correct: 'The address is not correct',
  support_service_greeting: 'Hello! We will be happy to assist you!',
  year_ago: 'A year ago',
  in_next_month: 'Next month',
  in_this_month: 'This month',
  gender_female: 'Female',
  gender_male: 'Male',
  filter_birth_date_month: 'Birthday',
  filter_purchase_days_ago: 'Age of purchases',
  filter_purchases_quantity: 'Number of purchases',
  filter_registered_days_ago: 'Registration date',
  filter_age: 'Age',
  filter_gender: 'Sex',
  select_employee_for_test_message: 'Select an employee to send a test message',
  filter_will_be_available_soon: 'This functionality will be available soon',
  mailing_status_rejected: 'Rejected',
  mailing_going_to_delete: 'You are about to delete the newsletter',
  mailing_save_filters: 'Save filters',
  mailing_selecting_filters: 'Filter selection',
  mailing_created_filters: 'Ready filters',
  mailing_all_clients: 'All clients',
  mailing_may_not_pass_moderation: 'Your newsletter may not have time to pass moderation.',
  mailing_invalid_time: 'Set the time interval from 9:00 to 21:00',
  mailing_save_and_start: 'Start mailing',
  mailing_only_save: 'Save but do not run',
  mailing_text: 'Mailing text',
  mailing_cost: 'Mailing cost',
  mailing_client_count: 'Number of clients',
  mailing_audience: 'Audience for mailing',
  mailing_date: 'Mailing date',
  mailing_name: 'Mailing name',
  mailing_moderation_description:
    'After passing the moderation, you will need to pay for the mailing. Please note that moderation takes place within 1-2 business days, payment is credited to the account within 2 business days. Consider this when planning your mailing.',
  mailing_add_description:
    'Add your newsletter. Specify submission time, customer audience and message text.',
  mailing_status_ready: 'Pay',
  mailing_status_cancelled: 'Canceled',
  mailing_status_wait: 'Awaiting dispatch',
  mailing_status_moderation: 'Moderation',
  mailing_status_saved: 'Saved',
  mailing_status_complete: 'Completed',
  mailing_edit: 'Editing a newsletter',
  mailing_new: 'New newsletter',
  do_add_mailing: 'Create a newsletter',
  mailings: 'Newsletters',
  report_order_id: 'Order number',
  report_about_item: 'Product statistics',
  product_product_date: 'Print production date',
  add_catalog_info:
    'You can add several categories to one catalog at once, for this use the “+” to add an additional field for entering the name of a new category.',
  add_catalog_warning:
    'You can link the catalog to several trade points, for this use the “+” to add an additional field for selecting a trade point.',
  write_new_category_name: 'Enter a category name',
  write_new_catalog_name: 'Enter a catalog name',
  warning: 'Attention!',
  search_by_catalogs: 'Search by catalogs',
  search_by_categories: 'Search by categories',
  catalogs: 'Catalogs',
  category_name: 'Name of category',
  add_category_action: 'Adding a category',
  add_catalog_action: 'Adding a catalog',
  add_category: 'Add a category',
  add_catalog: 'Add a catalog',
  categories_of_catalogs: 'Catalog categories',
  catalogs_and_categories: 'Catalogs and categories',
  need_to_select_catalog_and_category:
    'To create a product\n need to select\n catalog and category ',
  create_catalog_label_2:
    'You may have multiple catalogs in your account.\nFor example, for a micromarket selling ready-made food or selling frozen products.\nSelecting the appropriate catalog, you quickly switch from one list of products to another.\nYou can change this catalog here. ',
  exchange_settigns: 'Sharing settings',
  do_collapse: 'Collapse',
  delete_catalog_text_confirm_2: 'All categories and products in it will be deleted from it',
  delete_catalog_text_confirm_1: 'When deleting a catalog',
  delete_category_text_confirm:
    'This action will remove the catalog, and the products that are in it will be in the "all" category',
  unknown_error: 'Something went wrong',
  fail_onboarding_init_company_info: 'Failed to load company data filled in earlier',
  fail_init_employee_store_list: 'Failed to load store data',
  fail_unbind_employee_to_store: 'Failed to untie employee',
  fail_bind_employee_to_store: 'Failed to attach employee',
  error: 'Error',
  briskly_module_reassign_message: 'Are you sure you want to bind the new Briskly module?',
  briskly_module_reassign_title: 'Binding a new module',
  do_briskly_module_finish: 'Complete',
  do_briskly_module_active_new: 'Activate a new module',
  show_by: 'Show by',
  fields_are_optional: 'fields are optional',
  additionally: 'Additionally',
  order_composition: 'Order list',
  total_amount: 'Total cost',
  claim_sended: 'Complaint sent',
  order_info: 'Information about order',
  total_discount: 'Discount amount',
  client_id: 'Client ID',
  order: 'Order',
  for_order: 'To order',
  claim_text: 'Complaint text',
  do_send_claim: 'Send a complaint',
  actions: 'Actions',
  do_create_category: 'Create a category',
  write_category_name: 'Enter the category name',
  item_add_action2_label: 'Create categories and add products to them',
  integration_file_label:
    'To download the catalog correctly, match our field names with how they are named for you.',
  integration: 'Integration',
  upload_or_drag_file: 'Upload or drag file',
  select_catalog: 'Select catalog',
  integration_entry_label_option4: 'Add products manually',
  integration_entry_label_option3: 'Select an existing catalog',
  integration_entry_label_option2: 'Upload the product file.',
  integration_entry_label_option1: 'Integrate the Briskly system with your accounting system.',
  integration_entry_label:
    'This is where you add the products you want to sell. This can be done in several ways.',
  do_check_integration: 'Check Integration',
  select_other_system2: 'Choose another system',
  select_other_system: 'Select system',
  do_skip: 'Skip',
  do_change_info: 'To change the data',
  integration_errors: 'Integration errors occurred',
  inventory_system: 'Inventory accounting system',
  date: 'date',
  legal_as_type_of_organization: 'Organization',
  ip_as_type_of_organization: 'SP',
  type_of_employment: 'Employment type',
  phone_number: 'Phone number',
  your_name: 'Your name',
  bank_correspondent_number: 'Corr. score',
  bank_account_number: 'Account number',
  receivers_bank: "payee's bank",
  bank_credentials: 'Bank details',
  bik_register_placeholder: 'Enter 9 digits of BIK bank number',
  bik: 'BIK',
  bank_account_register_placeholder:
    'Enter 20 digits of the bank account (if the account consists of 22 digits, enter the first 20)',
  bank_account: 'Current account number',
  passport_page_with_place_of_live: 'Registration page',
  first_passport_page: 'First page of passport',
  ogrn: 'OGRN',
  name_short_placeholder: 'Bagration',
  name_short: 'Short name',
  type_of_company_placeholder: 'LLC, SP',
  name_short_with_opf_placeholder: "LLC 'Bagration'",
  name_short_with_opf: 'Short name with OPF',
  ooo_full_name: 'Limited liability company',
  okopf_name_full_label: 'Full legal form',
  okopf_name_short_label: 'Abbreviated legal form',
  position_placeholder: 'General manager',
  position_label: "Position (as in the organization's charter)",
  third_name_placeholder: 'Mikhailovich',
  third_name: 'Patronymic',
  second_name_placeholder: 'Mikhailov',
  second_name: 'Surname',
  first_name_placeholder: 'Michael',
  acting: 'Acting',
  usn2: 'STS (income 6%)',
  usn1: 'STS (income-expenses)',
  osno: 'OSN',
  do_write_inn: 'Enter ITN/TIN',
  taxes_system: 'Tax system',
  capital: 'Authorized capital',
  okved: 'OKVED',
  registration_date: 'Registration date',
  head_of_company: 'Leader',
  inn: 'TIN',
  organization_phone: 'Organization phone',
  organization_name: 'Name of the organization',
  organization_info: 'Organization data',
  unknown_organization: 'Unknown organization',
  choose_option_or_continue_input: 'Select an option or keep typing',
  cabinet_lowercase: 'cabinet',
  personal_lowercase: 'private',
  do_download_agreement: 'Download agreement',
  do_mark_all: 'Select all',
  amount_of_stickers_per_position: 'Number of stickers for one position',
  general_expiration_date: 'General shelf life',
  do_apply: 'Apply',
  years: 'Years',
  months: 'Months',
  weeks: 'Weeks',
  days: 'Days',
  minutes: 'Minutes',
  hours: 'Hours',
  time: 'Time',
  production_date: 'Manufacturing date',
  manufacturing_address: 'Manufacturing address',
  manufacturer_name: "Manufacturer's name",
  do_print_stickers: 'Print stickers',
  stickers: 'Stickers',
  doesnt_have_category: 'Has no category',
  catalog: 'Catalog',
  do_continue: 'Proceed',
  do_go_back: 'Back',
  do_add: 'Add to',
  write_name_of_new_category: 'Enter a name for the new category',
  write_name_of_new_catalog: 'Enter the name of the new catalog',
  stickers_print: 'Sticker Printing',
  do_create_new_catalog: 'Create a new catalog',
  do_create_catalog: 'Create catalog',
  items: 'Products',
  sticker: 'Sticker',
  active: 'Active',
  dimension_units: 'Unit measurements',
  photo: 'Photo',
  article: 'Vendor code',
  good_suffix: 'product',
  later: 'Later',
  go_to_edit: 'Go to editing',
  good_with_no_valid_barcode_need_write_it:
    'This product does not have a valid Barcode,\n fill it in ',
  need_detals: 'Need clarification',
  be_deteted: 'Will be deleted',
  do_delete_category: 'Delete category',
  add_goods: 'Add products',
  maybe_doest_valid_control_digit: 'Possibly: Invalid check digit!',
  error_with_bar_code: 'Error with Barcode generation!',
  with_barcode: 'With barcode',
  at_the_goods: 'Product',
  nutritional_value_per_100g_of_product: 'Nutritional value (per 100g of product)',
  ccal: 'kcal',
  calories_full: 'Energy value',
  carbohydrates: 'Carbohydrates',
  proteins: 'Protein',
  fats: 'Fats',
  do_not_specified: 'Not specified',
  do_not_specifiedSuffix: 'Not specified',
  composition: 'Composition',
  y: 'y',
  m: 'm',
  d: 'd',
  h: 'h',
  manufacturing_by: 'Produced by',
  sticker_quantity: 'Number of stickers',
  barcode: 'Barcode',
  expiration_date: 'Expiration date',
  goods_name: 'Name of a product',
  do_add_more_categories: 'Add another category',
  need_select_catalog: 'You need to select a catalog ',
  add_and_edit_catalog: 'Add / edit a catalog ',
  item_add_action2: 'Add products',
  dimension: 'Dimension',
  item_edit_action: 'Editing an item',
  item_add_action: 'Adding a product',
  block_with_modifications: 'Block with mods',
  vat_rate: 'VAT rate, %',
  vat_mode: 'VAT regime',
  optional_description: 'Description (optional)',
  calories: 'Calories',
  composition_of_item: 'Composition',
  age_limit: 'Age limit, years',
  units: 'Measurement units',
  altogether: 'Total',
  standard: 'Standard',
  payment: 'Estimated',
  non_tax: 'Is not a subject to a tax',

  portion: 'Portion',
  piece: 'Piece (pc)',
  catalog_name: 'Catalog name',
  create_catalog_label:
    'There may be several catalogs in your account (for example, for a micromarket selling prepared food or selling frozen products). Selecting the appropriate catalog, you quickly switch from one list of products to another. This is where you need to create this catalog.',
  create_catalog: 'Create a catalog',
  category_sufix: 'Categories',
  categories: 'Categories',
  category: 'Category',
  step_reminder: 'You can come back to this step later.',
  volume: 'Volume',
  good_photo: 'Product photo',
  doesnt_act: 'Day off',
  around_the_clock: 'Around the clock',
  set: 'Set',
  type_of_discount: 'Discount type',
  show_for_clients: 'Show for clients',
  is_disabled_female: 'Not available',
  is_enabled_female: 'Available',
  is_disabled: 'Not available',
  is_enabled: 'Available',
  title_for_clients: 'Customer header',
  discount_group: 'Set of discounts',
  store_name: 'Trade point name',
  discount_assign: 'Assigning discounts',
  you_are_trying_to_delete_discount_group: 'You are about to delete a set of discounts',
  you_are_trying_to_delete_discount: 'You are about to remove the discount',
  do_reset_changes: 'Сancel changes',
  do_delete: 'Delete',
  not_delete: 'Not delete',
  discounts: 'Discounts',
  do_assign_discount_group_to_store: 'Assign discounts to the store',
  do_add_discount_group_v2: 'Add a set of discounts',
  do_add_discount_group: 'Add set',
  editing_of_discount_group: 'Editing a set of discounts',
  adding_of_discount_group: 'Adding a set of discounts',
  add_image_proposal: 'Add an image',
  image: 'Picture',
  description: 'Description',
  title: 'Heading',
  do_show: 'Show',
  client_show_settings: 'Display settings for clients',
  excluded_cost_tooltip:
    'The amount in the check for which the discount does not apply.\nExample: 10% discount on purchases over RUB 500 (excluded cost RUB 500)\nThe purchase amount is 900 rubles, a 10% discount is valid for 400 rubles and will be 40 rubles. ',
  do_save: 'Save',
  save_changes: 'Save changes',
  applying_order: 'Application procedure',
  adding_of_discount: 'Adding a new discount',
  editing_of_discount: 'Discount editing',
  do_add_discount: 'Add discount',
  applying: 'Application',
  applying_features: 'Application features',
  excluded_cost: 'Excluded cost',
  discount: 'Discount',
  discount_size: 'Amount of discount',
  option_usage_first_order_after_timestamp: 'First purchase after the assigned time limit',
  option_usage_first_order: 'First purchase excluding time',
  option_usage_common: 'General discount',
  option_discount_new_price: 'New price',
  option_discount_amount: 'Discount in rubles',
  option_discount_percent: 'Percentage discount',
  option_max: 'Maximum',
  option_min: 'Minimum',
  option_unknown: 'Not installed',
  sold_items_with_amount: 'Sold, pcs',
  revenue_with_currency: 'Amount, RUR',
  price_with_currency: 'Price, RUR',
  item_name: 'Product Name',
  most_popular_items: 'Popular goods',
  do_edit: 'Edit',
  delivery: 'Delivery',
  credentials: 'Requisites',
  phone: 'Phone number',
  card: 'Map',
  actual_address: 'Actual address',
  status: 'Status',
  outside: 'Outside',
  inside: 'Inside',
  air_temperature: 'Air temperature',
  do_top_up_an_account: 'Top up balance',
  your_account_has_run_out_of_money: 'Your account has run out of money.',
  download_file_format: 'Download format',
  do_download_promo_briskly: 'Download Briskly promo materials',
  do_download_data: 'Download data',
  report_about_unique_clients: 'Statistics on unique daily clients',
  report_about_sales: 'Sales statistics',
  report_about_orders: 'Statistics on orders',
  report_about_items: 'Item report',
  report_about_clients: 'Client report',
  reports: 'Reports',
  personal_account_rules: 'Terms of use of the LC',
  tariff_plans: 'Tariff plans',
  offer_for_partners: 'Offer for partners',
  type: 'Type',
  time_stamp: 'Date',
  to_assign: 'Sign',
  is_touched: 'Viewed',
  is_doesnt_signed: 'Not signed',
  documents: 'Documents',
  is_signet: 'Signed',
  download: 'Download',
  video_not_found: 'Video is not found',
  video_recording_date: 'Video recording date',
  pick_date: 'Select date',
  video_by_order: 'Order video',
  Video: 'Video',
  time_of_ordering: 'Order time',
  temperature_setup_title:
    'Adjust the operating temperature of your micromarket. When the temperature inside the micromarket goes beyond the limits you set, we will send you a notification.',
  temperature_setup: 'Temperature setting',
  payment_status: 'Payment State',
  payment_method: 'Payment method',
  planogram_title: 'Planogram',
  employees_title: 'Staff',
  this_action_cant_canceled: 'This will permanently delete',
  delete_trade_point: 'Delete trade point',
  do_edit_sufix: 'Editing',
  download_table_banner: 'Download desktop banner',
  download_sticker: 'Download the label',
  download_qr_code: 'Download QR Code',
  congratulations_your_tt_is_ready_we_need_check:
    'Congratulations! You have completed your registration! Now we need 1-3 business days to check your details. After that, you will have access to the trade point.',
  fine: 'Excellent',
  congratulations_your_tt_is_ready_to_go: 'Congratulations! Your trade point is ready to go',
  trade_point_registation_complite: 'Registration completed',
  stages_description_payment:
    "The client has not yet connected the card for payment.\n Copy the link and send it to him if you haven't already. ",
  stages_description_schedule: 'For the trade point to work, fill in the schedule fields.',
  stages_description_requisites: 'Status: being checked.\nVerification takes 1-3 business days. ',
  stages_description_planogram:
    'Arrange the products on the shelves of the micromarket as your customers will see them.',
  stages_description_offer: 'You need to sign an offer agreement to use the Briskly account',
  stages_description_briskly_module:
    'All trade points are displayed in the Briskly system only after connecting the Briskly Module.',
  stages_description_exchange: 'Upload your products to the catalog so customers can see them.',
  stages_description_design:
    'Now your trade point in the B-pay application looks standard. You can change the design to stand out',
  stages_description_delivery: 'Choose one of the delivery options',
  last_check: 'Last check',
  lock: 'Lock',
  copy_link: 'Copy link',
  continue_filling: 'Continue filling',
  fill_in_the_required_sections_for_your_tt_start_working:
    'Please fill in the required sections for your trade point to start working',
  left_a_little: 'There is not much',
  module_apply: 'Apply',
  refregerator_with_two_doors: 'Refrigerator with two doors',
  electronic_lock: 'Electronic lock',
  trade_point_branding: 'Micromarket branding',
  refregerator_in_set: 'Fridge included',
  spy_camera: 'Surveillance Camera',
  temperature_sensor: 'Temperature sensors',
  goods_integration: 'Products range integration',
  connect_to_b_pay: 'Connect to the B-Pay app',
  lock_mount: 'Installing email lock',
  big_market_with_all_options: 'Big smart refrigerator "turnkey"',
  popular_format: 'Popular format',
  smart_ref_all_options: 'Smart refrigerator "turnkey"',
  setup_module_in_you_ref: 'Installing the system in your refrigerator',
  to_retry: 'Retry',
  buy_module: 'Buying Briskly Module',
  module_check: 'Check module',
  module_error_title: 'Data not available. This could be due to',
  lock_control: 'Lock control',
  last_time_in_network: 'Last time online',
  air_temp: 'Air temperature',
  lock_open: 'Open',
  lock_close: 'Closed',
  lock_status: 'Lock status',
  not_working: 'Is not working',
  working: 'Working',
  network_status: 'Network status',
  module_data: 'Briskly module data',
  indicate_divice_code: 'Enter the code from the device',
  module_link_to_buy: 'do it now',
  module_to_buy:
    'If you have not yet paid for the Micromarket (refrigerator + Briskly Module) or separately Briskly Module for your refrigerator,',
  module_description:
    'Briskly module is the brain of your micromarket. Upon purchase, you were given a code that must be linked to your account. The code can be viewed',
  briskly_module: 'Briskly Module',
  module_tittle: 'Module',
  valid_select_some_filter: 'Please select at least one filter',
  valid_maximum: 'Maximum number of characters',
  valid_account_number: 'Current account length must be 20 characters',
  valid_bic: 'Select BIC',
  valid_address: 'Select address',
  valid_inn: 'Select ITN/TIN',
  valid_passport_authority_code: 'Invalid department code',
  valid_passport_number: 'Invalid passport number',
  valid_passport_series: 'Invalid series',
  valid_incorrect_phone: 'Invalid phone number',
  valid_incorrect_date: 'Invalid date',
  valid_upload_both_photos: 'Load both images',
  valid_fill_the_field: 'Fill in the field',
  valid_no_more_99_percent: 'Not more than 99%',
  valid_no_less_1_percent: 'Not less than 1%',
  valid_phone_format: 'Phone number in the format +7 (123) 456 78 90',
  valid_mail_format: 'Email in the format: welcome@brskl.io',
  valid_special_symbols_not_allow: 'Special characters are not allowed',
  valid_max_350: 'Maximum 350 characters',
  valid_max_32: 'Maximum 32 characters',
  valid_max_20: 'Maximum 20 symbols',
  valid_max_13: 'Maximum 13 characters',
  valid_max_6: 'Maximum 6 characters',
  valid_max_5: 'Maximum 5 characters',
  valid_max_60: 'Maximum 60 characters',
  valid_min_6: 'At least 6 characters',
  valid_min_1: 'At least 1 character',
  valid_min_3: 'At least 3 characters',
  valid_article_required: 'Article is required',
  valid_required: 'Required',
  checker_subtitle: 'Collect orders from your customers remotely',
  checker_title: 'Use the Check-Er app',
  bpay_subtitle: 'Shop without queues',
  bpay_title: 'Download the B-pay app',
  follow_us_end: 'in social networks',
  follow_us_begin: 'Follow us',
  current_card: 'Current map',
  do_change_payment_method: 'Change the payment method',
  send_request: 'Send request',
  payment_description:
    'Send a request to add a payment method to the person in charge of the store where the cigarette cabinet will be installed.',
  use_suggestions_end: 'for system integration',
  use_suggestions_action: 'tips',
  use_suggestions_begin: 'Take advantage of our',
  something_wrong_try_again: 'Something went wrong. Try again.',
  unit_id: 'OKEI (Unit of Measurement)',
  service_xlsx: 'XLSX',
  service_yml: 'YML',
  service_supermag: 'Supermag',
  service_moy_sklad: 'Moy Sklad',
  service_iiko_biz: 'Aiko Biz',
  service_iiko: 'Aiko',
  service_one_s: '1C',
  refund_awaiting_from_client: 'Customer surcharge expected',
  completed_order: 'Completed order',
  sales: 'Sales',
  unique_clients: 'Unique day clients',
  clients: 'Clients',
  orders: 'Orders',
  sum: 'Amount',
  sum_with_discount: 'Discount amount',
  period_end: 'End of period',
  period_start: 'Beginning of period',
  item_price: 'Price per item',
  income: 'Profit',
  commission: 'Commission',
  item_quantity: 'Number of goods',
  client: 'Client',
  order_number: 'Order number',
  id_local: 'ID',
  year: 'Year',
  quantity: 'Quantity',
  removed: 'Deleted',
  refund_error: 'Refund error',
  refund_awaiting: 'Waiting for refund',
  refund: 'Refund',
  processed: 'Processed',
  paid: 'Paid',
  option_selection_by_number: "Sampling by Client's ID",
  pick_store: 'Pick a store',
  order_status: 'Order status',
  period: 'Period',
  group_by: 'Group by',
  do_download_new_planogram: 'Load a new planogram',
  planogram_list: 'List of planograms',
  planogram_item: 'item',
  going_to_remove_shelf: 'You are about to remove the shelf',
  going_to_remove_all_items: 'You are about to remove all products from the shelf',
  do_add_shelf: 'Add a shelf',
  do_add_items_on_another_shelfs: 'Add items to other shelves',
  shelf_is_overflow: 'This shelf will no longer fit',
  shelf: 'shelf',
  your_micromarket: 'Your micromarket',
  do_add_items_on_shelf: 'Add products to the shelf',
  planogram_photo: 'A picture of the planogram',
  will_be_deleted: 'Will be deleted',
  planogram_block_hint: 'You need to create a catalog',
  planogram: 'Planogram',
  do_send_confirmation_code: 'Send confirmation code',
  do_send_code_again: 'Resend code',
  in_seconds: 'seconds',
  try_again_in: 'If the code does not come, try again through',
  code_from_sms: 'SMS code',
  do_sign_document: 'Sign the document',
  do_download_offer: 'Download offer',
  offer: 'Offer',
  delivery_cost_unknown: 'Delivery is paid by the client',
  delivery_cost_fixed: 'Fix price',
  delivery_cost_discount_fixed: 'Discount on delivery price in rubles',
  delivery_cost_discount_percent: 'Discount on delivery price as a percentage',
  delivery_cost_free: 'Free',
  delivery_warning_modal_body: 'After saving, the previous shipping method will be deleted',
  delivery_warning_modal_title: 'You are about to change the shipping method',
  do_add_rate: 'Add condition',
  delivery_invalid_rates_text:
    'The intervals for order amounts must be different, the difference between them must be no more than 1 ruble.',
  delivery_cost: 'Cost of delivery',
  order_sum: 'Order price',
  delivery_tariffs: 'Delivery rates',
  delivery_pickup: 'Pickup possibility',
  delivery_briskly_price: 'Shipping cost by Briskly partners',
  delivery_briskly_description:
    'Please note that if the shipping cost you set for the client is less than the actual cost, at the end of the month you will receive an invoice to pay for this difference. If your cost is higher, then you will receive money in the amount of this difference.',
  delivery_briskly_tariffs: 'Shipping rates Briskly partners',
  delivery_own_tariffs: 'Self-delivery rates',
  delivery_both: 'Both options',
  delivery_briskly: 'Delivery by partners Briskly',
  delivery_own: 'Own delivery',
  delivery_briskly_radius: 'Delivery radius by Briskly partners',
  delivery_own_radius: 'Self-delivery radius',
  want_to_deliver: 'Would you like to deliver your goods using your own or ours?',
  requisites_missing: 'Absent',
  requisites_bank_correspondent_account: 'Corr. score',
  requisites_recipient_bank_name: 'Beneficiary bank',
  requisites_cheque_hint: 'Now you can see how your trade point check will look like.',
  requisites_bank_account_info: 'Bank account details',
  requisites_okved_code: 'OKVED',
  requisites_ogrn: 'OGRN',
  requisites_okopf_code: 'OKOPF',
  requisites_company_iname: 'Name of the company',
  requisites_org_phone: 'Organization phone number',
  tax_system_simplified_2: 'STS (income-6%)',
  tax_system_simplified_1: 'STS (income-expenses)',
  tax_system_general: 'OSN',
  requisites_tax_system: 'Select taxation system',
  requisites_capital: 'Authorized capital, RUR',
  passport_birth_date: 'Date of Birth',
  passport_birth_place: 'Place of Birth',
  passport_registration_place: 'Registration address (as in the passport)',
  passport_citizenship: 'Citizenship',
  passport_authority_name: 'The name of the department that issued the document',
  passport_authority_code: 'Department code',
  passport_issue_date: 'Date of issue',
  passport_number: 'Number',
  passport_series: 'Series',
  passport_second_page: 'Registration page',
  passport_first_page: 'First page of passport',
  requisites_position: 'Position (as per charter)',
  employment_type_legal: 'Organization',
  employment_type_individual: 'SP',
  requisites_pick_employment_type: 'Select the type of employment',
  requisites_step4: 'Payment information',
  requisites_step3: 'Organization details',
  requisites_step2_legal: 'Personal data of the head',
  requisites_step2: 'Personal data',
  requisites_step1: 'Type of employment',
  requisites_inn: 'TIN or name of the legal entity',
  requisites_name: 'Name',
  requisites_provider_info: 'Supplier data',
  requisites_item: 'Product',
  requisites_items: 'Products',
  requisites_buyer_phone: "Buyer's phone number",
  requisites_non_cash: 'Cashless',
  requisites_cheque: 'Cash register receipt',
  adding_new_requisites: 'Adding new details',
  add_new_requisites: 'Add new details',
  requisites_bic: 'BIС',
  requisites_bank_bic: "Bank's BIC",
  requisites_current_account_number: 'Current account number',
  requisites_account_number: 'Account number',
  requisites_mail: 'Email',
  requisites_phone: 'Phone number',
  requisites_fio: 'Full name',
  requisites_client_info: 'Customer data',
  requisites_company_info: 'Company data',
  pick_requisites: 'Select requisites',
  requisites_single_description:
    'This section contains all the details of your current trade point, and you can also add new ones. This is necessary to send money to the current account and when issuing a check to customers. The proceeds from sales are transferred to the current account once a day.',
  requisites_description:
    'Add the details of your trade point. They are needed to send money to your checking account. Company details will be indicated when the check is issued to customers.',
  requisites_title: 'Requisites',
  to_add_new_employee: 'Add a new employee',
  employees: 'Staff',
  role_checker_access:
    'Accepts orders from customers and collects them on the trading floor for sending to the client',
  role_checker_name: 'Checker',
  role_courier_access:
    'It is possible to open the refrigerator, change goods, take a photo, upload a photo to the courier interface',
  role_courier_name: 'Courier',
  role_administrator_access: 'Access to all the functionality of your personal account',
  role_administrator_name: 'Administrator',
  add_employee: 'Add employee',
  employee_create_modal_role_title: 'Select roles',
  employee_change_modal_role_title: 'Assign a role at this trade point',
  employee_create_modal_title: 'Adding an employee',
  employee_change_modal_title: 'Data change',
  employees_selected: 'Selected',
  delete_employee_modal_title: 'You are about to remove an employee',
  pick_employees: 'Select employees',
  employees_description:
    'On this screen, you add employees of your trade point, select them from the list, or enter new data. Assign roles to them according to their responsibilities. By default, the manager is connected to all roles. More details in the help section.',
  create_employees_title: 'Adding employees',
  templates: 'Templates',
  schedule_to: 'to',
  schedule_from: 'from',
  do_add_break: 'Add break',
  weekend: 'Output',
  permanently: 'Around the clock',
  week_all_day: 'All days',
  week_sun: 'Su',
  week_sat: 'Sa',
  week_fri: 'Fr',
  week_thu: 'Th',
  week_wed: 'We',
  week_tue: 'Tu',
  week_mon: 'Mo',
  do_enter_name: 'Enter the title',
  save_as_template: 'Save as template',
  start_day_after_visiting_courier: 'Start the working day after visiting the courier',
  do_download_from_template: 'Load from templates',
  schedule__description:
    'Set a schedule for your micromarket: at what time it opens for purchases, and at what time it is blocked.',
  work_schedule: 'Work schedule',
  schedule_title: 'Timetable',
  schedule: 'Timetable',
  no_design_to_select: 'No designs saved',
  store_photos: 'Shop photo',
  mock_address: 'Moscow, st. Academician Yangel, 26',
  do_change_preview: 'Change preview',
  big_background: 'Background for large preview in format',
  little_background: 'Background for small preview in format',
  pick_background: 'Choose a background',
  enter_text: 'Enter text',
  preview_for_app: 'In-app store preview',
  your_logo_in_format: 'Your logo in the format',
  your_logo: 'Your logo',
  card_is_missing: 'Not tied',
  design_title: 'Design',
  error_modal_title: 'Unable to activate a trade point',
  profile: 'Personal Area',
  support: 'Support',
  do_open_new_trade_point: 'Add a new point',
  profit: 'Income',
  purchases_quantity: 'Number of purchases',
  finish_registration: 'Complete registration',
  date_input_placeholder: 'dd.mm.yyyy',
  fuel: 'Fuel',
  promo_briskly: 'Briskly promo materials',
  agreement: 'Agreement',
  stage_design_create_new: 'Create a new design',
  stage_design_label_select: 'Select a design',
  stage_design_description:
    'Choose how your trade point will look in the B-Pay app. Or upload a logo. It will be displayed in the product card',
  stage_type_coffee_machine_radius: 'Radius of action\ncoffee machines ',
  stage_type_filling_station_radius: 'Radius of action\nBriskly systems ',
  stage_type_cigarettes_radius: 'The radius from which it will be\nopen the door ',
  stage_type_refrigerator_radius: 'The radius from which it will be\nopen the door ',
  stage_type_store_radius: 'Radius of action\nScan & Go systems ',
  stage_type_label_floor: 'Floor',
  address: 'The address',
  stage_type_label_phone: 'Trade point phone',
  stage_type_description:
    'Select your category: store, cafe or another from the list. Read more about the capabilities of each category here',
  stage_type_subtitle: 'Select the type of trade point',
  stage_type_edit_title: 'Main information',
  stage_type_title: 'Add a trade point',
  extended_types_coffee_machine: 'Coffee machine',
  extended_types_cigarettes: 'Cigarette cabinet',
  extended_types_filling_station: 'Gas station',
  extended_types_production_with_delivery: 'Production with delivery',
  extended_types_cafe: 'Cafe',
  extended_types_store_online: 'Online Store',
  extended_types_refrigerator: 'Micromarket',
  extended_types_store: 'Store',
  ready: 'Done',
  pick_time_zone: 'Select time zone',
  time_zone: 'Timezone',
  among: 'Of',
  shortcut_month: '31 days',
  shortcut_week: '7 days',
  logout: 'Log out',
  main_page: 'Home',
  you: 'You',
  today: 'Today',
  yesterday: 'Yesterday',
  do_send: 'Send message',
  go_to_page: 'Go to the page',
  pack_min: 'pack',
  active_status_no: 'Inactive',
  active_status_yes: 'Active',
  do_create: 'Create',
  price_with_currency2: 'Price, RUR *',
  go_to: 'Go to',
  here: 'Here',
  price: 'Price',
  all: 'All',
  step: 'Step',
  search: 'Search',
  pick_or_input: 'Select an option or keep typing',
  total: 'Total',
  do_download_file: 'Upload file',
  format: 'Format',
  cancel: 'Cancel',
  do_cancel: 'Cancel',
  before: 'Before',
  from: 'From',
  do_skip_and_end_registration: 'Skip and complete registration',
  do_end_registration: 'Complete registration',
  do_skip_and_finish: 'Skip and complete',
  no: 'No',
  yes: 'Yes',
  next_step: 'Next step',
  role: 'Role',
  trade_point_count: 'Number of trade points',
  trade_point: 'Trade point',
  middle_name: 'Patronymic',
  last_name: 'Surname',
  do_check: 'Verify',
  do_repeat: 'Repeat',
  required_fields: 'Required columns',
  first_name: 'Name',
  do_change: 'Edit',
  thousand_short: 'k',
  do_select: 'Select',
  million_short: 'million',
  can_come_back_later: 'You can come back to this step later.',
  km: 'km',
  metr: 'm',
  skip: 'Skip',
  save: 'Save',
  averageCheck: 'Average Check',
  revenue: 'Revenue',
  continue: 'Proceed',
  name: 'Name',
  by_list: 'The list',
  results: 'results',
  do_search: 'Search',
  do_close: 'Close',
  do_open: 'Open',
  id: 'ID',
  cost: 'The cost',
  number: 'Number',
  extended_types_washer_fluid: 'Washer fluid',
  trade_point_list: 'List of points of sale',
  select_dates: 'Select dates',
  doesnt_select: 'Not selected',
  bind: 'Bind',
  do_add_trade_point: 'Add trade point',
  on_map: 'On the map',
  trade_points: 'Trade points',
  referalProgram: 'Referral program',
  homeReferalProgramNote:
    'Use it yourself, invite a friend. As soon as he is registered in the system, you\n  get cashback from Briskly in the amount of % of ',
  homeSubtitleWithStat: 'This screen displays statistics on sales in your points of sale',
  homeSubtitle: 'This screen will soon show statistics on sales in your trade points',
  homeTitle: 'Main screen',
  items_sufix: '{_} product | {_} product | {_} goods',
  innLabel1: '{label} or organization name',
  innLabel2: '{label} or last name',
  integration_with_system_were_succeed: 'Integration with {system} was successful',
  more_then: 'More {num}',
  before_num: 'Until {num}',
  older_then: 'Older than {num}',
  before_days: '{_} day | Until {_} day | Until {_} days',

  stock_balances: 'Stock balances',
  do_add_items_list: 'Adding items as a list',
  accounting_for_stock_balances: 'Accounting for stock balances',
  rebase_catalogs: 'Removing a catalog > Editing trade points',
  clarification_in_items_side_bar:
    '* If the products in your file are already divided into categories, just select the Catalog and upload the file, the Categories will be created automatically.',
  do_download_xlsx: 'Upload .xlsx file',
  stock_accounting_balances_info:
    'To obtain up-to-date information on the quantity and value of goods in your retail trade points, use the function “Accounting for stock balances”. ',
  or: 'Or',
  category_info_tip:
    'Use this function to fill a new category with products faster, automatically.',
  good_must_be_in_catalog_or_category: 'The product must be tied to a catalog or category.',
  items_add_side_bar_info:
    'If you need to create a new catalog or category in the current catalog, go to the section',
  do_delete_catalog: 'Delete catalog',
  delete_catalog: 'Delete catalog',
  trade_point_edit: 'Editing points of sale',
  rebase_catalog_1: 'To remove the catalog “Name of the catalog”, you need',
  rebase_catalog_2: 'bind new catalogs to all trade points',
  rebase_catalog_3: 'in the table below.',
  cant_start_from_dot: "Can't start from dot",
  aprove_category_delete: 'You are about to delete a category',
  delete_category_warn: 'Deleting a category will delete all products in this category',
  delete_catalog_confirm_text_5:
    'You are about to delete an empty catalog with no categories or products',
  delete_catalog_confirm_text_6: 'You are about to delete a catalog that contains products',
  trade_point_type_name: 'Trade point type',
  select_new_catalog: 'Select a new catalog',
  barcoders_prefix_for_weight_good: 'Weight Item Barcode Prefixes',
  barcoders_prefix_info:
    'By the value of this prefix, the system recognizes the item by weight, highlighting the digital code of the item and its weight in grams. Available prefixes are from 20 to 29. If no prefix is specified, the goods will be treated as piece goods. ',
  age_limit_2: 'years',
  age_limit_1: 'Age limit,',

  requisites_personal_data_descr_part_1:
    'We ask you to fill in this information in order to be able to transfer the proceeds from the sales of your trade point to you on a daily basis. To do this, you must provide information similar to what you provide when enabling acquiring.',
  requires_personal_data_descr_part_2:
    'your data is requested at the request of the Central Bank and the acquiring Bank. They will be stored in encrypted form. Also, Briskly is a member of the register of personal data operators Роскомнадзора.',
  stages_description_employees: '',
  stages_description_type: '',
  stages_description_fuel: '',
  add_good: 'Add a product',
  external_id: 'External id',
  do_save_and_go_back: 'Save and return to list',
  print_shelf_life: 'Print expiration date',
  succes_saved: 'Successfully saved',
  quantity_cut: 'Qty',
  additional_data_about_good: 'Additional product data',
  doest_not_req_field: 'optional fields',
  do_upload_photo: 'Upload photo',
  upload_good_list: 'Upload products list',
  show_filters: 'Show filters',
  hide_filters: 'Hide filters',
  available_table_filters: 'table filters',
  deletion: 'Delete',
  item_warning_info: ' The product must be linked to any catalog and category.',
  item_create_info:
    'if you need to create a new catalog or category in the current catalog, go to ',
  aprove_catalog_delete: 'You are about to delete a catalog',
  delete_catalog_text_confirm_3:
    'deleting a Catalog will delete all Categories and Products within it.',
  delete_catalog_text_confirm_4:
    'For removal of catlogo first need to bind the new catalog to all the trade points instead of deleted.',
  go_to_edit_store: 'go to edit trade points',
  choose_trade_point: 'Select point',
  do_catalog_edit: 'Edit catalog',
  do_category_edit: 'Edit category',
  category_warn_text_warn: 'Category needs to be tied to any catalog.',
  category_warn_text: 'If you need to create a new catalog, click the link',
  category_warn_text_link: 'Create a new catalog.',

  catalog_rebase_info: 'no longer linked to any of the trade points, you can now',

  requisites_personal_data_descr_part_2:
    'Your data is requested by the Central Bank and the acquiring Bank. They will be stored in encrypted form. Also, Briskly is a member of the register of personal data operators of Roskomnadzor.',
  item_photo_note: '(you can upload your product photo or leave the default photo shown below)',
  store_doesnt_have_catalog:
    'the trade point {name} does not have a link to the catalog go to editing ?',
  success: 'Success',

  uploaded_image_min_width: 'Minimum width of the image',
  uploaded_image_max_width: 'Maximal width of the image',
  uploaded_image_min_height: 'Minimum height of the image',
  uploaded_image_max_height: 'Maximal height of the image',
  allowed_types: 'Allowed types',
  max_file_size: 'Maximal file size',
  standard_no_suf: 'Standard',
  orders_and_video: 'Orders and videos',
  payment_type_undefined: 'Not determined',

  service_csv: 'csv',
  valid_max_250: 'Maximum 250 characters',
  ok: 'Ok',
  valid_max_11: 'Maximum 11 characters',
  time_of_creation: 'Creation time',
  id_trade_point: 'Trade point ID ',
  item_id: 'Item ID',
  good_barcode: 'Item barcode',
  price_with_currency_inject: 'Price, {currency}',
  sum_with_currency_inject: 'Amount, {currency}',
  total_amount_price_with_currency_inject: 'Total price, {currency}',
  total_amount_sum_with_currency_inject: 'Total amount, {currency}',
  create_refund: 'Create refund',
  created_at: 'Creation time',
  customer_id: 'Client',
  store: 'Store',
  find_by_order_number: 'Search by order number',
  option_discount_percent_cut: 'Discount, %',
  orders_not_exist: 'There are no orders in your trading points yet.',

  //Новые ключи

  postal_code: 'Postcode',
  module_description_temp:
    'Briskly module  this is the brain of your micromarket. Upon purchase, you were given a code that must be linked to your account.',
  value_must_be_more_than_zero: 'The value must be greater than',
  valid_min_13: 'At least 13 characters',
  dosesnt_correct_number: 'Invalid number',
  success_deleted: 'Successfully remotely',
  do_return_back: 'Come back',
  abort_procedure: 'Abort procedure',
  abort: 'Abort',
  maybe_data_not_saved: 'Changes made may not be saved',
  empty_catalog: 'Catalog is empty',
  fileupload_page_info:
    '* If the products in your file are already divided into categories, just select the Catalog and upload the file, the Categories will be created automatically. ',
  do_download_xlsx_example: 'Download example .xlsx',
  payment_type: 'Payment type',
  app: 'Application',
  terminal: 'Terminal',
  add_refund: 'Add refund',
  view_refund: 'View refunds',
  about_order: 'About order',
  refund_title: 'Making a refund',
  order_with_id_inject: 'Order ID {orderId}',
  refund_with_order_id_inject: 'Refunds: Order ID {orderId}',
  copy_to_clipboard: 'Copy to clipboard',
  copied_to_clipboard: 'Copied to clipboard',
  amout_goods_to_refund: 'Total items to be refunded',
  technical_support: 'Technical support',
  amount_goods_payment: 'Refund amount',
  barcode_can_includes_only_digits: 'Numbers only',

  bbo_welcome: 'Welcome to<br>Briskly Business Backoffice!',
  complete_registration: 'To start using your personal account, please register.',
  your_company_number: 'Company registration number',
  your_company_inn: 'INN',
  contact_name: 'Contact name',
  accept_terms: 'By clicking "Submit", you accept the terms of the',
  user_agreement_terms: 'license agreement',
  register: 'Submit',
  exit: 'Exit',
  license_agreement: 'License agreement with the user of the website',
  your_company_country: 'Company based country',

  valid_minimum: 'Minimum number of characters',
  onboarding_virtual_terminal: 'First virtual terminal!',
  bank_name: 'Bank name',
  company_name: 'Сompany name',
  party_filling: 'Filling in Bank details',

  virtual_terminal: 'Virtual terminal',
  select_virtual_terminal: 'Select virtual terminal',
  virtual_terminal_description:
    'Fill in the details of your virtual terminal, they are necessary for crediting funds to your account, as well as for generating a receipt for the client.',
  add_new_virtual_terminal: 'Add new virtual terminal',

  coords_lng: 'Longitude',
  coords_lat: 'Latitude',

  delete_goods_modal_info: 'and {stringsQuantity}... more products',
  client_show_settings_2: 'Display settings for the Mobile app',
  no_changes_to_update: 'No changes to save',
  for_go_to_exchange_need_say_store: 'Choose a trade point',
  link_to_showcase: 'Link to product showcase',
  catalog_successfully_added: 'Catalog added successfully',
  refund_has_been_created: 'Refund created',
  no_trans_action: 'No transactions',
  no_refund_text_info: 'There have been no refunds for this order.',
  date_should_not_earlier: 'The moderation may not be complete before this time',
  date_should_not_later: 'Date must be no later than',
  cant_play_video: "Can't play video",
  check_refund_possibility: 'To quickly search for a product, enter its id, barcode or name',

  refund_helper_text: 'To quickly search for a product, enter its id, barcode or name',
  no_one_good_selected: 'No item selected for refund.',
  errors_occurred_while_creating_the_refund: 'Errors occurred while creating the refund:',
  refund_by_id_goes_successed: 'Refund for order id {refundId} was successful',
  refund_by_id_goes_accept: 'Confirm refund for order id {refundId}',
  refund_technical_support_notice: 'To make a refund, please contact technical support.',
  refund_money_back_notice:
    "The money will be credited to the client's card within 2 days from the date of the refund.",
  refund_ready_title: 'Order refund',
  refund_check_success_info: 'Refund can be successfully created',
  refund_check_success_info_with_order_id: 'Refund order ID {orderId} created successfully',
  approve_refund: 'Confirm refund',
  video_not_available: 'Video not available',
  video_tooltip_error:
    'The video file is damaged or temporarily unavailable. Please, contact support',
  do_upload: 'Download',
  amout_for_refund: 'Total items to refund',
  generate_automatic: 'Generate automatically',
  does_not_correct_control_sum: 'Invalid checksum',
  do_create_refund: 'Create refund',

  active_status: 'Awating payment',
  paid_status: 'Paid',
  refund_status: 'Refunded',
  refund_awaiting_status: 'Awaiting refund',
  refund_error_status: 'Refund error',
  refund_partial_status: 'Partially refunded',
  removed_status: 'Deleted',

  online_store_layouts: 'Layouts for printing on micromarket',
  promo_stickers: 'Stickers',
  promo_side_stickers: 'Side Stickers',
  promo_on_glas: 'On glass',
  promo_on_advertising_block: 'Per ad unit',

  promo_without_ad_block: '(without ad block)',
  promo_with_ad_block: '(with ad block)',
  attention: 'Attention',
  requisites_skip_warning:
    'If you skip the step of selecting / adding details, your trade point will work in test mode.',
  select_full_party_description:
    'You can choose previously used details or add new ones simply by filling out the form!',
  select_partial_party_description: 'Connect your virtual terminal to your Personal Account!',
  pick_terminal: 'Select terminal',
  add_new_full_party: 'Add new',
  add_new_partial_party: 'Add new',
  swift_bic: 'SWIFT / BIC',
  secure3d: '3DS',
  non_secure3d: 'non 3DS',
  full_party_choosing: 'Selecting details',
  partial_party_choosing: 'Selecting terminal',
  requisites_and_terminals: 'Legal entities',
  terminals: 'Virtual terminals',
  full_party_status_active: 'Active',
  full_party_status_pending: 'Checking',
  full_party_status_replacement: 'Replacing',
  full_party_edit: 'Editing requisites',
  partial_party_edit: 'Editing a virtual terminal',
  adding_new_terminal: 'Adding a new terminal',
  add_full_party: 'Add requisites',
  add_partial_party: 'Add virtual terminal',
  full_party_edit_warning:
    'After the change, the details will be checked for some time. New details will be saved for trade points immediately after the check.',
  partial_party_edit_warning:
    'After the change, the terminal will be checked for some time. The new terminal will be saved for trade points immediately after the check.',
  kpp: 'KPP',
  currency: 'Currency',

  BYN: 'Belarusian ruble(BYN)',
  USD: 'U.S. dollar(USD)',
  EUR: 'Euro(EUR)',
  KZT: 'Kazakh tenge(KZT)',
  CAD: 'Canadian dollar(CAD)',
  CNY: 'CNY(CNY)',
  RUB: 'Russian ruble(RUB)',
  TRY: 'Turkish lira(TRY)',
  UAH: 'Ukrainian hryvnia(UAH)',
  GBP: 'GBP(GBP)',
  CHF: 'Swiss frank(CHF)',

  serial_number: 'Serial number',
  coffee_reeboot: 'Reboot',
  coffee_reeboot_coffee_machine: 'Restart the coffee machine',
  coffee_reeboot_text: 'Restarting the device helps to solve a number of problems.',
  defer_service: 'Postpone mandatory service',
  coffee_postpone: 'Postpone',
  coffee_service_maintenance_text:
    'The service condition will be postponed until the next activation.',
  block_drinks: 'Block drinks preparation',
  coffee_block_text:
    'The coffee machine will remain active, but the preparation of drinks will not be available to customers',
  block_drink_change: 'Block change of beverage parameters',
  coffee_props_block_text:
    'Available at the time of preparation. Once activated, customers will not be able to add changes to the standard drink.',
  block_menu_access: 'Block access to custom menu',
  coffee_menu_text: 'Customers will not be able to use the menu on the coffee machine.',
  block_drinks_when_offline:
    'Block the preparation of drinks if there is  n no connection to the server for more than 48 hours',
  coffee_reloading: 'Rebooting ...',
  search_by_items: 'Search by products',
  coffee_item_photo: 'Product photo',
  coffee_item_photo_info:
    '(you can upload your own product photo or leave the default photo shown below)',
  coffee_item_type: 'Beverage type',
  item_recept: 'Product recipe',
  value_ml: 'ml',
  cup_with_units_injection: 'Glass {cupsize} ml',
  drink_with_units_injection: 'Drink {Drinksize} ml',
  coffee_foam: 'Milk foam',
  coffee_milk: 'Milk',
  coffee_water: 'Water',
  coffee_coffee_size: 'Coffee',
  return_values_to_default: 'Restore default values',
  price_must_be_more_then_inject: 'The price should be higher {price}',
  coffee_item_photo_info__temp:
    "You can leave the default photo or choose from the available ones by clicking the 'select' button",
  block_drink_changes: 'Block change of beverage parameters',

  file_name_uploaded: 'File {fileName} uploaded',
  columns: 'Columns',
  file_upload_rules: 'File requirements for product upload:',
  file_upload_rules_1: 'Format — .xlsx;',
  file_upload_rules_2: 'The file must not contain merged cells.',
  file_upload_rules_3: 'Field names should be no more than one line long.',
  file_upload_rules_4:
    "In case of errors in the fields 'Units of measurement' and 'Quantity ...' — '1 piece' will be automatically entered.",
  are_mandatory: 'are mandatory',
  file_upload_page_info:
    'To load the catalog correctly, match our field names with how they are named for you.',
  edit_file_and_try_again: 'Correct mistakes and try again',
  do_stop_now: 'Finish now',
  no_save_upload_remember:
    "By clicking the 'Finish now' button, you confirm ... the incorrect display of data about your products.",
  exchange_drpops_with_exception: 'Errors occurred during integration',
  good_must_be_in_catalog_or_category_rewrite: 'The product must be linked to a catalog.',
  fileupload_page_info_rewrite:
    '* If the products in your file are already divided into categories, just select the Catalog and upload the file, the categories will be created automatically.',
  loading: 'Loading...',
  show_detail_info: 'Show detailed information',
  file_upload_success_info:
    'By clicking “Add”, you confirm the loading of the products into the corresponding Catalog and Categories.',
  be_created: 'Were created',
  selected_catalog: 'Catalog selected',
  file_uploaded: 'File upload',
  was_successful: 'was successful',
  and: 'and',
  valid_min: 'Minimum {min}',
  value_mm: 'mm',

  unit_796: 'Piece (pc)',
  unit_657: 'Product',
  unit_111: 'Volume (ml)',
  unit_112: 'Liter',
  unit_163: "'Gram (g)",
  unit_625: 'Sheet',
  unit_704: 'Set',
  unit_715: 'Couple',
  unit_166: 'Kilogram(kg)',
  unit_736: 'Roll',
  unit_778: 'Pack',
  item_from_upload_info: 'To quickly add products to the catalog, use the function',
  promo_briskly_link: 'Promo',
  serial_number: 'Serial number',
  coffee_reeboot: 'Reboot',
  coffee_reeboot_coffee_machine: 'Restart the coffee machine',
  coffee_reeboot_text: 'Restarting the device helps to solve a number of problems.',
  defer_service: 'Postpone mandatory service',
  coffee_postpone: 'Postpone',
  coffee_service_maintenance_text:
    'The service condition will be postponed until the next activation.',
  block_drinks: 'Block drinks preparation',
  coffee_block_text:
    'The coffee machine will remain active, but the preparation of drinks will not be available to customers',
  block_drink_change: 'Block change of beverage parameters',
  coffee_props_block_text:
    'Available at the time of preparation. Once activated, customers will not be able to add changes to the standard drink.',
  block_menu_access: 'Block access to custom menu',
  coffee_menu_text: 'Customers will not be able to use the menu on the coffee machine.',
  block_drinks_when_offline:
    'Block the preparation of drinks if there is  n no connection to the server for more than 48 hours',
  coffee_reloading: 'Rebooting ...',
  search_by_items: 'Search by products',
  coffee_item_photo: 'Product photo',
  coffee_item_photo_info:
    '(you can upload your own product photo or leave the default photo shown below)',
  coffee_item_type: 'Beverage type',
  item_recept: 'Product recipe',
  value_ml: 'ml',
  cup_with_units_injection: 'Glass {cupsize} ml',
  drink_with_units_injection: 'Drink {Drinksize} ml',
  coffee_foam: 'Milk foam',
  coffee_milk: 'Milk',
  coffee_water: 'Water',
  coffee_coffee_size: 'Coffee',
  return_values_to_default: 'Restore default values',
  price_must_be_more_then_inject: 'The price should be higher {price}',
  coffee_item_photo_info__temp:
    "You can leave the default photo or choose from the available ones by clicking the 'select' button",
  block_drink_changes: 'Block change of beverage parameters',
  store_name_placeholder: 'Trade point name',
  heating: 'heating',
};
