export const TYPES = {
  CAFE: 'cafe',
  CIGARETTES: 'cigarettes',
  DELIVERY: 'delivery',
  GAS_STATION: 'gas_station',
  MICROMARKET: 'micromarket',
  SHOP: 'shop',
  PRODUCTION_WITH_DELIVERY: 'production_with_delivery',
};

export const ADDITIONAL_TYPES = {
  REFRIGERATOR: 'refrigerator',
};

export const EXTENDED_TYPES = {
  CAFE: 'cafe',
  CIGARETTES: 'cigarettes',
  FILLING_STATION: 'filling_station',
  REFRIGERATOR: 'refrigerator',
  STORE: 'store',
  STORE_ONLINE: 'store_online',
  PRODUCTION_WITH_DELIVERY: 'production_with_delivery',
  WASHER_FLUID: 'washer_fluid',
  COFFEE_MACHINE: 'coffee_machine',
};

export const TYPES_MAP = {
  CAFE: TYPES.CAFE,
  CIGARETTES: TYPES.CIGARETTES,
  FILLING_STATION: TYPES.GAS_STATION,
  REFRIGERATOR: TYPES.MICROMARKET,
  STORE: TYPES.SHOP,
  STORE_ONLINE: TYPES.DELIVERY,
  PRODUCTION_WITH_DELIVERY: TYPES.PRODUCTION_WITH_DELIVERY,
};

export const getStoreGroupType = (extendedType) => TYPES_MAP[extendedType] || '';
