export default [
  {
    path: 'employees',
    name: 'employees$main',
    component: () => import('@/apps/employees/views/Employees/Employees'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: 'main',
        },
        {
          label: 'employees',
          name: '',
        },
      ],
    },
  },
];
