import { companyAPI } from '@/core/api/company';
import { bus } from 'shared/core';

const initialState = () => ({});

const state = initialState();

const getters = {
  canSkipStage: () => false,
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  completeStage: async ({ dispatch, rootGetters }, { phone }) => {
    try {
      const store = rootGetters['trade-point$create/store'];
      if (!store) return false;

      const isSuccess = await dispatch('getCardLink', { phone, storeId: store.id });

      return isSuccess;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  getCardLink: async (_, { phone, storeId }) => {
    try {
      const result = await companyAPI.getCardLink({ phone, id: storeId });

      return !!result.message;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
