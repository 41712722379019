import { uniqBy, unionBy } from 'lodash';
export class InfinityList {
  static POSITIONS = {
    START: 'start',
    END: 'end',
  };

  constructor(loadList, opts = {}) {
    this.loadList = loadList;

    this._list = {
      items: [],
      meta: {
        page: null,
        limit: opts.limit || 20,
      },
    };

    this.loading = false;
  }

  getList() {
    return this._list.items;
  }

  clearList() {
    this._list = { ...this._list, items: [] };
  }

  getMeta() {
    return this._list.meta;
  }

  isEmpty() {
    return !this.getList().length;
  }

  addItems(items, position = InfinityList.POSITIONS.END) {
    const addMap = {
      [InfinityList.POSITIONS.START]: () => unionBy(items, this.getList(), 'id'),
      [InfinityList.POSITIONS.END]: () => unionBy(this.getList(), items, 'id'),
    };

    const unionItems = addMap[position] || this.getList;
    const newItems = unionItems();
    this._list = { ...this._list, items: newItems };
  }

  removeItem(id) {
    const newItems = this._list.items.filter((item) => item.id !== id);
    this._list = { ...this._list, items: newItems };
  }

  replaceItem(id, newItem) {
    const newItems = this._list.items.map((item) => (item.id === id ? newItem : item));

    this._list = { ...this._list, items: newItems };
  }

  async fetch(opts = {}) {
    if (this.loading) return;
    this.loading = true;

    const { page = 1, merge = false } = opts;
    const { limit } = this._list.meta;

    const list = await this.loadList({
      ...opts,
      page,
      limit,
    }).finally(() => (this.loading = false));

    const { items, meta } = list;
    const newItems = merge ? uniqBy([...this._list.items, ...items], 'id') : items;

    this._list = { meta, items: newItems };

    return true;
  }

  async loadMore(opts = {}) {
    const { page, total_pages } = this._list.meta;
    if (page + 1 > total_pages) return false;

    await this.fetch({
      ...opts,
      page: page + 1,
      merge: true,
    });

    return true;
  }

  async search(q, opts = {}) {
    const { items } = await this.loadList({
      ...opts,
      q,
    });

    return items;
  }
}
