import { companyAPI } from '@/core/api/company';
import { hasChanges } from '@/apps/trade-point/utils/changes';

const initialState = () => ({
  code: '',
  status: null,
  error: '',
  entity: null,
  lock: false,
  loading: false,
  boxStatus: {},
});

const state = initialState();

const getters = {
  code: (state) => state.code,
  status: (state) => state.status,
  error: (state) => state.error,
  entity: (state) => state.entity,
  lock: (state) => state.lock,
  loading: (state) => state.loading,
  canSkipStage: (state) => state.status !== 0,
  boxStatus: (state) => state.boxStatus,
  hasUnsavedChanges: (state) => hasChanges(state, initialState()),
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  completeStage: async () => {
    return Promise.resolve(true);
  },

  activate: async ({ commit, getters, rootGetters }, opts = {}) => {
    if (getters.loading) return;

    const { storeId, withReset } = opts;
    const { id } = storeId ? { id: storeId } : rootGetters['trade-point$create/store'];
    const { code } = getters;
    const payload = { store_id: id, bind_key: code };
    const errorOptions = { passive: true };

    try {
      commit('_setLoading', true);
      commit('_resetError');

      if (withReset) {
        try {
          await companyAPI.resetStoreBox({ id: payload.id, errorOptions });
        } catch (error) {
          console.error(error);
        }
      }

      await companyAPI.deviceBind({ ...payload, errorOptions });

      commit('_setStatus', 0);
      commit('_setEntity', true);
    } catch (error) {
      commit('_setError', error);
      commit('_setStatus', 1);
    } finally {
      commit('_setLoading', false);
    }
  },

  setBoxStatus: async ({ commit, rootGetters, getters }, opts = {}) => {
    if (getters.loading) return;

    const { storeId } = opts;
    const { id } = storeId ? { id: storeId } : rootGetters['trade-point$create/store'];
    const errorOptions = { passive: true };

    try {
      commit('_setLoading', true);
      commit('_resetError');

      const data = await companyAPI.getStoreBoxState({ id, errorOptions });

      commit('_setBoxStatus', data);
      commit('_setStatus', 0);
      commit('_setEntity', true);
    } catch (error) {
      commit('_setError', error);
      commit('_setStatus', 1);
    } finally {
      commit('_setLoading', false);
    }
  },

  setCode: ({ commit }, value) => {
    commit('_setCode', value);
    commit('_resetError');
  },

  setStatus: ({ commit }, value) => {
    commit('_setStatus', value);
  },

  setError: ({ commit }, value) => {
    commit('_setError', value);
  },

  setEntity: ({ commit }, value) => {
    commit('_setEntity', value);
  },

  setLock: async ({ commit, rootGetters, getters }, opts = {}) => {
    if (getters.loading) return;

    const { value, storeId } = opts;
    const { id } = storeId ? { id: storeId } : rootGetters['trade-point$create/store'];
    const errorOptions = { passive: true };

    try {
      commit('_setLoading', true);
      commit('_resetError');

      if (value) {
        await companyAPI.openStoreBox({ id, errorOptions });
        commit('_setLock', value);
      }
      await companyAPI.closeStoreBox({ id, errorOptions });
      commit('_setLock', value);
    } catch (error) {
      commit('_setError', error);
    } finally {
      commit('_setLoading', false);
    }
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _resetError: (state) => {
    if (state.error) state.error = '';

    if (state.entity) state.status = 0;
    else if (state.status === 1) state.status = null;
  },

  _setCode: (state, value) => {
    state.code = value;
  },

  _setStatus: (state, value) => {
    state.status = value;
  },

  _setError: (state, value) => {
    state.error = value;
  },

  _setEntity: (state, value) => {
    state.entity = value;
  },

  _setLock: (state, value) => {
    state.lock = value;
  },

  _setLoading: (state, value) => {
    state.loading = value;
  },
  _setBoxStatus: (state, value) => {
    state.boxStatus = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
