import { PARTY_TYPES } from '@/core/data/partyTypes';

export default [
  {
    path: '',
    component: () => import('@/apps/requisites/views/Requisites/Requisites'),
    children: [
      {
        path: 'requisites',
        name: 'requisites$main',
        component: () => import('@/apps/requisites/views/Requisites/Entry/Entry'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'requiseites_and_virtual',
              name: 'requisites$main',
            },
          ],
        },
      },
      {
        path: 'requisites/create',
        component: () => import('@/apps/requisites/views/Requisites/Create/Create'),
        children: [
          {
            path: 'party',
            meta: { type: PARTY_TYPES.FULL },
            name: 'requisites$create-requisites',
            component: () => import('@/core/components/RequisitesSteps/RequisitesSteps'),
          },
          {
            path: 'terminal',
            meta: { type: PARTY_TYPES.PARTIAL },
            name: 'requisites$create-terminal',
            component: () => import('@/core/components/Terminal/Terminal'),
          },
        ],
      },
    ],
  },
];
