import 'animate.css';
import 'normalize.css';
import '@/core/styles.scss';
import 'video.js/dist/video-js.css';
import 'regenerator-runtime';
import '@/core/scripts/mapbox';
import '@/core/scripts/error-handler';
import '@briskly/date-time-picker/dist/date-time-picker.css';

import Vue from 'vue';
import Vuex from 'vuex';
import { api, bus, Channel, utils } from 'shared/core';
import { auth, personal } from 'shared/modules';
import { enableMapSet } from 'immer';
import App from '@/core/App';
import router from '@/core/router';
import store from '@/core/store';
import VueProgressBar from 'vue-progressbar';
import VueI18n from 'vue-i18n';
import { getI18n, translateLocales } from '@/core/i18n';
import VTooltip from 'v-tooltip';
import VueVideoPlayer from 'vue-video-player';
import VueYandexMetrika from 'vue-yandex-metrika';
import { MonthPicker } from 'vue-month-picker';
import { checkVersion } from '@/core/scripts/version-checker';
import { checkTokens } from '@/core/scripts/token-checker';

checkVersion()
  .then(() => {
    api.setEndpoint('business/company');
    checkTokens();
  })
  .catch(() => {})
  .finally(() => {
    Vue.config.productionTip = false;
    Vue.prototype.$api = api;
    Vue.prototype.$bus = bus;

    Vue.use(Vuex);
    Vue.use(VueI18n);
    Vue.use(VueVideoPlayer);
    Vue.use(MonthPicker);

    Vue.use(VueProgressBar, {
      thickness: '3px',
      color: 'rgb(57, 229, 105)',
      failedColor: 'rgb(57, 229, 105)',
      autoFinish: false,
    });

    Vue.use(VTooltip);

    enableMapSet();

    utils.getEvents().forEach(({ value }) => value.init(store));
    utils.getChannels().forEach(({ value }) => new Channel(value));

    personal.connect();

    // TODO: change selectedCountry to something from API or to something that related to current locales
    auth.connect({
      store,
      router,
      opts: { version: 2 },
    });

    process.env.BRANCH === 'master' &&
      Vue.use(VueYandexMetrika, {
        id: 71742211,
        router,
        env: process.env.NODE_ENV,
        options: {
          clickmap: true,
          webvisor: true,
        },
      });

    new Vue({
      router,
      store,
      i18n: getI18n(),
      render: (h) => h(App),
    }).$mount('#app');
  });
