export default [
  {
    path: 'trade-point/create',
    redirect: { name: 'trade-point$create' },
    component: () => import('@/apps/trade-point/views/Create/Create'),
    children: [
      {
        path: '',
        name: 'trade-point$create',
        component: () => import('@/apps/trade-point/views/Create/Entry/Entry'),
        meta: { stage: 'type' },
      },
      {
        path: 'stages',
        component: () => import('@/apps/trade-point/views/Create/Stages/Stages'),
        children: [
          {
            path: '',
            component: () => import('@/apps/trade-point/views/Create/Stages/Design/Entry'),
            meta: { stage: 'design' },
            children: [
              {
                path: 'design',
                name: 'trade-point$stage-design',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/Design/Select/Select'),
              },

              {
                path: 'design/create',
                name: 'trade-point$stage-design-create',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/Design/Create/Create'),
              },
            ],
          },

          {
            path: 'employees',
            name: 'trade-point$stage-employees',
            component: () => import('@/apps/trade-point/views/Create/Stages/Employees/Employees'),
            meta: { stage: 'employees' },
          },

          {
            path: 'schedule',
            name: 'trade-point$stage-schedule',
            component: () => import('@/apps/trade-point/views/Create/Stages/Schedule/Schedule'),
            meta: { stage: 'schedule' },
          },

          {
            path: 'exchange',
            component: () => import('@/apps/trade-point/views/Create/Stages/Exchange/Exchange'),
            meta: { stage: 'exchange' },
            children: [
              {
                path: '',
                name: 'trade-point$stage-exchange',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/Exchange/Main/Entry/Entry'),
              },

              {
                path: 'accounting',
                name: 'trade-point$stage-exchange-accounting',
                component: () =>
                  import(
                    '@/apps/trade-point/views/Create/Stages/Exchange/Main/Accounting/Accounting'
                  ),
              },

              {
                path: 'file',
                name: 'trade-point$stage-exchange-file',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/Exchange/Main/File/File'),
              },

              {
                path: 'catalog',
                name: 'trade-point$stage-exchange-existing',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/Exchange/Main/Existing/Existing'),
              },

              {
                path: 'goods',
                name: 'trade-point$stage-exchange-goods',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/Exchange/Goods/Entry/Entry'),
              },

              {
                path: 'goods/items',
                name: 'trade-point$stage-exchange-goods-items',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/Exchange/Goods/Items/Items'),
              },

              {
                path: 'goods/items/item',
                name: 'trade-point$stage-exchange-goods-item',
                component: () => import('@/apps/items/views/Item/Item'),
                meta: {
                  createStore: true,
                },
              },
            ],
          },
          {
            path: 'requisites',
            component: () => import('@/apps/trade-point/views/Create/Stages/Requisites/Requisites'),
            meta: { stage: 'requisites' },
            children: [
              {
                path: '',
                name: 'trade-point$stage-requisites',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/Requisites/Entry/Entry'),
              },
              {
                path: 'steps',
                name: 'trade-point$stage-requisites-steps',
                component: () => import('@/core/components/RequisitesSteps/RequisitesSteps'),
              },
              {
                path: 'terminal',
                name: 'trade-point$stage-requisites-terminal',
                component: () => import('@/core/components/Terminal/Terminal'),
              },
            ],
          },
          {
            path: 'briskly-module',
            component: () =>
              import('@/apps/trade-point/views/Create/Stages/BrisklyModule/BrisklyModule'),
            meta: { stage: 'brisklyModule' },
            children: [
              {
                path: '',
                name: 'trade-point$stage-briskly-module',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/BrisklyModule/Entry/Entry'),
              },
              {
                path: 'buy',
                name: 'trade-point$stage-briskly-module-buy',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/BrisklyModule/Buy/Buy'),
              },
            ],
          },
          {
            path: 'planogram',
            component: () => import('@/apps/trade-point/views/Create/Stages/Planogram/Planogram'),
            meta: { stage: 'planogram' },
            children: [
              {
                path: '',
                name: 'trade-point$stage-planogram',
                component: () =>
                  import(
                    '@/apps/trade-point/views/Create/Stages/Planogram/Micromarket/Micromarket'
                  ),
              },

              {
                path: 'preview',
                name: 'trade-point$stage-planogram-preview',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/Planogram/Preview/Preview'),
              },
            ],
          },

          {
            path: 'payment',
            name: 'trade-point$stage-payment',
            component: () => import('@/apps/trade-point/views/Create/Stages/Payment/Payment'),
            meta: { stage: 'payment' },
          },
          {
            path: 'almostdone',
            name: 'trade-point$stage-almostdone',
            component: () => import('@/apps/trade-point/views/Create/Stages/AlmostDone/AlmostDone'),
            meta: { stage: 'final' },
          },
          {
            path: 'delivery',
            component: () => import('@/apps/trade-point/views/Create/Stages/Delivery/Delivery'),
            meta: { stage: 'delivery' },
            children: [
              {
                path: '',
                name: 'trade-point$stage-delivery',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/Delivery/Entry/Entry'),
              },
              {
                path: 'tariff',
                name: 'trade-point$stage-delivery-tariff',
                component: () =>
                  import('@/apps/trade-point/views/Create/Stages/Delivery/Tariff/Tariff'),
              },
            ],
          },
          {
            path: 'fuel',
            name: 'trade-point$stage-fuel',
            component: () => import('@/apps/trade-point/views/Create/Stages/Fuel/Fuel'),
            meta: { stage: 'fuel' },
          },
          {
            path: 'finishstages',
            name: 'trade-point$stage-finishstages',
            props: true,
            component: () =>
              import('@/apps/trade-point/views/Create/Stages/FinishStages/FinishStages'),
          },
        ],
      },
    ],
  },
  {
    path: 'trade-point',
    component: () => import('@/apps/trade-point/views/Main/Main'),

    children: [
      {
        path: '',
        name: 'trade-point$trade-point',
        component: () => import('@/apps/trade-point/views/Main/RetailStores/RetailStores'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
          ],
        },
      },
      {
        path: ':id',
        name: 'trade-point$trade-point-store',
        component: () => import('@/apps/trade-point/views/Main/Store/Store'),
        props: true,
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
          ],
        },
      },
    ],
  },

  {
    path: 'trade-point/edit/:id',
    component: () => import('@/apps/trade-point/views/Edit/Entry/Entry'),
    children: [
      {
        path: '',
        component: () => import('@/apps/trade-point/views/Edit/Design/Entry'),
        children: [
          {
            path: 'design',
            name: 'trade-point$edit-design',
            component: () => import('@/apps/trade-point/views/Edit/Design/Select/Select'),
            meta: {
              breadCrumb: [
                {
                  label: 'main_page',
                  name: 'main',
                },
                {
                  label: 'trade_points',
                  name: 'trade-point$trade-point',
                },
                {
                  label: 'get:tradePointId',
                  name: '',
                },
                {
                  label: 'design_title',
                  name: 'trade-point$edit-design',
                },
              ],
            },
          },

          {
            path: 'existing/:companyBrandId',
            name: 'trade-point$edit-design-existing',
            component: () => import('@/apps/trade-point/views/Edit/Design/Edit/Edit'),
          },
        ],
      },
      {
        path: 'planogram',
        component: () => import('@/apps/trade-point/views/Edit/Planogram/Planogram'),
        children: [
          {
            path: '',
            name: 'trade-point$edit-planogram',
            component: () =>
              import('@/apps/trade-point/views/Edit/Planogram/Micromarket/Micromarket'),
          },

          {
            path: 'preview',
            name: 'trade-point$edit-planogram-preview',
            meta: { photo: true },
            component: () => import('@/apps/trade-point/views/Edit/Planogram/Preview/Preview'),
          },
        ],
      },
      {
        path: 'type',
        name: 'trade-point$edit-type',
        component: () => import('@/apps/trade-point/views/Edit/Type/Type'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'stage_type_edit_title',
              name: '',
            },
          ],
        },
      },
      {
        path: 'schedule',
        name: 'trade-point$edit-schedule',
        component: () => import('@/apps/trade-point/views/Edit/Schedule/Schedule'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'schedule',
              name: '',
            },
          ],
        },
      },
      {
        path: 'employees',
        name: 'trade-point$edit-employees',
        component: () => import('@/apps/trade-point/views/Edit/Employees/Employees'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'employees',
              name: '',
            },
          ],
        },
      },
      {
        path: 'requisites',
        component: () => import('@/apps/trade-point/views/Edit/Requisites/Requisites'),
        children: [
          {
            path: '',
            name: 'trade-point$edit-requisites',
            component: () => import('@/apps/trade-point/views/Edit/Requisites/Entry/Entry'),
            meta: {
              breadCrumb: [
                {
                  label: 'main_page',
                  name: 'main',
                },
                {
                  label: 'trade_points',
                  name: 'trade-point$trade-point',
                },
                {
                  label: 'get:tradePointId',
                  name: '',
                },
                {
                  label: 'requisites_title',
                  name: '',
                },
              ],
            },
          },
          {
            path: 'steps',
            name: 'trade-point$edit-requisites-steps',
            component: () => import('@/core/components/RequisitesSteps/RequisitesSteps'),
          },
          {
            path: 'terminal',
            name: 'trade-point$edit-requisites-terminal',
            component: () => import('@/core/components/Terminal/Terminal'),
          },
        ],
      },
      {
        path: 'items',
        name: 'trade-point$edit-items',
        component: () => import('@/apps/items/views/Items/Items'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'requisites_items',
              name: 'trade-point$edit-design',
            },
          ],
        },
      },
      {
        path: 'items/additem',
        name: 'trade-point$edit-items-additem',
        props: true,
        component: () => import('@/apps/items/views/Item/Item'),
        meta: {
          createStore: false,
          editStore: true,

          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'items',
              name: 'trade-point$edit-items',
            },
            {
              label: 'add_good',
              name: '',
            },
          ],
        },
      },
      {
        path: 'items/item/:itemId',
        props: true,
        name: 'trade-point$edit-items-item',
        component: () => import('@/apps/items/views/Item/Item'),
        meta: {
          createStore: false,
          editStore: true,

          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'items',
              name: 'trade-point$edit-items',
            },
            {
              label: 'get:itemId',
              name: '',
            },
          ],
        },
      },
      {
        path: 'items-coffee',
        name: 'trade-point$edit-items-coffee',
        component: () => import('@/apps/trade-point/views/Edit/Items/itemsCoffee/itemsCoffee'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'items',
              name: 'trade-point$edit-items-coffee',
            },
          ],
        },
      },
      {
        path: 'items-coffee/add',
        name: 'trade-point$edit-items-additemcoffee',
        props: true,
        component: () => import('@/apps/trade-point/views/Edit/Items/ItemCoffee/ItemCoffee'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'items',
              name: 'trade-point$edit-items-coffee',
            },
            {
              label: 'add_good',
              name: '',
            },
          ],
        },
      },
      {
        path: 'items-coffee/:itemId',
        name: 'trade-point$edit-items-itemcoffee',
        props: true,
        component: () => import('@/apps/trade-point/views/Edit/Items/ItemCoffee/ItemCoffee'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'items',
              name: 'trade-point$edit-items',
            },
            {
              label: 'get:itemId',
              name: '',
            },
          ],
        },
      },
      {
        path: 'briskly-module',
        component: () => import('@/apps/trade-point/views/Edit/BrisklyModule/BrisklyModule'),
        children: [
          {
            path: '',
            name: 'trade-point$edit-briskly-module',
            component: () => import('@/apps/trade-point/views/Edit/BrisklyModule/Entry/Entry'),
          },

          {
            path: 'buy',
            name: 'trade-point$edit-briskly-module-buy',
            component: () => import('@/apps/trade-point/views/Edit/BrisklyModule/Buy/Buy'),
          },
        ],
      },
      {
        path: 'items/exchange',
        name: 'trade-point$edit-items-exchange',
        component: () => import('@/apps/trade-point/views/Edit/Items/Exchange/Entry'),
        meta: {
          createStore: false,
          editStore: true,

          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'items',
              name: 'trade-point$edit-items',
            },
            {
              label: 'exchange_settigns',
              name: 'trade-point$edit-items-exchange',
            },
          ],
        },
      },
      {
        path: 'items/exchange/accounting',
        name: 'trade-point$edit-items-exchange-accounting',
        component: () =>
          import('@/apps/trade-point/views/Edit/Items/Exchange/Accounting/Accounting'),
      },
      {
        path: 'items/exchange/file',
        name: 'trade-point$edit-items-exchange-file',
        component: () => import('@/apps/items/views/FileUpload/FileUpload'),
        meta: {
          editStore: true,
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'exchange_settigns',
              name: 'trade-point$edit-items-exchange',
            },
            {
              label: 'do_add_items_list',
            },
          ],
        },
      },
      {
        path: 'items/exchange/existing',
        name: 'trade-point$edit-items-exchange-existing',
        meta: {
          createStore: false,
          editStore: true,

          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'items',
              name: 'trade-point$edit-items',
            },
            {
              label: 'exchange_settigns',
              name: 'trade-point$edit-items-exchange',
            },
            {
              label: 'integration_entry_label_option3',
              name: 'trade-point$edit-items-exchange',
            },
          ],
        },
        component: () => import('@/apps/trade-point/views/Edit/Items/Exchange/Existing/Existing'),
      },
      {
        path: 'delivery',
        name: 'trade-point$edit-delivery',
        component: () => import('@/apps/trade-point/views/Edit/Delivery/Delivery'),
        meta: {
          breadCrumb: [
            {
              label: 'main_page',
              name: 'main',
            },
            {
              label: 'trade_points',
              name: 'trade-point$trade-point',
            },
            {
              label: 'get:tradePointId',
              name: '',
            },
            {
              label: 'delivery',
              name: '',
            },
          ],
        },
      },
      {
        path: 'payment',
        name: 'trade-point$edit-payment',
        component: () => import('@/apps/trade-point/views/Edit/Payment/Payment'),
      },
    ],
  },
];
