import { cloneDeep } from 'lodash';

export const week = [
  'week_mon',
  'week_tue',
  'week_wed',
  'week_thu',
  'week_fri',
  'week_sat',
  'week_sun',
];

const daySchedule = {
  title: '',
  permanently: false,
  weekend: false,
  intervals: [
    {
      from: { h: '', m: '' },
      to: { h: '', m: '' },
    },
  ],
};

export const flags = [
  {
    name: 'permanently',
    title: 'permanently',
    hideIntervals: true,
    reducer: (day) => ({ ...day, weekend: false, permanently: !day.permanently }),
  },
  {
    name: 'weekend',
    title: 'weekend',
    hideIntervals: true,
    reducer: (day) => ({ ...day, permanently: false, weekend: !day.weekend }),
  },
];

export const buildInitialSchedule = () =>
  week.map((day) => Object.assign(cloneDeep(daySchedule), { title: day }));
