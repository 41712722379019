import { companyAPI } from '@/core/api/company';
import { buildInitialSchedule } from '@/core/data/schedule';
import { serializeSchedule } from '@/apps/trade-point/utils/scheduleParse';
import { hasChanges } from '@/apps/trade-point/utils/changes';
import { notificationService } from '@/core/services/NotificationService';
import { getTimeZone } from '@/core/utils/timeZone';
import { EXTENDED_TYPES } from '@/apps/trade-point/data/types';

const initialState = () => ({
  days: buildInitialSchedule(),
  templateName: '',
  saveAsTemplate: false,
  afterVisitingCourier: false,
  timeZone: '',
});

const state = initialState();

const getters = {
  scheduleDays: (state) => state.days,
  templateName: (state) => state.templateName,
  saveAsTemplate: (state) => state.saveAsTemplate,
  afterVisitingCourier: (state) => state.afterVisitingCourier,
  timeZone: (state) => state.timeZone,

  canSkipStage: (state) =>
    state.days.every((day) => {
      const isFlagsInactive = !day.weekend && !day.permanently;
      const isIntervalsNotFilled = day.intervals.every((interval) => {
        return Object.values(interval).every(({ h, m }) => !h && !m);
      });

      return isFlagsInactive && isIntervalsNotFilled;
    }),
  hasUnsavedChanges: (state) => hasChanges(state, initialState()),
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  init: ({ dispatch, rootGetters }, _store) => {
    const store = _store || rootGetters['trade-point$create/store'] || {};
    dispatch('setTimeZone', store.timezone_name || getTimeZone());
  },

  completeStage: async ({ state, dispatch, rootGetters }) => {
    try {
      const store = rootGetters['trade-point$create/store'];
      const { id: schedule_set_id } = await dispatch('createOrUpdateSchedule', store);
      await dispatch('updateTimeZone', { ...store, schedule_set_id });

      await dispatch(
        'trade-point$create/setStore',
        { ...store, schedule_set_id, timezone_name: state.timeZone },
        { root: true },
      );

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  setScheduleDays: ({ commit }, days) => {
    commit('_setScheduleDays', days);
  },

  setTemplateName: ({ commit }, name) => {
    commit('_setTemplateName', name);
  },

  setSaveAsTemplate: ({ commit }, value) => {
    commit('_setSaveAsTemplate', value);
  },

  setTimeZone: ({ commit }, value) => {
    commit('_setTimeZone', value);
  },

  setAfterVisitingCourier: ({ commit }, value) => {
    commit('_setAfterVisitingCourier', value);
  },

  getScheduleList: (_, opts = {}) => {
    const { q } = opts;

    return companyAPI.getScheduleList({ q });
  },

  createOrUpdateSchedule: async ({ state }, store) => {
    const { id: storeId, name, schedule_set_id } = store;

    const payload = {
      days_of_week: serializeSchedule(state.days),
      name: state.templateName,
      is_template: Number(state.saveAsTemplate),
      text: name,
    };

    const schedule = schedule_set_id
      ? await companyAPI.updateSchedule({ ...payload, id: schedule_set_id })
      : await companyAPI.createSchedule(payload);

    await companyAPI.bindScheduleToStore({
      id: Number(storeId),
      schedule_set_id: schedule.id || schedule_set_id,
    });

    notificationService.sendSuccessSave();

    return schedule;
  },

  updateTimeZone: ({ state }, store) => {
    if (store.timezone_name === state.timeZone) {
      return Promise.resolve();
    }
    const isCoffeeMashine = store.extended_type === EXTENDED_TYPES.COFFEE_MACHINE;

    const payload = { ...store, timezone_name: state.timeZone };

    return isCoffeeMashine
      ? companyAPI.updateStoreCoffee(payload)
      : companyAPI.updateStore(payload);
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setScheduleDays: (state, days) => {
    state.days = days;
  },

  _setTemplateName: (state, name) => {
    state.templateName = name;
  },

  _setSaveAsTemplate: (state, value) => {
    state.saveAsTemplate = value;
  },

  _setTimeZone: (state, value) => {
    state.timeZone = value;
  },

  _setAfterVisitingCourier: (state, value) => {
    state.afterVisitingCourier = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
