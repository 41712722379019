import { EXTENDED_TYPES } from '@/apps/trade-point/data/types';

const getLocaleForType = (type) => {
  switch (type) {
    case EXTENDED_TYPES.STORE:
      return 'extended_types_store';

    case EXTENDED_TYPES.REFRIGERATOR:
      return 'extended_types_refrigerator';

    case EXTENDED_TYPES.STORE_ONLINE:
      return 'extended_types_store_online';

    case EXTENDED_TYPES.CAFE:
      return 'extended_types_cafe';

    case EXTENDED_TYPES.PRODUCTION_WITH_DELIVERY:
      return 'extended_types_production_with_delivery';

    case EXTENDED_TYPES.FILLING_STATION:
      return 'extended_types_filling_station';

    case EXTENDED_TYPES.CIGARETTES:
      return 'extended_types_cigarettes';

    case EXTENDED_TYPES.COFFEE_MACHINE:
      return 'extended_types_coffee_machine';

    case EXTENDED_TYPES.WASHER_FLUID:
      return 'extended_types_washer_fluid';

    default:
      return type;
  }
};

const canHaveDelivery = (type) => {
  return ![EXTENDED_TYPES.REFRIGERATOR, EXTENDED_TYPES.FILLING_STATION].includes(type);
};

export { getLocaleForType, canHaveDelivery };
