import { companyAPI } from '@/core/api/company';
import { phoneAlignment } from '@/core/utils/validate';
import { bus } from 'shared/core';
import cloneDeep from 'lodash/cloneDeep';

const state = {
  profile: {},
};

const getters = {
  profile: (state) => state.profile,
};

const actions = {
  init: async ({ commit }) => {
    try {
      const profile = await companyAPI.getSelfProfile();
      commit('_setProfile', profile);
      return true;
    } catch (error) {
      console.error(error);
    }
  },

  equalizeProfile: async ({ commit, dispatch }, profile) => {
    try {
      await dispatch('updateSelfProfile', profile);
      commit('_setProfile', profile);
    } catch (error) {
      console.error(error);
    }
  },

  updateSelfProfile: (_, profile) => {
    const { phone } = profile;
    const validProfile = { ...profile, phone: phoneAlignment(phone) };

    return companyAPI.updateSelfProfile({ profile: validProfile });
  },
};

const mutations = {
  _setProfile: (state, profile) => {
    state.profile = cloneDeep(profile);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
