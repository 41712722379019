export const checkRefundStatus = (order) => {
  const checkedOrder = { ...order };
  if (checkedOrder.status_refund) {
    if (checkedOrder.status_refund === 'partial')
      return { ...checkedOrder, status: 'refund_partial' };
    if (checkedOrder.status_refund === 'full') return { ...checkedOrder, status: 'refund' };
  } else {
    return checkedOrder;
  }
};
