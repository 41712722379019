import { produce } from 'immer';
import { cloneDeep, uniq } from 'lodash';
import { buildInitialStages } from '@/apps/trade-point/data/stages';
import { EXTENDED_TYPES } from '@/apps/trade-point/data/types';
import router from '@/core/router';
import { companyAPI } from '@/core/api/company';

const initialState = () => ({
  stages: buildInitialStages(),
  currentStageName: null,
  stagesOrder: [],

  storeGroup: null,
  store: null,
  companyBrand: null,
  catalog: null,

  loading: false,
  continues: false,
});

const state = initialState();

const getters = {
  stages: (state) => state.stages,
  stagesRoadmap: (state) => state.stagesOrder.map((type) => state.stages[type]),
  currentStageName: (state) => state.currentStageName,
  isStageRequired: (state) => (name) => state.stages[name].required,
  isStageComplete: (state) => (name) => state.stages[name].complete,

  mapKnownStageNames: () => (target = []) => {
    target = target.map((stage) => {
      let target;

      switch (stage) {
        case 'party':
          target = 'requisites';
          break;

        case 'catalog':
        case 'items':
          target = 'exchange';
          break;

        case 'briskly_module':
          target = 'brisklyModule';
          break;

        case 'employee':
          target = 'employees';
          break;

        case 'card':
          target = 'payment';
          break;

        default:
          target = stage;
      }

      return target;
    });

    target = [...new Set(target)];

    target = target.sort((s1, s2) => {
      if (s1 === 'type') return -1;
      else if (s2 === 'type') return 1;
      return 0;
    });

    return target;
  },

  getStageByName: (state) => (name) => {
    return Object.keys(state.stages)
      .map((k) => state.stages[k])
      .find((stage) => stage.name === name);
  },

  getNextStage: (state) => (currentStage) => {
    const { final } = state.stages;
    if (final.visited) return final;

    const order = state.stagesOrder.filter((stage) => {
      const { hide, block } = state.stages[stage];
      return !hide && !block;
    });

    const idx = order.findIndex((t) => t === currentStage);
    const nextStageName = order[idx + 1];
    const isLastStage = idx === order.length - 1;

    return isLastStage ? final : state.stages[nextStageName];
  },

  canSkipStage: (state, getters, rootState, rootGetters) => (name) => {
    return state.stages[name].required ? false : rootGetters[`trade-point$${name}/canSkipStage`];
  },

  isRegistrationComplete: (state) =>
    state.stagesOrder.every((stageName) => state.stages[stageName].complete),

  catalog: (state) => state.catalog,
  storeGroup: (state) => state.storeGroup,
  companyBrand: (state) => state.companyBrand,
  store: (state) => state.store,
  loading: (state) => state.loading,
  continues: (state) => state.continues,
  commodityAccounting: (state) => state.store.is_commodity_accounting_enabled,
};

const actions = {
  flush: async ({ state, commit, dispatch }) => {
    const stageNames = Object.keys(state.stages)
      .map((k) => state.stages[k])
      .map((stage) => stage.name);

    await Promise.all(
      stageNames.map((name) => dispatch(`trade-point$${name}/flush`, null, { root: true })),
    );

    commit('_flush');
  },

  init: async ({ dispatch, state }, opts) => {
    opts = opts || {};

    const { force = false, storeId, storeGroupId } = opts;

    const STORE_ID = storeId || process.env.STORE_ID;
    const STORE_GROUP_ID = storeGroupId || process.env.STORE_GROUP_ID;

    if (IS_DEV || force) {
      try {
        if (STORE_GROUP_ID) {
          const storeGroup = await companyAPI.getStoreGroupById({ id: STORE_GROUP_ID });
          await dispatch('setStoreGroup', storeGroup);
        }

        if (STORE_ID) {
          const store = await companyAPI.getStoreById({ id: STORE_ID });

          await dispatch('trade-point$type/setInitialInfo', store, { root: true });

          if (store.party_id) {
            const party = await companyAPI.getPartyById({ id: store.party_id });
            await dispatch('trade-point$requisites/setSelectedRequisites', party, { root: true });
          }

          if (store.extended_type) {
            await dispatch('trade-point$type/setTypeAction', store.extended_type, {
              root: true,
            });
          }

          await dispatch('setStore', store);
        }

        const CATALOG_ID = (state.store && state.store.catalog_id) || process.env.CATALOG_ID;

        if (CATALOG_ID) {
          const catalog = await companyAPI.getCatalogById({ id: CATALOG_ID });
          await dispatch('setCatalog', catalog);

          if (state.store.extended_type === EXTENDED_TYPES.REFRIGERATOR) {
            dispatch('addStageInOrder', 'planogram');
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  },

  setCurrentStageName: ({ commit }, value) => {
    commit('_setCurrentStageName', value);
  },

  continueStages: ({ state, dispatch, commit, getters }, opts = {}) => {
    let { stages, store_group_id, stage = 'final' } = opts;

    let completed = Object.keys(stages).filter((stage) => stages[stage].status);

    if (completed.includes('type') && !Number(store_group_id)) {
      completed = completed.filter((s) => s !== 'type');
    }

    if (!getters.store.store_group_id) {
      completed = completed.filter((s) => s !== 'type');
    }

    const processedStages = getters.mapKnownStageNames(Object.keys(stages));
    const missingStages = state.stagesOrder.filter((stage) => !processedStages.includes(stage));
    completed = getters.mapKnownStageNames(completed);

    uniq([...completed, ...missingStages]).forEach((stage) => {
      commit('setStageStatus', { name: stage, complete: true });
      commit('setStageHide', { name: stage, value: true });
    });

    if (processedStages.includes('planogram')) {
      const hasItems = completed.includes('exchange');
      commit('setStageBlock', { name: 'planogram', value: !hasItems });
    }

    if (!getters.store.extended_type || !getters.store.store_group_id) {
      stage = 'type';
    }

    [stage] = getters.mapKnownStageNames([stage]);

    commit('_setContinues', true);
    return dispatch('returnToStage', state.stages[stage]);
  },

  setCatalog: ({ commit }, value) => {
    commit('_setCatalog', value);
    commit('catalogs$catalogs/_addcatalog', value, { root: true });
  },

  setStore: ({ commit }, value) => {
    commit('_setStore', value);
  },

  setStoreGroup: ({ commit }, value) => {
    commit('_setStoreGroup', value);
  },

  setCompanyBrand: ({ commit }, value) => {
    commit('_setCompanyBrand', value);
  },

  addStageInOrder: ({ state, commit }, stageName) => {
    if (state.stagesOrder.includes(stageName)) return;

    const newOrder = [...state.stagesOrder, stageName];
    commit('setStagesOrder', newOrder);
  },

  removeStageFromOrder: ({ state, commit }, stageName) => {
    const newOrder = state.stagesOrder.filter((stage) => stage !== stageName);
    commit('setStagesOrder', newOrder);
  },

  unlockStage: ({ commit }, stageName) => {
    commit('setStageBlock', { name: stageName, value: false });
  },

  lockStage: ({ commit }, stageName) => {
    commit('setStageBlock', { name: stageName, value: true });
  },

  goToNextStage: async ({ dispatch, getters, commit }, opts = {}) => {
    const go = (stage) =>
      router
        .push({ name: stage.route })
        .then(() => commit('setStageVisited', { name: stage.name, value: true }));

    try {
      commit('setLoading', true);

      const { name, data = null, forceComplete = false, forceSkip = false } = opts;

      const currentStage = getters.getStageByName(name);
      let nextStage = getters.getNextStage(currentStage.name);

      const canSkip = !forceComplete && getters.canSkipStage(currentStage.name);

      if (forceSkip || canSkip) {
        commit('_setCurrentStageName', nextStage.name);
        await go(nextStage);
        return;
      }

      const complete = await dispatch(`trade-point$${currentStage.name}/completeStage`, data, {
        root: true,
      });

      nextStage = getters.getNextStage(currentStage.name);

      if (!complete) return;

      commit('setStageStatus', { name: currentStage.name, complete: !!complete });

      commit('_setCurrentStageName', nextStage.name);
      await go(nextStage);

      return true;
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },

  returnToStage: async ({ commit }, stage) => {
    commit('_setCurrentStageName', stage.name);
    commit('setStageVisited', { name: stage.name, value: true });
    if (router.currentRoute.name !== stage.route) {
      await router.push({ name: stage.route });
    }
  },

  updateCommodityAccounting: ({ commit }, data) => {
    commit('_setCommodityAccounting', data);
  },

  rebuildStages: ({ commit, getters }, stageReducer = (name, stage) => stage) => {
    const entries = Object.entries(getters.stages).map(([name, stage]) => [
      name,
      stageReducer(name, stage),
    ]);
    commit('_setStages', Object.fromEntries(entries));
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setStages: (state, stages) => {
    state.stages = stages;
  },

  _setCurrentStageName: (state, value) => {
    state.currentStageName = value;
  },

  setStagesOrder: (state, order) => {
    state.stagesOrder = order;
  },

  setStageStatus: (state, { name, complete }) => {
    state.stages = produce(cloneDeep(state.stages), (draft) => {
      draft[name].complete = complete;
    });
  },

  setStageVisited: (state, { name, value }) => {
    state.stages = produce(cloneDeep(state.stages), (draft) => {
      draft[name].visited = value;
    });
  },

  setStageHide: (state, { name, value }) => {
    state.stages = produce(cloneDeep(state.stages), (draft) => {
      draft[name].hide = value;
    });
  },

  setStageBlock: (state, { name, value }) => {
    state.stages = produce(cloneDeep(state.stages), (draft) => {
      draft[name].block = value;
    });
  },

  setStageRequired: (state, { name, value }) => {
    state.stages = produce(cloneDeep(state.stages), (draft) => {
      draft[name].required = value;
    });
  },

  _setCatalog: (state, value) => {
    state.catalog = value;
  },

  _setStore: (state, value) => {
    state.store = value;
  },

  _setStoreGroup: (state, value) => {
    state.storeGroup = value;
  },

  _setCompanyBrand: (state, value) => {
    state.companyBrand = value;
  },

  setLoading: (state, value) => {
    state.loading = value;
  },

  _setContinues: (state, value) => {
    state.continues = value;
  },

  _setCommodityAccounting: (state, { is_commodity_accounting_enabled }) => {
    state.store = { ...state.store, is_commodity_accounting_enabled };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
