export default [
  {
    path: 'items',
    name: 'items$items',
    component: () => import('@/apps/items/views/Items/Items'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: 'main',
        },
        {
          label: 'items',
          name: 'items$items',
        },
      ],
    },
  },
  {
    path: 'items/item/:itemId',
    name: 'items$items-item',
    component: () => import('@/apps/items/views/Item/Item'),
    props: true,
    meta: {
      createStore: false,
      breadCrumb: [
        {
          label: 'main_page',
          name: 'main',
        },
        {
          label: 'items',
          name: 'items$items',
        },
        {
          label: 'item_edit_action',
          name: '',
        },
      ],
    },
  },
  {
    path: 'items/item/new',
    name: 'items$items-additem',
    props: true,
    component: () => import('@/apps/items/views/Item/Item'),
    meta: {
      createStore: false,
      breadCrumb: [
        {
          label: 'main_page',
          name: 'main',
        },
        {
          label: 'items',
          name: 'items$items',
        },
        {
          label: 'add_good',
          name: '',
        },
      ],
    },
  },
  {
    path: 'items/stickers',
    name: 'items$stickers',
    component: () => import('@/apps/items/views/Stickers/Stickers'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'items',
          name: 'items$items',
        },
        {
          label: 'stickers_print',
          name: '',
        },
      ],
    },
  },
  {
    path: 'items/fromfile',
    name: 'items$fromfile',
    component: () => import('@/apps/items/views/FileUpload/FileUpload'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'items',
          name: 'items$items',
        },
        {
          label: 'do_add_items_list',
          name: '',
        },
      ],
    },
  },
  {
    path: 'items/itemsstock/:itemId',
    name: 'items$items-stock',
    props: true,
    component: () => import('@/apps/items/views/ItemStock/ItemStock'),
    meta: {
      breadCrumb: [
        {
          label: 'main_page',
          name: '/',
        },
        {
          label: 'items',
          name: 'items$items',
        },
        {
          label: 'stock_balances',
          name: '',
        },
      ],
    },
  },
];
