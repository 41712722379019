import { companyAPI } from '@/core/api/company';
import { getYOptionsByMode } from '@/core/utils/graph';
import { getDateGroupMode, getDateInterval } from '@/core/utils/date';
import { bus } from 'shared/core';

const state = {
  totalStoreAmount: 0,
  notCompletedStoreAmount: 0,
  notCompletedStores: [],
  dayInterval: {
    range: { start: null, end: null },
    shortcut: 'today',
  },
  income: {
    items: [],
    mode: '',
  },
  avgCheck: {
    intems: [],
  },
  avgCheckTotal: '',
  pillowData: [
    { id: 'quantity', text: 'purchases_quantity', val: '0' },
    { id: 'avg_amount', text: 'averageCheck', val: '0' },
    { id: 'total_amount', text: 'revenue', val: '0' },
    { id: 'income', text: 'profit', val: '0' },
  ],
};

const getters = {
  dayInterval: (state) => state.dayInterval,
  pillowData: (state) => state.pillowData,
  avgCheck: (state) =>
    state.income.items.map((i) => ({
      label: getYOptionsByMode(state.income.mode, i),
      value: Number(i.avg_amount),
    })),
  income: (state) =>
    state.income.items.map((i) => ({
      label: getYOptionsByMode(state.income.mode, i),
      value: Number(i.total_amount),
    })),
  totalStoreAmount: (state) => state.totalStoreAmount,
  notCompletedStoreAmount: (state) => state.notCompletedStoreAmount,
  notCompletedStores: (state) => state.notCompletedStores,
  avgCheckTotal: (state) => state.avgCheckTotal,
};

const actions = {
  setDayInterval: async ({ commit }, data) => {
    await commit('_setDayInterval', data);
  },

  getIncomeAction: async ({ commit }, interval) => {
    const { start, end } = getDateInterval(interval);
    const mode = getDateGroupMode({ start, end });
    const payload = {
      date_start: start,
      date_end: end,
      mode,
      status: 'paid',
    };
    try {
      const response = await companyAPI.getStatistics({ ...payload });
      commit('_setIncome', { ...response, mode });
      commit('_setAvgCheckTotal', response);
      commit('_setPillows', response);
    } catch (error) {
      console.error(error);
    }
  },

  async getTradePointStatus({ commit }) {
    try {
      const response = await companyAPI.getTradePointStatus();
      const { total, not_completed, stores } = response;

      commit('_setTotalStoreAmount', total);
      commit('_setNotCompletedStoreAmount', not_completed);
      commit('_setNotCompletedStores', stores);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  _setDayInterval: (state, { interval, shortcut }) => {
    state.dayInterval.range = interval;
    state.dayInterval.shortcut = shortcut;
  },

  _setIncome: (state, income) => {
    const { items, mode } = income;

    if (items.length === 1) {
      const newItems = [{ ...items[0], total_amount: '0', avg_amount: '0' }, ...items];
      return (state.income = { items: newItems, mode });
    }
    state.income = { items, mode };
  },

  _setPillows: (state, { total }) => {
    state.pillowData = state.pillowData.map((i) => ({
      id: i.id,
      text: i.text,
      val: total[i.id] === undefined ? 0 : total[i.id].toFixed(),
    }));
  },

  _setTotalStoreAmount: (state, value) => {
    state.totalStoreAmount = value;
  },

  _setNotCompletedStoreAmount: (state, value) => {
    state.notCompletedStoreAmount = value;
  },

  _setNotCompletedStores: (state, items) => {
    state.notCompletedStores = items;
  },

  _setAvgCheckTotal: (state, { total }) => {
    const { avg_amount } = total;
    state.avgCheckTotal = avg_amount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
