import { produce } from 'immer';
import { cloneDeep, last } from 'lodash';
import { companyAPI } from '@/core/api/company';
import {
  getEmptyShelf,
  buildInitialShelfs,
  flatShelfs,
  normalizeData,
} from '@/apps/trade-point/utils/planogram';

import { hasChanges } from '@/apps/trade-point/utils/changes';
import { bus } from 'shared/core';
import { notificationService } from '@/core/services/NotificationService';
import { nanoid } from 'nanoid';

const SHELFS_LIMIT = 10;
const PRODUCTS_LIMIT = 15;

const initialState = () => ({
  planogramList: [],
  planogram: null,
  shelfs: buildInitialShelfs(),
  tooltips: [],
  changeMode: false,
  addMode: false,
  addShelfIndex: -1,
  newProductName: '',
  selectedProduct: null,
});

const state = initialState();

const getters = {
  planogramList: (state) => state.planogramList,
  planogramName: (state) => (state.planogram && state.planogram.name) || '',
  shelfs: (state) => state.shelfs,
  tooltips: (state) => state.tooltips,
  changeMode: (state) => state.changeMode,
  addMode: (state) => state.addMode,
  addShelfIndex: (state) => state.addShelfIndex,
  newProductName: (state) => state.newProductName,
  selectedProduct: (state) => state.selectedProduct,

  canSkipStage: (state) => !state.shelfs.map(({ items }) => items).flat().length,

  isShelfOverflow: (state) => (shelfIndex) => {
    const shelf = state.shelfs[shelfIndex];

    return shelf.items.length >= PRODUCTS_LIMIT;
  },

  isPlanogramOverflow: (state) => state.shelfs.length >= SHELFS_LIMIT,

  getDragGroupOptions: (_, getters) => (shelfIndex) => ({
    name: `shelf${shelfIndex}`,
    put: getters.isShelfOverflow(shelfIndex) ? [`shelf${shelfIndex}`] : true,
  }),

  hasUnsavedChanges: (state) =>
    hasChanges(state, initialState(), ['planogramList', 'planogram', 'tooltips', 'addShelfIndex']),
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  completeStage: async ({ dispatch }) => {
    try {
      const isPlanogramItemsSet = await dispatch('trade-point$planogram/setPlanogramItems', null, {
        root: true,
      });

      return isPlanogramItemsSet;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  init: async ({ dispatch, rootGetters }, store) => {
    const currentStore = store || rootGetters['trade-point$create/store'];
    if (!currentStore) return;

    const { planogram_id } = currentStore;

    (await planogram_id)
      ? dispatch('getPlanogram', currentStore)
      : dispatch('createPlanogram', currentStore);

    await dispatch('getPlanogramList');
  },

  createPlanogram: async ({ state, commit, rootGetters, dispatch }, store) => {
    if (state.planogram) return;

    try {
      const planogram = await companyAPI.createPlanogram({ name: store.name });
      const updatedStore = await companyAPI.bindPlanogramToStore({
        id: store.id,
        planogram_id: planogram.id,
      });

      if (rootGetters['trade-point$create/store']) {
        await dispatch('trade-point$create/setStore', updatedStore, { root: true });
      }

      commit('_setPlanogram', planogram);
    } catch (error) {
      console.log(error);
    }
  },

  getPlanogram: async ({ commit }, store) => {
    try {
      let planogram = await companyAPI.getPlanogramById({ id: store.planogram_id });
      if (planogram.name !== store.name) {
        planogram = await companyAPI.updatePlanogram({ ...planogram, name: store.name });
      }
      const shelfs = planogram.items.length ? normalizeData(planogram.items) : buildInitialShelfs();

      commit('_setPlanogram', planogram);
      commit('_setShelfs', shelfs);
    } catch (error) {
      console.log(error);
    }
  },

  setPlanogramItems: async ({ state }) => {
    if (!state.planogram) return false;

    const payload = {
      planogram_id: state.planogram.id,
      items: flatShelfs(state.shelfs),
    };

    await companyAPI.createPlanogramItems(payload);
    notificationService.sendSuccessSave();
    return true;
  },

  getPlanogramList: async ({ commit }) => {
    const { items } = await companyAPI.getPlanogramList();
    commit('_setPlanogramList', items);
  },

  uploadPlanogramPhoto: async ({ dispatch }, opts = {}) => {
    const { file, id } = opts;

    await companyAPI.uploadPlanogramPhoto({ file, id });
    notificationService.sendSuccessSave();
    return dispatch('getPlanogramList');
  },

  getItemList: ({ rootGetters }, opts = {}) => {
    const catalog = rootGetters['trade-point$create/catalog'];
    const catalog_id = opts.catalog_id || (catalog && catalog.id) || 0;
    const { q } = opts;

    return companyAPI.getItemList({ q, catalog_id });
  },

  setShelf: ({ commit }, { shelf, shelfIdx }) => {
    commit('_setShelf', { shelf, shelfIdx });
  },

  setShelfs: ({ commit }, shelfs) => {
    commit('_setShelfs', shelfs);
  },

  addShelf: ({ commit, getters }) => {
    if (getters.isPlanogramOverflow) return;
    commit('_addShelf');
  },

  removeOrClearShelf: ({ commit, state }, index) => {
    commit('_removeTooltip', index);

    const shelf = state.shelfs[index];

    shelf.items.length ? commit('_clearShelf', index) : commit('_removeShelf', index);
    notificationService.sendSuccessDelete();
  },

  addProduct: ({ state, commit, getters, dispatch }) => {
    if (getters.isShelfOverflow(state.addShelfIndex)) {
      dispatch('showLimitTooltip', state.addShelfIndex);
      dispatch('turnOffAddMode');
      return;
    }

    commit('_addProduct');
    dispatch('turnOffAddMode');
  },

  removeProduct: ({ commit }, { itemIdx, shelfIdx }) => {
    commit('_removeProduct', { itemIdx, shelfIdx });
    notificationService.sendSuccessDelete();
  },

  toggleChangeMode: ({ commit, state }) => {
    if (state.addMode) return;

    commit('_toggleChangeMode');
  },

  turnOnAddMode: ({ commit, getters, dispatch }, shelfIndex) => {
    if (getters.isShelfOverflow(shelfIndex)) {
      return dispatch('showLimitTooltip', shelfIndex);
    }

    commit('_setAddMode', { value: true, shelfIndex });
  },

  turnOffAddMode: ({ commit }) => {
    commit('_setAddMode', { value: false });
  },

  setNewProductName: ({ commit }, value) => {
    commit('_setSelectedProduct', null);
    commit('_setNewProductName', value);
  },

  setSelectedProduct: ({ commit }, product) => {
    commit('_setNewProductName', product.name);
    commit('_setSelectedProduct', product);
  },

  showLimitTooltip: ({ commit }, shelfIndex) => {
    commit('_addTooltip', shelfIndex);
    setTimeout(() => commit('_removeTooltip', shelfIndex), 5000);
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setPlanogramList: (state, list) => {
    state.planogramList = list;
  },

  _setPlanogram: (state, planogram) => {
    state.planogram = planogram;
  },

  _addShelf: (state) => {
    const lastShelf = last(state.shelfs);
    const lastId = (lastShelf && lastShelf.id) || 0;

    state.shelfs = produce(cloneDeep(state.shelfs), (draft) => {
      draft.push(getEmptyShelf(lastId + 1));
    });
  },

  _removeShelf: (state, index) => {
    state.shelfs = produce(cloneDeep(state.shelfs), (draft) => {
      draft.splice(index, 1);
    });
  },

  _clearShelf: (state, index) => {
    state.shelfs = produce(cloneDeep(state.shelfs), (draft) => {
      draft[index] = { ...draft[index], items: [] };
    });
  },

  _setShelf: (state, { shelf, shelfIdx }) => {
    state.shelfs = produce(cloneDeep(state.shelfs), (draft) => {
      draft[shelfIdx].items = shelf;
    });
  },

  _setShelfs: (state, shelfs) => {
    state.shelfs = shelfs;
  },

  _addProduct: (state) => {
    const shelfIndex = state.addShelfIndex;

    state.shelfs = produce(cloneDeep(state.shelfs), (draft) => {
      const items = draft[shelfIndex].items;
      const newItem = state.selectedProduct;

      const _id = nanoid();

      draft[shelfIndex].items = [...items, { ...newItem, _id }];
    });
  },

  _removeProduct: (state, { itemIdx, shelfIdx }) => {
    state.shelfs = produce(cloneDeep(state.shelfs), (draft) => {
      draft[shelfIdx].items.splice(itemIdx, 1);
    });
  },

  _toggleChangeMode: (state) => {
    state.changeMode = !state.changeMode;
  },

  _setAddMode: (state, { value, shelfIndex }) => {
    state.addMode = value;
    state.addShelfIndex = value ? shelfIndex : -1;
    state.newProductName = '';
    state.selectedProduct = null;
  },

  _setNewProductName: (state, value) => {
    state.newProductName = value;
  },

  _setSelectedProduct: (state, product) => {
    state.selectedProduct = product;
  },

  _addTooltip: (state, shelfIndex) => {
    state.tooltips = [...state.tooltips, shelfIndex];
  },

  _removeTooltip: (state, shelfIndex) => {
    state.tooltips = state.tooltips.filter((tooltip) => tooltip !== shelfIndex);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
