import Vue from 'vue';
import Router from 'vue-router';

import TempRoute from '@/core/views/TempRoute';

import onboarding from '@/apps/onboardingv2/routes';
import tradePoint from '@/apps/trade-point/routes';
import reports from '@/apps/reports/routes';
import employees from '@/apps/employees/routes';
import documents from '@/apps/documents/routes';
import items from '@/apps/items/routes';
import requisites from '@/apps/requisites/routes';
import discounts from '@/apps/discounts/routes';
import mailings from '@/apps/mailings/routes';
import catalogs from '@/apps/catalogs/routes';
import orders from '@/apps/orders/routes';
import registration from '@/apps/registration/routes';

Vue.use(Router);

const routes = [
  {
    path: '/temp',
    name: 'temp',
    component: TempRoute,
  },
  {
    path: '/',
    component: () => import('@/core/views/Index'),
    children: [
      {
        path: '',
        component: () => import('@/core/views/Main'),
        children: [
          {
            path: '',
            name: 'main',
            component: () => import('@/core/views/Home/Home'),
          },
          {
            path: 'profile',
            name: 'profile',
            component: () => import('@/core/views/profile/Profile'),
          },
          ...reports,
          ...employees,
          ...tradePoint,
          ...documents,
          ...requisites,
          ...discounts,
          ...items,
          ...mailings,
          ...catalogs,
          ...orders,
          ...registration,
          ...onboarding,
        ],
      },
    ],
  },
];

// const addMeta = (routes) => {
//   const addName = ({ name }) => {
//     if (!name) return;
//     console.log(name);
//     return 'a';
//   };
//   const addBreadCrumbs = (route) => {
//     const breadbrumb = { ...route.meta, breadbrumb: addName(route) };
//     return breadbrumb;
//   };

//   const recursiveDetur = (route) => {
//     // console.log(route);
//     if (route.children) {
//       return recursiveDetur(route.children);
//     }
//     if (Array.isArray(route)) {
//       return route.map((r) => {
//         if (r.children) {
//           return { ...r, children: recursiveDetur(r.children) };
//         }
//         return { ...r, meta: addBreadCrumbs(r) };
//       });
//     }
//     return { ...route, meta: addBreadCrumbs(route) };
//   };
//   const routesWithMeta = {
//     ...routes[1],

//     children: routes[1].children.map((route) => ({
//       ...route,
//       children: recursiveDetur(route),
//     })),
//   };

//   return [routes[0], routesWithMeta];
// };

// const routesWithMeta = addMeta(routes);
// console.log(routesWithMeta);
// console.log(routes);
const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
});

export default router;
