import { bus } from 'shared/core';
import router from '@/core/router';
import { getI18n } from '@/core/i18n';

const i18n = getI18n();

const logOut = () => {
  router.push({ name: 'logout' });
  return true;
};

const createLogoutModalButtons = () => [
  {
    text: i18n.t('logout'),
    type: 'action',
    callback: logOut,
  },
  {
    text: i18n.t('do_cancel'),
    type: 'none',
    callback: () => true,
  },
];

class LogoutEvent {
  init() {
    bus.on('shared$navigation/logout', this.onLogout);
  }

  onLogout = () => {
    const meta = {
      buttons: createLogoutModalButtons(),
      message: i18n.t('log_out_question'),
      title: i18n.t('need_detals'),
      withClose: false,
    };

    bus.emit('core$notifications/add', { meta, type: 'modal' });
  };
}

export default new LogoutEvent();
