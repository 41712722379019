import { companyAPI } from '@/core/api/company';
import { isEmpty, uniq } from 'lodash';
import { STAGES } from '@/core/data/appStages';
import router from '@/core/router';
import { getTimeZone } from '@/core/utils/timeZone';

const redirect = (opts) => {
  const { name } = opts;
  if (router.currentRoute.name === name) return Promise.resolve(false);

  return router.replace(opts);
};

const stringifyOffset = (offset) => {
  const sign = offset > 0 ? '+' : '';

  return `${sign + offset} UTC`;
};

const state = {
  loading: false,
  stage: STAGES.INIT,
  settings: null,
  completedStages: [],
  partyList: [],
  firstRoute: null,
  allowedTypes: [],
  timeZones: {},

  currencyList: {},
  taxSystems: {},
};

const getters = {
  loading: (state) => state.loading,
  firstRoute: (state) => state.firstRoute,
  stage: (state) => state.stage,
  settings: (state) => state.settings,
  mailingsAccess: (state) => !!(state.settings && state.settings.is_push_distribution_enabled),
  refundAccess: (state) => !!(state.settings && state.settings.is_refund_enable),
  // refundAccess: (state) => true,
  isComplete: (state) => (stage) => state.completedStages.includes(stage),
  isReady: (state) => state.stage === STAGES.READY,
  allowedTypes: (state) => state.allowedTypes,
  partyList: (state) => state.partyList,
  taxSystems: (state) =>
    Object.entries(state.taxSystems).map(([value, title]) => ({ value, title })),

  timeZoneList: (state, getters) =>
    Object.keys(state.timeZones).map((timeZone) => {
      const stringifyOffset = getters.timeZoneOffset(timeZone, { stringify: true });

      return {
        name: `${timeZone.replace(/_/g, ' ')} ${stringifyOffset}`,
        id: timeZone,
        meta: { timeZone },
      };
    }),

  timeZoneOffset: (state) => (tz = getTimeZone(), opts = {}) => {
    const { stringify = false } = opts;
    if (!state.timeZones.hasOwnProperty(tz)) return '';

    const offset = state.timeZones[tz];
    const offsetInHours = offset ? -(offset / 60 / 60) : offset;

    return stringify ? stringifyOffset(offsetInHours) : offsetInHours;
  },
  internationalPartyList: (state) => state.partyList.filter((party) => !party.type_data),
  currencyList: (state) => state.currencyList,
};

const actions = {
  init: async ({ dispatch, state, commit }, opts = {}) => {
    if (state.loading) return;
    commit('setLoading', !opts.silent);

    try {
      await dispatch('_init', opts);
    } catch (error) {
      commit('setStage', STAGES.ERROR);
      //TODO: redirect on fail page
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },

  _init: async ({ commit, dispatch, getters }, opts = {}) => {
    const { silent = false } = opts;

    try {
      await dispatch('initCompany');
      await dispatch('initAllowedtypes');
      await dispatch('initSettings');
    } catch (error) {
      router.push('/logout');
    }

    await dispatch('initCurrency');
    await dispatch('initProfile');
    await dispatch('fetchTimeZones');
    await dispatch('fetchParty');
    await dispatch('fetchTaxSystems');

    let checkMap = [{ action: 'checkRegistration', stage: STAGES.REGISTRATION }];

    for (let { action, stage } of checkMap) {
      if (getters.isComplete(stage)) continue;

      const { complete, handler } = await dispatch(action);

      if (complete) {
        commit('addCompletedStage', stage);
        continue;
      }

      if (!getters.firstRoute) {
        commit('_setFirstRoute', router.currentRoute);
      }
      commit('setStage', stage);
      await handler();
      return;
    }

    // IS_DEV &&
    //   (await dispatch(
    //     'trade-point$create/init',
    //     { storeId: 6024, storeGroupId: 5998 },
    //     { root: true },
    //   ));
    commit('setStage', STAGES.READY);
    const successRedirectRoute = await dispatch('getSuccessRedirectRoute');

    if (silent) return;
    if (successRedirectRoute) {
      await redirect(successRedirectRoute);
    }
  },

  completeStage: ({ commit, dispatch }, { stage, ...restOpts }) => {
    commit('addCompletedStage', stage);
    return dispatch('init', restOpts);
  },

  unsetStage: ({ commit, dispatch }, { stage, ...restOpts }) => {
    commit('removeCompletedStage', stage);
    return dispatch('init', restOpts);
  },

  getSuccessRedirectRoute: async ({ getters, rootGetters }) => {
    const firstRoute = getters.firstRoute;

    try {
      const storeList = await companyAPI.getStoreList({ limit: 1, page: 1 });
      const hasStores = !!storeList.items.length;

      return hasStores ? firstRoute : { name: 'trade-point$create' };
    } catch (error) {
      return firstRoute;
    }
  },

  initCompany: ({ dispatch, rootGetters }) => {
    const company = rootGetters['core$company/company'];
    if (!isEmpty(company)) return;

    return dispatch('core$company/init', null, { root: true });
  },

  initSettings: async ({ commit, state }) => {
    if (state.settings) return;

    const settings = await companyAPI.getSettings();
    commit('setSettings', settings);
  },

  initAllowedtypes: async ({ commit, state }) => {
    if (state.allowedTypes.length) return;

    const allowedTypes = await companyAPI.getStoreAcceptedTypes();
    commit('setAllowedTypes', allowedTypes);
  },

  initProfile: ({ dispatch, rootGetters }) => {
    const profile = rootGetters['core$profile/profile'];
    if (!isEmpty(profile)) return;

    return dispatch('core$profile/init', null, { root: true });
  },

  fetchParty: async ({ commit, getters }) => {
    const { partyList } = getters;
    if (partyList.length) return;

    const { items: parties } = await companyAPI.getExchangePartyList({
      limit: 9999,
    });
    commit('setPartyList', parties);
  },

  fetchTaxSystems: async ({ commit, getters }) => {
    if (getters.taxSystems.length) return;

    const taxSystems = await companyAPI.getAvailableTaxSystems();
    commit('setTaxSystems', taxSystems);
  },

  checkRegistration: async ({ getters }) => {
    const handler = () => redirect({ name: 'registration' });
    const hasParty = !!getters.partyList.length;

    return { complete: hasParty, handler };
  },

  updatePartyInPartyList({ commit, getters }, updatedParty) {
    const updatedPartyList = getters.partyList.map((party) => {
      return party.id === updatedParty.id ? updatedParty : party;
    });

    commit('setPartyList', updatedPartyList);
  },

  fetchTimeZones: async ({ commit, getters }) => {
    if (getters.timeZoneList.length) return;

    const timeZones = await companyAPI.getTimeZoneList();
    commit('setTimeZones', timeZones);
  },

  initCurrency: async ({ commit, getters }) => {
    if (isEmpty(getters.currencyList)) {
      const currencyList = await companyAPI.getCurrencyCode();
      commit('setCurrencyList', currencyList);
    }
  },
};

const mutations = {
  _setFirstRoute: (state, value) => (state.firstRoute = value),

  setLoading: (state, value) => (state.loading = value),

  setInit: (state, value) => (state.init = value),

  setStage: (state, stage) => (state.stage = stage),

  setPartyList: (state, partyList) => (state.partyList = partyList),

  setTaxSystems: (state, taxSystems) => (state.taxSystems = taxSystems),

  setAllowedTypes: (state, allowedTypes) => (state.allowedTypes = allowedTypes),

  setSettings: (state, settings) => (state.settings = settings),

  setTimeZones: (state, timeZones) => (state.timeZones = timeZones),

  setCurrencyList: (state, list) => (state.currencyList = list),

  addCompletedStage: (state, stage) => {
    state.completedStages = uniq([...state.completedStages, stage]);
  },

  removeCompletedStage: (state, stage) => {
    state.completedStages = state.completedStages.filter((_stage) => _stage !== stage);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
